import { ChevronDown, ChevronUp } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';

interface IProps {
  title: any;
  children: any;
  className?: string;
  contentClassName?: string;
  handleClick?: any;
  isOpen?: boolean;
  showContentOnActive?: any;
  contentParentClass?: string;
  AccordionIconClass?: string;
  accordionHeaderWrap?: string;
  activeClassName?: string;
  inactiveClassName?: string;
  isClickable?: boolean;
}

const AccordionCmp = ({
  title,
  children,
  showContentOnActive,
  AccordionIconClass,
  accordionHeaderWrap,
  activeClassName,
  inactiveClassName,
  className = '',
  contentClassName = '',
  contentParentClass = '',
  handleClick = () => false,
  isOpen = undefined,
  isClickable = true,
}: IProps) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsActive(isOpen);
    }
  }, [isOpen]);

  return (
    <div
      className={`w-full ${
        isClickable ? 'cursor-pointer' : ''
      } px-4 py-3 bg-white rounded-[10px] border border-utilityGray200 ${className} ${
        isActive ? activeClassName : inactiveClassName
      } `}
    >
      <div
        className={`justify-between items-center flex gap-2 ${accordionHeaderWrap}`}
        onClick={() => {
          if (!isClickable) {
            return true;
          }

          const isActiveNew = !isActive;
          setIsActive(isActiveNew);
          handleClick(isActiveNew);
        }}
      >
        {isActive && showContentOnActive ? (
          <div className="flex mdm:flex-row md:flex-col sm:flex-row flex-col mdm:justify-between md:justify-normal sm:justify-between gap-2 flex-grow mdm:items-center md:items-stretch sm:items-center">
            {title} {showContentOnActive}
          </div>
        ) : (
          title
        )}
        <div className={`${AccordionIconClass}`}>
          {isActive ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </div>
      </div>

      {isActive && (
        <div
          className={`mt-3 pt-4 border-t border-utilityGray100 ${contentParentClass}`}
        >
          <div className={`${contentClassName} w-full bg-white`}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionCmp;
