import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const dimensionsOrderDetailRow = ({
  data,
  currentLegData,
  isOrderHasLeg,
  handlingUnitOptions,
}: any) => {
  console.log('data', data);

  const handlingUnit = handlingUnitOptions?.find(
    (item: any) => item?.value === data?.handlingUnit
  );

  const getLegType = (lineItem: any, currLegData: any): string => {
    if (lineItem?.dropLegId === currLegData?.id) {
      return 'Drop-off';
    }

    if (lineItem?.pickupLegId === currLegData?.id) {
      if (currLegData?.isLastLeg && lineItem?.dropLegId === null) {
        return 'Pickup & Drop';
      } else {
        return 'Pickup';
      }
    }

    if (
      currLegData?.isLastLeg &&
      lineItem?.dropLegId === null &&
      lineItem?.pickupLegId === currLegData?.id
    ) {
      return 'Pickup & Drop';
    }

    if (currLegData?.isLastLeg && lineItem?.dropLegId === null) {
      return 'Drop-off';
    }

    return 'Continue';
  };

  return (
    <>
      <td className="px-5 py-4">{data?.handlingUnitNo}</td>
      <td className="px-5 py-4">{handlingUnit?.label ?? '-'}</td>
      <td className="px-5 py-4">
        {data?.length} X {data?.width} X {data?.height}
      </td>
      <td className="px-5 py-4">Freight Class</td>
      <td className="px-5 py-4">
        {data?.totalWeight ? data?.totalWeight.toFixed(2) : 0} (
        {data?.weightMeasurement})
      </td>
      <td className="px-5 py-4">{data?.refNumber ?? '-'}</td>
      <td className="px-5 py-4">{data?.description ?? '-'}</td>

      {isOrderHasLeg && (
        <td className="px-5 py-4">{getLegType(data, currentLegData)}</td>
      )}
    </>
  );
};

export default dimensionsOrderDetailRow;
