import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import { getSpamEmails } from 'src/services/SettingService';
import WalToast from 'src/utils/WalToast';

import DeleteSpamEmailModal from './DeleteSpamEmailModal';

interface SpamEmail {
  id: number;
  email: string;
  non_classified_count: number;
  isSpam: boolean;
  created_at?: string;
}

const SpamEmailSettings = () => {
  const [spamEmails, setSpamEmails] = useState<SpamEmail[]>([]);
  const [showNonSpam, setShowNonSpam] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState<SpamEmail | null>(null);

  const fetchSpamEmails = async () => {
    try {
      const response = await getSpamEmails();

      if (response.data) {
        setSpamEmails(response.data);
      }
    } catch (error) {
      console.error('Error fetching spam emails:', error);
      WalToast.error('Failed to fetch spam emails');
    }
  };

  const handleRemoveSpamEmail = (email: SpamEmail) => {
    setEmailToDelete(email);
  };

  const handleDeleteModalClose = (success?: boolean) => {
    if (success) {
      fetchSpamEmails(); // Refresh the list
    }
    setEmailToDelete(null);
  };

  useEffect(() => {
    fetchSpamEmails();
  }, []);

  const spamEmailsList = spamEmails.filter((email) => email.isSpam);
  const nonSpamEmailsList = spamEmails.filter((email) => !email.isSpam);

  return (
    <div className="h-full w-full px-2 py-1">
      <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
        <div>
          <h6 className="text-grayLight900 font-semibold text-sm">
            Spam Email List
          </h6>
          <p className="text-grayLight600 font-normal text-sm">
            List of emails marked as spam
          </p>
        </div>
      </div>

      <div className="mt-4">
        {spamEmailsList.length === 0 ? (
          <p className="text-grayLight600 text-sm">No spam emails found</p>
        ) : (
          <div className="space-y-2">
            {spamEmailsList.map((email) => (
              <div
                key={email.id}
                className="flex justify-between items-center p-3 bg-red-50 rounded-md"
              >
                <div>
                  <p className="text-sm font-medium">{email.email}</p>
                  <p className="text-xs text-grayLight600">
                    Non-classified emails count: {email.non_classified_count}
                  </p>
                </div>
                <ButtonCmp
                  type="button"
                  className="btn_secondary_black shadow py-1 px-2 text-sm"
                  onClick={() => handleRemoveSpamEmail(email)}
                >
                  Remove
                </ButtonCmp>
              </div>
            ))}
          </div>
        )}

        {nonSpamEmailsList.length > 0 && (
          <div className="mt-6">
            <button
              onClick={() => setShowNonSpam(!showNonSpam)}
              className="flex items-center gap-2 text-sm text-grayLight900 font-medium"
            >
              <span>{showNonSpam ? '▼' : '▶'}</span>
              Non-spam Emails ({nonSpamEmailsList.length})
            </button>

            {showNonSpam && (
              <div className="space-y-2 mt-2">
                {nonSpamEmailsList.map((email) => (
                  <div
                    key={email.id}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded-md"
                  >
                    <div>
                      <p className="text-sm font-medium">{email.email}</p>
                      <p className="text-xs text-grayLight600">
                        Non-classified emails: {email.non_classified_count}
                      </p>
                    </div>
                    <ButtonCmp
                      type="button"
                      className="btn_secondary_black shadow py-1 px-2 text-sm"
                      onClick={() => handleRemoveSpamEmail(email)}
                    >
                      Remove
                    </ButtonCmp>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {emailToDelete && (
        <DeleteSpamEmailModal
          email={emailToDelete.email}
          id={emailToDelete.id}
          handleClose={handleDeleteModalClose}
        />
      )}
    </div>
  );
};

export default SpamEmailSettings;
