import { Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const salesPriceRow = ({
  data,
  handleSalesEdit,
  customerList,
  handleSalesDelete,
  carrierPrice,
}: any) => {
  const currentCustomer =
    customerList?.find(
      (customer: any) => customer.value === data?.customerId
    ) ?? null;

  const additionalChargesTotal = Array.isArray(data?.additional_charge)
    ? data?.additional_charge.reduce(
        (total: number, item: any) => total + (item.price || 0),
        0
      )
    : 0;

  const combinedMargin =
    data?.totalSalePrice -
    (carrierPrice?.length > 0
      ? carrierPrice[0]?.totalPrice
      : data?.totalSalePrice * 0.9);

  const combinedMarginPercentage =
    data?.totalSalePrice > 0
      ? (combinedMargin / data?.totalSalePrice) * 100
      : 0;

  return (
    <>
      <td className="px-5 py-4 w-48 max-w-48 min-w-48">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap">
            {currentCustomer?.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={`${currentCustomer.image}`}
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(currentCustomer?.label)}
              </div>
            )}
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {currentCustomer?.label}
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              USD Billing Account
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>${data?.baseSalePrice}</span>
      </td>
      <td className="px-5 py-4 w-[160px] max-w-[160px] min-w-[160px]">
        <span>${additionalChargesTotal}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>${data?.totalSalePrice}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>${combinedMargin ? combinedMargin?.toFixed(2) : 0}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>
          {combinedMarginPercentage
            ? Math.abs(combinedMarginPercentage)?.toFixed(2)
            : 0}
          %
        </span>
      </td>

      <td className="px-5 py-4 w-[80px] min-w-[80px] max-w-[80px]">
        <div className="justify-center items-center gap-2 flex ">
          <Edit01
            className="w-4 h-4 cursor-pointer"
            onClick={() => {
              handleSalesEdit(data);
            }}
          />
          <Trash01
            className="w-4 h-4 relative cursor-pointer"
            onClick={() => {
              handleSalesDelete();
            }}
          />
        </div>
      </td>
    </>
  );
};

export default salesPriceRow;
