import React, { useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';

import LaneHistoryRow from './LaneHistoryRow';

const laneHistoryData = [
  {
    customer: {
      name: 'Koray Okumus',
      id: 'WAL-DMIF-1',
    },
    orderCreation: 'Jan 6, 2022',
    totalRates: 5,
    address: {
      from: 'Etobicoke, ON, Canada',
      to: 'Stillwater, OK 74078, USA',
    },
    totalUnits: 4,
    totalWeight: '20',
    totalCost: '752.09',
    totalPrice: '905.80',
    margin: '56',
  },
  {
    customer: {
      name: 'Natali Craig',
      id: 'WAL-DMIF-1',
    },
    orderCreation: 'Jan 6, 2022',
    totalRates: 10,
    address: {
      from: 'Montreal, QC, Canada',
      to: 'Calgary, AB, Canada',
    },
    totalUnits: 4,
    totalWeight: '20',
    totalCost: '752.09',
    totalPrice: '905.80',
    margin: '56',
  },
  {
    customer: {
      name: 'Koray Okumus',
      id: 'WAL-DMIF-1',
    },
    orderCreation: 'Jan 6, 2022',
    totalRates: 20,
    address: {
      from: 'Etobicoke, ON, Canada',
      to: 'Stillwater, OK 74078, USA',
    },
    totalUnits: 4,
    totalWeight: '20',
    totalCost: '752.09',
    totalPrice: '905.80',
    margin: '56',
  },
  {
    customer: {
      name: 'Natali Craig',
      id: 'WAL-DMIF-1',
      image: 'natali-craig.jpg',
    },
    orderCreation: 'Jan 6, 2022',
    totalRates: 8,
    address: {
      from: 'Montreal, QC, Canada',
      to: 'Calgary, AB, Canada',
    },
    totalUnits: 4,
    totalWeight: '20',
    totalCost: '752.09',
    totalPrice: '905.80',
    margin: '56',
  },
];

const LaneHistory = () => {
  const [params, setParams] = useState([]);
  const [laneHistory, setLaneHistory] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setLaneHistory(laneHistoryData);
    setIsLoading(false);
  }, []);

  const headCells = useMemo(
    () => [
      {
        id: 'customers',
        name: 'Customers',
        sortable: false,
        visible: true,
      },
      {
        id: 'orderCreation',
        name: 'Order Creation',
        sortable: false,
        visible: true,
      },
      {
        id: 'totalRates',
        name: 'Total Rates',
        sortable: false,
        visible: true,
      },
      {
        id: 'address',
        name: 'Address',
        sortable: true,
        visible: true,
      },
      {
        id: 'totalUnits',
        name: 'Total units',
        sortable: false,
        visible: true,
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        sortable: false,
        visible: true,
      },
      {
        id: 'totalCost',
        name: 'Total Cost',
        sortable: false,
        visible: true,
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
        sortable: false,
        visible: true,
      },
      {
        id: 'margin',
        name: 'Margin',
        sortable: false,
        visible: true,
      },
    ],
    []
  );

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="w-full ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
            <TableCmp
              headCells={headCells}
              params={params}
              setParams={setParams}
              tableDataArr={laneHistory}
              // TableLoaderRowCmp={CustomerDetailClaimsLoadingRaw}
              TableRowCmp={LaneHistoryRow}
              isTableRowClickable={true}
              tableRowCmpProps={{}}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={7}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LaneHistory;
