import {
  CheckSquareBroken,
  InfoCircle,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CREDIT_INCREASE_REQUEST_STATUS_TYPES } from 'src/constants/common';
import { getFormattedNumber, getShortName } from 'src/utils/CommonFunctions';

import CreditIncreaseApproveModal from './CreditIncreaseApproveModal';
import CreditIncreaseDeclineModal from './CreditIncreaseDeclineModal';
import NextTasksModal from './NextStepsModal';

const CreditIncreaseRequestRow = ({ data, setIsRefresh }: any) => {
  const [isShowDeclineModal, setIsShowDeclineModal] = useState(false);
  const [isShowApproveModal, setIsShowApproveModal] = useState(false);
  const [isShowNextStepsModal, setIsShowNextStepsModal] = useState(false);

  return (
    <>
      <td className="px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap notification-mark-wrap">
            {data?.image && data?.imageUrl ? (
              <img
                className="table-profile-icn-circle-xl"
                src={`${data?.imageUrl}${data?.image}`}
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(`${data?.firstName} ${data?.lastName}`)}
              </div>
            )}
            {data.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>

          <div className="truncate  flex flex-col">
            <p className="truncate">
              {data?.firstName} {data?.lastName}
            </p>
            #{data?.requestFullId}
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[150px] w-[150px] max-w-[150px]">
        <div className="flex flex-wrap gap-1">
          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.NEW && (
            <BadgeCmp style="modern" type="primary">
              New
            </BadgeCmp>
          )}
          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.APPROVED && (
            <TooltipCmp
              message={`Approved Credit Limit : $${getFormattedNumber(
                data?.approvedTotalCreditLimit
              )}`}
            >
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName="cursor-pointer"
              >
                Approved
              </BadgeCmp>
            </TooltipCmp>
          )}
          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.IN_REVIEW && (
            <BadgeCmp style="modern" type="info">
              In-review
            </BadgeCmp>
          )}
          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.DENIED && (
            <TooltipCmp message={`Reason : ${data?.declineExplanation}`}>
              <BadgeCmp
                style="modern"
                type="red"
                mainClassName="cursor-pointer"
              >
                Denied
              </BadgeCmp>
            </TooltipCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 min-w-[150px] w-[17.75%] max-w-[1px]">
        <div className={`flex items-center gap-3 cursor-pointer`}>
          <div className="table-profile-wrap notification-mark-wrap">
            {data?.customerImage && data?.customerImageUrl ? (
              <img
                className="table-profile-icn-circle-sm"
                src={`${data?.customerImageUrl}${data?.customerImage}`}
              />
            ) : (
              <div className="table-profile-customer-circle-sm">
                {getShortName(`${data?.customerName}`)}
              </div>
            )}
            {data.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>
          <p className="truncate">{data?.customerName}</p>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[170px] w-[17.75%] max-w-[17.75%]">
        <span>
          $
          {getFormattedNumber(
            data?.requestedTotalCredit - data?.requestedCreditIncrease
          )}
        </span>
      </td>
      <td className="px-5 py-4 min-w-[220px] w-[17.75%] max-w-[17.75%]">
        <span>
          ${getFormattedNumber(parseFloat(data?.requestedCreditIncrease))}
        </span>
      </td>
      <td className="px-5 py-4 min-w-[195px] w-[17.75%] max-w-[17.75%]">
        <span>
          ${getFormattedNumber(parseFloat(data?.requestedTotalCredit))}
        </span>
      </td>
      <td className="px-5 py-4 w-20 max-w-20 min-w-20">
        <div className="justify-end items-center gap-2 flex ">
          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.IN_REVIEW && (
            <TooltipCmp message={'Approve'}>
              <div
                className="cursor-pointer"
                onClick={() => setIsShowApproveModal(true)}
              >
                <CheckSquareBroken className="w-3.5 h-3.5 text-fgSuccessPrimary" />
              </div>
            </TooltipCmp>
          )}

          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.IN_REVIEW && (
            <TooltipCmp message={'Decline'}>
              <div
                className="cursor-pointer"
                onClick={() => setIsShowDeclineModal(true)}
              >
                <XClose className="w-4 h-4 text-fgErrorPrimary" />
              </div>
            </TooltipCmp>
          )}

          {data?.status === CREDIT_INCREASE_REQUEST_STATUS_TYPES.APPROVED && (
            <InfoCircle
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={() => setIsShowNextStepsModal(true)}
            />
          )}
        </div>
      </td>

      {isShowApproveModal && (
        <CreditIncreaseApproveModal
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefresh(true);
            }
            setIsShowApproveModal(false);
          }}
          creditIncreaseRequestData={data}
        />
      )}

      {isShowDeclineModal && (
        <CreditIncreaseDeclineModal
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefresh(true);
            }
            setIsShowDeclineModal(false);
          }}
          creditIncreaseRequestData={data}
        />
      )}

      {isShowNextStepsModal && (
        <NextTasksModal handleClose={() => setIsShowNextStepsModal(false)} />
      )}
    </>
  );
};

export default CreditIncreaseRequestRow;
