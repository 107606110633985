import { yupResolver } from '@hookform/resolvers/yup';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import { addInterruption } from 'src/services/OrderService';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  interruptionExplanation: Yup.string()
    .required('Explanation is required')
    .min(10, 'Explanation must be at least 10 characters'),
  claim: Yup.string()
    .oneOf(['Yes', 'No'], 'Please select a valid option for claim')
    .required('Claim is required'),
  dispute: Yup.string()
    .oneOf(['Yes', 'No'], 'Please select a valid option for dispute')
    .required('Dispute is required'),
});

interface IProps {
  orderLegId: any;
  handleClose: any;
  handleOnSubmit: any;
}

const ReportInterruptionModal = ({
  orderLegId,
  handleClose,
  handleOnSubmit,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver<any>(validationSchema),
    defaultValues: {
      interruptionExplanation: '',
      claim: 'Yes',
      dispute: 'Yes',
    },
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);

    addInterruption({ ...data, orderLegId })
      .then((response) => {
        if (response?.data) {
          handleOnSubmit(response?.data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CommonModal
      title="Report Interruption"
      titleDesc="Next steps after reporting interruption"
      handleClose={handleClose}
      headerIcon={<PackagePlus />}
      size={'max-w-[600px]'}
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-5 flex flex-col gap-y-4"
      >
        <Controller
          name="interruptionExplanation"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputText
              inputName="chatbot"
              label="Please add a detailed explanation of the interruption"
              placeholder="Enter Explanation"
              labelClassName="block mb-1.5"
              className="h-auto"
              asTextarea="textarea"
              parentClassName={'w-full'}
              value={value}
              onChangeFunc={onChange}
              errorText={error ? error?.message : ''}
            />
          )}
        />
        <ul className="flex flex-wrap gap-2">
          <li className="flex-1">
            <h6 className="text-textSecondary text-sm font-normal mb-2">
              Would you like to open a claim?
            </h6>
            <div className="flex gap-x-2">
              <Controller
                name="claim"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Radio
                      id="claim-yes"
                      value="Yes"
                      labelName="Yes"
                      parentClassName="min-w-[75px]"
                      checked={value === 'Yes'}
                      onChangeFunc={onChange}
                      labelHtmlFor="claim-yes"
                    />
                    <Radio
                      id="claim-no"
                      value="No"
                      labelName="No"
                      parentClassName="min-w-[75px]"
                      checked={value === 'No'}
                      onChangeFunc={onChange}
                      labelHtmlFor="claim-no"
                    />
                  </>
                )}
              />
            </div>
          </li>

          <li className="flex-1">
            <h6 className="text-textSecondary text-sm font-normal mb-2">
              Would you like to open a dispute?
            </h6>
            <div className="flex gap-x-2">
              <Controller
                name="dispute"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Radio
                      id="dispute-yes"
                      value="Yes"
                      labelName="Yes"
                      parentClassName="min-w-[75px]"
                      checked={value === 'Yes'}
                      onChangeFunc={onChange}
                      labelHtmlFor="dispute-yes"
                    />
                    <Radio
                      id="dispute-no"
                      value="No"
                      labelName="No"
                      parentClassName="min-w-[75px]"
                      checked={value === 'No'}
                      onChangeFunc={onChange}
                      labelHtmlFor="dispute-no"
                    />
                  </>
                )}
              />
            </div>
          </li>
        </ul>
      </form>
    </CommonModal>
  );
};

export default ReportInterruptionModal;
