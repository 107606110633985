import { groupBy } from 'lodash';
import React, { memo, useEffect, useState, useMemo } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import WalToast from 'src/utils/WalToast';

import AccordionCmp from '../../components/AccordionCmp';
import InputText from '../../components/InputText/InputText';
import TooltipCmp from '../../components/TooltipCmp';
import { CURRENCY } from '../../constants/common';
import {
  getCarrierServicePrices,
  updateCarrierServicePrices,
} from '../../services/CarrierService';
import { listService } from '../../services/CommonService';
import { useRolePermission } from '../../utils/CommonFunctions';

const CarrierServicePrice = ({ carrier, currency, setIgnoreClose }: any) => {
  const { hasPermission } = useRolePermission();
  // const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<any>();
  const [carrierServicePrice, setCarrierServicePrice] = useState<any>({});
  const [isUpdatingPrice, setIsUpdatingPrice] = useState(false);

  const headCells = useMemo(
    () => [
      {
        id: 'serviceId',
        name: 'Service',
      },
      {
        id: 'price',
        name: 'Price',
      },
      {
        id: 'action',
      },
    ],
    []
  );

  useEffect(() => {
    listService({ carrierId: carrier.id })
      .then((response: any) => {
        // setServiceList(response.data);
        if (response.data.length) {
          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    getCarrierServicePrices(carrier.id)
      .then((response: any) => {
        if (response.data.length) {
          let priceData: any = {};
          response.data.forEach(
            (data: any) =>
              (priceData[data.serviceId] =
                currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
          );
          setCarrierServicePrice({ ...carrierServicePrice, ...priceData });
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  }, []);

  const handleChangePrice = (event: any, serviceId: any) => {
    setCarrierServicePrice({
      ...carrierServicePrice,
      [serviceId]: event.target.value,
    });
  };

  const handleSavePrice = () => {
    setIsLoading(true);
    const appendFormData: any = new FormData();
    appendFormData.append('data', JSON.stringify(carrierServicePrice));
    appendFormData.append('currency', currency);
    updateCarrierServicePrices(carrier.id, appendFormData)
      .then((response: any) => {
        if (response.data && response.data.length) {
          let priceData: any = {};
          response.data.forEach(
            (data: any) =>
              (priceData[data.serviceId] =
                currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
          );
          setCarrierServicePrice({ ...carrierServicePrice, ...priceData });
        } else {
          setCarrierServicePrice({});
        }
      })

      .finally(() => {
        WalToast.success('Price updated successfully.');
        setIsLoading(false);
        setIsUpdatingPrice(false);
      })
      .catch(() => {
        WalToast.error('Price Not updated');
        setIsLoading(false);
      });
  };

  return (
    <div className="relative overflow-x-auto pt-8">
      <div className="overflow-y-auto">
        {hasPermission('carrier_update') && (
          <div className="text-right sticky top-0">
            {!isUpdatingPrice && (
              <button
                type="button"
                className="btn_primary px-5"
                onClick={() => {
                  setIgnoreClose(true);
                  setIsUpdatingPrice(true);
                }}
              >
                Update Price
              </button>
            )}
            {isUpdatingPrice && (
              <button
                type="button"
                className="btn_primary px-5"
                onClick={() => {
                  setIgnoreClose(true);
                  handleSavePrice();
                }}
                disabled={isLoading}
              >
                save
              </button>
            )}
          </div>
        )}
        <AccordionCmp title="Pickup Services" className="mt-4">
          <table className="w-full text-sm text-left text-gray-500 service-price">
            <thead className="text-sm font-light text-grayText">
              <tr className="border-b border-gray-200">
                {headCells.map((head, index) => [
                  head?.name && (
                    <th key={index} className="text-sm font-medium p-3 pt-0">
                      {head.name}
                    </th>
                  ),
                ])}
              </tr>
            </thead>
            <tbody>
              {serviceTypes?.PICKUP?.length ? (
                serviceTypes.PICKUP.map((service: any, index: any) => (
                  <tr
                    key={index}
                    className="hover:row_active transition-all delay-100 odd:bg-neutral-100"
                  >
                    <td className="p-3 text-base text-black font-normal">
                      {
                        <div className="popover ml-1">
                          <div className="flex items-center">
                            <div className="mr-1 text-base cursor-pointer font-medium">
                              {service.name}
                            </div>
                            <TooltipCmp message={service.description}>
                              <HiOutlineExclamationCircle className="text-grayText" />
                            </TooltipCmp>
                          </div>
                        </div>
                      }
                    </td>
                    <td className="p-3 text-base text-black font-normal text-right">
                      {isUpdatingPrice ? (
                        <InputText
                          inputName="name"
                          inputType="number"
                          placeholder="Price"
                          value={
                            carrierServicePrice &&
                            carrierServicePrice[service.id]
                              ? carrierServicePrice[service.id]
                              : ''
                          }
                          onChangeFunc={(event: any) => {
                            handleChangePrice(event, service.id);
                          }}
                          shouldFormatNumber={true}
                          disabled={!isUpdatingPrice}
                          className={
                            !isUpdatingPrice
                              ? 'cursor-not-allowed h-1 w-20 pr-0'
                              : 'h-1 w-20 pr-0'
                          }
                        />
                      ) : (
                        <>
                          {carrierServicePrice &&
                          carrierServicePrice[service.id]
                            ? carrierServicePrice[service.id]
                            : '-'}
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="transition-all delay-100 odd:bg-neutral-200">
                  <td
                    className="p-3 text-base text-black text-center  font-normal"
                    colSpan={headCells.length}
                  >
                    {isUpdatingPrice ? 'Please wait...' : 'Data not found'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </AccordionCmp>
        <AccordionCmp title="Delivery Services" className="mt-4">
          <table className="w-full text-sm text-left text-gray-500 service-price">
            <thead className="text-sm font-light text-grayText">
              <tr className="border-b border-gray-200">
                {headCells.map(
                  (head, index) =>
                    head?.name && (
                      <th key={index} className="text-sm font-medium p-3 pt-0">
                        {head.name}
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {serviceTypes?.DELIVERY?.length ? (
                serviceTypes.DELIVERY.map((service: any, index: any) => (
                  <tr
                    key={index}
                    className="hover:row_active transition-all delay-100 odd:bg-neutral-100"
                  >
                    <td className="p-3 text-base text-black font-normal">
                      {
                        <div className="popover ml-1">
                          <div className="flex items-center">
                            <div className="mr-1 text-base cursor-pointer font-medium">
                              {service.name}
                            </div>
                            <TooltipCmp message={service.description}>
                              <HiOutlineExclamationCircle className="text-grayText" />
                            </TooltipCmp>
                          </div>
                        </div>
                      }
                    </td>
                    <td className="p-3 text-base text-black font-normal text-right">
                      {isUpdatingPrice ? (
                        <InputText
                          inputName="name"
                          inputType="number"
                          placeholder="Price"
                          value={
                            carrierServicePrice &&
                            carrierServicePrice[service.id]
                              ? carrierServicePrice[service.id]
                              : ''
                          }
                          onChangeFunc={(event: any) => {
                            handleChangePrice(event, service.id);
                          }}
                          shouldFormatNumber={true}
                          disabled={!isUpdatingPrice}
                          className={
                            !isUpdatingPrice
                              ? 'cursor-not-allowed h-1 w-20 pr-0'
                              : 'h-1 w-20 pr-0'
                          }
                        />
                      ) : (
                        <>
                          {carrierServicePrice &&
                          carrierServicePrice[service.id]
                            ? carrierServicePrice[service.id]
                            : '-'}
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="transition-all delay-100 odd:bg-neutral-200">
                  <td
                    className="p-3 text-base text-black text-center  font-normal"
                    colSpan={headCells.length}
                  >
                    {isUpdatingPrice ? 'Please wait...' : 'Data not found'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </AccordionCmp>

        <AccordionCmp title="Other Services" className="mt-4">
          <table className="w-full text-sm text-left text-gray-500 service-price">
            <thead className="text-sm font-light text-grayText">
              <tr className="border-b border-gray-200">
                {headCells.map(
                  (head, index) =>
                    head?.name && (
                      <th key={index} className="text-sm font-medium p-3 pt-0">
                        {head.name}
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {serviceTypes?.OTHER?.length ? (
                serviceTypes.OTHER.map((service: any, index: any) => (
                  <tr
                    key={index}
                    className="hover:row_active transition-all delay-100 odd:bg-neutral-100"
                  >
                    <td className="p-3 text-base text-black font-normal">
                      {
                        <div className="popover ml-1">
                          <div className="flex items-center">
                            <div className="mr-1 text-base cursor-pointer font-medium">
                              {service.name}
                            </div>
                            <TooltipCmp message={service.description}>
                              <HiOutlineExclamationCircle className="text-grayText" />
                            </TooltipCmp>
                          </div>
                        </div>
                      }
                    </td>
                    <td className="p-3 text-base text-black font-normal text-right">
                      {isUpdatingPrice ? (
                        <InputText
                          inputName="name"
                          inputType="number"
                          placeholder="Price"
                          value={
                            carrierServicePrice &&
                            carrierServicePrice[service.id]
                              ? carrierServicePrice[service.id]
                              : ''
                          }
                          onChangeFunc={(event: any) => {
                            handleChangePrice(event, service.id);
                          }}
                          shouldFormatNumber={true}
                          disabled={!isUpdatingPrice}
                          className={
                            !isUpdatingPrice
                              ? 'cursor-not-allowed h-1 w-20 pr-0'
                              : 'h-1 w-20 pr-0'
                          }
                        />
                      ) : (
                        <>
                          {carrierServicePrice &&
                          carrierServicePrice[service.id]
                            ? carrierServicePrice[service.id]
                            : '-'}
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="transition-all delay-100 odd:bg-neutral-200">
                  <td
                    className="p-3 text-base text-black text-center  font-normal"
                    colSpan={headCells.length}
                  >
                    {isUpdatingPrice ? 'Please wait...' : 'Data not found'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </AccordionCmp>
      </div>
    </div>
  );
};

export default memo(CarrierServicePrice);
