import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

interface IProps {
  skeletonCount: number;
}

const MessageListLoading = ({ skeletonCount = 4 }: IProps) => (
  <div
    className={` w-full  flex-col justify-start items-start flex transition-all duration-[0.5s]`}
  >
    <div className="w-full flex-1 overflow-auto custom-scrollbar-v3 overflow-x-hidden  ">
      {Array.from({ length: skeletonCount }).map((_, index) => (
        <div
          className="last:border-b-0 border-b border-utilityGray200"
          key={index}
        >
          <div className="m-4 flex gap-1.5 items-center cursor-pointer custom-loading">
            <p className=" w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start"></p>
            <div className="flex-1">
              <h6 className="text-sm textSecondary  font-medium ">Anthropic</h6>
              <p className="text-xs leading-[18px] font-medium line-clamp-1 text-textSecondary ">
                Lets get you signed in All you have to ...
              </p>
            </div>
            <div className="text-xs text-grayLight600 font-normal ">2d ago</div>
            <button
              className="btn no-animation text-xs min-h-[unset] h-auto font-semibold lg:py-[9px] py-2 shadow-sm btn_secondary_black lg:px-[9px] px-2 "
              type="submit"
            >
              <XClose className="w-4 h-4 " />
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default MessageListLoading;
