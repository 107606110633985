import React, { useEffect, useRef, useState } from 'react';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';

const CreditSafeCompanyRow = ({
  data,
  selectedCreditSafeCompanyId,
  setSelectedCreditSafeCompanyId,
}: any) => {
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (nameRef.current) {
      // Check if the text is truncated
      setShowTooltip(nameRef.current.scrollWidth > nameRef.current.clientWidth);
    }
  }, [data?.name]);

  const addressRef = useRef<HTMLParagraphElement>(null);
  const [showAddressTooltip, setShowAddressTooltip] = useState(false);

  useEffect(() => {
    if (addressRef.current) {
      setShowAddressTooltip(
        addressRef.current.scrollWidth > addressRef.current.clientWidth
      );
    }
  }, [data?.address?.simpleValue]);

  return (
    <>
      <td className="px-5 py-4 w-44 max-w-44 min-w-44">
        <div className="flex items-center gap-2">
          <CheckBox
            classes=""
            onChangeFunc={() => setSelectedCreditSafeCompanyId(data?.id)}
            checked={data?.id === selectedCreditSafeCompanyId}
          />
          {data?.id}
        </div>
      </td>

      <td className="px-5 py-4 w-44 max-w-44 min-w-44">
        <span>{data?.id2}</span>
      </td>

      <td className="px-5 py-4 w-[120px] max-w-[120px] min-w-[120px]">
        <span>{data?.country}</span>
      </td>

      <td className="px-5 py-4 w-[130px] max-w-[130px] min-w-[130px]">
        <span>{data?.safeNo}</span>
      </td>

      <td className="px-5 py-4 w-56 max-w-[1px] min-w-56">
        <TooltipCmp message={showTooltip ? data?.name : ''}>
          <p ref={nameRef} className="truncate">
            {data?.name}
          </p>
        </TooltipCmp>
      </td>

      <td className="px-5 py-4 w-56 max-w-[1px] min-w-56">
        <TooltipCmp
          message={showAddressTooltip ? data?.address?.simpleValue : ''}
        >
          <p ref={addressRef} className="truncate">
            {data?.address?.simpleValue}
          </p>
        </TooltipCmp>
      </td>

      <td className="px-5 py-4 w-[150px] max-w-[150px] min-w-[150px]">
        <span>{data?.status}</span>
      </td>

      <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
        <span>{data?.officeType}</span>
      </td>

      <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
        <span>{data?.type}</span>
      </td>

      <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
        <span>{data?.website?.toString()}</span>
      </td>

      <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
        <span>{data?.regNo}</span>
      </td>

      <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
        <TooltipCmp
          message={'have more fields, need some sort of popup design'}
        >
          {data?.headquarters?.name}
        </TooltipCmp>
      </td>

      <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
        <span>{data?.phoneNumbers?.toString()}</span>
      </td>

      <td className="px-5 py-4 w-[150px] max-w-[150px] min-w-[150px]">
        <span>{data?.activityCode}</span>
      </td>

      <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
        <p className="truncate mr-3">{data?.significantItems}</p>
      </td>

      <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
        <span>{data?.fileNo}</span>
      </td>

      <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
        <span>{data?.groupId}</span>
      </td>
    </>
  );
};

export default CreditSafeCompanyRow;
