import React from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import { getFormattedDate } from 'src/utils/CommonFunctions';
import { getDateWithSuffixFormat } from 'src/utils/CommonFunctions';

interface IProps {
  isLoading: boolean;
  currentLegData: Record<string, any>;
  order: any;
}

const RenderOrderSection = ({ isLoading, currentLegData, order }: IProps) => (
  <AccordionCmp
    className="!p-0 !border-0 !bg-transparent"
    contentParentClass="border-t-0 !pt-0"
    title={
      <div className="flex items-center justify-between gap-x-1.5 flex-1">
        <h6 className="text-grayLight900 text-sm font-medium uppercase">
          Details
        </h6>
        <p className="text-primary text-xs leading-[1.5] font-semibold">
          View Details
        </p>
      </div>
    }
    AccordionIconClass="text-primary"
    isOpen={true}
  >
    <>
      <div className="rounded-lg border border-utilityGray200 bg-white shadow-xxs">
        <ul className="flex flex-wrap overflow-hidden [&>li]:relative [&>li]:before:content-[''] [&>li]:before:h-px [&>li]:before:w-[90vw] [&>li]:before:bg-utilityGray200 [&>li]:before:-top-px [&>li]:before:left-0 [&>li]:before:absolute">
          <li className="px-4 py-3 w-1/4 ">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Order Type
            </h6>
            <BadgeCmp
              style="modern"
              type="success"
              mainClassName={`!flex w-fit ${isLoading ? 'custom-loading' : ''}`}
            >
              {currentLegData?.orderType === 'ltl' ? 'LTL' : 'FTL'}
            </BadgeCmp>
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Equipment type
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? currentLegData?.equipmentTypeFullName &&
                  currentLegData?.equipmentTypeFullName != ''
                  ? currentLegData?.equipmentTypeFullName
                  : '-'
                : '13123556'}
            </p>
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Linear Footage
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading ? currentLegData?.linearFootage : '123456'}
            </p>
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              PO Number
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? currentLegData?.poNumber && currentLegData?.poNumber != ''
                  ? currentLegData?.poNumber
                  : '-'
                : '13123556'}
            </p>
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Reference Number
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? currentLegData?.refNumber && currentLegData?.refNumber != ''
                  ? currentLegData?.refNumber
                  : '-'
                : '123456'}
            </p>
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Declared Value
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading ? order?.declaredValue ?? '-' : '123456'}
            </p>
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Pickup Date
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: currentLegData?.expectedPickupTime
                  ? getDateWithSuffixFormat(
                      getFormattedDate(
                        currentLegData?.expectedPickupTime,
                        `ddd MMM Do, YYYY`
                      )
                    )
                  : '-',
              }}
            />
          </li>
          <li className="px-4 py-3 w-1/4">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Delivery Date
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: currentLegData?.expectedEstimatedDeliveryTime
                  ? getDateWithSuffixFormat(
                      getFormattedDate(
                        currentLegData?.expectedEstimatedDeliveryTime,
                        `ddd MMM Do, YYYY`
                      )
                    )
                  : '-',
              }}
            />
          </li>
        </ul>
      </div>
    </>
  </AccordionCmp>
);

export default RenderOrderSection;
