import {
  Recording02,
  PlayCircle,
  PhoneCall01,
  Plus,
  ArrowDownLeft,
  ArrowNarrowUpLeft,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
// import { AuthContext } from 'src/context/AuthContext';
import {
  getFormattedDate,
  getMinutesAndSeconds,
  getShortName,
  usePhone,
} from 'src/utils/CommonFunctions';

import MissedIcon from '../../../assets/img/arrow-down-01.svg';
import magnifier from '../../../assets/img/radar/magnifying-glass.svg';

// import checkBoxGreen from '../../../assets/img/radar/checkbox-green.svg';
// import cross from '../../../assets/img/radar/cross-red.svg';

const CallLogRow = ({
  data,
  currentIndex,
  handleCall,
  setAction,
  action,
}: any) => {
  const { getFormatPhoneWithPlus } = usePhone();
  // const { currentUser } = useContext(AuthContext);

  const handleAddContact = (number: string) => {
    setAction({
      mode: 'addNewContact',
      data: {
        ...action.data,
        number,
      },
    });
  };

  return (
    <>
      <td className="px-5 py-4 w-48 min-w-48 max-w-48">
        <div className="flex items-center gap-3">
          <div className="flex-none">
            {data.contactImage ? (
              <img
                className="table-profile-icn-circle-xl"
                src={data.contactImageUrl + data.contactImage}
                alt=""
                title=""
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(`${data.contactName ?? 'Unknown'}`)}
              </div>
            )}
          </div>
          <div className="truncate max-w-44">
            <h6 className="text-grayLight900 font-medium truncate">
              {data.contactName ?? 'Unknown'}
            </h6>
            <p className="truncate mt-[1px] text-gray500 font-normal">
              {/* {data?.contactNumber
              ? parsePhoneNumberFromString(
                  String(data.contactNumber)
                )?.formatInternational()
              : 'N/A'} */}
              {getFormatPhoneWithPlus(data.contactNumber)}
            </p>
          </div>
        </div>
      </td>

      {/* {(currentUser?.role?.name === 'admin' ||
        currentUser?.role?.name === 'manager') && (
        <td className="px-5 py-4 w-48 min-w-48 max-w-48">
          <div className="flex items-center gap-3">
            <div className="flex-none">
              {data.userImage ? (
                <img
                  className="table-profile-icn-circle-xl"
                  src={data.userImageUrl + data.userImage}
                />
              ) : (
                <div className="table-profile-customer-circle-xl">
                  {getShortName(
                    `${
                      data.userFirstName && data.userLastName
                        ? `${data.userFirstName} ${data.userLastName}`
                        : 'Unknown'
                    }`
                  )}
                </div>
              )}
            </div>
            <div className="truncate max-w-44">
              <h6 className="text-grayLight900 font-medium truncate">
                {`${
                  data.userFirstName && data.userLastName
                    ? `${data.userFirstName} ${data.userLastName}`
                    : 'Unknown'
                }`}
              </h6>
              <p className="truncate mt-[1px] text-gray500 font-normal">
                {data?.userPhoneNumber
                ? parsePhoneNumberFromString(
                    String(data.userPhoneNumber)
                  )?.formatInternational()
                : 'N/A'}
                {getFormatPhoneWithPlus(data.userPhoneNumber)}
              </p>
            </div>
          </div>
        </td>
      )} */}

      <td className="px-5 py-4 w-56 min-w-56 max-w-56">
        <p className="truncate text-textSecondary font-medium">
          {data?.carrierName || data?.customerName || '-'}
        </p>
      </td>

      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        {data.status === 'ongoing' ? (
          <div
            className={`flex items-center gap-1 ${
              currentIndex === 1
                ? 'border border-borderPrimary rounded-md p-1 w-fit'
                : ''
            }`}
          >
            <Recording02 className="cursor-pointer w-4 h-4 text-primary" />
            <p className="text-primary">{data.status}</p>
          </div>
        ) : data.status === 'no-answer' ? (
          data.callType === 'outbound' ? (
            <div className="flex items-center gap-1">
              {/* <ArrowNarrowUpLeft className="w-4 h-4 text-fgErrorPrimary" /> */}
              <img src={MissedIcon} alt="" className="w-4 h-4" />
              <p className="text-fgErrorPrimary">Missed</p>
            </div>
          ) : data.callType === 'incoming' ? (
            <div className="flex items-center gap-1">
              <img src={MissedIcon} alt="" className="w-4 h-4" />
              <p className="text-fgErrorPrimary">Missed</p>
            </div>
          ) : null
        ) : data.callType === 'incoming' ? (
          <div className="flex items-center gap-1">
            <ArrowDownLeft className="w-4 h-4 text-gray400" />
            <p className="text-grayLight600">Incoming</p>
          </div>
        ) : data.callType === 'outbound' ? (
          <div className="flex items-center gap-1 text-gray400">
            <ArrowNarrowUpLeft className="w-4 h-4" />
            <p className="text-grayLight600">Outgoing</p>
          </div>
        ) : data.callType === 'missed' &&
          data.callType !== 'inbound' &&
          data.callType !== 'outbound' ? (
          <div className="flex items-center gap-1">
            <img src={MissedIcon} alt="" className="w-4 h-4" />
            <p className="text-fgErrorPrimary">Missed</p>
          </div>
        ) : null}
      </td>

      <td className="px-5 py-4 w-20 min-w-20 max-w-24">
        <div className="flex items-center gap-1">
          <PlayCircle className="w-4 h-4 text-gray400" />
          <p className="">{getMinutesAndSeconds(data.duration)}</p>
        </div>
      </td>

      <td className="px-5 py-4 w-44 min-w-44 max-w-44">
        <p className="truncate">
          {getFormattedDate(data?.createdAt, 'MMM Do, YYYY, HH:mm', true, true)}
        </p>
      </td>

      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <div className="flex gap-2">
          {data?.sentiment === 'positive' && (
            <BadgeCmp type="success" style="modern" isHidePillDot>
              <span className="mr-1">😀</span>
              Positive
            </BadgeCmp>
          )}

          {data?.sentiment === 'neutral' && (
            <BadgeCmp type="success" style="modern" isHidePillDot>
              <img src={magnifier} className="mr-1" />
              Prospect
            </BadgeCmp>
          )}
          {data?.sentiment === 'negative' && (
            <BadgeCmp type="success" style="modern" isHidePillDot>
              <span className="mr-1">😔</span>
              Negative
            </BadgeCmp>
          )}
          {data?.sentiment === null && (
            <BadgeCmp type="success" style="modern" isHidePillDot>
              <img src={magnifier} className="mr-1" />
              Prospect
            </BadgeCmp>
          )}
        </div>
      </td>

      <td className="px-5 py-4 w-[84px] min-w-[84px] max-w-[84px]">
        <div className="flex items-center justify-end gap-3 text-gray400">
          {!data.contactName && !data.contactImage && (
            <Plus
              className={'cursor-pointer w-4 h-4'}
              // className={`cursor-pointer w-4 h-4 ${
              //   currentIndex === 0 ? 'invisible' : ''
              // }`}
              onClick={(e) => {
                e.stopPropagation();
                handleAddContact(data.contactNumber);
              }}
            />
          )}

          <PhoneCall01
            className="cursor-pointer w-4 h-4"
            onClick={(e) => {
              e.stopPropagation();
              handleCall(data);
            }}
          />
        </div>
      </td>
    </>
  );
};

export default CallLogRow;
