import {
  Mail02,
  Phone,
  // SearchLg,
  // PhoneCall01,
  // Recording02,
  // Recording02,
  Send01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import { ThreadsCommunications } from 'src/services/CallCenterService';
import { getShortName } from 'src/utils/CommonFunctions';

// import outlook from '../../../assets/img/outlook.svg';

import ThreadCommunicationCalls from './ThreadCommunicationCalls';
// import ThreadCommunicationCallsInprogress from './ThreadCommunicationCallsInprogress';
// import ThreadCommunicationEmails from './ThreadCommunicationEmails';
// import ThreadCommunicationIncomingCalls from './ThreadCommunicationIncomingCalls';
import ThreadEmailModal from './ThreadEmailModal';
import ThreadCommunicationLoader from './ThreadLoader/ThreadCommunicationLoader';

const ThreadCommunication = ({
  setIsShowRightPanel,
  threads,
  onHandleThread,
  contactDetailsId,
}: any) => {
  console.log(
    '⭐️ ~ ThreadCommunication ~ setIsShowRightPanel:',
    setIsShowRightPanel
  );

  const [threadsCommunication, setThreadsCommunication] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailBody, setEmailBody] = useState<any>('');
  const [error, setError] = useState<boolean>(false);
  console.log('⭐️ ~ setError:', setError);
  const [isShowEmailModal, setIsShowEmailModal] = useState<boolean>(false);
  const contactId = threads.contactId;

  console.log('threadsCommunication', threadsCommunication);
  console.log('isLoading', isLoading);
  // const [isShowSummary, setIsShowSummary] = useState(false);
  // console.log('⭐️ ~ ThreadCommunication ~ summary:', summary);

  const getThreadsCommunicationData = (signal?: any) => {
    setThreadsCommunication([]);
    setIsLoading(true);

    ThreadsCommunications(contactId, signal)
      .then((response: any) => {
        if (response.data) {
          setThreadsCommunication(response.data);
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setThreadsCommunication([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getThreadsCommunicationData(signal);

    return () => {
      controller.abort();
    };
  }, [threads]);

  return (
    <>
      {isLoading ? (
        <ThreadCommunicationLoader />
      ) : (
        <>
          <div className="flex flex-wrap items-center gap-2 w-full justify-between border-b border-utilityGray200 px-5 py-3.5">
            {(threads.customerName || threads.carrierName) &&
              (threads.carrierImage || threads.customerImage ? (
                <img
                  src={threads.carrierImage || threads.customerImage}
                  className="w-9 h-9 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  alt="Profile"
                />
              ) : (
                <div className="w-9 h-9 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none">
                  <span className="text-primary lg:text-xl text-sm font-semibold">
                    {getShortName(
                      threads.customerName || threads.carrierName || ''
                    )}
                  </span>
                </div>
              ))}
            <h6 className="flex-1 text-base font-semibold text-grayLight900">
              {threads.customerName || threads.carrierName}
            </h6>

            <div className="flex gap-3">
              {/* onClick remove "w-9 pr-0.5" class from classname */}
              {/* <InputText
            parentClassName=""
            inputName="search"
            placeholder="Search"
            className="pl-8 cursor-pointer w-9 pr-0.5"
            icon={
              <SearchLg className="absolute -translate-y-2/4 top-2/4 left-[9px] text-grayText h-4 w-4 cursor-pointer" />
            }
          /> */}
              <ButtonCmp
                icon={<Phone className="w-4 h-4" />}
                className="btn_secondary_black lg:px-[9px] px-2"
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                icon={<Mail02 className="w-4 h-4" />}
                className="btn_secondary_black lg:px-[9px] px-2"
              >
                <></>
              </ButtonCmp>
            </div>
          </div>

          <div className="bg-gray25 flex-1 w-full p-5 overflow-y-auto custom-scrollbar-v2 flex flex-col">
            <ul className="w-full mt-auto flex flex-col gap-y-6">
              {/* <li className="flex items-center my-4">
            <span className="h-[1px] bg-utilityGray200 w-full"></span>
            <p className="text-grayLight600 text-xs text-center px-2 text-nowrap">
              November 11th, 2024
            </p>
            <span className="h-[1px] bg-utilityGray200 w-full"></span>
          </li> */}
              {/* Call Ended Left */}

              {/* CALL RECORDING CARD START */}
              {threadsCommunication.map((thread?: any, index?: number) => (
                <>
                  <ThreadCommunicationCalls
                    key={index}
                    thread={thread}
                    onViewTranscript={onHandleThread}
                    onViewContactDetails={contactDetailsId}
                  />
                </>
              ))}
              {/* CALL RECORDING CARD END */}

              {/* CHAT BOX CARD START */}
              {/* <ThreadCommunicationEmails /> */}
              {/* CHAT BOX CARD END */}

              {/* CALL INPROGRESS CARD START */}
              {/* <ThreadCommunicationCallsInprogress /> */}
              {/* CALL INPROGRESS CARD END */}

              {/* INCOMING CALL CARD START */}
              {/* <ThreadCommunicationIncomingCalls /> */}
              {/* INCOMING CALL CARD END */}

              {/* <div className="flex items-center mt-1"> */}

              {/* <div className="p-2 bg-utilityGray100 rounded-lg flex items-center gap-3">
                  <PlayCircle className="text-gray500 cursor-pointer" />
                  <div className="items-center flex flex-1">
                    <div className="rounded-full bg-primary w-2 h-2"></div>
                    <div className="border border-borderPrimary flex-1"></div>
                  </div>
                  <p className="text-xs font-medium text-grayLight900">
                    0:05
                  </p>
                  <VolumeMax className="text-gray500" />
                </div> */}
              {/* <div className="p-2 bg-utilityGray100 rounded-lg flex items-center gap-3">
                  {isRecordingLoading ? (
                    <div className="animate-pulse w-8 h-8 rounded-full bg-gray-300" />
                  ) : (
                    <button
                      onClick={handlePlayPause}
                      disabled={isRecordingLoading}
                      className="focus:outline-none"
                    >
                      {isPlaying ? (
                        <PauseCircle className="text-gray500 cursor-pointer" />
                      ) : (
                        <PlayCircle className="text-gray500 cursor-pointer" />
                      )}
                    </button>
                  )}

                  <div className="items-center flex flex-1">
                    <div
                      ref={progressRef}
                      className="border border-borderPrimary flex-1 relative h-2 cursor-pointer"
                      onMouseDown={handleMouseDown}
                    >
                      <div
                        className="absolute bg-primary h-full transition-all duration-300"
                        style={{
                          width: `${
                            duration ? (audioTime / duration) * 100 : 0
                          }%`,
                        }}
                      />
                      <div
                        className="absolute top-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-primary -ml-1.5"
                        style={{
                          left: `${
                            duration ? (audioTime / duration) * 100 : 0
                          }%`,
                        }}
                      />
                    </div>
                  </div>

                  <p className="text-xs font-medium text-grayLight900">
                    {Math.floor(audioTime / 60)}:
                    {Math.floor(audioTime % 60)
                      .toString()
                      .padStart(2, '0')}
                  </p>
                  <VolumeMax className="text-gray500" />

                  <audio
                    ref={audioRef}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onEnded={() => setIsPlaying(false)}
                  />

                  {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                  )}
                </div> */}
              {/* </div> */}
              {/* <div className="flex items-center gap-3 mt-2">
                <p className="text-xs font-medium text-grayLight900">
                  {moment.utc(350 * 1000).format('mm:ss')}
                </p>
              </div> */}
              {/* <div className="flex items-center mt-1">
                <p className="text-grayLight600 font-normal text-xs">
                  12:40am
                </p>
              </div> */}

              {/* Call Summary */}

              {/* Incoming call */}
              {/* <li className="flex gap-3 w-[400px]">
            <div>
              <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                <img src="" alt="" className="" />
                <p className="mb-0 text-sm">dc</p>
              </div>
              <div>
                <p className="text-sm text-grayLight900 font-medium">
                  Incoming call...
                </p>
                <p className="text-xs text-grayLight600 font-normal">
                  Not answered yet
                </p>
              </div>
              <ButtonCmp className="btn_primary bg-danger600 border-danger600 hover:bg-danger700 hover:border-danger700">
                Reject
              </ButtonCmp>
              <ButtonCmp className="btn_primary bg-fgSuccessPrimary border-fgSuccessPrimary hover:bg-success700 hover:border-success700">
                Accept
              </ButtonCmp>
            </div>
          </li> */}

              {/* Call in progress */}
              {/* <li className="flex">
            <div className="flex gap-3 w-full max-w-[275px]">
              <div>
                <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                  <img src="" alt="" className="" />
                  <p className="mb-0 text-sm">dc</p>
                </div>
              </div>
              <div className="bg-gray50 px-4 py-3 border border-utilityGray200 flex gap-3 rounded-xl flex-1">
                <div className="bg-fgSuccessPrimary rounded-full flex items-center justify-center w-10 h-10">
                  <Recording02 className="text-white" />
                </div>
                <div>
                  <p className="text-sm text-grayLight900 font-medium">
                    Call in progress...
                  </p>
                  <p className="text-xs text-grayLight600 font-normal">
                    You answered
                  </p>
                </div>
              </div>
            </div>
          </li> */}

              {/* <li className="flex justify-end gap-3 cursor-pointer ml-auto max-w-[60%]">
                <div className="min-w-[42%] space-y-1 max-w-[440px]">
                  <div className="flex justify-between gap-x-2">
                    <div className="flex gap-x-2.5">
                      <img
                        src={outlook}
                        className="w-4 object-contain"
                        alt=""
                      />
                      <p className="text-grayLight600 font-normal text-xs">
                        Open in Outlook
                      </p>
                    </div>
                    <p className="text-grayLight600 font-normal text-xs">
                      Drew Cano
                    </p>
                  </div>
                  CHAT BOX CARD START
                  <div className="bg-white flex flex-col text-grayLight900 text-xs font-normal p-4 rounded-xl rounded-tr-none border border-utilityGray200 shadow-xs gap-1.5 relative [&:hover>.hover-emoji]:!flex">
                    <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                      <div className="text-grayText w-8">From</div>
                      <div className="flex items-center">
                        <figure className="w-4 h-4 rounded-full border border-white flex-none">
                          <img
                            src="https://storage.googleapis.com/western-alliance-gcp/273e5a26-ce06-421f-936e-fb71ad05c48d.png"
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </figure>
                        <p className="text-grayLight900 font-medium ml-1 break-all">
                          kylaclay@walhq.com
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                      <div className="text-grayText w-8">To</div>
                      <div className="flex items-center">
                        <figure className="w-4 h-4 rounded-full border border-white flex-none">
                          <img
                            src="https://storage.googleapis.com/western-alliance-gcp/2fc09b99-de6a-4929-bb53-934808d2abe5.png"
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </figure>
                        <p className="text-grayLight900 font-medium ml-1 break-all">
                          demiwilkinson@walhq.com
                        </p>
                      </div>
                    </div>
                    <div className="flex py-1.5 gap-1.5 ">
                      <div className=" font-medium">Subject:</div>
                      <div className="flex items-center text-grayLight600">
                        Order Tracking Information for Your Shipment
                      </div>
                    </div>
                    <p className="">Dear Demi Wilkinson</p>
                    <p className="">
                      We are excited to inform you that your order #WAL-PDFA-859
                      has been processed and is on its way!...
                    </p>
                    <div className="rounded-xl border border-utilityGray200 flex gap-2 px-2 py-1 items-center bg-white absolute left-4 -bottom-[13px] hidden hover-emoji">
                      <div className="text-xs cursor-pointer">👍</div>
                      <div className="text-xs cursor-pointer">❤️</div>
                      <div className="text-xs cursor-pointer">👌</div>
                      <div className="text-xs cursor-pointer">🙂</div>
                      <div className="text-xs cursor-pointer">😅</div>
                    </div>
                  </div>
                  CHAT BOX CARD END chat block footer
                  <div className="flex justify-between items-center gap-x-2">
                    <div className="flex gap-1 items-center">
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        👍
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        ❤️
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        👌
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        🙂
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        😅
                      </div>
                    </div>
                    <p className="text-grayLight600 font-normal text-xs">
                      12:33am
                    </p>
                  </div>
                </div>

                <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                  <img src="" alt="" className="" />
                  <p className="mb-0 text-sm">dc</p>
                </div>
              </li> */}
            </ul>
            {/* </div> */}
          </div>
          <div>
            <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
              <InputText
                inputName="email"
                placeholder="Email"
                id="chatInput"
                className=""
                value={emailBody}
                onChangeFunc={(e: any) => {
                  setEmailBody(e.target.value);
                }}
                parentClassName="flex-1"
                errorText={error ? 'Email body is required!' : ''}
              />

              <ButtonCmp
                className="btn_primary lg:!px-[9px] !px-2"
                // onClick={() => {
                //   if (emailBody !== '') {
                //     setIsShowEmailModal(true);
                //     setError(false);
                //   } else {
                //     setError(true);
                //   }
                // }}
              >
                <Send01 className="w-4 h-4" />
              </ButtonCmp>
            </div>
          </div>
          {isShowEmailModal && (
            <ThreadEmailModal
              contactId={contactId}
              emailDetails={emailBody}
              handleClose={() => {
                setIsShowEmailModal(false);
                setEmailBody('');
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ThreadCommunication;
