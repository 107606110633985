import { yupResolver } from '@hookform/resolvers/yup';
import { Save01 } from '@untitled-ui/icons-react/build/cjs';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import ConfirmModal from 'src/components/ConfirmModal';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import StepBar from 'src/components/StepBar/StepBar';
import { FOOT, KGS, LBS, MEASUREMENT, TABLE_IDS } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { listService } from 'src/services/CommonService';
import { CustomersAllList } from 'src/services/CustomerService';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';
import {
  getOrder,
  saveOrderAsDraft,
  updateOrder,
} from 'src/services/OrderService';
import { listShippers } from 'src/services/ShipperService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import OrderHeader from '../Common/OrderHeader';
import RadarAndInternalChat from '../Common/RadarAndInternalChat';
import { constructOrderParam, STEPS } from '../order.constant';
import {
  defualtOrderValue,
  initAction,
  processOrderData,
  step1Schema,
  step3Schema,
  step4Schema,
} from '../order.interface';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

export const OrderContext = createContext({});

const orderDimensionInitAction = {
  tailgate: false,
  unusual: false,
};

const OrderDetails = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const location = useLocation();
  const timeFilter = location.state?.timeFilter;

  const [action, setAction] = useState(initAction);
  const [orderDimensionAction, setOrderDimensionAction] = useState(
    orderDimensionInitAction
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [stepperTitle, setStepperTitle] = useState('Add Address Details');
  const [stepperDescription, setStepperDescription] = useState(
    'Please provide the addresses for pickup and delivery.'
  );
  const [salesPrice, setSalesPrice] = useState<any>([]);
  const [carrierPrice, setCarrierPrice] = useState<any>([]);
  const [serviceList, setServiceList] = useState([]);

  const [order, setOrder] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] = useState(false);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [isTailgate, setIsTailgate] = useState<any>([]);
  const [isAllowOverWeigth, setIsAllowOverWeigth] = useState(false);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);

  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    consigneeAddress: true,
    shipperCompanyName: true,
    consigneeCompanyName: true,
    pickupDate: true,
    deliveryDate: true,
  });
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);

  const [currentLegData, setCurrentLegData] = useState<any>(null);
  const [shipperAddress, setShipperAddress] = useState<any>(null);
  const [consigneeAddress, setConsigneeAddress] = useState<any>(null);
  const [shipperFullAddress, setShipperFullAddress] = useState<any>(null);
  const [consigneeFullAddress, setConsigneeFullAddress] = useState<any>(null);
  const [isShowSaveAsDraftModal, setIsShowSaveAsDraftModal] = useState(false);
  const [isShowChatSideBar, setIsShowChatSideBar] = useState(true);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);

  let orderSchema = step1Schema;

  if (currentStep === 1) {
    orderSchema = step1Schema;
  } else if (currentStep === 3) {
    orderSchema = step3Schema as unknown as typeof step1Schema;
  } else if (currentStep === 4) {
    orderSchema = step4Schema as unknown as typeof step1Schema;
  }

  const methods = useForm({
    resolver: yupResolver(orderSchema) as Resolver<any>,
    defaultValues: defualtOrderValue,
  });

  const { handleSubmit, reset, getValues, trigger, clearErrors } = methods;

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getOrderData = () => {
    setIsLoading(true);
    const param = { id };

    getOrder(param)
      .then((response) => {
        if (response?.data) {
          const masterOrder = response?.data;

          let orderLegsData: any = [];

          if (masterOrder?.orderLegs) {
            orderLegsData = masterOrder?.orderLegs;
          }

          setOrder({ ...masterOrder, orderLegs: orderLegsData });

          orderLegsData.reduce((acc: any, subOrder: any, index: number) => {
            if (index === 0) {
              const resetOrderData = processOrderData({
                ...subOrder,
                orderType: masterOrder?.orderType,
                equipmentType: masterOrder?.equipmentType,
                equipmentTypeFullName: masterOrder?.equipmentTypeFullName,
              });

              setCurrentLegData(resetOrderData);
              reset({
                ...resetOrderData,
                declaredValue: masterOrder?.declaredValue,
                declaredValueUnit: masterOrder?.declaredValueUnit ?? 'CAD',
                totalWeight: masterOrder?.totalWeight,
              });

              setWeightMeasurement(resetOrderData?.weightMeasurement);
              setLengthMeasurement(resetOrderData?.lengthMeasurement);

              setSalesPrice(subOrder?.salesPrice);
              setCarrierPrice(resetOrderData?.carrierPrice);
            }

            acc[subOrder.legId] = processOrderData(subOrder);

            return acc;
          }, {});

          // console.log('childOrderData', childOrderData);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          navigate(`${ROUTES.ORDERS}`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getShipper = () => {
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => {
        // setIsShipperLoading(false)
      })
      .catch(console.error);
  };

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList()
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  const getListService = () => {
    listService({})
      .then((response: any) => {
        setServiceList(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(console.log);
  };

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);

    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  useEffect(() => {
    getOrderData();
    getShipper();
    getCustomerList();
    getListService();
    getVehicleTypeOptions();
  }, []);

  // const handleServiceActionType = (actionKey: string) => {
  //   setOrderDimensionAction((old) => ({ ...old, [actionKey]: true }));
  // };

  const onSubmit = (data: any) => {
    const currentData = { ...currentLegData, ...data };

    const param: any = {
      shipperCompanyName: currentData?.shipper?.companyName,
      shipperContactName: currentData?.shipper?.contactName,
      shipperContactNumber: currentData?.shipper?.phoneNumber,
      shipperEmail: currentData?.shipper?.email,
      id: currentData?.id,
      shipperId: currentData?.shipper?.id,
      shipperFullAddress: currentData?.shipper?.shipperAddress?.fullAddress,
      shipperAddress1: currentData?.shipper?.shipperAddress?.address1,
      shipperCity: currentData?.shipper?.shipperAddress?.city,
      shipperState: currentData?.shipper?.shipperAddress?.state,
      shipperCountry: currentData?.shipper?.shipperAddress?.country,
      shipperPostal: currentData?.shipper?.shipperAddress?.postal,
      shipperLatitude: currentData?.shipper?.shipperAddress?.latitude,
      shipperLongitude: currentData?.shipper?.shipperAddress?.longitude,
      shipperStateCode: currentData?.shipper?.shipperAddress?.stateCode,
      shipperCountryCode: currentData?.shipper?.shipperAddress?.countryCode,
      consigneeId: currentData?.consignee?.id,
      consigneeCompanyName: currentData?.consignee?.companyName,
      consigneeContactName: currentData?.consignee?.contactName,
      consigneeContactNumber: currentData?.consignee?.phoneNumber,
      consigneeEmail: currentData?.consignee?.email,
      consigneeFullAddress:
        currentData?.consignee?.consigneeAddress?.fullAddress,
      consigneeAddress1: currentData?.consignee?.consigneeAddress?.address1,
      consigneeCity: currentData?.consignee?.consigneeAddress?.city,
      consigneeState: currentData?.consignee?.consigneeAddress?.state,
      consigneeCountry: currentData?.consignee?.consigneeAddress?.country,
      consigneePostal: currentData?.consignee?.consigneeAddress?.postal,
      consigneeLatitude: currentData?.consignee?.consigneeAddress?.latitude,
      consigneeLongitude: currentData?.consignee?.consigneeAddress?.longitude,
      consigneeStateCode: currentData?.consignee?.consigneeAddress?.stateCode,
      consigneeCountryCode:
        currentData?.consignee?.consigneeAddress?.countryCode,
      order_dimensions: currentData?.order_dimensions,
      declaredValue: currentData?.declaredValue,
      totalWeight: currentData?.totalWeight,
      declaredValueUnit: currentData?.declaredValueUnit || 'CAD',
      equipmentType: currentData?.equipmentType,
      equipmentTypeFullName: currentData?.equipmentTypeFullName,
      linearFootage: currentData?.linearFootage,
      orderType: currentData?.orderType,
      poNumber: currentData?.poNumber,
      refNumber: currentData?.refNumber,
      specialNotes: currentData?.specialNotes,
      weightMeasurement: weightMeasurement,
      lengthMeasurement: lengthMeasurement,
      additionalServices: currentData?.additionalServices,
      carrierPrice: currentData?.carrierPrice,
      salesPrice: currentData?.salesPrice,
      masterOrderId: +id,
    };

    // const legData: any = [];
    // let isAnyLegOverweight = false;
    // let isAnyLegOversized = false;

    // Object.keys(updatedTabValues).forEach((key) => {
    //   const leg = tabValues[key];
    //   let totalWeight: any = 0;
    //   let weightMeasurementValue = '';
    //   let isItemLengthOver = false;

    //   leg?.order_dimensions?.forEach((form: any) => {
    //     totalWeight += form.totalWeight;
    //     weightMeasurementValue = form.weightMeasurement;

    //     const maxLengthOver =
    //       lengthMeasurement === MEASUREMENT.LENGTH1.value
    //         ? LBS.ITEM_LENGTH_OVER
    //         : KGS.ITEM_LENGTH_OVER;

    //     if (form.length >= maxLengthOver) {
    //       isItemLengthOver = true;
    //     }
    //   });

    //   const isTotalWeightOverBool =
    //     weightMeasurementValue === MEASUREMENT.WEIGHT1.value
    //       ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
    //       : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;

    //   if (isTotalWeightOverBool && !isAnyLegOverweight) {
    //     isAnyLegOverweight = true;
    //   }

    //   if (isItemLengthOver && !isAnyLegOversized) {
    //     isAnyLegOversized = true;
    //   }

    //   // if (key !== masterOrderKey) {
    //   legData.push({
    //     orderId: key,
    //     ...leg,
    //   });
    //   // }
    // });

    // const finalData = {
    //   legs: updatedTabValues,
    // };

    // let totalWeight: number | string = 0;
    // let weightMeasurementValue = '';
    // let isItemLengthOver = false;

    // data?.order_dimensions.forEach((form: any) => {
    //   totalWeight += form.totalWeight;
    //   weightMeasurementValue = form.weightMeasurement;
    // });

    // data?.order_dimensions.forEach((form: any) => {
    //   const maxLengthOver =
    //     lengthMeasurement === MEASUREMENT.LENGTH1.value
    //       ? LBS.ITEM_LENGTH_OVER
    //       : KGS.ITEM_LENGTH_OVER;

    //   if (form.length >= maxLengthOver) {
    //     isItemLengthOver = true;
    //   }
    // });

    // totalWeight = totalWeight.toFixed();

    // const isTotalWeightOverBool =
    //   weightMeasurementValue === MEASUREMENT.WEIGHT1.value
    //     ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
    //     : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;

    // if (
    //   !isAllowOverWeigth &&
    //   isTailgate.length &&
    //   (isAnyLegOverweight || isAnyLegOversized)
    // ) {
    //   handleServiceActionType('tailgate');

    //   return;
    // }

    setIsSubmitLoading(true);

    updateOrder(param)
      .then((response) => {
        if (response) {
          if (order?.orderStatus === 'draft') {
            localStorage.setItem(
              TABLE_IDS.ORDER_LIST,
              JSON.stringify({ orderType: 'new-order', timeFilter: timeFilter })
            );

            navigate(`${ROUTES.ORDERS}`);
          } else {
            navigate(-1);
          }
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setOrderDimensionAction(orderDimensionInitAction);

      if (status) {
        setOrderDimensionAction(orderDimensionInitAction);
        setIsAllowOverWeigth(true);
      }
    },
    [orderDimensionAction]
  );

  useEffect(() => {
    if (isAllowOverWeigth) {
      handleSubmit(onSubmit)();
    }
  }, [isAllowOverWeigth]);

  const handleSaveAsDraft = async () => {
    const formData = getValues();
    const fieldsToValidate: string[] = [
      'shipper.companyName',
      'consignee.companyName',
    ];

    clearErrors();

    const fieldsToCheck = [
      { path: 'shipper.email', value: formData.shipper?.email },
      { path: 'shipper.phoneNumber', value: formData.shipper?.phoneNumber },
      { path: 'consignee.email', value: formData.consignee?.email },
      { path: 'consignee.phoneNumber', value: formData.consignee?.phoneNumber },
    ];

    fieldsToCheck.forEach((field) => {
      if (field.value?.trim()) {
        fieldsToValidate.push(field.path);
      }
    });

    if (shipperAddress) {
      fieldsToValidate.push('shipper.shipperAddress.fullAddress');
    }

    if (consigneeAddress) {
      fieldsToValidate.push('consignee.consigneeAddress.fullAddress');
    }

    const validationResults = await trigger(fieldsToValidate);

    if (!validationResults) {
      return;
    }

    setIsShowSaveAsDraftModal(true);
  };

  const handleSubmitSaveAsDraft = () => {
    const formData = getValues();

    const draftParam = constructOrderParam(
      formData,
      currentLegData?.id,
      weightMeasurement,
      lengthMeasurement
    );

    setIsSaveAsDraftLoading(true);

    saveOrderAsDraft({ ...draftParam, masterOrderId: id })
      .then((response) => {
        if (response) {
          localStorage.setItem(
            TABLE_IDS.ORDER_LIST,
            JSON.stringify('new-order')
          );

          navigate(`${ROUTES.ORDERS}`);
          setIsShowSaveAsDraftModal(false);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsSaveAsDraftLoading(false);
      });
  };

  const handleCurrentStep = async (stepType: string) => {
    if (stepType !== 'back') {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      if (currentStep === 1) {
        if (
          !(isValidAddress?.shipperAddress && isValidAddress?.consigneeAddress)
        ) {
          return;
        }
      } else if (currentStep === 2) {
        if (
          currentLegData?.expectedPickupTime === null ||
          currentLegData?.expectedEstimatedDeliveryTime === null
        ) {
          setIsValidAddress((old) => ({
            ...old,
            pickupDate: !!currentLegData?.expectedPickupTime,
            deliveryDate: !!currentLegData?.expectedEstimatedDeliveryTime,
          }));

          return;
        }
      }
    }

    if (stepType === 'back') {
      if (currentStep === 2) {
        setIsValidAddress((old) => ({
          ...old,
          pickupDate: true,
          deliveryDate: true,
        }));
      }
    }

    const steps = [
      {
        title: 'Add Address Details',
        description: 'Please provide the addresses for pickup and delivery.',
      },
      {
        title: 'Add Pickup and Delivery Dates',
        description:
          'Pick dates for when the pickup and delivery should occur.',
      },
      {
        title: 'Add Order Details',
        description: 'Please add the details of your order before proceeding.',
      },
      {
        title: 'Add Carrier & Sale Price',
        description:
          'Select the carrier and set the sale price for your order.',
      },
    ];

    let newCurrentStepCount =
      stepType === 'back' ? currentStep - 1 : currentStep + 1;

    if (newCurrentStepCount === 5) {
      handleSubmit(onSubmit)();
    } else {
      setCurrentStep(newCurrentStepCount);

      if (newCurrentStepCount > 0 && newCurrentStepCount <= steps.length) {
        const { title, description } = steps[newCurrentStepCount - 1];
        setStepperTitle(title);
        setStepperDescription(description);
      }
    }
  };

  return (
    <OrderContext.Provider
      value={{
        isTailgate,
        setIsTailgate,
        orderDimensionAction,
        setOrderDimensionAction,
        order,
        setOrder,
        setWeightMeasurement,
        setLengthMeasurement,
        lengthMeasurement,
        weightMeasurement,
        customerList,
        isCustomerLoading,
        isValidAddress,
        setIsValidAddress,
        shippers,
        consignees,
        shipperOptions,
        consigneeOptions,
        currentLegData,
        setCurrentLegData,
        serviceList,
        setServiceList,
        salesPrice,
        setSalesPrice,
        carrierPrice,
        setCarrierPrice,
        shipperFullAddress,
        setShipperFullAddress,
        consigneeFullAddress,
        setConsigneeFullAddress,
        isConsigneeBtnDisable,
        setIsConsigneeBtnDisable,
        isShipperBtnDisable,
        setIsShipperBtnDisable,
        getShipper,
        equipmentTypeOptions,
        isEquipmentTypeLoading,
        // setTabValues,
        // // tabValues,
        // activeLeg,
      }}
    >
      <PageSectionLayout
        header={
          <OrderHeader
            id={id}
            isLoading={isLoading}
            order={order}
            navigate={navigate}
            isDiscardButton={true}
            isShowActionButton={false}
            setIsShowChatSideBar={setIsShowChatSideBar}
          />
        }
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
      >
        <FormProvider {...methods}>
          <div className="flex h-full w-full">
            <div className="flex-1 xl:pr-8 py-5 flex flex-col gap-y-4">
              <div className="flex items-center flex-wrap gap-x-2">
                <div className="flex-1">
                  <h6 className="text-grayLight900 text-base font-semibold">
                    {stepperTitle}
                  </h6>
                  <p className="text-grayLight600 text-sm font-medium">
                    {stepperDescription}
                  </p>
                </div>
                <div className="flex flex-wrap gap-2">
                  {order?.orderStatus === 'draft' && (
                    <ButtonCmp
                      className="btn-primary-light"
                      onClick={handleSaveAsDraft}
                      disabled={isSubmitLoading || isSaveAsDraftLoading}
                    >
                      Save As Draft
                    </ButtonCmp>
                  )}
                  <ButtonCmp
                    className="btn_secondary_black min-w-[80px]"
                    onClick={() => handleCurrentStep('back')}
                    disabled={currentStep === 1 || isSubmitLoading}
                  >
                    Back
                  </ButtonCmp>
                  <ButtonCmp
                    className="btn-outline-primary min-w-[80px]"
                    onClick={() => handleCurrentStep('next')}
                    loading={isSubmitLoading}
                    disabled={isSubmitLoading}
                  >
                    {currentStep === 4
                      ? order?.orderStatus === 'draft'
                        ? 'Create Order'
                        : 'Save Order'
                      : 'Next'}
                  </ButtonCmp>
                </div>
              </div>
              <StepBar
                steps={STEPS}
                activeStep={currentStep}
                variant="progressOnly"
              ></StepBar>

              <div className="gap-y-4 overflow-y-auto scrollbar-hide flex flex-col flex-1">
                {currentStep === 1 && (
                  <Step1
                    action={action}
                    setAction={setAction}
                    shipperAddress={shipperAddress}
                    setShipperAddress={setShipperAddress}
                    consigneeAddress={consigneeAddress}
                    setConsigneeAddress={setConsigneeAddress}
                  />
                )}

                {currentStep === 2 && <Step2 />}

                {currentStep === 3 && <Step3 />}

                {currentStep === 4 && <Step4 />}
              </div>
            </div>

            {isShowChatSideBar && (
              <RadarAndInternalChat
                order={order}
                shipperFullAddress={shipperFullAddress}
                consigneeFullAddress={consigneeFullAddress}
                isOrderView={false}
              />
            )}
          </div>
        </FormProvider>

        {orderDimensionAction.tailgate && (
          <ConfirmModal
            title="Tailgate Over Dimensions"
            description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
              weightMeasurement === MEASUREMENT.WEIGHT1.value
                ? `${LBS.MAX_WEIGHT}lbs`
                : `${KGS.MAX_WEIGHT}kgs`
            }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
            button1="Yes, I am sure"
            button2="Modify Selection"
            handleClose={handleModalClose}
          />
        )}

        {isShowSaveAsDraftModal && (
          <ConfirmationModalCmp
            title="Save As Draft"
            description="Are you sure you want to save this order as draft?"
            Icon={<Save01 className="w-7 h-7" />}
            handleClose={() => setIsShowSaveAsDraftModal(false)}
            isSubmitting={isSaveAsDraftLoading}
            handleSubmit={handleSubmitSaveAsDraft}
          />
        )}
      </PageSectionLayout>
    </OrderContext.Provider>
  );
};

export default OrderDetails;
