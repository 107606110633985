import {
  CheckCircleBroken,
  Truck01,
  ClockCheck,
  ArrowCircleBrokenUp,
  Briefcase02,
  Calendar,
  SwitchHorizontal01,
  VolumeMax,
  LayoutTop,
  CreditCard01,
  Package,
  CreditCard02,
  CalendarCheck02,
  LineChartUp02,
  Dataflow03,
  Star01,
  RefreshCcw05,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';

const PROMPTS = [
  {
    question:
      'Give me 5 customers to follow up with who I acquired this year, but who have not moved a load in the last 90 days.',
    icon: <Truck01 className="w-6 h-6 text-black" />,
  },
  {
    question: 'Which are my active customers?',
    icon: <ClockCheck className="w-6 h-6 text-black" />,
  },
  {
    question:
      'Please check in with my customers with whom I have not moved a load for more than 30 days.',
    icon: <CheckCircleBroken className="w-6 h-6 text-black" />,
  },
  {
    question: 'Who are my top 10 customers?',
    icon: <ArrowCircleBrokenUp className="w-6 h-6 text-black" />,
  },
  {
    question:
      'Which accounts haven’t repeated business since their first shipment?',
    icon: <Briefcase02 className="w-6 h-6 text-black" />,
  },
  {
    question:
      'Show me customers onboarded previous year with fewer than three loads moved.',
    icon: <Calendar className="w-6 h-6 text-black" />,
  },
  {
    question: 'Who has done cross-border shipments in the past six months?',
    icon: <SwitchHorizontal01 className="w-6 h-6 text-black" />,
  },
  {
    question: 'Who are our top five customers by load volume?',
    icon: <VolumeMax className="w-6 h-6 text-black" />,
  },
  {
    question: 'Which customers have active claims?',
    icon: <LayoutTop className="w-6 h-6 text-black" />,
  },
  {
    question: 'Which customers are over their credit limit?',
    icon: <CreditCard01 className="w-6 h-6 text-black" />,
  },
  {
    question: 'Which customers frequently cancel orders?',
    icon: <Package className="w-6 h-6 text-black" />,
  },
  {
    question: 'Which customers are at 90% or more of their credit limit?',
    icon: <CreditCard02 className="w-6 h-6 text-black" />,
  },
  {
    question:
      'What percentage of my new customers acquired in the first half of 2024 got to 4 loads booked?',
    icon: <CalendarCheck02 className="w-6 h-6 text-black" />,
  },
  {
    question:
      'Which customers brought in high revenue last year but have slowed this quarter?',
    icon: <LineChartUp02 className="w-6 h-6 text-black" />,
  },
  {
    question:
      'What are all my customers that have done cross-border inbound shipments?',
    icon: <Dataflow03 className="w-6 h-6 text-black" />,
  },
  {
    question: 'Sort my customers by win rate.',
    icon: <Star01 className="w-6 h-6 text-black" />,
  },
];

const PromptsCmp = ({
  onSendMessage = () => {},
}: {
  onSendMessage?: (message: string) => void;
}) => {
  const [displayedPrompts, setDisplayedPrompts] = useState<any[]>([
    ...PROMPTS.slice(0, 4),
  ]);
  const [currentIndexPrompt, setCurrentIndexPrompt] = useState(4);

  const refreshPrompts = () => {
    const nextBatch = [];
    let index = currentIndexPrompt;

    // Get the next 4 queries
    for (let i = 0; i < 4; i++) {
      nextBatch.push(PROMPTS[index]);
      index = (index + 1) % PROMPTS.length; // Loop back to the start if the end is reached
    }

    setDisplayedPrompts(nextBatch);
    setCurrentIndexPrompt(index); // Update the starting index for the next batch
  };

  return (
    <>
      <div className="mt-8 text-sm font-normal text-black flex gap-4 w-full ">
        {displayedPrompts?.map((data, index) => (
          <div
            className="h-[200px] bg-gray50 rounded-xl py-5 px-4 w-1/4 flex flex-col justify-between cursor-pointer"
            key={index}
            onClick={() => {
              onSendMessage(data?.question);
            }}
          >
            <p className="">{data?.question}</p>
            <div className="p-1.5 bg-white rounded-lg shadow-md flex items-center justify-center ml-auto">
              {data?.icon && data?.icon}
            </div>
          </div>
        ))}
      </div>
      <div
        className="flex items-center mt-5 w-fit cursor-pointer"
        onClick={() => {
          refreshPrompts();
        }}
      >
        <RefreshCcw05 className="w-5 h-5 text-grayLight600 cursor-pointer" />
        <p className="text-xs font-normal text-grayLight600 ml-2">
          New Prompts
        </p>
      </div>
    </>
  );
};

export default PromptsCmp;
