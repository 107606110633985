import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

export const getWarehouseList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.WAREHOUSE.LIST, {
    params,
    signal,
  });

  return response;
};

export const createWarehouse = async (data: any) => {
  const response = await axiosInterceptor.post(`${API.WAREHOUSE.CREATE}`, data);

  return response;
};

export const updateWarehouse = async (warehouseId: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.WAREHOUSE.UPDATE}/${warehouseId}`,
    data
  );

  return response;
};
