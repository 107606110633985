import { useState, useEffect, useRef, useContext } from 'react';
import { BasicContext } from 'src/context/BasicContext';

const useCountUp = (end: number, isLoading = false, duration = 1000) => {
  const { ordersUpdateSocketTriggeredCount } = useContext(BasicContext);
  const [count, setCount] = useState(0);
  const previousEnd = useRef(0);

  const isPageRefreshed = useRef(true);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isPageRefreshed.current) {
      isPageRefreshed.current = false;
      previousEnd.current = end;
      setCount(end);

      return;
    }

    let handle: any = undefined;

    if (previousEnd.current == end) {
      return;
    }

    if (!ordersUpdateSocketTriggeredCount) {
      previousEnd.current = end;
      setCount(end);
    } else {
      let startValue = Number(previousEnd.current);
      const totalFrames = duration / 100;
      const increment = (end - startValue) / totalFrames;

      handle = setInterval(() => {
        startValue += increment;

        if (startValue >= end) {
          setCount(end);
          previousEnd.current = end;
          clearInterval(handle);
        } else {
          setCount(Math.round(startValue * 100) / 100);
        }
      }, 10);
    }

    return () => {
      if (handle) {
        clearInterval(handle);
      }
    };
  }, [end, duration, isLoading, ordersUpdateSocketTriggeredCount]);

  return count;
};

export default useCountUp;
