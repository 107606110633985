import { Calendar, InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CheckBox from 'src/components/CheckBox';
import DateSelect from 'src/components/DateSelect/DateSelect';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
} from 'src/utils/CommonFunctions';

const repeatArr = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

const endAtArray = [
  { label: 'On this day', value: 'onThisDay' },
  { label: 'After', value: 'after' },
];

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface IProps {
  selectedDays: any;
  setSelectedDays: any;
}

const Step1 = ({ selectedDays, setSelectedDays }: IProps) => {
  const [recurrenceMessage, setRecurrenceMessage] = useState<any>(null);
  const [repeatOrderTimeFrameTitle, setRepeatOrderTimeFrameTitle] =
    useState('Week(s)');

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const watchRepeatOrderTimeFrame = watch('repeatOrderTimeFrame');
  const watchRecurrenceEndAt = watch('recurrenceEndAt');

  useEffect(() => {
    if (watchRepeatOrderTimeFrame === 'weekly') {
      setRepeatOrderTimeFrameTitle('Week(s)');
    }

    if (watchRepeatOrderTimeFrame === 'daily') {
      setRepeatOrderTimeFrameTitle('Day(s)');
    }
  }, [watchRepeatOrderTimeFrame]);

  useEffect(() => {
    if (
      !watchRepeatOrderTimeFrame ||
      !selectedDays.length ||
      !watchRecurrenceEndAt
    ) {
      setRecurrenceMessage('');

      return;
    }

    const formattedDays = selectedDays.join(', ');

    const pickupDateFormatted = moment(watch('pickupDate')).format(
      'dddd, MMMM Do, YYYY'
    );

    let endCondition = '';

    if (watchRecurrenceEndAt === 'onThisDay') {
      endCondition = `ending on ${moment(watch('recurrenceEndDate')).format(
        'dddd, MMMM Do, YYYY'
      )}`;
    } else if (watchRecurrenceEndAt === 'after') {
      endCondition = `for a total of ${watch('totalOccurrence')} orders`;
    }

    const message = `Occurs every ${formattedDays} starting from ${pickupDateFormatted} ${endCondition}.`;
    setRecurrenceMessage(message);
  }, [
    watchRepeatOrderTimeFrame,
    selectedDays,
    watch('pickupDate'),
    watchRecurrenceEndAt,
    watch('recurrenceEndDate'),
    watch('totalOccurrence'),
  ]);

  return (
    <div className="p-5 flex flex-col gap-y-4 ">
      <div className="flex flex-wrap items-center gap-x-3 ">
        <h6 className="text-textSecondary text-sm font-medium w-[90px]">
          Pickup Date
        </h6>

        <Controller
          name="pickupDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateSelect
              inputName="pickupDate"
              className="form_control"
              parentClassName="datepicker-w-auto one-month-datepicker flex-1"
              label="Select Date"
              selected={value ? moment(value).toDate() : moment().toDate()}
              onChangeFunc={(e: any) => onChange(e?.value)}
              minDate={moment().toDate()}
              labelClassName="block"
              placeholder="Select Date"
              dateFormat="dd/MM/yyyy"
              errorText={errors.pickupDate?.message}
              icon={<Calendar className="h-5 w-5" />}
              value={getDateWithSuffixFormat(getFormattedPickupDate(value))}
            />
          )}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-3 ">
        <h6 className="text-textSecondary text-sm font-medium w-[90px]">
          Repeat
        </h6>
        <Controller
          name="repeatOrderTimeFrame"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectBox
              name="repeatOrderTimeFrame"
              id="repeatOrderTimeFrame"
              placeholder="Select Repeat Order Time Frame"
              parentClassName="flex-1"
              options={repeatArr}
              onChangeFunc={(e: any) => {
                if (e?.value === 'monthly') {
                  setValue('recurrenceEndAt', 'after');
                }

                onChange(e?.value);
              }}
              value={repeatArr?.find((e: any) => e?.value == value)}
              errorText={error ? error?.message : ''}
            />
          )}
        />
      </div>

      {watchRepeatOrderTimeFrame !== 'monthly' && (
        <>
          <div className="flex flex-wrap items-center gap-x-3 ">
            <h6 className="text-textSecondary text-sm font-medium w-[90px]">
              Every
            </h6>
            <div className="form_control p-0 relative !h-auto flex-1">
              <Controller
                name="repeatTime"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="repeatTime"
                    placeholder="Enter Value"
                    className="!border-0 !h-auto !pr-20"
                    inputType="number"
                    value={value}
                    onChangeFunc={onChange}
                  />
                )}
              />
              <div className="absolute right-0 top-0 h-full flex items-center px-3 border-l border-utilityGray200 text-textSecondary text-xs font-normal">
                {repeatOrderTimeFrameTitle}
              </div>
            </div>
            {errors?.repeatTime?.message && (
              <ErrorMsg errorMessage={errors?.repeatTime?.message} />
            )}
          </div>

          <div className="flex flex-wrap items-center gap-x-3 ">
            <h6 className="text-textSecondary text-sm font-medium w-[90px]">
              On
            </h6>
            <Controller
              name="selectedDays"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="flex gap-2 flex-1">
                  {days.map((day) => (
                    <div
                      key={day}
                      className="flex gap-x-1.5 items-center w-[14.28%]"
                    >
                      <CheckBox
                        id={`day-${day}`}
                        name="on"
                        checked={selectedDays.includes(day)}
                        parentClassName={`gap-x-1.5`}
                        value={day}
                        // onChangeFunc={() => handleCheckboxChange(day)}
                        onChangeFunc={() => {
                          const updatedDays = value?.includes(day)
                            ? value.filter((d: any) => d !== day)
                            : [...(value || []), day];

                          onChange(updatedDays);

                          setSelectedDays((prevSelectedDays: any) =>
                            prevSelectedDays?.includes(day)
                              ? prevSelectedDays?.filter((d: any) => d !== day)
                              : [...prevSelectedDays, day]
                          );
                        }}
                        label={day}
                        labelHtmlFor={`day-${day}`}
                      />
                    </div>
                  ))}
                </div>
              )}
            />
          </div>
        </>
      )}

      <div className="flex flex-wrap items-center gap-x-3 ">
        <h6 className="text-textSecondary text-sm font-medium w-[90px]">
          End Date
        </h6>
        <div className="flex gap-3 flex-1">
          <Controller
            name="recurrenceEndAt"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectBox
                name="recurrenceEndAt"
                id="recurrenceEndAt"
                className="form_control"
                size="sm"
                placeholder="Select End Time"
                parentClassName="w-[180px]"
                options={endAtArray}
                onChangeFunc={(e: any) => onChange(e?.value)}
                value={endAtArray?.find((e: any) => e?.value == value)}
                errorText={error ? error?.message : ''}
              />
            )}
          />

          {watchRecurrenceEndAt === 'onThisDay' ? (
            <Controller
              name="recurrenceEndDate"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateSelect
                  inputName="recurrenceEndDate"
                  className="form_control"
                  parentClassName="datepicker-w-auto one-month-datepicker flex-1"
                  placeholder="Select Date"
                  dateFormat="dd/MM/yyyy"
                  icon={<Calendar className="h-5 w-5" />}
                  selected={value ? moment(value).toDate() : moment().toDate()}
                  onChangeFunc={(e: any) => onChange(e?.value)}
                  minDate={moment().toDate()}
                  value={getDateWithSuffixFormat(getFormattedPickupDate(value))}
                  errorText={error ? error?.message : ''}
                />
              )}
            />
          ) : (
            <div className="form_control p-0 relative !h-auto flex-1">
              <Controller
                name="totalOccurrence"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="totalOccurrence"
                    placeholder="Enter Occurrence"
                    className="!border-0 !h-auto !pr-24"
                    value={value}
                    inputType="number"
                    onChangeFunc={onChange}
                    errorText={error ? error?.message : ''}
                  />
                )}
              />
              <div className="absolute right-0 top-0 h-full flex items-center px-3 border-l border-utilityGray200 text-textSecondary text-xs font-normal">
                Occurences
              </div>
            </div>
          )}
        </div>
      </div>

      {recurrenceMessage && (
        <div className="border-t border-utilityGray200 pt-4">
          <div className="rounded-md border border-borderSecondary bg-utilityindigo25 px-3 py-2 flex items-start gap-x-1">
            <InfoCircle className="w-4 h-4" />
            <div className="text-textSecondary text-xs font-normal">
              {recurrenceMessage}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1;
