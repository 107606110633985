import { Send03 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';

import AiStarIcon from '../../assets/img/AiStarIcon.svg';
import ButtonCmp from '../ButtonCmp';
import InputText from '../InputText/InputText';
import OutsideClickHandler from '../OutsideClickHandler';

const AiInputText = () => {
  const [isShowAiActionsDropDown, setIsShowAiActionsDropDown] = useState(false);

  return (
    <InputText
      placeholder="Ask HQ AI to draft a response..."
      className="pl-9 pr-9 py-3 bg-gray25 border-utilityGray200 h-auto"
      icon={
        <>
          <OutsideClickHandler
            onOutsideClick={() => setIsShowAiActionsDropDown(false)}
          >
            <img
              src={AiStarIcon}
              alt="AiStarIcon"
              onClick={() => setIsShowAiActionsDropDown((prev) => !prev)}
              className="absolute top-1/2 -translate-y-1/2 left-3 text-grayText h-4 w-4 cursor-pointer"
            />
            <ul
              className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-full left-0 bottom-full absolute ${
                isShowAiActionsDropDown ? '' : 'hidden'
              }`}
            >
              <li
                className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                onClick={() => {
                  setIsShowAiActionsDropDown(false);
                }}
              >
                Reformat the text to improve its structure and presentation
              </li>
              <li
                className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                onClick={() => {
                  setIsShowAiActionsDropDown(false);
                }}
              >
                Kindly draft a formal message to request missing pickup date, as
                it is necessary to proceed with next steps.
              </li>
              <li
                className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer `}
                onClick={() => {
                  setIsShowAiActionsDropDown(false);
                }}
              >
                Make shorten the message while ensuring it remains clear,
                professional, and effective.
              </li>
            </ul>
          </OutsideClickHandler>

          <ButtonCmp
            className="absolute top-1/2 -translate-y-1/2 right-3 text-primary cursor-pointer !border-0 !p-0 !shadow-none !bg-transparent"
            icon={<Send03 className="h-4 w-4 " />}
          >
            <></>
          </ButtonCmp>
        </>
      }
    />
  );
};

export default AiInputText;
