// import { CheckSquareBroken } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
// import { useRolePermission } from 'src/utils/CommonFunctions';

const CustomerDetailDisputesLoadingRaw = () => (
  // const { hasRoleV2 } = useRolePermission();

  <>
    <td className="px-5 py-4 min-w-44 w-44 max-w-44">
      <div className="flex items-center">
        <div className="text-xs font-medium max-w-32">
          <div className="text-grayLight600 font-normal leading-tight truncate custom-loading">
            #DIS-JDS-181
          </div>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 text-xs max-w-32 w-32 min-w-32 ">
      <BadgeCmp style="modern" type={'success'} mainClassName="custom-loading">
        <span data-test-id="wal-QuotingDashboard-status">Resolved</span>
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 max-w-32 min-w-32 w-32">
      <div className="flex items-center">
        <div className="text-xs font-medium max-w-32">
          <div className="text-grayLight600 font-normal leading-tight truncate custom-loading">
            #WAL-JDS-7
          </div>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-40 w-40 max-w-40">
      <span className="custom-loading">MMM Do - YYYY</span>
    </td>

    <td
      data-test-id="wal-QuotingDashboard-totalWeight"
      className="px-5 py-4  min-w-[150px] max-w-[150px]"
    >
      <TooltipCmp
        message={'TEST SOLUTIONS J D ( TEST-JD Express ) -888-878-9121'}
        parentClassName="max-w-90"
      >
        <p className="truncate custom-loading">
          TEST SOLUTIONS J D ( TEST-JD Express ) -888-878-9121
        </p>
      </TooltipCmp>
    </td>
    {/* <td data-test-id="wal-QuotingDashboard-totalUnits" className="px-5 py-4">
      <span className="custom-loading">MMM Do - YYYY</span>
    </td> */}
    <td className={`px-5 py-4 min-w-30 max-w-30 `}>
      <span className="custom-loading">$4321.55</span>
    </td>
    <td className={`px-5 py-4 min-w-30 max-w-30 `}>
      <span className="custom-loading">$4321.55</span>
    </td>

    {/* <td className="px-5 py-4 min-w-30 max-w-30">
        <BadgeCmp
          style="pill"
          type="success"
          mainClassName="rounded-md custom-loading"
        >
          <div className="flex items-center gap-1">
            <Clock className={`w-3 h-3 mt-[1px] text-success500`} />
            10:10:10
          </div>
        </BadgeCmp>
      </td> */}

    {/* {hasRoleV2('admin') && (
        <td className="px-5 py-4">
          <div className="py-2">
            <div className="flex items-center custom-loading">
              <TooltipCmp message={'Resolve Claim'} parentClassName="max-w-90">
                <CheckSquareBroken
                  className={`w-4 h-4 relative mr-3 text-primary`}
                />
              </TooltipCmp>
            </div>
          </div>
        </td>
      )} */}
  </>
);
export default CustomerDetailDisputesLoadingRaw;
