import {
  DotsVertical,
  Mail02,
  PhoneCall01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const ContactLoaderRow = () => (
  <>
    <td className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]">
      <div className="flex items-center gap-3">
        <div className="flex-none">
          <div className="table-profile-icn-circle-xl custom-loading"></div>
        </div>
        <div className="truncate custom-loading">
          <h6 className="text-grayLight900 font-medium truncate ">data.name</h6>
          <p className="truncate mt-[1px] text-gray500 font-normal">
            data.countryCode + data.number
          </p>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]">
      <div className="custom-loading truncate">data.companyName</div>
    </td>

    <td className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]">
      <div className="custom-loading truncate">data.role</div>
    </td>

    <td className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]">
      <div className="custom-loading truncate">data.email</div>
    </td>

    <td className="px-5 py-4 w-[125px] min-w-[125px] max-w-[125px]">
      <div className="items-center gap-4 flex ">
        <div className="w-4 h-4 custom-loading">
          <PhoneCall01 className="cursor-pointer w-4 h-4" />
        </div>
        <div className="w-4 h-4 custom-loading">
          <Mail02 className="cursor-pointer w-4 h-4" />
        </div>
        <div className="w-4 h-4 custom-loading">
          <DotsVertical className="cursor-pointer w-4 h-4" />
        </div>
      </div>
    </td>
  </>
);

export default ContactLoaderRow;
