import React from 'react';
import CheckBox from 'src/components/CheckBox';

const LineItemsRow = ({
  data,
  selectedLineItemId,
  handlingUnitOptions,
}: any) => {
  const handlingUnit = handlingUnitOptions?.find(
    (item: any) => item?.value === data?.handlingUnit
  );

  return (
    <>
      <td className="px-5 py-4 w-11 min-w-11 max-w-11">
        <span> {data?.handlingUnitNo}</span>
      </td>
      <td className="px-5 py-4">{handlingUnit?.label ?? '-'}</td>
      <td className="px-5 py-4">
        {data?.length} X {data?.width} X {data?.height}
      </td>
      <td className="px-5 py-4">
        {data?.totalWeight ? data?.totalWeight.toFixed(2) : 0} (
        {data?.weightMeasurement})
      </td>
      <td className="px-5 py-4">{data?.refNumber ?? '-'}</td>
      <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
        <CheckBox
          classes="mr-2"
          onChangeFunc={() => {}}
          checked={selectedLineItemId?.includes(data?.id)}
        />
      </td>
    </>
  );
};

export default LineItemsRow;
