import { ArrowNarrowRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { calculateDistance } from 'src/utils/CommonFunctions';

const SplitLegDetailsRow = ({ data, splitLegData }: any) => (
  <>
    <td className="px-5 py-4 w-[22.60%] max-w-[1px] min-w-[22.60%]">
      <p className="truncate">#{data?.legId}</p>
    </td>
    <td className="px-5 py-4 w-[22.60%] max-w-[22.60%] min-w-[22.60%]">
      <span>
        {calculateDistance(
          data?.shipper?.shipperAddress?.latitude,
          data?.shipper?.shipperAddress?.longitude,
          data?.consignee?.consigneeAddress?.latitude,
          data?.consignee?.consigneeAddress?.longitude
        )}{' '}
        Mi
      </span>
    </td>
    <td className="px-5 py-4 w-[22.60%] max-w-[1px] min-w-[22.60%]">
      <p className="truncate">
        <TooltipCmp
          message={
            data?.shipper?.shipperAddress?.city.length > 24
              ? data?.shipper?.shipperAddress?.city
              : null
          }
          parentClassName="max-w-90"
        >
          <span className="truncate">
            {data?.shipper?.shipperAddress?.city}
          </span>
        </TooltipCmp>
      </p>
    </td>
    <td className="px-5 py-4 w-[22.60%] max-w-[1px] min-w-[22.60%]">
      <p className="truncate">
        <TooltipCmp
          message={
            data?.consignee?.consigneeAddress?.city.length > 24
              ? data?.consignee?.consigneeAddress?.city
              : null
          }
          parentClassName="max-w-90"
        >
          <span className="truncate">
            {data?.consignee?.consigneeAddress?.city}
          </span>
        </TooltipCmp>
      </p>
    </td>
    <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
      <ArrowNarrowRight
        className={`w-4 h-4 text-primary cursor-pointer transition-opacity duration-300 ${
          splitLegData?.id === data?.id
            ? 'visible'
            : 'invisible group-hover:visible'
        }`}
      />
    </td>
  </>
);

export default SplitLegDetailsRow;
