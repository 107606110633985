import {
  ArrowDown,
  Check,
  Edit05,
  Mail01,
  PhoneCall01,
  Settings02,
  Stars02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import TrackAndTraceSetting from 'src/app/Radar/TrackAndTraceSetting';
import AccordionCmp from 'src/components/AccordionCmp';
import SelectBox from 'src/components/SelectBox/SelectBox';

import UpdateOrderLocationModal from './UpdateOrderLocationModal';

const TrackTraceAiCmp = () => {
  const contactOptionArray = [
    {
      label: '',
      value: 'call',
      icon: <PhoneCall01 className="w-3.5 h-3.5" />,
    },
    {
      label: '',
      value: 'email',
      icon: <Mail01 className="w-3.5 h-3.5" />,
    },
  ];

  const [isShowUpdateOrderLocationModal, setIsShowUpdateOrderLocationModal] =
    useState(false);

  const [isDisplaySetting, setIsDisplaySetting] = useState(false);

  return (
    <>
      <AccordionCmp
        title={
          <div className="flex items-center w-full">
            <div className="flex flex-1 items-center gap-x-1.5 text-grayLight900 text-base font-medium">
              Track and Trace AI
              <Stars02 className="w-4 h-4 text-primary" />
            </div>
            <Settings02
              className="w-5 h-5 text-textSecondary"
              onClick={() => {
                setIsDisplaySetting(true);
              }}
            />
          </div>
        }
        className="!p-0 overflow-hidden flex-none"
        accordionHeaderWrap="p-4"
        contentParentClass="!p-4 !mt-0 bg-gray25"
        contentClassName="!bg-gray25"
      >
        <ul>
          <li className="relative flex items-start gap-x-2 pb-4 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
            <div className="after:content-[''] after:h-[98%] after:w-[2px] mt-2.5 after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary after:bg-fgSuccessPrimary">
              <Check className="w-3 h-3 text-white" />
            </div>

            {/* Intransit ACCORDION START */}
            <AccordionCmp
              title={
                <div className="flex items-end gap-x-2 flex-1">
                  <h6 className="text-sm font-semibold text-grayLight900">
                    In-Transit
                  </h6>
                  <p className="text-xs font-medium text-gray500 uppercase">
                    #WAL-NSBB-34-B
                  </p>
                </div>
              }
              className="rounded-lg mb-2.5 last:mb-0 !p-3 shadow-sm"
              inactiveClassName=""
              activeClassName=""
              contentParentClass="!pt-0 "
            >
              <ul className="mt-3">
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Sent carrier location request update
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 10:14 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <SelectBox
                      name="contactOption"
                      id="contactOption"
                      className="form_control"
                      size="sm"
                      options={contactOptionArray}
                      parentClassName="w-[42px] track-trace-select"
                      onChangeFunc={() => {}}
                      value={{
                        label: 'call',
                        value: 'call',
                        icon: (
                          <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                        ),
                      }}
                    />
                  </div>
                </li>
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Sent follow up for location request
                    <p className="text-gray400 text-xs !text-[11px] font-normal">
                      Today, 10:39 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <SelectBox
                      name="contactOption"
                      id="contactOption"
                      className="form_control"
                      size="sm"
                      options={contactOptionArray}
                      parentClassName="w-[42px] track-trace-select"
                      onChangeFunc={() => {}}
                      value={{
                        label: 'call',
                        value: 'call',
                        icon: (
                          <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                        ),
                      }}
                    />
                  </div>
                </li>
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Sent follow up for location request
                    <p className="text-gray400 text-xs !text-[11px] font-normal">
                      Today, 11:04 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <SelectBox
                      name="contactOption"
                      id="contactOption"
                      className="form_control"
                      size="sm"
                      options={contactOptionArray}
                      parentClassName="w-[42px] track-trace-select"
                      onChangeFunc={() => {}}
                      value={{
                        label: 'call',
                        value: 'call',
                        icon: (
                          <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                        ),
                      }}
                    />
                  </div>
                </li>
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Manual Intervention Required
                    <p className="text-gray400 text-xs !text-[11px] font-normal">
                      Today, 11:04 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <SelectBox
                      name="contactOption"
                      id="contactOption"
                      className="form_control"
                      size="sm"
                      options={contactOptionArray}
                      parentClassName="w-[42px] track-trace-select"
                      onChangeFunc={() => {}}
                      value={{
                        label: 'call',
                        value: 'call',
                        icon: (
                          <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                        ),
                      }}
                    />
                  </div>
                </li>
              </ul>
            </AccordionCmp>
            {/* Intransit ACCORDION END */}
          </li>

          <li className="relative flex items-start gap-x-2 pb-4 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
            <div className="after:content-[''] after:h-[98%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary after:bg-fgSuccessPrimary">
              <ArrowDown className="w-3 h-3 text-white" />
            </div>
            {/* PICKUP ACCORDION START */}
            <AccordionCmp
              title={
                <div className="flex items-end gap-x-2 flex-1">
                  <h6 className="text-sm font-semibold text-grayLight900">
                    Pickup
                  </h6>
                  <p className="text-xs font-medium text-gray500 uppercase">
                    #WAL-NSBB-34-B
                  </p>
                  {/* <div className="flex items-center gap-x-2.5">
                    IF STATUS IS COMPLETE
                    <CheckCircle className="w-4 h-4 text-primary" />
                    IF STATUS IS IN-PROGRESS
                    <div
                      className="radial-progress text-primary"
                      style={
                        {
                          '--value': 40,
                          '--size': '14px',
                          '--thickness': '3px',
                        } as React.CSSProperties & { '--value': number }
                      }
                      role="progressbar"
                    ></div>
                    IF STATUS IS NOT EVEN STARTED
                    <img src={circleDashed} className="w-4 h-4" />
                    <p className="text-gray500 text-sm font-normal">5 tasks</p>
                  </div> */}
                </div>
              }
              className="rounded-lg mb-2.5 last:mb-0 !p-3 shadow-sm"
              inactiveClassName=""
              activeClassName=""
              contentParentClass="!pt-0 "
            >
              <ul className="mt-3">
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Sent carrier location request update
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 10:14 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                  </div>
                </li>
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Manual Intervention Required
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 11:04 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                  </div>
                </li>
              </ul>
            </AccordionCmp>
            {/* PICKUP ACCORDION END */}
          </li>

          <li className="relative flex items-start gap-x-2 pb-4 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
            <div className="after:content-[''] after:h-[98%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary after:bg-fgSuccessPrimary">
              <ArrowDown className="w-3 h-3 text-white" />
            </div>
            <AccordionCmp
              title={
                <div className="flex items-end gap-x-2 flex-1">
                  <h6 className="text-sm font-semibold text-grayLight900">
                    Delivery
                  </h6>
                  <p className="text-xs font-medium text-gray500 uppercase">
                    #WAL-NSBB-34-A
                  </p>
                  {/* <div className="flex items-center gap-x-2.5">
                    IF STATUS IS COMPLETE
                    <CheckCircle className="w-4 h-4 text-primary" />
                    IF STATUS IS IN-PROGRESS
                    <div
                      className="radial-progress text-primary"
                      style={
                        {
                          '--value': 40,
                          '--size': '14px',
                          '--thickness': '3px',
                        } as React.CSSProperties & { '--value': number }
                      }
                      role="progressbar"
                    ></div>
                    IF STATUS IS NOT EVEN STARTED
                    <img src={circleDashed} className="w-4 h-4" />
                    <p className="text-gray500 text-sm font-normal">5 tasks</p>
                  </div> */}
                </div>
              }
              className="rounded-lg mb-2.5 last:mb-0 !p-3 shadow-sm"
              inactiveClassName=""
              activeClassName=""
              contentParentClass="!pt-0 "
            >
              <ul className="mt-3">
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Sent carrier location request update
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 10:14 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                  </div>
                </li>
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Manual Intervention Required
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 11:04 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                  </div>
                </li>
              </ul>
            </AccordionCmp>
          </li>

          <li className="relative flex items-start gap-x-2 pb-4 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
            <div className="after:content-[''] after:h-[98%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary after:bg-fgSuccessPrimary">
              <ArrowDown className="w-3 h-3 text-white" />
            </div>
            <AccordionCmp
              title={
                <div className="flex items-end gap-x-2 flex-1">
                  <h6 className="text-sm font-semibold text-grayLight900">
                    In-Transit
                  </h6>
                  <p className="text-xs font-medium text-gray500 uppercase">
                    #WAL-NSBB-34-A
                  </p>
                  {/* <div className="flex items-center gap-x-2.5">
                    IF STATUS IS COMPLETE
                    <CheckCircle className="w-4 h-4 text-primary" />
                    IF STATUS IS IN-PROGRESS
                    <div
                      className="radial-progress text-primary"
                      style={
                        {
                          '--value': 40,
                          '--size': '14px',
                          '--thickness': '3px',
                        } as React.CSSProperties & { '--value': number }
                      }
                      role="progressbar"
                    ></div>
                    IF STATUS IS NOT EVEN STARTED
                    <img src={circleDashed} className="w-4 h-4" />
                    <p className="text-gray500 text-sm font-normal">5 tasks</p>
                  </div> */}
                </div>
              }
              className="rounded-lg mb-2.5 last:mb-0 !p-3 shadow-sm"
              inactiveClassName=""
              activeClassName=""
              contentParentClass="!pt-0 "
            >
              <ul className="mt-3">
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Sent carrier location request update
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 10:14 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                  </div>
                </li>
                <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                  <div className="flex-1 text-grayLight600 text-xs font-normal">
                    Manual Intervention Required
                    <p className="text-gray400 text-[11px] font-normal">
                      Today, 11:04 am
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Edit05
                      className="w-[18px] h-[18px] text-gray400"
                      onClick={() => {
                        setIsShowUpdateOrderLocationModal(true);
                      }}
                    />
                    <PhoneCall01 className="w-[18px] h-[18px] text-gray400" />
                  </div>
                </li>
              </ul>
            </AccordionCmp>
          </li>

          {/* <AccordionCmp
            title={
              <div className="flex items-center justify-between flex-1">
                <h6 className="text-sm">Completed</h6>
                <div className="flex items-center gap-x-2.5">
                  IF STATUS IS COMPLETE
                  <CheckCircle className="w-4 h-4 text-primary" />
                  IF STATUS IS IN-PROGRESS
                  <div
                    className="radial-progress text-primary"
                    style={
                      {
                        '--value': 40,
                        '--size': '14px',
                        '--thickness': '3px',
                      } as React.CSSProperties & { '--value': number }
                    }
                    role="progressbar"
                  ></div>
                  IF STATUS IS NOT EVEN STARTED
                  <img src={circleDashed} className="w-4 h-4" />
                  <p className="text-gray500 text-sm font-normal">5 tasks</p>
                </div>
              </div>
            }
            className="rounded-lg mb-2.5 last:mb-0 !px-3 "
            inactiveClassName="rounded-none border-x-transparent border-t-transparent !bg-transparent last:border-b-0"
            activeClassName="shadow-md"
            contentParentClass="!mt-4 !pt-0 "
          >
            <ul className="mt-5">
              <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-successSecondary">
                  <div className="w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary flex justify-center items-center">
                    <Check className="w-3 h-3 text-white" />
                  </div>
                </div>
                <div className="flex-1 text-grayLight900 text-xs font-medium">
                  Sent carrier location request update
                  <p className="text-gray400 text-xs !text-[11px] font-normal">
                    Today, 10:14 am
                  </p>
                </div>
                <div className="flex items-center gap-x-3">
                  <Edit05
                    className="w-4 h-4 text-gray400"
                    onClick={() => {
                      setIsShowUpdateOrderLocationModal(true);
                    }}
                  />
                  <SelectBox
                    name="contactOption"
                    id="contactOption"
                    className="form_control"
                    size="sm"
                    options={contactOptionArray}
                    parentClassName="w-[42px] track-trace-select"
                    onChangeFunc={() => {}}
                    value={{
                      label: 'call',
                      value: 'call',
                      icon: <PhoneCall01 className="w-3.5 h-3.5" />,
                    }}
                  />
                </div>
              </li>
              <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-utilityGray200">
                  <div className="w-[18px] h-[18px] rounded-full bg-primary flex justify-center items-center">
                    <ArrowNarrowDown className="w-3 h-3 text-white" />
                  </div>
                </div>
                <div className="flex-1 text-grayLight600 text-xs font-normal">
                  Sent follow up for location request
                  <p className="text-gray400 text-xs !text-[11px] font-normal">
                    Today, 11:04 am
                  </p>
                </div>
                <div className="flex items-center gap-x-3">
                  <Edit05
                    className="w-4 h-4 text-gray400"
                    onClick={() => {
                      setIsShowUpdateOrderLocationModal(true);
                    }}
                  />
                  <SelectBox
                    name="contactOption"
                    id="contactOption"
                    className="form_control"
                    size="sm"
                    options={contactOptionArray}
                    parentClassName="w-[42px] track-trace-select"
                    onChangeFunc={() => {}}
                    value={{
                      label: 'call',
                      value: 'call',
                      icon: <PhoneCall01 className="w-3.5 h-3.5" />,
                    }}
                  />
                </div>
              </li>
              <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 [&:last-child>div:after]:opacity-0">
                <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-utilityGray200">
                  <div className="w-[18px] h-[18px] rounded-full bg-fgErrorPrimary flex justify-center items-center">
                    <ArrowNarrowDown className="w-3 h-3 text-white" />
                  </div>
                </div>
                <div className="flex-1 text-grayLight600 text-xs font-normal">
                  Manual Intervention Required
                  <p className="text-gray400 text-xs !text-[11px] font-normal">
                    Today, 12:04 am
                  </p>
                </div>
                <div className="flex items-center gap-x-3">
                  <Edit05
                    className="w-4 h-4 text-gray400"
                    onClick={() => {
                      setIsShowUpdateOrderLocationModal(true);
                    }}
                  />
                  <SelectBox
                    name="contactOption"
                    id="contactOption"
                    className="form_control"
                    size="sm"
                    options={contactOptionArray}
                    parentClassName="w-[42px] track-trace-select"
                    onChangeFunc={() => {}}
                    value={{
                      label: 'call',
                      value: 'call',
                      icon: <PhoneCall01 className="w-3.5 h-3.5" />,
                    }}
                  />
                </div>
              </li>
            </ul>
          </AccordionCmp> */}
        </ul>
      </AccordionCmp>
      {isShowUpdateOrderLocationModal && (
        <UpdateOrderLocationModal
          handleClose={() => {
            setIsShowUpdateOrderLocationModal(false);
          }}
        />
      )}

      {isDisplaySetting ? (
        <TrackAndTraceSetting handleClose={() => setIsDisplaySetting(false)} />
      ) : (
        ''
      )}
    </>
  );
};

export default TrackTraceAiCmp;
