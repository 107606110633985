import { MarkerPin02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import { BasicContext } from 'src/context/BasicContext';

import { initShipperAddress, scriptId } from '../../order.interface';
import MapboxMap from '../MapBoxMap';

interface IProps {
  handleClose: any;
  handleSubmit: any;
  splitLegData: any;
  legFormData: any;
  setLegFormData: any;
}

const SetMiddlePointModal = ({
  handleClose,
  handleSubmit,
  splitLegData,
  legFormData,
  setLegFormData,
}: IProps) => {
  const { mapService, googleKey } = useContext(BasicContext);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  const [isFullAddressChanged, setIsFullAddressChanged] = useState(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [fullAddress, setFullAddress] = useState('');
  const [error, setError] = useState<string>('');
  const [startPoint, setStartPoint] = useState<any>(null);
  const [endPoint, setEndPoint] = useState<any>(null);
  const [midPoint, setMidPoint] = useState<any>(null);

  useEffect(() => {
    if (scriptId) {
      document.getElementById(scriptId)?.remove();
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      if (!legFormData?.legAddress?.fullAddress) {
        setLegFormData((old: any) => ({
          ...old,
          legAddress: initShipperAddress,
        }));
        setError('Please select proper address');
        setIsFullAddressChanged(false);
      }

      return;
    }

    setIsFullAddressChanged(true);
    setLegFormData((old: any) => ({
      ...old,
      legAddress: value,
      companyName: value?.companyName,
    }));
  };

  useEffect(() => {
    setStartPoint({
      lat: splitLegData?.shipper?.shipperAddress?.latitude,
      lng: splitLegData?.shipper?.shipperAddress?.longitude,
      name: null,
    });

    setEndPoint({
      lat: splitLegData?.consignee?.consigneeAddress?.latitude,
      lng: splitLegData?.consignee?.consigneeAddress?.longitude,
      name: null,
    });
  }, []);

  useEffect(() => {
    if (legFormData?.legAddress?.latitude) {
      setMidPoint({
        lat: legFormData?.legAddress?.latitude,
        lng: legFormData?.legAddress?.longitude,
        name: null,
      });
    } else {
      setMidPoint(null);
    }
  }, [legFormData]);

  useEffect(() => {
    if (error) {
      setError('');
    }

    if (!fullAddress) {
      setLegFormData((old: any) => ({
        ...old,
        legAddress: initShipperAddress,
        companyName: null,
      }));
    }
  }, [fullAddress]);

  const handleNextClick = () => {
    if (!isFullAddressChanged || !fullAddress) {
      setError('Please select or enter a valid address.');
    } else {
      setError('');
      handleSubmit();
    }
  };

  return (
    <>
      <CommonModal
        title="Select a Midpoint"
        titleDesc="Select a midpoint to split your leg into 2."
        handleClose={handleClose}
        headerIcon={<MarkerPin02 />}
        size={'max-w-[600px]'}
        primaryBtnText="Next"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleClose}
        primaryBtnOnClick={handleNextClick}
        // primaryBtnDisabled={!isValid}
      >
        <div className="p-5 flex flex-col gap-y-3">
          {status &&
            (mapService === 'trimble_map' ? (
              <AutoCompleteTrimble
                inputName={`fullAddress`}
                required
                placeholder="Enter address"
                dropdownWrapClass="!top-[unset] bottom-[65%]"
                className={`form_control truncate`}
                label="Address"
                labelClassName="mb-1.5 block w-full"
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                isClearable
                onAddressInputChange={setFullAddress}
                value={fullAddress}
              />
            ) : (
              <AutoCompleteGoogle
                inputName={`fullAddress`}
                required
                placeholder="Enter address"
                dropdownWrapClass="!top-[unset] bottom-[65%]"
                className={`form_control truncate `}
                label="Address"
                labelClassName="mb-1.5 block w-full"
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                isClearable
                onAddressInputChange={setFullAddress}
                value={fullAddress}
              />
            ))}

          {error && <ErrorMsg errorText={error} />}

          <MapboxMap
            startPoint={startPoint}
            endPoint={endPoint}
            midpoint={midPoint}
            height="300px"
            className="map-container border border-utilityGray200 rounded-xl"
            markerRef={markerRef}
            isZoomControlVisible={true}
            isFullscreen={false}
            isDraggable={true}
            isZoomable={true}
            defaultZoom={4}
          />
        </div>
      </CommonModal>
    </>
  );
};

export default SetMiddlePointModal;
