import {
  PauseCircle,
  // PhoneCall01,
  PhoneOutgoing01,
  PlayCircle,
  // Recording02,
  // Recording02,
  VolumeMax,
  VolumeX,
} from '@untitled-ui/icons-react/build/cjs';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingCircle from 'src/components/AdvancedEditor/ui/icons/loading-circle';
import ErrorMsg from 'src/components/errorMsg';
import { getTranscriptAndSummary } from 'src/services/CallCenterService';
import { getMinutesAndSeconds, getShortName } from 'src/utils/CommonFunctions';

import aiGradientStar from '../../../assets/img/ai-gradient-star.svg';
import AiStar from '../../../assets/img/AiStarIcon.svg';

const ThreadCommunicationCalls = ({
  thread,
  onViewTranscript,
  onViewContactDetails,
}: any) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isRecordingLoading, setIsRecordingLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [audioTime, setAudioTime] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [summary, setSummary] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [isSummaryVisiable, setIsSummaryVisiable] = useState<boolean>(true);

  const fetchRecordingDetails = useCallback(async () => {
    setIsRecordingLoading(true);
    setError(null);

    try {
      if (thread?.recordingId) {
        const response = await axios.post(
          'http://localhost:1337/api/v1/twilio/download-recording',
          { recordingSid: thread.recordingId },
          { responseType: 'blob' }
        );

        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(audioBlob);

        if (audioRef.current) {
          audioRef.current.src = url;

          return () => URL.revokeObjectURL(url);
        }
      }
    } catch (err) {
      console.error('Error fetching recording details:', err);
      setError('Unable to fetch recording. Please try again.');
    } finally {
      setIsRecordingLoading(false);
    }
  }, []);

  const handlePlayPause = useCallback(async () => {
    if (!audioRef.current) return;

    try {
      if (isPlaying) {
        await audioRef.current.pause();
      } else {
        if (!audioRef.current.src) {
          await fetchRecordingDetails();
        }
        await audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } catch (err) {
      console.error('Playback error:', err);
      setError('Unable to play audio. Please try again.');
    }
  }, [isPlaying, fetchRecordingDetails]);

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current && !isDragging) {
      setAudioTime(audioRef.current.currentTime);
    }
  }, [isDragging]);

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  }, []);

  const updateTimeFromClick = useCallback(
    (e: any) => {
      if (!progressRef.current || !audioRef.current) return;

      const rect = progressRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      const newTime = pos * duration;

      audioRef.current.currentTime = newTime;
      setAudioTime(newTime);
    },
    [duration]
  );

  const toggleVolume = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setIsMuted(audioRef.current.muted);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: any) => {
      if (isDragging) {
        updateTimeFromClick(e);
      }
    },
    [isDragging, updateTimeFromClick]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (e: any) => {
      setIsDragging(true);
      updateTimeFromClick(e);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [updateTimeFromClick]
  );

  // Clean up when component unmounts
  useEffect(
    () => () => {
      if (audioRef.current?.src) {
        URL.revokeObjectURL(audioRef.current.src);
      }
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp]
  );

  const fetchCallSummary = async (recordingSid: string) => {
    const data = {
      recordingSid,
      type: 'summary',
    };
    setSummary('');
    setIsSummaryLoading(true);

    await getTranscriptAndSummary(data)
      .then((response: any) => {
        if (response.data) {
          setSummary(response.data);
        }
        setIsSummaryLoading(false);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setSummary('');
        setIsSummaryLoading(false);
      });
  };

  const handleRecording = (recordingId?: any) => {
    fetchCallSummary(recordingId);
    setIsSummaryVisiable(false);
  };

  const handleViewTranscript = () => {
    if (thread?.recordingId) {
      onViewTranscript(thread);
    }
  };

  const handleViewContactDetails = (userId?: string) => {
    if (userId) {
      console.log('userId', userId);
      onViewContactDetails(userId, 'user');
    } else {
      onViewContactDetails(thread.contactId, 'contact');
    }
  };

  return (
    <>
      {/* right side */}
      {thread?.type === 'call' && thread?.callType === 'outbound' ? (
        <li className="flex gap-3 cursor-pointer flex-row-reverse">
          <div
            className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none"
            onClick={() => handleViewContactDetails(thread?.userId)}
          >
            <img
              src={thread?.userImageUrl + thread?.userImage}
              alt=""
              className="rounded-full"
            />
            <p className="mb-0 text-sm">
              {/* {getShortName(thread.contactName || '')} */}
            </p>
          </div>
          <div className="w-[42%] flex-1">
            <div className="space-y-1 max-w-[416px] ml-auto w-fit">
              <div className="flex justify-between gap-x-2 flex-row-reverse">
                <p className="text-grayLight600 font-normal text-xs">
                  {/* {thread?.contactName} */}
                  {`${thread?.userFirstName} ${thread?.userLastName}`}
                </p>
              </div>

              <div className="rounded-xl rounded-tr-none border border-utilityGray200 bg-white p-4">
                <div className="flex items-center gap-3 last:mb-0 mb-3">
                  <div className="w-10 h-10 bg-borderPrimary rounded-full flex items-center justify-center">
                    <PhoneOutgoing01 className="text-grayLight600 w-6 h-6" />
                  </div>
                  <div className="flex-1">
                    <p className="text-grayLight900 text-sm font-medium">
                      Call Ended
                    </p>
                    <p className="text-grayLight600 text-xs font-normal">
                      {thread?.contactName}{' '}
                      {getMinutesAndSeconds(thread?.duration)}
                    </p>
                  </div>
                  {thread?.recordingId && (
                    <div className="text-primary700 text-xs font-normal flex items-center gap-x-1 self-start">
                      <div
                        className={`flex gap-[3px] ${
                          isSummaryVisiable ? '' : 'invisible'
                        }`}
                        onClick={() => handleRecording(thread.recordingId)}
                      >
                        Generate Summary
                        <img src={AiStar} alt="AI Star" className="w-4 h-4" />
                      </div>
                    </div>
                  )}
                </div>

                {thread?.recordingId && (
                  <>
                    <p className="text-gray500 text-[10px] leading-[1.8] font-medium">
                      Recording
                    </p>
                    {/* <div className="p-2 bg-utilityGray100 rounded-lg flex items-center gap-3">
                    <PlayCircle className="text-gray500 cursor-pointer" />
                    <div className="items-center flex flex-1">
                      <div className="rounded-full bg-primary w-2 h-2"></div>
                      <div className="border border-borderPrimary flex-1"></div>
                    </div>
                    <p className="text-xs font-medium text-grayLight900">
                      0:05
                    </p>
                    <VolumeMax className="text-gray500" />
                  </div> */}
                    <div className="p-2 rounded-lg bg-utilityGray100 flex items-center gap-x-3 flex-wrap min-w-[250px]">
                      {isRecordingLoading ? (
                        // <div className="animate-pulse w-6 h-6 rounded-full bg-gray-300" />
                        <LoadingCircle />
                      ) : (
                        <button
                          onClick={handlePlayPause}
                          disabled={isRecordingLoading}
                          className="focus:outline-none"
                        >
                          {isPlaying ? (
                            <PauseCircle className="text-gray500 cursor-pointer w-6 h-6" />
                          ) : (
                            <PlayCircle className="text-gray500 cursor-pointer w-6 h-6" />
                          )}
                        </button>
                      )}

                      <div className="items-center flex flex-1">
                        <div
                          ref={progressRef}
                          className="bg-borderPrimary flex-1 relative h-[2.5px] cursor-pointer rounded-full"
                          onMouseDown={handleMouseDown}
                        >
                          <div
                            className="absolute bg-gray400 h-full transition-all duration-300"
                            style={{
                              width: `${
                                duration ? (audioTime / duration) * 100 : 0
                              }%`,
                            }}
                          />
                          <div
                            className="absolute top-1/2 -translate-y-1/2 w-2.5 h-2.5 rounded-full bg-gray400 -ml-1.5"
                            style={{
                              left: `${
                                duration ? (audioTime / duration) * 100 : 0
                              }%`,
                            }}
                          />
                        </div>
                      </div>

                      <p className="text-sm font-medium text-grayLight900">
                        {Math.floor(audioTime / 60)}:
                        {Math.floor(audioTime % 60)
                          .toString()
                          .padStart(2, '0')}
                      </p>
                      {isMuted ? (
                        <VolumeX
                          className="text-gray500 cursor-pointer"
                          onClick={toggleVolume}
                        />
                      ) : (
                        <VolumeMax
                          className="text-gray500 cursor-pointer"
                          onClick={toggleVolume}
                        />
                      )}

                      <audio
                        ref={audioRef}
                        onTimeUpdate={handleTimeUpdate}
                        onLoadedMetadata={handleLoadedMetadata}
                        onEnded={() => setIsPlaying(false)}
                      />

                      {error && (
                        <ErrorMsg errorText={error} mainClassName="w-full" />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-between items-center gap-x-2 flex-row-reverse">
                <p className="text-grayLight600 font-normal text-xs">
                  {thread?.createdAt
                    ? moment(thread?.createdAt).format('hh:mm A')
                    : ''}
                </p>
              </div>
            </div>

            <div className="mt-3">
              {isSummaryLoading ? (
                <div className="flex gap-2 mr-5 flex-row-reverse">
                  <div className="w-6 h-6 rounded-br-xl border border-borderPrimary border-t-0 border-l-0 flex-none"></div>
                  <div className="bg-white flex flex-col gap-3 px-[14px] py-3 border border-utilityGray200 rounded-lg max-w-[480px]">
                    <div className="flex flex-col gap-3">
                      <div className="flex gap-1 items-center justify-between">
                        <div className="flex items-center gap-2">
                          <p className="text-xs text-grayLight900 font-medium custom-loading">
                            Call summary
                          </p>

                          <p className="text-xs text-grayLight900 font-normal flex items-center gradient-text custom-loading">
                            Powered by Twilio Voice Intelligence &nbsp;
                            <img src={aiGradientStar} />
                          </p>
                        </div>
                        <div className="text-gray500 text-xs font-normal custom-loading">
                          View transcript
                        </div>
                      </div>
                      <ul className="list-disc ml-4 marker:text-primary">
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                summary != '' && (
                  <div className="flex gap-2 mr-5 flex-row-reverse">
                    <div className="w-6 h-6 rounded-br-xl border border-borderPrimary border-t-0 border-l-0 flex-none"></div>
                    <div className="bg-white flex flex-col gap-3 px-[14px] py-3 border border-utilityGray200 rounded-lg max-w-[480px]">
                      <div className="flex flex-col gap-3">
                        <div className="flex gap-1 items-center justify-between">
                          <div className="flex items-center gap-2">
                            <p className="text-xs text-grayLight900 font-medium">
                              Call summary
                            </p>

                            <p className="text-xs text-grayLight900 font-normal flex items-center gradient-text">
                              Powered by Twilio Voice Intelligence &nbsp;
                              <img src={aiGradientStar} />
                            </p>
                          </div>
                          <div
                            className="text-gray500 text-xs font-normal"
                            onClick={handleViewTranscript}
                          >
                            View transcript
                          </div>
                        </div>
                        <ul className="list-disc ml-4 marker:text-primary">
                          <li className="text-xs text-grayLight600 font-normal mb-[6px]">
                            {summary}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </li>
      ) : (
        <li className="flex gap-3 cursor-pointer">
          <div
            className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none"
            onClick={() => handleViewContactDetails()}
          >
            {thread?.contactImage && (
              <img src={thread.contactImage} alt="" className="rounded-full " />
            )}
            {!thread?.contactImage && (
              <p className="mb-0 text-sm">
                {getShortName(thread.contactName || '')}
              </p>
            )}
          </div>
          <div className="w-[42%] flex-1 ">
            <div className="space-y-1 max-w-[416px] w-fit">
              <div className="flex justify-between gap-x-2">
                <p className="text-grayLight600 font-normal text-xs">
                  {thread?.contactName}
                </p>
              </div>

              <div className="rounded-xl rounded-tl-none border border-utilityGray200 bg-white p-4 ">
                <div className="flex items-center gap-3 last:mb-0 mb-3">
                  <div className="w-10 h-10 bg-borderPrimary rounded-full flex items-center justify-center">
                    <PhoneOutgoing01 className="text-grayLight600 w-6 h-6" />
                  </div>
                  <div className="flex-1">
                    <p className="text-grayLight900 text-sm font-medium">
                      Call Ended
                    </p>
                    <p className="text-grayLight600 text-xs font-normal">
                      Ruth answered {getMinutesAndSeconds(thread?.duration)}
                    </p>
                  </div>
                  <div className="text-primary700 text-xs font-normal flex items-center gap-x-1 self-start">
                    <div
                      className={`flex gap-[3px] ${
                        isSummaryVisiable ? '' : 'invisible'
                      }`}
                      onClick={() => handleRecording(thread.recordingId)}
                    >
                      Generate Summary
                      <img src={AiStar} alt="AI Star" className="w-4 h-4" />
                    </div>
                  </div>
                </div>

                {thread?.recordingId && (
                  <>
                    <p className="text-gray500 text-[10px] leading-[1.8] font-medium">
                      Recording
                    </p>
                    {/* <div className="p-2 bg-utilityGray100 rounded-lg flex items-center gap-3">
                    <PlayCircle className="text-gray500 cursor-pointer" />
                    <div className="items-center flex flex-1">
                      <div className="rounded-full bg-primary w-2 h-2"></div>
                      <div className="border border-borderPrimary flex-1"></div>
                    </div>
                    <p className="text-xs font-medium text-grayLight900">
                      0:05
                    </p>
                    <VolumeMax className="text-gray500" />
                  </div> */}
                    <div className="p-2 rounded-lg bg-utilityGray100 flex items-center gap-x-3 flex-wrap min-w-[250px]">
                      {isRecordingLoading ? (
                        // <div className="animate-pulse w-6 h-6 rounded-full bg-gray-300" />
                        <LoadingCircle />
                      ) : (
                        <button
                          onClick={handlePlayPause}
                          disabled={isRecordingLoading}
                          className="focus:outline-none"
                        >
                          {isPlaying ? (
                            <PauseCircle className="text-gray500 cursor-pointer w-6 h-6" />
                          ) : (
                            <PlayCircle className="text-gray500 cursor-pointer w-6 h-6" />
                          )}
                        </button>
                      )}

                      <div className="items-center flex flex-1">
                        <div
                          ref={progressRef}
                          className="bg-borderPrimary flex-1 relative h-[2.5px] cursor-pointer rounded-full"
                          onMouseDown={handleMouseDown}
                        >
                          <div
                            className="absolute bg-gray400 h-full transition-all duration-300"
                            style={{
                              width: `${
                                duration ? (audioTime / duration) * 100 : 0
                              }%`,
                            }}
                          />
                          <div
                            className="absolute top-1/2 -translate-y-1/2 w-2.5 h-2.5 rounded-full bg-gray400 -ml-1.5"
                            style={{
                              left: `${
                                duration ? (audioTime / duration) * 100 : 0
                              }%`,
                            }}
                          />
                        </div>
                      </div>

                      <p className="text-sm font-medium text-grayLight900">
                        {Math.floor(audioTime / 60)}:
                        {Math.floor(audioTime % 60)
                          .toString()
                          .padStart(2, '0')}
                      </p>
                      {isMuted ? (
                        <VolumeX
                          className="text-gray500 cursor-pointer"
                          onClick={toggleVolume}
                        />
                      ) : (
                        <VolumeMax
                          className="text-gray500 cursor-pointer"
                          onClick={toggleVolume}
                        />
                      )}

                      <audio
                        ref={audioRef}
                        onTimeUpdate={handleTimeUpdate}
                        onLoadedMetadata={handleLoadedMetadata}
                        onEnded={() => setIsPlaying(false)}
                      />

                      {error && (
                        <ErrorMsg errorText={error} mainClassName="w-full" />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-between items-center gap-x-2">
                <p className="text-grayLight600 font-normal text-xs">
                  {thread?.createdAt
                    ? moment(thread?.createdAt).format('hh:mm A')
                    : ''}
                </p>
              </div>
            </div>

            <div className="mt-3">
              {isSummaryLoading ? (
                <div className="flex gap-2 ml-5">
                  <div className="w-6 h-6 rounded-bl-xl border border-borderPrimary border-t-0 border-r-0 flex-none"></div>
                  <div className="bg-white flex flex-col gap-3 px-[14px] py-3 border border-utilityGray200 rounded-lg max-w-[480px]">
                    <div className="flex flex-col gap-3">
                      <div className="flex gap-1 items-center justify-between">
                        <div className="flex items-center gap-2">
                          <p className="text-xs text-grayLight900 font-medium custom-loading">
                            Call summary
                          </p>

                          <p className="text-xs text-grayLight900 font-normal flex items-center gradient-text custom-loading">
                            Powered by Twilio Voice Intelligence &nbsp;
                            <img src={aiGradientStar} />
                          </p>
                        </div>
                        <div className="text-gray500 text-xs font-normal custom-loading">
                          View transcript
                        </div>
                      </div>
                      <ul className="list-disc ml-4 marker:text-primary">
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                        <li className="text-xs text-grayLight600 font-normal mb-[6px] custom-loading">
                          Lorem ipsum dolor sit amet, consectetur adipiscing.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                summary != '' && (
                  <div className="flex gap-2 ml-5">
                    <div className="w-6 h-6 rounded-bl-xl border border-borderPrimary border-t-0 border-r-0 flex-none"></div>
                    <div className="bg-white flex flex-col gap-3 px-[14px] py-3 border border-utilityGray200 rounded-lg max-w-[480px]">
                      <div className="flex flex-col gap-3">
                        <div className="flex gap-1 items-center justify-between">
                          <div className="flex items-center gap-2">
                            <p className="text-xs text-grayLight900 font-medium">
                              Call summary
                            </p>

                            <p className="text-xs text-grayLight900 font-normal flex items-center gradient-text">
                              Powered by Twilio Voice Intelligence &nbsp;
                              <img src={aiGradientStar} />
                            </p>
                          </div>
                          <div
                            className="text-gray500 text-xs font-normal"
                            onClick={handleViewTranscript}
                          >
                            View transcript
                          </div>
                        </div>
                        <ul className="list-disc ml-4 marker:text-primary">
                          <li className="text-xs text-grayLight600 font-normal mb-[6px]">
                            {summary}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default ThreadCommunicationCalls;
