import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import InputText from 'src/components/InputText/InputText';
import Map from 'src/components/Map/Map';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import TrimbleMap from 'src/components/TrimbleMap/Map';
import { BasicContext } from 'src/context/BasicContext';

const ConsigneeAddress = ({
  formData,
  consigneeOptions,
  validForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isConsigneeBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  consigneeAddress,
  setConsigneeAddress,
  isLoading,
  isShipperLoading,
  // isValidAddress,
  status,
}: any) => {
  const { mapService } = useContext(BasicContext);

  return (
    <>
      <div className="w-full xl:p-5 sm:p-4 pt-2 flex flex-wrap xl:gap-[51px] gap-6 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 sm:shadow-sm">
        <div className="lg:w-1/2 w-full">
          <h6 className="text-textSecondary text-sm font-bold mb-3.5">
            Add Consignee Address Details {isShipperLoading}
          </h6>
          {/* <p className=" text-grayLight600 text-xs font-normal mb-3.5">
            Enter consignee details below.
          </p> */}

          <div className="mb-5 relative">
            <SelectBox
              isClearable
              isSearchable
              name="consigneeId"
              className={`form_control ${
                !validForm.consigneeId
                  ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
                  : ''
              }`}
              labelClassName="form_label mb-1.5 block"
              classComp=""
              label="Select a Consignee"
              isLoading={isLoading || isShipperLoading}
              options={consigneeOptions}
              value={selectFormData.consigneeId}
              onChangeFunc={handleSelectChange('consigneeId')}
              placeholder="Select a consignee address"
              menuPlaceOption={'top'}
              // errorText={!validForm.consigneeId ? "Consignee is required" : null}
            />
            <div className="absolute right-0 top-0 flex">
              {selectFormData.consigneeId && (
                <button
                  onClick={handleActionType('consignee', 'delete')}
                  type="button"
                  className="text-xs flex mr-2"
                >
                  <TooltipCmp message="Delete Consignee">
                    <Trash01 className="h-4 w-4" />
                  </TooltipCmp>
                </button>
              )}
              <button
                onClick={saveShipperDetails('consignee')}
                type="button"
                className={`text-primary700 text-xs flex font-semibold gap-2 ${
                  isConsigneeBtnDisable && 'text-opacity-50'
                }`}
                disabled={isConsigneeBtnDisable}
              >
                {selectFormData.consigneeId ? (
                  <Edit05 className="w-4 h-4" />
                ) : (
                  <Plus className="w-4 h-4" />
                )}
                {selectFormData.consigneeId ? 'Edit' : 'Add'} New Consignee
              </button>
            </div>
          </div>
          <div className="mb-5">
            <InputText
              inputName="consigneeCompanyName"
              placeholder="Enter company name"
              className={`form_control ${
                !validForm.consigneeCompanyName
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              label="Company name"
              labelClassName="mb-1.5 block"
              value={formData.consigneeCompanyName}
              // errorText={
              //   !validForm.consigneeCompanyName
              //     ? 'Company name is required'
              //     : null
              // }
              onChangeFunc={handleInputChange}
            />
          </div>
          <div className="relative">
            {status &&
              (mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="consigneeAddress"
                  required
                  placeholder="Enter address"
                  dropdownWrapClass="!top-[unset] bottom-[65%]"
                  className={`form_control truncate ${
                    !validForm.consigneeAddress
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Address"
                  labelClassName="mb-1.5 block"
                  onChangeFunc={handleAutocompleteChange('consigneeAddress')}
                  onBlur={handleAutocompleteChange('consigneeAddress')}
                  isClearable
                  onAddressInputChange={setConsigneeAddress}
                  // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                  value={consigneeAddress}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="consigneeAddress"
                  required
                  placeholder="Enter address"
                  className={`form_control truncate ${
                    !validForm.consigneeAddress
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  label="Address"
                  listClassName="showAddressOnTop"
                  labelClassName="mb-1.5 block"
                  onChangeFunc={handleAutocompleteChange('consigneeAddress')}
                  onBlur={handleAutocompleteChange('consigneeAddress')}
                  isClearable
                  onAddressInputChange={setConsigneeAddress}
                  // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                  value={consigneeAddress}
                />
              ))}
            <button
              onClick={handleActionType(
                'consignee',
                formData.consigneeAddress.fullAddress
                  ? 'addressUpdate'
                  : 'addressCreate'
              )}
              type="button"
              className={`text-primary700 text-xs flex font-semibold gap-2.5 absolute right-0 top-0`}
              disabled={false}
            >
              {formData.consigneeAddress.fullAddress ? 'Change' : 'Select'}{' '}
              Address
            </button>
          </div>
          {/* <p className="text-xs font-normal text-red-600 dark:text-red-500 min-h-4 mt-1">
                        {!validForm.consigneeAddress ? "Address is required." : !isValidAddress.consigneeAddress ? "Please choose a valid address in the country, city, and postal code." : null}
                    </p> */}
        </div>
        <div className="lg:flex-1 xl:ml-[30px] w-full relative">
          {/* {formData.consigneeAddress && formData.consigneeAddress.fullAddress !== "" && (
                        <> */}
          {status &&
            (mapService === 'trimble_map' ? (
              <TrimbleMap
                className="rounded-[20px] bg-white"
                address={
                  formData.consigneeAddress &&
                  formData.consigneeAddress.fullAddress !== ''
                    ? formData.consigneeAddress
                    : null
                }
                id={'consigneeMap'}
                height={261}
                onReverseAddressFunc={handleAutocompleteChange(
                  'consigneeAddress'
                )}
              />
            ) : (
              <Map
                className="rounded-[20px] bg-white"
                address={
                  formData.consigneeAddress &&
                  formData.consigneeAddress.fullAddress !== ''
                    ? formData.consigneeAddress
                    : null
                }
                id={'consigneeMap'}
                height={261}
                onReverseAddressFunc={handleAutocompleteChange(
                  'consigneeAddress'
                )}
              />
            ))}
          {/* </>
                    )} */}
        </div>
      </div>
    </>
  );
};

export default ConsigneeAddress;
