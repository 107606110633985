/* eslint-disable max-lines-per-function */
import {
  ChevronLeftDouble,
  Plus,
  // ClockRewind,
  Send03,
  // Image01,
  MessageSquare01,
  DotsVertical,
  ChevronDown,
  ChevronUp,
  DotsHorizontal,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import ButtonCmp from 'src/components/ButtonCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import { AuthContext } from 'src/context/AuthContext';
import {
  aiSendMessage,
  threadChatListDetail,
  threadList,
} from 'src/services/AiChatbotService';
import WalToast from 'src/utils/WalToast';

import MessageListDataShowCmp from './MessageListDataShowCmp';
import PromptsCmp from './PromptsCmp';
import UpdateDeleteThread from './UpdateDeleteThread';

interface Message {
  id?: number;
  threadId: string;
  chatId?: number;
  message: string;
  sender: string;
  createdAt?: string;
  updatedAt?: string;
  assistant_row?: any;
  user_row?: any;
}

const initialParamsThreadList: any = {
  page: 1,
  limit: 5,
};

const initialParamsMessageList: any = {
  page: 1,
  limit: 10,
};

const ChatBot = () => {
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [editMessageValue, setEditMessageValue] = useState('');
  // console.log('editMessageValue :>> ', editMessageValue);
  const [messageListdata, setMessageListdata] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [isThreadList, setIsThreadList] = useState<any[]>([]);
  const [isUpdateDeleteThread, setIsUpdateDeleteThread] = useState('');
  const [isThreadData, setIsThreadData] = useState<object | any>(null);
  const [isThreadChatListDetail, setIsThreadChatListDetail] = useState<
    object | any
  >(null);
  // console.log('loading :>> ', loading);
  // console.log('currentUser :>> ', currentUser);
  // console.log('isThreadList :>> ', isThreadList);
  // console.log('isThreadData :>> ', isThreadData);
  // console.log('isThreadChatListDetail :>> ', isThreadChatListDetail);
  // console.log('messageListdata :>> ', messageListdata);
  const [threadListParams, setThreadListParams] = useState(
    initialParamsThreadList
  );
  const [messageListParams, setMessageListParams] = useState(
    initialParamsMessageList
  );
  // console.log('threadListParams :>> ', threadListParams);
  const [totalOfThreadList, setTotalOfThreadList] = useState<number>(0);
  const [totalOfMessageList, setTotalOfMessageList] = useState<number>(0);
  const [isScrollToBottom, setIsScrollToBottom] = useState(true);
  const [showMoreThreadList, setShowMoreThreadList] = useState<boolean>(false);
  const [isMaxHeightReached, setIsMaxHeightReached] = useState(false);
  const [isMaxHeightReachedEditMessage, setIsMaxHeightReachedEditMessage] =
    useState(false);
  console.log(
    'isMaxHeightReachedEditMessage :>> ',
    isMaxHeightReachedEditMessage
  );
  const [isEditMessage, setIsEditMessage] = useState<any>(null);
  // console.log('isEditMessage :>> ', isEditMessage);
  const [threadListLoading, setThreadListLoading] = useState(false);
  const [aiError, setAiError] = useState(false);
  // console.log('showMoreThreadList :>> ', showMoreThreadList);
  const textAreaRef = useRef<any | null>(null);
  const textAreaEditMessageRef = useRef<any | null>(null);
  const sendMessageThreadId = useRef<string | null>(null);
  // console.log('sendMessageThreadId555555555 :>> ', sendMessageThreadId);

  const handleInputChange = (e: any) => {
    setMessage(e?.target?.value);

    // Automatically adjust the height of the textarea
    const textarea: any = textAreaRef.current;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust based on content

    if (textarea.scrollHeight > 54) {
      setIsMaxHeightReached(true);
      // textarea.style.height = '160px'; // Lock height at max limit
    } else {
      setIsMaxHeightReached(false);
    }
  };

  const handleInputChangeEditMessage = (e: any) => {
    setEditMessageValue(e?.target?.value);

    // Automatically adjust the height of the textarea
    const textareaEditMessage: any = textAreaEditMessageRef.current;
    textareaEditMessage.style.height = 'auto'; // Reset the height
    textareaEditMessage.style.height = `${textareaEditMessage.scrollHeight}px`; // Adjust based on content

    if (textareaEditMessage.scrollHeight > 54) {
      setIsMaxHeightReachedEditMessage(true);
      // textarea.style.height = '160px'; // Lock height at max limit
    } else {
      setIsMaxHeightReachedEditMessage(false);
    }
  };

  const getThreadList = (
    threadListUrl: string,
    reachToPageGetAll: number = 0
  ): void => {
    setThreadListLoading(true);
    threadList(
      reachToPageGetAll
        ? `${threadListUrl}&reachToPageGetAll=${reachToPageGetAll}`
        : threadListUrl
    )
      .then((response: any) => {
        // console.log('response111 :>> ', response);
        setIsThreadList((prevThreadList) =>
          reachToPageGetAll
            ? response?.data ?? []
            : [...(prevThreadList ?? []), ...(response?.data ?? [])]
        );
        setTotalOfThreadList(response?.total);
      })
      .catch(() => {
        WalToast.error('Failed to fetch thread list');
      })
      .finally(() => {
        setThreadListLoading(false);
      });
  };

  useEffect(() => {
    getThreadList(
      `?page=${threadListParams?.page}&limit=${threadListParams?.limit}`
    );
  }, [threadListParams]);

  const getThreadChatListDetail = (
    threadChatListUrl: string,
    isScrollTocall: boolean = false
  ): void => {
    threadChatListDetail(`${threadChatListUrl}`)
      .then((response: any) => {
        if (isScrollTocall) {
          setIsScrollToBottom(false);
        }
        setIsThreadChatListDetail(response?.data);
        setMessageListdata((prevMessageList) => [
          ...(response?.data?.messagesList
            ? response.data.messagesList.reverse()
            : []),
          ...prevMessageList,
        ]);
        setTotalOfMessageList(response?.total);
        setMessageListParams((old: any) => ({
          ...old,
          ...{ page: old?.page + 1, limit: 10 },
        }));
      })
      .catch(() => {
        WalToast.error('Failed to fetch thread chat list detail');
        setIsThreadChatListDetail(null);
        setMessageListdata([]);
      })
      .finally(() => {});
  };

  const handleScroll = () => {
    if (
      chatContainerRef.current?.scrollTop === 0 &&
      messageListdata?.length > 0 &&
      totalOfMessageList > messageListParams?.limit &&
      isThreadChatListDetail?.thread?.threadId &&
      messageListParams?.page > 1 &&
      Math.ceil(totalOfMessageList / messageListParams?.limit) >=
        messageListParams?.page
    ) {
      getThreadChatListDetail(
        `/${isThreadChatListDetail?.thread?.threadId}?page=${messageListParams?.page}&limit=${messageListParams?.limit}`,
        true
      );
    }
  };

  useEffect(() => {
    const container = chatContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messageListdata]);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current && messageEndRef.current) {
      const container = chatContainerRef.current;
      const lastMessage = messageEndRef.current;
      const scrollTop = lastMessage.offsetTop - container.offsetTop;

      if (isScrollToBottom) {
        container.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      } else {
        container.scrollTo({
          top: 500,
          behavior: 'smooth',
        });
      }
    }
  };

  // Scroll to the bottom whenever messageListdata changes
  useEffect(() => {
    scrollToBottom();
  }, [messageListdata]);
  // const [error, setError] = useState(null);

  const errorThreadListToRemove = () => {
    setIsThreadList((prevThreadList) =>
      prevThreadList.filter((thread) => thread.threadId !== '')
    );
  };

  const onSendMessage = async (
    msg: string,
    isEditSendMessage: boolean = false,
    user_message_id: any = null,
    assitant_message_id: any = null,
    isRegenrate: boolean = false
  ) => {
    if (!msg || loading) {
      return;
    }
    setAiError(false);
    setLoading(true); // Set loader active
    setIsScrollToBottom(true);
    sendMessageThreadId.current =
      isThreadChatListDetail?.thread?.threadId ?? null;
    let tempThreadId = sendMessageThreadId.current;
    let payload;

    if (isEditSendMessage) {
      setMessageListdata((prevMessageList) => {
        const updatedMessageList = [...prevMessageList];
        let messageIndexUser = -1;

        if (user_message_id && assitant_message_id) {
          messageIndexUser = updatedMessageList.findIndex(
            (isMsg) => (isMsg?.user_row?.id || isMsg?.id) === user_message_id
          );
        } else {
          //new message to error coming than to the try to update message
          messageIndexUser = updatedMessageList.findIndex(
            (isMsg) =>
              (isMsg?.user_row?.threadId || isMsg?.threadId) === '' &&
              (isMsg?.user_row?.sender || isMsg?.sender) === 'user'
          );
        }

        if (messageIndexUser !== -1) {
          if (updatedMessageList[messageIndexUser]?.user_row?.id) {
            updatedMessageList[messageIndexUser].user_row.message = isRegenrate
              ? msg
              : editMessageValue;
          }
          updatedMessageList[messageIndexUser].message = isRegenrate
            ? msg
            : editMessageValue;
        }

        return updatedMessageList;
      });

      if (user_message_id && assitant_message_id) {
        payload = {
          user_id: currentUser?.id,
          thread_id: messageListdata?.[0]?.threadId,
          question: msg,
          isMessageEdit: true,
          user_message_id: user_message_id,
          assitant_message_id: assitant_message_id,
        };
      } else {
        payload = {
          user_id: currentUser?.id,
          thread_id:
            messageListdata?.length === 0
              ? ''
              : messageListdata?.[0]?.threadId
              ? messageListdata?.[0]?.threadId
              : '',
          question: msg,
        };
      }
      // console.log('payload :>> ', payload);
      setIsEditMessage(null);
    } else {
      if (!aiError) {
        setMessageListdata((prevMessageList) => [
          ...prevMessageList,
          {
            threadId: '',
            message: msg,
            sender: 'user',
          },
          {
            threadId: '',
            message: '',
            sender: 'assistant',
          },
        ]);
        textAreaRef.current.style.height = 'auto'; // Reset height
      }

      if (messageListdata?.length === 0 && !isThreadChatListDetail) {
        setIsThreadList((prevThreadList) => [
          { threadId: '', titleOfSummary: '', isTemporary: true },
          ...prevThreadList,
        ]);
      }
      setMessage('');
      payload = {
        user_id: currentUser?.id,
        thread_id:
          messageListdata?.length === 0
            ? ''
            : messageListdata?.[0]?.threadId
            ? messageListdata?.[0]?.threadId
            : '',
        question: msg,
      };
    }

    aiSendMessage(payload)
      .then((response) => {
        // console.log('response :>> ', response);

        if (
          sendMessageThreadId.current ===
            isThreadChatListDetail?.thread?.threadId ||
          sendMessageThreadId.current === tempThreadId
        ) {
          if (isEditSendMessage) {
            setMessageListdata((prevMessageList) => {
              const updatedMessageList = [...prevMessageList];

              let messageIndexUser = -1;
              let messageIndexAssistant = -1;

              if (user_message_id && assitant_message_id) {
                messageIndexUser = updatedMessageList.findIndex(
                  (isMsg) =>
                    (isMsg?.user_row?.id || isMsg?.id) === user_message_id
                );

                messageIndexAssistant = updatedMessageList.findIndex(
                  (isMsg) =>
                    (isMsg?.assistant_row?.id || isMsg?.id) ===
                    assitant_message_id
                );
              } else {
                //new message to the try to update message
                messageIndexUser = updatedMessageList.findIndex(
                  (isMsg) =>
                    (isMsg?.user_row?.threadId || isMsg?.threadId) === '' &&
                    (isMsg?.user_row?.sender || isMsg?.sender) === 'user'
                );

                messageIndexAssistant = updatedMessageList.findIndex(
                  (isMsg) =>
                    (isMsg?.assistant_row?.threadId || isMsg?.threadId) ===
                      '' &&
                    (isMsg?.assistant_row?.sender || isMsg?.sender) ===
                      'assistant'
                );
              }

              if (messageIndexUser !== -1) {
                if (updatedMessageList[messageIndexUser]?.user_row?.id) {
                  updatedMessageList[messageIndexUser].user_row =
                    response?.data?.user_row;
                }
                updatedMessageList[messageIndexUser].message = isRegenrate
                  ? msg
                  : editMessageValue;
              }

              if (messageIndexAssistant !== -1) {
                if (
                  updatedMessageList[messageIndexAssistant]?.assistant_row?.id
                ) {
                  updatedMessageList[messageIndexAssistant].assistant_row =
                    response?.data?.assistant_row;
                }
                updatedMessageList[messageIndexAssistant].message =
                  response?.data?.response;
              }

              return updatedMessageList;
            });
            setEditMessageValue('');
          } else {
            setMessageListdata((prevMessageList) => {
              const updatedMessageList = [...prevMessageList];

              updatedMessageList[updatedMessageList.length - 2].threadId =
                response?.data?.thread_id;
              updatedMessageList[updatedMessageList.length - 2].user_row =
                response?.data?.user_row;
              updatedMessageList[updatedMessageList.length - 1].threadId =
                response?.data?.thread_id;
              updatedMessageList[updatedMessageList.length - 1].message =
                response?.data?.response;
              updatedMessageList[updatedMessageList.length - 1].assistant_row =
                response?.data?.assistant_row;

              return updatedMessageList;
            });
          }
        }

        if (
          (messageListdata?.length === 0 || messageListdata?.length === 2) &&
          !isThreadChatListDetail
        ) {
          setIsThreadList((prevThreadList) => {
            const updatedThreadList = [...prevThreadList];
            // console.log(
            //   'updatedThreadList?.length :>> ',
            //   updatedThreadList?.length
            // );
            updatedThreadList[0].threadId = response?.data?.thread_id;
            updatedThreadList[0].titleOfSummary = response?.data?.chat_title;
            updatedThreadList[0].isTemporary = false;

            return updatedThreadList;
          });

          if (sendMessageThreadId.current === tempThreadId) {
            getThreadChatListDetail(
              `/${response?.data?.thread_id}?page=${1}&limit=${
                messageListParams?.limit
              }`
            );
          }
          // console.log('tempThreadListLength :>> ', tempThreadListLength);
          // console.log('isThreadList.length :>> ', isThreadList.length);
          getThreadList(`?page=${1}&limit=${10}`, isThreadList.length + 1);
        } else {
          setIsThreadList((prevThreadList) => {
            // Create a shallow copy of the thread list
            const updatedThreadList = [...prevThreadList];

            // Find the index of the thread with the matching threadId
            const threadIndex = updatedThreadList.findIndex(
              (thread) => thread.threadId === response?.data?.thread_id
            );

            // If found, update only the titleOfSummary for that thread
            if (threadIndex !== -1) {
              updatedThreadList[threadIndex] = {
                ...updatedThreadList[threadIndex],
                titleOfSummary: response?.data?.chat_title
                  ? response?.data?.chat_title
                  : updatedThreadList[threadIndex]?.titleOfSummary,
              };
            }

            return updatedThreadList;
          });
        }
      })
      .catch(() => {
        if (sendMessageThreadId.current === tempThreadId) {
          // WalToast.error('Failed to get response from ai');
          setAiError(true);
        } else {
          errorThreadListToRemove();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // If Shift + Enter, allow the default behavior (add a new line)
        return;
      } else {
        // If Enter without Shift, prevent the default behavior (no new line)
        e.preventDefault();

        if (message.trim()) {
          onSendMessage(message.trim()); // Send the message/
        }
      }
    }
  };

  const handleKeyDownEditMessage = (
    e: any,
    user_id: any = null,
    assistant_id: any = null
  ) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // If Shift + Enter, allow the default behavior (add a new line)
        return;
      } else {
        // If Enter without Shift, prevent the default behavior (no new line)
        e.preventDefault();

        if (editMessageValue.trim()) {
          onSendMessage(editMessageValue.trim(), true, user_id, assistant_id); // Send the message/
        }
      }
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const tooltipItems = (data: any) => (
    <div className="menu bg-base-100 rounded-box w-[150px] p-0 text-xs font-semibold">
      <div
        className="p-2 rounded-md hover:bg-gray50"
        onClick={() => {
          setIsUpdateDeleteThread('update');
          setIsThreadData(data);
        }}
      >
        <div className="flex leading-[18px] items-center ">Rename</div>
      </div>
      <div
        className="p-2 rounded-md hover:bg-gray50"
        onClick={() => {
          setIsUpdateDeleteThread('delete');
          setIsThreadData(data);
        }}
      >
        <div className="flex leading-[18px] items-center ">Delete</div>
      </div>
    </div>
  );

  return (
    <>
      <PageSectionLayout
        header={
          <>
            <Header
              title="Copilot"
              desc="AI-powered assistant for seamless access to insights across all modules."
              mainContainerClassName="xxl:px-6 px-5"
            />
          </>
        }
        contentClassName="!p-0 overflow-hidden !flex-row"
        headerClassName="!px-0"
      >
        <div
          className={` border-r border-utilityGray200 rounded-tl-3xl bg-gray25 flex-col items-start flex transition-all duration-[0.3s] pb-4 ${
            isOpen ? 'w-[307px]' : 'w-[61px]'
          } `}
        >
          <div
            className={`px-3 py-5 border-b border-utilityGray200 w-full items-center flex `}
          >
            <ButtonCmp
              className="btn_secondary_black lg:px-[9px] px-2 max-sm:hidden"
              onClick={toggleSidebar}
              icon={
                <ChevronLeftDouble
                  className={`w-4 h-4 transition-all duration-[0.2s]  ${
                    isOpen ? '' : 'rotate-180'
                  }`}
                />
              }
            >
              <></>
            </ButtonCmp>
          </div>
          <div
            // onClick={() => !isOpen && toggleSidebar()}
            className={`mt-7 px-3 w-full`}
          >
            <div className="mb-4">
              <ButtonCmp
                className={`btn-outline-primary overflow-hidden flex-nowrap lg:px-[9px] px-2 justify-start max-sm:hidden whitespace-nowrap transition-all duration-[0.3s] rounded-full ${
                  isOpen ? 'w-[114px]' : 'w-10'
                } `}
                icon={<Plus className="w-5 h-5 flex-none" />}
                onClick={() => {
                  if (!isOpen) {
                    toggleSidebar();
                  }

                  if (aiError) {
                    errorThreadListToRemove();
                  }
                  setMessageListdata([]);
                  setIsThreadChatListDetail(null);
                  setAiError(false);
                  setIsScrollToBottom(true);
                  setMessageListParams((old: any) => ({
                    ...old,
                    ...{ page: 1, limit: 10 },
                  }));
                }}
                disabled={loading}
              >
                {isOpen && (
                  <span className="transition-all duration-[0.3s] overflow-hidden font-semibold text-sm">
                    New Chat
                  </span>
                )}
              </ButtonCmp>
            </div>
          </div>
          {isThreadList?.length !== 0 && (
            <div
              className={`overflow-y-auto overflow-x-hidden w-full px-3 custom-scrollbar-v2 visible transition-all flex-1 ${
                isOpen ? 'w-full' : 'w-[0] invisible'
              }`}
            >
              <p className="text-sm font-medium my-2  text-grayLight600">
                Recent
              </p>
              <ul className="mb-0 text-sm text-grayLight600">
                {(showMoreThreadList
                  ? isThreadList
                  : isThreadList.slice(0, threadListParams?.limit)
                )?.map((data, index) => (
                  <>
                    {loading && !data?.titleOfSummary && isOpen ? (
                      <div className="h-10 custom-loading mb-1 rounded-md">
                        jkhjkh
                      </div>
                    ) : (
                      <>
                        <li
                          className={`mb-1 flex px-4 py-2 items-center gap-3 rounded-md cursor-pointer transition-all group/item  relative ${
                            isThreadChatListDetail?.thread?.threadId ===
                              data?.threadId ||
                            (!data?.createdAt &&
                              index === 0 &&
                              !isThreadChatListDetail)
                              ? 'text-grayLight900 bg-primary100'
                              : 'text-grayLight600 hover:bg-utilityindigo25 hover:text-grayLight900'
                          }`}
                          key={index}
                          onClick={() => {
                            if (
                              isThreadChatListDetail?.thread?.threadId !==
                                data?.threadId &&
                              !data?.isTemporary
                            ) {
                              if (aiError) {
                                errorThreadListToRemove();
                              }
                              getThreadChatListDetail(
                                `/${data?.threadId}?page=${1}&limit=${
                                  messageListParams?.limit
                                }`
                              );
                              sendMessageThreadId.current = 'temptonull';
                              setIsEditMessage(null);
                              setEditMessageValue('');
                              setAiError(false);
                              setMessageListdata([]);
                              setIsThreadChatListDetail(null);
                              setIsScrollToBottom(true);
                              setMessageListParams((old: any) => ({
                                ...old,
                                ...{ page: 1, limit: 10 },
                              }));
                            }
                          }}
                        >
                          <MessageSquare01 className="w-4 h-4 flex-none" />

                          <span className="truncate flex-auto text-sm font-medium">
                            {data?.titleOfSummary || 'New chat'}
                          </span>
                          <span
                            className={`w-6 h-6 basis-6 grow-0 shrink-0 flex justify-center items-center rounded-full cursor-pointer transition-all  invisible group-hover/item:visible ${
                              isThreadChatListDetail?.thread?.threadId ===
                              data?.threadId
                                ? 'hover:bg-utilityindigo100'
                                : 'hover:bg-white'
                            } `}
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsOpenSubMenu(true);
                            }}
                          >
                            <ActionTooltip
                              message={
                                isOpenSubMenu ? tooltipItems(data) : null
                              }
                              isOpenChildTopPosOnTop={true}
                              tooltipPosition="left"
                              parentClassName="!py-1 !border-gray100"
                              type="light"
                            >
                              <DotsVertical width={16} height={16} />
                            </ActionTooltip>
                          </span>
                        </li>
                        {totalOfThreadList > threadListParams?.limit &&
                          index === threadListParams?.limit - 1 && (
                            <li
                              className={`mb-1 flex px-4 py-2 items-center gap-3 rounded-full cursor-pointer transition-all group/item  relative ${'text-grayLight600 hover:bg-utilityindigo25 hover:text-grayLight900'}`}
                              key={`${index}-${threadListParams?.page}`}
                              onClick={() => {
                                if (threadListParams?.page === 1) {
                                  setThreadListLoading(true);
                                  setThreadListParams((old: any) => ({
                                    ...old,
                                    ...{ page: old?.page + 1, limit: 5 },
                                  }));
                                }
                                setShowMoreThreadList(!showMoreThreadList);
                              }}
                            >
                              {showMoreThreadList ? (
                                <ChevronUp className="w-4 h-4" />
                              ) : (
                                <ChevronDown className="w-4 h-4" />
                              )}

                              <span className="truncate flex-auto text-sm font-medium">
                                {showMoreThreadList ? 'Show less' : 'Show more'}
                              </span>
                            </li>
                          )}
                        {!threadListLoading &&
                          totalOfThreadList > isThreadList?.length &&
                          isThreadList?.length === index + 1 &&
                          threadListParams?.page !== 1 && (
                            <li
                              className={`mb-1 flex px-4 py-2 items-center gap-3 rounded-md cursor-pointer transition-all group/item  relative ${'text-grayLight600 hover:bg-utilityindigo25 hover:text-grayLight900'}`}
                              key={`${index}-${threadListParams?.page}-load-more`}
                              onClick={() => {
                                setThreadListParams((old: any) => ({
                                  ...old,
                                  ...{ page: old?.page + 1, limit: 5 },
                                }));
                              }}
                            >
                              <DotsHorizontal className="w-4 h-4" />
                              <span className="truncate flex-auto text-sm font-medium">
                                {'Load more'}
                              </span>
                            </li>
                          )}
                      </>
                    )}
                  </>
                ))}
              </ul>
            </div>
          )}

          {/* <div
            onClick={() => !isOpen && toggleSidebar()}
            className={`p-3 w-full flex justify-between items-center`}
          >
            <div className="flex items-center justify-items-start w-full">
              <div className="relative w-6 h-6">
                {!isOpen && (
                  <span className="w-2 h-2 rounded-full bg-primary absolute -right-0.5 -top-1"></span>
                )}
                <ClockRewind className="w-6 h-6 text-gray500 cursor-pointer" />
              </div>
              {isOpen && (
                <div className="flex items-center ml-3 flex-auto">
                  <span className="flex-auto text-sm font-semibold text-grayLight600 ">
                    Activity
                  </span>
                  <span className="w-2 h-2 rounded-full bg-primary"></span>
                </div>
              )}
            </div>
          </div> */}
        </div>
        <div
          className={`w-full overflow-auto transition-all duration-[0.3s] flex flex-col flex-1 justify-end ${
            isOpen ? '' : ''
          } `}
        >
          <div
            ref={chatContainerRef}
            className="w-full flex flex-col overflow-y-auto gemini-scrollbar mt-5"
          >
            <div className="max-w-[812px] w-full mx-auto mt-auto flex flex-col">
              {messageListdata.length === 0 ? (
                <div className="mb-6 mt-10">
                  <p className="text-4xl gradient-text font-semibold text-transparent bg-clip-text tracking-[-2%] w-fit">
                    Hello, {`${currentUser?.firstName}`}
                  </p>
                  <p className=" text-3xl tracking-[-2%] font-medium text-senary300 mt-1">
                    How can i help you today?
                  </p>
                  <PromptsCmp onSendMessage={onSendMessage} />
                </div>
              ) : (
                <MessageListDataShowCmp
                  messageListdata={messageListdata}
                  messageEndRef={messageEndRef}
                  currentUser={currentUser}
                  isEditMessage={isEditMessage}
                  textAreaEditMessageRef={textAreaEditMessageRef}
                  editMessageValue={editMessageValue}
                  handleInputChangeEditMessage={handleInputChangeEditMessage}
                  handleKeyDownEditMessage={handleKeyDownEditMessage}
                  onSendMessage={onSendMessage}
                  setIsEditMessage={setIsEditMessage}
                  setEditMessageValue={setEditMessageValue}
                  loading={loading}
                  sendMessageThreadId={sendMessageThreadId}
                  isThreadChatListDetail={isThreadChatListDetail}
                  aiError={aiError}
                />
              )}
            </div>
          </div>
          <div className="w-full pb-[43px]">
            {!aiError && (
              <div
                className={`relative flex justify-center items-end w-full max-w-[812px]  mx-auto px-3 ${
                  isMaxHeightReached ? 'rounded-xl py-4' : 'rounded-full py-2'
                } border border-borderPrimary"`}
              >
                {/* <Image01 className="absolute top-1/2 -translate-y-1/2 left-5 text-grayLight900 h-5 w-5 cursor-pointer" /> */}
                <textarea
                  ref={textAreaRef}
                  name="askCopilot"
                  className="resize-none scrollbar-hide w-full overflow-y-auto !p-2 border-none ignore-blur text-sm placeholder:text-secondaryTxtColor focus:outline-none max-h-56"
                  placeholder="Ask HQ Copilot"
                  value={message}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  rows={1}
                />
                <Send03
                  className={` h-6 w-6  mb-1.5 ${
                    loading
                      ? 'cursor-default text-grayLight600 loading'
                      : 'cursor-pointer text-grayLight900'
                  }`}
                  onClick={() => onSendMessage(message.trim())}
                />
              </div>
            )}
            <p className="text-sm font-normal text-gray500 text-center mt-3">
              HQ Copilot can make mistakes, so double-check it
            </p>
          </div>
        </div>
      </PageSectionLayout>
      {isUpdateDeleteThread !== '' && (
        <UpdateDeleteThread
          handleClose={() => {
            setIsUpdateDeleteThread('');
            setIsThreadData(null);
          }}
          isUpdateDeleteThread={isUpdateDeleteThread}
          isThreadData={isThreadData}
          handleSuccess={() => {
            if (isUpdateDeleteThread === 'delete') {
              setMessageListdata([]);
            }
            getThreadList(`?page=${1}&limit=${10}`, isThreadList?.length);
            setIsUpdateDeleteThread('');
            setIsThreadData(null);
          }}
        />
      )}
    </>
  );
};

export default ChatBot;
