import {
  ArrowLeft,
  ArrowRight,
  CalendarDate,
  ChevronDown,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import { getDateRange } from '../../utils/CommonFunctions';
import ButtonCmp from '../ButtonCmp';
import SelectBox from '../SelectBox/SelectBox';

import { TimeRanges } from './../../../src/app/SalesDashboard/OverView/OverView.interface';

interface IProps {
  handleDatePickerChange: (val: any, type?: string | undefined) => void;
  startDate: any;
  containerClassName?: any;
  parentClassName?: any;
  endDate: any;
  minDate?: any;
  selectedTimeRange: string;
  allTimeStartDate: any;
  isToday?: boolean;
  isShowDropDownIcon?: boolean;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  showYearDropdown?: boolean;
  onPrevNextClick?: (type: string) => void;
  previewBoxInnerTitle?: string;
  placeholder?: string;
  textWrapClass?: string;
  calendarClass?: string;
  dateRangeWrapClass?: string;
  containerWrapClassName?: string;
  setTodayAsDefaultOnClear?: boolean;
}

const DateRangePicker = ({
  handleDatePickerChange,
  onPrevNextClick,
  startDate,
  endDate,
  selectedTimeRange,
  allTimeStartDate,
  containerClassName,
  // minDate,
  textWrapClass,
  calendarClass,
  parentClassName,
  isToday = false,
  isShowDropDownIcon = true,
  showYearDropdown = true,
  isPrevDisabled = false,
  isNextDisabled = false,
  previewBoxInnerTitle = '',
  placeholder,
  dateRangeWrapClass,
  containerWrapClassName,
  setTodayAsDefaultOnClear = false,
}: IProps) => {
  const uiState = useSelector((state: any) => state.MobileView);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [dateRange, setDateRange] = useState<any>([]);
  const [startDateCurrent, setStartDateCurrent] = useState<any>(startDate);
  const [endDateCurrent, setEndDateCurrent] = useState<any>(endDate);
  const [timeRangeCurrent, setTimeRangeCurrent] = useState(
    selectedTimeRange ?? 'custom'
  );
  const [isOpenDropDown, setIsOpenDropDown] = useState<any>(false);
  const [isSelectedStart, setIsSelectedStart] = useState(true);

  useEffect(() => {
    setStartDateCurrent(startDate);
  }, [startDate]);
  useEffect(() => {
    setEndDateCurrent(endDate);
  }, [endDate]);
  useEffect(() => {
    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, [endDate, startDate]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpenDropDown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  const handleTimeRangeChangesCurrent = (val: any) => {
    if (val !== 'custom') {
      const { start, end } = getDateRange(val, allTimeStartDate);
      setDateRange([start, end]);
      setStartDateCurrent(start);
      setEndDateCurrent(end);
    } else {
      const date = moment().toDate();
      setDateRange([date, date]);
      setStartDateCurrent(date);
      setEndDateCurrent(date);
    }
    setTimeRangeCurrent(val);
  };

  useEffect(() => {
    if (isOpenDropDown) {
      setTimeRangeCurrent(selectedTimeRange);
      setStartDateCurrent(startDate ?? null);
      setEndDateCurrent(endDate ?? null);
      const observer = new MutationObserver(() => {
        const focusedOption = document.querySelector(
          '.select__option--is-focused'
        );

        if (focusedOption) {
          focusedOption.classList.remove('select__option--is-focused');
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    }
  }, [isOpenDropDown, selectedTimeRange]);

  const handleDateChange = (dates: any) => {
    const [start, end] = dates;
    let dateRangeVal: any[] = [];
    setIsSelectedStart(true);

    if (start && end) {
      setStartDateCurrent(start);
      setEndDateCurrent(end);
      if (dates[1]) setDateRange(dates);
    } else {
      if (!startDateCurrent || endDateCurrent) {
        // if (start > startDateCurrent && start <= endDateCurrent) {
        //     console.log(isTodayClick);

        //     setEndDateCurrent(start); // Reset the end date if a new range is being started
        //     dateRangeVal = [startDateCurrent, start];
        //     setIsSelectedStart(false);
        // }
        // // else if (start < startDateCurrent && start <= endDateCurrent) {
        // //     console.log("222");
        // //     setStartDateCurrent(start);
        // //     dateRangeVal = [start, endDateCurrent];
        // // }
        // else {
        setStartDateCurrent(start);
        setEndDateCurrent(null); // Reset the end date if a new range is being started
        dateRangeVal = [start, null];
        // }
      } else if (start < startDateCurrent) {
        // If the selected date is before the start date, make it the new start date
        setEndDateCurrent(startDateCurrent);
        setStartDateCurrent(start);
        dateRangeVal = [start, startDateCurrent];
      } else {
        // Otherwise, set it as the end date
        dateRangeVal = [null, null];

        if (start) {
          setEndDateCurrent(start);
          dateRangeVal = [startDateCurrent, start];
        }
      }
      setDateRange(dateRangeVal);
    }

    // Update the custom time range selection
    setTimeRangeCurrent('custom');
  };

  const getFormattedDate = (date: any) => {
    const momentDate = moment(date);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (momentDate.isSame(today, 'day')) {
      return `Today, ${momentDate.format('MMM Do, YYYY')}`;
    } else if (momentDate.isSame(yesterday, 'day')) {
      return `Yesterday, ${momentDate.format('MMM Do, YYYY')}`;
    } else if (momentDate.isSame(tomorrow, 'day')) {
      return `Tomorrow, ${momentDate.format('MMM Do, YYYY')}`;
    } else {
      return momentDate.format('dddd, MMM Do, YYYY');
    }
  };

  return (
    <div
      className={`relative w-full cursor-pointer ${parentClassName}`}
      ref={datePickerRef}
    >
      <div
        className={`w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px] ${dateRangeWrapClass} ${''} justify-between items-center gap-1.5 flex ${
          timeRangeCurrent === 'custom' && !isToday && startDate && endDate
            ? 'bg-gray50'
            : 'bg-white'
        }  `}
      >
        {onPrevNextClick && startDate && (
          <ArrowLeft
            className={`xxl:w-4 xxl:h-4 xls:w-3 xls:h-3 w-4 h-4 ${
              !isPrevDisabled && !startDate
                ? 'text-grayText cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            onClick={() => onPrevNextClick('prev')}
          />
        )}
        <div
          className={`flex flex-1 justify-center items-center ${textWrapClass}`}
          onClick={() => setIsOpenDropDown(!isOpenDropDown)}
        >
          {previewBoxInnerTitle && (
            <div className="text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none mr-4">
              {previewBoxInnerTitle} :{' '}
            </div>
          )}
          <CalendarDate
            className={`w-4 h-4 xl:mr-2 mr-1 ${
              !startDate && !endDate ? 'text-gray-500' : ''
            } ${calendarClass}`}
          />
          <div className="text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none">
            {startDate && endDate ? (
              timeRangeCurrent === 'custom' || timeRangeCurrent === 'daily' ? (
                <>
                  {moment(startDateCurrent).format('DD-MM-YYYY') ===
                  moment(endDateCurrent).format('DD-MM-YYYY')
                    ? `${getFormattedDate(startDate)}`
                    : `${moment(startDateCurrent)
                        .local()
                        .format('MMM Do, YYYY')} -
                                    ${moment(
                                      endDateCurrent
                                        ? endDateCurrent
                                        : startDateCurrent
                                    ).format('MMM Do, YYYY')}`}
                </>
              ) : moment(startDateCurrent).format('DD-MM-YYYY') ===
                moment(endDateCurrent).format('DD-MM-YYYY') ? (
                `${getFormattedDate(startDateCurrent)}`
              ) : (
                <>
                  {startDateCurrent &&
                    moment(startDateCurrent).format('MMM Do, YYYY')}{' '}
                  -{' '}
                  {moment(
                    endDateCurrent
                      ? endDateCurrent
                      : startDateCurrent && startDateCurrent
                  ).format('MMM Do, YYYY')}
                </>
              )
            ) : (
              <div className="text-xs text-gray-500">
                {placeholder ?? 'No date selected'}
              </div>
            )}
          </div>
        </div>
        {isShowDropDownIcon && <ChevronDown className="w-4 h-4" />}

        {onPrevNextClick && endDate && (
          <ArrowRight
            className={`xxl:w-4 xxl:h-4 xls:w-3 xls:h-3 w-4 h-4 ${
              !isNextDisabled && !endDate
                ? 'text-grayText cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            onClick={() => onPrevNextClick('next')}
          />
        )}
      </div>
      <div className={isOpenDropDown ? containerWrapClassName : ''}>
        <div
          className={`${containerClassName} date-picker-menu bg-white absolute border-utilityGray200 border rounded-xl mt-1 flex z-[2]`}
        >
          <SelectBox
            name="timeRangeListGroup"
            id="timeRangeListGroup"
            className="form_control shadow"
            size="sm"
            classComp="hide-select-value-container lg:block hidden"
            options={TimeRanges}
            isCloseOnScroll={false}
            inlineSelect={true}
            onMenuOpen={() => setIsOpenDropDown(true)}
            onChangeFunc={(event: any) =>
              handleTimeRangeChangesCurrent(event.value)
            }
            value={TimeRanges.find((val) => val.value === timeRangeCurrent)}
            defaultValue={TimeRanges.find(
              (val) => val.value === timeRangeCurrent
            )}
            menuIsOpen={isOpenDropDown}
          />

          {isOpenDropDown && (
            <div className="w-full left-[61%] custom-time-range lg:border-l border-utilityGray200 flex flex-col">
              <div className="flex-grow relative w-full">
                {timeRangeCurrent === 'custom' ? (
                  <DatePicker
                    selected={
                      isSelectedStart ? startDateCurrent : endDateCurrent
                    }
                    className="form_control w-[132px] form_input_control"
                    onChange={(date: any) => handleDateChange(date)}
                    startDate={startDateCurrent}
                    endDate={endDateCurrent}
                    // minDate={minDate}
                    monthsShown={uiState.isMobile ? 1 : 2}
                    showYearDropdown={showYearDropdown}
                    selectsRange={true}
                    inline={isOpenDropDown}
                  />
                ) : (
                  <DatePicker
                    selected={
                      new Date(moment(startDateCurrent).format('MMM DD, YYYY'))
                    }
                    className="form_control w-[132px] form_input_control"
                    onChange={(date: any) => handleDateChange(date)}
                    startDate={
                      new Date(moment(startDateCurrent).format('MMM DD, YYYY'))
                    }
                    endDate={
                      new Date(moment(endDateCurrent).format('MMM DD, YYYY'))
                    }
                    // minDate={minDate}
                    monthsShown={uiState.isMobile ? 1 : 2}
                    showYearDropdown={showYearDropdown}
                    selectsRange={true}
                    inline={isOpenDropDown}
                  />
                )}
              </div>
              <div className="w-full p-4 border-t border-gray-200 justify-between items-start flex lg:flex-row flex-col gap-3">
                <div className="justify-start items-center lg:gap-3 sm:gap-2 gap-1 flex text-xs font-normal leading-tight lg:w-auto w-full">
                  <ButtonCmp
                    className="lg:flex-auto flex-1 btn_secondary_black"
                    onClick={() => {
                      handleDateChange([moment().toDate(), moment().toDate()]);
                      setDateRange([moment().toDate(), moment().toDate()]);
                    }}
                  >
                    Today
                  </ButtonCmp>
                  <ButtonCmp
                    className="lg:flex-auto flex-1 btn_secondary_black"
                    onClick={() => {
                      if (setTodayAsDefaultOnClear) {
                        handleDateChange([
                          moment().toDate(),
                          moment().toDate(),
                        ]);
                        setDateRange([moment().toDate(), moment().toDate()]);
                      } else {
                        handleDateChange([]);
                        setDateRange([]);
                      }
                    }}
                  >
                    Clear
                  </ButtonCmp>
                </div>
                <div className="gap-3 flex lg:w-auto w-full">
                  <ButtonCmp
                    className="lg:flex-auto flex-1 btn_secondary_black"
                    onClick={() => setIsOpenDropDown(false)}
                  >
                    Cancel
                  </ButtonCmp>
                  <ButtonCmp
                    className="lg:flex-auto flex-1 btn_primary"
                    onClick={() => {
                      handleDatePickerChange(dateRange, timeRangeCurrent);
                      setIsOpenDropDown(false);
                    }}
                  >
                    Apply
                  </ButtonCmp>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
