import { yupResolver } from '@hookform/resolvers/yup';
import { CurrencyDollar, FilePlus02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { modifyClaimAmount } from 'src/services/ClaimService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import { handleInputChange } from '../ClaimsDisputes.const';

const modifyClaimAmountSchema = yup.object().shape({
  claimAmount: yup
    .number()
    .required('Claim Amount is required.')
    .typeError('Claim amount must be a valid number')
    .min(1, 'Claim amount must be greater than zero')
    .max(999999, 'Claim amount is not valid'),
});

interface IProps {
  handleClose: any;
  claim: any;
  getClaim: any;
  setClaim?: any;
}

const ModifyClaimAmount = ({
  handleClose,
  claim,
  getClaim,
  setClaim,
}: IProps) => {
  const { reloadCounts } = useContext(EntityCountsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modifyClaimAmountSchema),
    defaultValues: {
      claimAmount: claim?.finalClaimAmount
        ? claim?.finalClaimAmount
        : claim?.claimAmount,
    },
  });

  const onSubmit = (formData: any) => {
    setIsLoading(true);

    // if value unchanged then not doing api call
    // if (formData?.claimAmount === claim?.claimAmount) {
    //   handleClose();

    //   return;
    // }

    const appendFormData: any = new FormData();
    appendFormData.append('claimID', claim?.claimID);
    appendFormData.append('claimAmount', formData?.claimAmount);

    modifyClaimAmount(appendFormData)
      .then(() => {
        getClaim();

        if (setClaim) {
          setClaim((prevClaim: any) => ({
            ...prevClaim,
            finalClaimAmount: formData?.claimAmount,
          }));
        }

        reloadCounts();
        handleClose();
      })
      .catch((e: any) => {
        WalToast.error(
          e?.response?.data?.message ?? 'Issue modifying claim amount'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const headerIcon = <FilePlus02 className="w-7 h-7" />;

  return (
    <CommonModal
      title={`Modify Claim Amount`}
      titleDesc={`View and manage your claim details`}
      handleClose={handleClose}
      size={'max-w-[480px]'}
      headerIcon={headerIcon}
      primaryBtnText="Modify"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        <Controller
          name="claimAmount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputType="number"
              inputName="claimAmount"
              label="Claim Amount"
              parentClassName="mb-4"
              placeholder="Enter Claim Amount"
              className="pl-8"
              value={`${parseFloat(value)}`}
              onChangeFunc={(e) => handleInputChange(e, 'change', onChange)}
              onBlur={(e) => handleInputChange(e, 'blur', onChange)}
              labelClassName="block mb-1.5"
              errorText={errors?.claimAmount?.message ?? ''}
              shouldFormatNumber={true}
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default ModifyClaimAmount;
