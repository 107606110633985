import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { listNotification } from 'src/services/NotificationService';
import { setUserStatus } from 'src/services/UserService';
import { fetchJsFromCDN, removeHtmlTags } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { AuthContext } from './AuthContext';
import { EntityCountsContext } from './EntityCountsContext';

export const NotificationsContext = createContext<any>(null);

export const initParams = {
  currentTab: 'viewall',
  page: 1,
  limit: 5,
  type: ['claim', 'dispute'],
};

export const NotificationsProvider = (props: any) => {
  const { children } = props;
  const [notificationParam, setNotificationParams] = useState(initParams);
  const [notifications, setNotifications] = useState([]);
  const [notificationTotals, setNotificationTotals] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [entityType, setEntityType] = useState('');
  const [entityId, setEntityId] = useState(0);

  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const EntityCounts = useContext(EntityCountsContext);
  const { setNotificationsCount } = useContext(EntityCountsContext);
  const notificaitonReloadCounts = EntityCounts.reloadCounts;
  const { isSocketConnected } = useSelector(
    (state: any) => state.SocketConnection
  );

  const fetchNotfications = async () => {
    if (!isAuthenticated) {
      return;
    }

    setIsNotificationLoading(true);
    setNotifications([]);
    const params = { ...notificationParam, userId: currentUser.id };

    listNotification(params)
      .then((response: any) => {
        if (response.data) {
          const responseData = response.data;
          setNotifications(responseData);
          setNotificationTotals(response.total);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsNotificationLoading(false);
      });
  };

  useEffect(() => {
    if (currentUser && currentUser?.id && isSocketConnected) {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            io.socket.get(
              `/subscribe/notification-user-${currentUser.id}`,
              (details: any, jwr: any) => {
                if (jwr.error) {
                  console.log('jwr.error', jwr.error);

                  return;
                }
              }
            );

            setUserStatus({
              status: 'online',
              userId: currentUser.id,
            })
              .then(() => {})
              .catch(console.log);

            return () => {
              setUserStatus({
                status: 'offline',
                userId: currentUser.id,
              })
                .then(() => {})
                .catch(console.log);
            };
          });

          io.socket.on('new-notification', (data: any) => {
            if (data) {
              const message = removeHtmlTags(data.message);
              WalToast.success(data.subject ?? 'Subject', message);

              if (
                [
                  'carrier_created',
                  'team_created',
                  'team_member_created',
                  'customer_sync_created',
                  'order_created',
                  'load_insurance_created',
                  'feedback_create',
                  'credit_increase_request_created',
                  'quote_request_arrived',
                ].includes(data?.type)
              ) {
                notificaitonReloadCounts();
              }

              setNotificationsCount(
                (notificationCount: number) => notificationCount + 1
              );

              setEntityType(data.type);
              setEntityId(data.entityId);

              if (data.type === 'claim' || data.type === 'dispute') {
                fetchNotfications();
              }
            }
          });
        })
        .catch((e: any) => {
          console.log('e', e);
        });
    }
  }, [isAuthenticated, currentUser, isSocketConnected]);

  const reloadCounts = () => {
    fetchNotfications();
  };

  return (
    <NotificationsContext.Provider
      value={{
        notificationParam,
        notifications,
        setNotifications,
        isNotificationLoading,
        notificationTotals,
        setNotificationParams,
        reloadCounts,
        fetchNotfications,
        setEntityType,
        entityType,
        setEntityId,
        entityId,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
