import {
  CheckCircle,
  Edit05,
  Mail01,
  Plus,
  SwitchHorizontal01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AppointmentModal from 'src/app/Orders/Common/Appointment/AppointmentModal';
import AppointmentSuccessModal from 'src/app/Orders/Common/Appointment/AppointmentSuccessModal';
import DraftMailModal from 'src/app/Orders/Common/DraftMailModal';
import {
  appointmentDetails,
  deliveryAppointments,
  fieldMapping,
  getEmailMessageForAppointMent,
  pickupAppointments,
  pickupDeliveryDate,
} from 'src/app/Orders/order.constant';
import { initAction } from 'src/app/Orders/order.interface';
import BadgeCmp from 'src/components/BadgeCmp';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import ErrorMsg from 'src/components/errorMsg';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  deleteAppointment,
  UpdateAppointmentStatus,
} from 'src/services/OrderService';
import { getFormattedDate } from 'src/utils/CommonFunctions';

import { OrderContext } from '../..';

interface IProps {
  isValidAddress: any;
  setIsValidAddress: any;
}

const Appointment = ({ isValidAddress, setIsValidAddress }: IProps) => {
  const { setValue, watch } = useFormContext();

  const additionalServiceWatch = watch('additionalServices');
  const orderTypeWatch = watch('orderType');

  const { currentLegData, setCurrentLegData, isLoading, serviceList } =
    useContext<any>(OrderContext);

  const [action, setAction] = useState<any>(initAction);
  const [hasPickupAppointment, setHasPickupAppointment] = useState(false);
  const [hasDeliveryAppointment, setHasDeliveryAppointment] = useState(false);

  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });

  useEffect(() => {
    if (orderTypeWatch === 'ltl') {
      if (additionalServiceWatch?.serviceId?.length > 0) {
        const filteredServices = serviceList.filter((service: any) =>
          additionalServiceWatch.serviceId.includes(service.id)
        );

        const hasPickupAppointmentSet = filteredServices.some(
          (service: any) =>
            service.name.toLowerCase() === 'pickup appointment required'
        );

        const hasDeliveryAppointmentSet = filteredServices.some(
          (service: any) =>
            service.name.toLowerCase() === 'delivery appointment required'
        );

        setHasPickupAppointment(hasPickupAppointmentSet);
        setHasDeliveryAppointment(hasDeliveryAppointmentSet);
      }
    } else {
      setHasPickupAppointment(true);
      setHasDeliveryAppointment(true);
    }
  }, [additionalServiceWatch, serviceList, orderTypeWatch]);

  const handleDeleteAppointment = (type: any) => {
    deleteAppointment({ orderLegId: currentLegData?.id, type: type })
      .then((response) => {
        if (response?.data) {
          const updatedLegData = { ...currentLegData };

          const fields = fieldMapping[type];

          if (fields) {
            updatedLegData[fields.datetimeField] = null;
            setValue(fields.datetimeField, null);

            if (fields.statusField) {
              updatedLegData[fields.statusField] = 'Pending';
              setValue(fields.statusField, 'Pending');
            }
          }

          setCurrentLegData((old: any) => ({ ...old, ...updatedLegData }));
        }
      })
      .catch(console.error);
  };

  const handleEdit = (type: string, details: any) => {
    setEditMode({ type, details });
    setAction((old: any) => ({
      ...old,
      mode: type,
    }));
  };

  const handleSubmit = (formattedData: any) => {
    let date = '';
    let type = action.mode;
    let updateCarrierPickupAsShipper = false;
    let updateCarrierDeliveryAsConsignee = false;

    if (type === 'carrier-pickup-appointment') {
      date = 'carrierPickupAppointmentDatetime';
    } else if (type === 'carrier-delivery-appointment') {
      date = 'carrierDeliveryAppointmentDatetime';
    } else if (type === 'shipper-appointment') {
      date = 'shipperAppointmentDatetime';

      if (!currentLegData?.carrierPickupAppointmentDatetime) {
        updateCarrierPickupAsShipper = true;
      }
    } else if (type === 'delivery-appointment') {
      date = 'deliveryAppointmentDatetime';

      if (!currentLegData?.carrierDeliveryAppointmentDatetime) {
        updateCarrierDeliveryAsConsignee = true;
      }
    } else if (type === 'delivery-date') {
      date = 'expectedEstimatedDeliveryTime';

      setIsValidAddress((old: any) => ({
        ...old,
        deliveryDate: true,
      }));
    } else if (type === 'pickup-date') {
      date = 'expectedPickupTime';

      setIsValidAddress((old: any) => ({
        ...old,
        pickupDate: true,
      }));
    }

    let updatedLegData = {
      ...currentLegData,
      [`${date}`]: formattedData.combinedDatetime,
      ...(updateCarrierPickupAsShipper
        ? { carrierPickupAppointmentDatetime: formattedData.combinedDatetime }
        : {}),
      ...(updateCarrierDeliveryAsConsignee
        ? { carrierDeliveryAppointmentDatetime: formattedData.combinedDatetime }
        : {}),
    };

    const updateValue = (key: any, value: any) => {
      if (setValue) {
        setValue(key, value);
      }
    };

    updateValue(date, formattedData.combinedDatetime);

    if (updateCarrierPickupAsShipper) {
      updateValue(
        'carrierPickupAppointmentDatetime',
        formattedData.combinedDatetime
      );
    }

    if (updateCarrierDeliveryAsConsignee) {
      updateValue(
        'carrierDeliveryAppointmentDatetime',
        formattedData.combinedDatetime
      );
    }

    if (setCurrentLegData) {
      setCurrentLegData(updatedLegData);
    }

    if (type === 'pickup-date' || type === 'delivery-date') {
      setAction(initAction);
    } else {
      setAction((old: any) => ({
        ...old,
        mode: 'appointment-success-modal',
        type: old.mode,
      }));
    }

    setEditMode({ type: null, details: null });
  };

  const handleUpdateAppointmentStatus = () => {
    const payloads = {
      type: action?.type,
      orderLegId: currentLegData?.id,
    };

    UpdateAppointmentStatus(payloads)
      .then((response) => {
        if (response?.data) {
          const fields = fieldMapping[action?.type];

          if (fields) {
            const updateValue = (key: any, value: any) => {
              if (setValue) {
                setValue(key, value);
              }
            };

            updateValue(fields.statusField, action?.status);

            let updatedLegData = {
              ...currentLegData,
              [`${fields.statusField}`]: action?.status,
            };

            if (setCurrentLegData) {
              setCurrentLegData(updatedLegData);
            }
          }

          setAction(initAction);
        }
      })
      .catch(console.error);
  };

  const AppointmentCard = ({
    title,
    description,
    mode,
    field,
    status,
    isLoadingAppointment,
  }: {
    title: string;
    description: string;
    mode: string;
    field: string;
    status?: string;
    isLoadingAppointment: boolean;
  }) => {
    const appointmentDate = currentLegData?.[field];
    const hasAppointment = appointmentDate != null;
    const hasAppointmentConfirmed =
      status && currentLegData?.[status] === 'Confirmed' ? true : false;
    const formatDate = (date: string) => ({
      month: moment(date).format('MMM'),
      day: moment(date).format('DD'),
      fullDate: getFormattedDate(date, 'dddd', false, false),
      time: moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm'),
    });

    return (
      <>
        <div className="sm:p-1.5 sm:flex-1 sm:min-w-[50%]">
          <h6 className="form_label block mb-1.5">{title}</h6>

          {hasAppointment ? (
            <div className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-3 px-3 flex items-center gap-x-3 cursor-pointer">
              <div
                className="leading-[1] text-sm font-semibold rounded p-1 border border-utilityGray200 h-[38px] w-[38px] text-primary700  flex flex-col items-center justify-center"
                onClick={() => {
                  handleEdit(mode, {
                    date: appointmentDate,
                    time: appointmentDate,
                  });
                }}
              >
                <p>{formatDate(appointmentDate).month}</p>
                <p className="leading-[1.2]">
                  {formatDate(appointmentDate).day}
                </p>
              </div>
              <div
                className="flex-1 text-grayLight900 text-xs font-medium"
                onClick={() => {
                  handleEdit(mode, {
                    date: appointmentDate,
                    time: appointmentDate,
                  });
                }}
              >
                <h6>
                  {formatDate(appointmentDate).fullDate}
                  {status && <>,&nbsp;{formatDate(appointmentDate).time}</>}
                </h6>
              </div>
              <div className="flex items-center gap-2">
                {status && (
                  <BadgeCmp
                    style="modern"
                    type={hasAppointmentConfirmed ? 'success' : 'error'}
                  >
                    {hasAppointmentConfirmed ? 'Confimred' : 'Pending'}
                  </BadgeCmp>
                )}

                <TooltipCmp message="Edit">
                  <Edit05
                    className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                    onClick={() => {
                      handleEdit(mode, {
                        date: appointmentDate,
                        time: appointmentDate,
                      });
                    }}
                  />
                </TooltipCmp>

                {status && (
                  <TooltipCmp
                    message={
                      hasAppointmentConfirmed
                        ? 'Revert to Pending'
                        : 'Confirm Appointment'
                    }
                  >
                    <SwitchHorizontal01
                      className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                      onClick={() => {
                        if (hasAppointmentConfirmed) {
                          setAction((old: any) => ({
                            ...old,
                            mode: 'appointment-confirm-modal',
                            type: mode,
                            status: 'Pending',
                          }));
                        } else {
                          setAction((old: any) => ({
                            ...old,
                            mode: 'appointment-confirm-modal',
                            type: mode,
                            status: 'Confirmed',
                          }));
                        }
                      }}
                    />
                  </TooltipCmp>
                )}

                {status && (
                  <TooltipCmp message="Draft Mail">
                    <Mail01
                      className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                      onClick={() => {
                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-darft-modal',
                          type: mode,
                        }));
                      }}
                    />
                  </TooltipCmp>
                )}

                <TooltipCmp message="Delete">
                  <Trash01
                    className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                    onClick={() => {
                      handleDeleteAppointment(mode);
                    }}
                  />
                </TooltipCmp>
              </div>
            </div>
          ) : (
            <div
              className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-[23px] px-3 flex items-center gap-x-3 cursor-pointer ${
                isLoadingAppointment ? 'custom-loading' : ''
              }`}
              onClick={() => {
                setAction((old: any) => ({
                  ...old,
                  mode: mode,
                }));
              }}
            >
              <Plus className="w-4 h-4 text-primary700" />
              <p className="text-grayLight900 text-xs font-medium">
                {description}
              </p>
            </div>
          )}

          {!isValidAddress?.pickupDate && mode === 'pickup-date' && (
            <ErrorMsg
              errorText={'Pickup date is required'}
              mainClassName="mt-2"
            />
          )}

          {!isValidAddress?.deliveryDate && mode === 'delivery-date' && (
            <ErrorMsg
              errorText={'Delivery date is required'}
              mainClassName="mt-2"
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
          Pickup & Delivery Date
        </h6>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
          <div className="flex flex-wrap -m-1.5">
            {pickupDeliveryDate.map((appointment, index) => (
              <AppointmentCard
                key={index}
                title={appointment.title}
                description={appointment.description}
                mode={appointment.mode}
                field={appointment.field}
                isLoadingAppointment={isLoading}
              />
            ))}
          </div>
        </div>
      </div>
      {hasPickupAppointment && (
        <div>
          <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
            Pickup Appointments
          </h6>
          <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
            <div className="flex flex-wrap -m-1.5">
              {pickupAppointments.map((appointment, index) => (
                <AppointmentCard
                  key={index}
                  title={appointment.title}
                  description={appointment.description}
                  mode={appointment.mode}
                  field={appointment.field}
                  status={appointment.status}
                  isLoadingAppointment={isLoading}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {hasDeliveryAppointment && (
        <div>
          <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
            Delivery Appointments
          </h6>
          <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
            <div className="flex flex-wrap -m-1.5">
              {deliveryAppointments.map((appointment, index) => (
                <AppointmentCard
                  key={index}
                  title={appointment.title}
                  description={appointment.description}
                  mode={appointment.mode}
                  field={appointment.field}
                  status={appointment.status}
                  isLoadingAppointment={isLoading}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText={
            action.mode === 'pickup-date'
              ? 'Set Pickup Date'
              : action.mode === 'delivery-date'
              ? 'Set Delivery Date'
              : 'Set Appointment'
          }
          type={action.mode}
          orderLegId={currentLegData?.id}
          editData={editMode.details}
          isShowTimePicker={
            !(action.mode === 'pickup-date' || action.mode === 'delivery-date')
          }
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            handleSubmit(formattedData);
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-confirm-modal' && (
        <ConfirmationModalCmp
          title={
            action.status === 'Pending'
              ? 'Revert to Pending'
              : 'Confirm Appointment'
          }
          description={appointmentDetails[action.type]?.messages[action.status]}
          Icon={<CheckCircle className="w-7 h-7" />}
          handleClose={() => setAction(initAction)}
          isSubmitting={isLoading}
          handleSubmit={handleUpdateAppointmentStatus}
          submitBtnText={
            action.status === 'pending' ? 'Mark Pending' : 'Confirm'
          }
        />
      )}

      {action.mode === 'appointment-darft-modal' &&
        appointmentDetails[action.type]?.title && (
          <DraftMailModal
            handleClose={() => setAction(initAction)}
            title={appointmentDetails[action.type].emailDraftModalTitle}
            DraftDetails={{
              subjectLine: appointmentDetails[action.type].draftTitle,
              toEmailAddress:
                appointmentDetails[action.type].sendMailTo === 'shipper'
                  ? currentLegData?.shipper?.email
                  : appointmentDetails[action.type].sendMailTo === 'consignee'
                  ? currentLegData?.consignee?.email
                  : appointmentDetails[action.type].sendMailTo === 'carrier'
                  ? currentLegData?.carrier?.length > 0
                    ? currentLegData?.carrier[0]?.email
                    : ''
                  : '',
              emailDetails: getEmailMessageForAppointMent(
                action.type,
                currentLegData
              ),
            }}
          />
        )}
    </>
  );
};

export default Appointment;
