import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';

interface IProps {
  formData: any;
  consigneeOptions: any;
  validForm: any;
  handleSelectChange: any;
  selectFormData: any;
  handleActionType: any;
  saveShipperDetails: any;
  isConsigneeBtnDisable: any;
  handleAutocompleteChange: any;
  handleInputChange: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
  isShipperLoading: any;
  status: any;
  isShowAiStarFullAddress?: boolean;
}

const ConsigneeAddressSection = ({
  formData,
  consigneeOptions,
  validForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isConsigneeBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  consigneeAddress,
  setConsigneeAddress,
  isShipperLoading,
  status,
  isShowAiStarFullAddress,
}: IProps) => {
  const { mapService } = useContext(BasicContext);

  return (
    <>
      <SelectBox
        isClearable
        isSearchable
        inlineSelect={true}
        name="consigneeId"
        placeholder="Select a consignee"
        className={`form_control ${
          !validForm.consigneeId
            ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
            : ''
        }`}
        labelRight={
          <div className="flex gap-x-2">
            {selectFormData.consigneeId && (
              <button
                onClick={handleActionType('consignee', 'delete')}
                type="button"
              >
                <TooltipCmp message="Delete Consignee">
                  <Trash01 className="h-4 w-4" />
                </TooltipCmp>
              </button>
            )}
            <button
              onClick={saveShipperDetails('consignee')}
              type="button"
              className={`text-primary700 text-xs flex font-semibold gap-2.5 ${
                isConsigneeBtnDisable && 'text-opacity-50'
              }`}
              disabled={isConsigneeBtnDisable}
            >
              {selectFormData.consigneeId ? (
                <Edit05 className="w-4 h-4" />
              ) : (
                <Plus className="w-4 h-4" />
              )}
              {selectFormData.consigneeId ? 'Edit' : 'Add'} New Consignee
            </button>
          </div>
        }
        labelClassName="form_label mb-0 block"
        labelWrapClassName="mb-1.5"
        classComp=""
        label="Consignee"
        isLoading={isShipperLoading}
        options={consigneeOptions}
        value={consigneeOptions.find(
          (val: any) => val.value === selectFormData.consigneeId
        )}
        onChangeFunc={handleSelectChange('consigneeId')}
      />

      <InputText
        inputName="consigneeCompanyName"
        placeholder="Enter company name"
        className="form_control"
        label="Company name"
        labelClassName="mb-1.5 block"
        value={formData.consigneeCompanyName}
        errorText={
          !validForm.consigneeCompanyName ? 'Company name is required' : null
        }
        onChangeFunc={handleInputChange}
      />

      {status &&
        (mapService === 'trimble_map' ? (
          <AutoCompleteTrimble
            inputName="consigneeAddress"
            required
            placeholder="Enter address"
            dropdownWrapClass="!top-[unset] bottom-[65%]"
            className={`form_control truncate ${
              !validForm.consigneeAddress
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            label="Address"
            labelRight={
              <button
                onClick={handleActionType(
                  'consignee',
                  formData.consigneeAddress.fullAddress
                    ? 'addressUpdate'
                    : 'addressCreate'
                )}
                type="button"
                className={`text-primary700 text-xs flex font-semibold gap-2.5`}
                disabled={false}
              >
                {formData.consigneeAddress.fullAddress ? 'Change' : 'Select'}{' '}
                Address
              </button>
            }
            labelClassName="mb-0 block"
            labelWrapClassName="mb-1.5"
            onChangeFunc={handleAutocompleteChange('consigneeAddress')}
            onBlur={handleAutocompleteChange('consigneeAddress')}
            isClearable
            onAddressInputChange={setConsigneeAddress}
            value={consigneeAddress}
            isShowAiStar={isShowAiStarFullAddress}
          />
        ) : (
          <AutoCompleteGoogle
            inputName="consigneeAddress"
            required
            placeholder="Enter consignee address"
            className={`form_control truncate ${
              !validForm.consigneeAddress
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            label="Select Address"
            listClassName="showAddressOnTop"
            labelRight={
              <button
                onClick={handleActionType(
                  'consignee',
                  formData.consigneeAddress.fullAddress
                    ? 'addressUpdate'
                    : 'addressCreate'
                )}
                type="button"
                className={`text-primary700 text-xs flex font-semibold gap-2.5`}
                disabled={false}
              >
                {formData.consigneeAddress.fullAddress ? 'Change' : 'Select'}{' '}
                Address
              </button>
            }
            labelClassName="mb-0 block"
            labelWrapClassName="mb-1.5"
            onChangeFunc={handleAutocompleteChange('consigneeAddress')}
            onBlur={handleAutocompleteChange('consigneeAddress')}
            isClearable
            onAddressInputChange={setConsigneeAddress}
            value={consigneeAddress}
            isShowAiStar={isShowAiStarFullAddress}
          />
        ))}
    </>
  );
};

export default ConsigneeAddressSection;
