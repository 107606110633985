import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import TooltipCmp from 'src/components/TooltipCmp';

const LoadBoardRowLoader = () => (
  <>
    <td className="px-5 py-4 max-w-[1px] w-[18%] min-w-[200px]">
      <div className="flex items-center">
        <div className="table-profile-customer-circle-xl rounded-md custom-loading">
          TT
        </div>
        <div className="ml-3 truncate custom-loading">
          <TooltipCmp>
            <h6 className="text-grayLight900 font-medium leading-tight truncate ">
              Canadian Logistic Express
            </h6>
          </TooltipCmp>

          <TooltipCmp>
            <span className="text-grayLight600 font-normal leading-tight truncate">
              +1 (226) 289-9000
            </span>
          </TooltipCmp>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 min-w-[13.04%] w-[13.04%] max-w-[1px]">
      <p className="truncate custom-loading">Montreal</p>
    </td>
    <td className="px-5 py-4 min-w-[12.04%] w-[12.04%] max-w-[12.04%]">
      <span className="custom-loading">0 mi</span>
    </td>
    <td className="px-5 py-4 min-w-[13.04%] w-[13.04%] max-w-[1px]">
      <p className="truncate custom-loading">London</p>
    </td>
    <td className="px-5 py-4 min-w-[12.04%] w-[12.04%] max-w-[12.04%]">
      <span className="custom-loading">114.2 mi</span>
    </td>
    <td className="px-5 py-4 min-w-[14.04%] w-[14.04%] max-w-[1px]">
      <div className="truncate w-fit max-w-full custom-loading">
        <ActionTooltip openOnHover={true} type="dark">
          <p className="truncate ">Step Deck</p>
        </ActionTooltip>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[14.04%] w-[14.04%] max-w-[1px]">
      <div className="truncate custom-loading">
        <TooltipCmp>
          <p className="truncate">x2891 flom@challenger.com</p>
        </TooltipCmp>
      </div>
    </td>
    {/* <td className="px-5 py-4 max-w-[60px] w-[60px] min-w-[60px]">
      <div className="w-4 h-4 custom-loading">
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
      </div>
    </td> */}
  </>
);

export default LoadBoardRowLoader;
