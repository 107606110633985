import { Star06, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useState, useEffect, useContext } from 'react';
import { BasicContext } from 'src/context/BasicContext';

import TooltipCmp from '../TooltipCmp';

const AutoCompleteTrimble = ({
  inputName,
  placeholder,
  errorText,
  className,
  label,
  labelRight,
  isClearable,
  labelClassName,
  dropdownWrapClass,
  dropdownClass,
  required,
  onChangeFunc,
  mapParentClass,
  onAddressInputChange,
  labelWrapClassName,
  isShowAiStar,
  ...props
}: any) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState(null);
  const { trimbleKey } = useContext(BasicContext);

  useEffect(() => {
    let timeOutId: any;
    let handleOutsideClick: any;
    const addressListEle: any = document.querySelector('.address-list');

    handleOutsideClick = (event: any) => {
      if (
        !addressListEle.contains(event.target) &&
        event.target.id !== 'trimbleInput'
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      clearTimeout(timeOutId);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const getAddress = async (lat: number, lng: number) => {
    const response = await fetch(
      `https://pcmiler.alk.com/apis/rest/v1.0/Service.svc/locations/reverse?Coords=${encodeURIComponent(
        `${lng},${lat}`
      )}&authToken=${trimbleKey}&matchNamedRoadsOnly=true&maxCleanupMiles=20&region=NA&dataset=Current`
    );
    const data = await response.json();
    let addressObject = data.Address;

    if (addressObject.Zip) {
      addressObject.Zip = addressObject.Zip.split('-')[0];
    }

    let fullAddress = [];

    if (addressObject.StreetAddress) {
      fullAddress.push(addressObject.StreetAddress);
    }

    if (addressObject.City) {
      fullAddress.push(addressObject.City);
    }

    if (addressObject.State) {
      fullAddress.push(addressObject.State);
    }

    if (addressObject.CountryAbbreviation) {
      fullAddress.push(
        `${addressObject.CountryAbbreviation} ${addressObject.Zip}`
      );
    }

    if (addressObject) {
      const addressObj = {
        fullAddress: fullAddress.join(', '),
        address1: addressObject.StreetAddress || addressObject.City,
        city: addressObject.City,
        state: addressObject.StateName,
        country: addressObject.Country,
        postal: addressObject.Zip,
        latitude: data.Coords.Lat,
        longitude: data.Coords.Lon,
        stateCode: addressObject.State,
        countryCode: addressObject.CountryAbbreviation,
      };

      return addressObj;
    } else {
      return false;
    }
  };

  const handleInputChange = async (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    onAddressInputChange(value);

    // Fetch suggestions from Trimble Maps Geocoding API
    if (value) {
      try {
        const response = await fetch(
          `https://singlesearch.alk.com/NA/api/search?query=${encodeURIComponent(
            value
          )}&authToken=${trimbleKey}&countries=CA,US&includeOnly=Country,State,City,POBox,Zip,Street,POIStreet,FullPostCode,CrossStreet`
        );

        if (response.status === 200) {
          const data = await response.json();
          // console.log(data);

          let addressList = data.Locations.map((obj: any) => {
            obj.address = obj.ShortString;

            return obj;
          });

          if (value) {
            setSuggestions(addressList);
          } else {
            setSuggestions([]);
          }
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectAddress = async (address: any) => {
    // console.log(address);
    setSuggestions([]);

    let addressObject = address.Address;

    if (addressObject.Zip) {
      addressObject.Zip = addressObject.Zip.split('-')[0];
    }

    if (addressObject) {
      let addressObj = {
        fullAddress: address.address,
        address1: addressObject.StreetAddress || addressObject.City,
        city: addressObject.City,
        state: addressObject.StateName,
        country: addressObject.CountryFullName,
        postal: addressObject.Zip,
        latitude: address.Coords.Lat,
        longitude: address.Coords.Lon,
        stateCode: addressObject.State,
        countryCode: addressObject.Country,
      };

      if (!addressObj.postal || !addressObj.city) {
        let checkReverseGeoAddress = await getAddress(
          addressObj.latitude,
          addressObj.longitude
        );

        if (checkReverseGeoAddress) {
          addressObj = { ...addressObj, postal: checkReverseGeoAddress.postal };
        }
      }

      // console.log(addressObj);

      onChangeFunc(addressObj);
      onAddressInputChange(addressObj.fullAddress);
    }
  };

  const clearInput = () => {
    handleInputChange({ target: { name: inputName, value: '' } });
  };

  useEffect(() => {
    setShowSuggestions(suggestions.length > 0);
  }, [suggestions]);

  return (
    <div className={`relative ${mapParentClass}`}>
      <div
        className={`flex justify-between items-start gap-x-2 ${labelWrapClassName}`}
      >
        <div className="flex">
          {label && (
            <label className={`form_label ${labelClassName}`}>{label}</label>
          )}
          {required && <span className="text-red-600 leading-4">*</span>}
        </div>
        {labelRight && <>{labelRight}</>}
      </div>

      <div
        className={`position-relative relative flex justify-center items-center ${
          isShowAiStar ? 'ai-gradient-border-wrap' : ''
        }`}
      >
        {isShowAiStar && (
          <div className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 mt-px">
            <TooltipCmp message={'Detected with AI'}>
              <Star06 className="w-4 h-4 text-primary " />
            </TooltipCmp>
          </div>
        )}
        <input
          type="text"
          name={inputName}
          id="trimbleInput"
          className={`mfnw__login-form-control ${className} ${
            isShowAiStar ? 'pl-7' : ''
          } ${errorText ? 'border border-red' : ''}`}
          placeholder={placeholder}
          onChange={handleInputChange}
          required={required}
          onFocus={() => {
            if (suggestions.length) {
              setShowSuggestions(true);
            }
          }}
          onMouseDown={() => {
            if (suggestions.length) {
              setShowSuggestions(true);
            }
          }}
          aria-autocomplete="none"
          autoComplete="off"
          {...props}
        />
        {isClearable && inputValue !== '' && (
          <XClose
            id="clear"
            className="h-4 w-4 cursor-pointer absolute right-2 clear"
            onClick={clearInput}
          />
        )}
      </div>
      <div
        className={`absolute w-full top-full left-0 bg-white border border-utilityGray200 shadow-lg rounded-lg my-2.5 px-1.5 py-1.5 max-h-60 overflow-y-auto custom-scrollbar-v2 z-[3] ${dropdownWrapClass} ${
          !showSuggestions ? 'opacity-0' : ''
        }`}
      >
        <ul className={`  address-list ${dropdownClass}`}>
          {suggestions.map((suggest: any, index) => (
            <li
              className="p-2 cursor-pointer transition-all duration-[0.3s text-grayLight900] leading-[18px] bg-transparent hover:bg-gray50 rounded-md hover:text-primary font-medium text-xs"
              key={index}
              onClick={() => handleSelectAddress(suggest)}
            >
              {suggest.address}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AutoCompleteTrimble;
