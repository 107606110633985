import { FilterFunnel02 } from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';

interface IProps {
  segmentListOption: any;
  teamListOptions: any;
  userList: any;
  handleClose: any;
  params: any;
  setParams: any;
}

const CustomerFilterModal = ({
  segmentListOption,
  teamListOptions,
  userList,
  handleClose,
  params,
  setParams,
}: IProps) => {
  const { hasRoleV2 } = useRolePermission();
  const [teamId, setTeamId] = useState(params?.team ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);
  const [memberId, setMemberId] = useState(params?.member ?? 'all');
  const [segment, setSegment] = useState<any[]>(params?.segment ?? []);

  const handleSave = () => {
    setParams((old: any) => ({
      ...old,
      segment: segment,
      page: 1,
      limit: 50,
      team: teamId,
      member: memberId,
    }));
    handleClose();
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  return (
    <CommonModal
      size={'smd:max-w-[535px] max-w-[336px]'}
      handleClose={handleClose}
      title="Customer Filters"
      primaryBtnText="Add"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Easily sort and focus on key customers."
      headerIcon={<FilterFunnel02 />}
    >
      <div className="p-5 flex flex-col gap-y-4">
        <div className="sm:flex flex-wrap items-center">
          <SelectBox
            label="Customer Type"
            labelClassName="form_label"
            name="segmentGroup"
            id="segmentGroup"
            className="form_control"
            size="sm"
            placeholder="All Tags"
            isClearable={true}
            parentClassName="smd:flex-1"
            options={segmentListOption}
            isMultipleSelection
            onChangeFunc={(event: any) => {
              const newValue = event.map((e: any) => e.value);
              setSegment([...newValue]);
            }}
            // onChangeFunc={(event: any) => setSegment(event?.value ?? '')}
            // value={segmentListOption.filter(
            //   (val: any) => segment === val.value
            // )}
            value={segmentListOption.filter((item: any) =>
              segment?.includes(item.value)
            )}
          />
        </div>

        {!hasRoleV2('user') && (
          <>
            <div className="sm:flex flex-wrap items-center">
              <SelectBox
                label="All Teams"
                labelClassName="form_label"
                name="teamListGroup"
                id="teamListGroup"
                className="form_control"
                size="sm"
                placeholder="All Teams"
                noOptionMessage="No Teams Found"
                isClearable={true}
                isSearchable={true}
                parentClassName="smd:flex-1"
                options={teamListOptions}
                onChangeFunc={(event: any) => {
                  setTeamId(event?.value ?? 'all');
                  setMemberId('all');
                }}
                value={
                  teamId
                    ? teamListOptions.filter((val: any) => teamId === val.value)
                    : null
                }
              />
            </div>

            <div className="sm:flex flex-wrap items-center">
              <SelectBox
                label="All Users"
                labelClassName="form_label"
                name="userListGroup"
                id="userListGroup"
                size="sm"
                placeholder="All Users"
                className="form_control"
                isClearable={true}
                isSearchable={true}
                options={teamMember}
                parentClassName="smd:flex-1"
                noOptionMessage="No Users Found"
                onChangeFunc={(event: any) =>
                  setMemberId(event?.value ?? 'all')
                }
                value={
                  memberId
                    ? teamMember.filter((user: any) => memberId === user.value)
                    : null
                }
                menuPlaceOption="top"
              />
            </div>
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default CustomerFilterModal;
