import React, { useEffect, useMemo, useRef, useState } from 'react';
import InternalChatAndNotes from 'src/components/InternalChatAndNotes';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import { getFormattedDate } from 'src/utils/CommonFunctions';

import MapboxMap from '../../OrderView/MapBoxMap';

interface IProps {
  order: any;
  shipperFullAddress?: any;
  consigneeFullAddress?: any;
  isOrderView: boolean;
  currentLocation?: any;
}

const RadarAndInternalChat = ({
  order,
  shipperFullAddress,
  consigneeFullAddress,
  isOrderView,
  currentLocation,
}: IProps) => {
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const [startPoint, setStartPoint] = useState<any>(null);
  const [endPoint, setEndPoint] = useState<any>(null);
  const [distance, setDistance] = useState<any>(null);
  const [duration, setDuration] = useState<any>(null);

  useEffect(() => {
    if (shipperFullAddress?.latitude) {
      setStartPoint({
        lat: shipperFullAddress?.latitude,
        lng: shipperFullAddress?.longitude,
        name: null,
      });
    } else {
      setStartPoint(null);
    }
  }, [shipperFullAddress]);

  useEffect(() => {
    if (consigneeFullAddress?.latitude) {
      setEndPoint({
        lat: consigneeFullAddress?.latitude,
        lng: consigneeFullAddress?.longitude,
        name: null,
      });
    } else {
      setEndPoint(null);
    }
  }, [consigneeFullAddress]);

  const map = useMemo(
    () => (
      <>
        <MapboxMap
          startPoint={startPoint}
          endPoint={endPoint}
          height="190px"
          className="map-container"
          markerRef={markerRef}
          isShowRoute={startPoint && endPoint}
          isZoomable={true}
          isDraggable={true}
          isFullscreen={false}
          defaultZoom={2}
          setDistance={setDistance}
          setDuration={setDuration}
          currentLocation={
            currentLocation
              ? {
                  lat: currentLocation?.latitude,
                  lng: currentLocation?.longitude,
                  name: 'Current Location',
                }
              : null
          }
        />

        {isOrderView && currentLocation && (
          <div className="flex gap-1.5 items-center px-1.5 py-1 rounded shadow-sm bg-white absolute bottom-2.5 left-2.5">
            <StatusDotProfileCmp
              dotColor="primary"
              label={
                <>
                  {' '}
                  Last update:{' '}
                  {getFormattedDate(
                    currentLocation?.updatedAt,
                    'MMM DD, YYYY hh:mm A',
                    true,
                    true
                  )}
                </>
              }
            ></StatusDotProfileCmp>
          </div>
        )}
        {distance && duration && (
          <div className="rounded-md border border-borderPrimary bg-white px-1.5 py-1 absolute top-2.5 right-2.5">
            <p className="text-textSecondary text-xs font-medium">
              {distance} miles | {duration}
            </p>
          </div>
        )}
      </>
    ),
    [startPoint, endPoint, distance, duration, currentLocation]
  );

  return (
    <InternalChatAndNotes
      data={order}
      chatType={'order'}
      chatChannelName={'order'}
      isShowInternalNote={false}
      notesCount={0}
      setNotesCount={<></>}
      isShowChatAndNotesSideBar={<></>}
      setIsShowChatAndNotesSideBar={<></>}
      chatEntityId={order?.id}
      chatWrapClassName=""
      sidebarChatCloseIconClass="absolute top-1 right-4"
      chatTopContent={
        <div className=" relative overflow-hidden border-b border-l border-utilityGray200 p-4 max-xl:pt-10">
          <div className="rounded-lg overflow-hidden border border-utilityGray200 relative">
            {map}
          </div>
        </div>
      }
    />
  );
};

export default RadarAndInternalChat;
