import React, { useContext, useMemo } from 'react';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '..';

import LegDetailsRow from './LegDetailsRow';

const LegDetails = () => {
  const { legData } = useContext<any>(OrderContext);

  const legHeadCells = useMemo(
    () => [
      {
        id: 'lefId',
        name: 'Leg ID',
      },
      {
        id: 'carrier',
        name: 'Carrier',
      },
      {
        id: 'addresses',
        name: 'Addresses',
      },
      {
        id: 'mileage',
        name: 'Mileage',
      },
      {
        id: 'estTransitTime',
        name: 'Est. Transit Time',
      },
    ],
    []
  );

  return (
    <li>
      <h6 className="text-textSecondary text-xs font-medium mb-1.5">
        Leg Details
      </h6>
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
          <TableCmp
            headCells={legHeadCells}
            tableDataArr={legData?.length > 0 ? legData : []}
            TableRowCmp={LegDetailsRow}
            tableFooterRowCmpProps={{}}
            numberOfSkeletonRows={1}
          />
        </div>
      </div>
    </li>
  );
};

export default LegDetails;
