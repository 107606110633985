import { Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

import defaultImage from '../../../../assets/img/default-image.jpg';

const SalesPriceRowLoader = () => (
  <>
    <td className="px-5 py-4 w-48 max-w-48 min-w-48">
      <div className="flex items-center gap-3">
        <div className="table-profile-wrap custom-loading rounded-full">
          <img
            className="table-profile-customer-circle-xl rounded-md image-loading"
            src={defaultImage}
          />
        </div>
        <div className="truncate">
          <h6 className="text-grayLight900 font-medium leading-tight truncate custom-loading">
            DSS Marine Incorporated Incorporated (USD)
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate custom-loading">
            USD Billing Account
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
      <span className="custom-loading">$123</span>
    </td>
    <td className="px-5 py-4 w-[160px] max-w-[160px] min-w-[160px]">
      <span className="custom-loading">$123</span>
    </td>
    <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
      <span className="custom-loading">$1234</span>
    </td>
    <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
      <span className="custom-loading">$123</span>
    </td>
    <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
      <span className="custom-loading">12.56%</span>
    </td>

    <td className="px-5 py-4 w-[80px] min-w-[80px] max-w-[80px]">
      <div className="justify-end items-center gap-2 flex ">
        <TooltipCmp message={'Edit Order'} childrenClassName="custom-loading">
          <Edit01 className="w-4 h-4 relative cursor-pointer custom-loading" />
        </TooltipCmp>
        <TooltipCmp message={'Delete Order'} childrenClassName="custom-loading">
          <Trash01 className="w-4 h-4 relative cursor-pointer custom-loading" />
        </TooltipCmp>
      </div>
    </td>
  </>
);

export default SalesPriceRowLoader;
