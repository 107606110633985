import React, { useContext } from 'react';

import { OrderContext } from '..';

import CarrierAndSalesPrice from './CarrierAndSalesPrice';

const Step4 = () => {
  const {
    id,
    isCustomerLoading,
    customerList,
    order,
    salesPrice,
    setSalesPrice,
    carrierPrice,
    setCarrierPrice,
  } = useContext<any>(OrderContext);

  return (
    <CarrierAndSalesPrice
      id={id}
      isCustomerLoading={isCustomerLoading}
      customerList={customerList}
      order={order}
      salesPrice={salesPrice}
      setSalesPrice={setSalesPrice}
      carrierPrice={carrierPrice}
      setCarrierPrice={setCarrierPrice}
    />
  );
};

export default Step4;
