import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const WareHouseRow = ({ data, handleWarehouseEdit }: any) => (
  <>
    <td className="px-5 py-4 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight900 font-medium truncate h-8 flex items-center">
        {data?.warehouseName}
      </div>
    </td>

    <td className="px-5 py-4 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      {data?.carrierName ? (
        <div className="flex items-center gap-2">
          <div className="flex-none">
            {data?.carrierImage && data?.carrierImageUrl ? (
              <img
                className="table-profile-icn-circle-sm"
                src={`${data?.carrierImageUrl}${data?.carrierImage}`}
                alt=""
              />
            ) : (
              <div className="table-profile-customer-circle-sm">
                {getShortName(`${data.carrierName}`)}
              </div>
            )}
          </div>
          <div className="truncate w-full">
            <h6 className="text-grayLight900 font-medium truncate">
              {data?.carrierName}
            </h6>
          </div>
        </div>
      ) : (
        ''
      )}
    </td>

    <td className="px-5 py-4 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate">{data?.address1}</div>
    </td>

    <td className="px-5 py-4 text-grayLight600 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate">{data?.city}</div>
    </td>

    <td className="px-5 py-4 text-grayLight600 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate">{data?.state}</div>
    </td>

    <td className="px-5 py-4 text-grayLight600 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate">{data?.country}</div>
    </td>

    <td className="px-5 py-4 w-14 max-w-14 min-w-14">
      <div onClick={() => handleWarehouseEdit(data)}>
        <Edit01 className="cursor-pointer w-4 h-4 text-grayLight600" />
      </div>
    </td>
  </>
);

export default WareHouseRow;
