import { throttle, unionBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { getMapService } from 'src/services/IntegrationService';
import {
  deleteAllEmail,
  deleteQuoteEmail,
  getIsActiveToken,
  getQuoteEmail,
  setEmailRead,
} from 'src/services/QuoteEmailService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { scriptId } from '../QuotingDashboard/AddressDetails/Address.interface';

import CreateQuoteFromMailSection from './CreateQuoteFromMailSection';
import CreateQuoteSectionLoading from './CreateQuoteFromMailSection/CreateQuoteSectionLoading';
import MessageDetailSection from './MessageDetailSection';
import MessageListSection from './MessageListSection';
import NotFoundUI from './NotFoundUI';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 15,
  isQuoteEmail: true,
};

const QuotingRequest = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);

  const [params, setParams] = useState(initParams);
  const [emailData, setEmailData] = useState<any[]>([]);
  const [totalEmails, setTotalEmails] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const scrollContainerRef = useRef<any>(null);
  const scrollPositionRef = useRef<number>(0);
  const [search, setSearch] = useState('');
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [deletingEmails, setDeletingEmails] = useState<{
    [key: string]: boolean;
  }>({});
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );

  const getQuoteEmails = async (isDeleteId = null, signal?: any) => {
    try {
      let filter = ``;

      if (isDeleteId === null) {
        setLoading(true);
      }

      if (params?.page) {
        filter += `?page=${params?.page}&limit=${params.limit}`;
      }

      if (params?.search) {
        filter += `&search=${params?.search}`;
      }

      if (params?.isQuoteEmail) {
        filter += `&isQuoteEmail=true`;
      }
      const quoteEmails = (await getQuoteEmail(filter, signal)) as any;
      let emailFilterData = quoteEmails?.data?.filter(
        (v: any) => v?.isConvertedToQuote === 0
      );
      setUser(quoteEmails?.user || null);
      setLoading(false);

      emailFilterData = emailFilterData?.map((email: any) => {
        const parseEmailThreads = email?.thread?.map((threadObj: any) => ({
          ...threadObj,
          emailBody: JSON.parse(threadObj?.emailBody),
        }));

        // Parse main email body
        const parsedEmail = {
          ...email,
          emailBody: JSON.parse(email?.emailBody),
          thread: parseEmailThreads,
        };

        return parsedEmail;
      });

      const deletedIndex = emailData.findIndex(
        (x: any) => x.id === isDeleteId || false
      );

      if (params?.page === 1) {
        setEmailData(emailFilterData);
      } else {
        if (isDeleteId) {
          setEmailData((prevItems: any) => {
            const combinedData = unionBy(prevItems, emailFilterData, 'id');
            const filteredData = combinedData.filter(
              (item: any) => item.id !== isDeleteId
            );

            return filteredData;
          });
        } else {
          setEmailData((prevItems: any) =>
            unionBy(prevItems, emailFilterData, 'id')
          );
        }
      }
      setTotalEmails(quoteEmails?.total || 0);
      setIsLoadingMore(false);

      if (!selectedEmail?.id || params?.page === 1) {
        setSelectedEmail(emailFilterData?.[0] || undefined);
      } else if (isDeleteId) {
        setTimeout(() => {
          const selectedEmailData =
            emailData?.[deletedIndex + 1] || emailData?.[deletedIndex - 1];
          setSelectedEmail(selectedEmailData);
        }, 200);
      }

      // Restore scroll position after new data is loaded
      setTimeout(() => {
        if (scrollContainerRef.current && params?.page === 1) {
          scrollContainerRef.current.scrollTop = 0;
        } else if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollPositionRef.current;
        }
      }, 0);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }

      console.log(e);
      setLoading(false);
      setIsLoadingMore(false);
      WalToast.error(
        e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      );
    }
  };

  const makeEmailRead = async (messageId: string) => {
    try {
      (await setEmailRead({ messageId: messageId })) as any;
    } catch (e: any) {
      console.log(e);
    }
  };

  const fetchTokenStatus = async () => {
    try {
      setLoading(true);
      const response = await getIsActiveToken();
      setIsActive(response.data?.isActive);

      if (!response.data?.isActive) {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching token status:', error);
      setIsActive(false);
      setLoading(false);
    }
  };

  const handleNewEmail = useCallback(
    (newEmail: any) => {
      // console.log('newEmail ', newEmail);
      // console.log('emailData ', emailData);

      if (params?.isQuoteEmail !== newEmail?.newEmailContent?.isQuoteEmail) {
        return;
      }

      const findIfEmailExistsInThread = emailData.find(
        (emailDt: any) =>
          emailDt?.conversionId === newEmail?.newEmailContent?.conversionId
      );
      // console.log('findIfEmailExistsInThread ', findIfEmailExistsInThread);

      if (findIfEmailExistsInThread) {
        // check if the email already exists in the thread
        const emailExistsInThread = findIfEmailExistsInThread?.thread.some(
          (thread: any) =>
            thread?.messageId === newEmail?.newEmailContent?.messageId
        );

        if (!emailExistsInThread) {
          setEmailData((prevEmails: any) => {
            const newEmailData = prevEmails.map((email: any) => {
              if (
                email?.conversionId === newEmail?.newEmailContent?.conversionId
              ) {
                const emailOldThread = email?.thread || [];
                email.thread = [...emailOldThread, newEmail?.newEmailContent];
              }

              return email;
            });

            return newEmailData;
          });
        }
      } else {
        setEmailData((prevEmails: any) => {
          // Check if an email with the same messageId already exists
          const emailExists = prevEmails.some(
            (email: any) =>
              email.messageId === newEmail?.newEmailContent?.messageId
          );

          if (!emailExists) {
            setTotalEmails((prevTotal: any) => prevTotal + 1);

            return [{ ...newEmail?.newEmailContent }, ...prevEmails];
          }

          return prevEmails;
        });
      }
    },
    [emailData]
  );

  useEffect(() => {
    let IO: any = undefined;
    fetchJsFromCDN(
      'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
      ['io']
    )
      .then(([io]: any) => {
        IO = io;
        io.sails.url = window.SERVER_URL;

        io.socket.on('connect', function socketConnected() {
          dispatch(setIsSocketConnected(true));
          dispatch(setSocketIo(io.socket));

          io.socket.get(
            `/subscribe/globalRoom`,
            function (data: any, jwr: any) {
              if (jwr.error) {
                return;
              }
            }
          );
        });

        io.socket.on('newEmail', (newEmail: any) => {
          const userIdToCompare = currentUser?.attachedUser
            ? currentUser.attachedUser
            : currentUser?.userId;

          if (userIdToCompare === newEmail?.newEmailContent?.userId) {
            handleNewEmail(newEmail);
          }
        });

        // Clean up on component unmount
        return () => {
          io.socket.off('newEmail');
        };
      })
      .catch((error) => {
        console.error('Failed to load Sails socket library:', error);
      });

    return () => {
      if (IO?.socket?.isConnected()) {
        IO.socket.off('newEmail');
        IO?.socket?.disconnect();
      }
    };
  }, [currentUser?.id, emailData]);

  useEffect(() => {
    fetchTokenStatus();
  }, []);

  useEffect(() => {
    const quoteEmailApiController = new AbortController();
    const quoteEmailApiSignal = quoteEmailApiController.signal;

    if (isActive) {
      getQuoteEmails(null, quoteEmailApiSignal);
    }

    return () => {
      quoteEmailApiController.abort();
    };
  }, [params, isActive]);

  const handleSelectedEmail = (emailDetails: any) => {
    setSelectedEmail(emailDetails);
    setIsEmailClicked(true);

    if (!emailDetails?.isRead) {
      makeEmailRead(emailDetails?.messageId);
      const emailDataCopy = emailData.map((email: any) => {
        if (email?.messageId === emailDetails?.messageId) {
          email.isRead = 1;
        }

        return email;
      });
      setEmailData(emailDataCopy);
    }
  };

  const handleScroll = useCallback(
    throttle(() => {
      if (!scrollContainerRef.current || isLoadingMore || loading) return;
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;

      // Save the current scroll position
      scrollPositionRef.current = scrollTop;

      // Check if we've reached the bottom and need to load more
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        totalEmails > emailData?.length &&
        !isLoadingMore
      ) {
        setIsLoadingMore(true);
        setParams((old) => ({
          ...old,
          page: old.page + 1,
        }));
      }
    }, 200),
    [isLoadingMore, loading, emailData?.length, totalEmails]
  );

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current as any;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const clearSearch = useCallback(() => {
    setSearch('');
    setParams((old) => ({ ...old, search: '', page: 1 }));
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [params?.isQuoteEmail]);

  const handleDeleteQuote = async (item: any) => {
    setDeletingEmails((prev) => ({ ...prev, [item.id]: true }));
    deleteQuoteEmail({ emailId: item.id })
      .then(() => {
        getQuoteEmails(item.id);
      })
      .catch((err) => {
        console.error('Error deleting email:', err);
      })
      .finally(() => {
        setDeletingEmails((prev) => ({ ...prev, [item.id]: false }));
      });
  };

  const handleDeleteAllEmails = async (isQuoteEmailStatus: boolean) => {
    setLoading(true);

    deleteAllEmail({ isQuoteEmail: isQuoteEmailStatus })
      .then(() => {
        getQuoteEmails();
      })
      .catch((err: any) => {
        WalToast.error(
          err?.response?.data?.message ||
            'Something went wrong while fetching quote emails.'
        );
      });
  };

  window.initMap = () => {
    setStatus(true);
  };

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!status) {
      document.getElementById(scriptId)?.remove();
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, [googleKey]);

  useEffect(() => {
    if (!isNotificationLoading) {
      setTimeout(() => {
        if (
          entityCounts?.quotingRequestCount &&
          entityCounts?.quotingRequestCount > 0
        ) {
          const param = { type: 'quote_request_email_received' };
          handleNotificationRead(param);
        }
      }, 5000);
    }
  }, [entityCounts, isNotificationLoading]);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quote Requests"
          desc="Create, view and manage your customer quotes."
          isShowNotificationBox
        />
      }
      contentClassName="h-[calc(100vh_-_97px)]"
    >
      {isActive ? (
        <>
          <div className="h-full w-full relative bg-white border border-gray-200 rounded-xl flex flex-wrap overflow-hidden">
            <MessageListSection
              isLoadingMore={isLoadingMore}
              loading={loading}
              params={params}
              search={search}
              setParams={setParams}
              setSearch={setSearch}
              totalEmails={totalEmails}
              userEmail={user?.email}
              deletingEmails={deletingEmails}
              emailData={emailData}
              handleDeleteQuote={handleDeleteQuote}
              handleSelectedEmail={handleSelectedEmail}
              scrollContainerRef={scrollContainerRef}
              selectedEmail={selectedEmail}
              handleDeleteAllEmails={handleDeleteAllEmails}
            />

            <MessageDetailSection
              isEmailClicked={isEmailClicked}
              isLoadingMore={isLoadingMore}
              loading={loading}
              emailData={emailData}
              clearSearch={clearSearch}
              params={params}
              setParams={setParams}
              selectedEmail={selectedEmail}
              setIsEmailClicked={setIsEmailClicked}
            />

            {!isLoadingMore && loading ? (
              <CreateQuoteSectionLoading />
            ) : (
              !!selectedEmail && (
                <CreateQuoteFromMailSection
                  key={selectedEmail}
                  selectedEmail={selectedEmail}
                  params={params}
                  setParams={setParams}
                  mapApiStatus={status}
                />
              )
            )}
          </div>
        </>
      ) : (
        <NotFoundUI />
      )}
    </PageSectionLayout>
  );
};

export default QuotingRequest;
