import {
  Expand02,
  Microphone01,
  MicrophoneOff01,
  PhoneCall02,
  PhoneOutgoing02,
  PhonePause,
  PhonePlus,
} from '@untitled-ui/icons-react/build/cjs';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import { AuthContext } from 'src/context/AuthContext';
import {
  incrementCallDuration,
  setCallDuration,
  setErrorMessage,
  setHoldStatus,
  setIncomingCall,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setOutgoingCall,
} from 'src/redux/CallCenter.slice';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { RootState } from 'src/redux/store';
import { transferCall } from 'src/services/TwilioService';
import {
  fetchJsFromCDN,
  getMinutesAndSeconds,
} from 'src/utils/CommonFunctions';

import Profile from '../../assets/img/profile.png';
import starWhite from '../../assets/img/star-white.svg';

const InProgressCall = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const {
    incomingCall,
    outgoingCall,
    fromNumberContact,
    isMuted,
    holdStatus,
    conferenceId,
    participants,
    conferenceName,
    // isRecording,
    outgoingCallDetails,
    onHold,
    isCallInProgress,
    callDuration,
  } = useSelector((state: RootState) => state.CallCenter);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isCallInProgress) {
      timer = setInterval(() => {
        dispatch(incrementCallDuration());
      }, 1000);
    } else if (timer) {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isCallInProgress, dispatch]);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.removeAllListeners();

      // Conference Created For Incoming Call
      socketIO.on('disconnectCall', async () => {
        console.log('disconnect call');
        // await addCallDuration({ callDuration, conferenceId });

        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setIncomingCall(null));
        dispatch(setOutgoingCall(null));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
      });
    } else {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          // Conference Created For Incoming Call
          io.socket.on('disconnectCall', () => {
            console.log('disconnect call');
            dispatch(setIsCallInProgress(false));
            dispatch(setCallDuration(0));
            dispatch(setIncomingCall(null));
            dispatch(setOutgoingCall(null));
            dispatch(setOnHold(false));
            dispatch(setIsMuted(false));
            dispatch(setHoldStatus(false));
            dispatch(setIsRecording(true));
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('disconnectCall');
          };
        })
        .catch((error: any) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, []);

  const toggleMute = () => {
    if (incomingCall) {
      const muteState = !isMuted;
      incomingCall.mute(muteState);
      dispatch(setIsMuted(muteState));
    }

    if (outgoingCall) {
      const muteState = !isMuted;
      outgoingCall.mute(muteState);
      dispatch(setIsMuted(muteState));
    }
  };

  const toggleHold = async () => {
    try {
      const newHoldStatus = !holdStatus;

      dispatch(setHoldStatus(newHoldStatus));
      dispatch(setOnHold(newHoldStatus));

      const myCallSid =
        incomingCall?.parameters.CallSid || outgoingCall?.parameters.CallSid;
      console.log('⭐️ ~ toggleHold ~ myCallSid:', myCallSid);

      const holdParticipant = participants.find(
        (parties: any) => parties.callSid !== myCallSid
      );

      const response = await axios.post(`${window.API_URL}/twilio/hold-call`, {
        conferenceSid: conferenceId,
        participantSid: holdParticipant?.callSid,
        hold: newHoldStatus,
      });
      console.log('⭐️ ~ toggleHold ~ response:', response);
    } catch (error) {
      console.error('Error toggling hold:', error);
    }
  };

  const handleHangup = async (isTransferCall: boolean) => {
    console.log('⭐️ ~ handleHangup ~ isTransferCall:', isTransferCall);

    // if (!isTransferCall) {
    //   await addCallDuration({ callDuration, conferenceId });
    // }

    if (incomingCall) {
      incomingCall.reject();
      incomingCall.disconnect();
      dispatch(setIncomingCall(null));
      dispatch(setIsCallInProgress(false));
      dispatch(setCallDuration(0));
      dispatch(setOnHold(false));
      dispatch(setIsMuted(false));
      dispatch(setHoldStatus(false));
      dispatch(setIsRecording(true));
    }

    if (outgoingCall) {
      dispatch(setOutgoingCall(null));
      dispatch(setIsCallInProgress(false));
      dispatch(setCallDuration(0));
      dispatch(setOnHold(false));
      dispatch(setIsMuted(false));
      dispatch(setHoldStatus(false));
      dispatch(setIsRecording(true));
      outgoingCall.disconnect();
    }
  };

  // const rejectIncomingCall = () => {
  //   if (incomingCall) {
  //     incomingCall.reject();
  //     dispatch(setIncomingCall(null));
  //   }
  // };

  const handleTransferCall = async () => {
    console.log('callInstance.current', outgoingCall);
    console.log('incomingCall', incomingCall);

    // if (!incomingCall?.parameters?.CallSid) {
    //   setErrorMessage('Please ensure a call is in progress.');
    //   return;
    // }

    try {
      const callSid =
        outgoingCall?.parameters?.CallSid || incomingCall?.parameters.CallSid;
      console.log('1. ⭐️ ~ handleTransferCall ~ callSid:', callSid);

      // const callSid = incomingCall?.parameters?.CallSid;
      console.log('2. ⭐️ handleTransferCall ~ callSid:', callSid);
      console.log('3. ⭐️ conferenceName', conferenceName);

      await transferCall({
        conferenceName: conferenceName,
        currentParticipantCallSid: callSid,
        newParticipantNumber: '+919427420518',
        // newParticipantNumber: '+919106919088',
        conferenceSid: conferenceId,
        removeCurrentParticipant: true,
      });

      // transfer the call
      // await axios.post(
      //   'https://absolute-sailfish-exact.ngrok-free.app/api/v1/twilio/transfer-call',
      //   {
      //     conferenceName: conferenceName,
      //     currentParticipantCallSid: callSid,
      //     newParticipantNumber: '+919427420518',
      //     conferenceSid: conferenceId,
      //     removeCurrentParticipant: true,
      //   }
      // );

      handleHangup(true);

      console.log(`4. ⭐️ Call successfully transferred`);
    } catch (error) {
      dispatch(
        setErrorMessage(
          error instanceof Error
            ? error.message
            : 'An error occurred during the call transfer.'
        )
      );
      console.error('Error transferring call:', error);
    }
  };

  // const stopRecording = async () => {
  //   try {
  //     const callSid =
  //       incomingCall?.parameters?.CallSid || outgoingCall?.parameters?.CallSid;

  //     if (!callSid) {
  //       throw new Error('No Call SID available.');
  //     }
  //     await axios.post(
  //       `${window.API_URL}/twilio/stop-recording`,
  //       { callSid },
  //       { responseType: 'blob' }
  //     );
  //     dispatch(setIsRecording(false));

  //     // // Create a blob from the response data
  //     // const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

  //     // // Create a URL for the blob
  //     // const url = URL.createObjectURL(audioBlob);

  //     // // Create a temporary anchor element
  //     // const anchor = document.createElement('a');
  //     // anchor.href = url;
  //     // // anchor.download = `recording_${recordingSid}.mp3`; // Set the file name
  //     // document.body.appendChild(anchor); // Append the anchor to the body
  //     // anchor.click(); // Trigger the download
  //     // document.body.removeChild(anchor); // Clean up

  //     // // Revoke the blob URL to release memory
  //     // URL.revokeObjectURL(url);
  //     console.log('Recording stopped');
  //   } catch (error) {
  //     console.error('Error stopping recording:', error);
  //     setErrorMessage('Unable to stop recording. Please try again.');
  //   }
  // };

  return (
    <>
      <Draggable>
        <div className="floating-modal">
          <div className="floating-modal-header">
            <h6 className="floating-modal-header-title">In Progress</h6>
            <div className="flex items-center gap-x-2.5">
              <span className="">{getMinutesAndSeconds(callDuration)}</span>
              <Expand02 className="w-3 h-3" />
            </div>
          </div>
          <div className="floating-modal-body ">
            <div className="flex gap-x-2.5">
              <img
                className="w-10 h-10 rounded-full border border-gray500 flex-none"
                src={
                  fromNumberContact?.image ||
                  outgoingCallDetails?.image ||
                  Profile
                }
              />
              {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
              {/* <div className="w-10 h-10 rounded-full border border-gray500 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
                MD
              </div> */}
              <div className="truncate">
                <div className="flex items-center justify-center gap-x-2.5">
                  <h6 className="text-base font-medium text-white truncate">
                    {fromNumberContact?.name ||
                      outgoingCallDetails?.name ||
                      outgoingCallDetails?.number}
                  </h6>
                  <div className="flex items-center gap-x-1 text-white text-[10px] font-normal leading-3 border border-gray400 p-[3px] rounded flex-none">
                    <span>3.5</span>
                    <img src={starWhite} />
                  </div>
                </div>
                <p className="text-xs leading-[1.5] font-normal text-borderPrimary truncate">
                  {fromNumberContact?.companyName ||
                    outgoingCallDetails?.companyName}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center gap-2 w-full mt-[18px]">
              {/* <ButtonCmp
                className="!px-[6px] !py-[7px] btn-gray"
                onClick={stopRecording}
              >
                <div>
                  <p
                    className={`w-1 h-1 rounded-full ${
                      isRecording ? 'bg-fgErrorPrimary' : 'bg-white'
                    }`}
                  ></p>
                  <p
                    className={`text-[10px] leading-[1] mt-0.5 ${
                      isRecording ? 'text-fgErrorPrimary' : 'text-white'
                    }`}
                  >
                    Rec
                  </p>
                </div>
              </ButtonCmp> */}

              <ButtonCmp
                className={`btn-xs ${onHold ? 'btn-success' : 'btn-gray'}`}
                icon={
                  onHold ? (
                    <PhonePlus className="w-4 h-4" />
                  ) : (
                    <PhonePause className="w-4 h-4" />
                  )
                }
                onClick={toggleHold}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                className="btn-xs btn-gray"
                icon={<PhoneOutgoing02 className="w-4 h-4" />}
                onClick={handleTransferCall}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                className={`btn-xs btn-gray`}
                icon={
                  isMuted ? (
                    <MicrophoneOff01 className="w-4 h-4" />
                  ) : (
                    <Microphone01 className="w-4 h-4" />
                  )
                }
                onClick={toggleMute}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                className="btn-xs btn-danger"
                icon={<PhoneCall02 className="w-4 h-4" />}
                onClick={() => handleHangup(false)}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
          <div className="floating-modal-body flex flex-wrap gap-y-3 gap-x-2.5 hidden">
            <div className="w-full flex items-center gap-x-2.5">
              <img
                className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                src={currentUser?.imageUrl + currentUser?.image}
              />
              IF NO IMAGE FOUND SHOW THIS BELOW CODE
              <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
                MD
              </div>
              <h6 className="text-base font-medium text-white line-clamp-1">
                {currentUser?.firstName} {currentUser?.lastName}
              </h6>
              <p className="text-xs leading-[1.5] font-normal text-gray400 flex-none">
                You
              </p>
            </div>
            <div className="pr-3 flex items-center gap-x-2">
              <img
                className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                src={
                  fromNumberContact?.image ||
                  outgoingCallDetails?.image ||
                  Profile
                }
              />
              {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
              {/* <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
                MD
              </div> */}
              <h6 className="text-base font-medium text-white line-clamp-1">
                {fromNumberContact?.name ||
                  outgoingCallDetails?.name ||
                  outgoingCallDetails?.number}
              </h6>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default InProgressCall;
