import { ApexOptions } from 'apexcharts';

export const predictivePriceOptions: ApexOptions = {
  chart: {
    height: 350,
    type: 'area',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
  colors: ['#A4BCFD', '#3538CD', '#6172F3'], // Adjust the colors if needed to match your chart
  yaxis: {
    labels: {
      offsetX: 0,
      formatter: function (val: any) {
        return val === 0 ? val : `$${val}`;
      },
    },
    min: 750,
    max: 2250,
    tickAmount: 4,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    categories: [],
    tickPlacement: 'between',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
};
