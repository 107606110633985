import {
  FilterLines,
  Plus,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import {
  CUSTOMER_SEGMENT_TYPES,
  PERMISSION,
  TABLE_IDS,
} from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { CustomerList, CustomerListCount } from 'src/services/CustomerService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndDash,
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../assets/img/Avatar7.png';
import BadgeCmp from '../../components/BadgeCmp';
import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';
import AddOnBoardCustomer from '../CustomerOnboarding/AddOnBoardCustomer';
import MailConfirmation from '../CustomerOnboarding/MailConfirmation';
import ReviewOnboarding from '../CustomerOnboarding/ReviewOnboarding';

import CustomerFilterModal from './CustomerFilterModal';
import CreateUpdateCustomerGroupModal from './CustomerGroup/CreateUpdateCustomerGroupModal';
import CustomerLoaderRow from './CustomerLoaderRow';
import CustomerRow from './CustomerRow';
import CustomerSalesRepReassignModal from './CustomerSalesRepReassignModal';

const initParams: any = {
  search: '',
  sortType: 'desc',
  sortField: 'totalGP',
  page: 1,
  segment: '',
  limit: 50,
  team: 'all',
  segmentModifierStartDate: undefined,
  segmentModifierEndDate: undefined,
  member: 'all',
};

const initAction = {
  mode: '',
  data: {},
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const Customers = () => {
  const location = useLocation();
  const uiState = useSelector((state: any) => state.MobileView);
  const navigate = useNavigate();
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();
  const { lastSyncDateTimeActual, appearance } = useContext(BasicContext);
  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);
  const [lastSyncTime, setLastSyncTime] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState<any[]>([]);
  const [customerOnboardData, setCustomerOnboardData] = useState<any>(null);
  const [action, setAction] = useState<any>(initAction);
  const [isShowCreateCustomerGroupModal, setIsShowCreateCustomerGroupModal] =
    useState(false);

  const [params, setParams] = useState(() => {
    const path = location?.state?.from?.split('/');
    const customerDetailLocal = localStorage.getItem('customer-detail');
    localStorage.removeItem('customer-detail');

    if (
      path?.includes('customers') &&
      path?.length >= 3 &&
      customerDetailLocal
    ) {
      const storedParams = localStorage.getItem(TABLE_IDS.CUSTOMER_LIST);

      return storedParams && isValidJSON(storedParams)
        ? JSON.parse(storedParams)
        : initParams;
    } else {
      localStorage.removeItem(TABLE_IDS.CUSTOMER_LIST);

      return initParams;
    }
  });

  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [segmentListOption, setSegmentListOption] = useState<any[]>([]);
  const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [isShowCustomerSalesRepReassign, setIsShowCustomerSalesRepReassign] =
    useState(false);
  const [
    selectedCustomersForSalesRepReassign,
    setSelectedCustomersForSalesRepReassign,
  ] = useState<any[]>([]);

  useEffect(() => {
    let fCount = 0;

    if (params?.segmentModifier && params?.segmentModifier != '') {
      fCount += 1;
    }

    if (
      params?.segmentModifierStartRange != '' &&
      params?.segmentModifierEndRange != ''
    ) {
      fCount += 1;
    }

    if (uiState.isMobile || uiState.currentBreakpoint === 'LG') {
      if (params?.team != 'all') {
        fCount += 1;
      }

      if (params?.member != 'all') {
        fCount += 1;
      }
    }

    if (uiState.isMobile && params?.segment != '') {
      fCount += 1;
    }

    setFilterCount(fCount);
  }, [params]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);

    const statusArray = Object.entries(CUSTOMER_SEGMENT_TYPES).map(
      (keyValue: any) => ({
        id: keyValue?.[1],
        value: capitalizeFirstLetter(keyValue?.[1]),
      })
    );
    setSegmentListOption(
      getSelectBoxOptions(
        statusArray.filter((status) => status.id != 'test'),
        'id',
        'value'
      )
    );
  }, []);

  useEffect(() => {
    if (customers && customers.length > 0 && !isNotificationLoading) {
      setTimeout(() => {
        if (entityCounts?.customerCount && entityCounts?.customerCount > 0) {
          const param = { type: 'customer_created' };
          handleNotificationRead(param);

          const newCustomers = customers.map((customer) => ({
            ...customer,
            hasUnreadNotification: false,
          }));

          setCustomers(newCustomers);
        }
      }, 5000);
    }
  }, [customers, entityCounts, isNotificationLoading]);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      const unassignedSelectBoxOption = {
        value: 'unassigned',
        label: 'Unassigned',
        image: null,
        isImage: false,
      };

      if (hasRoleV2('admin')) {
        setUserList([unassignedSelectBoxOption, ...updatedListAllMembers]);
      } else {
        setUserList([...updatedListAllMembers]);
      }
    }
  }, [params.team, teamListOptions]);

  const getCustomerData = (signal?: any) => {
    setCustomers([]);
    setIsLoading(true);

    Promise.all([
      CustomerList(params, signal),
      CustomerListCount(params, signal),
    ])
      .then(([customerResponse, customerCountResponse]: any) => {
        if (customerResponse.data) {
          setCustomers(customerResponse.data);
        }

        setTotal(customerCountResponse.total ?? 0);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setCustomers([]);
        setTotal(0);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getCustomerData(signal);
    localStorage.setItem(TABLE_IDS.CUSTOMER_LIST, JSON.stringify(params));

    return () => {
      controller.abort();
    };
  }, [params]);

  const onClearFilter = () => {
    if (!uiState.isMobile) {
      if (
        uiState.currentBreakpoint === 'LG' ||
        uiState.currentBreakpoint === 'SM'
      ) {
        setParams((old: any) => ({
          ...old,
          page: 1,
          team: 'all',
          member: 'all',
          segmentModifierStartRange: '',
          segmentModifierEndRange: '',
        }));
      } else {
        setParams((old: any) => ({
          ...old,
          page: 1,
          segmentModifierStartRange: '',
          segmentModifierEndRange: '',
        }));
      }
    } else {
      setParams((old: any) => ({
        ...old,
        page: 1,
        segment: '',
        segmentModifier: '',
        team: 'all',
        member: 'all',
        segmentModifierStartRange: '',
        segmentModifierEndRange: '',
      }));
    }

    setFilterCount(0);
  };

  const headCells = useMemo(() => {
    const baseHeadCells = [
      {
        id: 'name',
        name: 'Customer Name',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'new_segment',
        name: 'Tags',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        sortable: true,
      },
      {
        id: 'customerGroupCount',
        name: 'Grouping',
        sortable: true,
      },
      {
        id: 'totalGP',
        name: 'CLV',
        sortable: true,
      },
      {
        id: 'last_ordered',
        name: 'Last Order',
        sortable: true,
      },
    ];

    const actionCell = {
      id: 'action',
      rowClassName: 'w-[5%]',
      name: (
        <CheckBox
          checked={
            selectedCustomersForSalesRepReassign?.length > 0 &&
            customers.every((customer) =>
              selectedCustomersForSalesRepReassign?.some(
                (reAssignCustomer) => reAssignCustomer.id === customer.id
              )
            ) &&
            !isLoading
          }
          onChangeFunc={(e: any) => {
            if (e.target.checked) {
              setSelectedCustomersForSalesRepReassign([
                ...selectedCustomersForSalesRepReassign,
                ...customers,
              ]);
            } else {
              const assignCustomers =
                selectedCustomersForSalesRepReassign.filter(
                  (reAssignCustomer) =>
                    !customers?.some(
                      (customer) => customer.id === reAssignCustomer.id
                    )
                );
              setSelectedCustomersForSalesRepReassign(assignCustomers);
            }
          }}
          parentClassName=""
        />
      ),
      sortable: false,
    };

    if (hasRoleV2('admin')) {
      return [...baseHeadCells, actionCell];
    }

    return baseHeadCells;
  }, [selectedCustomersForSalesRepReassign, customers]);

  useEffect(() => {
    if (lastSyncDateTimeActual) {
      const browserTimezone =
        appearance?.timezone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      const parsedDate = moment.utc(lastSyncDateTimeActual).tz(browserTimezone);
      const formattedDate = parsedDate.isSame(moment(), 'day')
        ? `Today, ${parsedDate.format('hh:mm A')}`
        : parsedDate.format('MMMM Do, hh:mm A');
      setLastSyncTime(formattedDate);
    }
  }, [lastSyncDateTimeActual]);

  const handleCardSelection = (segmentType: any) => {
    // here
    setFilterCount(0);
    setSelectedCustomersForSalesRepReassign([]);

    if (segmentType) {
      setParams((old: any) => ({
        ...old,
        ...{
          segment: segmentType ?? '',
          segmentModifier: undefined,
          segmentModifierStartDate: undefined,
          segmentModifierEndDate: undefined,
          page: 1,
        },
      }));
    } else {
      setParams((old: any) => ({
        ...old,
        ...{
          segment: '',
          segmentModifier: undefined,
          segmentModifierStartDate: undefined,
          segmentModifierEndDate: undefined,
          page: 1,
        },
      }));
    }
  };

  const getSubTitle = () => {
    if (params.segment === CUSTOMER_SEGMENT_TYPES.NEW) {
      let newSelectedTimeRange = 'past 30 days';

      if (params.segmentModifier === '30_days') {
        newSelectedTimeRange = 'past 30 days';
      } else if (params.segmentModifier === '60_days') {
        newSelectedTimeRange = 'past 60 days';
      } else if (params.segmentModifier === '90_days') {
        newSelectedTimeRange = 'past 90 days';
      } else if (params.segmentModifier === '180_days') {
        newSelectedTimeRange = 'past 180 days';
      } else if (params.segmentModifier === '360_days') {
        newSelectedTimeRange = 'past 360 days';
      } else if (params.segmentModifier === 'this_year') {
        newSelectedTimeRange = 'current year';
      }

      return `Customer that has been on-boarded in the ${newSelectedTimeRange}.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.ACTIVE) {
      let activeSelectedTimeRange = 'past 30 days';

      if (params.segmentModifier === '30_days') {
        activeSelectedTimeRange = 'past 30 days';
      } else if (params.segmentModifier === '60_days') {
        activeSelectedTimeRange = 'past 60 days';
      } else if (params.segmentModifier === '90_days') {
        activeSelectedTimeRange = 'past 90 days';
      } else if (params.segmentModifier === '180_days') {
        activeSelectedTimeRange = 'past 180 days';
      } else if (params.segmentModifier === '360_days') {
        activeSelectedTimeRange = 'past 360 days';
      } else if (params.segmentModifier === 'this_year') {
        activeSelectedTimeRange = 'current year';
      }

      return `Customer with orders in the ${activeSelectedTimeRange}.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.INACTIVE) {
      return `Customer with at least one order but has not ordered in the last ${
        params.segmentModifierStartRange
          ? params.segmentModifierStartRange
          : '90'
      }-${
        params.segmentModifierEndRange ? params.segmentModifierEndRange : '360'
      } days.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.TOP) {
      return `Top ${
        params.segmentModifier ? params.segmentModifier : '25'
      } customers by total margin in the last 12 months.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.AT_RISK) {
      let atRiskSelectedTimeRange = params.segmentModifier
        ? params.segmentModifier
        : '45';

      return `Customer with a 50% GP decrease in the last ${atRiskSelectedTimeRange} days compared to the previous ${atRiskSelectedTimeRange} days.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.TEST) {
      return `Customers which are assumed as testing accounts.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.DORMANT) {
      let dormantSelectedTimeRange = 'last 360 days';

      if (params.segmentModifier === '30_days') {
        dormantSelectedTimeRange = 'last 30 days';
      } else if (params.segmentModifier === '60_days') {
        dormantSelectedTimeRange = 'last 60 days';
      } else if (params.segmentModifier === '90_days') {
        dormantSelectedTimeRange = 'last 90 days';
      } else if (params.segmentModifier === '180_days') {
        dormantSelectedTimeRange = 'last 180 days';
      } else if (params.segmentModifier === '360_days') {
        dormantSelectedTimeRange = 'last 360 days';
      } else if (params.segmentModifier === 'this_year') {
        dormantSelectedTimeRange = 'current year';
      }

      return `Customer with at least one order but has not ordered in the ${dormantSelectedTimeRange}.`;
    } else if (params.segment === CUSTOMER_SEGMENT_TYPES.PROSPECT) {
      return `On-boarded customers with no orders.`;
    } else {
      return `View, organize, and manage your customer list.`;
    }
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title={'Customers'}
          desc={`View, organize, and manage your organization customer list.`}
          rightSideContent={
            lastSyncTime ? (
              <div className="flex sm:gap-2 gap-3 items-center">
                <div className={`flex gap-1.5 justify-center items-center `}>
                  <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                  <span className="text-xs text-textSecondary">
                    Last sync: {lastSyncTime}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )
          }
          isShowNotificationBox
        />
      }
    >
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="table-top-header ">
          <div className="table-left-wrap sm:w-auto w-full">
            <div className="table-title-wrap">
              <h5 className="table-title">
                {params.segment !== ''
                  ? capitalizeFirstLetterAndDash(params?.segment ?? '')
                  : hasRoleV2('admin') || hasRoleV2('manager')
                  ? 'All'
                  : 'My'}{' '}
                Customers
              </h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {total} {total <= 1 ? 'Customer' : 'Customers'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">{getSubTitle()}</p>
          </div>
          {selectedCustomersForSalesRepReassign.length > 0 &&
            hasRoleV2('admin') && (
              <ButtonCmp
                className="btn-outline-primary sm:flex-none flex-1 xs:min-w-[auto] min-w-full"
                onClick={() => setIsShowCreateCustomerGroupModal(true)}
              >
                Group
              </ButtonCmp>
            )}
          {selectedCustomersForSalesRepReassign.length > 0 &&
            hasRoleV2('admin') && (
              <ButtonCmp
                className="btn-outline-primary sm:flex-none flex-1 xs:min-w-[auto] min-w-full"
                onClick={() => setIsShowCustomerSalesRepReassign(true)}
              >
                Reassign
              </ButtonCmp>
            )}
          <ButtonCmp
            className={`btn-outline-primary ${
              window.MODE === 'production' ? '!hidden' : ''
            }`}
            onClick={() =>
              setAction((old: any) => ({
                ...old,
                mode: 'addOnboardCustomer',
              }))
            }
          >
            <Plus className="w-4 h-4" />
            Onboard New Customer
          </ButtonCmp>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <InputText
              inputName="searchCustomer"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={params.search}
              inputType="text"
              isClearable={true}
              onChangeFunc={(e) => {
                setParams((old: any) => ({
                  ...old,
                  ...{ search: e.target.value, page: 1 },
                }));
              }}
              parentClassName="table-searchInput"
            />
          </div>
          <div className="table-statustbox !w-28 mdm:block hidden">
            <SelectBox
              name="segmentGroup"
              id="segmentGroup"
              className="form_control"
              size="sm"
              placeholder="All Tags"
              isClearable={true}
              options={segmentListOption}
              onChangeFunc={(event: any) =>
                handleCardSelection(event?.value ?? '')
              }
              value={segmentListOption.filter(
                (val: any) => params.segment === val.value
              )}
            />
          </div>
          {!hasRoleV2('user') && (
            <>
              <div className="table-selectbox xl:block hidden">
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  options={teamListOptions}
                  isDisabled={params.member === 'unassigned'}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      ...{
                        team: event?.value ?? 'all',
                        member: 'all',
                        page: 1,
                      },
                    }));
                  }}
                  value={teamListOptions.filter(
                    (val: any) => params.team === val.value
                  )}
                />
              </div>
              <div className="table-selectbox xl:block hidden">
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  noOptionMessage="No Users Found"
                  isSearchable={true}
                  className="form_control"
                  isClearable={true}
                  options={userList}
                  onChangeFunc={(event: any) => {
                    if (event?.value === 'unassigned') {
                      setParams((old: any) => ({
                        ...old,
                        ...{
                          team: 'all',
                          member: event?.value ?? 'all',
                          page: 1,
                        },
                      }));
                    } else {
                      setParams((old: any) => ({
                        ...old,
                        ...{ member: event?.value ?? 'all', page: 1 },
                      }));
                    }
                  }}
                  value={userList.filter(
                    (user: any) => params.member === user.value
                  )}
                />
              </div>
            </>
          )}

          {hasRoleV2('user') ? (
            <ButtonCmp
              type="submit"
              className={`btn_secondary_black table-filter-btn ${
                params.segment !== '' &&
                params.segment !== CUSTOMER_SEGMENT_TYPES.PROSPECT
                  ? ''
                  : 'mdm:hidden'
              }`}
              onClick={(e) => {
                if (
                  e?.target?.id !== 'closeBtn' &&
                  e?.target?.parentElement?.id !== 'closeBtn'
                )
                  setIsDisplayFilterModal(true);
              }}
              iconSide="left"
              icon={<FilterLines className=" w-4 h-4" />}
            >
              Filters
              {!!(filterCount > 0) && (
                <>
                  <p className="filter-btn-selected">{filterCount}</p>
                  <XClose
                    className="w-4 h-4 mt-[1px]"
                    id="closeBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      onClearFilter();
                    }}
                  />
                </>
              )}
            </ButtonCmp>
          ) : (
            <ButtonCmp
              type="submit"
              className={`btn_secondary_black table-filter-btn ${
                params.segment !== '' &&
                params.segment !== CUSTOMER_SEGMENT_TYPES.PROSPECT
                  ? ''
                  : 'xl:hidden'
              }`}
              onClick={(e) => {
                if (
                  e?.target?.id !== 'closeBtn' &&
                  e?.target?.parentElement?.id !== 'closeBtn'
                )
                  setIsDisplayFilterModal(true);
              }}
              iconSide="left"
              icon={<FilterLines className=" w-4 h-4" />}
            >
              Filters
              {!!(filterCount > 0) && (
                <>
                  <p className="filter-btn-selected">{filterCount}</p>
                  <XClose
                    className="w-4 h-4 mt-[1px]"
                    id="closeBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      onClearFilter();
                    }}
                  />
                </>
              )}
            </ButtonCmp>
          )}

          <div className="table-recordsPerPage min-w-[75px]">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              headCells={headCells}
              params={params}
              setParams={setParams}
              tableDataArr={customers}
              TableLoaderRowCmp={CustomerLoaderRow}
              TableRowCmp={CustomerRow}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={15}
              tableHeaderClass=""
              isTableRowClickable={hasPermissionV2(PERMISSION.CUSTOMER_DETAIL)}
              tableRowCmpProps={{
                openCustomerPage: (rowData: any) => {
                  if (hasPermissionV2(PERMISSION.CUSTOMER_DETAIL)) {
                    navigate(`${PATH.CUSTOMER_DETAILS}/${rowData?.id}`);
                  }
                },
                selectedCustomersForSalesRepReassign:
                  selectedCustomersForSalesRepReassign,
                setSelectedCustomersForSalesRepReassign:
                  setSelectedCustomersForSalesRepReassign,
              }}
            />
          </div>
          {!isLoading && !customers.length && (
            <NotFoundUI
              title="No Customer Found"
              desc="There are no data for customers."
              containerClassName="min-h-[unset]"
            />
          )}
        </div>{' '}
        <div className="full bg-white rounded-b-xl">
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>

      {isDisplayFilterModal && (
        <CustomerFilterModal
          handleClose={() => setIsDisplayFilterModal(false)}
          params={params}
          teamListOptions={teamListOptions}
          setParams={setParams}
          userList={userList}
          setFilterCount={setFilterCount}
          segmentListOption={segmentListOption}
          setSelectedCustomersForSalesRepReassign={
            setSelectedCustomersForSalesRepReassign
          }
        />
      )}

      {isShowCustomerSalesRepReassign && (
        <CustomerSalesRepReassignModal
          selectedCustomersForSalesRepReassign={
            selectedCustomersForSalesRepReassign
          }
          handleClose={(closeStatus: boolean) => {
            if (closeStatus) {
              getCustomerData();
            }
            setSelectedCustomersForSalesRepReassign([]);
            setIsShowCustomerSalesRepReassign(false);
          }}
        />
      )}

      {action.mode === 'addOnboardCustomer' && (
        <AddOnBoardCustomer
          action={action}
          setAction={setAction}
          customerData={action?.data}
          handleClose={(formData?: any) => {
            if (formData) {
              setCustomerOnboardData(formData);
              setAction({ mode: 'review-onboarding', data: null });
              // setAction({ mode: 'send-mail', data: null });
            } else {
              setAction({ mode: null, data: null });
            }
          }}
        />
      )}

      {action.mode === 'send-mail' && (
        <MailConfirmation
          setAction={setAction}
          customerData={customerOnboardData}
          handleClose={() => setAction({ mode: null, data: null })}
        />
      )}

      {action.mode === 'review-onboarding' && (
        <ReviewOnboarding
          action={action}
          setAction={setAction}
          customerData={customerOnboardData}
          handleClose={() => setAction({ mode: null, data: null })}
        />
      )}

      {isShowCreateCustomerGroupModal && (
        <CreateUpdateCustomerGroupModal
          handleClose={(status: boolean) => {
            if (status) {
              getCustomerData();
            }
            setSelectedCustomersForSalesRepReassign([]);
            setIsShowCreateCustomerGroupModal(false);
          }}
          createCustomersList={selectedCustomersForSalesRepReassign}
        />
      )}
    </PageSectionLayout>
  );
};
export default Customers;
