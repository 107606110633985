import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AccordionCmp from 'src/components/AccordionCmp';
import { usePhone } from 'src/utils/CommonFunctions';

import { OrderContext } from '..';
import { filterServicesByType } from '../../order.constant';

const AddressDetails = () => {
  const {
    currentLegData,
    isLoading,
    isOrderHasLeg,
    isMasterOrder,
    serviceList,
  } = useContext<any>(OrderContext);
  const { getFormatPhoneWithPlus } = usePhone();

  const renderContactDetails = (contactData: any, type: string) => (
    <div>
      <h6 className="form_label block mb-1.5">{type}</h6>
      <div className="rounded-[10px] border border-utilityGray200 shadow-sm p-4 bg-white flex gap-x-6 flex-wrap">
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Contact Name
          </h6>
          <p
            className={`text-textSecondary text-sm font-medium ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {!isLoading ? contactData?.contactName || 'N/A' : 'Test Customer'}
          </p>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Phone Number
          </h6>
          <div className="text-sm">
            <Link
              to={`tel:${contactData?.phoneNumber}`}
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? getFormatPhoneWithPlus(contactData?.phoneNumber) || 'N/A'
                : '(123) 123-1231'}
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Contact Email
          </h6>
          <div className="text-sm">
            <Link
              to={`mailto:${contactData?.email}`}
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading ? contactData?.email || 'N/A' : 'Test@gmail.com'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const renderAdditionalService = (additionalService: any, type: string) => (
    <>
      {additionalService?.length > 0 && (
        <div>
          <h6 className="form_label block mb-1.5">{type}</h6>
          <div className="rounded-xl border border-utilityGray200 bg-white shadow-sm px-4 py-1.5">
            <div className="flex flex-wrap -mx-5">
              {additionalService?.map((service: any, index: number) => (
                <div
                  className="flex gap-2 text-grayLight900 truncate px-5 py-2.5 w-1/4"
                  key={index}
                >
                  <Check className="w-4 h-4 text-primary flex-none" />
                  <h6 className="truncate text-xs"> {service?.name}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );

  const pickupAdditionalServices = filterServicesByType(
    serviceList,
    currentLegData?.additionalServices?.serviceId,
    'PICKUP'
  );

  const deliveryAdditionalServices = filterServicesByType(
    serviceList,
    currentLegData?.additionalServices?.serviceId,
    'DELIVERY'
  );

  return (
    <div>
      <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
        Shipper & Consignee Details
      </h6>
      <AccordionCmp
        title={
          <div className="flex items-start flex-1">
            <div className="w-1/2 pr-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                From
              </h6>
              <p
                className={`text-grayLight600 text-sm font-normal line-clamp-1 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {!isOrderHasLeg && (
                  <>
                    <span className="font-medium text-grayLight900">
                      {currentLegData?.shipper?.companyName || 'N/A'}
                    </span>
                    &nbsp;{' '}
                  </>
                )}
                {currentLegData?.shipper?.shipperAddress?.address1 || 'N/A'}
              </p>
            </div>
            <div className="w-1/2 pl-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">To</h6>
              <p
                className={`text-grayLight600 text-sm font-normal line-clamp-1 ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {!isOrderHasLeg && (
                  <>
                    <span className="font-medium text-grayLight900">
                      {currentLegData?.consignee?.companyName || 'N/A'}
                    </span>
                    &nbsp;{' '}
                  </>
                )}
                {currentLegData?.consignee?.consigneeAddress?.address1 || 'N/A'}
              </p>
            </div>
          </div>
        }
        className="!bg-gray25 !p-5 !rounded-xl !shadow-xs"
        contentClassName="!bg-gray25 flex flex-col gap-y-4"
        contentParentClass="!border-utilityGray200"
        isClickable={
          pickupAdditionalServices?.length > 0 ||
          deliveryAdditionalServices?.length > 0
        }
        AccordionIconClass={`${
          pickupAdditionalServices?.length > 0 ||
          deliveryAdditionalServices?.length > 0
            ? ''
            : 'hidden'
        }`}
      >
        <>
          {isMasterOrder &&
            renderContactDetails(currentLegData?.shipper, 'Shipper')}

          {((!isMasterOrder && isOrderHasLeg) ||
            (isMasterOrder && !isOrderHasLeg)) &&
            pickupAdditionalServices?.length > 0 &&
            renderAdditionalService(
              pickupAdditionalServices,
              'Pickup Additional Services'
            )}

          {isMasterOrder &&
            renderContactDetails(currentLegData?.consignee, 'Consignee')}

          {((!isMasterOrder && isOrderHasLeg) ||
            (isMasterOrder && !isOrderHasLeg)) &&
            deliveryAdditionalServices?.length > 0 &&
            renderAdditionalService(
              deliveryAdditionalServices,
              'Delivery Additional Services'
            )}
        </>
      </AccordionCmp>
    </div>
  );
};

export default AddressDetails;
