import {
  ArrowUpRight,
  Edit05,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AdditionalServiceCmp from 'src/app/Orders/Common/AdditionalServiceCmp';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';

const ShipperAndConsigneeAddressModal = ({
  control,
  errors,
  data,
  onAutocompleteChange,
  onAddressInputChange,
  title,
  // setAction,
  status,
  handleActionType,
  isAddressExits,
  isValidAddress,
  selectBoxOptionList,
  handleSelectChange,
  setIsTailgate,
  currentLegData,
  setCurrentLegData,
  setValue,
  serviceList,
  saveShipperDetails,
  shipperBtnDisable,
}: any) => {
  const { mapService } = useContext(BasicContext);
  const { clearErrors, setError, watch } = useFormContext();

  const shipperId = watch(`${data}.id`);

  return (
    <div>
      <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
        {title}
      </h6>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0">
        <div className="flex flex-wrap sm:-mx-1.5 -my-2">
          <Controller
            name={`${data}.id`}
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <SelectBox
                label={`${title}`}
                labelRight={
                  <div>
                    {shipperId && (
                      <ButtonCmp
                        onClick={handleActionType(data, 'delete')}
                        className={`!p-0 !border-0 !shadow-none !bg-transparent !gap-0.5`}
                      >
                        <TooltipCmp message={`Delete ${title}`}>
                          <Trash01 className="h-4 w-4" />
                        </TooltipCmp>
                      </ButtonCmp>
                    )}
                    <ButtonCmp
                      className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 ms-1 ${
                        shipperBtnDisable && 'text-opacity-50'
                      }`}
                      onClick={() => {
                        saveShipperDetails(data)();
                      }}
                      disabled={shipperBtnDisable}
                    >
                      {shipperId ? (
                        <Edit05 className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                      {shipperId ? 'Edit' : 'Add'} New {title}
                    </ButtonCmp>
                  </div>
                }
                labelClassName="form_label block mb-1.5"
                name={`${data}Id`}
                id={`${data}Id`}
                size="sm"
                placeholder={`Select a ${title}`}
                noOptionMessage={`No ${title} Found`}
                isSearchable={true}
                className="form_control"
                parentClassName="flex-1 px-1.5 py-2"
                isClearable={true}
                options={selectBoxOptionList}
                onChangeFunc={handleSelectChange(`${data}Id`)}
                value={
                  value
                    ? selectBoxOptionList?.find((e: any) => e?.value == value)
                    : null
                }
                errorText={error ? error.message : null}
              />
            )}
          />

          <Controller
            name={`${data}.companyName`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                inputName="companyName"
                label="Company Name"
                placeholder="Enter Company Name"
                value={value}
                labelClassName="block mb-1.5"
                parentClassName="sm:px-1.5 py-2 sm:w-1/2"
                className={`${
                  !isValidAddress?.[`${data}CompanyName`] || error?.message
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                onChangeFunc={(e) => {
                  onChange(e);

                  if (error?.message) {
                    clearErrors(`${data}.companyName`);
                  }
                }}
                required
                errorText={errors?.[data]?.companyName?.message}
              />
            )}
          />

          {status && (
            <Controller
              name={`${data}.${data}Address.fullAddress`}
              control={control}
              render={({ fieldState: { error } }) =>
                mapService === 'trimble_map' ? (
                  <AutoCompleteTrimble
                    inputName={`fullAddress`}
                    required
                    placeholder="Enter address"
                    dropdownWrapClass="!top-[unset] bottom-[65%]"
                    className={`form_control truncate ${
                      !isValidAddress?.[`${data}Address`] || error?.message
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Address"
                    labelClassName="mb-1.5 block w-full"
                    mapParentClass="sm:px-1.5 py-2 sm:w-1/2"
                    labelRight={
                      <ButtonCmp
                        className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5"
                        onClick={() => {
                          handleActionType(
                            data,
                            isAddressExits ? 'addressUpdate' : 'addressCreate'
                          )();
                        }}
                        icon={
                          <ArrowUpRight className="w-4 h-4 text-primary700" />
                        }
                        iconSide="right"
                      >
                        View in Map
                      </ButtonCmp>
                    }
                    onChangeFunc={onAutocompleteChange}
                    onBlur={onAutocompleteChange}
                    isClearable
                    onAddressInputChange={onAddressInputChange}
                    value={isAddressExits}
                    errorText={error ? error.message : null}
                  />
                ) : (
                  <AutoCompleteGoogle
                    inputName={`fullAddress`}
                    required
                    placeholder="Enter address"
                    dropdownWrapClass="!top-[unset] bottom-[65%]"
                    className={`form_control truncate ${
                      !isValidAddress?.[`${data}Address`] || error?.message
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label="Address"
                    labelClassName="mb-1.5 block w-full"
                    mapParentClass="sm:px-1.5 py-2 sm:w-1/2"
                    labelRight={
                      <ButtonCmp
                        className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5"
                        onClick={() => {
                          handleActionType(
                            data,
                            isAddressExits ? 'addressUpdate' : 'addressCreate'
                          )();
                        }}
                        icon={
                          <ArrowUpRight className="w-4 h-4 text-primary700" />
                        }
                        iconSide="right"
                      >
                        View in Map
                      </ButtonCmp>
                    }
                    onChangeFunc={onAutocompleteChange}
                    onBlur={onAutocompleteChange}
                    isClearable
                    onAddressInputChange={onAddressInputChange}
                    value={isAddressExits}
                    errorText={error ? error.message : null}
                  />
                )
              }
            />
          )}

          <Controller
            name={`${data}.contactName`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                inputName="contact_name"
                label="Contact Name"
                placeholder="Enter Contact Name"
                labelClassName="block mb-1.5"
                parentClassName="sm:px-1.5 py-2 sm:w-1/2"
                value={value}
                onChangeFunc={(e) => {
                  if (error?.message) {
                    clearErrors(`${data}.contactName`);
                  }

                  onChange(e);
                }}
                required
                errorText={errors?.[data]?.contactName?.message}
              />
            )}
          />

          <div className="sm:px-1.5 py-2 sm:w-1/2">
            <label className="form_label block mb-1.5">
              Phone number
              <span className="text-red-600 ms-[2px] leading-4">*</span>
            </label>
            <Controller
              name={`${data}.phoneNumber`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="phone"
                  onNumberChange={(
                    country: any,
                    phone: any,
                    code: any,
                    isValid: boolean
                  ) => {
                    onChange(`+${code}${phone}`);

                    if (isValid || phone === '') {
                      clearErrors(`${data}.phoneNumber`);
                    } else {
                      setError(`${data}.phoneNumber`, {
                        type: 'manual',
                        message: 'Contact phone number is invalid.',
                      });
                    }
                  }}
                  errors={errors?.[data]?.phoneNumber?.message}
                  value={value}
                />
              )}
            />
          </div>

          <Controller
            name={`${data}.email`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                inputName="email"
                label="Email"
                placeholder="Enter Email"
                value={value}
                labelClassName="block mb-1.5"
                parentClassName="sm:px-1.5 py-2 sm:w-1/2"
                onChangeFunc={(e) => {
                  onChange(e);

                  if (error?.message) {
                    clearErrors(`${data}.email`);
                  }
                }}
                required
                errorText={errors?.[data]?.email?.message}
              />
            )}
          />
          <AdditionalServiceCmp
            setIsTailgate={setIsTailgate}
            currentLegData={currentLegData}
            setCurrentLegData={setCurrentLegData}
            setValue={setValue}
            title={
              data === 'shipper'
                ? 'Add Pickup Additional Services'
                : 'Add Delivery Additional Services'
            }
            // titleDescription={
            //   data === 'shipper'
            //     ? 'Please select any pickup additional services that are needed for this shipment.'
            //     : 'Please select any Delivery additional services that are needed for this shipment.'
            // }
            serviceList={serviceList}
            showService={data === 'shipper' ? 'pickup' : 'delivery'}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipperAndConsigneeAddressModal;
