import React from 'react';
import { Link } from 'react-router-dom';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName } from 'src/utils/CommonFunctions';

const LaneHistoryRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4  min-w-52 w-52 max-w-52">
      <div className="flex items-center truncate ">
        <div className="table-profile-customer-circle-xl rounded-md">
          {getShortName(`${data?.customer?.name}`)}
        </div>
        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data?.customer?.name}
          </h6>
          <Link to={'#'}>
            <p className="text-primary underline font-normal leading-tight truncate">
              #{data.customer.id}
            </p>
          </Link>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.orderCreation}</div>
      </div>
    </td>

    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.totalRates}</div>
      </div>
    </td>
    <td className="px-5 py-4 max-w-44 min-w-44 w-44">
      <TooltipCmp message={data?.address.from} type="dark" parentClassName="">
        <p className="flex">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate max-w-[130px]">
            {data?.address.from}
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp
        message={data?.address.to}
        type="dark"
        parentClassName="max-w-90"
      >
        <p className="flex">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate max-w-[130px]">
            {data?.address.to}
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.totalUnits} </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.totalWeight} lbs</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">${data?.totalCost}</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">${data?.totalPrice}</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">${data?.margin}</div>
      </div>
    </td>
  </>
);

export default LaneHistoryRow;
