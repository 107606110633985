import {
  AlertCircle,
  Eye,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Toggle from 'src/components/Toggle/Toggle';
import { getLabelByValue } from 'src/utils/CommonFunctions';

import ButtonCmp from '../../../../../components/ButtonCmp';
import InputText from '../../../../../components/InputText/InputText';
import SelectBox from '../../../../../components/SelectBox/SelectBox';
import TooltipCmp from '../../../../../components/TooltipCmp';
import { getFreightClass } from '../../../../../services/QuoteService';
import VisualizeUnit from '../../../QuotingDashboard/VisualizeUnit';

interface IProps {
  dimensionsFormData: any;
  validForm: any;
  initFormData: any;
  initValidForm: any;
  handlingUnitOptions: any;
  isHandlingUnitLoading: any;
  setDimensionsFormData: any;
  setValidForm: any;
  lengthMeasurement: any;
  weightMeasurement: any;
  dimensionsExtractedData: any;
  currentDimensionStep: number;
  setCurrentDimensionStep: any;
  checkIsDimensionFormValid: any;
}

const DimensionItems = ({
  dimensionsFormData,
  validForm,
  initFormData,
  initValidForm,
  handlingUnitOptions,
  isHandlingUnitLoading,
  setDimensionsFormData,
  setValidForm,
  lengthMeasurement,
  weightMeasurement,
  dimensionsExtractedData,
  currentDimensionStep,
  setCurrentDimensionStep,
  checkIsDimensionFormValid,
}: IProps) => {
  const [currentItem, setCurrentItem] = useState<any>({
    index: null,
    data: null,
  });
  const [isShowVisualUnit, setIsShowVisualUnit] = useState(false);
  const [isFreightClassLoading, setIsFreightClassLoading] = useState([
    { isLoading: false },
  ]);

  const addItems = () => {
    if (dimensionsFormData?.length > 0) {
      let dimensionValidationResponse =
        checkIsDimensionFormValid(currentDimensionStep);

      if (!dimensionValidationResponse) {
        return true;
      }
    }

    const initFormDataWithPallet = {
      ...initFormData,
      handlingUnit: handlingUnitOptions?.[0]?.value,
    };
    setDimensionsFormData((old: any) => [...old, initFormDataWithPallet]);
    setIsFreightClassLoading((old: any) => [...old, { isLoading: false }]);
    setValidForm((old: any) => [...old, initValidForm]);
    setCurrentDimensionStep(dimensionsFormData?.length + 1);
  };

  const removeItems = (id: any) => {
    const form = dimensionsFormData.filter(
      (formDataField: any, index: number) => index !== id
    );
    setDimensionsFormData(form);

    if (currentDimensionStep !== 0) {
      setCurrentDimensionStep(currentDimensionStep);
    } else {
      setCurrentDimensionStep(currentDimensionStep + 1);
    }
  };

  const handleToggle = (index: number) => (event: any) => {
    const { name, checked } = event.target;
    setDimensionsFormData((old: any) => {
      const oldData = [...old];
      oldData[index] = {
        ...oldData[index],
        [name]: checked,
      };

      return oldData;
    });
  };

  const handleSelectChange = (index: number, name: string) => (event: any) => {
    const value = event?.value;
    setDimensionsFormData((old: any) => {
      const oldData = [...old];
      oldData[index] = {
        ...oldData[index],
        [name]: value,
      };
      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...oldValidFormVal];
        oldValidData[index] = {
          ...oldValidData[index],
          [name]: {
            ...oldValidData[index][name],
            required: Boolean(oldData[index][name]),
          },
        };

        return oldValidData;
      });

      return oldData;
    });
  };

  const handleInputChange = (index: number) => (event: any) => {
    const { target, type: eventType } = event;
    let { name, value } = target;

    value = value ? parseFloat(value) : eventType === 'blur' ? 0 : value;

    setDimensionsFormData((old: any) => {
      const oldData = [...old];
      let totalWeight = oldData[index].totalWeight;

      if (name === 'weight') {
        totalWeight = value * oldData[index]?.handlingUnitNo;
      } else if (name === 'handlingUnitNo') {
        totalWeight = value * oldData[index].weight;
      }

      oldData[index] = {
        ...oldData[index],
        [name]: value,
        totalWeight: parseFloat(totalWeight),
      };

      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...oldValidFormVal];
        oldValidData[index] = {
          ...oldValidData[index],
          [name]: {
            ...oldValidData[index][name],
            ...{
              required: Boolean(oldData[index][name]),
              valid: Boolean(oldData[index][name]),
            },
          },
        };

        return oldValidData;
      });

      return oldData;
    });
  };

  const checkFreightClass = (index: number) => async () => {
    const oldData = [...dimensionsFormData];
    let freightClass = oldData[index]?.freightClass;
    let itemClassForDayross = oldData[index]?.itemClassForDayross;
    let weight = oldData[index]?.weight;
    let itemLength = oldData[index]?.itemLength;
    let width = oldData[index]?.width;
    let height = oldData[index]?.height;

    if (weight && itemLength && width && height) {
      const updatedArray = [...isFreightClassLoading]; // Create a copy of the array
      updatedArray[index] = { isLoading: true }; // Update the value at the specified index
      setIsFreightClassLoading(updatedArray);

      let freightData = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: lengthMeasurement,
        weightUnit: weightMeasurement,
      })
        .then((data: any) => data?.data || 0)
        .catch(() => false)
        .finally(() => {
          const updatedArr = [...isFreightClassLoading]; // Create a copy of the array
          updatedArr[index] = { isLoading: false }; // Update the value at the specified index
          setIsFreightClassLoading(updatedArr);
        });

      freightClass = freightData?.freightClass;
      itemClassForDayross = freightData?.itemClassForDayross;
    } else {
      freightClass = 0;
    }

    oldData[index] = {
      ...oldData[index],
      freightClass,
      itemClassForDayross,
    };
    setDimensionsFormData(oldData);
  };

  useEffect(() => {
    if (currentItem.data) {
      setIsShowVisualUnit(true);
    }
  }, [currentItem]);

  const handleUnitChange = async () => {
    const newFormData = await Promise.all(
      dimensionsFormData.map(async (form: any, index: number) => {
        const oldData = [...dimensionsFormData];
        let freightClass = form?.freightClass;
        let itemClassForDayross = form?.itemClassForDayross;
        let weight = form?.weight;
        let itemLength = form?.itemLength;
        let width = form?.width;
        let height = form?.height;

        if (weight && itemLength && width && height) {
          const updatedArray = [...isFreightClassLoading]; // Create a copy of the array
          updatedArray[index] = { isLoading: true }; // Update the value at the specified index
          setIsFreightClassLoading(updatedArray);

          let freightData = await getFreightClass({
            itemLength: itemLength || 0,
            width: width || 0,
            height: height || 0,
            weight: weight || 0,
            lengthUnit: lengthMeasurement,
            weightUnit: weightMeasurement,
          })
            .then((data: any) => data?.data || 0)
            .catch(() => false)
            .finally(() => {
              const updatedArr = [...isFreightClassLoading]; // Create a copy of the array
              updatedArr[index] = { isLoading: false }; // Update the value at the specified index
              setIsFreightClassLoading(updatedArr);
            });
          freightClass = freightData?.freightClass;
          itemClassForDayross = freightData?.itemClassForDayross;
        } else {
          freightClass = 0;
        }

        oldData[index] = {
          ...oldData[index],
          freightClass,
          itemClassForDayross,
        };

        return oldData[index];
      })
    );

    setDimensionsFormData(newFormData);
  };

  useEffect(() => {
    handleUnitChange();
  }, [lengthMeasurement]);

  const handleClearValidation = () => {
    const currentStepValidObj = validForm[currentDimensionStep];

    const keys = Object.keys(currentStepValidObj);

    // Check each property
    for (const key of keys) {
      currentStepValidObj[key].required = true;
      currentStepValidObj[key].valid = true;
    }
  };

  return (
    <>
      {dimensionsFormData?.[currentDimensionStep] && (
        <div
          key={`quoting_req_create_quote_form_dimensions${currentDimensionStep}`}
          className="p-3 rounded-lg border border-utilityGray200 bg-white"
        >
          <div className="flex flex-wrap flex-1 -m-1">
            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="handlingUnitNo"
              label="No of handling units"
              labelClassName="block mb-1.5"
              min="0"
              isError={
                !validForm[currentDimensionStep]?.handlingUnitNo.required ||
                !validForm[currentDimensionStep]?.handlingUnitNo.valid
              }
              icon={
                !validForm[currentDimensionStep]?.handlingUnitNo.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={
                dimensionsFormData?.[currentDimensionStep]?.handlingUnitNo ||
                null
              }
              onChangeFunc={handleInputChange(currentDimensionStep)}
              onBlur={checkFreightClass(currentDimensionStep)}
              required
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.handlingUnitNo &&
                dimensionsFormData?.[currentDimensionStep]?.handlingUnitNo ===
                  dimensionsExtractedData?.[currentDimensionStep]
                    ?.handlingUnitNo
              }
              shouldFormatNumber={true}
            />

            <SelectBox
              parentClassName="w-1/2 p-1"
              isSearchable
              name="handlingUnit"
              id="handlingUnit"
              inlineSelect={true}
              label="Handling unit"
              labelClassName="form_label mb-1.5 block"
              className="form_control"
              classComp={`mt-0`}
              options={handlingUnitOptions}
              onChangeFunc={handleSelectChange(
                currentDimensionStep,
                'handlingUnit'
              )}
              value={
                handlingUnitOptions
                  ? handlingUnitOptions?.find(
                      (handlingUnitOption: any) =>
                        handlingUnitOption.value ===
                          parseInt(
                            dimensionsFormData?.[currentDimensionStep]
                              ?.handlingUnit
                          ) || ''
                    )
                  : null
              }
              isLoading={isHandlingUnitLoading}
              required
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.handlingUnit &&
                dimensionsFormData?.[currentDimensionStep]?.handlingUnit ===
                  dimensionsExtractedData?.[currentDimensionStep]?.handlingUnit
              }
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="itemLength"
              label={`Length (${getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              isError={
                !validForm[currentDimensionStep]?.itemLength.required ||
                !validForm[currentDimensionStep]?.itemLength.valid
              }
              icon={
                !validForm[currentDimensionStep]?.itemLength.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={
                dimensionsFormData?.[currentDimensionStep]?.itemLength || ''
              }
              onChangeFunc={handleInputChange(currentDimensionStep)}
              onBlur={checkFreightClass(currentDimensionStep)}
              required
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.itemLength &&
                dimensionsFormData?.[currentDimensionStep]?.itemLength ===
                  dimensionsExtractedData?.[currentDimensionStep]?.itemLength
              }
              shouldFormatNumber={true}
              inputValueUnitType={getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="width"
              label={`Width (${getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              isError={
                !validForm[currentDimensionStep]?.width.required ||
                !validForm[currentDimensionStep]?.width.valid
              }
              icon={
                !validForm[currentDimensionStep]?.width.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={dimensionsFormData?.[currentDimensionStep]?.width || ''}
              onChangeFunc={handleInputChange(currentDimensionStep)}
              onBlur={checkFreightClass(currentDimensionStep)}
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.width &&
                dimensionsFormData?.[currentDimensionStep]?.width ===
                  dimensionsExtractedData?.[currentDimensionStep]?.width
              }
              shouldFormatNumber={true}
              inputValueUnitType={getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()}
              required={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="height"
              label={`Height (${getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              isError={
                !validForm[currentDimensionStep]?.height.required ||
                !validForm[currentDimensionStep]?.height.valid
              }
              icon={
                !validForm[currentDimensionStep]?.height.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={dimensionsFormData?.[currentDimensionStep]?.height || ''}
              onChangeFunc={handleInputChange(currentDimensionStep)}
              onBlur={checkFreightClass(currentDimensionStep)}
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.height &&
                dimensionsFormData?.[currentDimensionStep]?.height ===
                  dimensionsExtractedData?.[currentDimensionStep]?.height
              }
              shouldFormatNumber={true}
              inputValueUnitType={getLabelByValue(
                lengthMeasurement
              )?.toLowerCase()}
              required={true}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="weight"
              label={`Weight per unit (${getLabelByValue(
                weightMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              min="0"
              required={true}
              isError={
                !validForm[currentDimensionStep]?.weight.required ||
                !validForm[currentDimensionStep]?.weight.valid
              }
              icon={
                !validForm[currentDimensionStep]?.weight.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              value={dimensionsFormData?.[currentDimensionStep]?.weight || ''}
              onChangeFunc={handleInputChange(currentDimensionStep)}
              onBlur={checkFreightClass(currentDimensionStep)}
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.weight &&
                dimensionsFormData?.[currentDimensionStep]?.weight ===
                  dimensionsExtractedData?.[currentDimensionStep]?.weight
              }
              shouldFormatNumber={true}
              inputValueUnitType={getLabelByValue(
                weightMeasurement
              )?.toLowerCase()}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputType="number"
              inputName="totalWeight"
              label={`Total weight (${getLabelByValue(
                weightMeasurement
              )?.toLowerCase()})`}
              labelClassName="mb-1.5 block"
              value={dimensionsFormData?.[currentDimensionStep]?.totalWeight}
              isError={
                !validForm[currentDimensionStep]?.totalWeight.required ||
                !validForm[currentDimensionStep]?.totalWeight.valid
              }
              icon={
                !validForm[currentDimensionStep]?.totalWeight.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
              readOnly
              isShowAiStar={
                !!dimensionsFormData?.[currentDimensionStep]?.totalWeight &&
                dimensionsFormData?.[currentDimensionStep]?.totalWeight ===
                  dimensionsExtractedData?.[currentDimensionStep]?.totalWeight
              }
              shouldFormatNumber={true}
              inputValueUnitType={getLabelByValue(
                weightMeasurement
              )?.toLowerCase()}
            />

            <InputText
              parentClassName="w-1/2 p-1"
              inputName="freightClass"
              inputType="number"
              label={
                <div className="flex">
                  Freight Class
                  <span className={`text-red-600 ms-[2px] leading-4 text-xs `}>
                    *
                  </span>
                  {isFreightClassLoading[currentDimensionStep]?.isLoading && (
                    <p
                      className={`loading loading-spinner text-gray-600 h-4 w-4 ml-2 }`}
                    ></p>
                  )}
                </div>
              }
              requiredClassName="hidden"
              required={true}
              labelClassName="mb-1.5 block"
              min={0}
              value={
                validForm[currentDimensionStep]?.weight.required
                  ? dimensionsFormData?.[currentDimensionStep]?.freightClass
                  : ''
              }
              onChangeFunc={handleInputChange(currentDimensionStep)}
              isError={
                !validForm[currentDimensionStep]?.weight.required ||
                !validForm[currentDimensionStep]?.weight.valid
              }
              icon={
                !validForm[currentDimensionStep]?.weight.required ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                ) : null
              }
            />
          </div>

          <div className="flex items-center gap-4 mt-3 justify-between">
            <Toggle
              name="isStack"
              label="Do not stack"
              labelPosition="right"
              labelTextClassName="!text-xs"
              isChecked={dimensionsFormData?.[currentDimensionStep]?.isStack}
              onChange={handleToggle(currentDimensionStep)}
            />
            <div className="flex gap-1">
              <TooltipCmp message="Visualize Unit">
                <div
                  className="delete cursor-pointer p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary"
                  onClick={() => {
                    setCurrentItem({
                      ...{
                        index: currentDimensionStep,
                        data: dimensionsFormData?.[currentDimensionStep],
                      },
                    });
                  }}
                >
                  <Eye className="w-4 h-4" />
                </div>
              </TooltipCmp>

              <div
                className={`delete p-[5px] rounded-[4px] flex justify-center items-center text-primary bg-primary100 cursor-pointer `}
                onClick={() => {
                  removeItems(currentDimensionStep);
                }}
              >
                <Trash01 className="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
      )}

      {currentDimensionStep + 1 >= dimensionsFormData?.length && (
        <div className="flex gap-3 mt-2.5">
          <ButtonCmp
            type="submit"
            className="btn-outline-primary w-full"
            onClick={addItems}
            icon={<Plus className="w-4 h-4" />}
            disabled={isFreightClassLoading[currentDimensionStep]?.isLoading}
          >
            Add Line Item
          </ButtonCmp>
        </div>
      )}
      {isShowVisualUnit && currentItem && (
        <VisualizeUnit
          setDimensionsFormData={setDimensionsFormData}
          currentItem={currentItem}
          unitArr={handlingUnitOptions}
          handleClose={() => {
            handleClearValidation();
            setIsShowVisualUnit(false);
            setCurrentItem({ index: null, data: null });
          }}
          weightMeasurement={weightMeasurement}
          lengthMeasurement={lengthMeasurement}
        />
      )}
    </>
  );
};

export default DimensionItems;
