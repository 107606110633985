import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
}

export const getStartingChars = (str: string): string => {
  const trimmedStr = str.trim();

  const string = trimmedStr.slice(0, 15);

  if (trimmedStr.length > 15) {
    return `${string}...`;
  }

  return string;
};

const CustomerSplitAsideTableRow = ({ data }: IProps) => {
  const tdClass = data.id !== 0 ? 'px-5 py-4' : '';

  return (
    <>
      <td
        className={`${tdClass} xl:max-w-48 xl:w-48 xl:min-w-48 max-w-[1px] w-[35%] min-w-[35%]`}
      >
        {data.id !== 0 && (
          <div className=" flex items-center gap-2 truncate">
            {data?.image ? (
              <img
                src={`${data.imageUrl}${data.image}`}
                className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                alt={data.name}
                title={data.name}
                onError={onError}
              />
            ) : (
              <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                {getShortName(`${data.name}`)}
              </div>
            )}

            {data.name?.length > 15 ? (
              <TooltipCmp
                message={data.name}
                parentClassName="xs:max-w-50 max-w-40"
              >
                <span className="truncate block xl:max-w-28 text-xs whitespace-nowrap font-semibold ">
                  {getStartingChars(`${data.name}`)}
                </span>
              </TooltipCmp>
            ) : (
              <span className="truncate block xl:max-w-24  text-xs whitespace-nowrap font-semibold ">
                {getStartingChars(`${data.name}`)}
              </span>
            )}
          </div>
        )}
      </td>
      <td className={`${tdClass} pr-0`}>
        {data.id !== 0 && getFormattedNumber(data.orderCount)}{' '}
      </td>
      <td className={`${tdClass}`}>
        {data.id !== 0 && `$${getFormattedNumber(data.profit)}`}
      </td>
    </>
  );
};
export default CustomerSplitAsideTableRow;
