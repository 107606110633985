import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import CustomPagination from '../../../../components/CustomPagination';
import Drawer from '../../../../components/Drawer';
import InputText from '../../../../components/InputText/InputText';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import { PDF_LANGUAGE_OPTIONS } from '../../../../constants/common';
import { listPDF } from '../../../../services/QuoteService';

import PdfRow from './PdfRow';
import PdfTableLoadingRow from './PdfTableLoadingRow';

const initParams = {
  search: '',
  sortDirection: 'DESC',
  sortBy: 'createdAt',
  page: 1,
  limit: 10,
};

const PDF = ({ isOpen, id, handleClose, addressDetails }: any) => {
  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');
  const [totals, setTotals] = useState(0);
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectPdfLanguage, setSelectPdfLanguage] = useState<any>({
    value: 'english',
    label: 'English',
  });
  const [pdfLanguage, setPdfLanguage] = useState<any>('english');

  const [ignoreClose, setIgnoreClose] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setPdfs([]);
      listPDF(id, params)
        .then((response: any) => {
          setPdfs(response.data);
          setTotals(response.total);
        })
        .finally(() => setIsLoading(false))
        .catch(console.error);
    }
  }, [isOpen, params]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'search') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        sortable: false,
      },
      {
        id: 'language',
        name: 'Language',
        sortable: false,
      },
      {
        id: 'currency',
        name: 'Currency',
        sortable: false,
      },
      {
        id: 'createdAt',
        name: 'Creation Date',
        sortable: true,
      },
      {
        id: 'action',
      },
    ],
    []
  );

  return (
    <Drawer
      isOpen={isOpen}
      handleClose={handleClose}
      title="Invoicing History"
      classDrawer="mdm:min-w-[726px] sm:!w-[620px] !w-[316px] flex flex-col"
      ignoreClose={ignoreClose}
      setIgnoreClose={setIgnoreClose}
      drawerHeight="flex-grow"
      description={`View pricing details for #${addressDetails.code}`}
      badge={`${pdfs.length} ${pdfs.length > 1 ? 'Invoices' : 'Invoice'} `}
      badgeclassName={isLoading ? 'custom-loading' : ''}
    >
      <div className="table-wrap-main h-full ">
        <div className="table-header justify-end">
          <div className="flex gap-3 w-full">
            <div className="flex-1">
              <InputText
                inputName="search"
                placeholder="Search File"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                isClearable
                onChangeFunc={handleFilter}
              />
            </div>
          </div>
        </div>

        <div className="table-wrap">
          <TableCmp
            headCells={headCells}
            tableDataArr={pdfs}
            TableLoaderRowCmp={PdfTableLoadingRow}
            TableRowCmp={PdfRow}
            params={params}
            setParams={setParams}
            tableRowCmpProps={{
              pdfLanguage,
            }}
            isTableDataLoading={isLoading}
            numberOfSkeletonRows={params.limit}
            isTableRowClickable={false}
          />
          {pdfs.length <= 0 && !isLoading ? (
            <NotFoundUI
              title="No PDF Found"
              desc="There are no PDF for this quote"
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          ) : (
            ''
          )}
        </div>
        <CustomPagination
          recordsPerPage={params.limit}
          totalRecords={totals}
          currentPage={params.page}
          handlePagination={handlePagination}
        />
      </div>

      <div className="w-full hidden items-center gap-2">
        <div className={window.MODE !== 'production' ? 'w-3/4' : 'w-full'}>
          <div className="searchbox relative">
            <input
              name="search"
              type="text"
              className="w-full border-none bg-inputBg rounded-full px-5 py-3 text-grayDark sm:text-sm m-0 focus:outline-none focus:ring-0"
              placeholder="Search file"
              value={search}
              onChange={handleFilter}
            />
            <HiMiniMagnifyingGlass
              className="absolute top-3 right-3 text-grayText"
              size={20}
            />
          </div>
        </div>
        {window.MODE !== 'production' && (
          <div className="w-1/4">
            <SelectBox
              isSearchable={false}
              isClearable={false}
              name="role"
              placeholder="Select Language"
              className="form_control"
              classNamePrefix="lang"
              labelClassName="form_label"
              value={selectPdfLanguage}
              options={PDF_LANGUAGE_OPTIONS}
              onChangeFunc={(e: any) => {
                setIgnoreClose(true);
                setSelectPdfLanguage(e);
                setPdfLanguage(e?.value);
              }}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};
export default memo(PDF);
