import { ArrowLeft, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
import PageSectionLayout from 'src/components/PageSectionLayout';
import TabButton from 'src/components/TabButton';
import {
  CustomerGroupDetailById,
  removeCustomerGroup,
} from 'src/services/CustomerService';
import WalToast from 'src/utils/WalToast';

import defaultimage from '../../../assets/img/default-image.jpg';

import CreateUpdateCustomerGroupModal from './CreateUpdateCustomerGroupModal';
import CustomerGroupDetailAnalyticsTab from './CustomerGroupDetailAnalyticsTab';
import CustomerGroupDetailOverviewTab from './CustomerGroupDetailOverviewTab';
import CustomerGroupDetailRightSideSection from './CustomerGroupDetailRightSideSection';

const TabArray = [
  {
    value: 'overview',
    name: 'Overview',
  },
  // {
  //   value: 'analytics',
  //   name: 'Analytics',
  // },
];

const CustomerGroupDetail = () => {
  const navigate = useNavigate();
  const customerGroupId = window.location.pathname?.split('/').pop();
  const [isRefresh, setIsRefresh] = useState(true);
  const [isCustomerGroupDetailLoading, setIsCustomerGroupDetailLoading] =
    useState(true);
  const [customerGroupDetail, setCustomerGroupDetail] =
    useState<any>(undefined);
  const [isShowUpdateCustomerGroupModal, setIsShowUpdateCustomerGroupModal] =
    useState(false);

  const [
    isShowCustomerGroupDeleteConfirmation,
    setIsShowCustomerGroupDeleteConfirmation,
  ] = useState(false);
  const [isCustomerGroupDeleteLoading, setIsCustomerGroupDeleteLoading] =
    useState(false);

  const [activeTab, setActiveTab] = useState('overview');

  const getCustomerGroupById = () => {
    setIsCustomerGroupDetailLoading(true);
    setCustomerGroupDetail(undefined);
    CustomerGroupDetailById(customerGroupId)
      .then((response: any) => {
        setCustomerGroupDetail(response.data);
      })
      .finally(() => {
        setIsCustomerGroupDetailLoading(false);
        setIsRefresh(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (isRefresh) {
      getCustomerGroupById();
    }
  }, [isRefresh]);

  const handleDeleteCustomerGroup = useCallback(
    (status: boolean) => () => {
      if (status) {
        setIsCustomerGroupDeleteLoading(true);
        removeCustomerGroup(customerGroupDetail?.id)
          .then(() => {
            navigate('/customers');
          })
          .catch((e: any) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting customer group.'
            );
          })
          .finally(() => {
            setIsCustomerGroupDeleteLoading(false);
            setIsShowCustomerGroupDeleteConfirmation(false);
          });
      } else {
        setIsShowCustomerGroupDeleteConfirmation(false);
      }
    },
    [customerGroupDetail]
  );

  return (
    <>
      <PageSectionLayout
        header={<></>}
        contentClassName="!p-0"
        headerClassName="!p-0"
      >
        <div className="z-[0] relative bg-white h-full flex flex-col before:[''] xl:before:h-[130px] mdm:before:h-[100px] sm:before:h-[102px] before:h-[70px] before:w-full before:absolute before:top-0 before:bg-[url('../../assets/img/vector-square.svg')] before:bg-gray50 before:bg-no-repeat before:bg-contain before:-z-[1] before:left-0 before:bg-right">
          <div className="pt-5 pb-3 xlm:px-[30px] px-5 flex items-center justify-between gap-1.5">
            <div
              className="flex items-center cursor-pointer gap-1.5"
              onClick={() => {
                localStorage.setItem('customer-detail', 'visited');
                navigate('/customers', {
                  state: { from: location.pathname },
                });
              }}
            >
              <ArrowLeft className="h-4 w-4" />
              <span className="text-grayLight600 text-xs font-semibold">
                Back to Customer{' '}
              </span>
            </div>
            <div
              className="bg-utilityGray200 rounded-full xl:p-2 sm:p-1.5 p-1 cursor-pointer"
              onClick={() => {
                localStorage.setItem('customer-detail', 'visited');

                if (history.length > 3) {
                  navigate(-1);
                } else {
                  navigate('/customers');
                }
              }}
            >
              <XClose className="xl:w-5 xl:h-5 w-4 h-4" />
            </div>
          </div>
          <div className=" xlm:px-8 px-5 relative w-full xl:pt-[10px] sm:pt-2 pt-[26px] pb-[26px] flex max-sm:flex-col sms:gap-4 gap-2 sm:items-end justify-between">
            <div className="flex md:gap-4 gap-2 smd:items-end w-full">
              <label
                className={`relative uppercase [&>div:nth-child(2)]:opacity-0 hover:[&>div:nth-child(2)]:opacity-100 xlm:w-36 xl:w-36 mdm:w-28 sm:w-24 w-16 xlm:h-36 xl:h-36 mdm:h-28 sm:h-24 h-16 rounded-xl overflow-hidden justify-center items-center flex border-4 border-white bg-utilityGray100 shadow-lg text-primary xl:text-6xl sm:text-4xl sms:text-2xl text-base font-semibold tracking-[-1.2px] flex-none ${
                  isCustomerGroupDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {customerGroupDetail?.groupImage &&
                customerGroupDetail?.groupImageUrl ? (
                  <img
                    className="w-full h-full relative"
                    src={`${customerGroupDetail?.groupImageUrl}${customerGroupDetail?.groupImage}`}
                  />
                ) : (
                  <img className="w-full h-full relative" src={defaultimage} />
                )}
              </label>
              <div className="smd:flex-1 flex smd:flex-row flex-col justify-between sm:mt-2 gap-2 smd:items-end">
                <div className="">
                  <h6
                    className={`mr-3 text-grayLight900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] ${
                      isCustomerGroupDetailLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerGroupDetail?.groupName ?? '-'}
                  </h6>

                  <div
                    className={`flex mt-1 ${
                      isCustomerGroupDetailLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <span className="text-grayLight600 sm:text-sm text-xs sm:mt-0 mt-0.5 font-normal">
                      {customerGroupDetail?.customersCount} Members
                    </span>
                  </div>
                </div>

                <div className="flex gap-4 smd:pt-2">
                  <ButtonCmp
                    type="button"
                    className="btn-outline-primary"
                    onClick={() => setIsShowUpdateCustomerGroupModal(true)}
                    disabled={isCustomerGroupDetailLoading}
                  >
                    Edit Group
                  </ButtonCmp>
                  <ButtonCmp
                    type="submit"
                    className="btn_secondary_black"
                    onClick={() =>
                      setIsShowCustomerGroupDeleteConfirmation(true)
                    }
                    disabled={isCustomerGroupDetailLoading}
                  >
                    Delete Group
                  </ButtonCmp>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-utilityGray200 xlm:px-8 px-5 py-[30px] bg-white flex flex-wrap flex-1 items-start ">
            <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_312px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8 h-full">
              <TabButton
                parentClassName="!bg-gray50 w-full overflow-x-auto scrollbar-hide text-nowrap"
                className="rounded-md text-gray500 !border-0 w-[193px]"
                tabParentClassName="!block"
                activeClassName="!bg-primary400 !text-blue"
                tabArray={TabArray}
                isActive={activeTab}
                handleOnClick={(e: any) => {
                  setActiveTab(e.target.dataset.value);
                }}
                isTab={true}
              />
              {activeTab === 'overview' && (
                <CustomerGroupDetailOverviewTab
                  customerGroupDetail={customerGroupDetail}
                />
              )}

              {activeTab === 'analytics' && <CustomerGroupDetailAnalyticsTab />}
            </div>
            <div className="xl:w-[306px] xls:w-[280px]  w-full xl:ml-8 xls:ml-4 lg:ml-2 ml-0 flex flex-col gap-y-4 border-b border-utilityGray200">
              <CustomerGroupDetailRightSideSection
                customerGroupDetail={customerGroupDetail}
                isCustomerGroupDetailLoading={isCustomerGroupDetailLoading}
              />
            </div>
          </div>
        </div>

        {isShowUpdateCustomerGroupModal && (
          <CreateUpdateCustomerGroupModal
            isUpdateCustomerGroup={true}
            currentGroupDetail={customerGroupDetail}
            handleClose={(status: boolean) => {
              if (status) {
                setIsRefresh(true);
              }
              setIsShowUpdateCustomerGroupModal(false);
            }}
          />
        )}

        {isShowCustomerGroupDeleteConfirmation && (
          <DeleteModal
            moduleName="Customer Group"
            isDeleteLoading={isCustomerGroupDeleteLoading}
            handleClose={handleDeleteCustomerGroup}
          />
        )}
      </PageSectionLayout>
    </>
  );
};

export default CustomerGroupDetail;
