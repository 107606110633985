import { yupResolver } from '@hookform/resolvers/yup';
import { CurrencyDollar, FilePlus02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { modifyDisputeAmount } from 'src/services/DisputeService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import { handleInputChange } from '../ClaimsDisputes.const';

const modifyDisputeAmountSchema = yup.object().shape({
  disputeAmount: yup
    .number()
    .required('Dispute Amount is required.')
    .typeError('Dispute amount must be a valid number')
    .min(1, 'Dispute amount must be greater than zero')
    .max(999999, 'Dispute amount is not valid'),
});

interface IProps {
  handleClose: any;
  dispute: any;
  getDisputeDetails: any;
  setDispute?: any;
}

const ModifyDisputeAmount = ({
  handleClose,
  dispute,
  getDisputeDetails,
  setDispute,
}: IProps) => {
  const { reloadCounts } = useContext(EntityCountsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(modifyDisputeAmountSchema),
    defaultValues: {
      disputeAmount: dispute?.finalDisputeAmount
        ? dispute?.finalDisputeAmount
        : dispute?.disputeAmount,
    },
  });

  const onSubmit = (formData: any) => {
    setIsLoading(true);

    // if value unchanged then not doing api call
    // if (formData?.disputeAmount === dispute?.disputeAmount) {
    //   handleClose();

    //   return;
    // }

    const appendFormData: any = new FormData();
    appendFormData.append('disputeID', dispute?.disputeID);
    appendFormData.append('disputeAmount', formData?.disputeAmount);

    modifyDisputeAmount(appendFormData)
      .then(() => {
        getDisputeDetails();

        if (setDispute) {
          setDispute((prevDispute: any) => ({
            ...prevDispute,
            finalDisputeAmount: formData?.disputeAmount,
          }));
        }

        reloadCounts();
        handleClose();
      })
      .catch((e: any) => {
        WalToast.error(
          e?.response?.data?.message ?? 'Issue modifying dispute amount'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const headerIcon = <FilePlus02 className="w-7 h-7" />;

  return (
    <CommonModal
      title={`Modify Dispute Amount`}
      titleDesc={`View and manage your dispute details`}
      handleClose={handleClose}
      size={'max-w-[480px]'}
      headerIcon={headerIcon}
      primaryBtnText="Modify"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        <Controller
          name="disputeAmount"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputType="number"
              inputName="disputeAmount"
              label="Dispute Amount"
              parentClassName=""
              placeholder="Enter Dispute Explanation"
              className="pl-8"
              value={`${parseFloat(value)}`}
              onChangeFunc={(e) => handleInputChange(e, 'change', onChange)}
              onBlur={(e) => handleInputChange(e, 'blur', onChange)}
              labelClassName="block mb-1.5"
              errorText={errors?.disputeAmount?.message ?? ''}
              shouldFormatNumber={true}
              icon={
                <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                  <CurrencyDollar className="w-4 h-4" />
                </div>
              }
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default ModifyDisputeAmount;
