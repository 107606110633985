import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

export const updateAppearance = async (data: any) => {
  const response = await axiosInterceptor.post(API.APPEARANCE.CREATE, data);

  return response;
};

export const getAppearance = async () => {
  const response = await axiosInterceptor.get(API.APPEARANCE.GET);

  return response;
};

export const getSpamEmails = () => axiosInterceptor.get('/spam-emails');

export const removeSpamEmail = (id: number) =>
  axiosInterceptor.delete(`/spam-emails/${id}`);
