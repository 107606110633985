import { Calendar } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import DateTimePicker from 'src/components/DateTimePicker';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { DATE_FORMAT } from 'src/constants/common';
import {
  getLoadLinkVehicleAttribute,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import { getDateWithSuffixFormat } from 'src/utils/CommonFunctions';

enum AddressDetails {
  ORIGIN = 'Origin',
  DESTINATION = 'destination',
}

enum EquipmentDetails {
  EQUIPMENTTYPES = 'equipmentTypes',
  ADDITIONAL = 'additional',
}

const addressDetailArr = [
  {
    value: AddressDetails.ORIGIN,
    name: 'Origin',
  },
  {
    value: AddressDetails.DESTINATION,
    name: 'Destination',
  },
];

const equipmentDetailArr = [
  {
    value: EquipmentDetails.EQUIPMENTTYPES,
    name: 'Equipment Types',
  },
  {
    value: EquipmentDetails.ADDITIONAL,
    name: 'Additional Services',
  },
];

const radiusOptions = [
  {
    label: '50 Miles',
    value: 50,
  },
  {
    label: '100 Miles',
    value: 100,
  },
  {
    label: '150 Miles',
    value: 150,
  },
  {
    label: '200 Miles',
    value: 200,
  },
];

const TruckFind = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [addressDetail, setAddressDetail] = useState('Origin');
  const [sourceRadius, setSourceRadius] = useState<any>(null);
  const [destinationRadius, setDestinationRadius] = useState<any>(null);
  const [equipmentDetail, setEquipmentDetail] = useState<any>(
    EquipmentDetails.EQUIPMENTTYPES
  );
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('Primary');

  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(true);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);

  const [isVehicleAttrLoading, setIsVehicleAttrLoading] = useState(true);
  const [vehicleAttrOptions, setVehicleAttrOptions] = useState<any[]>([]);
  const [vehicleAttributeFullNames, setVehicleAttributeFullNames] = useState<
    any[]
  >([]);

  const [window, setWindow] = useState(0);
  const [pickupTime, setPickupTime] = useState('');

  const [primaryEquipmentType, setPrimaryEquipmentType] = useState('');
  const [primaryEquipmentTypeFullName, setPrimaryEquipmentTypeFullName] =
    useState('');

  const [secondaryEquipmentType, setSecondaryEquipmentType] = useState<any[]>(
    []
  );
  const [secondaryEquipmentTypeFullName, setSecondaryEquipmentTypeFullName] =
    useState<any[]>([]);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const handleSelectAddressDetails = (name: any) => (event: any) => {
    if (name === AddressDetails.ORIGIN) {
      setDestinationRadius(event.value);
    } else {
      setSourceRadius(event.value);
    }
  };

  const getVehicleTypeOptions = () => {
    setIsVehicleTypeLoading(true);
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
        // Set Primary Equipment Type Full name
        const pEquipTypeFNames: any = updatedListVehicleTypes.find(
          (type: any) => primaryEquipmentType == type.value
        );
        if (pEquipTypeFNames)
          setPrimaryEquipmentTypeFullName(pEquipTypeFNames.label);

        const secEquipTypeFNames: any = updatedListVehicleTypes
          .filter((type: any) => secondaryEquipmentType.includes(type.value))
          .map((type: any) => type.label);
        setSecondaryEquipmentTypeFullName(secEquipTypeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Type Error ', e);
      })
      .finally(() => {
        setIsVehicleTypeLoading(false);
      });
  };

  const getVehicleAttributeOptions = () => {
    setIsVehicleAttrLoading(true);

    getLoadLinkVehicleAttribute()
      .then((response: any) => {
        const updatedListVehicleAttribute = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleAttrOptions(updatedListVehicleAttribute);
        const vehicleAttr: any = '';
        const vehicleAttributeFNames = updatedListVehicleAttribute
          .filter((type: any) => vehicleAttr.includes(type.value))
          .map((type: any) => type.label);
        setVehicleAttributeFullNames(vehicleAttributeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Attr Error', e);
      })
      .finally(() => {
        setIsVehicleAttrLoading(false);
      });
  };

  const changeEquipmentType = (vehicleType: any) => (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      if (!primaryEquipmentType) {
        setPrimaryEquipmentType(vehicleType.value);
        setPrimaryEquipmentTypeFullName(vehicleType.label);
      } else {
        const secEquipmentTypes =
          secondaryEquipmentType.length > 0 ? [...secondaryEquipmentType] : [];
        let secEquipmentTypeFullName: any =
          secondaryEquipmentTypeFullName.length > 0
            ? [...secondaryEquipmentTypeFullName]
            : [];
        secEquipmentTypes.push(vehicleType.value);
        secEquipmentTypeFullName.push(vehicleType.label);
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    } else {
      if (primaryEquipmentType == vehicleType.value) {
        if (secondaryEquipmentType.length > 0) {
          setPrimaryEquipmentType(secondaryEquipmentType[0]);
          setPrimaryEquipmentTypeFullName(secondaryEquipmentTypeFullName[0]);
          setSecondaryEquipmentType(secondaryEquipmentType.slice(1));
          setSecondaryEquipmentTypeFullName(
            secondaryEquipmentTypeFullName.slice(1)
          );
        } else {
          setPrimaryEquipmentType('');
          setPrimaryEquipmentTypeFullName('');
        }
      } else {
        const secEquipmentTypes = secondaryEquipmentType.filter(
          (item) => item !== vehicleType.value
        );
        const secEquipmentTypeFullName = secondaryEquipmentTypeFullName.filter(
          (item) => item !== vehicleType.value
        );
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    }
  };

  useEffect(() => {
    getVehicleTypeOptions();
    getVehicleAttributeOptions();
  }, []);

  console.log('vehicleAttributeFullNames', vehicleAttributeFullNames);
  console.log('primaryEquipmentTypeFullName', primaryEquipmentTypeFullName);
  console.log('setSelectedEquipmentType', setSelectedEquipmentType);

  return (
    <div className="p-5 bg-white flex flex-wrap gap-y-4 rounded-lg border border-utilityGray200 shadow-sm">
      {/* first row */}
      <div className="w-full">
        <div className="bg-gray25 p-4 xl:pb-3 border border-utilityGray200 shadow-xs rounded-xl h-full">
          <h5 className="text-grayLight900 mb-4 text-sm font-medium w-full min-h-[30px] flex items-center">
            Full Truckload Details
          </h5>
          <div className="-mx-1 flex flex-wrap gap-y-3">
            <div className="xl:w-[37%] px-1 relative xls:w-1/2 w-full">
              <label className="form_label block mb-1.5">
                Availability Date
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>

              <DateTimePicker
                inputName="availabilityDate"
                className={`form_control`}
                placeholder={DATE_FORMAT}
                dateFormat="dd/MM/yyyy"
                selected={moment().toDate()}
                value={getDateWithSuffixFormat(moment().toNow())}
                errorText={''}
                onChangeFunc={() => {}}
                icon={<Calendar className="h-5 w-5" />}
                parentClassName="w-full"
                pickupTime={pickupTime}
                setPickupTime={setPickupTime}
                window={window}
                setWindow={setWindow}
                availabilityDateSubmit={() => {}}
                resetAvailabilityDate={() => {}}
              />

              {/* <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                {errors.availabilityDate && (
                  <ErrorMsg errorText={errors.availabilityDate.message} />
                )}
              </div> */}
            </div>
            <div className="xl:flex-1 xls:w-1/2 w-full px-1">
              <label className="form_label mb-1.5 block">
                Total Weight (lbs)
                <span className="text-red-600 leading-4">*</span>
              </label>
              <div className="form_control p-0 relative !h-auto">
                <InputText
                  inputName="totalWeight"
                  inputType="text"
                  labelClassName="mb-1.5 block"
                  className="!border-0 !h-auto"
                  value={undefined}
                  required={true}
                  // onFocus={() => {
                  //   setValue('totalWeight', value.replace(/,|lbs/g, '').trim());
                  // }}
                  // onChangeFunc={(e) => {
                  //   const val = e.target.value;

                  //   if (/^\d*$/.test(val)) {
                  //     onChange(val);
                  //   }
                  // }}
                  // onBlur={setTotalWeight}
                />
              </div>
              {/* <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                {errors.totalWeight && (
                  <ErrorMsg errorText={errors.totalWeight.message} />
                )}
              </div> */}
            </div>

            <InputText
              inputName="commodity"
              placeholder="Enter Commodity"
              label="Commodity"
              labelClassName="form_label block mb-1.5"
              parentClassName="xl:flex-1 w-1/2 px-1"
              id="commodity"
              className="form_control"
              value={undefined}
              onChangeFunc={() => {}}
              onBlur={() => {}}
              required={true}
            />

            <InputText
              inputName="linearFootage"
              placeholder="Enter Linear Footage"
              label="Linear Footage"
              labelClassName="form_label block mb-1.5"
              parentClassName="xl:flex-1 w-1/2 px-1"
              id="linearFootage"
              className="form_control"
              value={undefined}
              // onFocus={() => {
              //   setValue(
              //     'linearFootage',
              //     value ? value.replace(/,|feet/g, '').trim() : ''
              //   );
              // }}
              onChangeFunc={() => {}}
              // onBlur={setLinearFootage}
              required={true}
              // errorText={
              //   errors.linearFootage ? errors.linearFootage.message : null
              // }
            />
          </div>
        </div>
      </div>

      {/* second row */}
      <div className="w-full">
        <div className="bg-gray25 p-4 border border-utilityGray200 shadow-xs rounded-xl">
          <div className="flex justify-between items-center mb-4 gap-2 w-full">
            <h5 className="text-grayLight900 text-sm font-medium">
              Address Details
            </h5>
            <TabButton
              className="!min-h-[30px]"
              activeClassName="!bg-utilityGray100"
              tabArray={addressDetailArr}
              isActive={addressDetail}
              handleOnClick={(e: any) => {
                setAddressDetail(e?.target?.dataset?.value);
              }}
            />
          </div>

          <div className="flex flex-wrap -mx-1 xls:gap-y-8 gap-y-5">
            {addressDetail === AddressDetails.ORIGIN && (
              <>
                <InputText
                  inputName="sourceState"
                  label="State"
                  labelClassName="mb-1.5 block"
                  parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                  className="form_control"
                  value={undefined}
                  required={true}
                  onChangeFunc={() => {}}
                  onBlur={() => {}}
                  // errorText={
                  //   errors.sourceState ? errors.sourceState.message : null
                  // }
                />

                <InputText
                  inputName="sourceCity"
                  label="City"
                  labelClassName="mb-1.5 block"
                  parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                  className="form_control"
                  value={undefined}
                  required={true}
                  onChangeFunc={() => {}}
                  onBlur={() => {}}
                  // errorText={
                  //   errors.sourceCity ? errors.sourceCity.message : null
                  // }
                />

                <SelectBox
                  required
                  label="Radius"
                  className={`form_control`}
                  labelClassName="form_label mb-1.5 block"
                  options={radiusOptions}
                  value={radiusOptions.filter(
                    (val: any) => sourceRadius === val.value
                  )}
                  onChangeFunc={handleSelectAddressDetails(
                    AddressDetails.ORIGIN
                  )}
                  parentClassName="sm:flex-1 single-select-x-scroll px-1 "
                />
              </>
            )}

            {addressDetail === AddressDetails.DESTINATION && (
              <>
                <InputText
                  inputName="destinationState"
                  label="State"
                  labelClassName="mb-1.5 block"
                  parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                  className="form_control"
                  value={undefined}
                  required={true}
                  onChangeFunc={() => {}}
                  onBlur={() => {}}
                  // errorText={
                  //   errors.destinationState
                  //     ? errors.destinationState.message
                  //     : null
                  // }
                />

                <InputText
                  inputName="destinationCity"
                  label="City"
                  labelClassName="mb-1.5 block"
                  parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                  className="form_control"
                  value={undefined}
                  required={true}
                  onChangeFunc={() => {}}
                  onBlur={() => {}}
                  // errorText={
                  //   errors.destinationCity
                  //     ? errors.destinationCity.message
                  //     : null
                  // }
                />

                <SelectBox
                  required
                  label="Radius"
                  className={`form_control`}
                  labelClassName="form_label mb-1.5 block"
                  options={radiusOptions}
                  value={radiusOptions.filter(
                    (val: any) => destinationRadius == val.value
                  )}
                  onChangeFunc={handleSelectAddressDetails(
                    AddressDetails.DESTINATION
                  )}
                  parentClassName="flex-1 single-select-x-scroll px-1"
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* third row */}
      <div
        className={`rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full`}
      >
        <div className="flex sm:flex-nowrap flex-wrap justify-between items-center gap-2 w-full pb-4 border-b border-utilityGray200">
          <h5 className="text-grayLight900 text-sm font-medium">
            {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES
              ? `Select a ${selectedEquipmentType} Equipment Type`
              : `Additional Services`}
          </h5>
          <TabButton
            className="!min-h-[30px]"
            activeClassName="!bg-utilityGray100"
            tabArray={equipmentDetailArr}
            isActive={equipmentDetail}
            handleOnClick={(e: any) => {
              setEquipmentDetail(e?.target?.dataset?.value);
            }}
          />
        </div>
        {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES && (
          <>
            <div
              className={`flex flex-wrap pt-4 gap-y-3 ${
                isVehicleTypeLoading ? 'custom-loading' : ''
              }`}
            >
              {vehicleTypeOptions.map((vehicleType: any, index: number) => (
                <div
                  className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                  key={`equipment_type_${index}`}
                >
                  <CheckBox
                    id={`vehicle_type_${vehicleType.value}`}
                    name="vehicleType"
                    checked={
                      primaryEquipmentType == vehicleType.value ||
                      secondaryEquipmentType.includes(vehicleType.value)
                    }
                    parentClassName={`!w-3.5 !h-3.5`}
                    value={vehicleType.value}
                    onChangeFunc={changeEquipmentType(vehicleType)}
                  />
                  <label
                    htmlFor={`vehicle_type_${vehicleType.value}`}
                    className={`text-textSecondary text-xs font-medium cursor-pointer`}
                  >
                    {vehicleType.label}&nbsp;
                    <span className="text-gray500 text-xs font-normal">
                      {primaryEquipmentType == vehicleType.value
                        ? '(Primary)'
                        : secondaryEquipmentType.includes(vehicleType.value)
                        ? '(Secondary)'
                        : ''}
                    </span>
                  </label>
                </div>
              ))}
            </div>
            {/* <div className="[&:empty]:hidden [&:empty]:mb-0 mt-5">
              {primaryEquipmentTypeError && (
                <ErrorMsg errorText="Primary equipment type is required." />
              )}
            </div> */}
          </>
        )}
        {equipmentDetail === EquipmentDetails.ADDITIONAL && (
          <div
            className={`flex flex-wrap pt-4 gap-y-3 ${
              isVehicleAttrLoading ? 'custom-loading' : ''
            }`}
          >
            {vehicleAttrOptions.map((vehicleAttribute: any, index: number) => (
              <div
                className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2 "
                key={index}
              >
                <CheckBox
                  id={vehicleAttribute.value}
                  name="vehicleAttribute"
                  value={vehicleAttribute.value}
                  checked={false}
                  onChangeFunc={() => {}}
                  // onChangeFunc={(event: any) => {
                  //   const checked = event.target.checked;
                  //   let vehicleAttributes = value ? [...value] : [];
                  //   let vehicleAttrFullNames = vehicleAttributeFullNames
                  //     ? [...vehicleAttributeFullNames]
                  //     : [];

                  //   if (checked) {
                  //     vehicleAttributes.push(vehicleAttribute.value);
                  //     vehicleAttrFullNames.push(vehicleAttribute.label);
                  //   } else {
                  //     vehicleAttributes = vehicleAttributes.filter(
                  //       (item) => item !== vehicleAttribute.value
                  //     );
                  //     vehicleAttrFullNames = vehicleAttributeFullNames.filter(
                  //       (item) => item !== vehicleAttribute.value
                  //     );
                  //   }
                  //   onChange(vehicleAttributes);
                  //   setVehicleAttributeFullNames(vehicleAttrFullNames);
                  // }}
                />
                <label
                  htmlFor={vehicleAttribute.value}
                  className="text-textSecondary text-xs font-medium  cursor-pointer "
                >
                  {vehicleAttribute.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* fourth row */}
      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full flex flex-col gap-3">
        <h5 className="text-grayLight900 text-sm font-medium w-full">
          Special Instructions
        </h5>

        <InputText
          inputName="specialInstructions"
          placeholder={'Add your special instructions'}
          asTextarea="textarea"
          parentClassName=""
          className="h-[76px]"
          value={undefined}
          onChangeFunc={() => {}}
        />
      </div>

      <div className="text-end w-full">
        <ButtonCmp className="btn-outline-primary" onClick={() => {}}>
          Post to Load Board
        </ButtonCmp>
      </div>
    </div>
  );
};

export default TruckFind;
