import { MarkerPin04 } from '@untitled-ui/icons-react/build/cjs';
import React, { useMemo, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import TableCmp from 'src/components/TableCmp';

import SplitLegDetailsRow from './SplitLegDetailsRow';

interface IProps {
  handleSubmit: any;
  handleClose: any;
  setSplitLegData: any;
  splitLegData: any;
  tabValues: any;
  setLegFormData: any;
}

const SplitLegModal = ({
  handleSubmit,
  handleClose,
  setSplitLegData,
  splitLegData,
  tabValues,
  setLegFormData,
}: IProps) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const splitLegHeadCells = useMemo(
    () => [
      {
        id: 'leg',
        name: 'Leg',
      },
      {
        id: 'mileage',
        name: 'Mileage',
      },
      {
        id: 'origin',
        name: 'Origin',
      },
      {
        id: 'destination',
        name: 'Destination',
      },
      {
        id: '',
        name: '',
      },
    ],
    []
  );

  const legItem = Object.values(tabValues);

  const handleCloseModal = () => {
    setSplitLegData(null);
    handleClose();
  };

  const handleSplitLegClick = (data: any) => {
    setErrorMsg(null);
    setLegFormData((old: any) => ({
      ...old,
      parentLegId: data?.id,
      parentLegPosition: data?.legPosition,
    }));

    setSplitLegData(data);
  };

  const validateAndSubmit = () => {
    if (!splitLegData) {
      setErrorMsg('Please select a leg to split.');

      return;
    }

    handleSubmit();
  };

  return (
    <>
      <CommonModal
        title="Split a Leg"
        titleDesc="Select which leg you want to split"
        handleClose={handleClose}
        headerIcon={<MarkerPin04 />}
        size={'max-w-[740px]'}
        primaryBtnText="Add Stop"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleCloseModal}
        primaryBtnOnClick={validateAndSubmit}
        // primaryBtnDisabled={!isValid}
      >
        <div className="p-5 flex flex-col gap-y-3">
          <h6 className="text-textSecondary text-sm font-semibold">
            Which leg would you like to split?
          </h6>
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={splitLegHeadCells}
                tableDataArr={legItem}
                TableRowCmp={SplitLegDetailsRow}
                isTableRowClickable
                onRowClick={handleSplitLegClick}
                tableRowCmpProps={{ splitLegData }}
                numberOfSkeletonRows={1}
                tableBodyRowClass={'group'}
              />
            </div>
          </div>
          {errorMsg && <ErrorMsg errorText={errorMsg} />}
        </div>
      </CommonModal>
    </>
  );
};
export default SplitLegModal;
