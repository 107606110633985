import {
  ArrowDown,
  Mail02,
  // ChevronDown,
  // ArrowRight,
  // Minimize02,
  Phone,
  Route,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getLaneHistory, getTopCarriers } from 'src/services/GeographyService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

import avatar from '../../../assets/img/Avatar1.png';
import star from '../../../assets/img/Star.svg';

import CityListLoading from './CityListLoading';

const filterArr = [
  {
    value: 'lane_history',
    name: 'Lane History',
  },
  {
    value: 'top_carriers',
    name: 'Top Carriers',
  },
  // {
  //   value: 'key_cities',
  //   name: 'Key Cities',
  // },
];

const radiusArr = [
  {
    value: 50,
    label: getFormattedNumber(50),
  },
  {
    value: 100,
    label: getFormattedNumber(100),
  },
  {
    value: 500,
    label: getFormattedNumber(500),
  },

  {
    value: 1000,
    label: getFormattedNumber(1000),
  },
  {
    value: 2500,
    label: getFormattedNumber(2500),
  },
  {
    value: 5000,
    label: getFormattedNumber(5000),
  },
  {
    value: 10000,
    label: getFormattedNumber(10000),
  },
];

const populationArr = [
  {
    value: 100,
    label: getFormattedNumber(100),
  },
  {
    value: 500,
    label: getFormattedNumber(500),
  },
  {
    value: 5000,
    label: getFormattedNumber(5000),
  },
  {
    value: 10000,
    label: getFormattedNumber(10000),
  },
  {
    value: 25000,
    label: getFormattedNumber(25000),
  },

  {
    value: 50000,
    label: getFormattedNumber(50000),
  },
  {
    value: 100000,
    label: getFormattedNumber(100000),
  },
];

const LaneOverview = ({
  routeFilters,
  setRouteFilters,
  handleClose,
  searchResult,
  predictivePricingDetail,
}: any) => {
  const { currency } = useContext(BasicContext);
  const [activeTab, setActiveTab] = useState('lane_history');
  const [isTopCarrierLoading, setIsTopCarrierLoading] = useState(false);
  const [topCarrierList, setTopCarrierList] = useState<any>();
  const [laneHistoryList, setLaneHistoryList] = useState<any>([]);
  const [isLaneHistoryLoading, setIsLaneHistoryLoading] = useState(false);
  useEffect(() => {
    // console.log('searchResult', searchResult);
  }, [searchResult]);

  const getTopCarrierList = () => {
    setIsTopCarrierLoading(true);
    setTopCarrierList([]);

    getTopCarriers()
      .then((response: any) => {
        setTopCarrierList(response?.data);
        setIsTopCarrierLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsTopCarrierLoading(false);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
      });
  };

  const getLaneHistoryList = () => {
    let payload = {
      sourceCity: searchResult?.fromAddressObj?.city,
      destinationCity: searchResult?.toAddressObj?.city,
      sourceState: searchResult?.fromAddressObj?.state,
      destinationState: searchResult?.toAddressObj?.state,
      sourcePostal: searchResult?.fromAddressObj?.postal,
      destinationPostal: searchResult?.toAddressObj?.postal,
    };
    setIsLaneHistoryLoading(true);
    setTopCarrierList([]);

    getLaneHistory({ ...payload })
      .then((response: any) => {
        setLaneHistoryList(response?.data);
        setIsLaneHistoryLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLaneHistoryLoading(false);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
      });
  };

  useEffect(() => {
    getTopCarrierList();
    getLaneHistoryList();
  }, []);

  return (
    <div className="fixed bottom-10 right-10 w-[450px] h-[calc(100vh_-_220px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 ">
        <div className="flex items-start gap-2">
          <div className="bg-utilityGray100 rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
            <Route className="w-4 h-4" />
          </div>
          <div className="flex-1">
            <h6 className="text-grayLight900 text-base font-semibold">
              Lane Overview
            </h6>
            <p className="text-gray500 text-xs font-normal">
              Here is an overview of your lane details.
            </p>
          </div>
          <ButtonCmp className="!p-1.5 btn_secondary_black">
            <ArrowDown
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={handleClose}
            />
          </ButtonCmp>
        </div>
        {/* <div className="flex items-center mt-6">
          <div className="flex-1">
            <p className="text-gray500 text-xs font-normal">From</p>
            <p
              className={`text-textSecondary text-xs font-medium ${
                !searchResult.isBasicDetail ? 'custom-loading' : ''
              }`}
            >
              {!searchResult.isBasicDetail
                ? '2995 Atlas Rd, Richmond, CA 94806, USA'
                : searchResult.from}
            </p>
          </div>
          <ButtonCmp className="lg:!px-[9px] !px-2 btn_secondary_black mx-3">
            <ArrowRight className="w-4 h-4" />
          </ButtonCmp>
          <div className="pl-3 flex-1">
            <p className="text-gray500 text-xs font-normal">To</p>
            <p
              className={`text-textSecondary text-xs font-medium ${
                !searchResult.isBasicDetail ? 'custom-loading' : ''
              }`}
            >
              {!searchResult.isBasicDetail
                ? '2995 Atlas Rd, Richmond, CA 94806, USA'
                : searchResult.to}
            </p>
          </div>
        </div> */}
      </div>
      <div className="my-4 [&>*]:px-4  flex flex-col gap-y-3 flex-1 overflow-hidden">
        <div>
          <ul className="flex flex-wrap gap-y-3 mb-3 -mx-1.5">
            <li className="w-1/2 px-1.5">
              <div className="px-3 py-2 rounded-lg border border-utilityGray200">
                <h6 className="text-gray500 text-xs font-normal">Distance</h6>
                <p
                  className={`text-primary text-xs font-semibold mt-1 ${
                    !searchResult?.isBasicDetail ? 'custom-loading' : ''
                  }`}
                >
                  {!searchResult?.isBasicDetail ? 1000 : searchResult.distance}{' '}
                  miles
                </p>
              </div>
            </li>

            <li className="w-1/2 px-1.5">
              <div className="px-3 py-2 rounded-lg border border-utilityGray200">
                <h6 className="text-gray500 text-xs font-normal">Duration</h6>
                <p
                  className={`text-primary text-xs font-semibold mt-1 ${
                    !searchResult?.isBasicDetail ? 'custom-loading' : ''
                  }`}
                >
                  {!searchResult?.isBasicDetail
                    ? '1 hour 20 min'
                    : searchResult.duration}
                </p>
              </div>
            </li>
            <li className="w-1/2 px-1.5">
              <div className="px-3 py-2 rounded-lg border border-utilityGray200">
                <h6 className="text-gray500 text-xs font-normal">
                  Target Cost
                </h6>
                <p
                  className={`text-primary text-xs font-semibold mt-1 ${
                    !searchResult?.isBasicDetail ? 'custom-loading' : ''
                  }`}
                >
                  {predictivePricingDetail?.offerAmount
                    ? `$${predictivePricingDetail?.offerAmount}`
                    : 'N/A'}
                </p>
              </div>
            </li>

            <li className="w-1/2 px-1.5">
              <div className="px-3 py-2 rounded-lg border border-utilityGray200">
                <h6 className="text-gray500 text-xs font-normal">CPM</h6>
                <p
                  className={`text-primary text-xs font-semibold mt-1 ${
                    !searchResult?.isBasicDetail ? 'custom-loading' : ''
                  }`}
                >
                  {predictivePricingDetail?.offerAmount
                    ? `$${(
                        predictivePricingDetail?.offerAmount /
                        searchResult.distance
                      ).toFixed(2)}`
                    : 'N/A'}
                  {predictivePricingDetail?.offerAmount > 0 ? (
                    <TooltipCmp
                      message={`${searchResult?.toAddressObj?.city} to ${searchResult?.fromAddressObj?.city}`}
                    >
                      <span className="text-gray500 text-[11px]">
                        (
                        {predictivePricingDetail?.reverseRouteData
                          ?.offerAmount > 0
                          ? `$${predictivePricingDetail?.reverseRouteData?.offerAmount}`
                          : 'N/A'}
                        )
                      </span>
                    </TooltipCmp>
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </li>
          </ul>
        </div>

        <TabButton
          parentClassName="!bg-gray50 !w-full "
          className={`!rounded-md !border-0 [&>span]:text-gray400 w-1/2`}
          activeClassName=" !bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md "
          tabArray={filterArr}
          tabParentClassName=""
          handleOnClick={(e: any) => {
            setActiveTab(e?.target?.dataset?.value);
          }}
          isActive={activeTab}
          isTab={true}
        />
        {activeTab === 'lane_history' && (
          <ul className="flex flex-col gap-3 flex-1 overflow-y-auto  custom-scrollbar-v2">
            {isLaneHistoryLoading && laneHistoryList?.length === 0 ? (
              <li className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg">
                <div className="flex justify-between items-center mb-3 gap-x-2">
                  <div className="flex gap-2">
                    <div className="w-6 h-6 rounded border border-gray-200 custom-loading"></div>
                    <p className="text-grayLight900 text-sm font-medium mt-0.5 custom-loading">
                      XPO Logistics Freight, Inc.
                    </p>
                    <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5  flex-none self-start custom-loading">
                      <p className="text-gray500 text-xs font-normal mt-[1px] ">
                        4.5
                      </p>
                      <img src={star} />
                    </div>
                  </div>
                  <h6 className="text-primary text-xs font-medium underline flex-none mt-0.5 self-start custom-loading">
                    #WAL-DMIF-1
                  </h6>
                  {/* <Share03 className="w-4 h-4 text-primary cursor-pointer" /> */}
                </div>

                <div className="flex flex-col gap-y-1 text-xs text-grayLight600 font-normal">
                  <div className="flex justify-between gap-x-2">
                    <h6>Total Weight:&nbsp;</h6>
                    <span className="font-medium text-grayLight900 custom-loading">
                      20lbs
                    </span>
                  </div>
                  <div className="flex justify-between gap-x-2">
                    <h6>Margin:&nbsp;</h6>
                    <span className="font-medium text-grayLight900 custom-loading">
                      $56
                    </span>
                  </div>
                  <div className="flex justify-between gap-x-2">
                    <h6>Total Cost:&nbsp;</h6>
                    <span className="font-medium text-grayLight900 custom-loading">
                      $752
                    </span>
                  </div>
                  <div className="flex justify-between gap-x-2">
                    <h6>Total Price:&nbsp;</h6>
                    <span className="font-medium text-grayLight900 custom-loading">
                      $905.80
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              laneHistoryList.map((value: any, index: any) => (
                <li
                  key={index}
                  className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg"
                >
                  <div className="flex justify-between items-center mb-3 gap-x-2">
                    <div className="flex gap-2">
                      <img
                        src={avatar}
                        alt="Avatar"
                        className="w-6 h-6 rounded border border-gray-200"
                      />
                      <p className="text-grayLight900 text-sm font-medium mt-0.5">
                        {value?.carrierName}
                      </p>
                      <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5  flex-none self-start">
                        <p className="text-gray500 text-xs font-normal mt-[1px]">
                          4.5
                        </p>
                        <img src={star} />
                      </div>
                    </div>
                    <h6 className="text-primary text-xs font-medium underline flex-none mt-0.5 self-start">
                      #{value?.orderId}
                    </h6>
                    {/* <Share03 className="w-4 h-4 text-primary cursor-pointer" /> */}
                  </div>

                  <div className="flex flex-col gap-y-1 text-xs text-grayLight600 font-normal">
                    <div className="flex justify-between gap-x-2">
                      <h6>Total Weight:&nbsp;</h6>
                      <span className="font-medium text-grayLight900">
                        {value?.totalWeight} lbs
                      </span>
                    </div>
                    <div className="flex justify-between gap-x-2">
                      <h6>Margin:&nbsp;</h6>
                      {/* <span className="font-medium text-grayLight900">
                        ${value?.totalWeight}
                      </span> */}
                      <span className="font-medium text-grayLight900">
                        {value.combinedMargin
                          ? getFormattedNumber(
                              currency === CURRENCY.CAD
                                ? value.combinedMargin
                                : value.combinedMarginUSD,
                              true,
                              true
                            )
                          : '$0.00'}
                      </span>
                    </div>
                    <div className="flex justify-between gap-x-2">
                      <h6>Total Cost:&nbsp;</h6>
                      <span className="font-medium text-grayLight900">
                        {value.combinedCost
                          ? getFormattedNumber(
                              currency === CURRENCY.CAD
                                ? value.combinedCost
                                : value.combinedCostUSD,
                              true,
                              true
                            )
                          : '$0.00'}
                      </span>
                    </div>
                    <div className="flex justify-between gap-x-2">
                      <h6>Total Price:&nbsp;</h6>
                      <span className="font-medium text-grayLight900">
                        {value.combinedRevenue
                          ? getFormattedNumber(
                              currency === CURRENCY.CAD
                                ? value.combinedRevenue
                                : value.combinedRevenueUSD,
                              true,
                              true
                            )
                          : '$0.00'}
                      </span>
                    </div>
                  </div>
                </li>
              ))
            )}
            {!isLaneHistoryLoading && laneHistoryList?.length === 0 ? (
              <NotFoundUI
                title="Not Found Lane history"
                desc="There are no data found for lane history."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            ) : (
              ''
            )}
          </ul>
        )}
        {activeTab === 'top_carriers' && (
          <ul className="flex flex-col gap-3 flex-1 overflow-y-auto  custom-scrollbar-v2">
            {isTopCarrierLoading && topCarrierList.length === 0 ? (
              <li className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg">
                <div className="flex justify-between items-center mb-3 gap-x-2">
                  <div className="flex gap-2">
                    <span className="w-6 h-6 rounded border border-gray-200 custom-loading" />
                    <p className="text-grayLight900 text-sm font-medium mt-0.5 custom-loading">
                      XPO Logistics Freight, Inc.
                    </p>
                    <div className="flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5 flex-none self-start">
                      <p
                        className={`text-gray500 text-xs font-normal mt-[1px] ${
                          isTopCarrierLoading ? 'custom-loading' : ''
                        }`}
                      >
                        4.5
                      </p>
                      <img src={star} alt="Star" />
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-0.5 flex-none">
                    <Phone className="w-3 h-3 text-gray500" />
                    <a
                      href="tel:18004092269"
                      className={`text-gray500 text-xs font-normal ${
                        isTopCarrierLoading ? 'custom-loading' : ''
                      }`}
                    >
                      1-800-409-2269
                    </a>
                  </div>
                </div>
                <div className="flex flex-col gap-y-1 text-xs text-grayLight600 font-normal">
                  <div className="flex justify-between gap-x-2">
                    <h6>Total Loads:&nbsp;</h6>
                    <span
                      className={`font-medium text-grayLight900 ${
                        isTopCarrierLoading ? 'custom-loading' : ''
                      }`}
                    >
                      768
                    </span>
                  </div>
                  <div className="flex justify-between gap-x-2s">
                    <h6>Average Price:&nbsp;</h6>
                    <span className="font-medium text-grayLight900 custom-loading">
                      $542
                    </span>
                  </div>
                  <div className="flex justify-between gap-x-2 ">
                    <h6>Last Order:&nbsp;</h6>
                    <span className="font-medium text-grayLight900 custom-loading">
                      #WAL-DMIF-1
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              topCarrierList.map((value: any, index: number) => (
                <li
                  key={index}
                  className="rounded-md border border-utilityGray200 px-2 py-3 shadow-none transition-all duration-[0.3s] hover:shadow-lg"
                >
                  <div className="flex items-center mb-3 gap-x-2">
                    {value.image ? (
                      <img
                        src={`${value.imageUrl}${value.image}`}
                        className="table-profile-icn-circle-xl rounded-md"
                        alt={value.name}
                        title={value.name}
                        onError={onError}
                      />
                    ) : (
                      <div className="table-profile-customer-circle-xl rounded-md">
                        {getShortName(`${value.name}`)}
                      </div>
                    )}
                    <div className="flex items-center gap-2 flex-1">
                      <p className="text-grayLight900 text-sm font-medium mt-0.5 line-clamp-1">
                        {value.name}
                      </p>
                      <div
                        className={`flex items-center gap-x-0.5 rounded-md border border-utilityGray200 px-1 py-0.5 flex-none ${
                          isTopCarrierLoading ? 'custom-loading' : ''
                        }`}
                      >
                        <p
                          className={`text-gray500 text-xs font-normal mt-[1px] `}
                        >
                          4.5
                        </p>
                        <img src={star} alt="Star" />
                      </div>
                    </div>

                    {value.phone ? (
                      <div className="flex gap-x-1.5">
                        <div className="flex items-center gap-1 flex-none">
                          <Phone className="w-4 h-4 text-gray500" />
                          <a
                            href={`tel:${value.phone}`}
                            className={`text-gray500 text-xs font-normal ${
                              isTopCarrierLoading ? 'custom-loading' : ''
                            }`}
                          >
                            {value.phone}
                          </a>
                        </div>

                        <Mail02 className="w-4 h-4 text-gray500" />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="flex flex-col gap-y-1 text-xs text-grayLight600 font-normal">
                    <div className="flex justify-between gap-x-2">
                      <h6>Total Loads:&nbsp;</h6>
                      <span
                        className={`font-medium text-grayLight900 ${
                          isTopCarrierLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {value.totalLoads}
                      </span>
                    </div>
                    <div className="flex justify-between gap-x-2">
                      <h6>Average Price:&nbsp;</h6>
                      <span className="font-medium text-grayLight900">
                        $ {value?.avgPrice?.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="flex justify-between gap-x-2">
                      <h6>Last Order:&nbsp;</h6>
                      <span className="font-medium text-grayLight900">
                        <p
                          className={`${
                            isTopCarrierLoading ? 'custom-loading' : ''
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: `${
                              value?.createdAt
                                ? getDateWithSuffixFormat(
                                    getFormattedDate(
                                      value?.lastOrderData?.createdAt,
                                      'MMM Do ,YYYY',
                                      true,
                                      true
                                    )
                                  )
                                : '-'
                            }`,
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </li>
              ))
            )}
            {!isTopCarrierLoading && topCarrierList?.length === 0 ? (
              <NotFoundUI
                title="Not Found top carriers"
                desc="There are no data found for top carriers."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            ) : (
              ''
            )}
          </ul>
        )}

        {activeTab === 'key_cities' && (
          <>
            <div className="flex flex-wrap -mx-1 mb-5">
              <SelectBox
                label="Search Radius"
                labelClassName="form_label block mb-1.5"
                parentClassName="sm:w-1/2 w-full px-1"
                name="radius"
                size="sm"
                options={radiusArr}
                onChangeFunc={(event: any) => {
                  setRouteFilters((old: any) => ({
                    ...old,
                    radius: event?.value ?? '',
                  }));
                }}
                isSearchable={false}
                value={radiusArr.find(
                  (val: any) => val.value === routeFilters.radius
                )}
              />
              <SelectBox
                label="Population Size"
                labelClassName="form_label block mb-1.5"
                parentClassName="sm:w-1/2 w-full px-1"
                name="population"
                className=""
                size="sm"
                options={populationArr}
                onChangeFunc={(event: any) => {
                  setRouteFilters((old: any) => ({
                    ...old,
                    population: event?.value ?? '',
                  }));
                }}
                isSearchable={false}
                value={populationArr.find(
                  (val: any) => val.value === routeFilters.population
                )}
              />
            </div>
            {searchResult?.status === 'loading' ? (
              <CityListLoading />
            ) : searchResult?.status === 'error' ? (
              <p className="text-red-500 p-3 text-sm">{searchResult.error}</p>
            ) : searchResult?.cities?.length > 0 ? (
              <>
                <ul>
                  {searchResult.cities.map((city: any, index: number) => (
                    <li
                      key={index}
                      className="relative flex items-start gap-x-2 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden [&:last-child>div:after]:hidden"
                    >
                      <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[26px] after:absolute flex items-center justify-center after:bg-utilityGray200">
                        <div className="rounded-full shadow-xs px-1 py-0.5 border border-utilityGray200 text-grayLight900 text-xs min-w-[22px] text-center">
                          {++index}
                        </div>
                      </div>
                      <div className="flex-1">
                        <h6 className="text-primary text-xs leading-[1.5] font-semibold">
                          {city.fullName}
                        </h6>
                        <p className="text-grayLight600 text-xs leading-[1.5] font-normal">
                          {city.population !== undefined
                            ? ` Population: ${city.population.toLocaleString()}`
                            : ' Population: Unknown'}
                        </p>
                      </div>

                      <p className="text-right text-grayLight900 text-xs leading-[1.5] font-medium">
                        {Math.round(city.distance)}km
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <p className="p-3 text-sm">
                No cities found within the specified radius.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LaneOverview;
