import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import { useRolePermission } from 'src/utils/CommonFunctions';

const CustomerLoaderRow = () => {
  const { hasRoleV2 } = useRolePermission();

  return (
    <>
      <td className="px-5 py-4 min-w-56 max-w-56 w-56">
        <div className="flex items-center gap-3">
          <div className="table-profile-customer-circle-xl custom-loading">
            J D
          </div>

          <span className="truncate block xlm:max-w-52 max-w-44 text-grayLight900 font-semibold leading-5 custom-loading">
            Culpa consectetur do laborum
          </span>
        </div>
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <BadgeCmp
          style="modern"
          badgeDotColor="bg-utilityPink600"
          mainClassName="w-min-[100px] custom-loading"
        >
          prospect
        </BadgeCmp>
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <div className="flex items-center min-w-92">
          <div className="table-profile-user-circle-sm custom-loading">J D</div>
          <div className="pl-2">
            <h6 className="text-grayLight900 font-medium leading-tight max-w-32 truncate custom-loading">
              Anim laborum{' '}
            </h6>
          </div>
        </div>
      </td>
      <td className="px-5 py-3 min-w-44 max-w-44 w-44">
        <p className="hover:text-primary700 font-medium custom-loading w-fit">
          House Team House Team
        </p>
      </td>
      <td className="px-5 py-3 font-normal min-w-44 max-w-44 w-44">
        <span className="custom-loading">MMM DD, YYYY</span>
      </td>
      <td className="px-5 py-3 font-normal min-w-44 max-w-44 w-44">
        <span className="custom-loading">MMM DD, YYYY</span>
      </td>
      {hasRoleV2('admin') && (
        <td className="px-5 py-4 min-w-16 max-w-16">
          <CheckBox
            parentClassName="w-3.5 h-3.5 custom-loading"
            onChangeFunc={() => {}}
          />
        </td>
      )}
    </>
  );
};

export default CustomerLoaderRow;
