import { ArrowRight, Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { PATH } from 'src/constants/path';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

import TagsModal from './TagsModal';

interface IProps {
  order: any;
  isLoading: boolean;
  currentLegData: any;
  tagList: any;
}

const OrderInfoSection = ({
  order,
  isLoading,
  currentLegData,
  tagList,
}: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [orderTags, setOrderTags] = useState([]);

  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (linkRef.current) {
      // Check if the text is truncated
      setShowTooltip(linkRef.current.scrollWidth > linkRef.current.clientWidth);
    }
  }, [order?.customerName, isLoading]);

  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      // Check if the text is truncated
      setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [order?.consigneeAddress?.address1, isLoading]);

  useEffect(() => {
    if (textRef.current) {
      // Check if the text is truncated
      setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [order?.consigneeAddress?.address1, isLoading]);

  useEffect(() => {
    if (order?.tags?.length > 0) {
      setOrderTags(order?.tags);
    }
  }, [order?.tags]);

  return (
    <>
      <div className="ml-1 border border-utilityGray200 rounded-xl">
        <div className="flex py-3 border-b border-utilityGray200 px-4">
          <div className="relative sm:w-1/4">
            <h6 className="text-xs font-semibold text-grayLight600 mb-1">
              Customer
            </h6>

            <div className="flex items-center gap-x-1">
              {order?.customerImage ? (
                <img
                  src={`${order?.customerImageUrl}${order?.customerImage}`}
                  className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                />
              ) : (
                <div
                  className={`table-profile-customer-circle-xl w-5 h-5 text-xs ${
                    isLoading ? 'custom-loading image-loading' : ''
                  }`}
                >
                  {getShortName(order?.customerName)}
                </div>
              )}
              <TooltipCmp message={showTooltip ? order?.customerName : ''}>
                <Link
                  to={`${PATH.CUSTOMER_DETAILS}/${order?.customerId}`}
                  className={`block text-xs font-normal text-grayLight600 truncate mr-6 ${
                    isLoading ? 'custom-loading' : ''
                  } `}
                  ref={linkRef}
                >
                  {!isLoading && order?.customerName
                    ? order?.customerName
                    : 'DSS Marine Incorporated (USD)'}
                </Link>
              </TooltipCmp>
            </div>
          </div>

          <div className="relative sm:w-1/4">
            <h6 className="text-xs font-semibold text-grayLight600 mb-1">
              Address
            </h6>
            <div className="flex gap-x-2">
              <TooltipCmp
                message={showTooltip ? order?.shipperAddress?.address1 : ''}
              >
                <p
                  ref={textRef}
                  className={`xxl:max-w-28 max-w-20 text-grayLight600 text-xs font-medium truncate ${
                    isLoading ? 'custom-loading w-12' : ''
                  }`}
                >
                  {order?.shipperAddress?.address1
                    ? order?.shipperAddress?.address1
                    : '-'}
                </p>
              </TooltipCmp>
              <ArrowRight className="w-3.5 h-3.5 text-grayLight900 flex-none" />
              <TooltipCmp
                message={showTooltip ? order?.consigneeAddress?.address1 : ''}
                parentClassName="max-w-90"
              >
                <p
                  ref={textRef}
                  className={`xxl:max-w-28 max-w-20 text-grayLight600 text-xs font-medium truncate ${
                    isLoading ? 'custom-loading w-12' : ''
                  }`}
                >
                  {order?.consigneeAddress?.address1
                    ? order?.consigneeAddress?.address1
                    : '-'}
                </p>
              </TooltipCmp>
            </div>
          </div>
          <div className="relative sm:w-1/4">
            <h6 className="text-xs font-semibold text-grayLight600 mb-1">
              Tags
            </h6>
            <div className="flex items-center gap-x-1 text-primary ">
              {isLoading ? (
                <BadgeCmp
                  type="success"
                  style="modern"
                  isHidePillDot
                  mainClassName="custom-loading"
                >
                  <span className="mr-1">🏷️</span>
                  <span className="custom-loading w-12"></span>
                </BadgeCmp>
              ) : orderTags?.length > 0 ? (
                <div className="flex flex-wrap gap-x-1">
                  {orderTags?.slice(0, 1).map((tag: any) => (
                    <BadgeCmp
                      type="success"
                      style="modern"
                      isHidePillDot
                      key={tag.id}
                    >
                      <span className="mr-1">{tag.emoji}</span>
                      {tag.name}
                    </BadgeCmp>
                  ))}

                  {orderTags?.length > 1 && (
                    <div className=" border border-rounded border-utilityGray200 bg-gray50 px-1.5 py-0.5 text-textSecondary text-xs font-medium cursor-pointer">
                      +{orderTags?.length - 1}
                    </div>
                  )}

                  <Plus
                    className="w-4 h-4 flex-none cursor-pointer"
                    onClick={() => setShowTagModal(true)}
                  />
                </div>
              ) : (
                <div
                  className="flex items-center gap-x-1 cursor-pointer"
                  onClick={() => setShowTagModal(true)}
                >
                  <Plus className="w-4 h-4 flex-none" />
                  <p className="text-sm font-medium">Add Tag</p>
                </div>
              )}
            </div>
          </div>

          <div className="relative sm:w-1/4">
            <h6 className="text-xs font-semibold text-grayLight600 mb-1">
              Sales Rep.
            </h6>
            <div className="flex items-center gap-x-1">
              {order?.salesRepFirstName &&
                (order?.salesImage && order?.salesImageUrl ? (
                  <img
                    src={order?.salesImageUrl + order?.salesImage}
                    className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  />
                ) : (
                  <div
                    className={`w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]  ${
                      isLoading ? 'custom-loading image-loading' : ''
                    }`}
                  >
                    {getShortName(
                      `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                    )}
                  </div>
                ))}
              <p
                className={`text-sm text-primary  ${
                  order?.salesRepId && 'hover:underline'
                } ${isLoading ? 'custom-loading' : ''}`}
              >
                {!isLoading
                  ? order?.salesRepId
                    ? `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                    : '-'
                  : 'Polaris Transportation'}
              </p>
            </div>
          </div>
        </div>

        <div className="flex py-3 px-4 bg-gray50">
          <div className="relative sm:w-1/4 flex items-center gap-1">
            <h6 className="text-xs font-normal text-grayLight600">
              Created At:
            </h6>
            <p
              dangerouslySetInnerHTML={{
                __html: order
                  ? getDateWithSuffixFormat(
                      getFormattedDate(
                        order.createdAt,
                        `Do MMMM YYYY`,
                        true,
                        true
                      )
                    )
                  : '-',
              }}
              className={`xlm:max-w-56 max-w-40 text-primary text-sm font-medium truncate ${
                isLoading ? 'custom-loading' : ''
              }`}
            ></p>
          </div>
          <div className="relative sm:w-1/4 flex items-center gap-1">
            <h6 className="text-xs font-normal text-grayLight600">Claim ID:</h6>
            <p
              className={`text-sm font-medium  ${
                currentLegData?.claim?.claimID
                  ? 'text-primary'
                  : 'text-textSecondary'
              } ${isLoading ? 'custom-loading' : ''}`}
            >
              {!isLoading
                ? currentLegData?.claim?.claimID
                  ? `#${currentLegData?.claim?.claimID}`
                  : '-'
                : '#CLM-4562'}
            </p>
            {/* <ButtonCmp className="btn-outline-primary !bg-transparent !p-0 !border-none !gap-1 !text-sm !font-medium">
              <Plus className="text-primary w-4 h-4" />
              Add Claim
            </ButtonCmp> */}
          </div>
          <div className="relative sm:w-1/4 flex items-center gap-1">
            <h6 className="text-xs font-normal text-grayLight600">
              Dispute ID:
            </h6>
            <p
              className={`text-sm font-medium ${
                currentLegData?.dispute?.disputeID
                  ? 'text-primary'
                  : 'text-textSecondary'
              } ${isLoading ? 'custom-loading' : ''}`}
            >
              {!isLoading
                ? currentLegData?.dispute?.disputeID
                  ? `#${currentLegData?.dispute?.disputeID}`
                  : '-'
                : '#CLM-4562'}
            </p>
          </div>
          <div className="relative sm:w-1/4 flex items-center gap-1">
            <h6 className="text-xs font-normal text-grayLight600">
              Load Insurance ID:
            </h6>
            <Link
              to={`${PATH.LOAD_INSURANCE}/${order?.loadInsurance?.id}`}
              className={`text-sm font-medium ${
                order?.loadInsurance?.load_insurance_id
                  ? 'text-primary hover:underline'
                  : 'text-textSecondary'
              } ${isLoading ? 'custom-loading' : ''} `}
            >
              {!isLoading
                ? order?.loadInsurance?.load_insurance_id
                  ? `#${order?.loadInsurance?.load_insurance_id}`
                  : '-'
                : '#LIN-45612'}
            </Link>
          </div>
        </div>
      </div>

      {showTagModal && (
        <TagsModal
          tagList={tagList}
          handleClose={() => setShowTagModal(false)}
          masterOrderId={order?.id}
          setOrderTags={setOrderTags}
        />
      )}
    </>
  );
};

export default OrderInfoSection;
