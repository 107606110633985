import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const LoadBoardRatesLoader = () => (
  <>
    <td className="px-5 py-4 max-w-[1px] w-[18%] min-w-[200px]">
      <div className="flex items-center truncate">
        <div className="table-profile-customer-circle-xl rounded-md custom-loading">
          TT
        </div>
        <div className="ml-3 truncate custom-loading">
          <TooltipCmp>
            <h6 className="text-grayLight900 font-medium leading-tight truncate ">
              Canadian Logistic Express
            </h6>
          </TooltipCmp>

          <TooltipCmp>
            <span className="text-grayLight600 font-normal leading-tight truncate">
              +1 (226) 289-9000
            </span>
          </TooltipCmp>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
      <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
        in progress
      </BadgeCmp>
    </td>

    <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
      <span className="custom-loading">inbound</span>
    </td>

    <td className="px-5 py-4 min-w-[12.10%] w-[12.10%] max-w-[1px]">
      <p className="custom-loading truncate">Dry Van</p>
    </td>
    <td className="px-5 py-4 min-w-[12.10%] w-[12.10%] max-w-[1px]">
      <span className="custom-loading">Dec 24th - 2024</span>
    </td>
    <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
      <span className="custom-loading">$3,000</span>
    </td>
    <td className="px-5 py-4 min-w-[20.04%] w-[20.04%] max-w-[1px]">
      <div className="truncate custom-loading">
        Carrier can pick up today at 2:30 PM and deliver by
      </div>
    </td>
    {/* <td className="px-5 py-4 max-w-[60px] w-[60px] min-w-[60px]">
      <div className="w-4 h-4 custom-loading">
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer " />
      </div>
    </td> */}
  </>
);

export default LoadBoardRatesLoader;
