import React, { memo } from 'react';

import defaultImage from '../../assets/img/default-image.jpg';

const RadarPricing = ({ orderLeg }: any) => {
  console.log('orderLeg', orderLeg);

  const additionalChargesTotal = Array.isArray(
    orderLeg?.carrierPrice?.additional_charge
  )
    ? orderLeg?.carrierPrice?.additional_charge.reduce(
        (total: number, item: any) => total + (item.price || 0),
        0
      )
    : 0;

  const additionalSaleChargesTotal = Array.isArray(
    orderLeg?.salesPrice?.additional_charge
  )
    ? orderLeg?.salesPrice?.additional_charge.reduce(
        (total: number, item: any) => total + (item.price || 0),
        0
      )
    : 0;

  const combinedMargin =
    orderLeg?.salesPrice?.totalSalePrice -
    (orderLeg?.carrierPrice?.totalPrice || 0);

  const combinedMarginPercentage =
    orderLeg?.salesPrice?.totalSalePrice > 0
      ? (combinedMargin / orderLeg?.salesPrice?.totalSalePrice) * 100
      : 0;

  return (
    <>
      <div className="w-full mb-5">
        <h6 className="text-grayLight600 text-xs font-normal">Carrier</h6>
        <div className="rounded-lg border border-utilityGray200 p-3 gap-x-2 mt-1">
          <div className="flex items-center gap-2.5">
            <img
              src={
                orderLeg.carrierImageUrl + orderLeg.carrierImage || defaultImage
              }
              className="w-8 h-8 rounded-md border border-utilityGray200 flex-none"
            />
            <div>
              <p className="font-medium text-sm text-grayLight900">
                {orderLeg?.carrierName}
              </p>
              <p className="text-xs text-textSecondary font-normal">
                #WQ085020145
              </p>
            </div>
          </div>
          <div className="mt-4 space-y-2">
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Carrier Type</span>
              <span className="text-grayLight900">
                {orderLeg?.carrierPrice?.carrier?.isPrivateCarrier
                  ? 'Private'
                  : 'Common'}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Quote ID</span>
              <span className="text-primary underline">
                {orderLeg?.carrierPrice?.code
                  ? `#${orderLeg?.carrierPrice?.code}`
                  : '-'}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Total Cost</span>
              <span>${orderLeg?.carrierPrice?.baseCarrierRate}</span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Additionals</span>
              <span>
                ${additionalChargesTotal + orderLeg?.carrierPrice?.fuel}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Total Price</span>
              <span>${orderLeg?.carrierPrice?.totalPrice}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <h6 className="text-grayLight600 text-xs font-normal">Sale Price</h6>
        <div className="rounded-lg border border-utilityGray200 p-3 gap-x-2 mt-1">
          <div className="flex items-center gap-2.5">
            <img
              src={`${
                orderLeg?.salesPrice?.customer?.imageUrl +
                  orderLeg?.salesPrice?.customer?.image || defaultImage
              }`}
              alt={`${orderLeg?.salesPrice?.customer?.name}`}
              className="w-8 h-8 rounded-full border border-utilityGray200 flex-none"
            />
            <div>
              <p className="font-medium text-sm text-grayLight900">
                {orderLeg?.salesPrice?.customer?.name}
              </p>
              <p className="text-xs text-textSecondary font-normal">
                Billing - CAD
              </p>
            </div>
          </div>

          <div className="mt-4 space-y-2">
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Base Sale Price</span>
              <span className="text-grayLight900">
                ${orderLeg?.salesPrice?.baseSalePrice}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Additionals</span>
              <span className="text-grayLight900">
                ${additionalSaleChargesTotal + orderLeg?.salesPrice?.fuel}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Margin</span>
              <span className="text-grayLight900">
                ${combinedMargin ? combinedMargin?.toFixed(2) : 0}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Margin %</span>
              <span className="text-grayLight900">
                %
                {combinedMarginPercentage
                  ? Math.abs(combinedMarginPercentage)?.toFixed(2)
                  : 0}
              </span>
            </div>
            <div className="flex justify-between text-xs">
              <span className="text-grayLight600">Total Sale Price</span>
              <span className="text-grayLight900">
                ${orderLeg?.salesPrice?.totalSalePrice}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(RadarPricing);
