// import { XClose } from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { CURRENCY } from 'src/constants/common';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

interface IProps {
  teamListOptions: any;
  customerList: any;
  userList: any;
  handleClose: any;
  paramsForE2EOrder: any;
  currency: any;
  setParamsForE2EOrder: any;
  setFilterCount: any;
  filterType: any;
  shipperOptions: any;
  consigneeOptions: any;
}

const FilterDrawer = ({
  teamListOptions,
  customerList,
  userList,
  handleClose,
  paramsForE2EOrder,
  currency,
  setParamsForE2EOrder,
  setFilterCount,
  filterType,
  shipperOptions,
  consigneeOptions,
}: IProps) => {
  const [teamId, setTeamId] = useState(paramsForE2EOrder?.team ?? 'all');
  const [memberId, setMemberId] = useState(paramsForE2EOrder?.member ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);
  const [customerId, setCustomerId] = useState(
    paramsForE2EOrder?.customer ?? 'all'
  );
  const [shipperId, setShipperId] = useState(
    paramsForE2EOrder?.shipper ?? 'all'
  );

  const [consigneeId, setConsigneeId] = useState(
    paramsForE2EOrder?.consignee ?? 'all'
  );

  useEffect(() => {
    if (paramsForE2EOrder?.team) {
      setTeamId(paramsForE2EOrder?.team);
    }

    if (paramsForE2EOrder?.member) {
      setMemberId(paramsForE2EOrder?.member);
    }

    if (paramsForE2EOrder?.customer) {
      setCustomerId(paramsForE2EOrder?.customer);
    }

    if (paramsForE2EOrder?.shipper) {
      setShipperId(paramsForE2EOrder?.shipper);
    }

    if (paramsForE2EOrder?.consignee) {
      setConsigneeId(paramsForE2EOrder?.consignee);
    }
  }, [paramsForE2EOrder]);

  const handleSave = () => {
    let fCount = 0;

    if (currency !== CURRENCY.CAD) fCount += 1;
    if (teamId !== 'all') fCount += 1;
    if (memberId !== 'all') fCount += 1;
    if (customerId !== 'all') fCount += 1;
    if (shipperId !== 'all') fCount += 1;
    if (consigneeId !== 'all') fCount += 1;

    setFilterCount(fCount);

    setParamsForE2EOrder((old: any) => ({
      ...old,
      page: 1,
      segment: '',
      limit: 50,
      team: teamId,
      member: memberId,
      customer: customerId,
      shipper: shipperId,
      consignee: consigneeId,
    }));

    handleClose();
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (teamId === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo: any) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo: any) => tlo.value === teamId
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [teamId, teamListOptions]);

  return (
    <>
      <li className="flex flex-col gap-y-2.5 flex-1 overflow-y-auto custom-scrollbar-v2 ">
        {filterType === 'teamAnduser' && (
          <>
            <SelectBox
              label="Team"
              labelClassName="form_label mb-1.5 block"
              name="teamListGroup"
              id="teamListGroup"
              className="form_control"
              size="sm"
              placeholder="All Teams"
              noOptionMessage="No Teams Found"
              parentClassName=""
              isClearable={true}
              isSearchable={true}
              options={teamListOptions}
              onChangeFunc={(event: any) => {
                setTeamId(event?.value ?? 'all');
                setMemberId('all');
              }}
              value={
                teamId
                  ? teamListOptions.filter((val: any) => teamId === val.value)
                  : null
              }
              // icon={<XClose className="absolute top-0 right-0" />}
            />

            <SelectBox
              label="Users"
              labelClassName="form_label mb-1.5 block"
              name="userListGroup"
              id="userListGroup"
              size="sm"
              placeholder="All Users"
              className="form_control"
              isClearable={true}
              isSearchable={true}
              options={teamMember}
              parentClassName=""
              noOptionMessage="No Users Found"
              onChangeFunc={(event: any) => setMemberId(event?.value ?? 'all')}
              value={
                memberId
                  ? teamMember.filter((user: any) => memberId === user.value)
                  : null
              }
            />
          </>
        )}

        {filterType === 'customer' && (
          <SelectBox
            label="Customer"
            labelClassName="form_label mb-1.5 block"
            name="customer"
            id="customer"
            size="sm"
            placeholder="Customer"
            className="form_control"
            isClearable={true}
            isSearchable={true}
            options={customerList}
            parentClassName=""
            noOptionMessage="No Customer Found"
            onChangeFunc={(event: any) => setCustomerId(event?.value ?? 'all')}
            value={
              customerId
                ? customerList.filter(
                    (customer: any) => customerId === customer.value
                  )
                : null
            }
          />
        )}

        {filterType === 'shipperAndConsignee' && (
          <>
            <SelectBox
              label="Shipper"
              labelClassName="form_label mb-1.5 block"
              name="shipper"
              id="shipper"
              size="sm"
              placeholder="Shipper"
              className="form_control"
              isClearable={true}
              isSearchable={true}
              options={shipperOptions}
              parentClassName=""
              noOptionMessage="No Shipper Found"
              onChangeFunc={(event: any) => setShipperId(event?.value ?? 'all')}
              value={
                shipperId
                  ? shipperOptions.filter(
                      (shipper: any) => shipperId === shipper.value
                    )
                  : null
              }
            />

            <SelectBox
              label="Consignee"
              labelClassName="form_label mb-1.5 block"
              name="consignee"
              id="consignee"
              size="sm"
              placeholder="Consignee"
              className="form_control"
              isClearable={true}
              isSearchable={true}
              options={consigneeOptions}
              parentClassName=""
              noOptionMessage="No Consignee Found"
              onChangeFunc={(event: any) =>
                setConsigneeId(event?.value ?? 'all')
              }
              value={
                consigneeId
                  ? consigneeOptions.filter(
                      (consignee: any) => consigneeId === consignee.value
                    )
                  : null
              }
            />
          </>
        )}
      </li>
      <li className="pt-4 flex gap-3 border-t border-utilityGray200">
        <ButtonCmp className="btn_secondary_black flex-1" onClick={handleClose}>
          Cancel
        </ButtonCmp>
        <ButtonCmp className="btn-outline-primary flex-1" onClick={handleSave}>
          Save
        </ButtonCmp>
      </li>
    </>
  );
};

export default FilterDrawer;
