import { yupResolver } from '@hookform/resolvers/yup';
import { ReverseRight } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import { createRecurringOrder } from 'src/services/OrderService';
import * as yup from 'yup';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

interface IProps {
  handleClose: any;
  order: any;
}

const schema = yup.object().shape({
  pickupDate: yup
    .date()
    .required('Pickup Date is required')
    .typeError('Pickup Date must be a valid date')
    .nullable(),

  repeatOrderTimeFrame: yup
    .string()
    .oneOf(['daily', 'weekly', 'monthly'], 'Invalid repeat order time frame')
    .required('Repeat Order Time Frame is required'),

  repeatTime: yup.number().when('repeatOrderTimeFrame', {
    is: (value: string) => value !== 'monthly',
    then: () =>
      yup
        .number()
        .required('Repeat Interval is required')
        .typeError('Repeat Interval must be a valid number')
        .min(1, 'Repeat Interval must be at least 1'),
    otherwise: () => yup.number().nullable(),
  }),

  recurrenceEndAt: yup
    .string()
    .oneOf(['onThisDay', 'after'], 'Invalid recurrence end type')
    .required('Recurrence End Type is required'),

  recurrenceEndDate: yup
    .date()
    .nullable()
    .when('recurrenceEndAt', {
      is: () => 'onThisDay',
      then: () =>
        yup
          .date()
          .required('End Date is required when "onThisDay" is selected')
          .typeError('End Date must be a valid date'),
      otherwise: () => yup.date().nullable(),
    }),

  totalOccurrence: yup.number().when('recurrenceEndAt', {
    is: () => 'onThisDay',
    then: () => yup.number().nullable(),
    otherwise: () =>
      yup
        .number()
        .required('Total Occurrence is required when "after" is selected')
        .typeError('Total Occurrence must be a valid number')
        .min(1, 'Total Occurrence must be at least 1'),
  }),

  selectedDays: yup
    .array()
    .of(yup.string().oneOf(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']))
    .nullable()
    .when('repeatOrderTimeFrame', {
      is: (value: any) => value === 'weekly',
      then: () =>
        yup
          .array()
          .min(1, 'At least one day must be selected for weekly recurrence')
          .required('Selected Days are required for weekly recurrence'),
      otherwise: () => yup.array().nullable(),
    }),
});

const RecurringOrderModal = ({ handleClose, order }: IProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [orderDetailsArray, setOrderDetailsArray] = useState<any>([]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      pickupDate: moment().toDate(),
      repeatOrderTimeFrame: 'weekly',
      recurrenceEndAt: 'onThisDay',
      recurrenceEndDate: moment().toDate(),
      repeatTime: undefined,
      totalOccurrence: undefined,
    },
  });

  const {
    formState: { errors },
    watch,
    handleSubmit,
  } = methods;

  console.log('errors', errors);

  const watchRepeatOrderTimeFrame = watch('repeatOrderTimeFrame');

  const calculateOrders = ({
    pickupDate,
    repeatOrderTimeFrame,
    repeatTime,
    recurrenceEndAt,
    recurrenceEndDate,
    totalOccurrence,
    selectedDay,
  }: any) => {
    const orders: any = [];
    let currentDate = moment(pickupDate);

    if (recurrenceEndAt === 'onThisDay') {
      while (currentDate.isSameOrBefore(moment(recurrenceEndDate))) {
        if (repeatOrderTimeFrame === 'weekly') {
          const validDays = selectedDay.map((day: any) =>
            moment().day(day).isoWeekday()
          );

          for (let i = 0; i < validDays.length; i++) {
            const day = validDays[i];
            const targetDate = moment(currentDate).day(day);

            if (
              targetDate.isSameOrAfter(currentDate) &&
              targetDate.isSameOrBefore(moment(recurrenceEndDate))
            ) {
              orders.push(targetDate.format('dddd, MMMM Do, YYYY'));
            }
          }

          currentDate.add(repeatTime, 'weeks');
        } else if (repeatOrderTimeFrame === 'daily') {
          orders.push(currentDate.format('dddd, MMMM Do, YYYY'));
          currentDate.add(repeatTime, 'days');
        }
      }
    } else if (recurrenceEndAt === 'after') {
      let occurrences = 0;

      while (occurrences < totalOccurrence) {
        if (repeatOrderTimeFrame === 'weekly') {
          const validDays = selectedDay.map((day: any) =>
            moment().day(day).isoWeekday()
          );

          for (let i = 0; i < validDays.length; i++) {
            const day = validDays[i];
            const targetDate = moment(currentDate).day(day);

            if (targetDate.isSameOrAfter(currentDate)) {
              orders.push(targetDate.format('dddd, MMMM Do, YYYY'));
              occurrences++;

              if (occurrences >= totalOccurrence) {
                break;
              }
            }
          }

          currentDate.add(repeatTime, 'weeks');
        } else if (repeatOrderTimeFrame === 'daily') {
          orders.push(currentDate.format('dddd, MMMM Do, YYYY'));
          occurrences++;
          currentDate.add(repeatTime, 'days');
        } else if (repeatOrderTimeFrame === 'monthly') {
          orders.push(currentDate.format('dddd, MMMM Do, YYYY'));
          occurrences++;
          currentDate = currentDate.clone().add(repeatTime, 'months');
        }
      }
    }

    return orders;
  };

  const onSubmit = (data: any) => {
    if (currentStep === 1) {
      // const payload = {
      //   pickupDate: data.pickupDate,
      //   repeatOrderTimeFrame: data.repeatOrderTimeFrame,
      //   repeatInterval: data.repeatTime,
      //   selectedDays:
      //     watchRepeatOrderTimeFrame === 'weekly' ? selectedDays : null,
      //   endType: data.recurrenceEndAt,
      //   endDate:
      //     data.recurrenceEndAt === 'onThisDay' ? data.recurrenceEndDate : null,
      //   totalOccurrences:
      //     data.recurrenceEndAt === 'after' ? data.totalOccurrence : null,
      // };

      const orders = calculateOrders({
        pickupDate: data.pickupDate,
        repeatOrderTimeFrame: data.repeatOrderTimeFrame,
        repeatTime: data.repeatTime,
        selectedDay:
          watchRepeatOrderTimeFrame === 'weekly' ? selectedDays : null,
        recurrenceEndAt: data.recurrenceEndAt,
        recurrenceEndDate:
          data.recurrenceEndAt === 'onThisDay' ? data.recurrenceEndDate : null,
        totalOccurrence:
          data.recurrenceEndAt === 'after' ? data.totalOccurrence : null,
      });

      setOrderDetailsArray(orders);
      setCurrentStep(2);

      return;
    } else if (currentStep === 2) {
      setCurrentStep(3);
    }

    createRecurringOrder(orderDetailsArray)
      .then((response) => {
        if (response) {
          handleClose();
        }
      })
      .catch(console.error);
  };

  const handleCloseModal = () => {
    if (currentStep === 1) {
      handleClose();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <CommonModal
      title="Create a Reoccuring Order"
      titleDesc="Managing orders with automated task recurrence"
      handleClose={handleClose}
      headerIcon={<ReverseRight />}
      size={'xl:max-w-[688px] md:max-w-[536px] max-w-[496px] overflow-unset'}
      primaryBtnText="Assign"
      secondaryBtnText={`${currentStep === 1 ? 'Cancel' : 'Back'}`}
      secondaryBtnOnClick={handleCloseModal}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      isOverflow={false}
    >
      <FormProvider {...methods}>
        {currentStep === 1 && (
          <Step1
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
          />
        )}

        {currentStep === 2 && <Step2 order={order} />}

        {currentStep === 3 && <Step3 orderDetailsArray={orderDetailsArray} />}
      </FormProvider>
    </CommonModal>
  );
};

export default RecurringOrderModal;
