import { Plus } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteModal from 'src/components/DeleteModal';
import TableCmp from 'src/components/TableCmp';
import {
  deleteCarrierPrice,
  deleteSalesPrice,
  updateOrCreateCarrierPrice,
  updateSalesPrice,
} from 'src/services/OrderService';

import { OrderContext } from '..';
import CarrierModal from '../../Common/Carrier/CarrierModal';
import SalesModal from '../../Common/Sales/SalesModal';

import CarrierPriceRow from './carrierPriceRow';
import CarrierPriceRowLoader from './CarrierPriceRowLoader';
import salesPriceRow from './salesPriceRow';
import SalesPriceRowLoader from './SalesPriceRowLoader';

const initDeleteAction = {
  mode: null,
  type: null,
};

const CarrierAndSalesPrice = () => {
  const {
    currentLegData,
    setCurrentLegData,
    id,
    customerList,
    isCustomerLoading,
    order,
    // setOrder,
    salesPrice,
    carrierPrice,
    setSalesPrice,
    setCarrierPrice,
    setTabValues,
    activeLeg,
    isLoading,
  } = useContext<any>(OrderContext);

  const [isSaleFormOpen, setIsSaleFormOpen] = useState<boolean>(false);
  const [isCarrierFormOpen, setIsCarrierFormOpen] = useState<boolean>(false);
  const [carrierEditData, setCarrierEditData] = useState(null);
  const [salesEditData, setSalesEditData] = useState<any>(null);
  const [deleteAction, setDeleteAction] = useState(initDeleteAction);

  const handleSalesClose = () => {
    setIsSaleFormOpen(false);
  };

  const handleCarrierEdit = (data: any) => {
    if (data.carrierDate) {
      data.carrierDate = moment(data.carrierDate).format('YYYY-MM-DD');
    }

    if (!data.carrierId) {
      data.carrierId = 'unassigned';
    }

    setCarrierEditData(data);
    setIsCarrierFormOpen(true);
  };

  const handleSalesEdit = (data: any) => {
    setSalesEditData(data);
    setIsSaleFormOpen(true);
  };

  const salesHeadCells = useMemo(
    () => [
      {
        id: 'customer',
        name: 'Customer',
      },
      {
        id: 'baseSalePrice',
        name: 'Base Sale Price',
      },
      {
        id: 'Additionals',
        name: 'Additionals',
      },

      {
        id: 'totalSalePrice',
        name: 'Total Sale Price',
      },
      {
        id: 'margin',
        name: 'Margin',
      },
      {
        id: 'margin%',
        name: 'Margin %',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const carrierHeadCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
      },
      // {
      //   id: 'carrierType',
      //   name: 'Carrier Type',
      // },
      {
        id: 'budgetCost',
        name: 'Budget Cost',
      },
      {
        id: 'carrierBookingDate',
        name: 'Carrier Booking Date',
      },
      {
        id: 'totalPrice',
        name: 'Total Cost',
      },
      {
        id: 'totalCost',
        name: 'Base Cost',
      },
      {
        id: 'additionals',
        name: 'Additionals',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    updateOrCreateCarrierPrice({
      ...newCarrierPrice,
      orderLegId: currentLegData?.id,
      masterOrderId: id,
    })
      .then((response) => {
        if (response?.data) {
          let carrierPriceData = {};

          if (response?.data && response?.data.length > 0) {
            carrierPriceData = response.data.map((data: any) => ({
              ...data,
              additional_charge: data.additional_charge
                ? JSON.parse(data.additional_charge)
                : [],
              carrier: data.carrier ? JSON.parse(data.carrier) : {},
            }));
          }

          setIsCarrierFormOpen(false);
          setCarrierPrice(carrierPriceData);

          setCurrentLegData((old: any) => ({
            ...old,
            carrierPrice: carrierPriceData,
          }));

          setTabValues((prev: any) => ({
            ...prev,
            [activeLeg]: {
              ...prev[activeLeg],
              carrierPrice: carrierPriceData,
            },
          }));

          // setCarrierData(carrierPrice);
        }
      })
      .catch(console.error);
  };

  const handleUpdateSalesPrice = (salePrice: any) => {
    updateSalesPrice({
      ...salePrice,
      orderLegId: currentLegData?.id,
    })
      .then((response) => {
        if (response?.data) {
          let salesPriceDetails = {};

          if (response?.data) {
            salesPriceDetails = {
              ...response?.data,
              additional_charge: response?.data.additional_charge,
            };
          }

          setIsSaleFormOpen(false);
          setSalesPrice([salesPriceDetails]);

          setCurrentLegData((old: any) => ({
            ...old,
            salesPrice: [salesPriceDetails],
          }));

          // setOrder((old: any) => ({
          //   ...old,
          //   salesPrice: [salesPrice],
          // }));

          // setCarrierData(carrierPrice);

          setTabValues((prev: any) => ({
            ...prev,
            [activeLeg]: {
              ...prev[activeLeg],
              salesPrice: [salesPriceDetails],
            },
          }));
        }
      })
      .catch(console.error);
  };

  const handleDeletePrice = useCallback(
    (status: boolean) => () => {
      if (status) {
        if (deleteAction.mode === 'delete-carrier-price') {
          deleteCarrierPrice({
            masterOrderId: order?.id,
            orderLegId: currentLegData?.id,
          })
            .then((response) => {
              if (response) {
                setCarrierPrice([]);
                setCurrentLegData((old: any) => ({
                  ...old,
                  carrierPrice: [],
                }));

                setTabValues((prev: any) => ({
                  ...prev,
                  [activeLeg]: {
                    ...prev[activeLeg],
                    carrierPrice: [],
                  },
                }));
              }
            })
            .catch(console.error);
        }

        if (deleteAction.mode === 'delete-sales-price') {
          deleteSalesPrice({
            masterOrderId: order?.id,
            orderLegId: currentLegData?.id,
          })
            .then((response) => {
              if (response) {
                setSalesPrice([]);
                setCurrentLegData((old: any) => ({
                  ...old,
                  salesPrice: [],
                }));

                setTabValues((prev: any) => ({
                  ...prev,
                  [activeLeg]: {
                    ...prev[activeLeg],
                    salesPrice: [],
                  },
                }));
              }
            })
            .catch(console.error);
        }
      }

      setDeleteAction((old: any) => ({
        ...old,
        ...{ mode: null },
      }));
    },
    [deleteAction]
  );

  return (
    <>
      {
        <>
          <li>
            <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
              Total Customer Sale Price
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                <TableCmp
                  headCells={salesHeadCells}
                  tableDataArr={salesPrice}
                  TableLoaderRowCmp={SalesPriceRowLoader}
                  TableRowCmp={salesPriceRow}
                  tableRowCmpProps={{
                    handleSalesEdit,
                    carrierPrice,
                    setDeleteAction,
                  }}
                  numberOfSkeletonRows={1}
                  isTableDataLoading={isLoading}
                />
                {!isLoading && salesPrice && salesPrice?.length === 0 && (
                  <div
                    className="p-4 text-center cursor-pointer hover:bg-gray25"
                    onClick={() => setIsSaleFormOpen(true)}
                  >
                    <div className="inline-flex items-center">
                      <Plus className="w-4 h-4 text-primary700" />
                      <p className="text-grayLight900 text-sm font-medium ml-2">
                        Add Sale price
                      </p>
                    </div>
                    <p className="text-grayLight900 text-sm font-medium mt-0.5">
                      Add the base price and include any extra charges.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </li>

          <li>
            <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
              Total Carrier Cost
            </h6>
            <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                <TableCmp
                  headCells={carrierHeadCells}
                  tableDataArr={carrierPrice}
                  TableRowCmp={CarrierPriceRow}
                  tableRowCmpProps={{ handleCarrierEdit, setDeleteAction }}
                  numberOfSkeletonRows={1}
                  TableLoaderRowCmp={CarrierPriceRowLoader}
                  isTableDataLoading={isLoading}
                />
                {!isLoading && carrierPrice && carrierPrice?.length === 0 && (
                  <div
                    className="p-4 text-center cursor-pointer hover:bg-gray25"
                    onClick={() => setIsCarrierFormOpen(true)}
                  >
                    <div className="inline-flex items-center">
                      <Plus className="w-4 h-4 text-primary700" />
                      <p className="text-grayLight900 text-sm font-medium ml-2">
                        Add Carrier Costs
                      </p>
                    </div>
                    <p className="text-grayLight900 text-sm font-medium mt-0.5">
                      Attach a carrier to this order, if applicable at this
                      time.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </li>
        </>
      }

      {isSaleFormOpen && (
        <SalesModal
          handleClose={handleSalesClose}
          handleAddSalesPrice={handleUpdateSalesPrice}
          orderSalesPrice={salesEditData}
          masterOrderId={id}
          customerList={customerList}
          isCustomerLoading={isCustomerLoading}
          customerId={order?.customerId}
        />
      )}

      {isCarrierFormOpen && (
        <CarrierModal
          handleClose={() => {
            setIsCarrierFormOpen(false);
          }}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
          customerId={order?.cusotmerId}
        />
      )}

      {deleteAction.mode === 'delete-carrier-price' && (
        <DeleteModal
          moduleName="Carrier Price"
          handleClose={handleDeletePrice}
        />
      )}

      {deleteAction.mode === 'delete-sales-price' && (
        <DeleteModal moduleName="Sales Price" handleClose={handleDeletePrice} />
      )}
    </>
  );
};

export default CarrierAndSalesPrice;
