import { Plus } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CarrierModal from 'src/app/Orders/Common/Carrier/CarrierModal';
import SalesModal from 'src/app/Orders/Common/Sales/SalesModal';
import ErrorMsg from 'src/components/errorMsg';
import TableCmp from 'src/components/TableCmp';

import carrierRow from './carrierRow';
import salesPriceRow from './salesPriceRow';

interface IProps {
  id: any;
  isCustomerLoading: any;
  customerList: any;
  order: any;
  salesPrice: any;
  setSalesPrice: any;
  carrierPrice: any;
  setCarrierPrice: any;
}

const CarrierAndSalesPrice = ({
  id,
  isCustomerLoading,
  customerList,
  order,
  salesPrice,
  setSalesPrice,
  carrierPrice,
  setCarrierPrice,
}: IProps) => {
  const [isSaleFormOpen, setIsSaleFormOpen] = useState<boolean>(false);
  const [isCarrierFormOpen, setIsCarrierFormOpen] = useState<boolean>(false);
  const [carrierEditData, setCarrierEditData] = useState(null);
  const [salesEditData, setSalesEditData] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);

  const {
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (salesPrice) {
      setValue('salesPrice', salesPrice);
    }
  }, [salesPrice]);

  useEffect(() => {
    if (carrierPrice) {
      setValue('carrierPrice', carrierPrice);
    }
  }, [carrierPrice]);

  useEffect(() => {
    if (order?.customerId) {
      const cusotmerId = order?.customerId;
      setCustomer(cusotmerId);
    }
  }, [order]);

  const handleSalesClose = () => {
    setIsSaleFormOpen(false);
    setCarrierEditData(null);
  };

  const handleCarrierClose = () => {
    setIsCarrierFormOpen(false);
    setCarrierEditData(null);
  };

  const handleAddSalesPrice = (newSalePrice: any) => {
    setSalesPrice(() => [newSalePrice]);
    setValue('salesPrice', [newSalePrice]);
    clearErrors('salesPrice');
  };

  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    // setCarrierPrice((prev: any[]) => {
    //   const existingIndex = prev.findIndex(
    //     (carrPrice) => carrPrice.uniqueKey === newCarrierPrice.uniqueKey
    //   );

    //   if (existingIndex !== -1) {
    //     const updatedCarrierPrices = [...prev];
    //     updatedCarrierPrices[existingIndex] = newCarrierPrice;

    //     return updatedCarrierPrices;
    //   }

    //   return [...prev, newCarrierPrice];
    // });

    // const existingCarrierPrices = getValues('carrierPrice') || [];
    // const existingIndex = existingCarrierPrices.findIndex(
    //   (carrPrice: any) => carrPrice.uniqueKey === newCarrierPrice.uniqueKey
    // );

    // if (existingIndex !== -1) {
    //   const updatedCarrierPrices = [...existingCarrierPrices];
    //   updatedCarrierPrices[existingIndex] = newCarrierPrice;
    //   setValue('carrierPrice', updatedCarrierPrices);
    // } else {
    //   setValue('carrierPrice', [...existingCarrierPrices, newCarrierPrice]);
    // }

    setCarrierPrice(() => [newCarrierPrice]);
    setValue('carrierPrice', [newCarrierPrice]);
    clearErrors('carrierPrice');

    setIsCarrierFormOpen(false);
  };

  const salesHeadCells = useMemo(
    () => [
      {
        id: 'customer',
        name: 'Customer',
      },
      {
        id: 'baseSalePrice',
        name: 'Base Sale Price',
      },
      {
        id: 'Additionals',
        name: 'Additionals',
      },

      {
        id: 'totalSalePrice',
        name: 'Total Sale Price',
      },
      {
        id: 'margin',
        name: 'Margin',
      },
      {
        id: 'margin%',
        name: 'Margin %',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const carrierHeadCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
      },
      // {
      //   id: 'carrierType',
      //   name: 'Carrier Type',
      // },
      {
        id: 'budgetCost',
        name: 'Budget Cost',
      },
      {
        id: 'carrierBookingDate',
        name: 'Carrier Booking Date',
      },
      {
        id: 'totalPrice',
        name: 'Total Cost',
      },
      {
        id: 'totalCost',
        name: 'Base Cost',
      },
      {
        id: 'additionals',
        name: 'Additionals',
      },

      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const handleCarrierEdit = (data: any) => {
    if (data.carrierDate) {
      data.carrierDate = moment(data.carrierDate).format('YYYY-MM-DD');
    }

    if (!data.carrierId) {
      data.carrierId = 'unassigned';
    }

    setCarrierEditData(data);
    setIsCarrierFormOpen(true);
  };

  const handleSalesEdit = (data: any) => {
    setSalesEditData(data);
    setIsSaleFormOpen(true);
  };

  const handleSalesDelete = () => {
    setSalesPrice([]);
    setSalesEditData(null);
  };

  const handleCarrierDelete = () => {
    setCarrierPrice([]);
    setCarrierEditData(null);
  };

  return (
    <>
      <div className="space-y-6">
        <div>
          <div className="flex justify-between">
            <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
              Select a carrier
            </h6>
            {/* {carrierPrice &&
              carrierPrice?.length > 0 &&
              carrierPrice[0]?.trackingUrl && (
                <p className="text-textSecondary text-xs font-normal">
                  Tracking URl:&nbsp;
                  <Link
                    to={`${carrierPrice[0]?.trackingUrl}`}
                    className="text-primary max-w-[150px] truncate underline"
                    target="_blank"
                  >
                    {carrierPrice[0]?.trackingUrl}
                  </Link>
                </p>
              )} */}
          </div>
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={carrierHeadCells}
                tableDataArr={carrierPrice}
                TableRowCmp={carrierRow}
                tableRowCmpProps={{
                  setIsCarrierFormOpen,
                  handleCarrierEdit,
                  handleCarrierDelete,
                }}
                numberOfSkeletonRows={1}
              />
              {carrierPrice && carrierPrice?.length === 0 && (
                <div
                  className="p-4 text-center cursor-pointer hover:bg-gray25"
                  onClick={() => setIsCarrierFormOpen(true)}
                >
                  <div className="inline-flex items-center">
                    <Plus className="w-4 h-4 text-primary700" />
                    <p className="text-grayLight900 text-sm font-medium ml-2">
                      Add Carrier Costs
                    </p>
                  </div>
                  <p className="text-grayLight900 text-xs font-medium mt-0.5">
                    Attach a carrier to this order, if applicable at this time.
                  </p>
                </div>
              )}
            </div>
          </div>

          {errors?.carrierPrice?.message && (
            <ErrorMsg errorText={errors?.carrierPrice?.message} />
          )}
        </div>

        <div>
          <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
            Add a Sale Price
          </h6>
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={salesHeadCells}
                tableDataArr={salesPrice}
                // TableLoaderRowCmp={CarriersListLoading}
                TableRowCmp={salesPriceRow}
                tableRowCmpProps={{
                  handleSalesEdit,
                  customerList,
                  handleSalesDelete,
                  carrierPrice,
                }}
                numberOfSkeletonRows={1}
              />
              {salesPrice && salesPrice?.length === 0 && (
                <div
                  className="p-4 text-center cursor-pointer hover:bg-gray25"
                  onClick={() => setIsSaleFormOpen(true)}
                >
                  <div className="inline-flex items-center">
                    <Plus className="w-4 h-4 text-primary700" />
                    <p className="text-grayLight900 text-sm font-medium ml-2">
                      Add Sale price
                    </p>
                  </div>
                  <p className="text-grayLight900 text-xs font-medium mt-0.5">
                    Add the base price and include any extra charges.
                  </p>
                </div>
              )}
            </div>
          </div>

          {errors?.salesPrice?.message && (
            <ErrorMsg errorText={errors?.salesPrice?.message} />
          )}
        </div>
      </div>

      {isSaleFormOpen && (
        <SalesModal
          handleClose={handleSalesClose}
          handleAddSalesPrice={handleAddSalesPrice}
          orderSalesPrice={salesEditData}
          masterOrderId={id}
          customerList={customerList}
          isCustomerLoading={isCustomerLoading}
          customerId={customer}
        />
      )}

      {isCarrierFormOpen && (
        <CarrierModal
          handleClose={handleCarrierClose}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
          customerId={order?.customerId}
          additionalServices={getValues('additionalServices')?.serviceId}
        />
      )}
    </>
  );
};

export default CarrierAndSalesPrice;
