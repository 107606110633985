import { yupResolver } from '@hookform/resolvers/yup';
import {} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
// import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Toggle from 'src/components/Toggle/Toggle';
import { setNumberWithCommas } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import TruckFindingAiIcon from '../../../../../assets/img/truck-finding-ai-icon.svg';

const truckFindingAiSchema = yup.object().shape({
  enableTruckFinding: yup
    .boolean()
    .required('Please select truck finding ai for this load'),
  enableInboundTruckFinding: yup.boolean(),
  acceptInboundCallDuration: yup
    .string()
    .nullable()
    .when('enableInboundTruckFinding', (enableInboundTruckFinding, schema) =>
      enableInboundTruckFinding[0]
        ? schema
            .required('Accept inbound calls is required.')
            .test(
              'is-greater-than-zero',
              'Accept inbound calls must be greater than 0.',
              (value) => {
                if (!value) return false;
                const parsedValue = parseInt(
                  value.replace(/minutes?/, '').trim(),
                  10
                );

                return !isNaN(parsedValue) && parsedValue > 0;
              }
            )
        : schema.nullable()
    ),
  enableOutboundTruckFinding: yup.boolean(),
  outgoingCallStartAfter: yup
    .string()
    .nullable()
    .when('enableOutboundTruckFinding', (enableOutboundTruckFinding, schema) =>
      enableOutboundTruckFinding[0]
        ? schema
            .required('Start dispatching calls is required.')
            .test(
              'is-greater-than-zero',
              'Start dispatching calls must be greater than 0.',
              (value) => {
                if (!value) return false;
                const parsedValue = parseInt(
                  value.replace(/minutes?/, '').trim(),
                  10
                );

                return !isNaN(parsedValue) && parsedValue > 0;
              }
            )
        : schema.nullable()
    ),
  totalRates: yup
    .string()
    .nullable()
    .when('enableTruckFinding', (enableTruckFinding, schema) =>
      enableTruckFinding[0]
        ? schema
            .required('Total rates is required.')
            .test(
              'is-greater-than-zero',
              'Total rates must be more than 0.',
              (value) => {
                if (!value) return false; // Ensure the field is required
                const parsedValue = parseInt(
                  value.replace(/rates?/, '').trim(),
                  10
                );

                return !isNaN(parsedValue) && parsedValue > 0; // Check it's a valid number and greater than 0
              }
            )
        : schema.nullable()
    ),
  budgetCost: yup.string().nullable(),
});

interface IProps {
  title?: any;
  truckFindingAiDetail?: any;
  postCreatePayload: any;
  rateGatheringWithAi: any;
  handleClose: () => void;
  handleModalSubmit?: (data: any) => void;
}

const setInboundCallDuration = (truckFindingAi: any) => {
  const updatedAt = moment.utc(truckFindingAi.updatedAt, 'YYYY-MM-DD HH:mm:ss');
  const currentGMT = moment().tz('GMT');
  const diffMinutes = currentGMT.diff(updatedAt, 'minutes');
  const remainingTime = truckFindingAi.acceptInboundCallDuration - diffMinutes;

  return `${
    remainingTime >= 0
      ? `${setNumberWithCommas(remainingTime)} minute${
          truckFindingAi.acceptInboundCallDuration == 1 ? '' : 's'
        }`
      : '0 minutes'
  }`;
};

const setOutboundCallDuration = (truckFindingAi: any) => {
  const updatedAt = moment.utc(truckFindingAi.updatedAt, 'YYYY-MM-DD HH:mm:ss');
  const currentGMT = moment().tz('GMT');
  const diffMinutes = currentGMT.diff(updatedAt, 'minutes');
  const remainingTime = truckFindingAi.outgoingCallStartAfter - diffMinutes;

  return `${
    remainingTime >= 0
      ? `${setNumberWithCommas(remainingTime)} minute${
          truckFindingAi.outgoingCallStartAfter == 1 ? '' : 's'
        }`
      : '0 minutes'
  }`;
};

const TruckFindingAi = ({
  title,
  truckFindingAiDetail,
  postCreatePayload,
  rateGatheringWithAi,
  handleClose,
  handleModalSubmit,
}: IProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(truckFindingAiSchema),
    mode: 'onChange',
    defaultValues: {
      enableTruckFinding:
        truckFindingAiDetail && 'enableTruckFinding' in truckFindingAiDetail
          ? truckFindingAiDetail.enableTruckFinding
          : rateGatheringWithAi?.enableTruckFinding || false,
      enableInboundTruckFinding:
        truckFindingAiDetail &&
        'enableInboundTruckFinding' in truckFindingAiDetail
          ? truckFindingAiDetail.enableInboundTruckFinding
          : rateGatheringWithAi?.enableInboundTruckFinding || false,
      acceptInboundCallDuration:
        truckFindingAiDetail &&
        'acceptInboundCallDuration' in truckFindingAiDetail
          ? setInboundCallDuration(truckFindingAiDetail)
          : rateGatheringWithAi &&
            rateGatheringWithAi.acceptInboundCallDuration != null &&
            rateGatheringWithAi.acceptInboundCallDuration >= 0
          ? `${setNumberWithCommas(
              rateGatheringWithAi.acceptInboundCallDuration
            )} minute${
              rateGatheringWithAi.acceptInboundCallDuration == 1 ? '' : 's'
            }`
          : '',
      enableOutboundTruckFinding:
        truckFindingAiDetail &&
        'enableOutboundTruckFinding' in truckFindingAiDetail
          ? truckFindingAiDetail.enableOutboundTruckFinding
          : rateGatheringWithAi?.enableOutboundTruckFinding || false,
      outgoingCallStartAfter:
        truckFindingAiDetail && 'outgoingCallStartAfter' in truckFindingAiDetail
          ? setOutboundCallDuration(truckFindingAiDetail)
          : rateGatheringWithAi &&
            rateGatheringWithAi.outgoingCallStartAfter != null &&
            rateGatheringWithAi.outgoingCallStartAfter >= 0
          ? `${setNumberWithCommas(
              rateGatheringWithAi.outgoingCallStartAfter
            )} minute${
              rateGatheringWithAi.outgoingCallStartAfter == 1 ? '' : 's'
            }`
          : '',
      totalRates:
        truckFindingAiDetail && 'totalRates' in truckFindingAiDetail
          ? truckFindingAiDetail.totalRates
            ? `${setNumberWithCommas(truckFindingAiDetail.totalRates)} rate${
                truckFindingAiDetail.totalRates == 1 ? '' : 's'
              }`
            : '0 rates'
          : rateGatheringWithAi && rateGatheringWithAi.totalRates
          ? `${setNumberWithCommas(rateGatheringWithAi.totalRates)} rate${
              rateGatheringWithAi.totalRates == 1 ? '' : 's'
            }`
          : '',
      budgetCost:
        truckFindingAiDetail && 'budgetCost' in truckFindingAiDetail
          ? `${
              truckFindingAiDetail.budgetCost
                ? `$ ${setNumberWithCommas(truckFindingAiDetail?.budgetCost)}`
                : ''
            }`
          : '',
    },
  });

  const enableTruckFinding = watch('enableTruckFinding');
  const enableInboundTruckFinding = watch('enableInboundTruckFinding');
  const enableOutboundTruckFinding = watch('enableOutboundTruckFinding');

  useEffect(() => {
    if (!enableTruckFinding) trigger('totalRates');
  }, [enableTruckFinding]);

  useEffect(() => {
    if (!enableInboundTruckFinding) trigger('acceptInboundCallDuration');
  }, [enableInboundTruckFinding]);

  useEffect(() => {
    if (!enableOutboundTruckFinding) trigger('outgoingCallStartAfter');
  }, [enableOutboundTruckFinding]);

  const onSubmit = (data: any) => {
    const loadPostingData = {
      ...postCreatePayload,
      truckFindingAiData: {
        enableTruckFinding: data.enableTruckFinding,
        enableInboundTruckFinding: data.enableInboundTruckFinding,
        acceptInboundCallDuration: data.acceptInboundCallDuration
          ? data.acceptInboundCallDuration.replace(/,|minutes?/g, '').trim()
          : null,
        enableOutboundTruckFinding: data.enableOutboundTruckFinding,
        outgoingCallStartAfter: data.outgoingCallStartAfter
          ? data.outgoingCallStartAfter.replace(/,|minutes?/g, '').trim()
          : null,
        totalRates: data.totalRates
          ? data.totalRates.replace(/,|rates?/g, '').trim()
          : null,
        budgetCost: data.budgetCost
          ? data.budgetCost.replace(/[$,\s]/g, '')
          : null,
      },
    };
    console.log('onSubmit loadPostingData', loadPostingData);

    if (handleModalSubmit) {
      handleModalSubmit(loadPostingData);
    }
  };

  const setAcceptInboundCallDuration = (e: any) => {
    setValue(
      'acceptInboundCallDuration',
      e.target.value
        ? `${setNumberWithCommas(parseInt(e.target.value))} minute${
            e.target.value == 1 ? '' : 's'
          }`
        : ''
    );
  };

  const setOutgoingCallStartAfter = (e: any) => {
    setValue(
      'outgoingCallStartAfter',
      e.target.value
        ? `${setNumberWithCommas(parseInt(e.target.value))} minute${
            e.target.value == 1 ? '' : 's'
          }`
        : ''
    );
  };

  const setTotalRates = (e: any) => {
    setValue(
      'totalRates',
      e.target.value
        ? `${setNumberWithCommas(parseInt(e.target.value))} rate${
            e.target.value == 1 ? '' : 's'
          }`
        : ''
    );
  };

  const setBudgetCost = (e: any) => {
    setValue(
      'budgetCost',
      e.target.value ? `$ ${setNumberWithCommas(parseInt(e.target.value))}` : ''
    );
  };

  const [isTruckFindingAiUpdateLoading] = useState(false);
  const headerIcon = (
    <img src={TruckFindingAiIcon} alt="logo" className="sm:w-50 w-30" />
  );

  return (
    <CommonModal
      title={title}
      titleDesc={`Receive and calls with Kate, your AI truck finder`}
      handleClose={handleClose}
      size={'max-w-[730px]'}
      headerIcon={headerIcon}
      primaryBtnText="Confirm"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isTruckFindingAiUpdateLoading}
      primaryBtnDisabled={isTruckFindingAiUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isTruckFindingAiUpdateLoading}
    >
      <ul className="p-5 flex flex-col gap-y-4">
        <li className="rounded-xl border border-utilityGray200 p-4 ">
          <Controller
            name="enableTruckFinding"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle
                name="enableTruckFinding"
                isChecked={!!value}
                onChange={(event: any) => {
                  setValue('enableInboundTruckFinding', false);
                  setValue('acceptInboundCallDuration', '');
                  setValue('enableOutboundTruckFinding', false);
                  setValue('outgoingCallStartAfter', '');
                  setValue('totalRates', '');
                  setValue('budgetCost', '');
                  onChange(event.target.checked);
                }}
                label={
                  'Would you like to enable Rate Gathering AI for this load?'
                }
                labelPosition="left"
                labelClassName="justify-between"
                labelTextClassName="text-sm font-semibold text-textSecondary"
              />
            )}
          />
        </li>

        <li className="rounded-xl border border-utilityGray200 p-4 bg-gray25">
          <Controller
            name="enableInboundTruckFinding"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle
                name="enableInboundTruckFinding"
                isChecked={value}
                isDisabled={!enableTruckFinding}
                onChange={(event: any) => {
                  setValue('acceptInboundCallDuration', '');
                  onChange(event.target.checked);
                }}
                label={'Enable Inbound Truck Finding AI.'}
                labelPosition="left"
                labelClassName="justify-between"
                labelTextClassName="text-textSecondary"
              />
            )}
          />

          <Controller
            name="acceptInboundCallDuration"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="acceptInboundCallDuration"
                inputType="text"
                label="For how many minutes should we accept inbound calls?"
                labelClassName="block mb-0 font-normal"
                inputMainClassName="flex items-center justify-between gap-x-2"
                placeholder="Enter Minutes"
                className="form_control min-w-[120px]"
                parentClassName="rounded-lg border border-utilityGray200 bg-white p-3 mt-2"
                value={value ?? ''}
                required={enableTruckFinding && enableInboundTruckFinding}
                disabled={!enableTruckFinding || !enableInboundTruckFinding}
                onFocus={() => {
                  setValue(
                    'acceptInboundCallDuration',
                    value && value.trim() !== '0 minutes'
                      ? value.replace(/,|minutes?/g, '').trim()
                      : ''
                  );
                }}
                onChangeFunc={(e) => {
                  const val = e.target.value;

                  if (/^\d*$/.test(val)) {
                    onChange(val);
                  }
                }}
                onBlur={setAcceptInboundCallDuration}
                errorText={errors.acceptInboundCallDuration ? true : false}
              />
            )}
          />
        </li>

        <li className="rounded-xl border border-utilityGray200 p-4 bg-gray25">
          <Controller
            name="enableOutboundTruckFinding"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle
                name="enableOutboundTruckFinding"
                isChecked={value}
                isDisabled={!enableTruckFinding}
                onChange={(event: any) => {
                  setValue('outgoingCallStartAfter', '');
                  onChange(event.target.checked);
                }}
                label={'Enable Outbound Truck Finding AI.'}
                labelPosition="left"
                labelClassName="justify-between"
                labelTextClassName="text-textSecondary"
              />
            )}
          />

          <Controller
            name="outgoingCallStartAfter"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="outgoingCallStartAfter"
                inputType="text"
                label="After how many minutes should we start dispatching calls?"
                labelClassName="block mb-0 font-normal"
                inputMainClassName="flex items-center justify-between gap-x-2"
                placeholder="Enter Minutes"
                className="form_control min-w-[120px]"
                parentClassName="rounded-lg border border-utilityGray200 bg-white p-3 mt-2"
                value={value ?? ''}
                required={enableTruckFinding && enableOutboundTruckFinding}
                disabled={!enableTruckFinding || !enableOutboundTruckFinding}
                onFocus={() => {
                  setValue(
                    'outgoingCallStartAfter',
                    value && value.trim() !== '0 minutes'
                      ? value.replace(/,|minutes?/g, '').trim()
                      : ''
                  );
                }}
                onChangeFunc={(e) => {
                  const val = e.target.value;

                  if (/^\d*$/.test(val)) {
                    onChange(val);
                  }
                }}
                onBlur={setOutgoingCallStartAfter}
                errorText={errors.outgoingCallStartAfter ? true : false}
              />
            )}
          />
        </li>
        <li className="rounded-xl border border-utilityGray200 p-4 bg-gray25 flex flex-col gap-y-4">
          <div className="flex items-center">
            <label className="text-textSecondary text-sm mr-2 mb-0 cursor-pointer">
              Additional Instructions
            </label>
          </div>
          <Controller
            name="totalRates"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="totalRates"
                inputType="text"
                label="How many total rates would you like to collect?"
                labelClassName="block mb-0 font-normal"
                placeholder="Enter Rates"
                className="form_control min-w-[120px]"
                parentClassName="rounded-lg border border-utilityGray200 bg-white p-3"
                inputMainClassName="flex items-center justify-between gap-x-2"
                value={value ?? ''}
                required={enableTruckFinding}
                disabled={!enableTruckFinding}
                onFocus={() => {
                  setValue(
                    'totalRates',
                    value && value.trim() !== '0 rates'
                      ? value.replace(/,|rates?/g, '').trim()
                      : ''
                  );
                }}
                onChangeFunc={(e) => {
                  const val = e.target.value;

                  if (/^\d*$/.test(val)) {
                    onChange(val);
                  }
                }}
                onBlur={setTotalRates}
                errorText={errors.totalRates ? true : false}
              />
            )}
          />

          <Controller
            name="budgetCost"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="budgetCost"
                inputType="text"
                label="Whats the maximum you would pay for this load?"
                labelClassName="mb-0 block font-normal"
                placeholder="Enter Budget Cost"
                className="form_control min-w-[120px]"
                parentClassName="rounded-lg border border-utilityGray200 bg-white p-3 "
                inputMainClassName="flex items-center justify-between gap-x-2"
                value={value ?? ''}
                disabled={!enableTruckFinding}
                onFocus={() => {
                  setValue(
                    'budgetCost',
                    value ? value.replace(/[$,\s]/g, '') : ''
                  );
                }}
                onChangeFunc={(e) => {
                  const val = e.target.value;

                  if (/^\d*$/.test(val)) {
                    onChange(val);
                  }
                }}
                onBlur={setBudgetCost}
                errorText={errors.budgetCost ? true : false}
              />
            )}
          />
        </li>
      </ul>
    </CommonModal>
  );
};

export default TruckFindingAi;
