import {
  ArrowRight,
  SearchLg,
  Zap,
  InfoCircle,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import CustomerCreditIncreaseModal from 'src/app/CreditDashboard/CreditIncreaseRequest/CustomerCreditIncreaseModal';
import EnableCustomerOnCredit from 'src/app/CreditDashboard/CreditIncreaseRequest/EnableCustomerOnCredit';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  customerCreditOrdersList,
  customerCreditOrdersListCount,
  getCustomerCreditDetails,
} from 'src/services/CustomerService';
import {
  getFormattedNumber,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import emptyGraph from '../../../assets/img/credit-empty-graph.webp';
import logomark from '../../../assets/img/Logomark.svg';
import RequestCreditIncreaseModal from '../../CreditDashboard/CreditIncreaseRequest/RequestCreditIncreaseModal';

import CreditIndexChart from './CreditIndexChart';
import CreditTabOrderLoadingRaw from './CreditTabOrderLoadingRaw';
import CreditTabOrderRaw from './CreditTabOrderRaw';
import FindAndLinkCompanyToCreditSafeModal from './FindAndLinkCompanyToCreditSafeModal';

const initParams: any = {
  sortType: 'desc',
  sortField: 'orderCreatedAt',
  page: 1,
  limit: 25,
  search: '',
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

function getBoostExpirationTooltip(expirationDate: any) {
  const now = moment();
  const nowInUTC = now.utc();
  const expiration = moment.utc(expirationDate, 'YYYY-MM-DD HH:mm:ss');
  const duration = moment.duration(expiration.diff(nowInUTC));

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  if (duration.asMilliseconds() <= 0) {
    return 'Temporary boost has expired';
  }

  let tooltipMessage = '';

  if (hours > 0) {
    tooltipMessage = `Temporary boost expires in ${hours} hour${
      hours > 1 ? 's' : ''
    }`;

    if (minutes > 0) {
      tooltipMessage += ` and ${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  } else if (minutes > 0) {
    tooltipMessage = `Temporary boost expires in ${minutes} minute${
      minutes > 1 ? 's' : ''
    }`;
  }

  return tooltipMessage;
}

interface IProps {
  customerId: string;
  customerName: string;
}

const CustomerDetailCreditTab = ({ customerId, customerName }: IProps) => {
  const { hasRoleV2 } = useRolePermission();
  const [isShowReqCreditModal, setIsShowReqCreditModal] = useState(false);
  const [params, setParams] = useState(initParams);
  const [orders, setOrders] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isCustomerCreditOrdersLoading, setIsCustomerCreditOrdersLoading] =
    useState(true);
  const [isCustomerCreditDetailLoading, setIsCustomerCreditDetailLoading] =
    useState(true);
  const [customerCreditDetail, setCustomerCreditDetail] =
    useState<any>(undefined);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshCreditDetail, setIsRefreshCreditDetail] = useState(true);
  const [
    isShowLinkCustomerToCreditSafeModal,
    setIsShowLinkCustomerToCreditSafeModal,
  ] = useState(false);
  const [isShowEnableCustomerCreditModal, setIsShowEnableCustomerCreditModal] =
    useState(false);
  const [isShowCreditLimitIncreaseModal, setIsShowCreditLimitIncreaseModal] =
    useState(false);

  useEffect(() => {
    setIsRefresh(true);
  }, [params]);

  useEffect(() => {
    if (!isRefresh) {
      return;
    }
    setIsCustomerCreditOrdersLoading(true);
    setOrders([]);

    const creditOrdersApiController = new AbortController();
    const creditOrdersApiSignal = creditOrdersApiController.signal;

    Promise.all([
      customerCreditOrdersList(customerId, params, creditOrdersApiSignal),
      customerCreditOrdersListCount(customerId, params, creditOrdersApiSignal),
    ])
      .then(([responseData, responseCount]: [any, any]) => {
        setOrders(responseData.data ?? []);
        setTotal(responseCount.total ?? 0);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
      })
      .finally(() => {
        setIsCustomerCreditOrdersLoading(false);
        setIsRefresh(false);
      });

    return () => {
      creditOrdersApiController.abort();
    };
  }, [isRefresh]);

  useEffect(() => {
    if (!isRefreshCreditDetail) {
      return;
    }
    getCustomerCreditDetails(customerId)
      .then((response: any) => {
        let cusCreditDetails = { ...response?.data, creditUsedPercentage: 0 };

        if (
          cusCreditDetails?.creditLimit &&
          cusCreditDetails?.currentAccountReceivable
        ) {
          cusCreditDetails.creditUsedPercentage = (
            (cusCreditDetails?.currentAccountReceivable * 100) /
            cusCreditDetails?.creditLimit
          ).toFixed(2);
        }
        setCustomerCreditDetail({ ...cusCreditDetails });
      })
      .catch((e: any) => {
        console.log(e);
        WalToast.error(
          e?.response?.data?.message ??
            'Something went wrong fetching customer credit details.'
        );
      })
      .finally(() => {
        setIsCustomerCreditDetailLoading(false);
        setIsRefreshCreditDetail(false);
      });
  }, [isRefreshCreditDetail]);

  const headCells = useMemo(
    () => [
      {
        id: 'fullId',
        name: 'Order ID',
        sortable: true,
      },
      {
        id: 'orderStatus',
        name: 'Status',
        sortable: true,
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Price',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      {customerCreditDetail?.creditStatus === 'on_hold' && (
        <div className="rounded-md border border-borderSecondary bg-utilityindigo25 px-3 py-2 flex items-center gap-x-1 mb-3">
          <InfoCircle className="w-4 h-4" />
          <div className="text-textSecondary text-xs font-normal">
            <p className="text-grayLight900 font-semibold inline-block">
              Account On Hold
            </p>
            &nbsp;This customers account has been set to on hold.
          </div>
        </div>
      )}
      {customerCreditDetail?.creditStatus === 'pre_pay' && (
        <div className="rounded-md border border-borderSecondary bg-utilityindigo25 px-3 py-2 flex items-center gap-x-1 mb-3">
          <InfoCircle className="w-4 h-4" />
          <div className="text-textSecondary text-xs font-normal">
            <p className="text-grayLight900 font-semibold inline-block">
              Prepay Account
            </p>
            &nbsp;Enable credit to activate direct payment.
          </div>
        </div>
      )}
      <ul className="flex flex-wrap">
        <li className="flex-1 mr-2.5 rounded-lg border border-utilityGray200 shadow-md relative">
          <div className="p-3 pb-8">
            <div className="flex justify-between items-center">
              <div className="flex gap-x-1 flex-wrap">
                <h6 className="grayLight900 text-sm font-semibold">
                  Accounts Receivable
                </h6>
                {customerCreditDetail?.creditStatus === 'on_hold' ? (
                  <BadgeCmp style="modern" type="error">
                    On hold
                  </BadgeCmp>
                ) : customerCreditDetail?.creditStatus === 'pre_pay' ? (
                  <BadgeCmp style="modern" type="pink">
                    Pre pay
                  </BadgeCmp>
                ) : (
                  <BadgeCmp style="modern" type="success">
                    Active
                  </BadgeCmp>
                )}
              </div>

              <div>
                {customerCreditDetail?.creditStatus === 'pre_pay' ? (
                  <a
                    className="text-primary text-xs font-semibold flex items-center cursor-pointer"
                    onClick={() => setIsShowEnableCustomerCreditModal(true)}
                  >
                    <span className="pr-0.5">Enable Credit</span>
                    <ArrowRight className="w-4 h-4" />
                  </a>
                ) : (
                  <>
                    {hasRoleV2('admin') ||
                    hasRoleV2('manager') ||
                    hasRoleV2('finance') ? (
                      <a
                        className="text-primary text-xs font-semibold flex items-center cursor-pointer"
                        onClick={() => setIsShowCreditLimitIncreaseModal(true)}
                      >
                        <Plus className="w-4 h-4" />
                        <span className="pr-0.5">Increase Credit Limit</span>
                      </a>
                    ) : (
                      <a
                        className="text-primary text-xs font-semibold flex items-center cursor-pointer"
                        onClick={() => setIsShowReqCreditModal(true)}
                      >
                        <span className="pr-0.5">Request Credit Increase</span>
                        <Zap className="w-4 h-4" />
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-between mb-1 mt-6">
              <p className="text-grayLight900 text-sm font-medium">$0</p>
              <p
                className={`text-grayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {customerCreditDetail?.creditStatus === 'pre_pay'
                  ? ''
                  : `$${
                      customerCreditDetail?.boostedCreditLimit
                        ? getFormattedNumber(
                            (customerCreditDetail?.creditLimit ?? 0) +
                              (customerCreditDetail?.boostedCreditLimit ?? 0)
                          )
                        : getFormattedNumber(
                            customerCreditDetail?.creditLimit ?? 0
                          )
                    }`}
              </p>
            </div>
            <div className="progress-range-container">
              <div
                className="progress-range-bar"
                style={{
                  width: `${customerCreditDetail?.creditUsedPercentage ?? 0}%`,
                }}
              >
                <div className="progress-range-thumb left-[100%]">
                  {customerCreditDetail?.creditUsedPercentage &&
                  customerCreditDetail?.creditStatus === 'active' ? (
                    <div
                      className={`absolute left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white  text-xs font-semibold top-full mt-3 tooltip-custom-top-arrow `}
                    >
                      {customerCreditDetail?.creditUsedPercentage}%
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
          <ul className="border-t border-utilityGray200 [&>li:not(:last-child)]:border-r [&>li:not(:last-child)]:border-utilityGray200 flex">
            <li className="px-2 py-4 w-1/3">
              <p className="text-grayLight600 xxl:text-sm text-xs font-normal mb-0.5">
                Current Accounts Receivable
              </p>
              <span
                className={`textgrayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {customerCreditDetail?.creditStatus === 'pre_pay'
                  ? '-'
                  : `$${getFormattedNumber(
                      customerCreditDetail?.currentAccountReceivable ?? 0
                    )}`}
              </span>
            </li>

            <li className="px-2 py-4 w-1/3">
              <p className="text-grayLight600 xxl:text-sm text-xs font-normal mb-0.5">
                Credit Utilization
              </p>
              <span
                className={`textgrayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {customerCreditDetail?.creditStatus === 'pre_pay'
                  ? '-'
                  : `${customerCreditDetail?.creditUsedPercentage ?? 0}%`}
              </span>
            </li>
            <li className="px-2 py-4 w-1/3">
              <p className="text-grayLight600 xxl:text-sm text-xs font-normal mb-0.5 flex">
                Total Credit Limit{' '}
                {customerCreditDetail?.boostedCreditLimit && (
                  <TooltipCmp
                    message={getBoostExpirationTooltip(
                      customerCreditDetail?.boostedLimitExpirationDate
                    )}
                  >
                    <HiOutlineExclamationCircle className="text-grayText mt-0.5 ml-1" />
                  </TooltipCmp>
                )}
              </p>
              <span
                className={`textgrayLight900 text-sm font-medium ${
                  isCustomerCreditDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {customerCreditDetail?.creditStatus === 'pre_pay' ? (
                  '-'
                ) : (
                  <>
                    {`$${getFormattedNumber(
                      customerCreditDetail?.creditLimit ?? 0
                    )}`}
                    {customerCreditDetail?.boostedCreditLimit && (
                      <>
                        {` + $${getFormattedNumber(
                          customerCreditDetail?.boostedCreditLimit ?? 0
                        )} `}
                      </>
                    )}
                  </>
                )}
              </span>
            </li>
          </ul>
        </li>
        <li className="xxl:w-[360px] w-72 rounded-lg border border-utilityGray200 shadow-md p-3">
          {isCustomerCreditDetailLoading ? (
            <div className="custom-loading rounded-2xl h-full">
              <CreditIndexChart customerCreditDetail={{}} />
            </div>
          ) : (
            <>
              {customerCreditDetail.creditValue ? (
                <>
                  <div className="flex justify-between items-center mb-2 z-[1] relative">
                    <h6 className="grayLight900 text-sm font-semibold">
                      Credit Index
                    </h6>
                    <div className="hover-text-wrap">
                      <div className="hover-text overflow-hidden border border-utilityGray200 rounded cursor-pointer flex items-center ">
                        <img src={logomark} />
                        <span className="font-semibold text-[#0d442e] text-xs leading-[20px] transition-all duration-[0.5s] w-0 opacity-0 creditsafe-text text-nowrap ">
                          By Creditsafe
                        </span>
                      </div>
                    </div>
                  </div>
                  <CreditIndexChart
                    customerCreditDetail={customerCreditDetail}
                  />
                </>
              ) : (
                <>
                  <div className="flex justify-between items-center mb-2 z-[1] relative">
                    <h6 className="grayLight900 text-sm font-semibold">
                      Credit Index
                    </h6>
                    <p
                      className="text-primary text-xs font-semibold flex items-center cursor-pointer"
                      onClick={() =>
                        setIsShowLinkCustomerToCreditSafeModal(true)
                      }
                    >
                      <Plus className="w-4 h-4" />
                      <span className="pl-1.5">Link Credit Profile</span>
                    </p>
                  </div>
                  <figure className="w-full flex items-center justify-center">
                    <img src={emptyGraph} className="xxl:w-60 w-52 mt-3" />
                  </figure>
                </>
              )}
            </>
          )}
        </li>
      </ul>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mt-6 overflow-hidden">
        <div className="w-full flex-col flex">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap ">
              <div className="table-title-wrap">
                <h5 className="table-title">Active Orders</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${
                    isCustomerCreditOrdersLoading ? 'custom-loading' : ''
                  }`}
                >
                  {total} {total > 1 ? 'Orders' : 'Order'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and manage your sales representative
              </p>
            </div>
            <div className="table-right-wrap max-mdm:w-full">
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={params.search}
                isClearable={true}
                parentClassName="table-searchInput max-mdm:flex-1"
                inputType="text"
                onChangeFunc={(e) =>
                  setParams((old: any) => ({
                    ...old,
                    ...{ search: e.target.value, page: 1 },
                  }))
                }
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === params.limit
                  )}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
            <TableCmp
              params={params}
              setParams={setParams}
              tableDataArr={orders}
              headCells={headCells}
              TableLoaderRowCmp={CreditTabOrderLoadingRaw}
              TableRowCmp={CreditTabOrderRaw}
              numberOfSkeletonRows={15}
              isTableDataLoading={isCustomerCreditOrdersLoading}
            />
          </div>
          {!isCustomerCreditOrdersLoading && !orders?.length && (
            <NotFoundUI
              title="No credit orders found"
              desc={`There are no credit orders found for ${customerName}.`}
              containerClassName="min-h-[160px] !h-auto"
            />
          )}
          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={total}
            currentPage={params.page}
            handlePagination={(page: number) => {
              setParams((old: any) => ({ ...old, page }));
            }}
          />
        </div>
      </div>

      {isShowReqCreditModal && (
        <RequestCreditIncreaseModal
          customerId={customerId}
          currentCreditLimit={customerCreditDetail?.creditLimit}
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefreshCreditDetail(true);
              setIsRefresh(true);
            }
            setIsShowReqCreditModal(false);
          }}
        />
      )}

      {isShowEnableCustomerCreditModal && (
        <EnableCustomerOnCredit
          customerId={customerId}
          customerCreditDetail={customerCreditDetail}
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefreshCreditDetail(true);
              setIsRefresh(true);
            }
            setIsShowEnableCustomerCreditModal(false);
          }}
        />
      )}

      {isShowCreditLimitIncreaseModal && (
        <CustomerCreditIncreaseModal
          customerId={customerId}
          customerCreditDetail={customerCreditDetail}
          handleClose={(status: boolean) => {
            if (status) {
              setIsRefreshCreditDetail(true);
              setIsRefresh(true);
            }
            setIsShowCreditLimitIncreaseModal(false);
          }}
        />
      )}

      {isShowLinkCustomerToCreditSafeModal && (
        <FindAndLinkCompanyToCreditSafeModal
          customerId={customerId}
          setIsRefreshCreditDetail={setIsRefreshCreditDetail}
          handleClose={() => setIsShowLinkCustomerToCreditSafeModal(false)}
          setIsRefresh={setIsRefresh}
        />
      )}
    </>
  );
};

export default CustomerDetailCreditTab;
