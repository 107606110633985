import { PieChart03 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IProps {
  handleClose: () => void;
  customerList: any[];
}

const recordsPerPageArr = [
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '300', value: 300 },
  { label: '400', value: 400 },
  { label: '500', value: 500 },
];

const CustomerSplitPieChartModal = ({ handleClose, customerList }: IProps) => {
  const [recordsPerPage, setRecordsPerPage] = useState(100);

  const pieChartConfigOptions = {
    chart: {
      type: 'donut',
    },
    legend: {
      show: false, // This line hides the legend
    },
    colors: [
      '#000080',
      '#00008B',
      '#0000CD',
      '#0000FF',
      '#000f89',
      '#0018A8',
      '#002147',
      '#002244',
      '#002244',
      '#002244',
      '#002244',
      '#002387',
      '#002D62',
      '#002D72',
      '#002fa7',
      '#00308F',
      '#003153',
      '#003399',
      '#00356B',
      '#0039a6',
      '#00416A',
      '#004170',
      '#00428c',
      '#004687',
      '#004792',
      '#0047AB',
      '#00538C',
      '#005A9C',
      '#005f69',
      '#0066b2',
      '#0070BB',
      '#0071c5',
      '#0076CE',
      '#007791',
      '#0077c0',
      '#007FFF',
      '#008080',
      '#008E97',
      '#0093AF',
      '#00BFFF',
      '#00BFFF',
      '#00CCCC',
      '#00CCFF',
      '#00CED1',
      '#00FFFF',
      '#00FFFF',
      '#011F5B',
      '#012169',
      '#034694',
      '#041E42',
      '#0530ad',
      '#073980',
      '#082567',
      '#0a2351',
      '#0C2340',
      '#0CAFFF',
      '#0E3386',
      '#1034A6',
      '#120A8F',
      '#132257',
      '#13274F',
      '#1560bd',
      '#15f4ee',
      '#1877F2',
      '#191970',
      '#1a1f71',
      '#1C39BB',
      '#1ca9c9',
      '#1D428A',
      '#1da1f2',
      '#1E2952',
      '#1E90FF',
      '#1F305E',
      '#1F75FE',
      '#224C98',
      '#2774AE',
      '#2a52be',
      '#2c3968',
      '#2D68C4',
      '#2E2787',
      '#2E5090',
      '#318CE7',
      '#324AB2',
      '#3457D5',
      '#367588',
      '#3E8EDE',
      '#3F00FF',
      '#40E0D0',
      '#4169E1',
      '#4682B4',
      '#48D1CC',
      '#4B61D1',
      '#4B9CD3',
      '#4C516D',
      '#4D4DFF',
      '#5072A7',
      '#545AA7',
      '#5A4FCF',
      '#5D76A9',
      '#5D8AA8',
      '#5F9EA0',
      '#6050DC',
      '#6082B6',
      '#6495ED',
      '#6699CC',
      '#6A5ACD',
      '#6CB4EE',
      '#6F00FF',
      '#72A0C1',
      '#73C2FB',
      '#76ABDF',
      '#7B68EE',
      '#7CB9E8',
      '#7DF9FF',
      '#7FFFD4',
      '#81D8D0',
      '#87CEEB',
      '#87CEFA',
      '#89CFF0',
      '#8DA399',
      '#99FFFF',
      '#A3C1AD',
      '#A4DDED',
      '#ADD8E6',
      '#AFDBF5',
      '#AFEEEE',
      '#B0C4DE',
      '#B0E0E6',
      '#B2FFFF',
      '#B9D9EB',
      '#CCCCFF',
      '#E0FFFF',
      '#E1EBEE',
      '#F0F8FF',
    ],
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    tooltip: {
      theme: 'dark',
      enabled: true,
      position: 'bottomLeft',
      offsetX: 0,
      offsetY: 0,
      custom: function ({ series, seriesIndex, w }: any) {
        const currentCustomerRec = customerList.find(
          (clData) => clData.name === w.globals.labels[seriesIndex]
        );

        let grossProfit = getFormattedNumber(currentCustomerRec?.profit ?? 0);
        let grossProfitPercentage = currentCustomerRec?.profitPercentage ?? 0;

        if (
          currentCustomerRec === undefined &&
          w.globals.labels[seriesIndex] === 'Others'
        ) {
          const remainingOtherCustomers = customerList.slice(
            recordsPerPage,
            undefined
          );

          grossProfit = remainingOtherCustomers.reduce(
            (accumulator, current) => accumulator + current.profit,
            0
          );

          grossProfit = getFormattedNumber(Number(grossProfit).toFixed(2) ?? 0);

          grossProfitPercentage = remainingOtherCustomers.reduce(
            (accumulator, current) => accumulator + current.profitPercentage,
            0
          );

          grossProfitPercentage = (
            grossProfitPercentage / (remainingOtherCustomers.length ?? 1)
          ).toFixed(2);
        }

        let tooltipContent = `
					<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; padding: 2px 4px;">
						${w.globals.labels[seriesIndex]}
					</div>

                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    	<div style="padding: 4px 0 2px">
                    		<span class="apexcharts-tooltip-text-y-label">Gross Profit: </span>
                    		<span class="apexcharts-tooltip-text-y-value">$${parseFloat(
                          grossProfit || '0'
                        ).toFixed(2)}</span>
                    	</div>
                    </div>

                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    	<div style="padding: 4px 0 2px">
                    		<span class="apexcharts-tooltip-text-y-label">Gross Profit %: </span>
                    		<span class="apexcharts-tooltip-text-y-value"> ${parseFloat(
                          grossProfitPercentage || '0'
                        ).toFixed(2)}%</span>
                    	</div>
                    </div>

                    <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    	<div style="padding: 4px 0 2px">
                    		<span class="apexcharts-tooltip-text-y-label">Weight: </span>
                    		<span class="apexcharts-tooltip-text-y-value"> ${parseFloat(
                          series[seriesIndex] || '0'
                        ).toFixed(2)}%</span>
                    	</div>
                    </div>
				`;

        return tooltipContent;
      },
    },
    dataLabels: {
      enabled: false, // This line disables the percentage display
    },
  };

  const [chartOptions, setChartOptions] = useState<any>({
    options: pieChartConfigOptions,
    series: [],
  });

  useEffect(() => {
    customerList.sort((a, b) => b.change - a.change);
    const firstSelectedCustomers = customerList.slice(0, recordsPerPage);

    let series = firstSelectedCustomers.map(
      (customerListItem) => customerListItem.change
    );
    let labels = firstSelectedCustomers.map(
      (customerListItem) => customerListItem.name
    );

    const remainingOtherCustomers = customerList.slice(
      recordsPerPage,
      undefined
    );

    if (remainingOtherCustomers.length > 0) {
      const totalOthersChangeTotal = remainingOtherCustomers.reduce(
        (accumulator, current) => accumulator + current.change,
        0
      );

      series.push(Number(totalOthersChangeTotal.toFixed(2)));
      labels.push('Others');
    }

    setChartOptions({ options: { ...pieChartConfigOptions, labels }, series });
  }, [customerList, recordsPerPage]);

  return (
    <CommonModal
      title="Customer Split Chart"
      titleDesc="These companies have purchased in the last 12 months."
      handleClose={handleClose}
      headerIcon={<PieChart03 className="text-gray-900" />}
      headerIconClass="bg-white"
      size={'max-w-[960px]'}
    >
      <div className="w-full px-6 pt-5 flex-col gap-4 inline-flex pb-6">
        <div className="w-24">
          <SelectBox
            name="recordsPerPageGroup"
            id="recordsPerPageGroup"
            className="form_control shadow"
            size="sm"
            options={recordsPerPageArr}
            onChangeFunc={(event: any) => {
              setRecordsPerPage(event.value);
            }}
            isSearchable={false}
            value={recordsPerPageArr.find(
              (val: any) => val.value === recordsPerPage
            )}
          />
        </div>
        <div className="flex flex-wrap">
          <div className="lg:w-[70%] md:w-3/5 w-full sm:h-[494px] smd:h-[430px] h-[200px] sm:mb-0 mb-4 flex justify-center items-center smd:p-0 pt-10">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="donut"
              style={{ width: '70%', height: '70%' }}
            />
          </div>
          <div className="lg:w-[30%] md:w-2/5 w-full">
            <div className="sms:h-[440px] h-[350px] w-full rounded-xl shadow border border-gray-200 justify-start items-start gap-5 inline-flex overflow-hidden">
              <div className="h-full w-full flex flex-col gap-1.5 p-4 overflow-auto custom-scrollbar-v2">
                {chartOptions.options.labels.map(
                  (label: any, index: number) => (
                    <div
                      className="gap-2 flex w-full"
                      key={`${label}_${index}`}
                    >
                      <div
                        className={`w-2 h-2 rounded-full flex-none mt-[5px]`}
                        style={{
                          backgroundColor: chartOptions.options.colors[index],
                        }}
                      />
                      <div className="text-slate-600 text-sm font-normal leading-tight">
                        {label}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerSplitPieChartModal;
