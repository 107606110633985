import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

import defaultimage from '../../../assets/img/default-image.jpg';

import CustomerGroupDetailRightSideSectionCard from './CustomerGroupDetailRightSideSectionCard';

interface IProps {
  customerGroupDetail: any;
  isCustomerGroupDetailLoading: boolean;
}

const CustomerGroupDetailRightSideSection = ({
  customerGroupDetail,
  isCustomerGroupDetailLoading,
}: IProps) => (
  <>
    <h6 className="text-grayLight900 font-medium text-sm flex items-center gap-2">
      <span>Customers </span>
      <BadgeCmp
        style="modern"
        isHidePillDot
        mainClassName={`${
          isCustomerGroupDetailLoading ? 'custom-loading w-4 h-4' : ''
        }`}
      >
        {customerGroupDetail?.customersCount}
      </BadgeCmp>
    </h6>
    <div>
      {isCustomerGroupDetailLoading ? (
        <div className="rounded-xl border border-utilityGray200 p-3 shadow-none transition-all duration-[0.3s] hover:shadow-md cursor-pointer mb-4">
          <div className="">
            <div className="flex gap-1.5">
              <div className="w-8 h-8 rounded-md custom-loading">
                <img
                  src={defaultimage}
                  className="w-8 h-8 rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                />
              </div>
              <div>
                <h6 className="text-grayLight900 text-xs font-semibold mb-0.5 custom-loading">
                  HC Companies
                </h6>
                <p className="text-grayLight600 text-xs font-normal custom-loading">
                  2425 Boul Edouard-Michelin, Terrebonne, CA
                </p>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 empty:mt-0 mt-4">
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-yellow300"
                mainClassName="custom-loading"
              >
                Test
              </BadgeCmp>

              <BadgeCmp
                style="modern"
                badgeDotColor="bg-[#B2AC17]"
                mainClassName="custom-loading"
              >
                Top
              </BadgeCmp>

              <BadgeCmp
                style="modern"
                badgeDotColor="bg-utilityPink600"
                mainClassName="custom-loading"
              >
                Dormat
              </BadgeCmp>

              <BadgeCmp
                style="modern"
                type="lightblue"
                mainClassName="custom-loading"
              >
                Prospect
              </BadgeCmp>

              <BadgeCmp
                style="modern"
                badgeDotColor="bg-fgSuccessPrimary"
                mainClassName="custom-loading"
              >
                New
              </BadgeCmp>

              <BadgeCmp
                style="modern"
                type="primary"
                mainClassName="custom-loading"
              >
                Active
              </BadgeCmp>
            </div>
          </div>
        </div>
      ) : (
        customerGroupDetail?.customers.map((groupCustomer: any) => (
          <CustomerGroupDetailRightSideSectionCard
            key={groupCustomer?.id}
            groupCustomer={groupCustomer}
          />
        ))
      )}
    </div>
  </>
);

export default CustomerGroupDetailRightSideSection;
