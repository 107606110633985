import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const WareHouseLoaderRow = () => (
  <>
    <td className="px-5 py-4 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight900 font-medium truncate custom-loading h-8 flex items-center">
        Warehouses - A
      </div>
    </td>
    <td className="px-5 py-4 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="flex items-center gap-3">
        <div className="flex-none">
          <div className="table-profile-customer-circle-xl custom-loading"></div>
        </div>
        <div className="truncate w-full">
          <h6 className="text-grayLight900 font-medium truncate custom-loading">
            {'Olivia Rhye'}
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate custom-loading">
        901 Massachusetts Ave NW
      </div>
    </td>
    <td className="px-5 py-4 text-grayLight600 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate custom-loading">
        Washington
      </div>
    </td>
    <td className="px-5 py-4 text-grayLight600 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate custom-loading">
        District of Columbia
      </div>
    </td>
    <td className="px-5 py-4 text-grayLight600 w-[16.66%] min-w-[16.66%] max-w-[1px]">
      <div className="text-grayLight600 truncate custom-loading">
        United States
      </div>
    </td>
    <td className="px-5 py-4 w-14 max-w-14 min-w-14">
      <div className="custom-loading">
        <Edit01 className="cursor-pointer w-4 h-4 text-grayLight600" />
      </div>
    </td>
  </>
);

export default WareHouseLoaderRow;
