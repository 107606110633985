import { yupResolver } from '@hookform/resolvers/yup';
import { Edit05 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { inviteCustomerContact } from 'src/services/CustomerService';
import * as yup from 'yup';

const roleOptions = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
];

const ContactsInvitationModal = ({ contactData, handleClose }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    contactRole: yup.string().required('Role is required.').nullable(),
    contactEmail: yup
      .string()
      .required('Email is required.')
      .test({
        message: 'Invalid email address',
        test: function (value) {
          if (value !== '') {
            const emailRegex =
              /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

            return emailRegex.test(value ?? '');
          }

          return true;
        },
      })
      .nullable(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      contactEmail: contactData?.contactEmail,
      contactRole: roleOptions[0]?.value,
    },
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);

    inviteCustomerContact(contactData?.id, data)
      .then((response: any) => {
        console.log('response', response);
        setIsLoading(false);
        handleClose(true);
      })
      .catch(console.log)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <CommonModal
        title={'Invite to Customer Portal'}
        titleDesc={
          'Invite customers to access their portal for a seamless experience.'
        }
        handleClose={() => handleClose(false)}
        headerIcon={<Edit05 />}
        size={'xl:max-w-[647px] max-w-[551px] '}
        modalClassName=""
        primaryBtnText="Save Changes"
        primaryBtnOnClick={handleSubmit(onSubmit)}
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={isLoading}
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={() => handleClose(false)}
      >
        <div className="w-full p-5 flex flex-col gap-4">
          <div className="flex max-sm:flex-col -mx-1.5">
            <Controller
              name="contactEmail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="contactEmail"
                  label="Email"
                  labelClassName="block mb-1.5"
                  placeholder="Enter Email"
                  parentClassName="sm:w-1/2 px-1.5"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={errors.email ? errors.email.message : ''}
                />
              )}
            />
            <Controller
              name="contactRole"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="contactRole"
                  placeholder="Select role"
                  label="Role"
                  parentClassName="flex-1 md:max-w-[calc(100%_-_160px)]"
                  labelClassName="form_label"
                  value={
                    value
                      ? roleOptions?.find((val: any) => val?.value === value)
                      : ''
                  }
                  options={roleOptions}
                  onChangeFunc={(e: any) => {
                    onChange(e?.label);
                  }}
                  errorText={errors?.contactRole?.message ?? null}
                  isClearable
                />
              )}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default ContactsInvitationModal;
