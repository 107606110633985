import { XClose } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const SalesRepCommissionReportLoadingRow = () => (
  <>
    <td className="px-5 py-4 w-52 max-w-52 min-w-52">
      <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 custom-loading">
        #WAL-MU3A-111
      </h6>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div className="flex gap-1 flex-wrap">
        <BadgeCmp style="modern" type="pink" mainClassName="custom-loading">
          invoice-created
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 w-[17.78%] max-w-[17.78%] min-w-[17.78%]">
      <span className="custom-loading">
        Aug 23<sup>rd</sup> - 2024, 05:30 AM
      </span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span className="custom-loading">$800</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span className="custom-loading">$800</span>
    </td>
    <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <span className="custom-loading">$800</span>
    </td>
    <td className="px-5 py-4 w-52 max-w-52 min-w-52">
      <p className="custom-loading truncate">Customer name</p>
    </td>
    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="justify-end items-center gap-3 flex custom-loading">
        <XClose className="w-4 h-4 text-fgErrorPrimary" />
      </div>
    </td>
  </>
);

export default SalesRepCommissionReportLoadingRow;
