import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';

import CustomerDetailClaimsTab from './CustomerDetailClaimsTab';
import CustomerDetailDisputesTab from './CustomerDetailDisputesTab';

interface IProps {
  customerId: string;
  customerName: string;
}

const FilterArray: any = [
  {
    value: 'cusotmer-claims',
    name: 'Customer Claims',
  },
  {
    value: 'carrier-dispute',
    name: 'Carrier Disputes',
  },
];

const recordsPerPageArray = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const filterOptions: any = [
  {
    value: 'New',
    label: 'New',
  },
  {
    value: 'Opened',
    label: 'Opened',
  },
  {
    value: 'Resolved',
    label: 'Resolved',
  },
  {
    value: 'Closed',
    label: 'Closed',
  },
];

const CustomerDetailClaimsAndDisputesTab = ({
  customerId,
  customerName,
}: IProps) => {
  const [activeTab, setActiveTab] = useState('cusotmer-claims');

  const initParams: any = {
    search: '',
    sortType: 'desc',
    sortField: 'createdAt',
    page: 1,
    limit: 10,
    filter: {
      status: 'all',
      customerId: customerId,
    },
  };

  const [params, setParams] = useState(initParams);
  const [search, setSearch] = useState('');

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchClaimAndDispute') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  return (
    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
      <div className="w-full flex-col flex">
        <div className="table-bottom-header border-b border-utilityGray200">
          <div className="table-header-bottom-left flex">
            <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
              <TabButton
                className=""
                tabArray={FilterArray}
                activeClassName="!bg-utilityGray100"
                isActive={activeTab}
                handleOnClick={(e: any) => {
                  setParams((old: any) => ({
                    ...old,
                    sortType: 'desc',
                    sortField: 'createdAt',
                    page: 1,
                  }));
                  setActiveTab(e?.target?.dataset?.value);
                }}
              />
            </div>
            <InputText
              inputName="searchClaimAndDispute"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              parentClassName="table-searchInput"
              onChangeFunc={handleFilter}
              isClearable={true}
            />
          </div>

          <div className="table-selectbox xlm:block hidden">
            <SelectBox
              name="claim&disputeStatus"
              id="claim&disputeStatus"
              className="form_control"
              size="sm"
              placeholder="Filter by status"
              noOptionMessage="No Status Found"
              isClearable={true}
              isSearchable={true}
              options={filterOptions}
              onChangeFunc={(event: any) =>
                setParams((old: any) => ({
                  ...old,
                  filter: {
                    ...old.filter,
                    status: event?.value ?? '',
                  },
                  sortType: 'desc',
                  sortField: 'createdAt',
                  page: 1,
                }))
              }
              value={filterOptions.filter(
                (val: any) => params.filter.status === val.value
              )}
            />
          </div>
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              options={recordsPerPageArray}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>

        <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
          {activeTab === 'cusotmer-claims' && (
            <CustomerDetailClaimsTab
              params={params}
              setParams={setParams}
              customerName={customerName}
            />
          )}

          {activeTab === 'carrier-dispute' && (
            <CustomerDetailDisputesTab
              params={params}
              setParams={setParams}
              customerName={customerName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailClaimsAndDisputesTab;
