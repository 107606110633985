import {
  Edit05,
  Mail01,
  Plus,
  SwitchHorizontal01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useMemo } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getFormattedDate } from 'src/utils/CommonFunctions';

interface IProps {
  localLegData: any;
  setAction: any;
  handleDeleteAppointment: any;
  setEditMode: any;
  isShowDraftEmail: any;
  isLoading: any;
  fromRadar?: boolean;
  activeAppointmentTab?: any;
}

interface AppointmentProps {
  title: any;
  status: any;
  description: any;
  appointmentDatetime: any;
  fromRadar: any;
  isLoading: any;
  setAction: any;
  setEditMode: any;
  handleDeleteAppointment: any;
  isShowDraftEmail: any;
  appointmentType: any;
  localLegData: any;
}

const AppointmentSection = ({
  title,
  status,
  description,
  appointmentDatetime,
  fromRadar,
  isLoading,
  setAction,
  setEditMode,
  handleDeleteAppointment,
  appointmentType,
  localLegData,
}: AppointmentProps) => {
  const isAppointmentSet = Boolean(appointmentDatetime);

  const handleEdit = (type: string, details: any) => {
    setEditMode({ type, details });
    setAction((old: any) => ({
      ...old,
      mode: type,
    }));
  };

  const hasAppointmentConfirmed =
    localLegData?.[status] === 'Confirmed' ? true : false;

  return (
    <>
      {!isAppointmentSet ? (
        <div className={`xxl:p-1.5 p-1 ${fromRadar ? 'w-full' : 'sm:flex-1'}`}>
          <h6 className="form_label block mb-1.5">{title}</h6>
          <div
            className={`rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-[23px] px-3 flex items-center gap-x-3 cursor-pointer ${
              isLoading ? 'custom-loading' : ''
            }`}
            onClick={() => {
              setAction((old: any) => ({
                ...old,
                mode: appointmentType,
              }));
            }}
          >
            <Plus className="w-4 h-4 text-primary700" />
            <p className="text-grayLight900 text-xs font-medium">
              {description}
            </p>
          </div>
        </div>
      ) : (
        <div className={`xxl:p-1.5 p-1 ${fromRadar ? 'w-full' : 'sm:flex-1'}`}>
          <h6 className="form_label block mb-1.5">{title}</h6>
          <div className="rounded-lg border border-borderPrimary bg-white hover:bg-gray50 shadow-xs py-3 px-3 flex items-center gap-x-3 cursor-pointer">
            <div
              className="leading-[1] text-sm font-semibold rounded p-1 border border-utilityGray200 h-[38px] w-[38px] text-primary700  flex flex-col items-center justify-center"
              onClick={() => {
                handleEdit(appointmentType, {
                  date: appointmentDatetime,
                  time: appointmentDatetime,
                });
              }}
            >
              <p>
                {appointmentDatetime
                  ? moment(appointmentDatetime).format('MMM')
                  : '--'}
              </p>
              <p className="leading-[1.2]">
                {appointmentDatetime
                  ? moment(appointmentDatetime).format('DD')
                  : '--'}
              </p>
            </div>
            <div
              className="flex-1 text-grayLight900 text-xs font-medium"
              onClick={() => {
                handleEdit(appointmentType, {
                  date: appointmentDatetime,
                  time: appointmentDatetime,
                });
              }}
            >
              <h6>
                {appointmentDatetime
                  ? getFormattedDate(appointmentDatetime, 'dddd', false, true)
                  : 'No Date'}
                ,&nbsp;
                {appointmentDatetime
                  ? moment
                      .utc(appointmentDatetime, 'YYYY-MM-DD HH:mm')
                      .local()
                      .format('HH:mm')
                  : 'No Time'}
              </h6>
            </div>
            <div className="flex items-center gap-2">
              {status && (
                <BadgeCmp
                  style="modern"
                  mainClassName="!mr-3"
                  type={hasAppointmentConfirmed ? 'success' : 'error'}
                >
                  {hasAppointmentConfirmed ? 'Confimred' : 'Pending'}
                </BadgeCmp>
              )}

              <TooltipCmp message="Edit">
                <Edit05
                  className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                  onClick={() => {
                    handleEdit(appointmentType, {
                      date: appointmentDatetime,
                      time: appointmentDatetime,
                    });
                  }}
                />
              </TooltipCmp>

              {status && (
                <TooltipCmp
                  message={
                    hasAppointmentConfirmed
                      ? 'Revert to Pending'
                      : 'Confirm Appointment'
                  }
                >
                  <SwitchHorizontal01
                    className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                    onClick={() => {
                      if (hasAppointmentConfirmed) {
                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-confirm-modal',
                          type: appointmentType,
                          status: 'Pending',
                        }));
                      } else {
                        setAction((old: any) => ({
                          ...old,
                          mode: 'appointment-confirm-modal',
                          type: appointmentType,
                          status: 'Confirmed',
                        }));
                      }
                    }}
                  />
                </TooltipCmp>
              )}

              {status && (
                <TooltipCmp message="Draft Mail">
                  <Mail01
                    className={`w-4 h-4 cursor-pointer text-textSecondary flex-none`}
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'appointment-darft-modal',
                        type: appointmentType,
                      }));
                    }}
                  />
                </TooltipCmp>
              )}

              <TooltipCmp message="Delete">
                <Trash01
                  className="w-4 h-4 cursor-pointer text-textSecondary flex-none"
                  onClick={() => {
                    handleDeleteAppointment(appointmentType);
                  }}
                />
              </TooltipCmp>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CommonAppointmentDetails = ({
  localLegData,
  setAction,
  handleDeleteAppointment,
  setEditMode,
  isShowDraftEmail,
  isLoading,
  fromRadar = false,
  activeAppointmentTab,
}: IProps) => {
  const pickupAppointmentsArr = useMemo(
    () => [
      {
        title: 'Shipper Pickup Appointment',
        type: 'shipper-appointment',
        description: 'Add a Shipper Pickup Appointment',
        datetime: localLegData?.shipperAppointmentDatetime,
        status: 'shipperAppointmentStatus',
      },
      {
        title: 'Carrier Pickup Appointment',
        type: 'carrier-pickup-appointment',
        description: 'Add a Carrier Pickup Appointment',
        datetime: localLegData?.carrierPickupAppointmentDatetime,
        status: 'carrierPickupAppointmentStatus',
      },
    ],
    [localLegData]
  );

  const deliveryAppointmentsArr = useMemo(
    () => [
      {
        title: 'Carrier Delivery Appointment',
        description: 'Add a Consignee Delivery Appointment',
        type: 'carrier-delivery-appointment',
        datetime: localLegData?.carrierDeliveryAppointmentDatetime,
        status: 'deliveryAppointmentStatus',
      },
      {
        title: 'Consignee Delivery Appointment',
        description: 'Add a Carrier Delivery Appointment',
        type: 'delivery-appointment',
        datetime: localLegData?.deliveryAppointmentDatetime,
        status: 'carrierDeliveryAppointmentStatus',
      },
    ],
    [localLegData]
  );

  return (
    <div className={`flex flex-wrap sm:-m-1.5 ${fromRadar ? 'gap-y-1' : ''}`}>
      {(activeAppointmentTab === 'pickup' || fromRadar) &&
        pickupAppointmentsArr?.map((appointment: any, index: number) => (
          <AppointmentSection
            key={index}
            title={appointment.title}
            status={appointment.status}
            description={appointment.description}
            appointmentType={appointment.type}
            appointmentDatetime={appointment.datetime}
            isLoading={isLoading}
            setAction={setAction}
            setEditMode={setEditMode}
            handleDeleteAppointment={handleDeleteAppointment}
            isShowDraftEmail={isShowDraftEmail}
            fromRadar={fromRadar}
            localLegData={localLegData}
          />
        ))}

      {(activeAppointmentTab === 'delivery' || fromRadar) &&
        deliveryAppointmentsArr?.map((appointment: any, index: number) => (
          <AppointmentSection
            key={index}
            title={appointment.title}
            status={appointment.status}
            description={appointment.description}
            appointmentType={appointment.type}
            appointmentDatetime={appointment.datetime}
            isLoading={isLoading}
            setAction={setAction}
            setEditMode={setEditMode}
            handleDeleteAppointment={handleDeleteAppointment}
            isShowDraftEmail={isShowDraftEmail}
            fromRadar={fromRadar}
            localLegData={localLegData}
          />
        ))}
    </div>
  );
};

export default CommonAppointmentDetails;
