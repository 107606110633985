import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import { DeleteContact } from 'src/services/CallCenterService';
import WalToast from 'src/utils/WalToast';

interface IDeleteContact {
  setAction: any;
  contactData?: any;
  handleClose: any;
}

const DeleteContactModal = ({ handleClose, contactData }: IDeleteContact) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsLoading(true);

    if (contactData?.id) {
      DeleteContact(contactData?.id)
        .then((response: any) => {
          handleClose(response.data);
          handleClose(true);
        })
        .catch(() => WalToast.error('Contact not deleted'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <CommonModal
      title={<span className="line-clamp-2 word-break">Delete Contact</span>}
      size="max-w-[400px]"
      titleDesc={`Are you sure you want to delete this contact? This action can't be undone.`}
      handleClose={() => handleClose(false)}
      modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
      modalHeaderTextClass="!flex-col"
      modalFooterClass="border-t-0 pt-0"
      headerIcon={<Trash01 />}
      secondaryBtnOnClick={() => handleClose(false)}
      dangerBtnText="Delete"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={isLoading}
      primaryBtnOnClick={handleSubmit}
    >
      &nbsp;
    </CommonModal>
  );
};

export default DeleteContactModal;
