import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  ImagePlus,
  Mail01,
  User01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import CropperModal from 'src/components/CropperModal/CropperModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import TabButton from 'src/components/TabButton';
import Toggle from 'src/components/Toggle/Toggle';
import TooltipCmp from 'src/components/TooltipCmp';
import { usePhone } from 'src/hooks/usePhone';
import { addCarrier, updateCarrier } from 'src/services/CarrierService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import BanyanIcon from '../../../assets/img/banyan.svg';
import defaultImage from '../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../assets/img/frieghtcom.png';

const EditCarrier = ({
  action,
  carrier,
  handleClose,
  // setIsRefreshMain,
  getListCarrier,
  handleActionType,
}: any) => {
  // const supportedFormats = [
  //   'image/svg+xml',
  //   'image/png',
  //   'image/jpeg',
  //   'image/gif',
  // ];
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const ServiceAreaArr = [
    {
      value: '1',
      name: 'CA-CA',
    },
    {
      value: '2',
      name: 'US-US',
    },
    {
      value: '3',
      name: 'Cross-Border',
    },
  ];

  const { isPhoneValid } = usePhone();
  const [image, setImage] = useState<any>();
  const [Scope, setScope] = useState<any>([]);
  const [preview, setPreview] = useState<string | null>(null);
  const [upload, setUpload] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('general');
  const [imageError, setImageError] = useState('');
  // const [cropData, setCropData] = useState<any>("");

  useEffect(() => {
    if (action.mode === 'edit' || action.mode === 'viewCarrier')
      setPreview(carrier.imageUrl + carrier.image);
    var selectedScopeArr = [];

    if (action.mode === 'add' || carrier.isSpotQuote) {
      selectedScopeArr.push('Spot Quote');
    }

    if (carrier.isApiConnection) {
      selectedScopeArr.push('API');
    }
    setScope(selectedScopeArr);
  }, [carrier]);

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Carrier is required.'),
    image: yup
      .mixed()
      .nullable()
      // .test({
      //   name: 'fileFormat',
      //   message: 'Unsupported image format.',
      //   test: async (value: any) => {
      //     console.log('fileTypedsfds :>> ', fileType);
      //     if (!value || !(value instanceof File) || !fileType) return true;

      //     return supportedFormats.includes(fileType);
      //   },
      // })
      .test({
        name: 'fileSize',
        message: 'Image size should be less than 2MB.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return value.size <= MAX_FILE_SIZE;
        },
      }),
    scope: yup.string().trim(),
    // provider: yup.string().when([], {
    //     is: () => action.mode === "edit",
    //     then: (schema) => schema.required("Provider is required."),
    //     otherwise: (schema) => schema,
    // }),
    provider: yup.string().trim(),
    contactPhone: yup.string().test({
      message: 'Invalid phone number',
      test: function (value) {
        if (value === '') {
          return true;
        }
        const { phone_country_code: phoneCountryCode } = this.parent;
        const isValid = isPhoneValid(value, phoneCountryCode);

        return isValid;
      },
    }),
    // phone: yup.string(),
    contactName: yup.string().trim(),
    phone_country_code: yup.string(),
    contactEmail: yup.string().test({
      message: 'Invalid email address',
      test: function (value) {
        if (value != '') {
          const emailRegex =
            /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

          return emailRegex.test(value ?? '');
        }

        return true;
      },
    }),
    // contactEmail: yup.string().email('Invalid email address'),
    serviceArea: yup
      .array()
      .min(1, 'At least one checkbox must be selected')
      .required(),
    isSpotQuote: yup.boolean(),
    spotQuoteEmail: yup.string().when('customerData', {
      is: true,
      then: (schema) =>
        schema.required('spot quot email is required.').test({
          message: 'Invalid spot quot email address',
          test: function (value) {
            const emailRegex =
              /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

            return emailRegex.test(value ?? '');
          },
        }),
      otherwise: (schema) => schema,
    }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    setError,
    getValues,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: carrier.name || '',
      phone_country_code: 'CA',
      image: carrier.imageUrl + carrier.image || null,
      contactName: carrier.contactName || '',
      contactPhone: carrier.contactPhone || '',
      contactEmail: carrier.contactEmail || '',
      scope: `${
        action.mode === 'add' || carrier.isSpotQuote ? 'Spot Quote' : ''
      }, ${carrier.isApiConnection ? 'API' : ''}`,
      // scope: Scope,
      provider: carrier.parentCarrierEmail || carrier.contactEmail || '',
      serviceArea: carrier.serviceArea ? carrier.serviceArea.split(',') : [],
      isSpotQuote: carrier.isSpotQuote || false,
      spotQuoteEmail: carrier.spotQuoteEmail || undefined,
    },
    mode: 'onTouched',
  });

  var carrierName = getValues('name');

  let filterArr = [
    {
      value: 'general',
      name: 'General',
    },
    {
      value: 'contacts',
      name: 'Contacts',
    },
    {
      value: 'spot_quote_email',
      name: 'Spot Quote Email',
    },
  ];

  if (action.mode === 'edit' || action.mode === 'viewCarrier') {
    filterArr = [
      {
        value: 'general',
        name: 'General',
      },
      {
        value: 'contacts',
        name: 'Contacts',
      },
      {
        value: 'provider',
        name: 'Provider',
      },
      {
        value: 'spot_quote_email',
        name: 'Spot Quote Email',
      },
    ];
  }

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      if (key !== 'image') appendFormData.append(key, value);
    });
    // console.log('formData.image :>> ', formData.image);
    appendFormData.append('image', formData.image);

    if (carrier.id) {
      updateCarrier(carrier.id, appendFormData)
        .then(() => {
          // setIsRefreshMain(true);
          getListCarrier();
          WalToast.success('Carrier updated successfully');
        })
        .catch(() => WalToast.error('Carrier not updated'))
        .finally(() => {
          setIsLoading(false);
          handleClose();
        });
    } else {
      addCarrier(appendFormData)
        .then(() => {
          // setIsRefreshMain(true);
          getListCarrier();
          WalToast.success('Carrier added successfully');
        })
        .catch(() => WalToast.error('Carrier not added'))
        .finally(() => {
          setIsLoading(false);
          handleClose();
        });
    }
  };
  useEffect(() => {
    if (errors && errors.contactPhone) {
      setActiveTab('contacts');
    } else if (errors && errors.spotQuoteEmail) {
      setActiveTab('spot_quote_email');
    }
  }, [errors]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (action.mode === 'viewCarrier') {
        return;
      }

      setError('image' as 'image', {});
      setPreview(null);
      const file = acceptedFiles[0];

      setValue('image', file, { shouldValidate: true });
      const { result, message } = checkFileTypeValidation(
        acceptedFiles,
        MAX_FILE_SIZE
      );

      if (result) {
        setIsLoading(false);
        const reader = new FileReader();

        reader.onloadstart = () => {
          setImage('');
          setUpload(false);
        };

        reader.onloadend = () => {
          setImage(reader.result as any);
          // setValue("image", reader.result, { shouldValidate: true });
          setUpload(true);
        };
        reader.readAsDataURL(file);
      } else {
        setImageError(message);
        // setError('image' as 'image', {
        //   type: 'manual',
        //   // message: 'Image size must be less than 2MB.',
        //   message: message,
        // });
      }
    },
    [action]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    disabled: action.mode === 'viewCarrier' ? true : false,
  });

  const isSpotQuote = watch('isSpotQuote');

  useEffect(() => {
    if (!isSpotQuote) {
      setValue('spotQuoteEmail', '');
    }
  }, [isSpotQuote]);

  // const chooseFile = (e: any) => {
  //   e.preventDefault();
  //   setError('image' as 'image', {});
  //   let files;

  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //   }
  //   const { result } = checkFileTypeValidation(files, MAX_FILE_SIZE);

  //   if (result) {
  //     setIsLoading(false);
  //     const reader = new FileReader();
  //     console.log('reader :>> ', reader);

  //     reader.onloadstart = () => {
  //       setImage('');
  //       setUpload(false);
  //     };

  //     reader.onloadend = () => {
  //       setImage(reader.result as any);
  //       // setValue("image", reader.result, { shouldValidate: true });
  //       setUpload(true);
  //     };
  //     reader.readAsDataURL(files[0]);
  //     e.target.value = null;
  //   } else {
  //     setIsLoading(true);
  //     setError('image' as 'image', {
  //       type: 'manual',
  //       message: 'Image size must be less than 2MB.',
  //     });
  //   }
  // };

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    // console.log('fileInfo :>> ', fileInfo);
    setValue('image', fileInfo?.convertedFile);
  };
  const carrierImage = getValues('image') as string;
  const headerIcon = <Container className="w-5 h-5" />;

  return (
    <>
      <CommonModal
        title={`${
          action.mode === 'add'
            ? 'Add Carrier Details'
            : action.mode === 'viewCarrier'
            ? 'View Carrier Details'
            : 'Edit Carrier Details'
        }`}
        titleDesc={`${
          action.mode === 'add'
            ? 'Create new carrier for free in less than 5 minutes.'
            : 'You can edit carrier details as per your requirement'
        }`}
        handleClose={() => handleClose()}
        headerIcon={headerIcon}
        modalHeaderClass={`${action.mode === 'viewCarrier' ? '' : ''}`}
        headerIconClass=""
        size={'max-w-[688px] h-[60vh]'}
        primaryBtnText={`${action.mode !== 'viewCarrier' ? 'Save' : 'Edit'}`}
        secondaryBtnText={`${
          action.mode !== 'viewCarrier' ? 'Cancel' : 'Close'
        }`}
        primaryBtnDisabled={isLoading}
        primaryBtnLoading={isLoading}
        primaryBtnOnClick={
          action.mode !== 'viewCarrier'
            ? handleSubmit(onSubmit)
            : handleActionType('carrier', 'edit', carrier)
        }
        secondaryBtnOnClick={() => handleClose(false)}
      >
        <div className="p-5 pt-0">
          <TabButton
            tabArray={filterArr}
            tabParentClassName="mb-6 sticky top-0 pt-5 z-20 bg-white"
            parentClassName="w-full"
            isActive={activeTab}
            isTab={true}
            handleOnClick={(e: any) => {
              setActiveTab(e?.target?.dataset?.value);
            }}
          />

          {/* <form className="h-full" onSubmit={handleSubmit(onSubmit)}> */}
          {activeTab === 'general' ? (
            <div className="border border-utilityGray200 rounded-[10px]">
              <h5 className="text-textSecondary text-sm font-semibold px-4 py-3 border-b border-utilityGray200">
                Carrier Details
              </h5>
              <div className="py-5 px-4">
                <div className="w-full md:flex pb-4 border-b border-utilityGray200 mb-4">
                  <p className="text-xs font-medium text-textSecondary mb-2 md:mb-0 w-40">
                    Carrier Logo
                  </p>

                  <div className="position-relative flex-1">
                    <div className="flex md:gap-4 gap-2 ">
                      {preview ? (
                        <img
                          className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                          src={preview}
                          alt={carrierName}
                          title={carrierName}
                          onError={onError}
                        />
                      ) : getValues('image') ? (
                        <img
                          className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                          src={carrierImage}
                          alt={carrierName}
                          title={carrierName}
                          onError={onError}
                        />
                      ) : carrierName ? (
                        <label className="rounded-lg bg-utilityGray100 w-14 h-14 border-utilityBlack border-[0.75px] border-opacity-[0.08] text-primary text-xl font-semibold uppercase flex items-center justify-center flex-none">
                          {getShortName(`${carrierName}`)}
                        </label>
                      ) : (
                        <label className="w-14 h-14 border border-black/[0.08] rounded-lg object-cover flex justify-center items-center bg-utilityGray100 text-gray500 flex-none">
                          <ImagePlus />
                        </label>
                      )}
                      {/* <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                                <label className="cursor-pointer" {...getRootProps()}>
                                    <progress className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden" value="70" max="100"></progress>
                                    <div className="text-grayLight600 text-xs font-normal">
                                        <p>
                                            <span className="text-[#2422DD] font-semibold pr-1">Click to upload </span>or drag and drop
                                        </p>
                                        <p className="text-[11px] leading-[18px]">SVG, PNG or JPG (max. 800x800px)</p>
                                    </div>
                                </label>
                                <input type="file" name="image" className="hidden" {...getInputProps()} disabled={action.mode === "viewCarrier"} />
                            </div> */}
                      {action.mode !== 'viewCarrier' && (
                        <div className="position-relative flex-1">
                          <div className="">
                            <div {...getRootProps()}>
                              <label className="cursor-pointer flex items-center gap-2">
                                <div
                                  className={`relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white ${
                                    errors.image?.message
                                      ? 'border-red-400'
                                      : 'border-utilityGray200'
                                  }`}
                                >
                                  <div className="justify-center text-xs items-center gap-1.5 flex">
                                    <div className="text-primary font-semibold leading-tight">
                                      Click to upload
                                    </div>
                                    <div className="text-grayLight600 text-xs font-normal leading-tight">
                                      or drag and drop
                                    </div>
                                  </div>
                                  <div className="text-center text-grayLight600 text-xs font-normal leading-[18px]">
                                    SVG, PNG or JPG
                                  </div>
                                </div>
                              </label>
                              <input
                                accept=".svg, .png, .jpg, .jpeg"
                                {...getInputProps()}
                              />
                              <div className="mt-1.5">
                                {imageError ? (
                                  <ErrorMsg errorText={imageError} />
                                ) : (
                                  errors.image && (
                                    <ErrorMsg
                                      errorText={`${errors.image.message} `}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="w-full">
                    {imageError ? (
                      <ErrorMsg errorText={imageError} />
                    ) : (
                      errors.image && (
                        <ErrorMsg errorText={`${errors.image.message} `} />
                      )
                    )}
                  </div> */}
                  </div>
                </div>

                {action.mode !== 'add' && (
                  <InputText
                    label="Carrier Provider Name"
                    inputName="provider_name"
                    placeholder="Carrier Provider Name"
                    parentClassName="mb-4"
                    className=""
                    value={carrier.carrierNameByProvider || carrier.name}
                    disabled={true}
                    labelClassName="block mb-1.5"
                  />
                )}

                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      label="Display Name"
                      inputName="name"
                      placeholder="Display Name"
                      parentClassName="mb-4"
                      className=""
                      value={value}
                      onChangeFunc={onChange}
                      errorText={errors.name ? errors.name.message : null}
                      labelClassName="block mb-1.5"
                      disabled={action.mode === 'viewCarrier'}
                    />
                  )}
                />

                <div className="flex items-center gap-3 mb-4 rounded-lg border border-utilityGray200 p-3">
                  <label className="text-textSecondary text-xs font-medium">
                    Scope
                  </label>
                  {Scope.map((val: any, index: number) => (
                    <BadgeCmp
                      key={index}
                      style="pill"
                      type={`${val === 'API' ? 'success' : 'primary'}`}
                    >
                      {val}
                    </BadgeCmp>
                  ))}
                </div>
                <Controller
                  name="serviceArea"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="rounded-lg border border-utilityGray200 p-3">
                      <div className="flex sm:flex-row flex-col sm:items-center  gap-x-3">
                        <label className="text-xs text-textSecondary font-medium sm:mb-0 mb-3">
                          Service Areas
                        </label>
                        {action.mode === 'viewCarrier'
                          ? watch('serviceArea').map(
                              (serviceA: any, index: number) => (
                                <BadgeCmp
                                  key={index}
                                  mainClassName="w-fit"
                                  style="modern"
                                  type="success"
                                  isHidePillDot
                                >
                                  {
                                    ServiceAreaArr.find(
                                      (item: any) => item.value === serviceA
                                    )?.name
                                  }
                                </BadgeCmp>
                              )
                            )
                          : ''}
                        {action.mode !== 'viewCarrier' && (
                          <div className="flex xs:flex-row flex-col sm:gap-x-5 gap-x-4 gap-y-2">
                            {ServiceAreaArr.map((serviceA: any) => (
                              <div
                                key={serviceA.value}
                                className="flex items-center"
                              >
                                <CheckBox
                                  id={serviceA.value}
                                  disabled={action.mode === 'viewCarrier'}
                                  onChangeFunc={(e: any) => {
                                    const checked = e.target.checked;
                                    let updatedServiceArea = [...value];

                                    if (checked) {
                                      updatedServiceArea.push(serviceA.value);
                                    } else {
                                      updatedServiceArea =
                                        updatedServiceArea.filter(
                                          (item) => item !== serviceA.value
                                        );
                                    }
                                    onChange(updatedServiceArea);
                                  }}
                                  checked={value.includes(serviceA.value)}
                                />
                                <label
                                  htmlFor={serviceA.value}
                                  className="sm:ml-2 ml-1 items-start text-textSecondary font-medium text-xs cursor-pointer"
                                >
                                  {serviceA?.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      <div>
                        {errors.serviceArea && (
                          <ErrorMsg errorText="At least one value must be selected" />
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {activeTab === 'contacts' ? (
            <div className="border border-utilityGray200 rounded-[10px]">
              <h5 className="text-textSecondary text-sm font-semibold px-4 py-3 border-b border-utilityGray200">
                Contact Details
              </h5>
              <div className="py-5 px-4">
                <Controller
                  name="contactName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      label="Name"
                      inputName="contactName"
                      placeholder="Contact Name"
                      parentClassName="mb-3"
                      className="pl-9"
                      value={value}
                      onChangeFunc={onChange}
                      disabled={action.mode === 'viewCarrier'}
                      errorText={
                        errors.contactName ? errors.contactName.message : null
                      }
                      labelClassName="block mb-1.5"
                      icon={
                        <User01 className="w-4 h-4 absolute mr-2 ml-3 text-gray500 left-0" />
                      }
                    />
                  )}
                />
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      label="Email"
                      inputName="contactEmail"
                      placeholder="Enter Email"
                      parentClassName="mb-3"
                      className="pl-9"
                      value={value}
                      disabled={action.mode === 'viewCarrier'}
                      onChangeFunc={onChange}
                      errorText={
                        errors.contactEmail ? errors.contactEmail.message : null
                      }
                      labelClassName="block mb-1.5"
                      icon={
                        <Mail01 className="w-4 h-4 absolute mr-2 ml-3 text-gray500 left-0 mt-0.5" />
                      }
                    />
                  )}
                />
                <Controller
                  name="contactPhone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <label className="form_label block mb-1.5">
                        {' '}
                        Contact Number
                      </label>
                      <PhoneInput
                        onNumberChange={(
                          country: any,
                          phone: any,
                          code: any
                        ) => {
                          const newValue = `+${code}${phone}`;
                          setValue('contactPhone', newValue);
                          setValue('phone_country_code', country);
                          onChange(newValue);

                          if (touchedFields.contactPhone) {
                            trigger('contactPhone');
                            trigger('phone_country_code');
                          }
                        }}
                        name="contactPhone"
                        disabled={action.mode === 'viewCarrier'}
                        errors={
                          errors.contactPhone
                            ? errors.contactPhone.message
                            : errors.phone_country_code
                            ? errors.phone_country_code.message
                            : null
                        }
                        value={value}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          ) : (
            ''
          )}

          {action.mode !== 'add' && activeTab === 'provider' && (
            <div className="border border-utilityGray200 rounded-[10px]">
              <h5 className="text-textSecondary text-sm font-semibold px-4 py-3 border-b border-utilityGray200">
                Provider Details
              </h5>
              <div className="pt-[18px] pb-5 px-4">
                <Controller
                  name="provider"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <div className="flex items-center gap-2 mb-2.5">
                        {(carrier.isFreightcom ||
                          carrier.isFreightcom === 1) && (
                          <div className="flex items-center gap-2">
                            <img
                              className="w-5 h-5 relative rounded border-[0.75px] border-black/[0.08]"
                              src={FreightcomIcon}
                              alt="FC"
                            />
                            <label className="form_label mb-0 text-sm">
                              {' '}
                              Freightcom
                            </label>
                          </div>
                        )}
                        {(carrier.isBanyan || carrier.isBanyan === 1) && (
                          <div className="flex items-center gap-2">
                            <img
                              className="w-5 h-5 relative rounded  border-[0.75px] border-black/[0.08]"
                              src={BanyanIcon}
                              alt="BANYAN"
                            />
                            <label className="form_label mb-0 text-sm">
                              {' '}
                              Banyan
                            </label>
                          </div>
                        )}
                        {!carrier.isBanyan && !carrier.isFreightcom && (
                          <div className="flex items-center gap-2">
                            <img
                              className="rounded w-5 h-5 border-[0.75px] border-gray100 object-cover"
                              src={
                                carrier.image
                                  ? `${carrier.imageUrl}${carrier.image}`
                                  : defaultImage
                              }
                              alt="CR"
                            />
                            <label className="form_label mb-0 text-sm">
                              {' '}
                              Direct
                            </label>
                          </div>
                        )}

                        <TooltipCmp
                          type="dark"
                          childrenClassName="!flex"
                          parentClassName="!p-0"
                          message={
                            <div className="text-left text-xs font-semibold p-1.5">
                              <p className="flex items-center gap-1 text-xs font-bold capitalize ">
                                <span
                                  className={`w-2 h-2 ${
                                    carrier.apiStatus === 'active'
                                      ? 'bg-successSecondary'
                                      : 'bg-orange500'
                                  } rounded-full`}
                                ></span>
                                {carrier.apiStatus === 'active'
                                  ? 'Live'
                                  : 'Unavailable'}
                              </p>
                              {carrier.apiLastCheck && (
                                <p
                                  className="mt-1"
                                  dangerouslySetInnerHTML={{
                                    __html: carrier.apiLastCheck
                                      ? getDateWithSuffixFormat(
                                          getFormattedDate(
                                            carrier.apiLastCheck,
                                            `MMM Do - YYYY hh:mm:ss A`,
                                            true
                                          )
                                        )
                                      : '-',
                                  }}
                                />
                              )}
                            </div>
                          }
                        >
                          <BadgeCmp
                            style="modern"
                            type={
                              carrier.apiStatus === 'active'
                                ? 'success'
                                : 'error'
                            }
                          >
                            {carrier.apiStatus === 'active'
                              ? 'Live'
                              : 'Unavailable'}
                          </BadgeCmp>
                        </TooltipCmp>
                      </div>
                      <InputText
                        inputName="provider "
                        placeholder="Provider Email"
                        value={value}
                        required
                        disabled
                        onChangeFunc={onChange}
                        errorText={
                          errors.provider ? errors.provider.message : null
                        }
                        parentClassName=""
                      />
                    </>
                  )}
                />
              </div>
            </div>
          )}

          {activeTab === 'spot_quote_email' ? (
            <div className="border border-utilityGray200 rounded-[10px]">
              <h5 className="text-textSecondary text-sm font-semibold px-4 py-3 border-b border-utilityGray200">
                Spot Quotes Details
              </h5>
              <div className="py-5 px-4">
                <div className="items-center flex justify-between ">
                  <label className="form_label block mb-0">Email</label>
                  <Controller
                    name="isSpotQuote"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        isDisabled={action.mode === 'viewCarrier'}
                        isChecked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                {watch('isSpotQuote') && (
                  <Controller
                    name="spotQuoteEmail"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="spotQuoteEmail "
                        placeholder="Spot Quote Email"
                        parentClassName="mt-1.5"
                        value={value}
                        disabled={action.mode === 'viewCarrier'}
                        onChangeFunc={onChange}
                        errorText={
                          errors.spotQuoteEmail
                            ? errors.spotQuoteEmail.message
                            : null
                        }
                        labelClassName="form_label block mb-1.5"
                      />
                    )}
                  />
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </CommonModal>
      {upload && (
        <CropperModal
          modalTitle="Edit Carrier Image"
          modalDesc="Edit carrier image as per your requirement"
          imageUpload={imageUpload}
          imageUrl={image}
          setUpload={setUpload}
          setCropData={setPreview}
          defaultImage={carrier?.imageUrl + carrier?.image ?? ''}
          isCropType={false}
          defaultCropType="Square"
        />
      )}
    </>
  );
};

export default EditCarrier;
