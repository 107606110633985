import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';

const BankOnboarding = ({ data }: any) => {
  const phoneNumber = parsePhoneNumberFromString(String(data?.bank_phone));

  return (
    <>
      <td className="px-5 py-4">
        <span>{data.bank_name}</span>
      </td>
      <td className="px-5 py-4">
        <span>{data.bank_contact_name}</span>
      </td>
      <td className="px-5 py-4">
        <span>{data.bank_email}</span>
      </td>
      <td className="px-5 py-4">
        <span> {phoneNumber ? phoneNumber.formatInternational() : ''}</span>
      </td>
      <td className="px-5 py-4 w-56 min-w-56 max-w-56">
        <p className="truncate">{data.bank_address}</p>
      </td>
    </>
  );
};
export default BankOnboarding;
