import React, { useEffect, useState } from 'react';
import CheckBox from 'src/components/CheckBox';
import TabButton from 'src/components/TabButton';
import Toggle from 'src/components/Toggle/Toggle';

interface IProps {
  order: any;
}

const addressDetailsFields = [
  { name: 'shipper', label: 'Shipper' },
  { name: 'consignee', label: 'Consignee' },
];

const orderDetailsFields = [
  { name: 'orderType', label: 'Order Type' },
  { name: 'equipmentType', label: 'Equipment Type' },
  { name: 'refNumber', label: 'REF Number' },
  { name: 'poNumber', label: 'PO Number' },
  { name: 'linearFootage', label: 'Linear Footage' },
  { name: 'declaredValue', label: 'Declared Value' },
  { name: 'specialNotes', label: 'Special Notes' },
  { name: 'dimensions', label: 'Dimensions' },
  { name: 'additionalServices', label: 'Additional Services' },
];

const Step2 = ({ order }: IProps) => {
  const [orderTab, setOrderTab] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>('leg1');
  const [tabState, setTabState] = useState<any>({});

  useEffect(() => {
    if (order) {
      const orderLegItems = [
        ...(order?.orderLegs?.map((subOrder: any, index: number) => ({
          value: subOrder?.legId,
          name:
            order?.orderLegs?.length === 1 && index === 0
              ? `Master (#${subOrder?.legId})`
              : `Leg ${index + 1} (#${subOrder?.legId})`,
        })) || []),
      ];

      setOrderTab(orderLegItems);
      setActiveTab(orderLegItems?.[0]?.value);
    }
  }, [order]);

  const handleToggleChange = (section: string) => {
    setTabState((prev: any) => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        [section]: !prev[activeTab]?.[section],
      },
    }));
  };

  const handleCheckboxChange = (section: string, field: string) => {
    setTabState((prev: any) => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        [section]: {
          ...prev[activeTab]?.[section],
          [field]: !prev[activeTab]?.[section]?.[field],
        },
      },
    }));
  };

  const getCheckBox = (name: string, parentId: string, label: string) => (
    <li className="flex items-start">
      <CheckBox
        id={name}
        name={name}
        parentClassName={`gap-x-1.5`}
        onChangeFunc={() => {
          handleCheckboxChange(parentId, name);
        }}
        label={label}
        labelHtmlFor={name}
        checked={tabState[activeTab]?.[parentId]?.[name] ?? false}
      />
    </li>
  );

  return (
    <div className="p-5">
      <h6 className="text-grayLight900 text-sm font-medium mb-2">
        Select which fields you would like to copy to new orders
      </h6>
      <div className="rounded-xl border border-utilityGray200 p-3 flex flex-col gap-y-4">
        <TabButton
          tabArray={orderTab}
          parentClassName="p-1 rounded-[10px] border border-gray100 bg-gray50 !w-full whitespace-nowrap overflow-x-auto scrollbar-hide"
          className="!border-0 !bg-gray50 rounded-md flex-1"
          activeClassName="!bg-white shadow-md"
          isActive={activeTab}
          isTab={true}
          handleOnClick={(event: any) =>
            setActiveTab(event?.target?.dataset?.value)
          }
        />
        <div className="rounded-lg border border-utilityGray200 p-4">
          <div className="flex items-center gap-x-2">
            <h6 className="text-textSecondary text-sm font-semibold flex-1">
              Address Details
            </h6>
            <Toggle
              isChecked={tabState[activeTab]?.addressDetails ?? true}
              onClick={() => handleToggleChange('addressDetails')}
            />
          </div>
          <ul className="border-t border-utilityGray200 pt-4 mt-4 grid grid-cols-3 gap-x-4 gap-y-5">
            {addressDetailsFields.map((field) =>
              getCheckBox(field.name, 'addressDetails', field.label)
            )}
          </ul>
        </div>
        <div className="rounded-lg border border-utilityGray200 p-4">
          <div className="flex items-center gap-x-2">
            <h6 className="text-textSecondary text-sm font-semibold flex-1">
              Order Details
            </h6>
            <Toggle
              isChecked={tabState[activeTab]?.orderDetails ?? true}
              onClick={() => handleToggleChange('orderDetails')}
            />
          </div>
          <ul className="border-t border-utilityGray200 pt-4 mt-4 grid grid-cols-3 gap-x-4 gap-y-5">
            {orderDetailsFields.map((field) =>
              getCheckBox(field.name, 'orderDetails', field.label)
            )}
          </ul>
        </div>
        <div className="rounded-lg border border-utilityGray200 p-4">
          <div className="flex items-center gap-x-2">
            <h6 className="text-textSecondary text-sm font-semibold flex-1">
              Carriers Cost
            </h6>
            <Toggle
              isChecked={tabState[activeTab]?.carrierCost ?? true}
              onClick={() => handleToggleChange('carrierCost')}
            />
          </div>
        </div>
        <div className="rounded-lg border border-utilityGray200 p-4">
          <div className="flex items-center gap-x-2">
            <h6 className="text-textSecondary text-sm font-semibold flex-1">
              Customer Sale Price
            </h6>
            <Toggle
              isChecked={tabState[activeTab]?.customerSaleCost ?? true}
              onClick={() => handleToggleChange('customerSaleCost')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
