import React from 'react';

import ErrorMsg from '../errorMsg';

import { ICheckBoxProps } from './checkBox.interface';

const CheckBox = ({
  errorText,
  classes,
  parentClassName,
  checked,
  disabled = false,
  required,
  onChangeFunc,
  labelHtmlFor,
  label,
  ...props
}: ICheckBoxProps) => (
  <>
    <div className={`flex ${parentClassName}`}>
      <input
        type="checkbox"
        className={`checkbox checkbox-primary border-borderPrimary  h-3.5 w-3.5 border-1 cursor-pointer rounded !animate-none ${
          disabled ? '!checkbox-primary !opacity-60' : ''
        } ${classes}`}
        id={props.id}
        value={props.value}
        onChange={onChangeFunc}
        required={required}
        checked={checked}
        disabled={disabled}
        {...props}
      />

      {label && (
        <label
          htmlFor={labelHtmlFor ? labelHtmlFor : ''}
          className={`text-grayLight900 text-xs font-medium cursor-pointer `}
        >
          {label}
        </label>
      )}
    </div>

    <ErrorMsg errorText={errorText}></ErrorMsg>
  </>
);

export default CheckBox;
