import { Edit05 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import InputText from 'src/components/InputText/InputText';

interface IProps {
  orderDetailsArray: any;
}

const Step3 = ({ orderDetailsArray }: IProps) => {
  const [poNumbers, setPoNumbers] = useState(
    orderDetailsArray.map((item: any) => item.poNumber || '')
  );
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPoNumbers = [...poNumbers];
    updatedPoNumbers[index] = value;
    setPoNumbers(updatedPoNumbers);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setEditingIndex(null);
    }
  };

  return (
    <ul className="p-5 flex flex-col gap-y-4 ">
      {orderDetailsArray &&
        orderDetailsArray?.length > 0 &&
        orderDetailsArray?.map((item: any, index: number) => (
          <li
            className="rounded-lg border border-utilityGray200 p-4 flex"
            key={index}
          >
            <div className="flex flex-wrap -m-2 flex-1">
              <div className="p-2 w-1/3 flex flex-col gap-y-1.5">
                <h6 className="text-grayLight600 text-xs font-medium">
                  Order Number
                </h6>
                <p className="text-grayLight900 text-sm font-medium ">{`WAL-TEST-${
                  index + 1
                }`}</p>
              </div>
              <div className="p-2 w-1/3 flex flex-col gap-y-1.5">
                <h6 className="text-grayLight600 text-xs font-medium">
                  Pickup Date
                </h6>
                <p className="text-grayLight900 text-sm font-medium ">{item}</p>
              </div>
              <div className="p-2 w-1/3 flex flex-col gap-y-1.5">
                <h6 className="text-grayLight600 text-xs font-medium">
                  PO Number
                </h6>
                {poNumbers[index] ? (
                  <div className="flex gap-x-1 items-center">
                    {editingIndex === index ? (
                      <>
                        <InputText
                          placeholder="Enter PO Number"
                          onChangeFunc={(e: any) =>
                            handleInputChange(index, e.target.value)
                          }
                          onKeyPress={(e) => handleKeyDown(e)}
                          onBlur={() => setEditingIndex(null)}
                          value={poNumbers[index]}
                        />
                      </>
                    ) : (
                      <>
                        <p className="text-grayLight900 text-sm font-medium">
                          {poNumbers[index]}
                        </p>
                        <Edit05
                          className="w-3.5 h-3.5 text-borderPrimary cursor-pointer"
                          onClick={() => handleEditClick(index)}
                        />
                      </>
                    )}
                  </div>
                ) : editingIndex === index ? (
                  <>
                    <InputText
                      placeholder="Enter PO Number"
                      onChangeFunc={(e: any) =>
                        handleInputChange(index, e.target.value)
                      }
                      onKeyPress={(e) => handleKeyDown(e)}
                      onBlur={() => setEditingIndex(null)}
                      value={poNumbers[index]}
                    />
                  </>
                ) : (
                  <div
                    className="text-primary text-sm font-medium cursor-pointer"
                    onClick={() => {
                      handleEditClick(index);
                    }}
                  >
                    + Add PO Number
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default Step3;
