import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const OrderDetailsRow = ({ data, handlingUnitOptions }: any) => {
  const handlingUnit = handlingUnitOptions?.find(
    (item: any) => item?.value === data?.handlingUnit
  );

  return (
    <>
      <td className="px-5 py-4 w-[10%] min-w-[10%] max-w-[10%]">
        <span>{data?.handlingUnitNo}</span>
      </td>
      <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-px">
        <span>{handlingUnit?.label ?? '-'}</span>
      </td>

      <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-[13.5%]">
        <span>#{data?.orderLegId}</span>
      </td>
      <td className="px-5 py-4 w-[18%] min-w-[18%] max-w-px">
        <TooltipCmp
          message={
            data?.shipperAddress1?.length > 20 ? data?.shipperAddress1 : ''
          }
        >
          <p className="truncate">{data?.shipperAddress1}</p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 w-[18%] min-w-[18%] max-w-px">
        <TooltipCmp
          message={
            data?.consigneeAddress1?.length > 20 ? data?.consigneeAddress1 : ''
          }
        >
          <p className="truncate">{data?.consigneeAddress1}</p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-[13.5%]">
        <span className="lowercase">
          {data?.totalWeight ? data?.totalWeight.toFixed(2) : 0} (
          {data?.weightMeasurement})
        </span>
      </td>
      <td className="px-5 py-4 w-[13.5%] min-w-[13.5%] max-w-[13.5%]">
        <span>{data?.refNumber ?? '-'}</span>
      </td>
    </>
  );
};

export default OrderDetailsRow;
