import { yupResolver } from '@hookform/resolvers/yup';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getFreightClass } from 'src/services/QuoteService';
import { getFormattedNumber, getLabelByValue } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import ThreeJsScene from './ThreeJsScene';

interface IProps {
  handleClose: any;
  lengthMeasurement: any;
  weightMeasurement: any;
  unitArr: any;
  currentItem: any;
  setDimensionsFormData: any;
}

const VisualizeUnit = ({
  handleClose,
  lengthMeasurement,
  weightMeasurement,
  unitArr,
  currentItem,
  setDimensionsFormData,
}: IProps) => {
  const [totalObj, setTotalObj] = useState({
    cubicFeet: 0,
    density: 0,
    freightClass: 0,
  });
  const validationSchema = yup.object().shape({
    handlingUnit: yup.string().required('Unit is required.'),
    totalWeight: yup.number().required('Total_weight is required.'),
    itemLength: yup.number().required('Length is required.'),
    height: yup.number().required('Height is required.'),
    width: yup.number().required('Width is required.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      handlingUnit: currentItem.data.handlingUnit || '',
      totalWeight: currentItem.data.totalWeight || '',
      itemLength: currentItem.data.itemLength || '',
      height: currentItem.data.height || '',
      width: currentItem.data.width || '',
    },
  });

  useEffect(() => {
    const weight = Number(currentItem.data.weight);
    const length = Number(getValues('itemLength'));
    const width = Number(getValues('width'));
    const height = Number(getValues('height'));

    const controller = new AbortController();
    const signal = controller.signal;

    if (weight && length && width && height) {
      getFreightClass(
        {
          itemLength: length,
          width: width,
          height: height,
          weight: weight,
          lengthUnit: lengthMeasurement,
          weightUnit: weightMeasurement,
        },
        signal
      )
        .then((data: any) => {
          const freightClass = data?.data?.freightClass || 0;
          var cubicFeet =
            (Number(getValues('itemLength')) *
              Number(getValues('width')) *
              Number(getValues('height'))) /
            1728;
          var density =
            cubicFeet > 0 ? Number(currentItem.data.weight) / cubicFeet : 0;
          setTotalObj((old: any) => ({
            ...old,
            cubicFeet: cubicFeet,
            density: density,
            freightClass: freightClass,
          }));
        })
        .catch(() => false);
    } else {
      const freightClass = 0;
      var cubicFeet =
        (Number(getValues('itemLength')) *
          Number(getValues('width')) *
          Number(getValues('height'))) /
        1728;
      var density =
        cubicFeet > 0 ? Number(currentItem.data.weight) / cubicFeet : 0;
      setTotalObj((old: any) => ({
        ...old,
        cubicFeet: cubicFeet,
        density: density,
        freightClass: freightClass,
      }));
    }

    return () => {
      controller?.abort();
    };
  }, [watch('itemLength'), watch('width'), watch('height')]);

  const onSubmit = async (data: any) => {
    const index = currentItem.index;
    setDimensionsFormData((oldState: any) => {
      const newState = [...oldState]; // Create a copy of the state array
      newState[index] = {
        ...newState[index],
        ...data,
        freightClass: totalObj.freightClass,
      }; // Update the specific index

      return newState; // Return the updated state
    });
    handleClose();
  };

  return (
    <CommonModal
      headerIcon={<PackagePlus />}
      size={'max-w-[954px]'}
      handleClose={handleClose}
      title="Visualize Unit"
      titleDesc="Drag the map so the pin matches the exact location of your business location."
      primaryBtnText="Save Changes"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSubmit(onSubmit)}
    >
      <div className="p-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex md:flex-row flex-col items-center w-full"
        >
          <div className="flex flex-col gap-5 md:flex-1 md:mr-5 w-full md:mb-0 mb-5">
            <Controller
              name="handlingUnit"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="handlingUnit"
                  label="Select Unit"
                  className={`form_control`}
                  labelClassName="form_label block mb-1.5"
                  id="handlingUnit"
                  size="sm"
                  options={unitArr}
                  onChangeFunc={(data: any) => onChange(data.value)}
                  isSearchable={false}
                  value={unitArr.find(
                    (handlingUnitOption: any) =>
                      handlingUnitOption.value === parseInt(value) || ''
                  )}
                  errorText={
                    errors.handlingUnit ? errors.handlingUnit.message : null
                  }
                />
              )}
            />

            <Controller
              name="totalWeight"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label={`Total weight (${getLabelByValue(
                    weightMeasurement
                  )?.toLowerCase()})`}
                  inputName="totalWeight"
                  placeholder="Enter Total weight"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  disabled={true}
                  value={value}
                  // disabled={true}
                  readOnly
                  onChangeFunc={onChange}
                  shouldFormatNumber={true}
                  inputValueUnitType={getLabelByValue(
                    weightMeasurement
                  )?.toLowerCase()}
                  errorText={
                    errors.totalWeight ? errors.totalWeight.message : null
                  }
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />

            <Controller
              name="itemLength"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label={`Length (${getLabelByValue(
                    lengthMeasurement
                  )?.toLowerCase()})`}
                  inputName="itemLength"
                  inputType="number"
                  placeholder="Enter length"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  onChangeFunc={onChange}
                  shouldFormatNumber={true}
                  inputValueUnitType={getLabelByValue(
                    lengthMeasurement
                  )?.toLowerCase()}
                  errorText={
                    errors.itemLength ? errors.itemLength.message : null
                  }
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />

            <Controller
              name="width"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label={`Width (${getLabelByValue(
                    lengthMeasurement
                  )?.toLowerCase()})`}
                  inputName="width"
                  inputType="number"
                  placeholder="Enter width"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  shouldFormatNumber={true}
                  inputValueUnitType={getLabelByValue(
                    lengthMeasurement
                  )?.toLowerCase()}
                  onChangeFunc={onChange}
                  errorText={errors.width ? errors.width.message : null}
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />

            <Controller
              name="height"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label={`Height (${getLabelByValue(
                    lengthMeasurement
                  )?.toLowerCase()})`}
                  inputName="height"
                  inputType="number"
                  placeholder="Enter height"
                  parentClassName="flex-1"
                  className="p-2 flex-1 h-[unset]"
                  value={value}
                  onChangeFunc={onChange}
                  shouldFormatNumber={true}
                  inputValueUnitType={getLabelByValue(
                    lengthMeasurement
                  )?.toLowerCase()}
                  errorText={errors.height ? errors.height.message : null}
                  labelClassName="form_label block mb-1.5"
                />
              )}
            />
          </div>
          <div className="md:w-[50%] w-full h-auto px-5">
            <div className="md:h-full w-full">
              <Controller
                name="height"
                control={control}
                render={() => (
                  <ThreeJsScene
                    height={Number(getValues('height') ?? 0) / 10}
                    width={Number(getValues('width') ?? 0) / 10}
                    depth={Number(getValues('itemLength') ?? 0) / 10}
                  />
                )}
              />
            </div>
          </div>
        </form>
        <div className="w-full flex sm:flex-row flex-col -mx-2.5 sm:my-4">
          <div className="sm:w-1/3 rounded-lg border border-borderPrimary shadow-xs px-6 py-2 text-center mx-2.5 sm:my-0 my-2 w-full">
            <h6 className="text-textSecondary text-xs font-normal mb-1">
              Total Volume of Shipment
            </h6>
            <p className="text-primary text-xs font-semibold">
              {getFormattedNumber(totalObj.cubicFeet)} cubic ft.
            </p>
          </div>
          <div className="sm:w-1/3 rounded-lg border border-borderPrimary shadow-xs px-6 py-2 text-center mx-2.5 sm:my-0 my-2 w-full">
            <h6 className="text-textSecondary text-xs font-normal mb-1">
              Total Density of Shipment
            </h6>
            <p className="text-primary text-xs font-semibold">
              {getFormattedNumber(totalObj.density)}{' '}
              {getLabelByValue(weightMeasurement)?.toLowerCase()}/cubic ft.
            </p>
          </div>
          <div className="sm:w-1/3 rounded-lg border border-borderPrimary shadow-xs px-6 py-2 text-center mx-2.5 sm:my-0 my-2 w-full">
            <h6 className="text-textSecondary text-xs font-normal mb-1">
              Freight Density Class
            </h6>
            <p className="text-primary text-xs font-semibold">
              {getFormattedNumber(totalObj.freightClass)}
            </p>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default VisualizeUnit;
