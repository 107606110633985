import { SearchLg, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { CallHistoryList } from 'src/services/CallCenterService';

import CallHistoryLoaderRow from './CallHistoryLoaderRow';
import CallHistoryRow from './CallHistoryRow';

const initParams: any = {
  search: '',
  sortType: 'desc',
  page: 1,
  limit: 50,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const CallHistory = () => {
  const [callHistory, setCallHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [callHistoryParams, setCallHistoryParams] = useState<any>(initParams);
  const { id } = useParams();
  const navigate = useNavigate();

  const headCells = [
    { id: 'call', name: 'Call', sortable: true },
    { id: 'date', name: 'Date', sortable: true },
    { id: 'time', name: 'Time', sortable: true },
    { id: 'call_recording', name: 'Call recording', sortable: true },
    { id: 'action', name: '', sortable: false },
  ];

  const getCallHistoryData = (signal?: any) => {
    setCallHistory([]);
    setIsLoading(true);

    CallHistoryList(callHistoryParams, id, signal)
      .then((response: any) => {
        if (response?.data) {
          setCallHistory(response?.data);
        }
        setTotal(response.total ?? 0);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setCallHistory([]);
        setTotal(0);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getCallHistoryData(signal);

    return () => {
      controller.abort();
    };
  }, [callHistoryParams]);

  const handleClose = () => {
    navigate(PATH.CONTACTS);
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title={'Contacts'}
          desc={`Lorem Ipsum has been the industry's standard dummy text`}
          rightSideContent={
            <ButtonCmp
              onClick={handleClose}
              className="btn_secondary_black lg:!px-[9px] !px-2"
            >
              <XClose className="w-4 h-4" />
            </ButtonCmp>
          }
        />
      }
    >
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className="table-title-wrap">
              <h5 className="table-title">Call History</h5>
              <BadgeCmp
                style="modern"
                type="success"
                // mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {total} Call History
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              Lorem Ipsum has been the industrys standard dummy text
            </p>
          </div>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left">
            <InputText
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={callHistoryParams.search}
              inputType="text"
              isClearable={true}
              onChangeFunc={(e) => {
                setCallHistoryParams((old: any) => ({
                  ...old,
                  ...{ search: e.target.value, page: 1 },
                }));
              }}
              parentClassName="table-searchInput"
            />
          </div>
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setCallHistoryParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === callHistoryParams.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={headCells}
              // params={params}
              // setParams={setParams}
              tableDataArr={callHistory}
              TableRowCmp={CallHistoryRow}
              // tableRowCmpProps={{
              //   handleCall: handleCall,
              // }}
              TableLoaderRowCmp={CallHistoryLoaderRow}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={15}
              tableHeaderClass=""
            />
          </div>
          {!isLoading && !callHistory.length && (
            <NotFoundUI
              title="No Call History Found"
              desc="There are no call history found."
              containerClassName="min-h-[unset] !h-auto flex-1"
            />
          )}
        </div>
        <CustomPagination
          recordsPerPage={callHistoryParams.limit}
          totalRecords={total}
          currentPage={callHistoryParams.page}
          handlePagination={(page: number) => {
            setCallHistoryParams((old: any) => ({ ...old, page }));
          }}
        />
      </div>
    </PageSectionLayout>
  );
};

export default CallHistory;
