import { DownloadCloud02, Eye } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo } from 'react';

import pdfIcon2 from '../../../../assets/img/pdf-icon.webp';
import ButtonCmp from '../../../../components/ButtonCmp';
import { PDF_LANGUAGE } from '../../../../constants/common';
import {
  capitalizeFirstLetterSpace,
  downloadPdf,
} from '../../../../utils/CommonFunctions';

const PdfRow = ({ data, pdfLanguage }: any) => {
  const viewPdf = (pdfData: any) => () => {
    if (pdfData?.url && pdfData?.file) {
      let url: string = pdfData?.url + pdfData?.file;

      if (
        pdfLanguage === PDF_LANGUAGE.FRE &&
        pdfData?.frenchUrl &&
        pdfData?.frenchFile
      ) {
        url = pdfData?.frenchUrl + pdfData?.frenchFile;
      }
      window.open(url);
    }
  };

  const download = (pdfData: any) => () => {
    if (pdfData?.url && pdfData?.file) {
      let url: string = pdfData?.url + pdfData?.file;
      let name: string = `${pdfData?.name}.pdf`;

      if (
        pdfLanguage === PDF_LANGUAGE.FRE &&
        pdfData?.frenchUrl &&
        pdfData?.frenchFile
      ) {
        url = pdfData?.frenchUrl + pdfData?.frenchFile;
      }
      downloadPdf(url, name);
    }
  };

  return (
    <>
      <td className="w-52 min-w-52 max-w-52">
        <div className="flex items-center gap-3">
          <img
            src={pdfIcon2}
            className="w-9 h-9 flex-none"
            alt={data.name}
            title={data.name}
          />
          <p className="font-medium text-grayLight900 truncate">
            {data.name}.pdf
          </p>
        </div>
      </td>
      <td>{capitalizeFirstLetterSpace(data.pdfLanguage)}</td>
      <td>{data.pdfCurrency}</td>
      <td>{moment(data.createdAt).format('DD MMM YYYY')}</td>
      <td className="w-[115px] min-w-[115px]">
        <div className="flex gap-1">
          <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2  ">
            <Eye className="w-4 h-4" onClick={viewPdf(data)} />
          </ButtonCmp>
          <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2  ">
            <DownloadCloud02 className="w-4 h-4" onClick={download(data)} />
          </ButtonCmp>
        </div>
      </td>
    </>
  );
};
export default memo(PdfRow);
