import { yupResolver } from '@hookform/resolvers/yup';
// import { Calendar } from '@untitled-ui/icons-react/build/cjs';
// import { CalendarCheck01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CheckBox from 'src/components/CheckBox';
// import DateSelect from 'src/components/DateSelect/DateSelect';
// import DateTimePicker from 'src/components/DateTimePicker';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
// import { DATE_FORMAT } from 'src/constants/common';
import {
  createdLoadLinkPosting,
  getLoadLinkVehicleAttribute,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import {
  // getDateWithSuffixFormat,
  // getLocalFormattedPickupDate,
  setNumberWithCommas,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import { QUOTE_CLASSIFICATION } from '../../../../../constants/common';

import { QuoteType } from './LoadBoard.interface';
import TruckFindingAi from './TruckFindingAi';

const today = new Date();
today.setHours(0, 0, 0, 0);
const radiusOptions = [
  {
    label: '50 Miles',
    value: 50,
  },
  {
    label: '100 Miles',
    value: 100,
  },
  {
    label: '150 Miles',
    value: 150,
  },
  {
    label: '200 Miles',
    value: 200,
  },
];

interface IProps {
  addressDetails: any;
  validatePostToLoadBoardForm: any;
  setValidatePostToLoadBoardForm: any;
  setIsPostCreateLoading: any;
  setIsRefresh: any;
  loadLinkPostingDetail?: any;
  isUpdateLoadPost: any;
  setIsUpdateLoadPost: any;
  setIsCreateLoadPost: any;
  rateGatheringWithAi: any;
  setShowTimerLoader: any;
  vehicleSizeOptions: any;
  setCurrentTab: any;
}

enum AddressDetails {
  ORIGIN = 'Origin',
  DESTINATION = 'destination',
}

enum EquipmentDetails {
  EQUIPMENTTYPES = 'equipmentTypes',
  ADDITIONAL = 'additional',
}

const CreateLoadPostingFrom = ({
  addressDetails,
  validatePostToLoadBoardForm,
  setValidatePostToLoadBoardForm,
  setIsPostCreateLoading,
  setIsRefresh,
  loadLinkPostingDetail,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
  setIsCreateLoadPost,
  rateGatheringWithAi,
  setShowTimerLoader,
  vehicleSizeOptions,
  setCurrentTab,
}: IProps) => {
  const loadPostingSchema = yup.object().shape({
    availabilityDate: yup
      .date()
      .required('Pickup date is required')
      .min(today, 'Pickup date cannot be in the past'),
    serviceType: yup.string().required('Service type is required'),
    vehicleAttribute: yup.array(),
    sourceState: yup.string().required('Origin state is required'),
    sourceCity: yup.string().required('Origin city is required'),
    sourceRadius: yup.number().required('Source radius is required.'),
    destinationState: yup.string().required('Destination state is required'),
    destinationCity: yup.string().required('Destination city is required'),
    destinationRadius: yup.number().required('Destination radius is required.'),
    totalWeight: yup.string().required('Total weight is required.'),
    specialInstructions: yup.string(),
    commodity: yup.string(),
    linearFootage: yup
      .string()
      .when([], (field, schema) =>
        addressDetails?.classification === QUOTE_CLASSIFICATION.LTL
          ? schema.required('Linear footage is required')
          : schema
      ),
  });

  const abortControllerRef = useRef<AbortController | null>(null);
  const [postServiceType, setPostServiceType] = useState<string>(
    loadLinkPostingDetail?.serviceType || addressDetails?.classification || ''
  );
  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(true);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);

  const [isVehicleAttrLoading, setIsVehicleAttrLoading] = useState(true);
  const [vehicleAttrOptions, setVehicleAttrOptions] = useState<any[]>([]);

  const [postCreatePayload, setPostCreatePayload] = useState({});
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('Primary');
  const [primaryEquipmentType, setPrimaryEquipmentType] = useState(
    loadLinkPostingDetail?.primaryEquipmentType
      ? loadLinkPostingDetail?.primaryEquipmentType
      : addressDetails?.primaryEquipmentType
      ? addressDetails.primaryEquipmentType
      : ''
  );
  const [primaryEquipmentTypeFullName, setPrimaryEquipmentTypeFullName] =
    useState('');
  const [primaryEquipmentTypeError, setPrimaryEquipmentTypeError] =
    useState(false);
  const [secondaryEquipmentType, setSecondaryEquipmentType] = useState<any[]>(
    loadLinkPostingDetail?.secondaryEquipmentType
      ? loadLinkPostingDetail?.secondaryEquipmentType.split(',') || ''
      : addressDetails?.secondaryEquipmentTypes
      ? addressDetails.secondaryEquipmentTypes.split(',')
      : []
  );
  const [secondaryEquipmentTypeFullName, setSecondaryEquipmentTypeFullName] =
    useState<any[]>([]);
  const [vehicleAttributeFullNames, setVehicleAttributeFullNames] = useState<
    any[]
  >([]);
  const [isShowTruckFindingAiModal, setIsShowTruckFindingAiModal] =
    useState(false);
  const [addressDetail, setAddressDetail] = useState<any>(
    AddressDetails.ORIGIN
  );
  const [equipmentDetail, setEquipmentDetail] = useState<any>(
    EquipmentDetails.EQUIPMENTTYPES
  );
  const specialInstructionMaxLength = 17;
  // const [pickupTime, setPickupTime] = useState('');
  // const [window, setWindow] = useState(0);
  // const [tomorrowDayName, setTomorrowDayName] = useState('');

  const addressDetailArr = [
    {
      value: AddressDetails.ORIGIN,
      name: 'Origin',
    },
    {
      value: AddressDetails.DESTINATION,
      name: 'Destination',
    },
  ];

  const equipmentDetailArr = [
    {
      value: EquipmentDetails.EQUIPMENTTYPES,
      name: 'Equipment Types',
    },
    {
      value: EquipmentDetails.ADDITIONAL,
      name: 'Additional Services',
    },
  ];

  const classOptions = [
    {
      label: 'LTL',
      value: QUOTE_CLASSIFICATION.LTL,
    },
    {
      label: 'FTL',
      value: QUOTE_CLASSIFICATION.FTL,
    },
  ];

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const {
    handleSubmit,
    getValues,
    control,
    trigger,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(loadPostingSchema),
    defaultValues: {
      availabilityDate: new Date(),
      serviceType:
        loadLinkPostingDetail?.serviceType ||
        addressDetails?.classification ||
        '',
      vehicleAttribute: loadLinkPostingDetail?.vehicleAttribute
        ? loadLinkPostingDetail?.vehicleAttribute?.split(',') || []
        : [],
      sourceState:
        loadLinkPostingDetail?.sourceState ||
        addressDetails?.shipperStateCode ||
        '',
      sourceCity:
        loadLinkPostingDetail?.sourceCity || addressDetails?.shipperCity || '',
      sourceRadius: loadLinkPostingDetail?.sourceRadius || 200,
      destinationState:
        loadLinkPostingDetail?.destinationState ||
        addressDetails?.consigneeStateCode ||
        '',
      destinationCity:
        loadLinkPostingDetail?.destinationCity ||
        addressDetails?.consigneeCity ||
        '',
      destinationRadius: loadLinkPostingDetail?.destinationRadius || 200,
      specialInstructions: loadLinkPostingDetail?.specialInstructions || '',
      commodity: loadLinkPostingDetail?.commodity || '',
      totalWeight:
        (loadLinkPostingDetail && loadLinkPostingDetail.totalWeight
          ? `${setNumberWithCommas(loadLinkPostingDetail.totalWeight)} lbs`
          : '') ||
        (addressDetails && addressDetails.totalWeight
          ? `${setNumberWithCommas(addressDetails.totalWeight)} lbs`
          : '') ||
        '',
      linearFootage:
        (addressDetails?.classification === QUOTE_CLASSIFICATION.LTL &&
          (loadLinkPostingDetail && loadLinkPostingDetail.linearFootage
            ? `${setNumberWithCommas(loadLinkPostingDetail.linearFootage)} feet`
            : '')) ||
        (addressDetails && addressDetails.linearFootage
          ? `${setNumberWithCommas(addressDetails.linearFootage)} feet`
          : '') ||
        '',
    },
  });

  const onSubmit = (data: any) => {
    const vehicleSize = vehicleSizeOptions.find((size: any) =>
      size.label.toLowerCase() ==
      [QUOTE_CLASSIFICATION.LTL, QUOTE_CLASSIFICATION.VOLUME_LTL].includes(
        postServiceType
      )
        ? 'l'
        : 'tl'
    );
    console.log('vehicleSize', vehicleSize);

    const formDataPayload = {
      quoteId: addressDetails?.id,
      availabilityDate: moment.utc(data?.availabilityDate),
      pickupTime: '',
      window: 0,
      vehicleSize: vehicleSize?.value,
      serviceType: postServiceType,
      primaryEquipmentType,
      primaryEquipmentTypeFullName: primaryEquipmentTypeFullName.toString(),
      secondaryEquipmentType: secondaryEquipmentType.toString(),
      secondaryEquipmentTypeFullName: secondaryEquipmentTypeFullName.toString(),
      vehicleAttribute: data?.vehicleAttribute?.toString(),
      vehicleAttributeFullNames: vehicleAttributeFullNames.toString(),
      specialInstructions: data?.specialInstructions,
      sourceState: data?.sourceState,
      sourceCity: data?.sourceCity,
      sourceRadius: data?.sourceRadius,
      destinationState: data?.destinationState,
      destinationCity: data?.destinationCity,
      destinationRadius: data?.destinationRadius,
      totalWeight: data.totalWeight
        ? data.totalWeight.replace(/,|lbs/g, '').trim()
        : null,
      linearFootage: data.linearFootage
        ? data.linearFootage.replace(/,|feet/g, '').trim()
        : null,
      commodity: data?.commodity,
      postId: null,
    };

    if (
      isUpdateLoadPost &&
      loadLinkPostingDetail &&
      loadLinkPostingDetail?.id
    ) {
      formDataPayload.postId = loadLinkPostingDetail?.id;
    }
    setPostCreatePayload(formDataPayload);
    setIsShowTruckFindingAiModal(true);
  };

  const changeEquipmentType = (vehicleType: any) => (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      if (!primaryEquipmentType) {
        setPrimaryEquipmentType(vehicleType.value);
        setPrimaryEquipmentTypeFullName(vehicleType.label);
      } else {
        const secEquipmentTypes =
          secondaryEquipmentType.length > 0 ? [...secondaryEquipmentType] : [];
        let secEquipmentTypeFullName: any =
          secondaryEquipmentTypeFullName.length > 0
            ? [...secondaryEquipmentTypeFullName]
            : [];
        secEquipmentTypes.push(vehicleType.value);
        secEquipmentTypeFullName.push(vehicleType.label);
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    } else {
      if (primaryEquipmentType == vehicleType.value) {
        if (secondaryEquipmentType.length > 0) {
          setPrimaryEquipmentType(secondaryEquipmentType[0]);
          setPrimaryEquipmentTypeFullName(secondaryEquipmentTypeFullName[0]);
          setSecondaryEquipmentType(secondaryEquipmentType.slice(1));
          setSecondaryEquipmentTypeFullName(
            secondaryEquipmentTypeFullName.slice(1)
          );
        } else {
          setPrimaryEquipmentType('');
          setPrimaryEquipmentTypeFullName('');
        }
      } else {
        const secEquipmentTypes = secondaryEquipmentType.filter(
          (item) => item !== vehicleType.value
        );
        const secEquipmentTypeFullName = secondaryEquipmentTypeFullName.filter(
          (item) => item !== vehicleType.value
        );
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    }
  };

  const handleSelectAddressDetails = (name: any) => (event: any) => {
    if (name === AddressDetails.ORIGIN) {
      setValue('sourceRadius', event.value);
    } else {
      setValue('destinationRadius', event.value);
    }
  };

  // const handleDateChange = (event: any) => {
  //   const { value } = event;
  //   setValue('availabilityDate', value);
  // };

  const onTruckFindingAiModalSubmit = (formDataPayload: any) => {
    setIsShowTruckFindingAiModal(false);
    setIsPostCreateLoading(true);
    createdLoadLinkPosting(formDataPayload)
      .then((response: any) => {
        WalToast.success(response?.message);

        if (isUpdateLoadPost) {
          setIsUpdateLoadPost(false);
        } else {
          setIsCreateLoadPost(false);
        }
        setShowTimerLoader(true);
        setCurrentTab(QuoteType.LOAD_BOARD);
        setIsRefresh(true);
      })
      .finally(() => {
        setIsPostCreateLoading(false);
        setValidatePostToLoadBoardForm(false);
      })
      .catch((e: any) => {
        WalToast.error('Something went wrong while creating load link post.');
        console.log(e);
      });
  };

  const getVehicleTypeOptions = () => {
    setIsVehicleTypeLoading(true);
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
        // Set Primary Equipment Type Full name
        const pEquipTypeFNames: any = updatedListVehicleTypes.find(
          (type: any) => primaryEquipmentType == type.value
        );
        if (pEquipTypeFNames)
          setPrimaryEquipmentTypeFullName(pEquipTypeFNames.label);

        const secEquipTypeFNames: any = updatedListVehicleTypes
          .filter((type: any) => secondaryEquipmentType.includes(type.value))
          .map((type: any) => type.label);
        setSecondaryEquipmentTypeFullName(secEquipTypeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Type Error ', e);
      })
      .finally(() => {
        setIsVehicleTypeLoading(false);
      });
  };

  const getVehicleAttributeOptions = () => {
    setIsVehicleAttrLoading(true);

    getLoadLinkVehicleAttribute()
      .then((response: any) => {
        const updatedListVehicleAttribute = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleAttrOptions(updatedListVehicleAttribute);
        const vehicleAttr: any = getValues('vehicleAttribute');
        const vehicleAttributeFNames = updatedListVehicleAttribute
          .filter((type: any) => vehicleAttr.includes(type.value))
          .map((type: any) => type.label);
        setVehicleAttributeFullNames(vehicleAttributeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Attr Error', e);
      })
      .finally(() => {
        setIsVehicleAttrLoading(false);
      });
  };

  const handleCommodityChange = (pEquipmentType: string) => {
    const commodity = getValues('commodity');
    if (commodity) return;

    switch (pEquipmentType) {
      case 'V':
      case 'K':
        setValue('commodity', 'Dry Freight');
        break;
      case 'F':
      case 'S':
      case 'I':
      case 'N':
        setValue('commodity', 'Construction Equipment (Tarp)');
        break;
      case 'R':
        setValue('commodity', 'Produce (Fresh)');
        break;
      case 'L':
      case 'D':
        setValue('commodity', 'Machinery');
        break;
      case 'default':
        break;
    }
  };

  const setDefaultCommodity = () => {
    if (primaryEquipmentType) {
      handleCommodityChange(primaryEquipmentType);
    }
  };

  const validateForm = async () => trigger();

  const handleManualSubmit = async () => {
    const isValid = await validateForm();

    if (!isValid) {
      setValidatePostToLoadBoardForm(false);
    }
  };

  const setTotalWeight = (e: any) => {
    setValue(
      'totalWeight',
      e.target.value ? `${setNumberWithCommas(e.target.value)} lbs` : ''
    );
  };

  const setLinearFootage = (e: any) => {
    setValue(
      'linearFootage',
      e.target.value ? `${setNumberWithCommas(e.target.value)} feet` : ''
    );
  };

  const checkPickupDate = () => {
    // const pickupDate = new Date();
    // const currentDateTime = new Date();
    // pickupDate.setHours(
    //   currentDateTime.getHours(),
    //   currentDateTime.getMinutes(),
    //   currentDateTime.getSeconds(),
    //   currentDateTime.getMilliseconds()
    // );

    // const threePM = new Date();
    // threePM.setHours(15, 0, 0, 0); // 3 PM today

    // const isSameDay = pickupDate.toDateString() === today.toDateString();
    // const isAfterThreePM = pickupDate.getTime() > threePM.getTime();

    // if (isSameDay && isAfterThreePM) {
    //   const tomorrow = moment().add(1, 'days');
    //   const dayName = tomorrow.format('dddd');
    //   setTomorrowDayName(dayName);
    // }
    const currentDay = moment().format('dddd');

    if (currentDay === 'Saturday') {
      const tomorrow: any = moment().add(2, 'days');
      setValue('availabilityDate', tomorrow);
    } else if (currentDay === 'Sunday') {
      const tomorrow: any = moment().add(1, 'days');
      setValue('availabilityDate', tomorrow);
    }
  };

  // const selectNextDay = (val: boolean) => {
  //   if (val) {
  //     if (tomorrowDayName === 'Saturday' || tomorrowDayName === 'Sunday') {
  //       const tomorrow: any = moment().add(3, 'days');
  //       setValue('availabilityDate', tomorrow);
  //     } else {
  //       const tomorrow: any = moment().add(1, 'days');
  //       setValue('availabilityDate', tomorrow);
  //     }
  //   } else {
  //     const currentDay = moment().format('dddd');

  //     if (currentDay === 'Saturday') {
  //       const tomorrow: any = moment().add(2, 'days');
  //       setValue('availabilityDate', tomorrow);
  //     } else if (currentDay === 'Sunday') {
  //       const tomorrow: any = moment().add(1, 'days');
  //       setValue('availabilityDate', tomorrow);
  //     } else {
  //       const currentDate: any = moment();
  //       setValue('availabilityDate', currentDate);
  //     }
  //   }
  // };

  useEffect(() => {
    if (primaryEquipmentType) {
      setSelectedEquipmentType('Secondary');
      setPrimaryEquipmentTypeError(false);
      setDefaultCommodity();
    } else {
      setSelectedEquipmentType('Primary');
    }
  }, [primaryEquipmentType]);

  useEffect(() => {
    getVehicleTypeOptions();
    getVehicleAttributeOptions();
    setDefaultCommodity();
    checkPickupDate();
  }, []);

  useEffect(() => {
    if (validatePostToLoadBoardForm) {
      if (!primaryEquipmentType) {
        validateForm();
        setPrimaryEquipmentTypeError(true);
        setValidatePostToLoadBoardForm(false);
      } else {
        handleManualSubmit();
        handleSubmit(onSubmit)();
      }
    }
  }, [validatePostToLoadBoardForm]);

  const specialInstructions = watch('specialInstructions') || '';
  // const availabilityDateSubmit = (initialDateTime: any) => {
  //   const selectedDate = getValues('availabilityDate');

  //   setValue('availabilityDate', selectedDate);
  //   setPickupTime(initialDateTime.pickupTime);
  //   setWindow(initialDateTime.window);
  // };

  // const resetAvailabilityDate = (initialDateTime: any) => {
  //   const selectedDate: any = new Date(initialDateTime.pickupDate);

  //   setValue('availabilityDate', selectedDate);
  //   setPickupTime(initialDateTime.pickupTime);
  //   setWindow(initialDateTime.window);
  // };

  return (
    <>
      <form className="smd:px-5 px-3 py-3">
        <div className="sm:-mx-1.5 flex flex-wrap gap-y-4">
          {/* first row */}
          <div className="sm:px-1.5 mdm:w-1/2 w-full">
            <div className="bg-gray25 p-4 xl:pb-3 border border-utilityGray200 shadow-xs rounded-xl h-full">
              <h5 className="text-grayLight900 mb-4 text-sm font-medium w-full min-h-[30px] flex items-center">
                Load Details
              </h5>
              <div className="-mx-1 flex flex-wrap gap-y-3">
                <Controller
                  name="serviceType"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <SelectBox
                      parentClassName="single-select-x-scroll w-[132px] min-w-fit px-1.5"
                      name="serviceType"
                      id="serviceType"
                      className="form_control shadow"
                      label="Service Type"
                      labelClassName="form_label mb-1.5 block"
                      size="sm"
                      isSearchable={false}
                      options={classOptions}
                      value={classOptions.filter(
                        (val: any) => getValues('serviceType') === val.value
                      )}
                      onChangeFunc={(e: any) => {
                        onChange(e.value);
                        setPostServiceType(e.value);
                      }}
                    />
                  )}
                />

                {/* <div className="xl:w-[37%] px-1 relative xls:w-1/2 w-full">
                  <label className="form_label block mb-1.5">
                    Availability Date
                    <span className="text-red-600 ms-[2px] leading-4 text-xs">
                      *
                    </span>
                  </label>
                  <Controller
                    name="availabilityDate"
                    control={control}
                    render={() => (
                      <DateTimePicker
                        inputName="availabilityDate"
                        className={`form_control`}
                        placeholder={DATE_FORMAT}
                        dateFormat="dd/MM/yyyy"
                        selected={
                          getValues('availabilityDate')
                            ? moment(getValues('availabilityDate')).toDate()
                            : null
                        }
                        value={getDateWithSuffixFormat(
                          getLocalFormattedPickupDate(
                            getValues('availabilityDate')
                          )
                        )}
                        errorText={''}
                        onChangeFunc={handleDateChange}
                        icon={<Calendar className="h-5 w-5" />}
                        parentClassName="w-full"
                        pickupTime={pickupTime}
                        setPickupTime={setPickupTime}
                        window={window}
                        setWindow={setWindow}
                        availabilityDateSubmit={availabilityDateSubmit}
                        resetAvailabilityDate={resetAvailabilityDate}
                      />
                    )}
                  /> */}
                {/* <p
                    className={`form_label mb-0 flex items-center absolute bottom-0`}
                  >
                    <span className="text-[11px] leading-4 text-primary font-normal flex items-center">
                      {tomorrowDayName === 'Saturday'
                        ? `Set to Monday`
                        : 'Set to Tomorrow'}
                    </span>
                    <a
                      onClick={() => selectNextDay(true)}
                      className="text-[11px] leading-4 cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                    >
                      Yes
                    </a>
                    &nbsp;
                    {'/'}
                    <a
                      onClick={() => selectNextDay(false)}
                      className="text-[11px] leading-4 cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                    >
                      No
                    </a>
                  </p> */}
                {/* <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                    {errors.availabilityDate && (
                      <ErrorMsg errorText={errors.availabilityDate.message} />
                    )}
                  </div>
                </div> */}
                <div className="xl:flex-1 xls:w-1/2 w-full px-1">
                  <label className="form_label mb-1.5 block">
                    Total Weight (lbs)
                    <span className="text-red-600 leading-4">*</span>
                  </label>
                  <div className="form_control p-0 relative !h-auto">
                    <Controller
                      name="totalWeight"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="totalWeight"
                          inputType="text"
                          placeholder="Enter Total Weight"
                          labelClassName="mb-1.5 block"
                          className="!border-0 !h-auto"
                          value={value}
                          required={true}
                          onFocus={() => {
                            setValue(
                              'totalWeight',
                              value.replace(/,|lbs/g, '').trim()
                            );
                          }}
                          onChangeFunc={(e) => {
                            const val = e.target.value;

                            if (/^\d*$/.test(val)) {
                              onChange(val);
                            }
                          }}
                          onBlur={setTotalWeight}
                        />
                      )}
                    />
                  </div>
                  <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                    {errors.totalWeight && (
                      <ErrorMsg errorText={errors.totalWeight.message} />
                    )}
                  </div>
                </div>
                <Controller
                  name="commodity"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      inputName="commodity"
                      placeholder="Enter Commodity"
                      label="Commodity"
                      labelClassName="form_label block mb-1.5"
                      parentClassName="xl:flex-1 w-1/2 px-1"
                      id="commodity"
                      className="form_control"
                      value={value}
                      onChangeFunc={(e) => onChange(e.target.value)}
                      onBlur={(e) => onChange(e.target.value)}
                      required={false}
                    />
                  )}
                />
                {[
                  QUOTE_CLASSIFICATION.LTL,
                  QUOTE_CLASSIFICATION.VOLUME_LTL,
                ].includes(postServiceType) && (
                  <Controller
                    name="linearFootage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="linearFootage"
                        placeholder="Enter Linear Footage"
                        label="Linear Footage"
                        labelClassName="form_label block mb-1.5"
                        parentClassName="xl:flex-1 w-1/2 px-1"
                        id="linearFootage"
                        className="form_control"
                        value={value}
                        onFocus={() => {
                          setValue(
                            'linearFootage',
                            value ? value.replace(/,|feet/g, '').trim() : ''
                          );
                        }}
                        onChangeFunc={(e) => {
                          const val = e.target.value;

                          if (/^\d*$/.test(val)) {
                            onChange(val);
                          }
                        }}
                        onBlur={setLinearFootage}
                        required={true}
                        errorText={
                          errors.linearFootage
                            ? errors.linearFootage.message
                            : null
                        }
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="sm:px-1.5 mdm:w-1/2 w-full">
            <div className="bg-gray25 p-4 border border-utilityGray200 shadow-xs rounded-xl">
              <div className="flex justify-between items-center mb-4 gap-2 w-full">
                <h5 className="text-grayLight900 text-sm font-medium">
                  Address Details
                </h5>
                <TabButton
                  className="!min-h-[30px]"
                  activeClassName="!bg-utilityGray100"
                  tabArray={addressDetailArr}
                  isActive={addressDetail}
                  handleOnClick={(e: any) => {
                    setAddressDetail(e?.target?.dataset?.value);
                  }}
                />
              </div>

              <div className="flex flex-wrap -mx-1 xls:gap-y-8 gap-y-5">
                {addressDetail === AddressDetails.ORIGIN && (
                  <>
                    <Controller
                      name="sourceState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="sourceState"
                          label="State"
                          labelClassName="mb-1.5 block"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => onChange(e.target.value)}
                          onBlur={(e) => onChange(e.target.value)}
                          errorText={
                            errors.sourceState
                              ? errors.sourceState.message
                              : null
                          }
                        />
                      )}
                    />

                    <Controller
                      name="sourceCity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="sourceCity"
                          label="City"
                          labelClassName="mb-1.5 block"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => onChange(e.target.value)}
                          onBlur={(e) => onChange(e.target.value)}
                          errorText={
                            errors.sourceCity ? errors.sourceCity.message : null
                          }
                        />
                      )}
                    />

                    <Controller
                      name="sourceRadius"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          required
                          label="Radius"
                          className={`form_control`}
                          labelClassName="form_label mb-1.5 block"
                          options={radiusOptions}
                          value={radiusOptions.filter(
                            (val: any) =>
                              getValues('sourceRadius') === val.value
                          )}
                          onChangeFunc={handleSelectAddressDetails(
                            AddressDetails.ORIGIN
                          )}
                          parentClassName="sm:flex-1 single-select-x-scroll px-1 "
                        />
                      )}
                    />
                  </>
                )}

                {addressDetail === AddressDetails.DESTINATION && (
                  <>
                    <Controller
                      name="destinationState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="destinationState"
                          label="State"
                          labelClassName="mb-1.5 block"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => onChange(e.target.value)}
                          onBlur={(e) => onChange(e.target.value)}
                          errorText={
                            errors.destinationState
                              ? errors.destinationState.message
                              : null
                          }
                        />
                      )}
                    />
                    <Controller
                      name="destinationCity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="destinationCity"
                          label="City"
                          labelClassName="mb-1.5 block"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => onChange(e.target.value)}
                          onBlur={(e) => onChange(e.target.value)}
                          errorText={
                            errors.destinationCity
                              ? errors.destinationCity.message
                              : null
                          }
                        />
                      )}
                    />
                    <Controller
                      name="destinationRadius"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          required
                          label="Radius"
                          className={`form_control`}
                          labelClassName="form_label mb-1.5 block"
                          options={radiusOptions}
                          value={radiusOptions.filter(
                            (val: any) =>
                              getValues('destinationRadius') == val.value
                          )}
                          onChangeFunc={handleSelectAddressDetails(
                            AddressDetails.DESTINATION
                          )}
                          parentClassName="flex-1 single-select-x-scroll px-1"
                        />
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          {/* second row */}
          <div
            className={`rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full mx-1.5`}
          >
            <div className="flex sm:flex-nowrap flex-wrap justify-between items-center gap-2 w-full pb-4 border-b border-utilityGray200">
              <h5 className="text-grayLight900 text-sm font-medium">
                {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES
                  ? `Select a ${selectedEquipmentType} Equipment Type`
                  : `Select Additional Services`}
              </h5>
              <TabButton
                className="!min-h-[30px]"
                activeClassName="!bg-utilityGray100"
                tabArray={equipmentDetailArr}
                isActive={equipmentDetail}
                handleOnClick={(e: any) => {
                  setEquipmentDetail(e?.target?.dataset?.value);
                }}
              />
            </div>
            {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES && (
              <>
                {isVehicleTypeLoading ? (
                  <div className="flex flex-wrap pt-4 gap-y-3 ">
                    {Array.from({ length: 15 }).map((_, index) => (
                      <div
                        key={index}
                        className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                      >
                        <label className="text-textSecondary text-xs font-medium cursor-pointer custom-loading">
                          Double Drop
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <div
                      className={`flex flex-wrap pt-4 gap-y-3 ${
                        isVehicleTypeLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {vehicleTypeOptions.map(
                        (vehicleType: any, index: number) => (
                          <div
                            className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                            key={`equipment_type_${index}`}
                          >
                            <CheckBox
                              id={`vehicle_type_${vehicleType.value}`}
                              name="vehicleType"
                              checked={
                                primaryEquipmentType == vehicleType.value ||
                                secondaryEquipmentType.includes(
                                  vehicleType.value
                                )
                              }
                              parentClassName={`!w-3.5 !h-3.5`}
                              value={vehicleType.value}
                              onChangeFunc={changeEquipmentType(vehicleType)}
                            />
                            <label
                              htmlFor={`vehicle_type_${vehicleType.value}`}
                              className={`text-textSecondary text-xs font-medium cursor-pointer`}
                            >
                              {vehicleType.label}&nbsp;
                              <span className="text-gray500 text-xs font-normal">
                                {primaryEquipmentType == vehicleType.value
                                  ? '(Primary)'
                                  : secondaryEquipmentType.includes(
                                      vehicleType.value
                                    )
                                  ? '(Secondary)'
                                  : ''}
                              </span>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                    <div className="[&:empty]:hidden [&:empty]:mb-0 mt-5">
                      {primaryEquipmentTypeError && (
                        <ErrorMsg errorText="Primary equipment type is required." />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {equipmentDetail === EquipmentDetails.ADDITIONAL && (
              <div
                className={`flex flex-wrap pt-4 gap-y-3 ${
                  isVehicleAttrLoading ? 'custom-loading' : ''
                }`}
              >
                {vehicleAttrOptions.map(
                  (vehicleAttribute: any, index: number) => (
                    <Controller
                      key={`vehicle_attribute_${index}`}
                      name="vehicleAttribute"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2 ">
                          <CheckBox
                            id={vehicleAttribute.value}
                            name="vehicleAttribute"
                            value={vehicleAttribute.value}
                            checked={value?.includes(vehicleAttribute.value)}
                            onChangeFunc={(event: any) => {
                              const checked = event.target.checked;
                              let vehicleAttributes = value ? [...value] : [];
                              let vehicleAttrFullNames =
                                vehicleAttributeFullNames
                                  ? [...vehicleAttributeFullNames]
                                  : [];

                              if (checked) {
                                vehicleAttributes.push(vehicleAttribute.value);
                                vehicleAttrFullNames.push(
                                  vehicleAttribute.label
                                );
                              } else {
                                vehicleAttributes = vehicleAttributes.filter(
                                  (item) => item !== vehicleAttribute.value
                                );
                                vehicleAttrFullNames =
                                  vehicleAttributeFullNames.filter(
                                    (item) => item !== vehicleAttribute.value
                                  );
                              }
                              onChange(vehicleAttributes);
                              setVehicleAttributeFullNames(
                                vehicleAttrFullNames
                              );
                            }}
                          />
                          <label
                            htmlFor={vehicleAttribute.value}
                            className="text-textSecondary text-xs font-medium  cursor-pointer "
                          >
                            {vehicleAttribute.label}
                          </label>
                        </div>
                      )}
                    />
                  )
                )}
              </div>
            )}
          </div>

          {/* third row */}
          <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full mx-1.5 flex flex-col gap-3">
            <h5 className="text-grayLight900 text-sm font-medium w-full">
              Special Instructions
            </h5>
            <Controller
              name="specialInstructions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="specialInstructions"
                  placeholder={'Add your special instructions'}
                  asTextarea="textarea"
                  parentClassName=""
                  maxLength={specialInstructionMaxLength}
                  rows={1}
                  className="h-[38px]"
                  value={value}
                  onChangeFunc={(e) => onChange(e?.target?.value)}
                />
              )}
            />
            <p className="text-grayLight900 text-xs flex justify-end">
              {specialInstructions?.length}&nbsp;/&nbsp;
              {specialInstructionMaxLength}
            </p>
          </div>
        </div>
      </form>

      {isShowTruckFindingAiModal && (
        <TruckFindingAi
          title={`AI Rate Gathering Settings`}
          postCreatePayload={postCreatePayload}
          truckFindingAiDetail={loadLinkPostingDetail?.truckFindingAi}
          rateGatheringWithAi={rateGatheringWithAi}
          handleClose={() => {
            setIsShowTruckFindingAiModal(false);
            setValidatePostToLoadBoardForm(false);
          }}
          handleModalSubmit={onTruckFindingAiModalSubmit}
        />
      )}
    </>
  );
};

export default CreateLoadPostingFrom;
