import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
// import { Link } from 'react-router-dom';
import TableCmp from 'src/components/TableCmp';
import { STATUS } from 'src/constants/common';
import {
  capitalizeFirstLetter,
  getLabelByValue,
} from 'src/utils/CommonFunctions';

import QuoteDetailSubRow from './QuoteDetailSubRow';
import QuoteDetailTableLoadingRow from './QuoteDetailTableLoadingRow';
import QuoteDetailTableRow from './QuoteDetailTableRow';

const QuoteDetailTable = ({
  isLoading,
  dimensions,
  services,
  addressDetails,
  totalWeight,
  totalUnit,
}: any) => {
  const [quoteDetailData, setQuoteDetailData] = useState<any[]>([]);
  const [isDisplayDimensionsDetail, setIsDisplayDimensionsDetail] =
    useState(false);
  useEffect(() => {
    if (!isLoading) setQuoteDetailData([addressDetails]);
  }, [addressDetails]);

  const weightMeasurement = dimensions?.[0]?.weightMeasurement
    ? getLabelByValue(dimensions[0].weightMeasurement)?.toLowerCase()
    : 'N/A';
  const lengthMeasurement = dimensions?.[0]?.lengthMeasurement ?? 'N/A';

  const headCells = useMemo(
    () => [
      {
        id: 'firtname',
        name: 'User',
        sortable: true,
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
      },
      {
        id: 'serviceType',
        name: 'Service Type',
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
      },
      {
        id: 'order_id',
        name: 'OrderID',
        sortable: true,
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
        visible: addressDetails.status === STATUS.WON,
      },
      {
        id: 'customer',
        name: 'Customer',
        rowClassName: 'min-w-[16%] max-w-[1px] w-[16%]',
      },
      {
        id: 'addresses',
        name: 'Addresses',
        rowClassName: 'min-w-[20%] max-w-[1px] w-[20%]',
      },
      {
        id: 'pickupDate',
        name: 'Pickup Date',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'total_weight',
        name: 'Total Weight',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'total_unit',
        name: 'Total № of HU',
        sortable: true,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'action',
        rowClassName: 'min-w-[65px] w-[65px] max-w-[65px]',
      },
    ],
    [addressDetails]
  );

  const subHeadCells = useMemo(
    () => [
      {
        id: 'no_hu',
        name: '№ of HU',
        rowClassName: 'min-w-[16%] max-w-[1px] w-[16%]',
      },
      {
        id: 'handling_unit',
        name: 'Handling unit',
        rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
      },
      {
        id: 'weight_per_unit',
        name: `Weight per unit (${capitalizeFirstLetter(
          weightMeasurement?.toLowerCase()
        )})`,
        rowClassName: 'min-w-[20%] max-w-[1px] w-[20%]',
      },
      {
        id: 'freight_class',
        name: 'Freight Class',
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'item_class',
        name: 'Item Class',
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'Dimensions',
        name: `Dimensions ${
          lengthMeasurement !== 'N/A'
            ? `(${capitalizeFirstLetter(lengthMeasurement?.toLowerCase())})`
            : ''
        }`,
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'description',
        name: 'Description',
        rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
      },
      {
        id: 'action',
        rowClassName: 'min-w-[65px] w-[65px] max-w-[65px]',
      },
    ],
    [weightMeasurement, lengthMeasurement, dimensions]
  );

  // const serviceHeadCells = useMemo(
  //   () => [
  //     {
  //       id: 'additonal_services',
  //       name: 'Additonal Services',
  //       rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[18%] max-w-[1px] w-[18%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
  //     },
  //     {
  //       id: '',
  //       name: '',
  //       rowClassName: 'min-w-[14%] max-w-[14%] w-[14%]',
  //     },
  //     {
  //       id: '',
  //       rowClassName: 'min-w-[65px] w-[65px] max-w-[65px]',
  //     },
  //   ],
  //   []
  // );

  return (
    <>
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
        {/* {addressDetails.status === STATUS.WON && (
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">Order ID:</h5>
                <Link
                  to={ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId}
                  className="underline text-primary text-base font-medium"
                  onClick={(e: any) => e.preventDefault()}
                >
                  #{addressDetails.rossRocketPublicId}
                </Link>
              </div>
            </div>
          </div>
        )} */}
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
          <TableCmp
            headCells={headCells}
            tableDataArr={quoteDetailData}
            TableLoaderRowCmp={QuoteDetailTableLoadingRow}
            TableRowCmp={QuoteDetailTableRow}
            tableRowCmpProps={{
              dimensions: dimensions,
              isDisplayDimensionsDetail: isDisplayDimensionsDetail,
              setIsDisplayDimensionsDetail: setIsDisplayDimensionsDetail,
              totalUnit: totalUnit,
              totalWeight: totalWeight,
              addressDetails: addressDetails,
            }}
            isTableDataLoading={isLoading}
            onRowClick={() =>
              setIsDisplayDimensionsDetail(!isDisplayDimensionsDetail)
            }
            numberOfSkeletonRows={1}
            isTableRowClickable={true}
          />
          {isDisplayDimensionsDetail && (
            <div className="p-5 empty:hidden">
              {dimensions.length ? (
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                    <TableCmp
                      headCells={subHeadCells}
                      tableDataArr={dimensions}
                      TableRowCmp={QuoteDetailSubRow}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {services.length ? (
                <>
                  <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden mt-5">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                      <div className="text-xs text-grayLight600 font-medium bg-gray50 ">
                        <div className="border-b border-utilityGray200">
                          <div className="px-5 py-3 font-medium">
                            <div className="flex items-center">
                              <span>Additional Services</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-x-2 -mx-5 px-5 py-4">
                        {services.map((val: any) => (
                          <div
                            key={val?.serviceName}
                            className="flex gap-2 text-grayLight900 truncate px-5 py-2.5"
                          >
                            <Check className="w-4 h-4 text-primary flex-none" />
                            <h6 className="truncate text-xs">
                              {' '}
                              {val.serviceName}
                            </h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>

      {/* ACCORDION TABLE DESIGN */}
    </>
  );
};

export default QuoteDetailTable;
