import { yupResolver } from '@hookform/resolvers/yup';
import {
  PackagePlus,
  SearchLg,
  Check,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  // Controller,
  Resolver,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
// import SelectBox from 'src/components/SelectBox/SelectBox';
import NotFoundUI from 'src/components/NotFoundUI';
import { PATH } from 'src/constants/path';
import { CustomerList } from 'src/services/CustomerService';
import { createOrder, getRecentOrders } from 'src/services/OrderService';
import { getShortName } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import { IOrderForm, IOrderProps } from './order.interface';

const schema = yup.object().shape({
  customerId: yup.number().required('Cutomer is required'),
});

export const ORDER_PARAM: any = {
  search: '',
  limit: 5,
};

const CreateOrder = ({ handleClose, onCreate }: IOrderProps) => {
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [recentOrderList, setRecentOrderList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState(ORDER_PARAM);

  const abortControllerRef = useRef<AbortController | null>(null);

  const navigate = useNavigate();

  const {
    handleSubmit,
    // control,
    setValue,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema) as Resolver<IOrderForm>,
    defaultValues: {
      customerId: undefined,
    },
  });

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getCustomerList = () => {
    setCustomerList([]);
    setIsCustomerLoading(true);

    cancelApiCall();

    const orderListApiController = new AbortController();
    const orderListApiSignal = orderListApiController.signal;

    abortControllerRef.current = orderListApiController;

    CustomerList(params, orderListApiSignal)
      .then((response: any) => {
        if (response.data) {
          setCustomerList(response.data);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  const getRecentOrderList = () => {
    setIsCustomerLoading(true);

    getRecentOrders()
      .then((response) => {
        if (response?.data) {
          setRecentOrderList(response?.data);
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsCustomerLoading(false);
      });
  };

  useEffect(() => {
    // getCustomerList();
    getRecentOrderList();
  }, []);

  useEffect(() => {
    if (params?.search !== '') {
      getCustomerList();
    }
  }, [params]);

  const onSubmit = async (formData: any) => {
    setIsLoading(true);

    createOrder(formData)
      .then((response) => {
        if (response?.data) {
          if (onCreate) {
            navigate(`${PATH.ORDER_DETAILS}/${response?.data?.id}`);
            onCreate();
          } else {
            navigate(`${PATH.ORDER_DETAILS}/${response?.data?.id}`);
          }
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchDebounce = useCallback(
    debounce((searchData: string) => {
      setParams((old: any) => ({ ...old, ...{ search: searchData } }));

      if (searchData === '') {
        setCustomerList([]);
      }
    }, 400),

    []
  );

  return (
    <CommonModal
      title={'Create New Order'}
      titleDesc={'Select a customer to get started with this order'}
      handleClose={handleClose}
      size={'max-w-[540px] h-[526px]'}
      headerIcon={<PackagePlus />}
      primaryBtnText={'Create Order'}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        {/* <Controller
          name="customerId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SelectBox
              label="Select Customer"
              labelClassName="form_label block mb-1.5"
              name="customerId"
              id="customerId"
              size="sm"
              placeholder="All Customers"
              noOptionMessage="No Customers Found"
              isSearchable={true}
              className="form_control"
              isClearable={true}
              options={customerList}
              onChangeFunc={(e: any) => onChange(e?.value)}
              value={customerList?.find((e) => e?.value == value)}
              isLoading={isCustomerLoading}
              errorText={errors.customerId ? errors.customerId.message : null}
            />
          )}
        /> */}

        <InputText
          label="Select a customer"
          labelClassName="block mb-1.5"
          inputName="searchQuote"
          placeholder="Search customer"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input form_control"
          icon={
            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
          }
          value={search}
          inputType="text"
          onChangeFunc={(e) => {
            setSearch(e?.target?.value);
            searchDebounce(e?.target?.value);
          }}
          isClearable={true}
        />

        {!isCustomerLoading &&
          customerList?.length === 0 &&
          params.search !== '' && (
            <NotFoundUI
              title="No Customer Found"
              desc="There are no data for customers."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}

        {!isCustomerLoading &&
          params.search === '' &&
          recentOrderList &&
          recentOrderList?.length > 0 && (
            <>
              {/* <h6 className="mt-6 form_label block mb-1.5">Recent</h6> */}
              <ul className="flex flex-col mt-1.5">
                {recentOrderList.map((order: any, index: number) => (
                  <li
                    className="p-3 flex items-center gap-x-3 cursor-pointer bg-transparent hover:bg-blue25 border-b border-utilityGray200"
                    key={index}
                    onClick={() => {
                      setValue('customerId', order?.customerId);
                      setSelectedCustomer(order);
                    }}
                  >
                    {order?.image ? (
                      <img
                        src={order?.imageUrl + order?.image}
                        className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                        {getShortName(order?.name)}
                      </div>
                    )}
                    <h6 className="text-grayLight900 text-sm font-medium flex-1">
                      {order?.name}
                    </h6>

                    {selectedCustomer?.customerId === order?.customerId && (
                      <Check className="w-4 h-4 text-primary cursor-pointer ml-auto" />
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}

        {isCustomerLoading ? (
          <>
            {/* <h6 className="mt-6 form_label block mb-1.5">Result</h6> */}
            <ul className="flex flex-col pointer-events-none mt-1.5">
              {[...Array(5)].map((_, index) => (
                <li
                  className="p-3 flex items-center gap-x-3 cursor-pointer bg-transparent hover:bg-blue25 border-b border-utilityGray200"
                  key={index}
                >
                  <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm custom-loading">
                    D{index + 1}
                  </div>
                  <h6 className="text-grayLight900 text-sm font-medium flex-1 custom-loading">
                    test company test company
                  </h6>
                </li>
              ))}
            </ul>
          </>
        ) : (
          customerList &&
          customerList?.length > 0 && (
            <>
              {/* <h6 className="mt-6 form_label block mb-1.5">Result</h6> */}
              <ul className="flex flex-col mt-1.5">
                {customerList.map((customer: any, index: number) => (
                  <li
                    className="p-3 flex items-center gap-x-3 cursor-pointer bg-transparent hover:bg-blue25 border-b border-utilityGray200"
                    key={index}
                    onClick={() => {
                      setValue('customerId', customer?.id);
                      setSelectedCustomer(customer);
                    }}
                  >
                    {customer?.image ? (
                      <img
                        src={customer?.imageUrl + customer?.image}
                        className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                        {getShortName(customer?.name)}
                      </div>
                    )}
                    <h6 className="text-grayLight900 text-sm font-medium flex-1">
                      {customer?.name}
                    </h6>

                    {selectedCustomer?.id === customer?.id && (
                      <Check className="w-4 h-4 text-primary cursor-pointer flex-none" />
                    )}
                  </li>
                ))}
              </ul>
            </>
          )
        )}
      </div>
    </CommonModal>
  );
};

export default CreateOrder;
