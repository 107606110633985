import React from 'react';

import Appointment from './Appointment';

interface IProps {
  isValidAddress: any;
  setIsValidAddress: any;
}

const Step2 = ({ isValidAddress, setIsValidAddress }: IProps) => (
  <>
    {/* <AddressDetails /> */}
    <Appointment
      isValidAddress={isValidAddress}
      setIsValidAddress={setIsValidAddress}
    />
  </>
);

export default Step2;
