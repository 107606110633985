// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FilterLines,
  SearchSm,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
// import mapboxgl from 'mapbox-gl';
import React, { useState, useEffect, useContext } from 'react';
import { scriptId } from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address.interface';
import { BasicContext } from 'src/context/BasicContext';
import WalToast from 'src/utils/WalToast';

import AutoCompleteGoogle from '../AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from '../AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from '../ButtonCmp';
import ErrorMsg from '../errorMsg';
import InputText from '../InputText/InputText';

const initValidForm = {
  from: true,
  to: true,
  linearFootage: true,
  serviceType: true,
  equipmentId: true,
  classification: true,
};

const SearchBox = ({
  hasSearch,
  onSearch,
  setSearchResult,
  setHasSearched,
  isSearchLoading,
  onClear,
  searchResult,
  isDisplayCustomerSearch,
  setParams,
  setIsShowMapFilterModal,
  isShowMapFilterModal,
}: any) => {
  const { mapService, googleKey } = useContext(BasicContext);
  const [isDisplayClear, setIsDisplayClear] = useState(hasSearch);

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [customerName, setCustomerName] = useState(null);
  const [toAddress, setToAddress] = useState('');

  const [isDisplayFromSuggestion, setIsDisplayFromSuggestion] = useState(false);
  const [isDisplayToSuggestion, setIsDisplayToSuggestion] = useState(false);

  const [validForm, setValidForm] = useState(initValidForm);
  const [isValid, setIsValid] = useState(false);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      from: true,
      to: true,
    };
    // console.log('searchResult dfdf', searchResult);

    if (from === '' || from === null) {
      validFormData.from = false;
      valid = false;
    }

    if (to === '' || to === null) {
      validFormData.to = false;
      valid = false;
    }

    // console.log('validFromData', validFormData);
    setValidForm({ ...validForm, ...validFormData });
    setIsValid(valid);
    // console.log('valid', valid);

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (isDisplayCustomerSearch) {
      console.log('search2');
      setIsDisplayClear(true);
      setParams((old: any) => ({
        ...old,
        search: customerName,
      }));
    } else {
      if (isFormValid()) {
        console.log('search3');
        setIsDisplayClear(true);

        setSearchResult((old: any) => ({
          ...old,
          fromAddressObj: fromAddress,
          toAddressObj: toAddress,
        }));
        onSearch(fromAddress, toAddress);
      } else {
        WalToast.error('Please enter all required fields');
      }
    }
  };

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleOutsideClick = (event: any) => {
    let suggestionBox: any = document.querySelector(`suggestion-box`);

    if (suggestionBox && !suggestionBox.contains(event.targte)) {
      setIsDisplayFromSuggestion(false);
      setIsDisplayToSuggestion(false);
    }
  };

  const clearSearch = () => {
    if (hasSearch) {
      setSearchResult((old: any) => ({
        ...old,
        from: '',
        to: '',
      }));
      setFrom('');
      setTo('');
      setFromAddress('');
      setToAddress('');
      setIsShowMapFilterModal(false);
      setIsDisplayClear(false);
      setCustomerName(null);
      onClear();
    }

    if (isDisplayCustomerSearch) {
      console.log('clear serach false');
      setCustomerName(null);
      setSearchResult((old: any) => ({
        ...old,
        search: '',
      }));
      setParams((old: any) => ({
        ...old,
        search: '',
      }));
      setIsDisplayClear(false);
    }
  };

  useEffect(() => {
    if (isDisplayFromSuggestion) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
  }, [isDisplayFromSuggestion, isDisplayToSuggestion]);

  useEffect(() => {
    isFormValid();
  }, [from, to]);

  useEffect(() => {
    if (customerName === '' || customerName === null) {
      setParams((old: any) => ({
        ...old,
        search: '',
      }));
      setIsDisplayClear(false);
    } else {
      console.log('search1', customerName);
      // setIsDisplayClear(true);
    }
  }, [customerName]);

  useEffect(() => {
    if (isDisplayCustomerSearch) {
      console.log('customerName');
      setCustomerName(null);
      setIsDisplayClear(false);
    }
  }, [isDisplayCustomerSearch]);

  useEffect(() => {
    console.log('isDisplayClear', isDisplayClear);
  }, [isDisplayClear]);

  return (
    // <form onSubmit={handleSubmit}>
    <div className="max-w-[740px] mx-auto shadow-lg px-6 py-4 border border-borderPrimary rounded-2xl bg-white flex items-center relative [&>div:last-child]:pr-0 [&>div:first-child]:pl-0 [&>div]:px-4 [&>div:first-child]:border-l-0 [&>div]:border-l [&>div]:border-utilityGray200 ">
      {isDisplayCustomerSearch ? (
        <>
          <InputText
            parentClassName="flex-1 w-1/5"
            inputName="customerName"
            label="Search Customer"
            labelClassName="text-grayLight900 text-sm font-semibold mb-0 "
            placeholder={'Enter Customer Name'}
            className={`border-0 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0`}
            value={customerName ?? ''}
            required={true}
            onChangeFunc={(e) => setCustomerName(e?.target?.value)}
          />
          <div className="flex gap-x-4">
            {isDisplayClear ? (
              <ButtonCmp
                className="btn_primary rounded-full lg:!px-[9px] !px-2"
                type="button"
                onClick={clearSearch}
                // loading={isSearchLoading}
                // disabled={isSearchLoading}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            ) : (
              <ButtonCmp
                className="btn_primary rounded-full lg:!px-[9px] !px-2"
                type="submit"
                onClick={handleSubmit}
                loading={isSearchLoading}
                disabled={
                  isSearchLoading ||
                  customerName === '' ||
                  customerName === null
                }
              >
                <SearchSm className="w-4 h-4" />
              </ButtonCmp>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex-1 relative w-1/5">
            <label className="form_label text-grayLight900 text-sm font-semibold mb-0 ">
              From
              <span className="text-red-600 ms-[2px] leading-4 text-xs">*</span>
            </label>
            {status &&
              (mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="from"
                  placeholder="Search Origin"
                  require
                  // dropdownWrapClass="!top-[unset] bottom-[65%]"
                  className={`placeholder:text-gray500 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                    !validForm.from ? 'border border-red-500 border-solid' : ''
                  }`}
                  onChangeFunc={(e: any) => {
                    setHasSearched(false);
                    setFrom(e?.fullAddress);
                    setFromAddress(e);
                  }}
                  onBlur={(e: any) => {
                    setFrom(e);
                  }}
                  onAddressInputChange={(e: any) => {
                    setHasSearched(false);
                    setFrom(e);
                  }}
                  errorText={
                    !validForm.from ? 'from address is required' : null
                  }
                  // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                  value={from}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="from"
                  placeholder="Enter Origin"
                  className={`placeholder:text-gray500 pl-0 py-0 pr-7 h-auto border-0 text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                    !validForm.from ? 'border border-red-500 border-solid' : ''
                  }`}
                  listClassName="mapit-address-list"
                  onChangeFunc={(e: any) => {
                    setHasSearched(false);
                    setFromAddress(e);
                  }}
                  onBlur={(e: any) => {
                    setSearchResult((old: any) => ({
                      ...old,
                      from: e.target?.value,
                    }));
                  }}
                  onAddressInputChange={(e: any) => {
                    setHasSearched(false);
                    setFrom(e);
                    setFromAddress(e);
                  }}
                  errorText={
                    !validForm.from ? 'from address is required' : null
                  }
                  value={from}
                />
              ))}
          </div>
          {!validForm.from && <ErrorMsg message="from address is required" />}
          <div className="flex-1 relative w-1/5">
            <label className="form_label text-grayLight900 text-sm font-semibold mb-0 ">
              To{' '}
              <span className="text-red-600 ms-[2px] leading-4 text-xs">*</span>
            </label>
            {status &&
              (mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="to"
                  placeholder="Search Destination"
                  // dropdownWrapClass="!top-[unset] bottom-[65%]"
                  className={`placeholder:text-gray500 pl-0 py-0 pr-7 h-auto text-sm font-normal !shadow-none rounded-none !w-full !outline-0`}
                  onChangeFunc={(e: any) => {
                    setHasSearched(false);
                    setTo(e?.fullAddress ?? '');
                    // setSearchResult((old: any) => ({
                    //   ...old,
                    //   to: e?.fullAddress ?? '',
                    // }));
                    setToAddress(e);
                  }}
                  onBlur={(e: any) => {
                    setTo(e);
                    // setSearchResult((old: any) => ({
                    //   ...old,
                    //   to: e,
                    // }));
                  }}
                  onAddressInputChange={(e: any) => {
                    setTo(e ?? '');
                    setHasSearched(false);
                    // setSearchResult((old: any) => ({
                    //   ...old,
                    //   to: e ?? '',
                    // }));
                  }}
                  errorText={!validForm.to ? 'to address is required' : null}
                  // errorText={!validForm.consigneeAddress ? "Address is required" : null}
                  value={to}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="to"
                  placeholder="Enter Destination"
                  className={`placeholder:text-gray500 pl-0 py-0 pr-7 h-auto border-0 text-sm font-normal !shadow-none rounded-none !w-full !outline-0 ${
                    !validForm.to ? 'border border-red-500 border-solid' : ''
                  }`}
                  listClassName="mapit-address-list"
                  onChangeFunc={(e: any) => {
                    setTo(e?.fullAddress ?? '');
                    setHasSearched(false);
                    // setSearchResult((old: any) => ({
                    //   ...old,
                    //   to: e?.fullAddress ?? '',
                    // }));
                    setToAddress(e);
                  }}
                  onBlur={(e: any) => {
                    setTo(e.target?.value ?? '');
                    // setSearchResult((old: any) => ({
                    //   ...old,
                    //   to: e.target?.value ?? '',
                    // }));
                  }}
                  onAddressInputChange={(e: any) => {
                    setTo(e ?? '');
                    setHasSearched(false);
                    // setSearchResult((old: any) => ({
                    //   ...old,
                    //   to: e ?? '',
                    // }));
                    setToAddress(e);
                  }}
                  errorText={!validForm.to ? 'to address is required' : null}
                  value={to}
                />
              ))}
          </div>

          <div className="flex gap-x-4">
            <ButtonCmp
              className="btn_secondary_black rounded-full lg:!px-[9px] !px-2"
              type="button"
              onClick={() => setIsShowMapFilterModal(!isShowMapFilterModal)}
              // loading={isSearchLoading}
              // disabled={isSearchLoading}
            >
              <FilterLines className="w-4 h-4" />
            </ButtonCmp>

            {isDisplayClear &&
            searchResult?.status === 'success' &&
            hasSearch ? (
              <ButtonCmp
                className="btn_primary rounded-full lg:!px-[9px] !px-2"
                type="button"
                onClick={clearSearch}
                // loading={isSearchLoading}
                // disabled={isSearchLoading}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            ) : (
              <ButtonCmp
                className="btn_primary rounded-full lg:!px-[9px] !px-2"
                type="submit"
                onClick={handleSubmit}
                loading={isSearchLoading}
                disabled={!isValid || isSearchLoading}
              >
                <SearchSm className="w-4 h-4" />
              </ButtonCmp>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchBox;
