import { Zap } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { createCreditIncreaseRequest } from 'src/services/CreditIncreaseRequestService';
import {
  CustomersAllList,
  getCustomerCreditDetails,
} from 'src/services/CustomerService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

interface IProps {
  customerId?: any;
  currentCreditLimit?: any;
  handleClose: any;
}

const RequestCreditIncreaseModal = ({
  customerId,
  currentCreditLimit,
  handleClose,
}: IProps) => {
  // customer list & selected customer related fields
  const [isCustomerListLoading, setIsCustomerListLoading] = useState(true);
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);

  // selected customer's credit related fields
  const [selectedCustomerCreditLimit, setSelectedCustomerCreditLimit] =
    useState(currentCreditLimit);
  const [isCustomerCreditInfoLoading, setIsCustomerCreditInfoLoading] =
    useState(false);

  // main form states
  const [creditIncrease, setCreditIncrease] = useState<any>(0);
  const [requestedCreditLimit, setRequestedCreditLimit] = useState<any>(
    selectedCustomerCreditLimit ?? 0
  );
  const [isRequestIncreaseLimitLoading, setIsRequestIncreaseLimitLoading] =
    useState(false);

  useEffect(() => {
    if (!customerId) {
      // loading customer list
      setIsCustomerListLoading(true);
      CustomersAllList()
        .then((result: any) => {
          if (result.data && result.data.length) {
            const customerSelectBoxOptions = getSelectBoxOptions(
              result.data,
              'id',
              'name'
            );
            setCustomerOptions(customerSelectBoxOptions);
          }
        })
        .finally(() => setIsCustomerListLoading(false))
        .catch(console.error);
    }
  }, [customerId]);

  useEffect(() => {
    if (!customerId && selectedCustomerId) {
      setIsCustomerCreditInfoLoading(true);
      getCustomerCreditDetails(selectedCustomerId)
        .then((response: any) => {
          setSelectedCustomerCreditLimit(response?.data?.creditLimit);
        })
        .catch((e: any) => {
          console.log(e);
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong fetching customer credit details.'
          );
        })
        .finally(() => {
          setIsCustomerCreditInfoLoading(false);
        });
    }
  }, [selectedCustomerId]);

  const handleCreditIncreaseChange = (e: any) => {
    const newCreditIncrease = e?.target?.value;

    // Allow empty value
    if (newCreditIncrease === '') {
      setCreditIncrease('');
      setRequestedCreditLimit('');

      return;
    }

    const parsedCreditIncrease = parseFloat(newCreditIncrease);

    if (!isNaN(parsedCreditIncrease)) {
      setCreditIncrease(parsedCreditIncrease);
      setRequestedCreditLimit(
        selectedCustomerCreditLimit + parsedCreditIncrease
      );
    }
  };

  const handleRequestedCreditLimitChange = (e: any) => {
    const newRequestedCreditLimit = e?.target?.value;

    // Allow empty value
    if (newRequestedCreditLimit === '') {
      setRequestedCreditLimit('');
      setCreditIncrease('');

      return;
    }

    const parsedRequestedCreditLimit = parseFloat(newRequestedCreditLimit);

    if (!isNaN(parsedRequestedCreditLimit)) {
      setRequestedCreditLimit(parsedRequestedCreditLimit);
      setCreditIncrease(
        parsedRequestedCreditLimit - selectedCustomerCreditLimit
      );
    }
  };

  const handleRequestCreditIncreaseLimit = () => {
    setIsRequestIncreaseLimitLoading(true);
    createCreditIncreaseRequest({
      customerId: selectedCustomerId,
      requestedCreditIncrease: creditIncrease,
      requestedTotalCredit: requestedCreditLimit,
    })
      .then((response: any) => {
        WalToast.success(response?.message);
        handleClose(true);
      })
      .finally(() => {
        setIsRequestIncreaseLimitLoading(false);
      })
      .catch((e: any) => {
        console.log('ERROR :', e);
        handleClose(false);
      });
  };

  return (
    <CommonModal
      title={'Request Credit Increase'}
      titleDesc={'Update and mange your sales representative'}
      handleClose={() =>
        isRequestIncreaseLimitLoading ? false : handleClose(false)
      }
      headerIcon={<Zap />}
      size={'max-w-[688px]'}
      isOverflow={true}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Request"
      primaryBtnOnClick={handleRequestCreditIncreaseLimit}
      primaryBtnDisabled={
        isRequestIncreaseLimitLoading ||
        creditIncrease === '' ||
        creditIncrease <= 0
      }
      primaryBtnLoading={isRequestIncreaseLimitLoading}
    >
      <div className="w-full p-5 flex flex-col gap-4">
        {!customerId && (
          <SelectBox
            isClearable
            name="customerId"
            required
            label="Customer"
            className={`form_control`}
            labelClassName="form_label mb-1.5 block"
            isSearchable={true}
            isLoading={isCustomerListLoading}
            options={customerOptions}
            value={customerOptions?.find(
              (op: any) => op.value === selectedCustomerId
            )}
            onChangeFunc={(e: any) => {
              console.log('e ', e);
              setSelectedCustomerId(e?.value);
            }}
            parentClassName=" single-select-x-scroll"
          />
        )}

        <InputText
          label="Current Credit Limit"
          value={selectedCustomerCreditLimit ?? 0}
          inputType="number"
          disabled={true}
          shouldFormatNumber={true}
        />

        <InputText
          label="Credit Increase"
          placeholder="Enter new credit increase"
          value={creditIncrease ?? 0}
          onChangeFunc={handleCreditIncreaseChange}
          inputType="number"
          disabled={!selectedCustomerId || isCustomerCreditInfoLoading}
          shouldFormatNumber={true}
          onFocus={() => {
            if (creditIncrease === 0) {
              setCreditIncrease('');
            }
          }}
          onBlur={() => {
            if (creditIncrease === '') {
              setCreditIncrease(0);
            }
          }}
        />

        <InputText
          label="Requested Credit Limit"
          placeholder="Enter new credit limit"
          value={requestedCreditLimit ?? 0}
          onChangeFunc={handleRequestedCreditLimitChange}
          inputType="number"
          disabled={!selectedCustomerId || isCustomerCreditInfoLoading}
          shouldFormatNumber={true}
          onFocus={() => {
            if (requestedCreditLimit === 0) {
              setRequestedCreditLimit('');
            }
          }}
          onBlur={() => {
            if (requestedCreditLimit === '') {
              setRequestedCreditLimit(0);
            }
          }}
        />
      </div>
    </CommonModal>
  );
};

export default RequestCreditIncreaseModal;
