import {
  ArrowDown,
  ArrowRight,
  MarkerPin01,
  Package,
  Plus,
  Target05,
  Truck02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import { CURRENCY } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { BasicContext } from 'src/context/BasicContext';
import { getOrder } from 'src/services/OrderService';
import {
  // formatAddress,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const ActiveShipments = ({ orderId, handleClose }: any) => {
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [order, setOrder] = useState<any>();
  const { currency } = useContext(BasicContext);

  // function formatLastUpdate(date: any) {
  //   const now: any = new Date();
  //   const lastUpdate: any = new Date(date);
  //   const differenceInMilliseconds = now - lastUpdate;
  //   const differenceInHours = Math.floor(
  //     differenceInMilliseconds / (1000 * 60 * 60)
  //   );
  //   const differenceInDays = Math.floor(differenceInHours / 24);

  //   if (differenceInHours <= 72) {
  //     return `${differenceInHours} hours ago`;
  //   } else if (differenceInDays <= 3) {
  //     return `${differenceInDays} days ago`;
  //   } else {
  //     return `${lastUpdate.toLocaleDateString()}`;
  //   }
  // }

  const getOrderDetail = () => {
    setIsOrderLoading(true);
    console.log('getOrder Detail');
    let param = { id: orderId };
    getOrder(param)
      .then((response: any) => {
        setOrder(response.data ?? []);
        setIsOrderLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrderLoading(false);
      });
  };

  // const getAddressWholeString = (
  //   first: string,
  //   second: string,
  //   third: string
  // ) =>
  //   [first, second, third].filter((e) => e).length
  //     ? [first, second, third].filter((e) => e).toString()
  //     : false;

  useEffect(() => {
    // console.log('Active shipments orderId', orderId);
    getOrderDetail();
  }, [orderId]);

  return (
    <div className="fixed bottom-10 right-10 w-[450px] h-[calc(100vh_-_220px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 ">
        <div className="flex items-start gap-2">
          <div className="bg-utilityGray100 rounded border border-utilityGray200 shadow-xs p-[9px] mt-0.5 w-11 h-11 flex justify-center items-center">
            <Package className="w-6 h-6 text-grayLight600" />
          </div>
          <div className="flex-1">
            <h6
              className={`text-primary text-base font-medium underline ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              #{order?.orderId}
            </h6>
            <div className="text-grayLight900 text-xs font-medium flex items-center mt-1">
              <p className="w-3 h-3 bg-successSecondary rounded-full border-[3px] border-utilitySuccess100 mr-1.5"></p>
              <p
                className={`${isOrderLoading ? 'custom-loading' : ''}`}
                dangerouslySetInnerHTML={{
                  __html: `Last Update: ${
                    order?.updatedAt
                      ? getDateWithSuffixFormat(
                          getFormattedDate(
                            order?.updatedAt,
                            'MMM Do , hh:mm A',
                            true,
                            true
                          )
                        )
                      : '-'
                  }`,
                }}
              />
            </div>
          </div>
          {/* <ButtonCmp className="!p-1.5 btn_secondary_black">
            <Minimize02
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={() => {
                console.log('button :>> ');
                handleClose();
              }}
            />
          </ButtonCmp> */}
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName={`self-center ${
              isOrderLoading ? 'custom-loading' : ''
            }`}
          >
            {order?.orderType?.toUpperCase()}
          </BadgeCmp>

          <ButtonCmp
            className="!p-1.5 !flex items-end !justify-end btn_secondary_black self-center"
            onClick={() => handleClose()}
          >
            <ArrowDown className="w-4 h-4 text-primary cursor-pointer" />
          </ButtonCmp>
        </div>
        <div className="flex mt-3 pt-3 border-t border-utilityGray200">
          <div className="flex-1">
            <p className="text-gray500 text-xs font-normal">From</p>
            <p
              className={`text-textSecondary text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.shipperAddress?.fullAddress}
            </p>
          </div>
          <ButtonCmp className="lg:!px-[6px] lg:py-[6px] btn_secondary_black mx-3 self-center">
            <ArrowRight className="w-4 h-4" />
          </ButtonCmp>
          <div className="pl-3 flex-1">
            <p className="text-gray500 text-xs font-normal">To</p>
            <p
              className={`text-textSecondary text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.consigneeAddress?.fullAddress}
            </p>
          </div>
        </div>
      </div>
      <div className="my-5 px-4  flex flex-col gap-y-5 flex-1 overflow-y-auto  custom-scrollbar-v2 ">
        <ul className="border border-utilityGray200 rounded-lg p-3 flex flex-wrap gap-y-4">
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Customer Name
            </h6>
            <div
              className={`flex gap-x-1 ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.customerImage ? (
                <img
                  src={order?.customerImageUrl + order?.customerImage}
                  className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                />
              ) : (
                <div className="w-4 h-4 bg-primary text-[6px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none leading-[1]">
                  {getShortName(order?.customerName)}
                </div>
              )}

              <p
                className={`text-textSecondary text-xs font-medium ${
                  isOrderLoading ? 'custom-loading' : ''
                }`}
              >
                {order?.customerName}
              </p>
            </div>
          </li>
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Order Created
            </h6>

            <p
              className={`text-textSecondary text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: order?.createdAt
                    ? getDateWithSuffixFormat(
                        getFormattedDate(
                          order?.createdAt,
                          `MMM Do - YYYY, hh:mm A`,
                          true,
                          true
                        )
                      )
                    : '-',
                }}
              ></span>
            </p>
          </li>
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Sales Rep.
            </h6>
            <div
              className={`flex gap-x-1 ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.salesRepFirstName ? (
                <>
                  {order?.salesImage ? (
                    <img
                      src={order?.salesImageUrl + order?.salesImage}
                      onError={onError}
                      className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  ) : (
                    <div className="w-4 h-4 bg-primary text-[6px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none leading-[1]">
                      {getShortName(
                        `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                      )}
                    </div>
                  )}

                  <p
                    className={`text-textSecondary text-xs font-medium ${
                      isOrderLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {`${order?.salesRepFirstName} ${order?.salesRepLastName}`}
                  </p>
                </>
              ) : (
                '-'
              )}
            </div>
          </li>
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Carrier
            </h6>

            <div
              className={`flex gap-x-1 ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.carrierName ? (
                <>
                  {order.carrierImage ? (
                    <img
                      src={order?.carrierImageUrl + order?.carrierImage}
                      onError={onError}
                      className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  ) : (
                    <div className="w-4 h-4 bg-primary text-[6px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none leading-[1]">
                      {getShortName(order?.carrierName)}
                    </div>
                  )}
                  <p className="text-textSecondary text-xs font-medium">
                    {order?.carrierName}
                  </p>
                </>
              ) : (
                '-'
              )}
            </div>
          </li>
        </ul>
        <ul className="border border-utilityGray200 rounded-lg p-3 flex flex-wrap gap-y-4">
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Total Price
            </h6>
            <p
              className={`text-grayLight900 text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.combinedRevenue
                ? getFormattedNumber(
                    currency === CURRENCY.CAD
                      ? order?.combinedRevenue
                      : order?.combinedRevenueUSD,
                    true,
                    true
                  )
                : '$0.00'}
            </p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Total Cost
            </h6>
            <p
              className={`text-grayLight900 text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.combinedCost
                ? getFormattedNumber(
                    currency === CURRENCY.CAD
                      ? order?.combinedCost
                      : order?.combinedCostUSD,
                    true,
                    true
                  )
                : '$0.00'}
            </p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Margin
            </h6>
            <p
              className={`text-grayLight900 text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {order?.combinedMargin
                ? getFormattedNumber(
                    currency === CURRENCY.CAD
                      ? order?.combinedMargin
                      : order?.combinedMarginUSD,
                    true,
                    true
                  )
                : '$0.00'}
            </p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Est. Delivery
            </h6>
            <p
              className={`text-grayLight900 text-xs font-medium ${
                isOrderLoading ? 'custom-loading' : ''
              }`}
            >
              {' '}
              {order?.deliveryDateRequested
                ? moment.utc(order?.deliveryDateRequested).fromNow(true) ===
                  'a day'
                  ? '1 Day'
                  : moment.utc(order.deliveryDateRequested).fromNow(true)
                : ''}
            </p>
          </li>
        </ul>

        <div className="flex items-center justify-between gap-x-2">
          <h6 className="text-textSecondary text-sm font-semibold">
            Order Status
          </h6>
          <Link
            className="btn_secondary_black !px-1.5 !py-[3px]"
            to={ROUTES.RADAR}
          >
            <div className="flex text-xs">
              {' '}
              <Target05 className="w-4 h-4" />
              Open in Radar
            </div>
          </Link>
        </div>
        <ul>
          <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 overflow-hidden">
            <div className="text-right w-20">
              <p className="text-gray500 text-xs font-normal">31 Dec 2023</p>
              <p className="text-gray400 text-[10px] leading-3 font-normal">
                9:42 AM
              </p>
            </div>
            <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-successSecondary">
              <div className="w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary flex justify-center items-center">
                <Plus className="w-3 h-3 text-white" />
              </div>
            </div>

            <div className="flex-1 text-grayLight900 text-xs font-medium">
              Confirmation of pick up at noon
            </div>
          </li>
          <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 overflow-hidden">
            <div className="text-right w-20">
              <p className="text-gray500 text-xs font-normal">15 Feb 2023</p>
              <p className="text-gray400 text-[10px] leading-3 font-normal">
                6:36 PM
              </p>
            </div>
            <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-successSecondary">
              <div className="w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary flex justify-center items-center">
                <ArrowDown className="w-3 h-3 text-white" />
              </div>
            </div>

            <div className="flex-1 text-grayLight900 text-xs font-medium">
              Arrived to processing depot
            </div>
          </li>
          <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 overflow-hidden">
            <div className="text-right w-20">
              <p className="text-gray500 text-xs font-normal">18 Feb 2023</p>
              <p className="text-gray400 text-[10px] leading-3 font-normal">
                9:22 PM
              </p>
            </div>
            <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-successSecondary">
              <div className="w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary flex justify-center items-center">
                <ArrowDown className="w-3 h-3 text-white" />
              </div>
            </div>

            <div className="flex-1 text-grayLight900 text-xs font-medium">
              Arrived to local depot
            </div>
          </li>
          <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 overflow-hidden">
            <div className="text-right w-20">
              <p className="text-gray500 text-xs font-normal">23 Mar 2023</p>
              <p className="text-gray400 text-[10px] leading-3 font-normal">
                12:54 AM
              </p>
            </div>
            <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-utilityGray200">
              <div className="w-[18px] h-[18px] rounded-full bg-fgErrorPrimary flex justify-center items-center">
                <Truck02 className="w-3 h-3 text-white" />
              </div>
            </div>

            <div className="flex-1 text-grayLight900 text-xs font-medium">
              In transit with carrier
            </div>
          </li>
          <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 overflow-hidden">
            <div className="text-right w-20">
              <p className="text-gray500 text-xs font-normal">16 Jun 2023</p>
            </div>
            <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[18px] after:absolute flex items-center justify-center after:bg-utilityGray200">
              <div className="w-[18px] h-[18px] rounded-full bg-primary50 flex justify-center items-center">
                <MarkerPin01 className="w-3 h-3 text-grayLight600" />
              </div>
            </div>

            <div className="flex-1 text-grayLight900 text-xs font-medium">
              Estimated delivery
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ActiveShipments;
