'use client';
import { debounce } from 'lodash';
import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
  type EditorInstance,
  EditorRoot,
  type JSONContent,
} from 'novel';
import { ImageResizer, handleCommandNavigation } from 'novel/extensions';
import { handleImageDrop, handleImagePaste } from 'novel/plugins';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';

import { defaultExtensions } from './extensions';
import GenerativeMenuSwitch from './generative/generative-menu-switch';
import { uploadFn } from './image-upload';
import { ColorSelector } from './selectors/color-selector';
import { LinkSelector } from './selectors/link-selector';
import { MathSelector } from './selectors/math-selector';
import { NodeSelector } from './selectors/node-selector';
import { TextButtons } from './selectors/text-buttons';
import { slashCommand, suggestionItems } from './slash-command';
import { Separator } from './ui/separator';

const hljs = require('highlight.js');

const extensions = [...defaultExtensions, slashCommand];

const TailwindAdvancedEditor = ({
  initialValue,
  handleChange,
  editorWrapClassName,
  editorContentClassName,
  isSetPreviousContent = true,
}: any) => {
  const [initialContent, setInitialContent] = useState<null | JSONContent>(
    initialValue ?? null
  );
  // const [saveStatus, setSaveStatus] = useState('Saved');
  // const [charsCount, setCharsCount] = useState();

  const [openNode, setOpenNode] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openAI, setOpenAI] = useState(false);

  //Apply Codeblock Highlighting on the HTML from editor.getHTML()
  const highlightCodeblocks = (content: string) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    doc.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el);
    });

    return new XMLSerializer().serializeToString(doc);
  };
  const debouncedUpdates = useCallback(
    debounce(async (editor: EditorInstance) => {
      const json = editor.getJSON();
      // setCharsCount(editor.storage.characterCount.words());
      window.localStorage.setItem(
        'html-content',
        highlightCodeblocks(editor.getHTML())
      );
      window.localStorage.setItem('novel-content', JSON.stringify(json));
      const htmlString = editor.getHTML();
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const bodyContent = doc.body.innerHTML;
      // console.log('bodyContent :>> ', bodyContent);
      handleChange(bodyContent, JSON.stringify(json));
      window.localStorage.setItem(
        'markdown',
        editor.storage.markdown.getMarkdown()
      );
      // setSaveStatus('Saved');
    }, 500),
    []
  );

  useEffect(() => {
    if (isSetPreviousContent) {
      const htmlContent = window.localStorage.getItem('html-content') ?? '';
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const bodyContent = doc.body.innerHTML;

      const content = window.localStorage.getItem('novel-content');

      if (htmlContent) {
        handleChange(bodyContent, content);
      }

      if (content) setInitialContent(JSON.parse(content));
    }
    //   else setInitialContent(defaultEditorContent);
  }, []);

  if (!initialContent) return null;

  return (
    <div className="relative w-full max-w-screen-lg">
      <EditorRoot>
        <EditorContent
          initialContent={initialContent}
          extensions={extensions}
          className={`relative min-h-[300px] w-full max-w-screen-lg border-muted bg-white rounded-lg border px-4 py-5 ${editorWrapClassName}`}
          editorProps={{
            handleDOMEvents: {
              keydown: (_view, event) => handleCommandNavigation(event),
            },
            handlePaste: (view, event) =>
              handleImagePaste(view, event, uploadFn),
            handleDrop: (view, event, _slice, moved) =>
              handleImageDrop(view, event, moved, uploadFn),
            attributes: {
              class: `prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full ${editorContentClassName} !px-0`,
            },
          }}
          onUpdate={({ editor }) => {
            debouncedUpdates(editor);
            // setSaveStatus('Unsaved');
          }}
          slotAfter={<ImageResizer />}
        >
          <EditorCommand className="z-50 h-auto max-h-[330px] overflow-y-auto rounded-md border border-muted bg-white px-1 py-2 shadow-md transition-all custom-scrollbar-v2">
            <EditorCommandEmpty className="px-2 text-muted-foreground">
              No results
            </EditorCommandEmpty>
            <EditorCommandList>
              {suggestionItems.map((item: any) => (
                <EditorCommandItem
                  value={item.title}
                  onCommand={(val) => item.command(val)}
                  className="flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-accent aria-selected:bg-accent cursor-pointer"
                  key={item.title}
                >
                  <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-white">
                    {item.icon}
                  </div>
                  <div>
                    <p className="font-medium">{item.title}</p>
                    <p className="text-xs text-muted-foreground">
                      {item.description}
                    </p>
                  </div>
                </EditorCommandItem>
              ))}
            </EditorCommandList>
          </EditorCommand>

          <GenerativeMenuSwitch open={openAI} onOpenChange={setOpenAI}>
            <Separator orientation="vertical" />
            <NodeSelector open={openNode} onOpenChange={setOpenNode} />
            <Separator orientation="vertical" />

            <LinkSelector open={openLink} onOpenChange={setOpenLink} />
            <Separator orientation="vertical" />
            <MathSelector />
            <Separator orientation="vertical" />
            <TextButtons />
            <Separator orientation="vertical" />
            <ColorSelector open={openColor} onOpenChange={setOpenColor} />
          </GenerativeMenuSwitch>
        </EditorContent>
      </EditorRoot>
    </div>
  );
};

export default TailwindAdvancedEditor;
