import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const CallHistoryLoaderRow = () => (
  <>
    <td className="px-5 py-4 w-[24.06%] min-w-[24.06%] max-w-[1px]">
      <div className="h-8 flex items-center gap-3 truncate">
        <p className="capitalize truncate custom-loading">Missed</p>
      </div>
    </td>
    <td className="px-5 py-4 w-[24.06%] min-w-[200px] max-w-[24.06%]">
      <p className="truncate text-grayLight600 w-fit custom-loading">
        Today, 03:40 PM
      </p>
    </td>
    <td className="px-5 py-4 w-[24.06%] min-w-[150px] max-w-[24.06%]">
      <p className="truncate text-grayLight600 w-fit custom-loading">00:00</p>
    </td>
    <td className="px-5 py-2 w-[24.06%] min-w-[400px] max-w-[24.06%]">
      <p className="custom-loading w-fit">No Recording Available</p>
    </td>
    <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
      <div className="w-4 h-4 custom-loading">
        <Trash01 className="w-4 h-4 text-gray400 cursor-pointer" />
      </div>
    </td>
  </>
);

export default CallHistoryLoaderRow;
