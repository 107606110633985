import {
  Calendar,
  Check,
  ChevronDown,
  CurrencyDollar,
  PackageCheck,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import { DATE_FORMAT, MARGIN_TYPE } from 'src/constants/common';
import { CarrierList } from 'src/services/CarrierService';
import { quickQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  getFormattedPickupDate,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

const initRequiredFields = [
  'carrierId',
  'totalCharge',
  'finalCharge',
  'projectedDeliveryDate',
];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const initValidForm = {
  carrierId: false,
  projectedDeliveryDate: false,
  totalCharge: false,
  finalCharge: false,
};

const AddQuickQuote = ({
  carrierListArr,
  quoteId,
  currency,
  serviceList,
  handleClose,
  setIsRefresh,
}: any) => {
  const [isPrimaryBtnClicked, setIsPrimaryBtnClicked] =
    useState<boolean>(false);
  const filterRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({});
  // const [requiredFields] = useState(initRequiredFields);

  const [validForm, setValidForm] = useState<any>(initValidForm);
  const [isValid, setIsValid] = useState<any>(false);
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [isShowCarrierList, setIsShowCarrierList] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [carriers, setCarriers] = useState([]);
  const [filteredCarrierList, setFilteredCarrierList] =
    useState<any>(carrierListArr);
  useEffect(() => {
    setIsValid(Object.values(validForm).every((value) => value));
  }, [validForm]);

  const getShowFilteredCarrierList = (search: string, originalList: any) => {
    const filteredList = originalList.filter((originalData: any) =>
      originalData?.name?.toLowerCase().includes(search.toLowerCase().trim())
    );

    return [...filteredList] as any;
  };

  useEffect(() => {
    if (searchValue !== '') {
      const filteredList = getShowFilteredCarrierList(searchValue, carriers);
      setFilteredCarrierList(filteredList);
    } else {
      setFilteredCarrierList(carrierListArr);
    }
  }, [searchValue]);

  useEffect(() => {
    let services: any = [];

    const serviceListArr = Object.values(serviceList);

    if (serviceListArr && serviceListArr?.length) {
      serviceListArr.forEach((service: any) => {
        services.push({
          [service.slug]: {
            name: service.serviceName,
            value: 0,
            serviceId: service.serviceId,
          },
        });
        // existingRequiredFields.push(service.serviceId.slug);
      });
    }

    setFormData({ ...formData, services: services, currency });
  }, [serviceList]);

  useEffect(() => {
    CarrierList()
      .then((response: any) => {
        setCarriers(response.data);
        // setFilteredCarrierList(response.data);
      })
      .catch(console.error);
  }, []);

  const handleSubmit = async () => {
    setIsPrimaryBtnClicked(true);

    try {
      formData.quoteId = quoteId;
      formData.currency = currency;
      quickQuote(formData)
        .then(() => {
          setFormData({});
          handleClose();
          setIsRefresh(true);
          WalToast.success('Quick quote added successfully', '');
        })
        .finally(() => setIsPrimaryBtnClicked(false))
        .catch(console.error);
    } catch (error) {
      handleClose(false);
      setIsRefresh(true);
    }
  };

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]) => {
      if (initRequiredFields.includes(key)) {
        setValidForm((prevValidForm: any) => ({
          ...prevValidForm,
          [key]: value ? true : false,
        }));
      }
    });
  }, [formData]);

  const handleInputChange = (event: any, isService = false) => {
    const { target, type } = event;
    const { name, value } = target;

    const regex = /^[0-9.]*$/;

    if (
      ['totalCharge', 'marginUnit', 'transitTime', 'margin'].includes(name) &&
      !regex.test(value)
    ) {
      return;
    }

    // if (name === "carrierQuoteId" && !editableFormData.carrierQuoteId) {
    //     return;
    // }

    if (initRequiredFields.includes(name)) {
      setIsValid({ ...isValid, [name]: value ? true : false });
    }

    const existingFormData: any = { ...formData };

    if (!isService) {
      existingFormData[name] = value;
    }

    if (name === 'transitTime') {
      existingFormData.projectedDeliveryDate = moment()
        .clone()
        .add(value, 'days')
        .toDate();
    }

    if (['totalCharge', 'marginUnit', 'margin'].includes(name) || isService) {
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;
      existingFormData.services.forEach((quoteService: any, index: number) => {
        Object.entries(quoteService).forEach(([slug, service]: any) => {
          service.value = service.value || 0;
          additionalCharges +=
            name === slug ? parseValue : parseFloat(service.value);
          existingFormData.services[index][slug] = {
            ...service,
            value: name === slug ? value : parseFloat(service.value),
          };
        });
      });

      existingFormData.additionalCharges = additionalCharges.toFixed(2);
      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;
      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 ? parseFloat(((total / 100) * marginUnit).toFixed(2)) : 0;
      } else {
        marginUnit =
          total > 0 ? parseFloat(((100 * margin) / total).toFixed(2)) : 0;
      }
      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total ? total.toFixed(2) : parseValue.toFixed(2);
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        existingFormData.finalCharge = (total + margin).toFixed(2);
      }

      if (name === 'marginUnit') {
        existingFormData.marginUnit = value;
      } else if (name === 'margin') {
        existingFormData.margin = value;
      }
    }

    setFormData(existingFormData);
  };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';
      const updatedFormData = { ...formData };

      const total =
        parseFloat(updatedFormData?.totalCharge || 0) +
        parseFloat(updatedFormData?.additionalCharges);

      if (newType === '%') {
        const margin = parseFloat(updatedFormData?.margin || 0);
        updatedFormData.marginUnit =
          total > 0 && margin > 0 ? ((margin / total) * 100).toFixed(2) : 0;

        updatedFormData.finalCharge = (total + margin).toFixed(2);
      } else {
        const marginUnit = parseFloat(updatedFormData?.marginUnit || 0);

        updatedFormData.margin =
          total > 0 && marginUnit > 0
            ? ((total * marginUnit) / 100).toFixed(2)
            : 0;

        updatedFormData.finalCharge = (
          total + parseFloat(updatedFormData?.margin)
        ).toFixed(2);
      }

      setFormData(updatedFormData);

      return newType;
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isShowCarrierList &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        event.target.name !== 'searchOrder'
      ) {
        setIsShowCarrierList(false);
      }
    };

    const handleEscapePress = (event: any) => {
      if (isShowCarrierList && event.key === 'Escape') {
        setIsShowCarrierList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowCarrierList]);

  // const [isNotScrollable, setIsNotScrollable] = useState<undefined | boolean>(
  //   undefined
  // );

  // useEffect(() => {
  //   const checkScrollable = () => {
  //     const articleElement = document.getElementById('add_quick_quote');

  //     if (articleElement) {
  //       const isVerticallyScrollable =
  //         articleElement.scrollHeight > articleElement.clientHeight;

  //       const isHorizontallyScrollable =
  //         articleElement.scrollWidth > articleElement.clientWidth;

  //       setIsNotScrollable(
  //         !(isVerticallyScrollable || isHorizontallyScrollable)
  //       );
  //     }
  //   };

  //   checkScrollable();

  //   window.addEventListener('resize', checkScrollable);

  //   return () => {
  //     window.removeEventListener('resize', checkScrollable);
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log('isNotScrollable ', isNotScrollable);
  // }, [isNotScrollable]);

  return (
    <CommonModal
      title="Add a Quick Quote"
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose(false)}
      headerIcon={<PackageCheck />}
      size={`max-w-[480px] overflow-unset`} // ADD "overflow-unset" IN SIZE WHEN SCROLL IS NOT THERE
      isOverflow={false} // ADD isOverflow={false} WHEN SCROLL IS NOT THERE
      modalClassName=""
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={!isValid || isPrimaryBtnClicked}
      primaryBtnOnClick={handleSubmit}
      secondaryBtnOnClick={() => handleClose(false)}
      childrenContainerId={'add_quick_quote'} // should be unique
    >
      <div className="w-full p-5 flex flex-col gap-y-4">
        {/* Warning message */}

        {/* Carrier Info */}

        <div className="w-full relative">
          <InputText
            label="Carrier name"
            labelClassName="block mb-1.5"
            inputName="searchOrder"
            placeholder="Enter carrier name"
            className="placeholder:text-secondaryTxtColor"
            parentClassName=""
            searchIconClass={`!right-8`}
            icon={
              <ChevronDown className="absolute top-1/2 -translate-y-1/2 right-2 text-grayText h-5 w-5" />
            }
            value={carrierName}
            onChangeFunc={(e: any) => {
              setFormData({
                ...formData,
                carrierId: undefined,
              });
              setSearchValue(e.target.value);
              setCarrierName(e?.target?.value);
            }}
            onFocus={() => {
              setIsShowCarrierList(true);
            }}
            // errorText={errors?.orderID?.message ?? null}
            isClearable={true}
          />
          {isShowCarrierList && (
            <div
              id="dropdownDelay"
              ref={filterRef}
              className={`min-w-[262px] z-10 absolute top-15 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px] left-0 top-full`}
            >
              <ul
                className="mt-1.5 mb-[9px] max-h-[180px] overflow-y-auto custom-scrollbar-v2"
                aria-labelledby="dropdownDelayButton"
              >
                {filteredCarrierList?.length > 0 ? (
                  filteredCarrierList
                    .slice(0, 1000)
                    .map((carrier: any, index: number) => (
                      <>
                        <li
                          key={index}
                          className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 hover:text-primary transition-all duration-[0.3s] ${
                            carrier.id === formData?.carrierId
                              ? 'bg-gray50'
                              : ''
                          }`}
                          onClick={() => {
                            setFormData({
                              ...formData,
                              carrierId: carrier?.id,
                            });
                            setCarrierName(carrier?.name);
                            setSearchValue(carrier?.name);
                            // setShowOrdersSearchValue();
                            setIsShowCarrierList(false);
                          }}
                        >
                          <div className="flex justify-between items-center align-middle">
                            {' '}
                            <div className="flex justify-center items-center">
                              {carrier.image ? (
                                <img
                                  src={`${carrier.imageUrl}${carrier.image}`}
                                  className="table-profile-icn-circle-xl !w-6 !h-6 rounded-full"
                                  alt={carrier.name}
                                  title={carrier.name}
                                  onError={onError}
                                />
                              ) : (
                                <div className="table-profile-customer-circle-xl !w-6 !h-6 text-xs rounded-full">
                                  {getShortName(carrier.name)}
                                </div>
                              )}
                              <span className="font-medium mx-2">
                                {carrier?.name}{' '}
                              </span>
                            </div>
                            {carrier.id === formData?.carrierId ? (
                              <Check className="w-4 h-4 ml-2 text-primary" />
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      </>
                    ))
                ) : (
                  <li
                    className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] `}
                  >
                    {' '}
                    <span className="font-semibold mr-2 items-center justify-center flex">
                      No Carriers Found
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {/* <div className="p-1 w-1/2">
              <label className="form_label block mb-1.5">Carrier</label>
              <SelectBox
                name="carrierId"
                placeholder="Carrier Rates"
                parentClassName=" block w-full"
                labelClassName="form_label"
                value={carrierListArr.find(
                  (val: any) => val.value === formData?.carrierId
                )}
                onChangeFunc={(e: any) =>
                  setFormData({
                    ...formData,
                    carrierId: e?.value,
                  })
                }
                options={carrierListArr}
              />
            </div> */}

        <InputText
          label="Carrier quote ID"
          labelClassName="block mb-1.5"
          inputType="text"
          placeholder="Enter carrier quote ID"
          inputName="carrierQuoteId"
          value={formData.carrierQuoteId}
          onChangeFunc={handleInputChange}
          onBlur={handleInputChange}
        />

        <DateSelect
          inputName="projectedDeliveryDate"
          className={`form_control `}
          label="Projected delivery"
          labelClassName="block"
          placeholder={DATE_FORMAT}
          dateFormat="dd/MM/yyyy"
          minDate={moment().toDate()}
          selected={
            formData && formData.projectedDeliveryDate
              ? moment(formData.projectedDeliveryDate).toDate()
              : moment(new Date().toISOString()).toDate()
          }
          errorText={''}
          value={getDateWithSuffixFormat(
            getFormattedPickupDate(formData.projectedDeliveryDate)
          )}
          onChangeFunc={(date: any) => {
            setFormData({
              ...formData,
              projectedDeliveryDate: date?.value,
            });
          }}
          icon={<Calendar className="h-4 w-4 " />}
          calendarIconClass="!text-primary"
          parentClassName="fixed-width-datepicker right-side-datepicker one-month-datepicker "
        />
        <InputText
          label="Base Quote"
          placeholder="Enter base quote"
          labelClassName="block mb-1.5"
          inputType="text"
          inputName="totalCharge"
          className="pl-8"
          parentClassName="w-full"
          value={formData?.totalCharge}
          onChangeFunc={handleInputChange}
          onBlur={handleInputChange}
          icon={<CurrencyDollar className="input-currency-sign" />}
        />
        <div className="max-h-[155px] rounded-lg p-3 border border-utilityGray200 flex flex-col gap-3 overflow-auto custom-scrollbar-v2 empty:hidden">
          {formData?.services?.map((nonQuoteService: any) =>
            Object.entries(nonQuoteService).map(([slug, service]: any) => (
              <>
                <InputText
                  inputName={slug}
                  className="pl-8"
                  parentClassName="w-full"
                  value={service.value}
                  label={service.name}
                  labelClassName="block mb-1.5"
                  icon={<CurrencyDollar className="input-currency-sign" />}
                  onChangeFunc={(e) => handleInputChange(e, true)}
                  onBlur={(e) => handleInputChange(e, true)}
                  onFocus={(e) => handleInputChange(e, true)}
                />
              </>
            ))
          )}
        </div>
        <div className="flex gap-x-3">
          <div className="w-full flex-1">
            <label className="form_label block mb-1.5">Margin</label>
            <div className="form_control p-0 relative !h-auto">
              {activeMarginType === '$' ? (
                <InputText
                  inputName="margin"
                  inputType="text"
                  placeholder="Enter margin"
                  className="!border-0 !h-auto !pl-8 !pr-20"
                  labelClassName="block mb-1.5"
                  value={formData?.margin}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  onFocus={() => {}}
                  icon={<CurrencyDollar className="input-currency-sign" />}
                />
              ) : (
                <InputText
                  inputName="marginUnit"
                  inputType="text"
                  placeholder="Enter margin"
                  className="!border-0 !h-auto !pl-8 !pr-20"
                  labelClassName="block mb-1.5"
                  value={formData?.marginUnit}
                  onChangeFunc={handleInputChange}
                  onBlur={handleInputChange}
                  onFocus={() => {}}
                  icon={<Percent02 className="input-currency-sign" />}
                />
              )}

              <TabButton
                tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                parentClassName="!bg-gray50 !p-[3px]"
                className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                tabArray={tabArray}
                isActive={activeMarginType}
                isTab={true}
                handleOnClick={handleTabChange}
              />
            </div>
          </div>

          <InputText
            inputType="text"
            label="Total Price"
            inputName="finalCharge"
            placeholder="Enter total Price"
            className="pl-8"
            labelClassName="block mb-1.5"
            parentClassName="w-full flex-1"
            value={formData?.finalCharge}
            onChangeFunc={handleInputChange}
            onBlur={handleInputChange}
            icon={<CurrencyDollar className="input-currency-sign" />}
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default AddQuickQuote;
