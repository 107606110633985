import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import ChatBot from 'src/app/AiChatbot/index';
import Carriers from 'src/app/Carrier/Carriers';
import WareHouses from 'src/app/Carrier/WareHouses';
import ClaimsAndDisputes from 'src/app/Claims&Disputes';
import ClaimsDetail from 'src/app/Claims&Disputes/Claims/ClaimDetails';
import DisputeDetails from 'src/app/Claims&Disputes/Disputes/DisputeDetails';
import CreditDashboard from 'src/app/CreditDashboard';
import CreditDashboardDetail from 'src/app/CreditDashboard/CreditCustomerOnboarding/CreditDashboardDetail';
import BusinessApplicationForm from 'src/app/CustomerOnboarding/BusinessApplicationForm';
import Submission from 'src/app/CustomerOnboarding/Submission';
import Thankyou from 'src/app/CustomerOnboarding/Thankyou';
import CustomerDetail from 'src/app/Customers/CustomerDetail';
import CustomerGroupDetail from 'src/app/Customers/CustomerGroup/CustomerGroupDetail';
import MapIt from 'src/app/Geography/MapIt';
import Integration from 'src/app/Integration';
import LoadInsurance from 'src/app/LoadInsurance';
import LoadInsuranceDetails from 'src/app/LoadInsurance/LoadInsuranceDetails';
import Orders from 'src/app/Orders';
import OrderDetails from 'src/app/Orders/OrderDetails';
import OrderView from 'src/app/Orders/OrderView';
import PredictivePricing from 'src/app/PredictivePricing';
import QuotingAnalytics from 'src/app/QuotingHub/QuotingAnalytics';
import Radar from 'src/app/Radar';
import AllMonthCommissionReport from 'src/app/SalesDashboard/CommissionReport';
import MonthlyCommissionReport from 'src/app/SalesDashboard/CommissionReport/MonthlyCommissionReport';
import SalesRepCommissionReport from 'src/app/SalesDashboard/CommissionReport/SalesRepCommissionReport';
import TargetSetEdit from 'src/app/SalesDashboard/TargetSet/TargetSetEdit';
import Settings from 'src/app/Setting';
import TeamMemberDetail from 'src/app/Teams/TeamManagement/TeamDetail';
import CallComponent from 'src/app/Twilio';
import CallLogs from 'src/app/Twilio/CallLogs/CallLogs';
import CallHistory from 'src/app/Twilio/Contacts/CallHistory';
import ContactModal from 'src/app/Twilio/Contacts/ContactModal';
import Contacts from 'src/app/Twilio/Contacts/Contacts';
import IncomingCallModal from 'src/app/Twilio/IncomingCallModal';
import OutboundModal from 'src/app/Twilio/OutboundModal';
import Threads from 'src/app/Twilio/Threads';
import { setCurrentBreakPoint, setIsMobile } from 'src/redux/MobileView.slice';
import { useRolePermission } from 'src/utils/CommonFunctions';

import AppLayout from '../app/AppLayout';
import Callback from '../app/Callback';
import Connections from '../app/Connections';
import Customers from '../app/Customers';
import PageNotFound from '../app/Errors/404';
import Feedback from '../app/Feedback';
import Login from '../app/Login';
import TrackAndTrace from '../app/Operations/TrackAndTrace';
import SmartQuotes from '../app/QuotingHub/QuotingDashboard';
import QuotingDashboard from '../app/QuotingHub/QuotingDashboard';
import AddressDetails from '../app/QuotingHub/QuotingDashboard/AddressDetails/AddressDetailsForm';
import Dimensions from '../app/QuotingHub/QuotingDashboard/Dimensions';
import QuoteDetailPage from '../app/QuotingHub/QuotingDashboard/Quote/QuoteDetailPage';
import QuotingRequest from '../app/QuotingHub/QuotingRequest';
import OverView from '../app/SalesDashboard/OverView';
import SalesHistory from '../app/SalesDashboard/SalesHistory';
import TargetSet from '../app/SalesDashboard/TargetSet';
import TeamManagement from '../app/Teams/TeamManagement';
import TeamMembers from '../app/Teams/TeamMembers';
import { PERMISSION } from '../constants/common';
import { ROUTES } from '../constants/routes';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { hasRoleV2 } = useRolePermission();
  const dispatch = useDispatch();
  const uiState = useSelector((state: any) => state.MobileView);

  const updateMobileState = () => {
    const isMobileState = window.innerWidth <= 1023;

    if (
      window.innerWidth > 700 &&
      window.innerWidth <= 1023 &&
      uiState.currentBreakpoint !== 'SM'
    ) {
      dispatch(setCurrentBreakPoint('SM'));
    }

    if (
      window.innerWidth > 1023 &&
      window.innerWidth <= 1360 &&
      uiState.currentBreakpoint !== 'LG'
    ) {
      dispatch(setCurrentBreakPoint('LG'));
    }

    if (
      window.innerWidth > 1360 &&
      window.innerWidth <= 1549 &&
      uiState.currentBreakpoint !== 'XL'
    ) {
      dispatch(setCurrentBreakPoint('XL'));
    }

    if (
      window.innerWidth > 1549 &&
      window.innerWidth <= 1760 &&
      uiState.currentBreakpoint !== 'XLM'
    ) {
      dispatch(setCurrentBreakPoint('XLM'));
    }

    if (window.innerWidth > 1760 && uiState.currentBreakpoint !== '2XL') {
      dispatch(setCurrentBreakPoint('2XL'));
    }

    if (isMobileState === uiState.isMobile) {
      return;
    }

    if (
      isMobileState === uiState.isMobile &&
      uiState.currentBreakpoint !== 'SM'
    ) {
      dispatch(setCurrentBreakPoint(''));
    }

    dispatch(setIsMobile(isMobileState));
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateMobileState);
    });

    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [uiState.currentBreakpoint]);

  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path={ROUTES.HOME} element={<AppLayout />}>
          <Route
            path={ROUTES.LOGIN}
            element={<PublicRoute component={Login} title="Login" />}
          />
          <Route
            path={ROUTES.CALLBACK}
            element={
              <PublicRoute
                isIgnoreAuthentication={true}
                component={Callback}
                title="Callback"
              />
            }
          />

          <Route
            index
            path={ROUTES.HOME}
            element={
              window.MODE === 'production' || window.MODE === 'staging' ? (
                <PrivateRoute
                  component={SmartQuotes}
                  title="Smart Quote"
                  permission={PERMISSION.VIEW_SMART_QUOTE}
                />
              ) : (
                <PrivateRoute
                  component={MapIt}
                  title="Zone Management"
                  permission={true}
                />
              )
            }
          />

          <Route
            index
            path={ROUTES.QUOTING_DASHBOARD}
            element={
              <PrivateRoute
                component={SmartQuotes}
                title="Smart Quote"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />
          <Route
            index
            path={ROUTES.MAPIT}
            element={
              <PrivateRoute
                component={MapIt}
                title="Zone Management"
                permission={true}
              />
            }
          />

          <Route
            index
            path={ROUTES.ADDRESS_DETAILS}
            element={
              <PrivateRoute
                component={AddressDetails}
                title="Address Details"
                permission={PERMISSION.CREATE_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.DIMENSIONS}
            element={
              <PrivateRoute
                component={Dimensions}
                title="Dimensions"
                permission={PERMISSION.CREATE_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.MY_QUOTE}
            element={
              <PrivateRoute
                component={QuoteDetailPage}
                title="My Quote"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />
          <Route
            path={ROUTES.FEEDBACK_LIST}
            element={
              <PrivateRoute
                component={Feedback}
                title="Feedback"
                permission={true}
              />
            }
          />
          <Route
            path={ROUTES.QUOTING_DASHBOARD}
            element={
              <PrivateRoute
                component={QuotingDashboard}
                title="Quoting Dashboard"
                permission={PERMISSION.VIEW_SMART_QUOTE}
              />
            }
          />

          <Route
            path={ROUTES.PREDICTIVE_PRICING}
            element={
              <PrivateRoute
                component={PredictivePricing}
                title="Predictive Pricing"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.QUOTING_REQUEST}
            element={
              <PrivateRoute
                component={QuotingRequest}
                title="Quoting Request"
                permission={PERMISSION.QUOTING_REQUEST}
              />
            }
          />
          <Route
            path={ROUTES.QUOTING_ANALYTICS}
            element={
              <PrivateRoute
                component={QuotingAnalytics}
                title="Quoting Request"
                permission={PERMISSION.QUOTING_REQUEST}
              />
            }
          />

          {/* Permission control v2 */}
          <Route
            path={ROUTES.TEAMS}
            element={
              <PrivateRoute
                component={TeamMembers}
                title="Teams"
                permission={PERMISSION.USER_LIST}
              />
            }
          />
          <Route
            path={ROUTES.TEAM_MANAGEMENT}
            element={
              <PrivateRoute
                component={TeamManagement}
                title="Team Management"
                permission={PERMISSION.TEAM_LIST}
              />
            }
          />
          <Route
            path={ROUTES.TEAM_DETAIL}
            element={
              <PrivateRoute
                component={TeamMemberDetail}
                title="Team Detail"
                permission={PERMISSION.TEAM_LIST}
              />
            }
          />
          {/* <Route path={ROUTES.ORGANIZATIONAL_CHART} element={<PrivateRoute component={OrganizationalChart} title="Organizational Chart" permission={PERMISSION.ORGANIZATIONAL_CHART} />} /> */}

          <Route
            path={ROUTES.CARRIERS}
            element={
              <PrivateRoute
                component={Carriers}
                title="Carriers"
                permission={PERMISSION.CARRIER_VIEW}
              />
            }
          />

          <Route
            path={ROUTES.WAREHOUSES}
            element={
              <PrivateRoute
                component={WareHouses}
                title="Warehouses"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.CONNECTIONS}
            element={
              <PrivateRoute
                component={Connections}
                title="Carriers"
                permission={
                  hasRoleV2('admin') ? PERMISSION.CARRIER_VIEW : false
                }
              />
            }
          />

          <Route
            path={ROUTES.SETTING}
            element={
              <PrivateRoute
                component={Settings}
                title="Account Settings"
                permission={true}
              />
            }
          />
          {/* Permission control v2 */}

          <Route
            path={ROUTES.SALES_OVERVIEW}
            element={
              <PrivateRoute
                component={OverView}
                title="Sales Dashboard"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.OVERVIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.ORDERS}
            element={
              <PrivateRoute
                component={Orders}
                title="Orders"
                permission={PERMISSION.ORDERS}
              />
            }
          />
          <Route
            path={ROUTES.SALES_HISTORY}
            element={
              <PrivateRoute
                component={SalesHistory}
                title="Sales Backup"
                permission={
                  hasRoleV2('admin') || hasRoleV2('manager')
                    ? PERMISSION.ADVANCE_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_TARGET}
            element={
              <PrivateRoute
                component={TargetSet}
                title="Sales - Target"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.TARGET_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_COMMISSION_REPORT}
            element={
              <PrivateRoute
                component={AllMonthCommissionReport}
                title="Sales Commission Report"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.TARGET_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_MONTHLY_COMMISSION_REPORT}
            element={
              <PrivateRoute
                component={MonthlyCommissionReport}
                title="Monthly Sales Commission Report"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.TARGET_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_REP_COMMISSION_REPORT}
            element={
              <PrivateRoute
                component={SalesRepCommissionReport}
                title="Sales Rep Commission Report"
                permission={
                  hasRoleV2('admin') ||
                  hasRoleV2('manager') ||
                  hasRoleV2('sales')
                    ? PERMISSION.TARGET_VIEW
                    : false
                }
              />
            }
          />
          <Route
            path={ROUTES.SALES_TARGET_EDIT}
            element={
              <PrivateRoute
                component={TargetSetEdit}
                title="Sales - Target-Set"
                permission={PERMISSION.TARGET_UPDATE}
              />
            }
          />

          <Route
            path={ROUTES.TRACK_AND_TRACE}
            element={
              <PrivateRoute
                component={TrackAndTrace}
                title="Operations - Track and Trace"
                permission={PERMISSION.TRACK_AND_TRACE}
              />
            }
          />

          <Route
            path={ROUTES.CUSTOMERS}
            element={
              <PrivateRoute
                component={Customers}
                title="Customers"
                permission={PERMISSION.CUSTOMER_LIST}
              />
            }
          />
          <Route
            path={ROUTES.CUSTOMER_DETAILS}
            element={
              <PrivateRoute
                component={CustomerDetail}
                title="Customers Detail"
                permission={PERMISSION.CUSTOMER_DETAIL}
              />
            }
          />
          <Route
            path={ROUTES.CUSTOMER_GROUP}
            element={
              <PrivateRoute
                component={CustomerGroupDetail}
                title="Customers Group Detail"
                permission={PERMISSION.CUSTOMER_GROUP_DETAIL}
              />
            }
          />
          <Route
            path={ROUTES.INTEGRATION}
            element={
              <PrivateRoute
                component={Integration}
                title="Integration"
                permission={hasRoleV2('admin') && window.MODE !== 'production'}
              />
            }
          />
          <Route
            path={ROUTES.CLAIMS_AND_DISPUTES_DETAILS}
            element={
              <PrivateRoute
                component={ClaimsAndDisputes}
                title="Claims & Disputes"
                permission={PERMISSION.CLAIMS_AND_DISPUTES}
                // permission={true}
              />
            }
          />
          <Route
            path={ROUTES.CLAIM_DETAILS}
            element={
              <PrivateRoute
                component={ClaimsDetail}
                title="Claim Details"
                // permission={PERMISSION.CARRIER_VIEW}
                permission={PERMISSION.CLAIMS_AND_DISPUTES}
              />
            }
          />
          <Route
            path={ROUTES.DISPUTE_DETAILS}
            element={
              <PrivateRoute
                component={DisputeDetails}
                title="Dispute Details"
                // permission={PERMISSION.CARRIER_VIEW}
                permission={PERMISSION.CLAIMS_AND_DISPUTES}
              />
            }
          />
          <Route
            path={ROUTES.LOAD_INSURANCE}
            element={
              <PrivateRoute
                component={LoadInsurance}
                title="Load Insurance"
                // permission={
                //   hasRoleV2('admin') ||
                //   hasRoleV2('manager') ||
                //   hasRoleV2('operation') ||
                //   hasRoleV2('claims') ||
                //   hasRoleV2('sales')
                // }
                permission={PERMISSION.LOAD_INSURANCE}
              />
            }
          />
          <Route
            path={ROUTES.LOAD_INSURANCE_DETAILS}
            element={
              <PrivateRoute
                component={LoadInsuranceDetails}
                title="Load Insurance Details"
                // permission={
                //   hasRoleV2('admin') ||
                //   hasRoleV2('manager') ||
                //   hasRoleV2('operation') ||
                //   hasRoleV2('claims') ||
                //   hasRoleV2('sales')
                // }
                permission={PERMISSION.LOAD_INSURANCE}
              />
            }
          />

          <Route
            path={ROUTES.MAPIT}
            element={
              <PrivateRoute
                component={MapIt}
                title="Map It"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.CUSTOMER_ONBOARDING}
            element={
              <PublicRoute
                isIgnoreAuthentication={true}
                component={BusinessApplicationForm}
                title="Customer OnBoarding"
                permission={PERMISSION.CUSTOMER_ONBOARDING}
              />
            }
          />

          <Route
            path={ROUTES.SUBMISSION}
            element={
              <PublicRoute
                isIgnoreAuthentication={true}
                component={Submission}
                title="Customer OnBoarding"
                permission={PERMISSION.CUSTOMER_ONBOARDING}
              />
            }
          />

          <Route
            path={ROUTES.CREDIT_DASHBOARD}
            element={
              <PrivateRoute
                component={CreditDashboard}
                title="Credit Dashboard"
                permission={
                  PERMISSION.CUSTOMER_ONBOARDING ||
                  PERMISSION.CREDIT_INCREASE_REQUEST
                }
              />
            }
          />

          <Route
            path={ROUTES.CREDIT_DASHBOARD_DETAIL}
            element={
              <PrivateRoute
                component={CreditDashboardDetail}
                title="Credit Dashboard Details"
                permission={PERMISSION.CUSTOMER_ONBOARDING}
              />
            }
          />

          <Route
            path={ROUTES.THANK_YOU}
            element={
              <PublicRoute
                isIgnoreAuthentication={true}
                component={Thankyou}
                title="Thank You"
                permission={PERMISSION.CUSTOMER_ONBOARDING}
              />
            }
          />

          <Route
            path={ROUTES.AI_CHATBOT}
            element={
              <PrivateRoute
                component={ChatBot}
                title="AI Chatbot"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.TWILIO_VOICE}
            element={
              <PrivateRoute
                component={CallComponent}
                title="Voice"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.TWILIO_INCOMING_CALL}
            element={
              <PrivateRoute
                component={IncomingCallModal}
                title="Incoming Call"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.CALL_CENTER_CONTACTS}
            element={
              <PrivateRoute
                component={Contacts}
                title="Contacts"
                permission={PERMISSION.CONTACT}
              />
            }
          />

          <Route
            path={ROUTES.CALL_CENTER_CONTACTS_CALL_HISTORY}
            element={
              <PrivateRoute
                component={CallHistory}
                title="Call History"
                permission={PERMISSION.CALL_LOGS}
              />
            }
          />

          <Route
            path={ROUTES.CALL_CENTER_CALL_LOGS}
            element={
              <PrivateRoute
                component={CallLogs}
                title="Call Activity"
                permission={PERMISSION.CALL_LOGS}
              />
            }
          />

          <Route
            path={ROUTES.TWILIO_OUTBOUND_MODAL}
            element={
              <PrivateRoute
                component={OutboundModal}
                title="Outgoing Call"
                permission={true}
              />
            }
          />

          <Route
            path={ROUTES.CALL_CENTER_THREADS}
            element={
              <PrivateRoute
                component={Threads}
                title="Call Center Threads"
                permission={PERMISSION.THREADS}
              />
            }
          />

          <Route
            path={ROUTES.TWILIO_CONTACT_MODAL}
            element={
              <PrivateRoute
                component={ContactModal}
                title="Contact Modal"
                permission={PERMISSION.CONTACT}
              />
            }
          />

          <Route
            path={ROUTES.ORDER_DETAILS}
            element={
              <PrivateRoute
                component={OrderDetails}
                title="Order"
                // permission={PERMISSION.CARRIER_VIEW}
                permission={PERMISSION.ORDERS}
              />
            }
          />

          <Route
            path={ROUTES.ORDER_DETAILS_VIEW}
            element={
              <PrivateRoute
                component={OrderView}
                title="Order"
                // permission={PERMISSION.CARRIER_VIEW}
                permission={PERMISSION.ORDERS}
              />
            }
          />

          <Route
            path={ROUTES.RADAR}
            element={
              <PrivateRoute
                component={Radar}
                title="Radar"
                // permission={PERMISSION.CARRIER_VIEW}
                permission={PERMISSION.RADAR}
              />
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};

export default Routes;
