import { Send01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

import defaultImage from '../../../../assets/img/default-image.jpg';

const EmailTreads = () => (
  <>
    <div className="flex flex-col gap-y-5">
      <AccordionCmp
        title={
          <div className="flex items-center gap-2">
            <h5 className="text-sm font-medium text-textSecondary">Customer</h5>
            <p className="text-xs text-gray500 font-normal border-l border-borderPrimary pl-2">
              noelbaldwin@walhq.com
            </p>
            <BadgeCmp style="modern" type="success" isHidePillDot>
              2
            </BadgeCmp>
          </div>
        }
        contentParentClass="!border-0 !p-0 !mt-0"
        accordionHeaderWrap="p-5 bg-gray25 rounded-t-xl"
        contentClassName=""
        className="!p-0 !rounded-xl overflow-hidden"
      >
        <div className="border-t border-utilityGray200 flex flex-col">
          <div className="p-4 overflow-y-auto flex-1 custom-scrollbar-v2 max-h-[420px]">
            <ul className="w-full mt-auto flex flex-col gap-y-6">
              <li className="flex gap-3 cursor-pointer">
                <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                  <p className="mb-0 text-sm">dc</p>
                </div>
                <div className="w-[42%] space-y-1 max-w-[440px] flex-1">
                  <div className="flex justify-between gap-x-2">
                    <p className="text-grayLight600 font-normal text-xs">
                      Drew Cano
                    </p>
                    <p className="text-grayLight600 font-normal text-xs">
                      #WAL-PDFA-859
                    </p>
                  </div>
                  <div className="bg-white flex flex-col text-grayLight900 text-xs font-normal p-4 rounded-xl rounded-tl-none border border-utilityGray200 shadow-xs gap-1.5 relative [&:hover>.hover-emoji]:!flex">
                    <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                      <div className="text-grayText w-8">From</div>
                      <div className="flex items-center">
                        <figure className="w-4 h-4 rounded-full border border-white flex-none">
                          <img
                            src={defaultImage}
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </figure>
                        <p className="text-grayLight900 font-medium ml-1 break-all">
                          kylaclay@walhq.com
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                      <div className="text-grayText w-8">To</div>
                      <div className="flex items-center">
                        <figure className="w-4 h-4 rounded-full border border-white flex-none">
                          <img
                            src={defaultImage}
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </figure>
                        <p className="text-grayLight900 font-medium ml-1 break-all">
                          demiwilkinson@walhq.com
                        </p>
                      </div>
                    </div>
                    <div className="flex py-1.5 gap-1.5 ">
                      <div className=" font-medium">Subject:</div>
                      <div className="flex items-center text-grayLight600">
                        Order Tracking Information for Your Shipment
                      </div>
                    </div>
                    <p className="">
                      We are excited to inform you that your order #WAL-PDFA-859
                      has been processed and is ...
                    </p>
                  </div>
                  <div className="flex justify-between items-center gap-x-2">
                    <p className="text-grayLight600 font-normal text-xs">
                      12:40am
                    </p>
                    <div className="flex gap-1 items-center">
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        👍
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        ❤️
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        👌
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        🙂
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        😅
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex gap-3 cursor-pointer ml-auto">
                <div className="w-[42%] space-y-1 max-w-[440px] flex-1">
                  <div className="flex justify-between gap-x-2">
                    <p className="text-grayLight600 font-normal text-xs">
                      Drew Cano
                    </p>
                    <p className="text-grayLight600 font-normal text-xs">
                      #WAL-PDFA-859
                    </p>
                  </div>
                  <div className="bg-white flex flex-col text-grayLight900 text-xs font-normal p-4 rounded-xl rounded-tl-none border border-utilityGray200 shadow-xs gap-1.5 relative [&:hover>.hover-emoji]:!flex">
                    <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                      <div className="text-grayText w-8">From</div>
                      <div className="flex items-center">
                        <figure className="w-4 h-4 rounded-full border border-white flex-none">
                          <img
                            src={defaultImage}
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </figure>
                        <p className="text-grayLight900 font-medium ml-1 break-all">
                          kylaclay@walhq.com
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                      <div className="text-grayText w-8">To</div>
                      <div className="flex items-center">
                        <figure className="w-4 h-4 rounded-full border border-white flex-none">
                          <img
                            src={defaultImage}
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </figure>
                        <p className="text-grayLight900 font-medium ml-1 break-all">
                          demiwilkinson@walhq.com
                        </p>
                      </div>
                    </div>
                    <div className="flex py-1.5 gap-1.5 ">
                      <div className=" font-medium">Subject:</div>
                      <div className="flex items-center text-grayLight600">
                        Order Tracking Information for Your Shipment
                      </div>
                    </div>
                    <p className="">
                      We are excited to inform you that your order #WAL-PDFA-859
                      has been processed and is ...
                    </p>
                  </div>
                  <div className="flex justify-between items-center gap-x-2">
                    <div className="flex gap-1 items-center">
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        👍
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        ❤️
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        👌
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        🙂
                      </div>
                      <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                        😅
                      </div>
                    </div>
                    <p className="text-grayLight600 font-normal text-xs">
                      12:40am
                    </p>
                  </div>
                </div>
                <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none">
                  <p className="mb-0 text-sm">dc</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
            <InputText
              inputName="email"
              placeholder="Email"
              id="chatInput"
              className=""
              value={''}
              onChangeFunc={() => {}}
              parentClassName="flex-1"
            />

            <ButtonCmp className="btn_primary lg:!px-[9px] !px-2">
              <Send01 className="w-4 h-4" />
            </ButtonCmp>
          </div>
        </div>
      </AccordionCmp>
    </div>
  </>
);
export default EmailTreads;
