import React, { useEffect, useMemo, useState } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import RatesRow from './RatesRow';

const ratesData = [
  {
    carrier: {
      code: 'CL',
      name: 'Canadian Logistic Express',
      contact: '+1-800-409-2269',
      rating: 4.5,
    },
    contactPerson: 'Liam',
    rate: '250',
    equipmentType: 'Dry Van',
    estDeliveryDate: 'Aug. 11th',
    availability: 'Aug. 12th',
  },
  {
    carrier: {
      code: 'MT',
      name: 'Mannan Transport Inc.',
      contact: '+1-800-409-2269',
      rating: 3.2,
    },
    contactPerson: 'Logan',
    rate: '300',
    equipmentType: 'Reefer',
    estDeliveryDate: 'Aug. 11th',
    availability: 'Aug. 12th',
  },
  {
    carrier: {
      code: 'CL',
      name: 'Canadian Logistic Express',
      contact: '+1-800-409-2269',
      rating: 2.5,
    },
    contactPerson: 'Oliver',
    rate: '241',
    equipmentType: 'Straight Truck',
    estDeliveryDate: 'Aug. 11th',
    availability: 'Aug. 12th',
  },
  {
    carrier: {
      code: 'MT',
      name: 'Mannan Transport Inc.',
      contact: '+1-800-409-2269',
      rating: 4.2,
    },
    contactPerson: 'Ethan',
    rate: '600',
    equipmentType: 'Flatbed',
    estDeliveryDate: 'Aug. 11th',
    availability: 'Aug. 12th',
  },
];

const RatesTab = () => {
  const [params, setParams] = useState([]);
  const [rates, setRates] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setRates(ratesData);

    setIsLoading(false);
  }, []);

  const headCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
        sortable: false,
        visible: true,
      },
      {
        id: 'contactPerson',
        name: 'Contact Person',
        sortable: false,
        visible: true,
      },
      {
        id: 'rate',
        name: 'Rate',
        sortable: false,
        visible: true,
      },
      {
        id: 'equipmentType',
        name: 'Equipment Type',
        sortable: true,
        visible: true,
      },
      {
        id: 'est.DeliveryDate',
        name: 'Est.DeliveryDate',
        sortable: false,
        visible: true,
      },
      {
        id: 'availability',
        name: 'Availability',
        sortable: false,
        visible: true,
      },
    ],
    []
  );

  return (
    <>
      <TableCmp
        headCells={headCells}
        params={params}
        setParams={setParams}
        tableDataArr={rates}
        // TableLoaderRowCmp={CustomerDetailClaimsLoadingRaw}
        TableRowCmp={RatesRow}
        tableRowCmpProps={{}}
        isTableDataLoading={isLoading}
        numberOfSkeletonRows={7}
      />
      {!isLoading && !rates?.length && (
        <NotFoundUI
          title={`No Rates Found`}
          desc={`There are currently no rates found`}
          containerClassName="min-h-[160px] !h-auto"
        />
      )}
    </>
  );
};

export default RatesTab;
