import {
  Clock,
  LinkExternal02,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TooltipCmp from 'src/components/TooltipCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import {
  getDisputeData,
  updateDisputeStatus,
} from 'src/services/DisputeService';
import {
  displayTimeDifference,
  getFormattedNumber,
  useRolePermission,
} from 'src/utils/CommonFunctions';
// import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../../assets/img/Avatar7.png';
import doubleCheck from '../../../../assets/img/double-check.svg';
import ActionItem from '../../ActionItem';
import { CLAIM_DISPUTE_STATUS, getBadgeType } from '../../ClaimsDisputes.const';
import FileUpload from '../../FileUpload';
import InternalChatAndNotes from '../../InternalChatAndNotes';
import DisputeResolve from '../DisputeResolve';
import EditDisputeExplanation from '../EditDisputeExplanation';
import ModifyDisputeAmount from '../ModifyDisputeAmount';

const DisputeDetails = () => {
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const { reloadCounts } = useContext(EntityCountsContext);
  const { id: disputeId } = useParams<{ id: any }>();

  const [isLoading, setIsLoading] = useState(false);
  const [dispute, setDispute] = useState<any>(undefined);
  const [notesCount, setNotesCount] = useState<any>(null);
  const [userOptions, setUserOptions] = useState([]);
  const [isShowEditExplanationModal, setIsShowEditExplanationModal] =
    useState(false);
  const [isShowDisputeResolveModal, setIsShowDisputeResolveModal] =
    useState(false);
  const [isModifyDisputeAmount, setIsModifyDisputeAmount] = useState(false);
  const [isShowDisputeCloseModal, setIsShowDisputeCloseModal] = useState(false);
  const [isCloseDisputeLoading, setIsCloseDisputeLoading] = useState(false);
  const [isShowChatAndNotesSideBar, setIsShowChatAndNotesSideBar] =
    useState(true);
  const [isShowClaimActionsDropDown, setIsShowClaimActionsDropDown] =
    useState(false);
  const [outstadingTime, setOutstadingTime] = useState(null);

  const { type, clockType } = getBadgeType(
    moment.utc(dispute?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
  );

  const getDisputeDetails = () => {
    setIsLoading(true);
    getDisputeData(disputeId)
      .then((response: any) => {
        if (response && response.data) {
          const responseData = response.data;
          setDispute(responseData);
          setUserOptions(responseData.members);
          setNotesCount(responseData.total_notes_count);
          setOutstadingTime(
            displayTimeDifference(
              moment
                .utc(responseData?.createdAt)
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
            )
          );

          if (response.isReloadCount) {
            reloadCounts();
          }
        } else {
          navigate(ROUTES.HOME);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDisputeDetails();

    return () => {
      if (localStorage.getItem(TABLE_IDS.CUSTOMER_DETAILS)) {
        localStorage.removeItem(TABLE_IDS.CUSTOMER_DETAILS);
      }
    };
  }, []);

  const handleCloseDisputeConfirmationAction = (status = false) => {
    if (status) {
      setIsCloseDisputeLoading(true);
      updateDisputeStatus({
        disputeID: dispute.disputeID,
        status: CLAIM_DISPUTE_STATUS.CLOSED,
      })
        .then(() => {
          // WalToast.success('Dispute closed successfully.');
          setIsShowDisputeCloseModal(false);
          getDisputeDetails();
        })
        .finally(() => {
          setIsCloseDisputeLoading(false);
        })
        .catch(console.log);
    } else {
      setIsShowDisputeCloseModal(false);
    }
  };

  useEffect(() => {
    if (dispute?.createdAt) {
      const updateTime = () => {
        setOutstadingTime(
          displayTimeDifference(
            moment.utc(dispute?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
          )
        );
      };

      const intervalId = setInterval(updateTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [dispute]);

  const memoizedTimeBadge = useMemo(() => {
    if (outstadingTime || isLoading) {
      return (
        <BadgeCmp
          style="pill"
          type={type}
          mainClassName={`rounded-md ${isLoading ? 'custom-loading' : ''}`}
        >
          <div className="flex items-center gap-1">
            <Clock className={`w-3 h-3 mt-[1px] ${clockType}`} />
            {isLoading ? '25:04:47' : outstadingTime}
          </div>
        </BadgeCmp>
      );
    } else {
      return false;
    }
  }, [outstadingTime, type, clockType, isLoading]);

  return (
    <PageSectionLayout
      header={
        <Header
          leftClassName="!w-auto"
          title={
            <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
              Dispute
              <span className={`${isLoading ? 'custom-loading ' : ''}`}>
                #{isLoading ? 'CL-AOI1-141' : dispute?.disputeID}
              </span>
              <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                {dispute?.members?.map((groupCus: any) => (
                  <TooltipCmp key={groupCus?.image} message={groupCus?.name}>
                    {groupCus.image && groupCus?.imageUrl ? (
                      <div className="rounded-full bg-white">
                        <img
                          src={`${groupCus?.imageUrl}${groupCus.image}`}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    ) : (
                      <div className="rounded-full bg-white">
                        <img
                          src={TeamIcon1}
                          className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full ${
                            isLoading ? 'image-loading custom-loading' : ''
                          }`}
                        />
                      </div>
                    )}
                  </TooltipCmp>
                ))}
              </div>
              {(dispute?.status || isLoading) && (
                <>
                  <BadgeCmp
                    style="modern"
                    mainClassName={`rounded-md ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    type={
                      dispute?.status === CLAIM_DISPUTE_STATUS.NEW
                        ? 'success'
                        : dispute?.status === CLAIM_DISPUTE_STATUS.RESOLVED
                        ? 'lightblue'
                        : dispute?.status === CLAIM_DISPUTE_STATUS.OPENED
                        ? 'primary'
                        : dispute?.status === CLAIM_DISPUTE_STATUS.CLOSED
                        ? 'error'
                        : 'success'
                    }
                  >
                    <p data-test-id="wal-QuotingDashboard-status">
                      {isLoading ? 'opened' : dispute?.status}
                    </p>
                  </BadgeCmp>
                </>
              )}
              {memoizedTimeBadge}
            </div>
          }
          desc={`Manage, segment and view your customers here.`}
          rightSideContent={
            <div className="flex flex-wrap gap-3">
              {isShowChatAndNotesSideBar ? (
                <ButtonCmp
                  className="btn_secondary_black xl:hidden"
                  onClick={() => setIsShowChatAndNotesSideBar(false)}
                >
                  <p className="w-1.5 h-1.5 bg-successSecondary rounded-full"></p>
                  Messages
                </ButtonCmp>
              ) : (
                <ButtonCmp
                  className="btn_secondary_black"
                  onClick={() => setIsShowChatAndNotesSideBar(true)}
                >
                  <p className="w-1.5 h-1.5 bg-successSecondary rounded-full"></p>
                  Messages
                </ButtonCmp>
              )}

              {dispute?.status !== CLAIM_DISPUTE_STATUS.CLOSED && (
                <OutsideClickHandler
                  onOutsideClick={() => setIsShowClaimActionsDropDown(false)}
                  containerClassName="relative"
                >
                  <ButtonCmp
                    className="lg:!py-[9px] !py-2 lg:!px-[9px] !px-2 btn-outline-primary"
                    onClick={() =>
                      setIsShowClaimActionsDropDown((prev) => !prev)
                    }
                  >
                    Dispute Actions
                  </ButtonCmp>

                  <ul
                    className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
                      isShowClaimActionsDropDown ? '' : 'hidden'
                    }`}
                  >
                    {dispute?.status !== CLAIM_DISPUTE_STATUS.RESOLVED && (
                      <li
                        className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                          isCloseDisputeLoading || isLoading
                            ? 'custom-loading'
                            : ''
                        }`}
                        onClick={() => {
                          setIsShowClaimActionsDropDown(false);
                          setIsShowEditExplanationModal(true);
                        }}
                      >
                        Edit Dispute Description
                      </li>
                    )}
                    {(hasRoleV2('admin') || hasRoleV2('finance')) &&
                      dispute?.status === CLAIM_DISPUTE_STATUS.RESOLVED && (
                        <li
                          className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                            isCloseDisputeLoading || isLoading
                              ? 'custom-loading'
                              : ''
                          }`}
                          onClick={() => {
                            setIsShowClaimActionsDropDown(false);
                            setIsShowDisputeCloseModal(true);
                          }}
                        >
                          Close Dispute
                        </li>
                      )}
                    {(hasRoleV2('admin') || hasRoleV2('finance')) &&
                      dispute?.status === CLAIM_DISPUTE_STATUS.OPENED && (
                        <li
                          className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                            isLoading ? 'custom-loading' : ''
                          }`}
                          onClick={() => {
                            setIsShowClaimActionsDropDown(false);
                            setIsShowDisputeResolveModal(true);
                          }}
                        >
                          Resolve Dispute
                        </li>
                      )}

                    {dispute?.status === CLAIM_DISPUTE_STATUS.OPENED && (
                      <li
                        className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                        onClick={() => {
                          setIsShowClaimActionsDropDown(false);
                          setIsModifyDisputeAmount(true);
                        }}
                      >
                        Modify Dispute Amount
                      </li>
                    )}
                  </ul>
                </OutsideClickHandler>
              )}

              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                onClick={() => {
                  if (history.length > 3) {
                    navigate(-1);
                  } else {
                    navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS);
                  }
                }}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            </div>
          }
        />
      }
      contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
    >
      <div className="flex h-full">
        <div className="flex-1 xl:pr-8 overflow-auto custom-scrollbar-v2 scrollbar-hide xxl:gap-4 gap-3 pt-5 flex flex-col">
          {dispute?.status === CLAIM_DISPUTE_STATUS.RESOLVED && (
            <div className="rounded-md border border-utilitysuccess300 bg-green25 px-3 py-2 flex mb-3">
              <div className="flex-none mr-1">
                <img src={doubleCheck} />
              </div>
              <div className="text-xs">
                <span className="text-grayLight900 text-xs font-medium">
                  Dispute resolved on
                </span>
                <span className="text-xs font-semibold text-primary">
                  &nbsp;{moment(dispute?.resolvedAt).format('Do MMMM, YYYY')}
                  &nbsp;
                </span>
                <span className="text-xs font-medium text-grayLight900">
                  by {dispute?.resolverUserName}. Resolution Amount: $
                  {dispute?.finalDisputeAmount
                    ? dispute?.finalDisputeAmount
                    : dispute?.disputeAmount}
                </span>
                {dispute?.resolveDescription ? (
                  <p className="text-xs text-grayLight900 font-medium mt-1">
                    Resolution Note: {dispute?.resolveDescription}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}

          <div className="flex flex-wrap justify-between items-start border-b border-utilityGray200 pb-4 ml-1">
            <div className=" flex-1">
              <h6 className="inline-block text-grayLight900 text-sm font-semibold">
                Overview:&nbsp;
              </h6>
              <span
                className={`text-grayLight600 text-sm font-normal   ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {dispute && dispute?.disputeExplanation}
              </span>
            </div>

            {/* {dispute?.status !== CLAIM_DISPUTE_STATUS.RESOLVED && (
              <ButtonCmp
                className={`btn-outline-primary ${
                  isLoading ? 'custom-loading' : ''
                }`}
                onClick={() => setIsShowEditExplanationModal(true)}
              >
                Edit
              </ButtonCmp>
            )} */}
          </div>

          <ul className="flex xls:justify-between justify-start mb-2 flex-wrap transition-all duration-[0.5s] -mx-[18px]">
            <li className="w-1/5 px-[18px]">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Customer
              </h6>
              <Link
                to={`${PATH.CUSTOMER_DETAILS}/${dispute?.customerPageId}`}
                className="text-primary700 text-sm font-medium"
              >
                <TooltipCmp
                  message={
                    dispute?.customer && dispute?.customer.length > 24
                      ? dispute?.customer
                      : null
                  }
                  parentClassName="md:!p-3 !p-1.5 max-w-90"
                >
                  <p
                    className={`xlm:max-w-56 max-w-40 truncate ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {isLoading ? 'Airtek Ontario Inc' : dispute?.customer}
                  </p>
                </TooltipCmp>
              </Link>
            </li>
            <li className="w-1/5 px-[18px]">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Order ID
              </h6>
              <a
                href={`https://wal.roserocket.com/#/ops/orders/${dispute?.roserocketOrderId}`}
                className={`text-primary700 text-sm font-medium max-w-30 truncate block ${
                  isLoading ? 'custom-loading' : ''
                }`}
                target="_blank"
              >
                #{isLoading ? 'WAL-AOI1-21' : dispute?.orderID}
              </a>
            </li>
            <li className="w-1/5 px-[18px]">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Claim ID
              </h6>
              <p
                className={`text-primary700 text-sm font-medium flex items-center ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {dispute?.claimId ? (
                  <a
                    href={`/claims-and-disputes/claim-details/${dispute.claimIdKey}`}
                    className={`flex items-center text-primary700 cursor-pointer text-sm font-medium max-w-30 truncate ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    target="_blank"
                  >
                    Link
                    <LinkExternal02 className="w-3.5 h-3.5 ml-0.5" />
                  </a>
                ) : (
                  '-'
                )}
              </p>
            </li>
            <li className="w-1/5 px-[18px]">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Load Insurance
              </h6>

              <p
                className={`text-primary700 text-sm font-medium flex items-center ${
                  isLoading ? 'custom-loading' : ''
                }`}
              >
                {dispute?.load_insurance_id ? (
                  <a
                    href={`/load-insurance/${dispute?.loadInsuranceId}`}
                    className="text-primary700 text-sm font-medium max-w-30 truncate"
                    target="_blank"
                  >
                    #{dispute?.load_insurance_id}
                  </a>
                ) : (
                  '-'
                )}
              </p>
            </li>
            <li className="w-1/5 px-[18px]">
              <h6 className="text-xs font-normal text-grayLight600 mb-1">
                Dispute Amount
              </h6>
              <div className="flex gap-1">
                <p
                  className={`text-grayLight900 text-sm ${
                    dispute?.finalDisputeAmount
                      ? 'line-through font-normal'
                      : 'font-semibold'
                  } ${isLoading ? 'custom-loading' : ''}`}
                >
                  $
                  {isLoading ? 255 : getFormattedNumber(dispute?.disputeAmount)}
                </p>
                {dispute?.finalDisputeAmount && (
                  <p
                    className={`text-fgSuccessPrimary text-sm font-semibold ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    ${getFormattedNumber(dispute?.finalDisputeAmount)}
                  </p>
                )}
              </div>
            </li>
          </ul>

          <ActionItem
            disputeId={disputeId}
            dispute={dispute}
            userOptions={userOptions}
          />

          <FileUpload disputeId={disputeId} />
        </div>

        <InternalChatAndNotes
          dispute={dispute}
          isClaim={false}
          notesCount={notesCount}
          setNotesCount={setNotesCount}
          isShowChatAndNotesSideBar={isShowChatAndNotesSideBar}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
        />
      </div>

      {isShowEditExplanationModal && (
        <EditDisputeExplanation
          handleClose={() => setIsShowEditExplanationModal(false)}
          dispute={dispute}
          title={'Edit Dispute Description'}
          isEdit={true}
          getDispute={getDisputeDetails}
        />
      )}

      {isShowDisputeResolveModal && (
        <DisputeResolve
          handleClose={() => {
            setIsShowDisputeResolveModal(false);
            setIsModifyDisputeAmount(false);
          }}
          dispute={dispute}
          isDisputeListPage={false}
          reloadDisputes={getDisputeDetails}
          handleModifyDisputeAmount={() => setIsModifyDisputeAmount(true)}
        />
      )}

      {isModifyDisputeAmount && (
        <ModifyDisputeAmount
          dispute={dispute}
          handleClose={() => setIsModifyDisputeAmount(false)}
          getDisputeDetails={getDisputeDetails}
          setDispute={setDispute}
        />
      )}

      {isShowDisputeCloseModal && (
        <CommonModal
          title={`Close Dispute : #${dispute?.disputeID}`}
          titleDesc={`This action will close ${dispute?.disputeID} permanently.`}
          handleClose={() =>
            isCloseDisputeLoading
              ? false
              : handleCloseDisputeConfirmationAction(false)
          }
          secondaryBtnOnClick={() =>
            handleCloseDisputeConfirmationAction(false)
          }
          dangerBtnText="Close Dispute"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleCloseDisputeConfirmationAction(true)}
          primaryBtnLoading={isCloseDisputeLoading}
          primaryBtnDisabled={isCloseDisputeLoading}
          secondaryBtnDisabled={isCloseDisputeLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}
    </PageSectionLayout>
  );
};

export default DisputeDetails;
