import { Check, Mail02 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useState } from 'react';
import EmailReplyCmp from 'src/components/EmailReplyCmp';
// import { getShortName } from 'src/utils/CommonFunctions';

import CommonModal from '../../../../components/CommonModal';
// import AiInputText from 'src/components/AiInputText';

const SpotquoteConfirmation = ({
  handleClose,
  dimensions,
  // services,
  selectedCarrierRate,
  addressDetails,
  loading,
}: any) => {
  const [doNotAskChecked] = useState<boolean>(false);
  const [serviceNames] = useState<any[]>([]);

  const headerIcon = <Mail02 className="text-textSecondary w-5 h-5" />;

  const defaultMessage =
    'We are looking to get a spot quote for the following load:';

  const emailBody = (
    <div className="flex flex-col gap-4 ">
      <h5 className="text-grayLight900 text-xs font-bold pt-1">
        Shipment Details
      </h5>
      <div className="flex items-center text-xs ">
        <b className="mr-1 text-grayLight600 font-medium">Pickup Date:</b>
        <span className="font-normal text-grayLight600">
          {moment(addressDetails.pickupDate).format('dddd, MMMM Do, YYYY')}
        </span>
      </div>
      <div className="flex items-center text-xs ">
        <b className="mr-1 text-grayLight600 font-medium">Classification: </b>
        <span className="font-normal text-grayLight600">LTL</span>
      </div>
      <div className="">
        <h6 className="text-grayLight600 uppercase text-[11px] leading-4 font-medium mb-1">
          SHIPPER DETAILS
        </h6>
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide rounded-xl border border-utilityGray200 bg-white shadow-sm">
          <table className="w-full text-xs text-left  text-nowrap whitespace-nowrap">
            <thead>
              <tr className="text-grayLight600 border-b border-utilityGray200">
                <th className="px-5 py-3 font-medium">Address</th>
                <th className="px-5 py-3 font-medium">City</th>
                <th className="px-5 py-3 font-medium">Province</th>
                <th className="px-5 py-3 font-medium">PostalCode</th>
                <th className="px-5 py-3 font-medium">Country</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-grayLight600 border-b border-utilityGray200 bg-gray50">
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.shipperAddress1}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.shipperCity}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.shipperStateCode}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.shipperPostal}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.shipperCountryCode}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="">
        <h6 className="text-grayLight600 uppercase text-[11px] leading-4 font-medium mb-1">
          CONSIGNEE DETAILS
        </h6>
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide rounded-xl border border-utilityGray200 bg-white shadow-sm">
          <table className="w-full text-xs text-left  text-nowrap whitespace-nowrap">
            <thead>
              <tr className="text-grayLight600 border-b border-utilityGray200">
                <th className="px-5 py-3 font-medium">Address</th>
                <th className="px-5 py-3 font-medium">City</th>
                <th className="px-5 py-3 font-medium">Province</th>
                <th className="px-5 py-3 font-medium">PostalCode</th>
                <th className="px-5 py-3 font-medium">Country</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-grayLight600 border-b border-utilityGray200 bg-gray50">
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.consigneeAddress1}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.consigneeCity}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.consigneeStateCode}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.consigneePostal}
                </td>
                <td className="px-5 py-3 font-normal">
                  {addressDetails?.consigneeCountryCode}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="">
        <h6 className="text-grayLight600 uppercase text-[11px] leading-4 font-medium mb-1">
          Dimensions
        </h6>
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide rounded-xl border border-utilityGray200 bg-white shadow-sm">
          <table className="w-full text-xs text-left  text-nowrap whitespace-nowrap">
            <thead>
              <tr className="text-grayLight600 border-b border-utilityGray200">
                <th className="px-5 py-3 font-medium">Quantity</th>
                <th className="px-5 py-3 font-medium">Description</th>
                <th className="px-5 py-3 font-medium">
                  Dimensions (
                  <span className="font-bold">
                    {dimensions.length > 0 &&
                      dimensions[0].lengthMeasurement.toLowerCase()}
                  </span>
                  )
                </th>
                <th className="px-5 py-3 font-medium">Class</th>
                <th className="px-5 py-3 font-medium">Total weight</th>
              </tr>
            </thead>
            <tbody>
              {dimensions.map((dimension: any, index: number) => (
                <tr
                  className="text-grayLight600 border-b border-utilityGray200 bg-gray50"
                  key={`spot_dim_${index}`}
                >
                  <td className="px-5 py-3 font-semibold text-grayLight900">
                    {dimension.handlingUnitNo < 10
                      ? `0${dimension.handlingUnitNo}`
                      : dimension.handlingUnitNo}
                  </td>
                  <td className="px-5 py-3 font-normal">
                    {dimension.handlingUnitName}
                  </td>
                  <td className="px-5 py-3 font-normal">
                    Length: {dimension.itemLength} Width: {dimension.width}{' '}
                    Height: {dimension.height}
                  </td>
                  <td className="px-5 py-3 font-normal">
                    {dimension.class ?? '-'}
                  </td>
                  <td className="px-5 py-3 font-normal">
                    {' '}
                    {dimension.totalWeight} ({dimension.weightMeasurement})
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {serviceNames.length > 0 && (
        <>
          <h6 className="border-t pt-4 text-grayLight900 text-xs font-bold">
            Additional Services
          </h6>
          <div className="grid mdm:grid-cols-3 md:grid-cols-2 gap-4">
            {serviceNames.map((service: any) => (
              <div key={service} className=" items-start flex gap-1">
                <Check className="w-4 h-4 text-primary" />
                <div className="text-grayLight600 text-xs font-medium">
                  {service}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {/* <AiInputText /> */}
    </div>
  );

  return (
    <CommonModal
      handleClose={handleClose(false)}
      // titleDesc="Create new carrier for free in less than 5 minutes."
      headerIcon={headerIcon}
      title="Request a Spot Quote"
      primaryBtnText=""
      size={'max-w-3xl'}
      primaryBtnLoading={loading}
    >
      <EmailReplyCmp
        subjectLine="Request For Price"
        toEmailAddress={selectedCarrierRate?.spotQuoteEmail || ''}
        emailDetails={defaultMessage}
        emailStaticBody={emailBody}
        handleClose={handleClose(false)}
        onSubmit={(data: any) => {
          handleClose(true, 'spotQuoteConfirmation', {
            doNotAskSpotQuote: doNotAskChecked,
            sentEmailTo: data.to,
            cc: data.cc ?? [],
            emailContent: data.emailBody,
            attachments: data.attachments,
          })();
        }}
      />
    </CommonModal>
  );
};

export default memo(SpotquoteConfirmation);
