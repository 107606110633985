import { XClose } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { PATH } from 'src/constants/path';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
} from 'src/utils/CommonFunctions';

const SalesRepCommissionReportRow = ({
  data,
  setIsShowDeleteModal,
  activeTab,
}: any) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    const id = data.claimID || data.disputeID;
    const lastNumber = id?.split('-').pop();

    if (data.claimID) {
      navigate(`${PATH.CLAIM_DETAILS}/${lastNumber}`);
    } else if (data.disputeID) {
      navigate(`${PATH.DISPUTE_DETAILS}/${lastNumber}`);
    }
  };

  return (
    <>
      {/* <td className="px-5 py-4 pr-0 w-8 min-w-8 max-w-8">
      <CheckCircle className="w-4 h-4 text-fgSuccessPrimary" />
    </td> */}
      <td className="px-5 py-4 w-52 max-w-52 min-w-52">
        <div className="flex items-center">
          {data.orderCommissionees == null || data.orderCommissionees === '' ? (
            <div className="flex items-center">
              <div className="table-profile-empty-circle-xl"></div>
              <div className="pl-2 max-w-32 text-xs font-medium w-36">
                <p className="truncate">Unassigned</p>
                <p className="text-grayLight600 font-normal leading-tight truncate">
                  #{data?.fullID}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              {data.orderCommissioneeImage ? (
                <div className="table-profile-wrap notification-mark-wrap">
                  <img
                    className="table-profile-icn-circle-xl"
                    src={
                      data.orderCommissioneeImageUrl +
                      data.orderCommissioneeImage
                    }
                    alt={data.csrFullName}
                    title={data.csrFullName}
                  />
                  {data?.hasUnreadNotification && (
                    <span className="notification-dot"></span>
                  )}
                </div>
              ) : (
                <div className="table-profile-wrap notification-mark-wrap">
                  <div className="table-profile-user-circle-xl">
                    {getShortName(data.orderCommissionees)}
                  </div>
                  {data?.hasUnreadNotification && (
                    <span className="notification-dot"></span>
                  )}
                </div>
              )}

              <div className="pl-3 text-xs font-medium truncate w-36">
                <h6 className="text-grayLight900 font-medium leading-tight truncate">
                  {data.orderCommissionees}
                </h6>
                <p className="text-grayLight600 font-normal leading-tight truncate">
                  #{data?.fullID}
                </p>
              </div>
            </div>
          )}
        </div>
      </td>
      {/* <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
      <div>
        <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700">
          <a
            href={`https://wal.roserocket.com/#/ops/orders/${data?.roserocketOrderId}`}
            className="text-primary700 font-normal leading-tight truncate underline"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            #{data?.fullID}
          </a>
        </h6>
      </div>
    </td> */}
      <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
        <div className="flex gap-1 flex-wrap">
          {(data?.orderStatus === 'new' ||
            data?.orderStatus === 'saved' ||
            data?.orderStatus === 'quoted' ||
            data?.orderStatus === 'spot-qq-requested' ||
            data?.orderStatus === 'spot-quote-requested' ||
            data?.orderStatus === 'pending' ||
            data?.orderStatus === 'draft-quick-quote' ||
            data?.orderStatus === 'quick-quoted' ||
            data?.orderStatus === 'reject' ||
            data?.orderStatus === 'archived' ||
            data?.orderStatus === 'expired') && (
            <BadgeCmp style="modern" type="gray">
              {data?.orderStatus}
            </BadgeCmp>
          )}
          {(data?.orderStatus === 'no-quote' ||
            data?.orderStatus === 'no-quick-quote' ||
            data?.orderStatus === 'cancelled') && (
            <BadgeCmp style="modern" type="pink">
              {data?.orderStatus}
            </BadgeCmp>
          )}
          {(data?.orderStatus === 'tender' ||
            data?.orderStatus === 'booked' ||
            data?.orderStatus === 'invoice-paid' ||
            data?.orderStatus === 'delivered') && (
            <BadgeCmp style="modern" type="lightblue">
              {data?.orderStatus}
            </BadgeCmp>
          )}
          {(data?.orderStatus === 'invoice-created' ||
            data?.orderStatus === 'invoice-sent') && (
            <BadgeCmp style="modern" type="error">
              {data?.orderStatus}
            </BadgeCmp>
          )}
          {/* Tags */}
          {data?.orderStatus === 'dispatched' && (
            <BadgeCmp style="modern" type="pink">
              {data?.orderStatus}
            </BadgeCmp>
          )}

          {/* <BadgeCmp style="modern" type="success">
            New
          </BadgeCmp>
          <BadgeCmp style="modern" type="primary">
            Dispatched
          </BadgeCmp>
          <BadgeCmp style="modern" type="warning">
            Invoice- created
          </BadgeCmp> */}
        </div>
      </td>
      <td className="px-5 py-4 w-[17.78%] max-w-[17.78%] min-w-[17.78%]">
        <span
          dangerouslySetInnerHTML={{
            __html: data?.orderCreatedAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data?.orderCreatedAt,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </td>

      <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
        <span>${getFormattedNumber(data?.combinedCost)}</span>
      </td>
      <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
        <span>${getFormattedNumber(data?.combinedRevenue)}</span>
      </td>
      <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
        <span>${getFormattedNumber(data?.combinedMargin)}</span>
      </td>
      {activeTab === 'commissioned' && (
        <td className="px-5 py-4 w-52 max-w-52 min-w-52">
          {data?.customerName?.length > 23 ? (
            <TooltipCmp
              childrenClassName="!block truncate"
              message={data?.customerName}
            >
              {data?.customerName}
            </TooltipCmp>
          ) : (
            <p className="truncate">{data?.customerName}</p>
          )}
        </td>
      )}

      {activeTab !== 'commissioned' && (
        <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]">
          <div>
            <h6
              className="h-8 text-primary underline flex items-center font-medium hover:text-primary700 cursor-pointer"
              onClick={handleRedirect}
            >
              #{data?.claimID ? data.claimID : data.disputeID}
            </h6>
          </div>
        </td>
      )}

      {/* <td className="px-5 py-4 w-[12.82%] max-w-[12.82%] min-w-[12.82%]"></td> */}
      <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
        <div
          className="justify-end items-center gap-3 flex"
          // onClick={() => {
          //   setIsShowDeleteModal({
          //     status: true,
          //     orderId: data?.fullID,
          //     userName: data?.csrFullName,
          //   });
          // }}
        >
          <XClose
            className="w-4 h-4 text-fgErrorPrimary cursor-pointer"
            onClick={() => {
              setIsShowDeleteModal({
                status: true,
                orderId: data?.fullID,
                userName: data?.orderCommissionees,
              });
            }}
          />
        </div>
      </td>
    </>
  );
};

export default SalesRepCommissionReportRow;
