import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const ListPredictivePricing = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.PREDICTIVE_PRICING.LIST, {
    params,
    signal,
  });

  return response;
};
