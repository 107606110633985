import { Copy04, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dimensionObj } from 'src/app/Orders/order.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
// import Loader from 'src/components/Loader';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import Toggle from 'src/components/Toggle/Toggle';
import TooltipCmp from 'src/components/TooltipCmp';
import { MEASUREMENT } from 'src/constants/common';
import { getFreightClass } from 'src/services/QuoteService';

interface IProps {
  handlingUnitOptions: any;
  isHandlingUnitLoading: any;
  setWeightMeasurement: any;
  setLengthMeasurement: any;
  lengthMeasurement: any;
  weightMeasurement: any;
}

let filterArr = [
  {
    value: 'LBS',
    name: 'Imperial',
  },
  {
    value: 'KGS',
    name: ' Metric',
  },
];

const Dimensions = ({
  handlingUnitOptions,
  isHandlingUnitLoading,
  setWeightMeasurement,
  setLengthMeasurement,
  lengthMeasurement,
  weightMeasurement,
}: IProps) => {
  const { control, getValues, setValue, watch, clearErrors } = useFormContext();

  const [isFreightClassLoading, setIsFreightClassLoading] = useState([
    { isLoading: false },
  ]);
  const [activeMatrixTab, setActiveMatrixTab] = useState(weightMeasurement);

  const watchOrderDimension = watch('order_dimensions');

  useEffect(() => {
    if (weightMeasurement) {
      setActiveMatrixTab(weightMeasurement);
    }
  }, [weightMeasurement]);

  const addItems = () => {
    const dimensions = [...watchOrderDimension, dimensionObj];
    setValue('order_dimensions', dimensions);
  };

  const duplicateItems = () => {
    const formData = watchOrderDimension || [];

    if (formData.length > 0) {
      const lastItem = { ...formData[formData.length - 1] };
      delete lastItem.id;
      setValue('order_dimensions', [...formData, lastItem]);
    } else {
      setValue('order_dimensions', [dimensionObj]);
    }
  };

  const removeItems = (index: number) => () => {
    const formData = watchOrderDimension || [];

    if (formData && formData.length > 1) {
      const updatedFormData = formData.filter(
        (_: any, idx: any) => idx !== index
      );

      setValue('order_dimensions', updatedFormData);
    }
  };

  const checkFreightClass = (index: number) => async (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;
    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const rowData = getValues(`order_dimensions[${index}]`);

    let freightClass = rowData.freightClass;
    let itemClassForDayross = rowData.itemClassForDayross;
    let weight =
      +rowData.totalWeight > 0
        ? +rowData.totalWeight / +rowData.handlingUnitNo
        : 0;
    let itemLength = +rowData.length;
    let width = +rowData.width;
    let height = +rowData.height;

    if (weight && itemLength && width && height) {
      const updatedArray = [...isFreightClassLoading];
      updatedArray[index] = { isLoading: true };
      setIsFreightClassLoading(updatedArray);

      let freightData = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: MEASUREMENT.LENGTH1.value,
        weightUnit: MEASUREMENT.WEIGHT1.value,
      })
        .then((data: any) => data?.data || 0)
        .catch(() => false)
        .finally(() => {
          const updatedArr = [...isFreightClassLoading];
          updatedArr[index] = { isLoading: false };
          setIsFreightClassLoading(updatedArr);
        });

      freightClass = freightData?.freightClass;
      itemClassForDayross = freightData?.itemClassForDayross;
    } else {
      freightClass = 0;
    }

    if (value != 0) {
      setValue(`order_dimensions[${index}].${name}`, value);
    }

    if (freightClass != 0) {
      setValue(`order_dimensions[${index}].freightClass`, freightClass);
    }

    if (itemClassForDayross != 0) {
      setValue(
        `order_dimensions[${index}].itemClassForDayross`,
        itemClassForDayross
      );
    }
  };

  const handleUnitChange = async () => {
    await Promise.all(
      getValues('order_dimensions').map(async (form: any, index: number) => {
        let freightClass = form.freightClass;
        let itemClassForDayross = form.itemClassForDayross;
        let weight =
          +form.totalWeight > 0 ? +form.totalWeight / +form.handlingUnitNo : 0;
        let itemLength = form.length;
        let width = form.width;
        let height = form.height;

        if (weight && itemLength && width && height) {
          const updatedArray = [...isFreightClassLoading];
          updatedArray[index] = { isLoading: true };
          setIsFreightClassLoading(updatedArray);

          let freightData = await getFreightClass({
            itemLength: itemLength || 0,
            width: width || 0,
            height: height || 0,
            weight: weight || 0,
            lengthUnit: lengthMeasurement,
            weightUnit: weightMeasurement,
          })
            .then((data: any) => data?.data || 0)
            .catch(() => false)
            .finally(() => {
              const updatedArr = [...isFreightClassLoading];
              updatedArr[index] = { isLoading: false };
              setIsFreightClassLoading(updatedArr);
            });
          freightClass = freightData?.freightClass;
          itemClassForDayross = freightData?.itemClassForDayross;
        } else {
          freightClass = 0;
        }

        setValue(`order_dimensions[${index}].freightClass`, freightClass);
        setValue(
          `order_dimensions[${index}].itemClassForDayross`,
          itemClassForDayross
        );
      })
    );
  };

  const handleMeasurement = (lengthMeasurementValue: string, value: any) => {
    setWeightMeasurement(value);
    setLengthMeasurement(lengthMeasurementValue);
    setActiveMatrixTab(value);
    handleUnitChange();
  };

  return (
    <>
      <div className="flex items-end justify-between mb-2">
        <h6 className="text-textSecondary text-sm font-medium leading-[1.5]">
          Add Dimensions
        </h6>
        <TabButton
          className="!min-h-[30px]"
          activeClassName="!bg-utilityGray100"
          tabArray={filterArr}
          parentClassName="w-full"
          tabParentClassName=""
          isActive={activeMatrixTab}
          handleOnClick={(e: any) => {
            if (e.target.dataset.value == 'LBS') {
              handleMeasurement(
                MEASUREMENT.LENGTH1.value,
                e.target.dataset.value
              );
            } else {
              handleMeasurement(
                MEASUREMENT.LENGTH2.value,
                e.target.dataset.value
              );
            }
          }}
        />
      </div>

      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5">
        {watchOrderDimension &&
          watchOrderDimension.map((item: any, index: number) => (
            <div
              className="flex flex-wrap sm:-m-1.5 !mb-5 last:mb-0 text-nowrap"
              key={index}
            >
              <Controller
                name={`order_dimensions[${index}].handlingUnit`}
                control={control}
                defaultValue={handlingUnitOptions[0]?.value}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SelectBox
                    name="handling_unit"
                    id="handling_unit"
                    className="form_control"
                    label="Handling unit"
                    labelClassName={'form_label block mb-1.5'}
                    parentClassName="sm:p-1.5 w-[145px] min-w-min"
                    size="sm"
                    options={handlingUnitOptions}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value);

                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].handlingUnit`);
                      }
                    }}
                    isSearchable={false}
                    value={
                      value
                        ? handlingUnitOptions.find(
                            (val: any) => value === val.value
                          )
                        : handlingUnitOptions[0]
                    }
                    isLoading={isHandlingUnitLoading}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].handlingUnitNo`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="handlingUnitNo"
                    inputType="number"
                    label="№ of HU"
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    shouldFormatNumber={true}
                    onChangeFunc={(e) => {
                      const handlingUnitNo = e.target.value;
                      const parsedHandlingUnitNo =
                        handlingUnitNo === '' ? null : +handlingUnitNo;

                      if (error?.message) {
                        clearErrors(
                          `order_dimensions[${index}].handlingUnitNo`
                        );
                      }

                      onChange(parsedHandlingUnitNo);
                    }}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].length`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="length"
                    inputType="number"
                    label={`Length`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    shouldFormatNumber={true}
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].length`);
                      }

                      const newValue =
                        e.target.value === '' ? undefined : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange(undefined);
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].width`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="width"
                    inputType="number"
                    label={`Width`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    shouldFormatNumber={true}
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].width`);
                      }

                      const newValue =
                        e.target.value === '' ? undefined : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange(undefined);
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].height`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="height"
                    inputType="number"
                    label={`Height`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    shouldFormatNumber={true}
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].height`);
                      }

                      const newValue =
                        e.target.value === '' ? undefined : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange(undefined);
                      }
                    }}
                    onBlur={checkFreightClass(index)}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].totalWeight`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="totalWeight"
                    inputType="number"
                    label={`Total Wt.`}
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    shouldFormatNumber={true}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].totalWeight`);
                      }

                      const newValue =
                        e.target.value === '' ? undefined : +e.target.value;

                      onChange(newValue);
                    }}
                    onBlur={checkFreightClass(index)}
                    onFocus={() => {
                      if (value === 0) {
                        onChange(undefined);
                      }
                    }}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].freightClass`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="freightClass"
                    inputType="number"
                    label="FC"
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    shouldFormatNumber={true}
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].freightClass`);
                      }

                      const newValue =
                        e.target.value === '' ? undefined : +e.target.value;
                      onChange(newValue);
                    }}
                    onFocus={() => {
                      if (value === 0) {
                        onChange(undefined);
                      }
                    }}
                  />
                )}
              />

              <Controller
                name={`order_dimensions[${index}].refNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="refNumber"
                    label="REF No."
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[9%] min-w-min"
                    shouldFormatNumber={false}
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].refNumber`);
                      }

                      onChange(e);
                    }}
                  />
                )}
              />
              <Controller
                name={`order_dimensions[${index}].description`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="description"
                    label="Description"
                    value={value}
                    labelClassName="block mb-1.5"
                    className={`${
                      error?.message ? 'border border-red-500 border-solid' : ''
                    }`}
                    parentClassName="sm:p-1.5 w-[10%] min-w-min flex-1"
                    onChangeFunc={(e) => {
                      if (error?.message) {
                        clearErrors(`order_dimensions[${index}].description`);
                      }

                      onChange(e);
                    }}
                  />
                )}
              />
              <div className="flex w-[84px]">
                <div className="sm:p-1.5 w-[46px]">
                  <TooltipCmp message={'Do Not Stack'}>
                    <label className="form_label block mb-1.5 cursor-pointer">
                      D.N.S
                    </label>
                  </TooltipCmp>
                  <Controller
                    name={`order_dimensions[${index}].isStack`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        isChecked={value ?? true}
                        onChange={onChange}
                        labelClassName="lg:h-[36px] h-[34px] flex items-center"
                        name="isStack"
                      />
                    )}
                  />
                </div>
                <div className="m-1.5 lg:h-9 h-[34px] flex items-center mt-[26px]">
                  <div
                    className={`delete p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer ${
                      index == 0 && watchOrderDimension?.length === 1
                        ? 'pointer-events-none'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      removeItems(index)();
                    }}
                  >
                    <Trash01 className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="flex gap-x-4">
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            icon={<Copy04 className="w-4 h-4" />}
            onClick={duplicateItems}
          >
            Duplicate
          </ButtonCmp>
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => addItems()}
          >
            Add Row
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default Dimensions;
