import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef } from 'react';

import startMarkerImg from '../../../assets/img/pinWithParcel.svg';
import endMarkerImg from '../../../assets/img/pinWithParcel.svg';

interface MapboxProps {
  startPoint: { lat: number; lng: number; name: string | null };
  endPoint?: { lat: number; lng: number; name: string | null };
  midpoint?: { lat: number; lng: number; name: string };
  height?: any;
  className?: string;
  markerRef?: any;
  isShowRoute?: boolean;
  isZoomControlVisible?: boolean;
  isFullscreen?: boolean;
  isDraggable?: boolean;
  isZoomable?: boolean;
  defaultZoom?: any;
  setDistance?: any;
  setDuration?: any;
  showImageAsMarker?: boolean;
  currentLocation?: any;
}

const createSVGMarker = () => {
  const svgElement = document.createElement('div');
  svgElement.innerHTML = `
    <svg width="40" height="40" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="58.5848" cy="58.5848" r="47.1" transform="rotate(-75.3409 58.5848 58.5848)" stroke="#079455" stroke-width="1.8"/>
      <path d="M50.4894 29.0752C66.7871 24.6041 83.6236 34.1915 88.0946 50.4893C92.5657 66.7871 82.9783 83.6235 66.6805 88.0946C50.3828 92.5656 33.5463 82.9782 29.0753 66.6804C24.6042 50.3827 34.1916 33.5462 50.4894 29.0752Z" fill="#079455"/>
      <path d="M50.4894 29.0752C66.7871 24.6041 83.6236 34.1915 88.0946 50.4893C92.5657 66.7871 82.9783 83.6235 66.6805 88.0946C50.3828 92.5656 33.5463 82.9782 29.0753 66.6804C24.6042 50.3827 34.1916 33.5462 50.4894 29.0752Z" stroke="white" stroke-width="1.2"/>
      <path d="M41.2494 51.7883C40.6049 50.995 40.2827 50.5984 40.2939 50.2976C40.3037 50.0369 40.4399 49.7974 40.659 49.6557C40.9117 49.4923 41.4173 49.5664 42.4285 49.7147L71.1267 53.9224C72.0395 54.0562 72.496 54.1232 72.6892 54.3388C72.857 54.526 72.927 54.7812 72.8783 55.0279C72.8221 55.3119 72.4637 55.6024 71.7469 56.1833L49.2131 74.4457C48.4191 75.0893 48.0221 75.411 47.7214 75.3994C47.4607 75.3894 47.2213 75.2529 47.0799 75.0336C46.9168 74.7807 46.9915 74.2752 47.141 73.2641L48.8375 61.7872C48.8678 61.582 48.883 61.4793 48.8728 61.3799C48.8638 61.2917 48.8402 61.2056 48.803 61.1252C48.761 61.0344 48.6955 60.9539 48.5647 60.7929L41.2494 51.7883Z" stroke="white" stroke-width="3.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

  return svgElement;
};

// const calculateAngle = (start: any, end: any) => {
//   const dx = end[0] - start[0];
//   const dy = end[1] - start[1];

//   return Math.atan2(dy, dx) * (180 / Math.PI);
// };

const MapboxMap: React.FC<MapboxProps> = ({
  startPoint,
  endPoint,
  midpoint,
  height = 500,
  className,
  markerRef,
  isShowRoute,
  isZoomControlVisible = false,
  isFullscreen = true,
  isDraggable = false,
  isZoomable = true,
  defaultZoom = 2,
  setDistance,
  setDuration,
  showImageAsMarker = false,
  currentLocation,
}) => {
  const mapRef = useRef<any>(null);
  const mapContainerRef = useRef<any>(null);

  const addMarker = useCallback(
    (map: any, position: any, imageUrl: any, name: any, showImage: any) => {
      if (!position || !position.lng || !position.lat) return;

      let markerElement;

      if (showImage) {
        markerElement = document.createElement('div');
        markerElement.style.width = '23px';
        markerElement.style.height = '30px';
        markerElement.style.backgroundImage = `url(${imageUrl})`;
        markerElement.style.backgroundSize = 'contain';
      } else {
        markerElement = document.createElement('div');
        markerElement.style.width = '10px';
        markerElement.style.height = '10px';
        markerElement.style.backgroundColor = '#444CE7';
        markerElement.style.borderRadius = '50%';
        markerElement.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.5)';
      }

      markerElement.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.5)';

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([position.lng, position.lat])
        .addTo(map);

      if (name) {
        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setHTML(`<h3>${name}</h3>`);
        marker.setPopup(popup);
        popup.addTo(map);
      }
    },
    []
  );

  const initializeMap = useCallback(() => {
    if (!window?.MAPBOX_ACCESSTOKEN) {
      console.error('Mapbox access token is missing');

      return;
    }

    mapboxgl.accessToken = window.MAPBOX_ACCESSTOKEN;

    // if (mapContainerRef.current && !mapRef.current) {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [-95, 55],
      zoom: defaultZoom,
      pitch: 0,
      bearing: 0,
      boxZoom: isZoomable,
      antialias: true,
      dragPan: isDraggable,
      scrollZoom: isZoomable,
      doubleClickZoom: isZoomable,
      touchZoomRotate: isZoomable,
      projection: 'mercator',
      attributionControl: false,
    });

    if (isZoomControlVisible) {
      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    // Fullscreen control
    if (isFullscreen) {
      const fullscreenControl = new mapboxgl.FullscreenControl();
      mapRef.current.addControl(fullscreenControl);
    }

    if (startPoint || endPoint) {
      mapRef.current.on('load', async () => {
        if (startPoint && startPoint.lng) {
          addMarker(
            mapRef.current,
            startPoint,
            startMarkerImg,
            startPoint.name,
            showImageAsMarker
          );
        }

        if (endPoint && endPoint.lng) {
          addMarker(
            mapRef.current,
            endPoint,
            endMarkerImg,
            endPoint.name,
            showImageAsMarker
          );
        }

        if (
          isShowRoute &&
          startPoint &&
          startPoint.lng &&
          endPoint &&
          endPoint.lng
        ) {
          const query = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint.lng},${startPoint.lat};${endPoint.lng},${endPoint.lat}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
            { method: 'GET' }
          );

          const json = await query.json();

          if (json.routes && json.routes.length > 0) {
            const data = json.routes[0];
            const fetchedRoute = data.geometry.coordinates;

            let routeDistance = Math.round((data.distance / 1000) * 0.621371);
            setDistance(routeDistance);

            const durationInSeconds = json.routes[0].duration;
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);
            const formattedDuration = `${hours} hr ${minutes} min`;

            setDuration(formattedDuration);

            if (mapRef.current.getSource('fetched-route')) {
              (
                mapRef.current.getSource(
                  'fetched-route'
                ) as mapboxgl.GeoJSONSource
              ).setData({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: fetchedRoute,
                },
              });
            } else {
              mapRef.current.addSource('fetched-route', {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: fetchedRoute,
                  },
                },
              });

              mapRef.current.addLayer({
                id: 'fetched-route',
                type: 'line',
                source: 'fetched-route',
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#444CE7',
                  'line-width': 3,
                  'line-opacity': 0.75,
                },
              });

              const bounds = new mapboxgl.LngLatBounds(
                fetchedRoute[0],
                fetchedRoute[0]
              );

              if (currentLocation) {
                const svgMarker = createSVGMarker();
                new mapboxgl.Marker(svgMarker)
                  .setLngLat([currentLocation.lng, currentLocation.lat])
                  .addTo(mapRef.current!);
              }

              fetchedRoute.forEach((coord: any) => bounds.extend(coord));

              mapRef.current.fitBounds(bounds, {
                padding: { top: 50, bottom: 50, left: 50, right: 50 },
              });
            }
          }
        }

        const bounds = new mapboxgl.LngLatBounds();

        if (startPoint && startPoint.lng) {
          bounds.extend([startPoint.lng, startPoint.lat]);
        }

        if (endPoint && endPoint.lng) {
          bounds.extend([endPoint.lng, endPoint.lat]);
        }

        if (startPoint && endPoint) {
          mapRef?.current?.fitBounds(bounds, {
            padding: { top: 50, bottom: 50, left: 50, right: 50 },
            maxZoom: 10,
          });
        } else if (startPoint) {
          mapRef?.current?.setCenter([startPoint.lng, startPoint.lat]);
          mapRef?.current?.setZoom(defaultZoom > 16 ? defaultZoom : 16);
        } else if (endPoint) {
          mapRef?.current?.setCenter([endPoint.lng, endPoint.lat]);
          mapRef?.current?.setZoom(defaultZoom > 16 ? defaultZoom : 16);
        }
      });
    }
    // }
  }, [startPoint, endPoint, currentLocation]);

  useEffect(() => {
    initializeMap();

    // return () => {
    //   if (mapRef.current) {
    //     mapRef.current.remove();
    //   }
    // };
  }, [initializeMap]);

  useEffect(() => {
    if (midpoint?.lat && midpoint?.lng) {
      const midpointMarker = document.createElement('div');
      midpointMarker.style.width = '23px';
      midpointMarker.style.height = '30px';
      midpointMarker.style.backgroundImage = `url(${endMarkerImg})`;
      midpointMarker.style.backgroundSize = 'cover';

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker(midpointMarker)
        .setLngLat([midpoint.lng, midpoint.lat])
        .addTo(mapRef.current);
    } else {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    }

    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    };
  }, [midpoint]);

  return (
    <div
      id="map-container"
      ref={mapContainerRef}
      style={{ width: '100%', height: `${height}` }}
      className={className}
    />
  );
};

export default MapboxMap;
