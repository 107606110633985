import {
  DotsVertical,
  Mail02,
  PhoneCall01,
  Edit01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, usePhone } from 'src/utils/CommonFunctions';

const ContactRow = ({
  data,
  handleCall,
  handleEditContact,
  handleDeleteContact,
  handleRowClick,
}: any) => {
  const tooltipItems = (
    <div className="menu bg-base-100 rounded-box w-36">
      <div onClick={() => handleEditContact(data)} className="py-2">
        <div className="flex items-center">
          <Edit01 className="w-4 h-4 relative mr-3" /> Edit
        </div>
      </div>
      <div onClick={() => handleDeleteContact(data)} className="py-2">
        <div className="flex items-center">
          <Trash01 className="w-4 h-4 relative mr-3" /> Delete
        </div>
      </div>
    </div>
  );

  const { getFormatPhoneWithPlus } = usePhone();

  return (
    <>
      <td
        className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]"
        onClick={() => handleRowClick(data)}
      >
        <div className="flex items-center gap-3">
          <div className="flex-none">
            {data?.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={data?.imageUrl + data?.image}
                alt=""
                title=""
              />
            ) : (
              <div className="table-profile-customer-circle-xl">
                {getShortName(`${data?.name}`)}
              </div>
            )}
          </div>
          <div className="truncate">
            <TooltipCmp message={data?.name}>
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {data?.name}
              </h6>
            </TooltipCmp>
            <p className="truncate mt-[1px] text-gray500 font-normal">
              {/* {data?.number
                ? parsePhoneNumberFromString(
                    String(data?.number)
                  )?.formatInternational()
                : 'N/A'} */}
              {getFormatPhoneWithPlus(data?.number)}
            </p>
          </div>
        </div>
      </td>

      <td
        className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]"
        onClick={() => handleRowClick(data)}
      >
        <TooltipCmp>
          <div className="truncate">
            {data?.customerName || data?.carrierName || '-'}
          </div>
        </TooltipCmp>
      </td>

      <td
        className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]"
        onClick={() => handleRowClick(data)}
      >
        <TooltipCmp>
          <div className="truncate">{data?.role}</div>
        </TooltipCmp>
      </td>

      <td
        className="px-5 py-4 w-[23.04%] min-w-[23.04%] max-w-[1px]"
        onClick={() => handleRowClick(data)}
      >
        <TooltipCmp>
          <div className="truncate">
            {data?.customerEmail || data?.carrierEmail}
          </div>
        </TooltipCmp>
      </td>

      <td className="px-5 py-4 w-[125px] min-w-[125px] max-w-[125px]">
        <div className="items-center gap-4 flex ">
          {data?.customerEmail || data?.carrierEmail ? (
            <Mail02 className="cursor-pointer w-[18px] h-[18px] text-gray400" />
          ) : (
            <div className="cursor-pointer w-[18px] h-[18px] text-gray400" />
          )}
          <PhoneCall01
            onClick={() => handleCall(data)}
            className="cursor-pointer w-4 h-4 text-gray400"
          />
          <ActionTooltip
            message={tooltipItems}
            isOpenChildTopPosOnTop={true}
            type="light"
          >
            <DotsVertical className="cursor-pointer w-4 h-4 text-gray400" />
          </ActionTooltip>
        </div>
      </td>
    </>
  );
};

export default ContactRow;
