import React from 'react';

import './Toggle.css';
import { IToggle } from './Toggle.interface';

const Toggle: React.FC<IToggle> = ({
  error,
  onChange,
  onClick,
  id,
  className,
  labelClassName,
  name,
  value,
  label,
  labelPosition,
  labelTextClassName,
  switchClassName,
  switchRoundClassName,
  image = '',
  isDisabled = false,
  isChecked,
  size = 'sm',
}): JSX.Element => (
  <div className={`${labelClassName} toggle-switch`}>
    {labelPosition === 'left' ? (
      <div className="flex items-center">
        {image}
        <label
          htmlFor={id}
          className={`text-textSecondary text-sm mr-2 mb-0 cursor-pointer ${labelTextClassName}`}
        >
          {label}
        </label>
      </div>
    ) : (
      ''
    )}

    <div className="switch-input-block">
      <input
        className={`${className} ${error ? 'is-invalid' : ''} toggle-checkbox`}
        type="checkbox"
        id={id}
        checked={isChecked}
        disabled={isDisabled}
        value={value}
        name={name}
        onChange={(e) => (onChange ? onChange(e) : false)}
        onClick={(e) => (onClick ? onClick(e) : false)}
      />
      <div
        className={`${switchClassName} toggle-switch-view 
                ${size === 'sm' ? 'w-[36px] h-[20px]' : ''} 
                ${size === 'md' ? 'w-[44px] h-[24px]' : ''}`}
      >
        <span
          className={`toggle-swithc-glob 
                    ${size === 'sm' ? 'w-[16px] h-[16px]' : ''} 
                    ${size === 'md' ? 'w-[20px] h-[20px]' : ''} 
                    ${switchRoundClassName}`}
        ></span>
      </div>
    </div>
    {labelPosition === 'right' ? (
      <label
        htmlFor={id}
        className={`text-textSecondary text-sm ml-2 mb-0 cursor-pointer ${labelTextClassName}`}
      >
        {label}
      </label>
    ) : (
      ''
    )}
  </div>
);

export default Toggle;
