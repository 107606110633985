import React, { useContext, useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '../..';
import OrderDetailsRow from '../../OrderDetails/OrderDetailsRow';

interface IProps {
  type: string;
}

const PickupAndDeliveryTab = ({ type }: IProps) => {
  const [dimensions, setDimensions] = useState([]);

  const { order, handlingUnitOptions } = useContext<any>(OrderContext);

  useEffect(() => {
    if (order?.order_dimensions?.length > 0) {
      setDimensions(order?.order_dimensions);
    }
  }, [order, type]);

  const headCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
      },
      {
        id: 'legId',
        name: 'Leg ID',
      },
      {
        id: 'pickup',
        name: 'Pickup',
      },
      {
        id: 'dropoff',
        name: 'Dropoff',
      },
      {
        id: 'totalWeight',
        name: 'Total weight (lbs)',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
      },
    ],
    []
  );

  return (
    <div>
      <div className="flex-1 p-4">
        <TableCmp
          headCells={headCells}
          tableDataArr={dimensions}
          TableRowCmp={OrderDetailsRow}
          tableRowCmpProps={{ handlingUnitOptions }}
          numberOfSkeletonRows={2}
        />
      </div>
    </div>
  );
};

export default PickupAndDeliveryTab;
