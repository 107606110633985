import { Check } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

interface IProps {
  additionalService: any;
  type: any;
}

const AdditionServiceSection = ({ additionalService, type }: IProps) => (
  <div className="rounded-lg border border-utilityGray200 bg-white shadow-xxs p-3">
    <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase mb-px">
      {type}
    </h6>

    <div className="flex flex-wrap -mx-[5px] -mb-[5px]">
      {additionalService?.map((service: any, index: number) => (
        <div
          className="flex gap-2 text-grayLight900 truncate px-[5px] py-[5px]"
          key={index}
        >
          <Check className="w-4 h-4 text-primary flex-none" />
          <h6 className="truncate text-xs text-textSecondary">
            {service?.name}
          </h6>
        </div>
      ))}
    </div>
  </div>
);

export default AdditionServiceSection;
