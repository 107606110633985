import {
  SearchLg,
  Plus,
  FilterLines,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import _, { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { ONBOARDING_CUSTOMER_STATUS, TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import {
  listCustomerOnboarding,
  listCustomerOnboardingCount,
} from 'src/services/CreditDashboard';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  useRolePermission,
  isValidJSON,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../assets/img/Avatar7.png';
import AddOnBoardCustomer from '../CustomerOnboarding/AddOnBoardCustomer';
import MailConfirmation from '../CustomerOnboarding/MailConfirmation';
import ReviewOnboarding from '../CustomerOnboarding/ReviewOnboarding';

import CreditCustomerLoaderRow from './CreditCustomerOnboarding/CreditCustomerLoaderRow';
import CreditCustomerRow from './CreditCustomerOnboarding/CreditCustomerRow';
import CustomerFilterModal from './CreditCustomerOnboarding/CustomerFilterModal';
import CreditIncreaseRequest from './CreditIncreaseRequest';
import NextTasks from './NextTasks';

const initTabTypeArray: any = [
  {
    value: 'customer_onboarding',
    name: 'Customer Onboarding',
    count: 0,
  },
  {
    value: 'credit_increase_requests',
    name: 'Credit Increase Requests',
    count: 0,
  },
];

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const initAction = {
  mode: '',
  id: 0,
  data: {},
};

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  status: 'all',
  team: 'all',
  member: 'all',
};

const CreditDashboard = () => {
  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);
  const { entityType, setEntityType } = useContext(NotificationsContext);
  const uiState = useSelector((state: any) => state.MobileView);
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const [activeTab, setActiveTab] = useState('customer_onboarding');
  const [customerOnboardData, setCustomerOnboardData] = useState<any>(null);
  const [tabArray, setTabArray] = useState(initTabTypeArray);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.CREDIT_DASHBOARD);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  const [action, setAction] = useState<any>(initAction);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [customerList, setCustomerList] = useState<any>([]);
  const [totals, setTotals] = useState(0);
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);
  const headCells = [
    {
      id: 'firstName',
      name: 'User',
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'name',
      name: 'Customers',
    },
    {
      id: 'contactName',
      name: 'Contact Name',
    },
    {
      id: 'contactEmail',
      name: 'Contact Email',
    },

    {
      id: 'createdAt',
      name: 'Created Date',
    },
    {
      id: 'form_view_date',
      name: 'Viewed Date',
    },
    {
      id: 'email',
      name: 'Email',
    },

    {
      id: '#',
    },
  ];

  const filterArr: any = [
    {
      value: 'all',
      name: 'View All',
    },
    {
      value: ONBOARDING_CUSTOMER_STATUS?.NEW,
      name: 'New',
    },
    {
      value: ONBOARDING_CUSTOMER_STATUS?.IN_REVIEW,
      name: 'In Review',
    },
    {
      value: ONBOARDING_CUSTOMER_STATUS?.APPROVED,
      name: 'Approved',
    },
  ];

  const fetchCustomerOnboardingData = async (listApiSignal?: any) => {
    setIsLoading(true);
    setCustomerList([]);

    try {
      const [customerOnboardingResponse, customerOnboardingCountResponse]: [
        any,
        any
      ] = await Promise.all([
        listCustomerOnboarding(params, listApiSignal),
        listCustomerOnboardingCount(params, listApiSignal),
      ]);

      if (customerOnboardingResponse?.data) {
        setCustomerList(customerOnboardingResponse.data);
      }

      setTotals(customerOnboardingCountResponse.total);

      setIsLoading(false);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let fCount = 0;

    if (uiState.isMobile || uiState.currentBreakpoint === 'LG') {
      if (params?.team != 'all') {
        fCount += 1;
      }

      if (params?.member != 'all') {
        fCount += 1;
      }
    }

    if (uiState.isMobile && params?.segment != '') {
      fCount += 1;
    }

    setFilterCount(fCount);
  }, [params]);

  useEffect(() => {
    const listApiController = new AbortController();
    const listApiSignal = listApiController.signal;
    fetchCustomerOnboardingData(listApiSignal);

    return () => {
      listApiController.abort();
    };
  }, [params]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (customerList.length > 0 && !isNotificationLoading) {
      setTimeout(() => {
        if (
          entityCounts?.custOnBoardCount &&
          entityCounts?.custOnBoardCount > 0
        ) {
          const param = { type: 'customer_onboarding' };
          handleNotificationRead(param);

          const newCustomerList = customerList.map((data: any) => ({
            ...data,
            hasUnreadNotification: false,
          }));

          setCustomerList(newCustomerList);
        }
      }, 5000);
    }
  }, [customerList, entityCounts, isNotificationLoading]);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      // const unassignedSelectBoxOption = {
      //   value: 'unassigned',
      //   label: 'Unassigned',
      //   image: null,
      //   isImage: false,
      // };

      if (hasRoleV2('admin')) {
        setUserList([...updatedListAllMembers]);
      } else {
        setUserList([...updatedListAllMembers]);
      }
    }
  }, [params.team, teamListOptions]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchCustomer') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  useEffect(() => {
    if (entityType === 'customer_onboarding_status') {
      fetchCustomerOnboardingData();
      setEntityType(null);
    }
  }, [entityType]);

  useEffect(() => {
    const updateTypeArray = tabArray.map((tab: any) => {
      let count = 0;

      if (tab.name === 'Customer Onboarding') {
        count =
          entityCounts.custOnBoardCount > 0 ? entityCounts.custOnBoardCount : 0;
      }

      if (tab.name === 'Credit Increase Requests') {
        count =
          entityCounts.creditIncreaseRequestCount > 0
            ? entityCounts.creditIncreaseRequestCount
            : 0;
      }

      return {
        ...tab,
        count,
      };
    });

    setTabArray(updateTypeArray);
  }, [entityCounts]);

  const onClearFilter = () => {
    setParams((old: any) => ({
      ...old,
      page: 1,
      team: 'all',
      member: 'all',
    }));

    setFilterCount(0);
  };

  const memoizedTabTypeArray = useMemo(
    () => (
      <TabButton
        parentClassName="!bg-gray50 !w-full "
        className="rounded-md text-gray500 !border-0 min-w-[182px] "
        tabParentClassName={`!w-full ${
          window.MODE === 'production' ? '!hidden' : ''
        }`}
        activeClassName="!bg-primary100 !text-primary700"
        tabArray={tabArray}
        isActive={activeTab}
        isTab={true}
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
      />
    ),
    [tabArray, activeTab]
  );

  return (
    <PageSectionLayout
      header={
        <Header
          title="Credit Dashboard"
          desc="Manage and view your customer credit requests."
          isShowNotificationBox
        />
      }
    >
      {memoizedTabTypeArray}
      {activeTab === 'customer_onboarding' && (
        <>
          <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
            <div className="table-top-header ">
              <div className="table-left-wrap sm:w-auto w-full">
                <div className="table-title-wrap">
                  <h5 className="table-title">All Customer Onboardings</h5>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName={isLoading ? 'custom-loading' : ''}
                  >
                    {totals} {totals > 1 ? 'Customers' : 'Customer'}
                  </BadgeCmp>
                </div>
                <p className="table-subtitle">
                  Efficiently track and manage new customers onboardings
                </p>
              </div>

              <ButtonCmp
                className={`btn-outline-primary ${
                  window.MODE === 'production' ? '!hidden' : ''
                }`}
                onClick={() =>
                  setAction((old: any) => ({
                    ...old,
                    mode: 'addOnboardCustomer',
                  }))
                }
              >
                <Plus className="w-4 h-4" />
                Onboard New Customer
              </ButtonCmp>
            </div>
            <div className="table-bottom-header">
              <div className="table-header-bottom-left">
                <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
                  <TabButton
                    tabArray={filterArr}
                    parentClassName=""
                    className="text-nowrap"
                    activeClassName="!bg-utilityGray100"
                    isActive={params.status}
                    handleOnClick={(e: any) => {
                      setParams((old: any) => ({
                        ...old,
                        ...{ status: e?.target?.dataset?.value, page: 1 },
                      }));
                    }}
                  />
                </div>
              </div>
              {!hasRoleV2('user') && (
                <>
                  <div className="table-selectbox xl:block hidden">
                    <SelectBox
                      name="teamListGroup"
                      id="teamListGroup"
                      className="form_control"
                      size="sm"
                      placeholder="All Teams"
                      noOptionMessage="No Teams Found"
                      isClearable={true}
                      isSearchable={true}
                      options={teamListOptions}
                      isDisabled={params.member === 'unassigned'}
                      onChangeFunc={(event: any) => {
                        setParams((old: any) => ({
                          ...old,
                          ...{
                            team: event?.value ?? 'all',
                            member: 'all',
                            page: 1,
                          },
                        }));
                      }}
                      value={teamListOptions.filter(
                        (val: any) => params.team === val.value
                      )}
                    />
                  </div>
                  <div className="table-selectbox xl:block hidden">
                    <SelectBox
                      name="userListGroup"
                      id="userListGroup"
                      size="sm"
                      placeholder="All Users"
                      noOptionMessage="No Users Found"
                      isSearchable={true}
                      className="form_control"
                      isClearable={true}
                      options={userList}
                      onChangeFunc={(event: any) => {
                        if (event?.value === 'unassigned') {
                          setParams((old: any) => ({
                            ...old,
                            ...{
                              team: 'all',
                              member: event?.value ?? 'all',
                              page: 1,
                            },
                          }));
                        } else {
                          setParams((old: any) => ({
                            ...old,
                            ...{ member: event?.value ?? 'all', page: 1 },
                          }));
                        }
                      }}
                      value={userList.filter(
                        (user: any) => params.member === user.value
                      )}
                    />
                  </div>
                </>
              )}
              <ButtonCmp
                type="submit"
                className={`btn_secondary_black table-filter-btn xl:hidden  ${
                  filterCount > 0
                    ? 'border-borderSecondary hover:border-borderSecondary'
                    : ''
                }`}
                onClick={(e) => {
                  if (
                    e?.target?.id !== 'closeBtn' &&
                    e?.target?.parentElement?.id !== 'closeBtn'
                  )
                    setIsDisplayFilterModal(true);
                }}
                iconSide="left"
                icon={<FilterLines className=" w-4 h-4" />}
              >
                Filters
                {!!(filterCount > 0) && (
                  <>
                    <p className="filter-btn-selected">{filterCount}</p>
                    <XClose
                      className="w-4 h-4 text-primary700 mt-[1px]"
                      id="closeBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        onClearFilter();
                      }}
                    />
                  </>
                )}
              </ButtonCmp>
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                // parentClassName="table-searchInput !xxl:w-[19rem] "
                isClearable={true}
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === params.limit
                  )}
                />
              </div>
            </div>
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
                <TableCmp
                  headCells={headCells}
                  params={params}
                  setParams={setParams}
                  tableDataArr={customerList}
                  TableLoaderRowCmp={CreditCustomerLoaderRow}
                  TableRowCmp={CreditCustomerRow}
                  numberOfSkeletonRows={15}
                  tableHeaderClass=""
                  // tableRowCmpProps={{
                  //   openCustomerPage: (rowData: any) =>
                  //     localStorage.setItem(
                  //       TABLE_IDS.TARGET_LIST_BY_CUSTOMER,  // Key for the table storage
                  //       JSON.stringify({
                  //         ...initParamsForTargetSet,
                  //         userId: data.userId,
                  //         targetMonth: params.targetMonth || initParams.targetMonth,  // Retaining the current targetMonth
                  //       })
                  //     )
                  //     hasRoleV2('admin') &&
                  //     navigate(`${PATH.CREDIT_DASHBOARD}/${rowData?.id}`),
                  //   setAction: setAction,
                  // }}
                  tableRowCmpProps={{
                    openCustomerPage: (rowData: any) => {
                      // Store the table parameters in local storage
                      localStorage.setItem(
                        TABLE_IDS.CREDIT_DASHBOARD, // Key for the table storage
                        JSON.stringify(params)
                      );

                      if (rowData?.status?.toLowerCase() === 'new') {
                        setCustomerOnboardData(rowData);
                        setAction({
                          mode: 'addOnboardCustomer',
                          data: rowData,
                        });
                      } else {
                        navigate(`${PATH.CREDIT_DASHBOARD}/${rowData?.id}`);
                      }
                    },
                    // setAction: setAction,
                  }}
                  isTableDataLoading={isLoading}
                  isTableRowClickable={true}
                />
              </div>
              {!isLoading && !customerList.length && (
                <NotFoundUI
                  title="No Customer Found"
                  desc="There are no data for customers."
                  containerClassName="min-h-[unset]"
                />
              )}
              {/*  */}
            </div>

            <div className="w-full bg-white rounded-b-xl">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={totals}
                currentPage={params.page}
                handlePagination={(page: number) => {
                  setParams((old: any) => ({ ...old, page }));
                }}
              />
            </div>
            {action.mode === 'next-task' && (
              <NextTasks handleClose={() => setAction(initAction)} />
            )}
          </div>
        </>
      )}
      {activeTab === 'credit_increase_requests' && <CreditIncreaseRequest />}
      {action.mode === 'addOnboardCustomer' && (
        <AddOnBoardCustomer
          action={action}
          setAction={setAction}
          customerData={action?.data}
          handleClose={(formData?: any) => {
            if (formData) {
              setCustomerOnboardData(formData);
              setAction({ mode: 'review-onboarding', data: formData });
              // setAction({ mode: 'send-mail', data: null });
            } else {
              setAction({ mode: null, data: null });
            }
          }}
        />
      )}

      {action.mode === 'send-mail' && (
        <MailConfirmation
          action={action}
          setAction={setAction}
          customerData={customerOnboardData}
          handleClose={() => setAction({ mode: null, data: null })}
        />
      )}
      {action.mode === 'review-onboarding' && (
        <ReviewOnboarding
          action={action}
          setAction={setAction}
          customerData={customerOnboardData}
          handleClose={() => setAction({ mode: null, data: null })}
        />
      )}
      {isDisplayFilterModal && (
        <CustomerFilterModal
          handleClose={() => setIsDisplayFilterModal(false)}
          params={params}
          teamListOptions={teamListOptions}
          setParams={setParams}
          userList={userList}
          setFilterCount={setFilterCount}
        />
      )}
    </PageSectionLayout>
  );
};
export default CreditDashboard;
