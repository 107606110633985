import React, { memo, useCallback, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import { deleteThread, updateThreadName } from 'src/services/AiChatbotService';
import WalToast from 'src/utils/WalToast';

const UpdateDeleteThread = ({
  handleClose,
  isUpdateDeleteThread,
  isThreadData,
  handleSuccess,
}: any) => {
  const [value, setValue] = useState(isThreadData?.titleOfSummary || '');

  const renameThread = (): void => {
    updateThreadName(`${isThreadData?.threadId}`, { title: value })
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        WalToast.error('Failed to renaming thread');
      })
      .finally(() => {});
  };

  const isDeleteThread = (): void => {
    deleteThread(`${isThreadData?.threadId}`)
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        WalToast.error('Failed to deleting thread');
      })
      .finally(() => {});
  };

  const handleDeleteModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        isDeleteThread();
      } else {
        handleClose();
      }
    },
    []
  );

  return (
    <>
      {isUpdateDeleteThread === 'update' ? (
        <CommonModal
          title={
            isUpdateDeleteThread === 'update'
              ? 'Rename this thread'
              : 'Delete chat?'
          }
          modalHeaderClass="!border-b-0"
          modalFooterClass="!border-t-0"
          titleDesc=""
          handleClose={handleClose}
          headerIcon={''}
          size={'max-w-[500px]'}
          primaryBtnText={
            isUpdateDeleteThread === 'update' ? 'Rename' : 'Delete'
          }
          secondaryBtnText="Cancel"
          secondaryBtnOnClick={() => {
            handleClose();
          }}
          primaryBtnOnClick={() => {
            if (isUpdateDeleteThread === 'update') {
              renameThread();
            } else {
              isDeleteThread();
            }
          }}
          primaryBtnDisabled={value ? false : true}
        >
          {isUpdateDeleteThread === 'update' ? (
            <div className="px-5 py-3.5">
              <InputText
                inputName="threadname"
                placeholder=""
                className="form_control"
                label=""
                labelClassName=""
                value={value}
                // errorText={!validForm.address1 ? 'Address 1 name is required' : null}
                onChangeFunc={(e) => {
                  const startTrimValue = e?.target?.value.trimStart();
                  setValue(startTrimValue);
                }}
              />
            </div>
          ) : (
            <div className="px-5 py-3.5">
              <p className="text-sm font-normal text-grayLight900">
                You&apos;ll no longer see this chat here. This will also delete
                related activity like prompts, responses and feedback from your
                Copilot Apps activity.
              </p>
            </div>
          )}
        </CommonModal>
      ) : (
        <DeleteModal
          moduleTitle={'Delete chat?'}
          moduleTitleDesc={` You'll no longer see this chat here. This will also delete
              related activity like prompts, responses and feedback from your
              Copilot Apps activity.`}
          handleClose={handleDeleteModalClose}
          dangerBtnText={'Delete'}
        />
      )}
    </>
  );
};
export default memo(UpdateDeleteThread);
