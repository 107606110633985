import {
  Microphone01,
  MicrophoneOff01,
  PhoneCall01,
  PhoneCall02,
  // PhoneOutgoing02,
  PhonePause,
  PhonePlus,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';

import avatar from '../../assets/img/Avatar9.png';

const incomingCallObj1 = {
  name: 'Josh Richard',
  companyName: 'Technologies Mindcore',
  number: '+1 (315) 623-3438',
};

const incomingCallObj2 = {
  name: 'John Doe',
  companyName: 'Technologies Mindcore',
  number: '+1 (315) 623-1234',
};

const incomingCallObj3 = {
  name: 'Jack Richard',
  companyName: 'Technologies Mindcore',
  number: '+1 (315) 623-9876',
};

const ActiveCallModal = () => {
  const isTrue = true;
  const [activeCall, setActiveCall] = useState<any | null>(null);
  const [incomingCalls, setIncomingCalls] = useState<any[]>([]);
  const [callsOnHold, setCallsOnHold] = useState<any[]>([]);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const acceptIncomingCall = (index: any) => {
    const acceptedCall = incomingCalls[index];

    if (activeCall) {
      setCallsOnHold((prevCalls) => [...prevCalls, activeCall]);
    }

    setActiveCall(acceptedCall);

    setIncomingCalls((prevCalls) => prevCalls.filter((_, i) => i !== index));
  };

  const rejectIncomingCall = (index: any) => {
    setIncomingCalls((prevCalls) => prevCalls.filter((_, i) => i !== index));
  };

  const rejectActiveCall = (index: any) => {
    setCallsOnHold((prevCalls) => prevCalls.filter((_, i) => i !== index));
  };

  const activeCallOnHold = () => {
    if (activeCall) {
      setCallsOnHold((prevCalls) => [...prevCalls, activeCall]);
      setActiveCall(null);
    }
  };

  const retrieveOnHoldCall = (index: any) => {
    if (callsOnHold.length > 0) {
      const callToRetrieve = callsOnHold[index];

      if (activeCall) {
        setCallsOnHold((prevCalls) => [...prevCalls, activeCall]);
      }
      setActiveCall(callToRetrieve);
      setCallsOnHold((prevCalls) => prevCalls.filter((call, i) => i !== index));
    }
  };

  const cutActiveCall = () => {
    setActiveCall(null);
  };

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  const toggleRecording = () => {
    setIsRecording((prev) => !prev);
  };

  useEffect(() => {
    setIncomingCalls([incomingCallObj1]);

    setTimeout(() => {
      setIncomingCalls((prevCalls) => [...prevCalls, incomingCallObj2]);
    }, 3000);

    setTimeout(() => {
      setIncomingCalls((prevCalls) => [...prevCalls, incomingCallObj3]);
    }, 6000);
  }, []);

  return (
    <>
      {(incomingCalls.length > 0 || activeCall || callsOnHold.length > 0) && (
        <div className="floating-modal">
          <div className="floating-modal-body overflow-y-auto custom-scrollbar-v3-dark max-h-[455px] flex flex-col gap-y-3">
            {incomingCalls.map((call, index) => (
              <div
                className="rounded-[10px] border border-grayLight600 p-3 flex items-center gap-x-2"
                key={index}
              >
                <img
                  className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  src={avatar}
                />
                {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
                {/* <div className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
            MD
           </div> */}
                <div className="flex-1 truncate mr-1">
                  <h6 className="text-sm font-medium text-white truncate">
                    {/* {'outgoingCallDetails?.name'} */}
                    {call.name}
                  </h6>
                  <p className="text-xs leading-[1.5] font-medium text-borderPrimary truncate">
                    {/* {'outgoingCallDetails?.companyName'} */}
                    {call.companyName}
                  </p>
                  <p className="text-sm font-normal text-borderPrimary truncate">
                    {/* {'outgoingCallDetails?.number'} */}
                    {call.number}
                  </p>
                </div>
                <div className="flex items-center gap-2 flex-none">
                  <ButtonCmp
                    type="button"
                    className="btn-xs btn-success"
                    onClick={() => {
                      acceptIncomingCall(index);
                    }}
                    iconSide="left"
                    icon={<PhoneCall01 className="w-4 h-4" />}
                  >
                    <></>
                  </ButtonCmp>
                  <ButtonCmp
                    type="button"
                    className="btn-xs btn-danger"
                    onClick={() => {
                      rejectIncomingCall(index);
                    }}
                    iconSide="left"
                    icon={<PhoneCall02 className="w-4 h-4" />}
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>
            ))}

            {callsOnHold.map((call, index) => (
              <div
                key={index}
                className="rounded-[10px] border border-grayLight600 flex flex-col gap-y-3 bg-utilityGray800"
              >
                <p className="p-3 text-xs leading-[1.5] font-medium text-borderPrimary border-b border-textSecondary">
                  Incoming 40:25
                </p>

                <div className="px-3 py-3 flex gap-x-2">
                  <img
                    className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    src={avatar}
                  />
                  {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
                  {/* <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
            MD
          </div> */}

                  <div className="flex-1 truncate">
                    <h6 className="text-base font-medium text-white truncate">
                      {/* {'outgoingCallDetails?.name'} */}
                      {call.name}
                    </h6>
                    <p className="text-xs leading-[1.5] font-medium text-borderPrimary truncate">
                      {/* {'outgoingCallDetails?.companyName'} */}
                      {call.companyName}
                    </p>
                    <p className="text-xs font-normal text-borderPrimary truncate">
                      {/* {'outgoingCallDetails?.number'} */}
                      {call.number}
                    </p>
                  </div>
                </div>

                <div className="px-3 pt-2 pb-3.5 flex gap-2 justify-center items-center">
                  <ButtonCmp
                    className={`btn-xs ${!isTrue ? 'btn-success' : 'btn-gray'}`}
                    icon={<PhonePlus className="w-4 h-4" />}
                    onClick={() => retrieveOnHoldCall(index)}
                  >
                    <></>
                  </ButtonCmp>

                  <ButtonCmp
                    className="btn-xs btn-danger "
                    icon={<PhoneCall02 className="w-4 h-4" />}
                    onClick={() => rejectActiveCall(index)}
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>
            ))}

            {activeCall && (
              <div className="rounded-[10px] border border-grayLight600 flex flex-col gap-y-3 bg-utilityGray800">
                <p className="p-3 text-xs leading-[1.5] font-medium text-borderPrimary border-b border-textSecondary">
                  Incoming 40:25
                </p>
                <div className="px-3 py-3 flex gap-x-2">
                  <img
                    className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    src={avatar}
                  />
                  {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
                  {/* <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
                MD
              </div> */}

                  <div className="flex-1 truncate">
                    <h6 className="text-base font-medium text-white truncate">
                      {/* {'outgoingCallDetails?.name'} */}
                      {activeCall.name}
                    </h6>
                    <p className="text-xs leading-[1.5] font-medium text-borderPrimary truncate">
                      {/* {'outgoingCallDetails?.companyName'} */}
                      {activeCall.companyName}
                    </p>
                    <p className="text-xs font-normal text-borderPrimary truncate">
                      {/* {'outgoingCallDetails?.number'} */}
                      {activeCall.number}
                    </p>
                  </div>
                </div>

                <div className="px-3 pt-2 pb-3.5 flex gap-2 justify-between items-center">
                  <ButtonCmp
                    className={`btn-xs ${
                      isMuted ? 'btn-danger' : 'btn-success'
                    }`}
                    icon={
                      isMuted ? (
                        <MicrophoneOff01 className="w-4 h-4 text-white" />
                      ) : (
                        <Microphone01 className="w-4 h-4 text-white" />
                      )
                    }
                    onClick={toggleMute}
                  >
                    <></>
                  </ButtonCmp>
                  <div className="flex items-center gap-2">
                    <ButtonCmp
                      className="!px-[6px] !py-[7px] btn-gray"
                      onClick={toggleRecording}
                    >
                      <div>
                        <p
                          className={`w-1 h-1 rounded-full ${
                            isRecording ? 'bg-fgErrorPrimary' : 'bg-white'
                          }`}
                        ></p>
                        <p
                          className={`text-[10px] leading-[1] mt-0.5 ${
                            isRecording ? 'text-fgErrorPrimary' : 'text-white'
                          }`}
                        >
                          Rec
                        </p>
                      </div>
                    </ButtonCmp>

                    <ButtonCmp
                      className={`btn-xs text-white ${
                        !isTrue ? 'btn-success' : 'btn-gray'
                      }`}
                      icon={
                        !isTrue ? (
                          <PhonePlus className="w-4 h-4 " />
                        ) : (
                          <PhonePause className="w-4 h-4 " />
                        )
                      }
                      onClick={activeCallOnHold}
                    >
                      <></>
                    </ButtonCmp>
                    {/* <ButtonCmp
                      className="btn-xs btn-gray"
                      icon={<PhoneOutgoing02 className="w-4 h-4" />}
                      onClick={handleTransferCall}
                    >
                      <></>
                    </ButtonCmp> */}
                  </div>
                  <ButtonCmp
                    className="btn-xs btn-danger"
                    icon={<PhoneCall02 className="w-4 h-4" />}
                    onClick={cutActiveCall}
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ActiveCallModal;
