import { Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import { removeSpamEmail } from 'src/services/SettingService';
import WalToast from 'src/utils/WalToast';

interface IDeleteSpamEmail {
  email: string;
  id: number;
  handleClose: (success?: boolean) => void;
}

const DeleteSpamEmailModal = ({ handleClose, email, id }: IDeleteSpamEmail) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await removeSpamEmail(id);
      WalToast.success('Email removed from list');
      handleClose(true);
    } catch (error) {
      WalToast.error('Failed to remove email');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommonModal
      title={<span className="line-clamp-2 word-break">Remove Email</span>}
      size="max-w-[400px]"
      titleDesc={`Are you sure you want to remove ${email} from the list? This action can't be undone.`}
      handleClose={() => handleClose(false)}
      modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
      modalHeaderTextClass="!flex-col"
      modalFooterClass="border-t-0 pt-0"
      headerIcon={<Trash01 />}
      secondaryBtnOnClick={() => handleClose(false)}
      dangerBtnText="Remove"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={isLoading}
      primaryBtnOnClick={handleSubmit}
    >
      &nbsp;
    </CommonModal>
  );
};

export default DeleteSpamEmailModal;
