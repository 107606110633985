import {
  Glasses01,
  Globe01,
  Microphone01,
  MicrophoneOff01,
  PhoneCall02,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  incrementCallDuration,
  setBargeWhisperCall,
  setBargeWhisperCallDetails,
  setBargeWhisperConferenceId,
  setBargeWhisperNumber,
  setCallDuration,
  setErrorMessage,
  setHoldStatus,
  setIncomingCall,
  setIsBarge,
  setIsBargeWhisper,
  setIsBargeWhisperMuted,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setIsWhisper,
  setOnHold,
  setOutgoingCall,
  setSupervisorSid,
} from 'src/redux/CallCenter.slice';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { RootState } from 'src/redux/store';
import {
  bargeInConferenceCall,
  LeaveConferenceCall,
  whisperInConferenceCall,
} from 'src/services/CallCenterService';
import { addCallDuration } from 'src/services/TwilioService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

const BargeWhisperModal = () => {
  const dispatch = useDispatch();
  const {
    bargeWhisperCallDetails,
    incomingCall,
    outgoingCall,
    isBargeWhisperMuted,
    conferenceId,
    isCallInProgress,
    callDuration,
    supervisorSid,
    isBarge,
    isWhisper,
    callLogId,
    agentCallSid,
    bargeWhisperConferenceId,
    bargeWhisperCall,
    // adminJoiningACall,
  } = useSelector((state: RootState) => state.CallCenter);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isCallInProgress) {
      timer = setInterval(() => {
        dispatch(incrementCallDuration());
      }, 1000);
    } else if (timer) {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isCallInProgress, dispatch]);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.removeAllListeners();

      // Conference Created For Incoming Call
      socketIO.on('disconnectCall', async () => {
        console.log('disconnect call');
        await addCallDuration({ callDuration, conferenceId });

        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
        dispatch(setIncomingCall(null));
        dispatch(setOutgoingCall(null));
      });
    } else {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          // Conference Created For Incoming Call
          io.socket.on('disconnectCall', () => {
            console.log('disconnect call');
            dispatch(setIsCallInProgress(false));
            dispatch(setCallDuration(0));
            dispatch(setOnHold(false));
            dispatch(setIsMuted(false));
            dispatch(setHoldStatus(false));
            dispatch(setIsRecording(true));
            dispatch(setIncomingCall(null));
            dispatch(setOutgoingCall(null));
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('disconnectCall');
          };
        })
        .catch((error: any) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, []);

  const toggleMute = () => {
    if (bargeWhisperCall) {
      const muteState = !isBargeWhisperMuted;
      bargeWhisperCall.mute(muteState);
      dispatch(setIsBargeWhisperMuted(muteState));
    }
  };

  const whisperInConference = async () => {
    try {
      const newWhisperStatus = !isWhisper;
      console.log(
        '5. ⭐️ ~ whisperInConference ~ newWhisperStatus:',
        newWhisperStatus
      );
      dispatch(setIsWhisper(newWhisperStatus));
      const callSid =
        outgoingCall?.parameters?.CallSid || incomingCall?.parameters.CallSid;

      console.log('6. ⭐️ ~ whisperInConference ~ callSid:', callSid);
      console.log(
        '⭐️ ~ whisperInConference ~ body.agentCallSid:',
        agentCallSid
      );
      const body = {
        bargeWhisperConferenceId,
        agentCallSid,
        supervisorSid: supervisorSid,
      };
      console.log('⭐️ ~ whisperInConference ~ body:', body);

      await whisperInConferenceCall(body);

      console.log(`7. Whisper successfully`);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the call transfer.'
      );
      console.error('Error transferring call:', error);
    }
  };

  const bargeInConference = async () => {
    try {
      const newBargeStatus = !isBarge;
      console.log(
        '3. ⭐️ ~ bargeInConference ~ newBargeStatus:',
        newBargeStatus
      );
      dispatch(setIsBarge(newBargeStatus));
      const bargeData = {
        bargeWhisperConferenceId,
        supervisorSid,
      };

      await bargeInConferenceCall(bargeData);

      console.log(`4. Barge successfully`);
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the Barge in conference.'
      );
      console.error('Error transferring call:', error);
    }
  };

  const LeaveFromConferenceCall = async () => {
    try {
      dispatch(setIsBargeWhisper(false));
      dispatch(setBargeWhisperCallDetails(null));
      dispatch(setIsWhisper(false));
      dispatch(setIsBarge(false));
      dispatch(setBargeWhisperConferenceId(null));
      dispatch(setBargeWhisperCall(null));
      dispatch(setIsBargeWhisperMuted(null));
      dispatch(setBargeWhisperNumber(null));

      const leaveData = {
        callLogId,
        participantCallSid: supervisorSid,
      };
      await LeaveConferenceCall(leaveData);
      dispatch(setSupervisorSid(''));
    } catch (error) {
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred during the leave conference.'
      );
      console.error('Error transferring call:', error);
    }
  };

  return (
    <>
      <div className="floating-modal">
        <div className="floating-modal-header">
          <h6 className="floating-modal-header-title">In Barge & Whisper</h6>
        </div>
        <div className="floating-modal-body">
          <ul className="flex flex-col gap-y-3">
            <li className="flex items-center gap-x-2">
              <img
                className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                src={bargeWhisperCallDetails?.agentImage}
              />
              {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
              {/* <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
              MD
            </div> */}
              <div>
                <h6 className="text-base font-medium text-white line-clamp-1">
                  {bargeWhisperCallDetails?.agentName}
                </h6>

                {/* <p className="text-gray500 text-xs leading-[1.5] font-medium">
                Technologies Mindcore
              </p>
              <p className="text-gray500 text-xs leading-[1.5] font-normal">
                +1 (315) 623-3438
              </p> */}
              </div>
            </li>
            <li className="flex items-center gap-x-2">
              <img
                className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                src={bargeWhisperCallDetails?.customerImage}
              />
              {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
              {/* <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[12px] leading-4">
              MD
            </div> */}
              <div>
                <h6 className="text-base font-medium text-white line-clamp-1">
                  {bargeWhisperCallDetails?.customerName}
                </h6>
                {/* <p className="text-gray500 text-xs leading-[1.5] font-medium">
                Technologies Mindcore
              </p>
              <p className="text-gray500 text-xs leading-[1.5] font-normal">
                +1 (315) 623-3438
              </p> */}
              </div>
            </li>
          </ul>
          <div className="pt-3 flex gap-2 justify-center items-center">
            <ButtonCmp
              className={`btn-xs  ${
                isBargeWhisperMuted ? 'btn-danger' : 'btn-success'
              }`}
              icon={
                isBargeWhisperMuted ? (
                  <MicrophoneOff01 className="w-4 h-4" />
                ) : (
                  <Microphone01 className="w-4 h-4" />
                )
              }
              onClick={toggleMute}
              // disabled={adminJoiningACall}
            >
              <></>
            </ButtonCmp>
            {/* Barge  */}
            <TooltipCmp
              message={`${isBarge ? 'Stop Barge' : 'Barge'}`}
              type="light"
            >
              <ButtonCmp
                className={`btn-xs  ${isBarge ? 'btn-success' : 'btn-gray'}`}
                icon={<Globe01 className="w-4 h-4" />}
                onClick={bargeInConference}
                // disabled={adminJoiningACall}
              >
                <></>
              </ButtonCmp>
            </TooltipCmp>
            {/* Whisper */}
            <TooltipCmp
              message={`${isWhisper ? 'Stop Whisper' : 'Whisper'}`}
              type="light"
            >
              <ButtonCmp
                className={`btn-xs ${isWhisper ? 'btn-success' : 'btn-gray'}`}
                icon={<Glasses01 className={`w-4 h-4`} />}
                onClick={whisperInConference}
                // disabled={adminJoiningACall}
              >
                <></>
              </ButtonCmp>
            </TooltipCmp>
            <ButtonCmp
              className="btn-xs btn-danger"
              icon={<PhoneCall02 className="w-4 h-4" />}
              onClick={LeaveFromConferenceCall}
              // disabled={adminJoiningACall}
            >
              <></>
            </ButtonCmp>
          </div>
        </div>
      </div>
    </>
  );
};

export default BargeWhisperModal;
