import { XClose } from '@untitled-ui/icons-react/build/cjs';
// import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
// import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
// import TooltipCmp from 'src/components/TooltipCmp';
// import TooltipCmp from 'src/components/TooltipCmp';
// import TooltipCmp from 'src/components/TooltipCmp';
// import TooltipCmp from 'src/components/TooltipCmp';
// import TooltipCmp from 'src/components/TooltipCmp';
// import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

// import profile1 from '../../../../assets/img/Avatar1.png';

import CustomerOrdersTableRow from './CustomerOrdersTableRow';
import CustomerSplitDetailRowLoader from './CustomerSplitDetailTable/CustomerSplitDetailRowLoader';

interface IProps {
  isLoading: boolean;
  handleClose: () => void;
  selectedCustomer: any;
}

// const initCounter = {
//   NEW: 0,
//   NO_QUICK_QUOTE: 0,
//   NO_QUOTE: 0,
//   QUICK_QUOTED: 0,
//   QUOTED: 0,
//   SAVED: 0,
//   SPOT_QQ_REQUESTED: 0,
//   CANCELLED: 0,
//   DRAFT_QUICK_QUOTE: 0,
//   CLAIM: 0,
//   ARCHIVED: 0,
//   INVOICE_SENT: 0,
//   INVOICE_PAID: 0,
//   DISPATCHED: 0,
//   INVOICE_CREATED: 0,
//   DELIVERED: 0,
//   IN_TRANSIT: 0,
// };

const CustomerOrdersTable = ({
  handleClose,
  isLoading,
  selectedCustomer,
}: IProps) => {
  const [sortData, setSortData] = useState({
    sortField: 'name',
    sortType: 'desc',
  });
  const [filteredOrderList, setfilteredOrderList] = useState<any[]>([
    ...selectedCustomer.orderDetails,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const [searchInput, setSearchInput] = useState('');
  // const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [pageItem, setPageItem] = useState<any[]>([
    ...selectedCustomer.orderDetails,
  ]);
  // const [counter, setCounter] = useState(initCounter);
  // const counter = initCounter;

  const headCells = useMemo(
    () => [
      {
        id: 'orderCommissionees',
        name: 'User',
        sortable: true,
      },
      {
        id: 'deliveryDateActual',
        name: 'Delivery',
        sortable: true,
      },
      {
        id: 'orderStatus',
        name: 'Status',
        sortable: true,
      },
      // {
      //   id: 'orderCreatedAt',
      //   name: 'Order Created',
      //   sortable: true,
      // },
      {
        id: 'combinedRevenue',
        name: 'Revenue',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Cost',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
      },
      {
        id: 'combinedMarginPercentage',
        name: 'Margin %',
        sortable: true,
      },
    ],
    []
  );

  const recordsPerPageArr = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedfilteredOrderList = filteredOrderList.sort((a: any, b: any) => {
      if (sortField === 'name') {
        const nameA = a.name?.toUpperCase(); // to ensure case-insensitive comparison
        const nameB = b.name?.toUpperCase(); // to ensure case-insensitive comparison

        if (sortType === 'asc') {
          return nameA?.localeCompare(nameB);
        }

        return nameB?.localeCompare(nameA);
      } else if (sortField === 'commissionee') {
        const nameA =
          a.orderCommissioneesList?.[0]?.orderCommissionees?.toUpperCase() ??
          ''; // to ensure case-insensitive comparison
        const nameB =
          b.orderCommissioneesList?.[0]?.orderCommissionees?.toUpperCase() ??
          ''; // to ensure case-insensitive comparison

        if (sortType === 'asc') {
          return nameA?.localeCompare(nameB);
        }

        return nameB?.localeCompare(nameA);
      } else {
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }
      }

      return 0;
    });
    setfilteredOrderList([...sortedfilteredOrderList]);
  };

  // useEffect(() => {
  //   const filteredList = selectedCustomer.orderDetails.filter((item: any) => {
  //     const matchesStatus = selectedStatus
  //       ? item.orderStatus === selectedStatus
  //       : true;
  //     const matchesSearch = searchInput
  //       ? Object.keys(item).some(
  //           (key) =>
  //             item[key] &&
  //             item[key]
  //               .toString()
  //               .toLowerCase()
  //               .includes(searchInput.toLowerCase())
  //         )
  //       : true;

  //     return matchesStatus && matchesSearch;
  //   });

  //   setfilteredOrderList([...filteredList]);
  // }, [searchInput, selectedStatus, selectedCustomer]);

  useEffect(() => {
    if (filteredOrderList?.length > recordsPerPage) {
      const pageItems = filteredOrderList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredOrderList]);
    }
  }, [filteredOrderList, currentPage, recordsPerPage]);

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  // const statuses = useMemo(
  //   () => [
  //     {
  //       id: 1,
  //       value: 'New',
  //       name: 'New',
  //       counter: counter.NEW,
  //     },
  //     {
  //       id: 2,
  //       value: 'No-Quick-Quote',
  //       name: 'No Quick Quote',
  //       counter: counter.NO_QUICK_QUOTE,
  //     },
  //     {
  //       id: 3,
  //       value: 'No-Quote',
  //       name: 'No Quote',
  //       counter: counter.NO_QUOTE,
  //     },
  //     {
  //       id: 4,
  //       value: 'Quick-Quoted',
  //       name: 'Quick Quoted',
  //       counter: counter.QUICK_QUOTED,
  //     },
  //     {
  //       id: 5,
  //       value: 'Quoted',
  //       name: 'Quoted',
  //       counter: counter.QUOTED,
  //     },
  //     {
  //       id: 6,
  //       value: 'Saved',
  //       name: 'Saved',
  //       counter: counter.SAVED,
  //     },
  //     {
  //       id: 7,
  //       value: 'Spot-Qq-Requested',
  //       name: 'Spot Qq Requested',
  //       counter: counter.SPOT_QQ_REQUESTED,
  //     },
  //     {
  //       id: 8,
  //       value: 'cancelled',
  //       name: 'Cancelled',
  //       counter: counter.CANCELLED,
  //     },
  //     {
  //       id: 9,
  //       value: 'draft-quick-quote',
  //       name: 'Draft quick quote',
  //       counter: counter.DRAFT_QUICK_QUOTE,
  //     },
  //     {
  //       id: 10,
  //       value: 'claim',
  //       name: 'Claim',
  //       counter: counter.CLAIM,
  //     },
  //     {
  //       id: 11,
  //       value: 'archived',
  //       name: 'Archived',
  //       counter: counter.ARCHIVED,
  //     },
  //     {
  //       id: 12,
  //       value: 'invoice-sent',
  //       name: 'Invoice sent',
  //       counter: counter.INVOICE_SENT,
  //     },
  //     {
  //       id: 13,
  //       value: 'invoice-paid',
  //       name: 'Invoice paid',
  //       counter: counter.INVOICE_PAID,
  //     },
  //     {
  //       id: 14,
  //       value: 'dispatched',
  //       name: 'Dispatched',
  //       counter: counter.DISPATCHED,
  //     },
  //     {
  //       id: 15,
  //       value: 'invoice-created',
  //       name: 'Invoice created',
  //       counter: counter.INVOICE_CREATED,
  //     },
  //     {
  //       id: 16,
  //       value: 'delivered',
  //       name: 'Delivered',
  //       counter: counter.DELIVERED,
  //     },
  //     {
  //       id: 17,
  //       value: 'in-transit',
  //       name: 'In transit',
  //       counter: counter.IN_TRANSIT,
  //     },
  //   ],
  //   [counter]
  // );
  // const statusArr = getSelectBoxOptions(statuses, 'value', 'name');

  return (
    <CommonModal
      size="max-w-6xl overflow-unset"
      handleClose={() => handleClose()}
      isOverflow={false}
    >
      <div className="p-5 max-h-[75vh] flex flex-col">
        <div className="min-h-full flex-1 w-full bg-white rounded-xl shadow border border-utilityGray200 flex flex-col">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap">
              <div className="table-title-wrap flex-wrap">
                {/* <h5 className="table-title truncate max-w-[calc(100%_-_120px)] ">
                  {selectedCustomer.name.length > 24 ? (
                    <TooltipCmp
                      message={selectedCustomer.name}
                      parentClassName="max-w-64"
                    >
                      {selectedCustomer.name}
                    </TooltipCmp>
                  ) : (
                    <span>{selectedCustomer.name}</span>
                  )}
                </h5> */}
                <h5 className="table-title max-w-[calc(100%_-_123px)] line-clamp-1">
                  <span>{selectedCustomer.name}</span>
                </h5>

                <div className="flex gap-2.5 flex-wrap">
                  {!isLoading && (
                    <BadgeCmp style="modern" type="success">
                      {filteredOrderList.length}
                      {filteredOrderList.length > 1 ? ' Orders' : ' Order'}
                    </BadgeCmp>
                  )}
                </div>
              </div>
              <p className="table-subtitle">
                View and manage orders, synced from RoseRocket.
              </p>
            </div>
            <div className="table-right-wrap max-mdm:flex-1 ">
              {/* <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchInput}
                inputType="text"
                isClearable={true}
                onChangeFunc={(e: any) => setSearchInput(e.target.value)}
                parentClassName="table-searchInput max-mdm:flex-1 max-sm:flex-[0_0_100%]"
              />
              <div className="table-statustbox max-sm:flex-1">
                <SelectBox
                  name="status"
                  placeholder="Filte By Status"
                  parentClassName=" block w-full"
                  labelClassName="form_label"
                  isClearable={true}
                  value={
                    selectedStatus
                      ? statusArr.find(
                          (status) => status.value === selectedStatus
                        )
                      : null
                  }
                  options={statusArr}
                  onChangeFunc={(event: any) => {
                    setSelectedStatus(event?.value ?? null);
                    setCurrentPage(1);
                  }}
                />
              </div> */}
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  placeholder="10"
                  options={recordsPerPageArr}
                  isSearchable={false}
                  onChangeFunc={(event: any) => {
                    setRecordsPerPage(event.value);
                    setCurrentPage(1);
                  }}
                  value={recordsPerPageArr.find(
                    (val: any) => val.value === recordsPerPage
                  )}
                />
              </div>
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                icon={<XClose className="w-4 h-4" />}
                iconSide="left"
                onClick={handleClose}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
          <div className="max-h-[calc(100vh_-_415px)] w-full flex flex-col">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                params={sortData}
                setParams={setSortData}
                headCells={headCells}
                tableDataArr={pageItem}
                TableRowCmp={CustomerOrdersTableRow}
                TableLoaderRowCmp={CustomerSplitDetailRowLoader}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={recordsPerPage}
              />
            </div>
            {!isLoading && !filteredOrderList?.length && (
              <NotFoundUI
                title="No Customer Data Found"
                desc="There are no data for selectedCustomer split related data."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>
          <div className="w-full">
            {!isLoading && filteredOrderList?.length > recordsPerPage && (
              <CustomPagination
                recordsPerPage={recordsPerPage}
                totalRecords={filteredOrderList?.length}
                currentPage={currentPage}
                handlePagination={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerOrdersTable;
