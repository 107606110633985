import React from 'react';

import AddressDetails from './AddressDetails';

interface IProps {
  action: any;
  setAction: any;
  shippers: any;
  consignees: any;
  shipperOptions: any;
  consigneeOptions: any;
  isValidAddress: any;
  setIsValidAddress: any;
  isConsigneeBtnDisable: any;
  setIsConsigneeBtnDisable: any;
  isShipperBtnDisable: any;
  setIsShipperBtnDisable: any;
  getShipper: any;
  currentLegData: any;
  setCurrentLegData: any;
  tempLegData: any;
  setTempLegData: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
}

const Step1 = ({
  action,
  setAction,
  shippers,
  consignees,
  shipperOptions,
  consigneeOptions,
  isValidAddress,
  setIsValidAddress,
  isConsigneeBtnDisable,
  setIsConsigneeBtnDisable,
  isShipperBtnDisable,
  setIsShipperBtnDisable,
  getShipper,
  currentLegData,
  setCurrentLegData,
  tempLegData,
  setTempLegData,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
}: IProps) => (
  <AddressDetails
    action={action}
    setAction={setAction}
    currentLegData={currentLegData}
    setCurrentLegData={setCurrentLegData}
    shippers={shippers}
    consignees={consignees}
    shipperOptions={shipperOptions}
    consigneeOptions={consigneeOptions}
    isValidAddress={isValidAddress}
    setIsValidAddress={setIsValidAddress}
    isConsigneeBtnDisable={isConsigneeBtnDisable}
    setIsConsigneeBtnDisable={setIsConsigneeBtnDisable}
    isShipperBtnDisable={isShipperBtnDisable}
    setIsShipperBtnDisable={setIsShipperBtnDisable}
    getShipper={getShipper}
    tempLegData={tempLegData}
    setTempLegData={setTempLegData}
    shipperAddress={shipperAddress}
    setShipperAddress={setShipperAddress}
    consigneeAddress={consigneeAddress}
    setConsigneeAddress={setConsigneeAddress}
  />
);

export default Step1;
