import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import {
  convertSecondsToTime,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const QuotingSplitsRow = ({ data, activeTableTab }: any) => (
  <>
    <td className={`px-5 py-4  min-w-96 w-96 max-w-96`}>
      <div className="flex items-center truncate">
        <div className="table-profile-wrap notification-mark-wrap">
          {data?.image ? (
            <img
              src={`${data?.imageUrl}${data?.image}`}
              className="table-profile-icn-circle-xl rounded-md"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl rounded-md">
              {activeTableTab === 'teamPerformance'
                ? getShortName(`${data.firstName} ${data.lastName}`)
                : getShortName(data.name)}
            </div>
          )}
          {data.hasUnreadNotification ? (
            <span className={`notification-dot`}></span>
          ) : (
            ''
          )}
        </div>

        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {activeTableTab === 'teamPerformance'
              ? `${data.firstName}  ${data.lastName}`
              : data.name}
          </h6>
        </div>
      </div>
    </td>

    <td className={`px-5 py-4`}>
      <span className="">{data.totalRequest}</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="">
        {data.avgTimeToClassify
          ? convertSecondsToTime(data.avgTimeToClassify)
          : '0'}
      </span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="">{data.totalQuote}</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="">
        {data.avgTimeToQuote ? convertSecondsToTime(data.avgTimeToQuote) : '0'}
      </span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="">
        {' '}
        {activeTableTab === 'teamPerformance'
          ? data.totalHits ?? 0
          : data.totalOrders ?? 0}
      </span>
    </td>

    <td className={`px-5 py-4`}>
      <span className="">{data.wonLoss}</span>
    </td>
  </>
);

export default QuotingSplitsRow;
