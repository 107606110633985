import {
  PresentationChart03,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { reassignCustomersSalesRep } from 'src/services/CustomerService';
import { listUser } from 'src/services/TeamMemberService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import CustomerReassignRow from './CustomerReassignRow';

const recordsPerPageArr = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const initParams = {
  page: 1,
  limit: 10,
  sortType: 'asc',
  sortField: 'name',
};

const CustomerSalesRepReassignModal = ({
  selectedCustomersForSalesRepReassign,
  handleClose,
}: any) => {
  const [params, setParams] = useState(initParams);
  const [salesUserList, setSalesUserList] = useState<any[]>([]);
  const [isSalesUsersListLoading, setIsSalesUsersListLoading] = useState(false);
  const [customerAssignedSalesReps, setCustomerAssignedSalesReps] = useState<
    any[]
  >([]);
  const [customers, setCustomers] = useState(
    selectedCustomersForSalesRepReassign
  );
  const [
    customersSalesRepReassignLoading,
    setCustomersSalesRepReassignLoading,
  ] = useState(false);
  const [isFilledSalesRepOnce, setIsFilledSalesRepOnce] = useState(false);

  useEffect(() => {
    if (
      !isFilledSalesRepOnce &&
      customerAssignedSalesReps.length > 0 &&
      customerAssignedSalesReps.length === 1 &&
      customers.length > 1
    ) {
      const latestAssignedRecord: any =
        customerAssignedSalesReps[customerAssignedSalesReps?.length - 1];
      const assignAllFinalRec = [latestAssignedRecord];

      for (const cusRec of selectedCustomersForSalesRepReassign) {
        // console.log(cusRec, assignAllFinalRec);

        if (cusRec.id !== latestAssignedRecord?.customerId) {
          assignAllFinalRec.push({
            customerId: cusRec.id,
            salesRep: latestAssignedRecord.salesRep,
          });
        }
      }
      // console.log(assignAllFinalRec);
      setCustomerAssignedSalesReps(assignAllFinalRec);
      setIsFilledSalesRepOnce(false);
    }
  }, [customerAssignedSalesReps]);

  useEffect(() => {
    let selectedCustomers = [...selectedCustomersForSalesRepReassign];

    const name = params.sortField;
    const type = params.sortType;

    selectedCustomers = selectedCustomers.sort((a: any, b: any) => {
      if (name === 'name') {
        const nameA = (a.name ?? '').toUpperCase(); // to ensure case-insensitive comparison
        const nameB = (b.name ?? '').toUpperCase(); // to ensure case-insensitive comparison

        if (type === 'asc') {
          return nameA.localeCompare(nameB);
        }

        return nameB.localeCompare(nameA);
      } else if (name === 'orderCommissionees') {
        const nameA = (a.orderCommissionees ?? '').toUpperCase(); // to ensure case-insensitive comparison
        const nameB = (b.orderCommissionees ?? '').toUpperCase(); // to ensure case-insensitive comparison

        if (type === 'asc') {
          return nameA.localeCompare(nameB);
        }

        return nameB.localeCompare(nameA);
      } else {
        if (a[name] < b[name]) {
          return type === 'asc' ? -1 : 1;
        }

        if (a[name] > b[name]) {
          return type === 'asc' ? 1 : -1;
        }
      }
    });

    const startIndex = (params.page - 1) * params.limit;
    const endIndex = startIndex + params.limit;
    selectedCustomers = selectedCustomers.slice(startIndex, endIndex);

    setCustomers(selectedCustomers);
  }, [selectedCustomersForSalesRepReassign, params]);

  const loadMembersList = () => {
    setIsSalesUsersListLoading(true);
    listUser({ type: 'sales' })
      .then((response: any) => {
        const updatedListAllMembers = getSelectBoxOptions(
          response?.data,
          'id',
          'firstName',
          true,
          'imageUrl',
          'image',
          undefined,
          'lastName',
          true
        );
        updatedListAllMembers?.sort((a, b) => a?.label.localeCompare(b?.label));

        setSalesUserList([
          {
            value: 'unassign',
            label: 'Unassign',
            image: null,
            isImage: false,
          },
          ...updatedListAllMembers,
        ]);
      })
      .catch((e: unknown) => {
        console.log(e);
      })
      .finally(() => {
        setIsSalesUsersListLoading(false);
      });
  };

  useEffect(() => {
    loadMembersList();
  }, []);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer Name',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
        sortable: false,
      },
    ],
    []
  );

  const reassignSalesRepOfCustomers = () => {
    setCustomersSalesRepReassignLoading(true);

    reassignCustomersSalesRep({ customerAssignedSalesReps })
      .then((response: any) => {
        WalToast.success(response?.message);
      })
      .finally(() => {
        setCustomersSalesRepReassignLoading(false);
        setCustomerAssignedSalesReps([]);
        handleClose(true);
      })
      .catch(console.log);
  };

  return (
    <CommonModal
      handleClose={() =>
        customersSalesRepReassignLoading ? false : handleClose(false)
      }
      headerIcon={<PresentationChart03 />}
      size={'max-w-[970px]'}
      isOverflow={true}
    >
      <div className="w-full p-5">
        <div
          className={`w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col`}
        >
          <div className="table-top-header relative">
            <div className="table-left-wrap max-smd:w-full max-sm:mb-2">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title max-md:w-full">
                  Reassign Customers
                </h5>
                <p className="table-subtitle mt-0 hidden max-md:block w-full">
                  Manage your Unassigned Orders.
                </p>
                <BadgeCmp style="modern" type="success" mainClassName="">
                  {selectedCustomersForSalesRepReassign.length} unassigned
                  customers
                </BadgeCmp>
              </div>
              <p className="table-subtitle max-md:hidden">
                Manage your Unassigned Orders.
              </p>
            </div>
            <div className="gap-3 flex flex-wrap flex-none sm:w-auto w-full items-center">
              <div className="min-w-[70px]">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArr}
                  onChangeFunc={(event: any) => {
                    setParams({ ...params, limit: event.value, page: 1 });
                  }}
                  isSearchable={false}
                  value={recordsPerPageArr.find(
                    (val: any) => val.value === params.limit
                  )}
                />
              </div>
              <div className="flex-1 flex gap-3 items-center">
                <ButtonCmp
                  className="btn_secondary_black md:flex-none flex-1 min-w-20"
                  disabled={customersSalesRepReassignLoading}
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </ButtonCmp>
                <ButtonCmp
                  className="btn_primary  md:flex-none flex-1 min-w-20"
                  onClick={reassignSalesRepOfCustomers}
                  disabled={
                    customersSalesRepReassignLoading ||
                    !(customerAssignedSalesReps.length > 0)
                  }
                  loading={customersSalesRepReassignLoading}
                >
                  Save
                </ButtonCmp>
              </div>

              <XClose
                className="text-gray400 cursor-pointer max-sm:absolute max-sm:top-4 max-sm:right-4"
                onClick={() =>
                  customersSalesRepReassignLoading ? false : handleClose(false)
                }
              />
            </div>
          </div>
          <div className="h-[calc(100vh_-_353px)] min-h-[200px] w-full flex">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                TableLoaderRowCmp={<></>}
                headCells={headCells}
                params={params}
                setParams={setParams}
                tableDataArr={customers}
                TableRowCmp={CustomerReassignRow}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
                isTableDataLoading={false}
                tableRowCmpProps={{
                  salesUserList: salesUserList,
                  isSalesUsersListLoading: isSalesUsersListLoading,
                  setCustomerAssignedSalesReps: setCustomerAssignedSalesReps,
                  customerAssignedSalesReps: customerAssignedSalesReps,
                }}
              />
            </div>
          </div>
          {selectedCustomersForSalesRepReassign?.length ? (
            <div className="w-full ">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={selectedCustomersForSalesRepReassign?.length}
                currentPage={params.page}
                handlePagination={(page: number) => {
                  setParams((old) => ({ ...old, page }));
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </CommonModal>
  );
};

export default CustomerSalesRepReassignModal;
