import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { memo } from 'react';

const RadarAdditionalServices = ({ orderLeg }: any) => (
  <>
    <div className="flex flex-col gap-5">
      {orderLeg?.orderAdditionalServices?.filter(
        (additionalServices: any) => additionalServices.serviceType == 'PICKUP'
      ).length > 0 && (
        <div className="w-full">
          <h6 className="text-grayLight600 text-xs font-medium mb-1">
            Pickup Additional Services
          </h6>
          <ul className="rounded-[10px] border border-utilityGray200 p-3 flex flex-wrap gap-y-3">
            {orderLeg?.orderAdditionalServices
              ?.filter(
                (additionalServices: any) =>
                  additionalServices.serviceType == 'PICKUP'
              )
              .map((additionalServices: any, index: number) => (
                <li className="flex gap-2 text-textSecondary w-1/2" key={index}>
                  <Check className="w-4 h-4 text-primary flex-none" />
                  <h6 className="text-xs truncate pr-1">
                    {' '}
                    {additionalServices.serviceName}
                  </h6>
                </li>
              ))}
          </ul>
        </div>
      )}

      {orderLeg?.orderAdditionalServices?.filter(
        (additionalServices: any) =>
          additionalServices.serviceType == 'DELIVERY'
      ).length > 0 && (
        <div className="w-full">
          <h6 className="text-grayLight600 text-xs font-medium mb-1">
            Delivery Additional Services
          </h6>
          <ul className="rounded-[10px] border border-utilityGray200 p-3 flex flex-wrap gap-y-3">
            {orderLeg?.orderAdditionalServices
              ?.filter(
                (additionalServices: any) =>
                  additionalServices.serviceType == 'DELIVERY'
              )
              .map((additionalServices: any, index: number) => (
                <li className="flex gap-2 text-textSecondary w-1/2" key={index}>
                  <Check className="w-4 h-4 text-primary flex-none" />
                  <h6 className="text-xs truncate pr-1">
                    {' '}
                    {additionalServices.serviceName}
                  </h6>
                </li>
              ))}
          </ul>
        </div>
      )}

      {orderLeg?.orderAdditionalServices?.filter(
        (additionalServices: any) => additionalServices.serviceType == 'OTHER'
      ).length > 0 && (
        <div className="w-full">
          <h6 className="text-grayLight600 text-xs font-medium mb-1">
            Select Other Additional Services
          </h6>
          <ul className="rounded-[10px] border border-utilityGray200 p-3 flex flex-wrap gap-y-3">
            {orderLeg?.orderAdditionalServices
              ?.filter(
                (additionalServices: any) =>
                  additionalServices.serviceType == 'OTHER'
              )
              .map((additionalServices: any, index: number) => (
                <li className="flex gap-2 text-textSecondary w-1/2" key={index}>
                  <Check className="w-4 h-4 text-primary flex-none" />
                  <h6 className="text-xs truncate pr-1">
                    {' '}
                    {additionalServices.serviceName}
                  </h6>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  </>
);

export default memo(RadarAdditionalServices);
