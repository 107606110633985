import { SearchLg, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';

const NoEmailUI = ({ clearSearch, params }: any) => (
  <div className="h-full flex items-center justify-center overflow-hidden ">
    <div className="max-w-[480px] flex items-center justify-center flex-col z-[0] relative before:[''] before:bg-[url('../../assets/img/not-found-images/empty-state.png')] before:w-full before:h-[calc(100%+190px)] before:absolute before:bottom-0 before:z-[-1] before:bg-no-repeat before:bg-top ">
      <div
        className={`rounded-[10px] border border-utilityGray200 shadow-sm h-11 w-11 bg-white text-textSecondary flex items-center justify-center mb-4`}
      >
        <SearchLg className="w-5 h-5" />
      </div>
      <div className="text-center text-grayLight900 text-sm font-semibold leading-6 mb-1 bg-white">
        No Quote Requests Found
      </div>
      <div className="sm:w-[342px] p-1 text-center text-grayLight600 text-xs font-normal leading-normal bg-white mb-2">
        Quote Requests will automatically populate here as you receive them in
        your connected outlook inbox.
      </div>
      {params.search && (
        <ButtonCmp
          icon={<XClose className="w-4 h-4" />}
          iconSide="right"
          className={'btn_primary'}
          onClick={clearSearch}
        >
          Clear Search
        </ButtonCmp>
      )}
    </div>
  </div>
);

export default NoEmailUI;
