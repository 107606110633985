import React, { useContext } from 'react';
import { filterServicesByType } from 'src/app/Orders/order.constant';

import { OrderContext } from '../..';
import AdditionServiceSection from '../AdditionServiceSection';

import RenderOrderDimensionSection from './RenderOrderDimensionSection';
import RenderOrderSection from './RenderOrderSection';

const OrderDetailsTab = () => {
  const { currentLegData, isLoading, serviceList, order, handlingUnitOptions } =
    useContext<any>(OrderContext);

  const otherAddtionalService = filterServicesByType(
    serviceList,
    currentLegData?.additionalServices?.serviceId,
    'OTHER'
  );

  return (
    <>
      <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0">
        <RenderOrderSection
          isLoading={isLoading}
          currentLegData={currentLegData}
          order={order}
        />
      </div>
      {currentLegData?.order_dimensions?.length > 0 &&
        currentLegData?.order_dimensions[0]?.id && (
          <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0">
            <RenderOrderDimensionSection
              isLoading={isLoading}
              currentLegData={currentLegData}
              handlingUnitOptions={handlingUnitOptions}
            />
          </div>
        )}
      {otherAddtionalService?.length > 0 && (
        <div className="flex-1 p-4">
          <AdditionServiceSection
            additionalService={otherAddtionalService}
            type={'Other Additional Services'}
          />
        </div>
      )}
    </>
  );
};

export default OrderDetailsTab;
