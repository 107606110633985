import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import RequestLoadInsurance from 'src/app/LoadInsurance/RequestLoadInsurance';
import AdditionalServiceCmp from 'src/app/Orders/Common/AdditionalServiceCmp';
import { handleInputChange } from 'src/app/Orders/order.constant';
import DeleteModal from 'src/components/DeleteModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';
import { listHandlingUnit } from 'src/services/CommonService';
import {
  deleteLoadInsurance,
  linkLoadInsuranceWithOrder,
} from 'src/services/OrderService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';

import Dimensions from './Dimensions';

interface IProps {
  setIsTailgate: any;
  serviceList: any;
  setWeightMeasurement: any;
  setLengthMeasurement: any;
  lengthMeasurement: any;
  weightMeasurement: any;
  tempLegData: any;
  setTempLegData: any;
  dimensions: any;
  setDimensions: any;
  order: any;
  setOrder: any;
}

const initAction = {
  mode: null,
  carrier: false,
};

const AddOrderDetails = ({
  setIsTailgate,
  serviceList,
  setWeightMeasurement,
  setLengthMeasurement,
  lengthMeasurement,
  weightMeasurement,
  tempLegData,
  setTempLegData,
  dimensions,
  setDimensions,
  order,
  setOrder,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();

  const [action, setAction] = useState<{
    mode: string | null;
    carrier: boolean;
  }>(initAction);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);
  const [isInsuranceToggleChecked, setIsInsuranceToggleChecked] =
    useState(false);

  const currencyOptions = [
    {
      label: 'USD',
      value: 'USD',
    },
    {
      label: 'CAD',
      value: 'CAD',
    },
  ];

  useEffect(() => {
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (order?.loadInsuranceId) {
      setIsInsuranceToggleChecked(true);
    }
  }, [order]);

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setIsInsuranceToggleChecked(false);
  }, []);

  const handleToggleChange = useCallback(() => {
    let newState = !isInsuranceToggleChecked;
    let newMode: any = null;

    if (newState) {
      newMode = 'load-insurance-request';
    } else if (order?.loadInsuranceId) {
      newMode = 'delete-load-insurance';
      newState = true;
    }

    setIsInsuranceToggleChecked(newState);

    setAction((old) => ({ ...old, mode: newMode }));
  }, [order, setAction, isInsuranceToggleChecked]);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        deleteLoadInsurance({
          loadInsuranceId: order?.loadInsuranceId,
          masterOrderId: order?.id,
        })
          .then((response) => {
            if (response?.data) {
              setIsInsuranceToggleChecked(false);

              setOrder((old: any) => ({
                ...old,
                loadInsurance: null,
                loadInsuranceId: null,
              }));

              setAction((old) => ({ ...old, mode: null }));
            }
          })
          .catch(console.error);
      } else {
        setAction((old) => ({ ...old, mode: null }));
      }
    },
    [order]
  );

  return (
    <>
      <div className="flex flex-col gap-y-5">
        <div>
          <h5 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
            Add Order Details
          </h5>
          <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
            <div className="flex flex-wrap sm:-m-1.5">
              <Controller
                name={`poNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="PO_Number"
                    label="PO Number"
                    placeholder="Enter PO number"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:px-1.5 sm:py-2 sm:w-[19%]"
                    onChangeFunc={(e) => {
                      onChange(e);

                      if (error?.message) {
                        clearErrors('poNumber');
                      }
                    }}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`refNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="refNumber"
                    label="REF Number"
                    placeholder="Enter REF number"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:px-1.5 sm:py-2 sm:w-[19%]"
                    onChangeFunc={(e) => {
                      onChange(e);

                      if (error?.message) {
                        clearErrors('refNumber');
                      }
                    }}
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <Controller
                name={`linearFootage`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="linearFootage"
                    inputType="number"
                    label="Linear Footage"
                    placeholder="Enter Linear Footage"
                    value={value}
                    labelClassName="block mb-1.5"
                    className=""
                    parentClassName="sm:px-1.5 sm:py-2 sm:w-[19%]"
                    onChangeFunc={(e) => {
                      onChange(e);

                      if (error?.message) {
                        clearErrors('linearFootage');
                      }
                    }}
                    shouldFormatNumber={true}
                    // required
                    errorText={error ? error.message : null}
                  />
                )}
              />

              <div className="sm:px-1.5 sm:py-2 sm:w-[19%]">
                <label className="form_label mb-1.5 block">Total Weight</label>
                <Controller
                  name={`totalWeight`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      inputName="totalWeight"
                      inputType="number"
                      placeholder="Enter Total Weight"
                      value={`${value}`}
                      className=""
                      parentClassName=""
                      onChangeFunc={(e) => {
                        handleInputChange(e, 'change', onChange);

                        if (error?.message) {
                          clearErrors('totalWeight');
                        }
                      }}
                      shouldFormatNumber={true}
                      errorText={error ? error.message : null}
                    />
                  )}
                />
              </div>

              <div className="sm:px-1.5 sm:py-2 sm:w-[22%] flex-1">
                <label className="form_label mb-1.5 block">
                  Commercial Value
                </label>
                <div className="form_control p-0 relative !h-auto">
                  <Controller
                    name={`declaredValue`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputText
                        inputName="Declared_Value"
                        inputType="number"
                        placeholder="Enter number"
                        value={value}
                        className={`!h-auto !pr-20 ${
                          errors?.declaredValue?.message
                            ? 'border border-red-500 border-solid'
                            : '!border-0'
                        }`}
                        shouldFormatNumber={true}
                        onChangeFunc={(e) => {
                          onChange(e);

                          if (error?.message) {
                            clearErrors('declaredValue');
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={`declaredValueUnit`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectBox
                        name="declaredValueUnit"
                        labelClassName="form_label block mb-1.5"
                        parentClassName="absolute right-0 top-0 w-[80px] dropdown-within-input"
                        id="declaredValueUnit"
                        classComp={`rounded-none rounded-r-lg ${
                          errors?.declaredValue?.message
                            ? 'border border-red-500 border-solid'
                            : ''
                        }`}
                        size="sm"
                        options={currencyOptions}
                        onChangeFunc={(e: any) => {
                          onChange(e?.value);
                        }}
                        value={currencyOptions?.find(
                          (e: any) => e?.value == value
                        )}
                      />
                    )}
                  />
                </div>
                <div className="[&:empty]:mt-0 mt-1.5">
                  {errors?.declaredValue && (
                    <ErrorMsg errorText={errors?.declaredValue?.message} />
                  )}
                </div>
              </div>

              <Controller
                name={`specialNotes`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="Special_Notes"
                    label="Special Notes"
                    asTextarea="textarea"
                    placeholder="Enter notes"
                    value={value}
                    labelClassName="block mb-1.5"
                    className="h-[76px]"
                    parentClassName="sm:px-1.5 sm:py-2 w-full"
                    onChangeFunc={(e) => {
                      onChange(e);

                      if (error?.message) {
                        clearErrors('specialNotes');
                      }
                    }}
                    errorText={error ? error.message : null}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div>
          <Dimensions
            handlingUnitOptions={handlingUnitOptions}
            isHandlingUnitLoading={isHandlingUnitLoading}
            setWeightMeasurement={setWeightMeasurement}
            setLengthMeasurement={setLengthMeasurement}
            lengthMeasurement={lengthMeasurement}
            weightMeasurement={weightMeasurement}
            dimensions={dimensions}
            setDimensions={setDimensions}
          />
        </div>
        <div className="sm:-mx-1.5 sm:-my-1">
          <AdditionalServiceCmp
            setIsTailgate={setIsTailgate}
            currentLegData={tempLegData}
            setCurrentLegData={setTempLegData}
            setValue={setValue}
            title={'Select Other Additional Services'}
            serviceList={serviceList}
            showService={'other'}
            additionalServiceHeaderClass="!bg-gray25"
            additionalServiceClass="!border-utilityGray200 !shadow-xs"
          />
        </div>

        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-5">
          <Toggle
            labelPosition="left"
            label={`Would you like to insure this load?`}
            isChecked={isInsuranceToggleChecked}
            onChange={() => {
              handleToggleChange();
            }}
            labelClassName="justify-between"
            labelTextClassName="text-grayLight900 text-sm font-semibold"
          />

          {order?.loadInsurance && (
            <div className="rounded-[10px] border border-utilityGray200 shadow-sm p-4 bg-white flex gap-x-6 flex-wrap mt-3">
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Load Insurance ID
                </h6>
                <div className="text-sm">
                  {order?.loadInsurance?.load_insurance_id
                    ? `#${order?.loadInsurance?.load_insurance_id}`
                    : '-'}
                </div>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Created Date
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium `}
                  dangerouslySetInnerHTML={{
                    __html: order?.loadInsurance?.createdAt
                      ? getDateWithSuffixFormat(
                          getFormattedDate(
                            order?.loadInsurance?.createdAt,
                            `Do MMMM, HH:mm A`,
                            true,
                            true
                          )
                        )
                      : '-',
                  }}
                ></p>
              </div>
              <div className="flex-1">
                <h6 className="text-grayLight600 text-xs font-normal mb-1">
                  Commodity
                </h6>
                <div className="text-sm">
                  {order?.loadInsurance?.commodity ?? '-'}
                </div>
              </div>
            </div>
          )}
        </div>

        {action.mode === 'load-insurance-request' ? (
          <RequestLoadInsurance
            handleClose={handleDrawerClose}
            customerID={order?.customerId}
            orderId={order?.orderId}
            userID={order?.salesRepId}
            isDisabled={true}
            commercialValue={order?.declaredValue}
            onSubmitLoadInsurance={(data: any) => {
              if (data?.data) {
                setOrder((old: any) => ({
                  ...old,
                  loadInsurance: data?.data,
                  loadInsuranceId: data?.data?.id,
                }));

                linkLoadInsuranceWithOrder({
                  loadInsuranceId: data?.data?.id,
                  masterOrderId: order?.id,
                })
                  .then((response) => {
                    if (response?.data) {
                      return true;
                    }
                  })
                  .catch(console.error);
              }

              setAction(initAction);
            }}
          />
        ) : (
          ''
        )}

        {action.mode === 'delete-load-insurance' && (
          <DeleteModal
            moduleTitle={'Delete Load Insurance'}
            moduleTitleDesc={`Are you sure you want to delete these load insurance?`}
            handleClose={handleModalClose}
            dangerBtnText={'Delete'}
          />
        )}
      </div>
    </>
  );
};

export default AddOrderDetails;
