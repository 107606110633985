import {
  Check,
  ChevronDown,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import Map from 'src/components/Map/Map';
import TrimbleMap from 'src/components/TrimbleMap/Map';
import { VALIDATION_MESSAGE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { CarrierList } from 'src/services/CarrierService';
import {
  createWarehouse,
  updateWarehouse,
} from 'src/services/WarehouseService';
import { getShortName, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

const initFormData = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

const initValidForm = {
  warehouseName: true,
  fullAddress: true,
  address1: true,
  city: true,
  state: true,
  country: true,
  postal: true,
  latitude: true,
  longitude: true,
  stateCode: true,
  countryCode: true,
};

const requiredFields = ['fullAddress', 'city', 'state', 'country', 'postal'];

interface IProps {
  handleClose: any;
  status: boolean;
  editWarehouseData?: any;
  isEdit?: boolean;
}

const AddWareHouse = ({
  handleClose,
  status,
  editWarehouseData,
  isEdit,
}: IProps) => {
  const formRef = useRef(null);
  const filterRef: any = useRef(null);
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const { mapService } = useContext(BasicContext);
  const [searchValue, setSearchValue] = useState('');
  const [isFullAddressChanged, setIsFullAddressChanged] = useState(false);
  const [fullAddress, setFullAddress] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isWarehouseCreateLoading, setIsWarehouseCreateLoading] =
    useState(false);
  const [warehouseName, setWarehouseName] = useState('');
  const [carriers, setCarriers] = useState<any[]>([]);
  const [isCarriersLoading, setIsCarriersLoading] = useState(true);
  const [filteredCarrierList, setFilteredCarrierList] = useState<any>([]);
  const [isShowCarrierList, setIsShowCarrierList] = useState(false);
  const [carrierName, setCarrierName] = useState('');
  const [carrierId, setCarrierId] = useState('');

  useEffect(() => {
    if (editWarehouseData && isEdit) {
      setCarrierId(editWarehouseData?.carrierId || '');
      setWarehouseName(editWarehouseData?.warehouseName);
      setFormData({
        fullAddress: editWarehouseData?.fullAddress,
        address1: editWarehouseData?.address1,
        city: editWarehouseData?.city,
        state: editWarehouseData?.state,
        country: editWarehouseData?.country,
        postal: editWarehouseData?.postal,
        latitude: editWarehouseData?.latitude,
        longitude: editWarehouseData?.longitude,
        stateCode: editWarehouseData?.stateCode,
        countryCode: editWarehouseData?.countryCode,
      });
    }
  }, [editWarehouseData]);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      fullAddress: true,
      city: true,
      state: true,
      country: true,
      postal: true,
      warehouseName: true,
    };

    if (warehouseName === '' || warehouseName === null) {
      validFormData.warehouseName = false;
      valid = false;
    }

    if (formData.fullAddress === '' || formData.fullAddress === null) {
      validFormData.fullAddress = false;
      valid = false;
    }

    if (formData.city === '' || formData.city === null) {
      validFormData.city = false;
      valid = false;
    }

    if (formData.state === '' || formData.state === null) {
      validFormData.state = false;
      valid = false;
    }

    if (formData.country === '' || formData.country === null) {
      validFormData.country = false;
      valid = false;
    }

    if (
      formData.postal === '' ||
      formData.postal === null ||
      formData.postal?.length < 4
    ) {
      validFormData.postal = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleCloseModal = () => {
    handleClose(false);
  };

  const getShowFilteredCarrierList = (search: string, originalList: any) => {
    const filteredList = originalList.filter((originalData: any) =>
      originalData?.name?.toLowerCase().includes(search.toLowerCase().trim())
    );

    return [...filteredList] as any;
  };

  useEffect(() => {
    if (isShowCarrierList && searchValue !== '') {
      const filteredList = getShowFilteredCarrierList(searchValue, carriers);
      setFilteredCarrierList(filteredList);
    } else {
      setFilteredCarrierList(carriers.slice(0, 50));
    }
  }, [isShowCarrierList, searchValue]);

  useEffect(() => {
    setIsCarriersLoading(true);
    CarrierList()
      .then((response: any) => {
        setCarriers(response.data);

        if (isEdit && editWarehouseData) {
          const crrName = response.data.find(
            (crr: any) => crr.id === editWarehouseData?.carrierId
          );
          setCarrierName(crrName?.name);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setIsCarriersLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isShowCarrierList &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        event.target.name !== 'searchOrder'
      ) {
        setIsShowCarrierList(false);
      }
    };

    const handleEscapePress = (event: any) => {
      if (isShowCarrierList && event.key === 'Escape') {
        setIsShowCarrierList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowCarrierList]);

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      if (!formData.fullAddress) {
        setFormData(initFormData);
        setValidForm(initValidForm);
        setValidForm({ ...validForm, fullAddress: false });
        setIsFullAddressChanged(false);
      }

      return;
    }

    setFormData(value);
    setIsFullAddressChanged(true);
    isFormValid();
  };

  const MapComponent = useMemo(
    () => (
      <div>
        {mapService === 'trimble_map' ? (
          <TrimbleMap
            address={formData}
            id={'addressMap'}
            height={261}
            onReverseAddressFunc={handleAutocompleteChange}
            className="rounded-[20px]"
          />
        ) : (
          <Map
            address={formData}
            id={'addressMap'}
            height={261}
            onReverseAddressFunc={handleAutocompleteChange}
            className="rounded-[20px]"
          />
        )}
      </div>
    ),
    [isFullAddressChanged, formData.fullAddress]
  );

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event?.preventDefault();
    }
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();

    if (!isFormValid()) {
      return true;
    }

    const createWarehousePayload = {
      carrierId: carrierId,
      warehouseName: warehouseName,
      fullAddress: formData?.fullAddress,
      address1: formData?.address1,
      postal: formData?.postal,
      city: formData?.city,
      state: formData?.state,
      stateCode: formData?.stateCode,
      country: formData?.country,
      countryCode: formData?.countryCode,
      longitude: formData?.longitude,
      latitude: formData?.latitude,
    };

    setIsWarehouseCreateLoading(true);

    if (isEdit) {
      updateWarehouse(editWarehouseData?.id, createWarehousePayload)
        .then((response: any) => {
          WalToast.success(
            response?.message ?? 'Warehouse details updated successfully'
          );
          handleClose(true);
        })
        .finally(() => {
          setIsWarehouseCreateLoading(false);
        })
        .catch((e: any) => {
          console.log(e);
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong updating customer detail.'
          );
        });
    } else {
      createWarehouse(createWarehousePayload)
        .then((response: any) => {
          WalToast.success(
            response?.message ?? 'Warehouse details added successfully'
          );
          handleClose(true);
        })
        .finally(() => {
          setIsWarehouseCreateLoading(false);
        })
        .catch((e: any) => {
          console.log(e);
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong updating customer detail.'
          );
        });
    }
  };

  useEffect(() => {
    let isFieldsValid = requiredFields.every(
      (requiredField) =>
        formData[requiredField] !== '' && formData[requiredField] !== null
    );

    if (formData?.postal?.length < 4) {
      isFieldsValid = false;
    }

    if (formData?.fullAddress) {
      isFormValid();
    }
    setIsValid(isFieldsValid);
  }, [formData]);

  useEffect(() => {
    console.log('carrierName ', carrierName);
  }, [carrierName]);

  return (
    <>
      <CommonModal
        title={`${isEdit ? 'Edit Warehouses' : 'Add New Warehouses'}`}
        titleDesc={`${
          isEdit
            ? 'Edit warehouse with carrier and their location'
            : 'Add new warehouse with carrier and their location'
        }`}
        handleClose={handleClose}
        headerIcon={<PackagePlus />}
        size={'max-w-[688px]'}
        primaryBtnText={`${isEdit ? 'Update' : 'Save Changes'}`}
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleCloseModal}
        primaryBtnOnClick={handleSubmit}
        primaryBtnDisabled={isWarehouseCreateLoading || !isValid}
        primaryBtnLoading={isWarehouseCreateLoading}
        secondaryBtnDisabled={isWarehouseCreateLoading}
      >
        <div className="p-5">
          <div className="w-full relative mb-5">
            <InputText
              inputName="warehouseName"
              label="Facility Name"
              labelClassName="block mb-1.5"
              placeholder="Enter warehouse facility name"
              value={warehouseName}
              className={`form_control ${
                !validForm.warehouseName
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              onChangeFunc={(e) => {
                setWarehouseName(e?.target?.value);
              }}
            />
          </div>
          <div className="w-full relative mb-5">
            <InputText
              label="Carrier"
              labelClassName="block mb-1.5"
              inputName="searchOrder"
              placeholder="Search carrier name"
              className="placeholder:text-secondaryTxtColor"
              parentClassName=""
              searchIconClass={`!right-8`}
              icon={
                <ChevronDown className="absolute top-1/2 -translate-y-1/2 right-2 text-grayText h-5 w-5" />
              }
              value={carrierName}
              onChangeFunc={(e: any) => {
                if (e?.target?.value === '') {
                  setCarrierId('');
                }
                setSearchValue(e.target.value);
                setCarrierName(e?.target?.value);
              }}
              onFocus={() => {
                setIsShowCarrierList(true);
              }}
              // errorText={errors?.orderID?.message ?? null}
              isClearable={true}
              isLoading={isCarriersLoading}
            />
            {isShowCarrierList && (
              <div
                id="dropdownDelay"
                ref={filterRef}
                className={`min-w-[262px] z-10 absolute top-15 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px] left-0 top-full`}
              >
                <ul
                  className="mt-1.5 mb-[9px] max-h-[180px] overflow-y-auto custom-scrollbar-v2"
                  aria-labelledby="dropdownDelayButton"
                >
                  {filteredCarrierList?.length > 0 ? (
                    filteredCarrierList
                      .slice(0, 1000)
                      .map((carrier: any, index: number) => (
                        <>
                          <li
                            key={index}
                            className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 hover:text-primary transition-all duration-[0.3s] ${
                              carrier.id === carrierId ? 'bg-gray50' : ''
                            }`}
                            onClick={() => {
                              console.log('formData ', formData);
                              setCarrierId(carrier?.id);
                              setCarrierName(carrier?.name);
                              setSearchValue('');
                              setIsShowCarrierList(false);
                            }}
                          >
                            <div className="flex justify-between items-center align-middle">
                              {' '}
                              <div className="flex justify-center items-center">
                                {carrier.image ? (
                                  <img
                                    src={`${carrier.imageUrl}${carrier.image}`}
                                    className="table-profile-icn-circle-xl !w-6 !h-6 rounded-full"
                                    alt={carrier.name}
                                    title={carrier.name}
                                    onError={onError}
                                  />
                                ) : (
                                  <div className="table-profile-customer-circle-xl !w-6 !h-6 text-xs rounded-full">
                                    {getShortName(carrier.name)}
                                  </div>
                                )}
                                <span className="font-medium mx-2">
                                  {carrier?.name}{' '}
                                </span>
                              </div>
                              {carrier.id === carrierId ? (
                                <Check className="w-4 h-4 ml-2 text-primary" />
                              ) : (
                                ''
                              )}
                            </div>
                          </li>
                        </>
                      ))
                  ) : (
                    <li
                      className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] `}
                    >
                      {' '}
                      <span className="font-semibold mr-2 items-center justify-center flex">
                        No Carriers Found
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <form
            ref={formRef}
            onKeyDown={handleKeyPress}
            className="flex flex-col gap-5"
          >
            {status &&
              (mapService === 'trimble_map' ? (
                <AutoCompleteTrimble
                  inputName="fullAddress"
                  placeholder="Enter address"
                  className="form_control truncate"
                  label="Address"
                  labelClassName="mb-1.5 block"
                  dropdownClass="!max-h-[200px]"
                  onAddressInputChange={setFullAddress}
                  onChangeFunc={handleAutocompleteChange}
                  onBlur={handleAutocompleteChange}
                  errorText={
                    !validForm.fullAddress ? 'Address is required' : null
                  }
                  value={fullAddress}
                />
              ) : (
                <AutoCompleteGoogle
                  inputName="fullAddress"
                  placeholder="Enter address"
                  className="form_control truncate"
                  label="Address"
                  labelClassName="mb-1.5 block"
                  onAddressInputChange={handleAutocompleteChange}
                  onChangeFunc={handleAutocompleteChange}
                  onBlur={handleAutocompleteChange}
                  errorText={
                    !validForm.fullAddress ? 'Address is required' : null
                  }
                  value={formData?.fullAddress}
                />
              ))}

            {MapComponent}

            <InputText
              inputName="address1"
              placeholder="Enter address 1"
              className="form_control"
              label="Street"
              labelClassName="block mb-1.5"
              value={formData.address1}
              // errorText={!validForm.address1 ? 'Address 1 name is required' : null}
              onChangeFunc={handleInputChange}
            />

            <div className="flex gap-5">
              <div className=" w-1/2 ">
                <InputText
                  inputName="city"
                  placeholder="Enter city"
                  className="form_control"
                  label="City"
                  labelClassName="block mb-1.5"
                  value={formData.city}
                  errorText={
                    formData.fullAddress && !validForm.city
                      ? VALIDATION_MESSAGE.INVALID_ADDRESS
                      : null
                  }
                  onChangeFunc={handleInputChange}
                />
              </div>
              <div className="w-1/2 ">
                <InputText
                  inputName="postal"
                  placeholder="Enter postal code"
                  className="form_control"
                  label="Postal code"
                  labelClassName="block mb-1.5"
                  value={formData.postal}
                  errorText={
                    formData.fullAddress && !validForm.postal
                      ? VALIDATION_MESSAGE.INVALID_ADDRESS
                      : null
                  }
                  onChangeFunc={handleInputChange}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="w-1/2">
                <InputText
                  inputName="state"
                  placeholder="Enter state"
                  className="form_control"
                  label="State"
                  labelClassName="block mb-1.5"
                  value={formData.state}
                  errorText={
                    formData.fullAddress && !validForm.state
                      ? VALIDATION_MESSAGE.INVALID_ADDRESS
                      : null
                  }
                  onChangeFunc={handleInputChange}
                />
              </div>
              <div className="w-1/2">
                <InputText
                  inputName="country"
                  placeholder="Enter country"
                  className="form_control"
                  label="Country"
                  labelClassName="block mb-1.5"
                  value={formData.country}
                  errorText={
                    formData.fullAddress && !validForm.country
                      ? VALIDATION_MESSAGE.INVALID_ADDRESS
                      : null
                  }
                  onChangeFunc={handleInputChange}
                />
              </div>
            </div>
          </form>
        </div>
      </CommonModal>
    </>
  );
};
export default memo(AddWareHouse);
