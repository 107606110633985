import { Call, Device } from '@twilio/voice-sdk';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from 'src/context/AuthContext';
import {
  setAdminJoiningACall,
  setBargeWhisperCall,
  setBargeWhisperNumber,
  setDevice,
  setFromNumberContact,
  setIncomingCall,
  setToken,
} from 'src/redux/CallCenter.slice';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { RootState } from 'src/redux/store';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import ActiveCallModal from './ActiveCallModal';
import BargeWhisperModal from './BargeWhisperModal';
import IncomingCallModal from './IncomingCallModal';
import InProgressCall from './InProgressCall';
import OutboundModal from './OutboundModal';
// import OutboundModal from './OutboundModal';
// import OutboundModal from './OutboundModal';

interface TokenResponse {
  token: string;
}

const CallCenterModals = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);

  const {
    // device,
    incomingCall,
    isCallInProgress,
    outgoingCall,
    isBargeWhisper,
    adminJoiningACall,
    bargeWhisperNumber,
  } = useSelector((state: RootState) => state.CallCenter);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const initializeDevice = (token: string) => {
    // if (device) {
    //   // Unregister if the device is already registered
    //   if (device._state === 'registered') {
    //     device.unregister();
    //   }
    // }

    const newDevice = new Device(token, {
      disableAudioContextSounds: false,
    });

    newDevice.register();

    dispatch(setDevice(newDevice));

    newDevice.on('ready', () => {
      console.log('Device ready for incoming calls');
    });

    newDevice.on('error', (error: any) => {
      console.error('Twilio Device Error:', error);
      // setErrorMessage(error.message);
    });

    newDevice.on('incoming', (call: Call) => {
      console.log('1. Incoming Call:', call);
      console.log('2. call?.parameters', call?.parameters);
      console.log('3. incomingCall?.parameters.To', call?.parameters?.To);

      if (
        call?.parameters.To === `client:${currentUser?.phone}` &&
        bargeWhisperNumber === currentUser.phone
      ) {
        console.log('should not go hear');

        dispatch(setBargeWhisperNumber(null));

        dispatch(setBargeWhisperCall(call));
        dispatch(setAdminJoiningACall(!adminJoiningACall));

        return call.accept();
      }
      console.log('4. Accept call');

      dispatch(setIncomingCall(call));

      // if (call != null) {
      //   setAnotherIncomingCall(call);
      // } else {
      //   setIncomingCall(call);
      // }

      // console.log('5. conference details get successfully');
    });

    // device.register();
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post<TokenResponse>(
          `${window.API_URL}/twilio/get-token`,
          { identity: currentUser?.phone || 'device' }
        );
        const token = response.data.token;
        dispatch(setToken(token));

        // Initialize Twilio Device
        initializeDevice(response.data.token);
      } catch (err) {
        // setErrorMessage('Error fetching token. Please try again.');
        console.error('Error fetching token:', err);
      }
    };

    fetchToken();

    // return () => {
    //   if (device) {
    //     device.destroy();
    //   }
    // };
  }, []);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      console.log('hear 2. socket is connected');

      socketIO.removeAllListeners();
      socketIO.on('hear classifyCaller', function (contact: any) {
        console.log('hear 3. ~ classifyCaller:', contact);
        dispatch(setFromNumberContact(contact));
      });
    } else {
      console.log('hear 2. socket is not connected');
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            console.log('hear socket connected');

            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          // Listen for classifyCaller event
          io.socket.on('classifyCaller', (contact: any) => {
            console.log('hear 🚀 ~ contact:', contact);
            dispatch(setFromNumberContact(contact));
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('classifyCaller');
          };
        })
        .catch((error) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, []);

  return (
    <>
      {incomingCall && !isCallInProgress && <IncomingCallModal />}
      {isCallInProgress && <InProgressCall />}
      {outgoingCall && !isCallInProgress && <OutboundModal />}
      {isBargeWhisper && !isCallInProgress && <BargeWhisperModal />}
      {false && isBargeWhisper && !isCallInProgress && <ActiveCallModal />}
    </>
  );
};

export default CallCenterModals;
