import {
  Phone,
  Mail02,
  Luggage02,
  Star01,
  User01,
  Send01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

const ContactDetailLoader = () => (
  <>
    <div className="border-b border-utilityGray200 flex flex-col items-center pt-8">
      <div className="w-[100px] h-[100px] bg-utilityGray100 rounded-full flex items-center justify-center mb-2 custom-loading">
        <img src="" alt="" className="" />
        <span className="text-5xl font-semibold text-gray500">OR</span>
      </div>
      <h6 className="text-lg text-grayLight900 font-semibold leading-6 mb-2 custom-loading">
        Olivia Rhye
      </h6>
      <div className="flex flex-1 justify-end gap-3 mb-4 custom-loading">
        <ButtonCmp
          icon={<Phone className="w-4 h-4" />}
          className="btn_secondary_black lg:px-[9px] px-2"
        >
          <></>
        </ButtonCmp>
        <ButtonCmp
          icon={<Mail02 className="w-4 h-4" />}
          className="btn_secondary_black lg:px-[9px] px-2"
        >
          <></>
        </ButtonCmp>
      </div>
    </div>
    <div className="overflow-y-auto scrollbar-hide py-4">
      <div className="px-4">
        <div className="flex flex-col gap-y-4">
          <div className="flex items-center gap-2">
            <div className="flex gap-1 w-28 custom-loading">
              <Luggage02 className="w-4 h-4 text-grayLight600" />
              <p className="mb-0 text-xs text-grayLight600">Carrier</p>
            </div>
            <div className="flex items-center cursor-pointer custom-loading">
              <p className="text-primary700 mb-0 text-xs">
                DSS Marine Incorporated
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex gap-1 w-28 custom-loading">
              <Star01 className="w-4 h-4 text-grayLight600" />
              <p className="mb-0 text-xs text-grayLight600">Carrier Rating</p>
            </div>
            <div className="flex items-center cursor-pointer custom-loading">
              <p className="text-primary700 mb-0 text-xs">
                DSS Marine Incorporated
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex gap-1 w-28 custom-loading">
              <User01 className="w-4 h-4 text-grayLight600" />
              <p className="mb-0 text-xs text-grayLight600">Role</p>
            </div>
            <div className="flex items-center cursor-pointer custom-loading">
              <p className="text-primary700 mb-0 text-xs">
                DSS Marine Incorporated
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex gap-1 w-28 custom-loading">
              <Phone className="w-4 h-4 text-grayLight600" />
              <p className="mb-0 text-xs text-grayLight600">Phone</p>
            </div>
            <div className="flex items-center cursor-pointer custom-loading">
              <p className="text-primary700 mb-0 text-xs">
                DSS Marine Incorporated
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex gap-1 w-28 custom-loading">
              <Mail02 className="w-4 h-4 text-grayLight600" />
              <p className="mb-0 text-xs text-grayLight600">Email</p>
            </div>
            <div className="flex items-center cursor-pointer custom-loading">
              <p className="text-primary700 mb-0 text-xs">
                DSS Marine Incorporated
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3 pt-7 flex-1">
        <h6 className="text-xs font-medium text-gray500 mb-1 custom-loading w-fit">
          Notes 2 Notes 2
        </h6>
        <div className="py-3 flex gap-3">
          <div className="relative custom-loading flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary">
            <p className="mb-0 text-sm">OR</p>
            <img src="" alt="" />
          </div>
          <div className="flex-1">
            <h6 className="text-sm text-grayLight900 font-medium custom-loading">
              Olivia Rhye
              <span className="ml-1.5 text-[10px] font-normal text-grayLight600">
                Today, 10:40 AM
              </span>
            </h6>
            <p className="text-xs text-gray500 font-normal mt-1 leading-[18px] custom-loading">
              <span className="mr-1 px-1.5 py-[2px] rounded-md text-xs text-primary700 font-normal bg-primary100">
                @Orlando Diggs
              </span>
              Lorem Ipsum has been the industry&apos;s standard dummy text ever
              since the 1500s, when an unknown printer.
            </p>
          </div>
        </div>

        <div className="py-3 flex gap-3">
          <div className="relative custom-loading flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary">
            <p className="mb-0 text-sm">DW</p>
            <img src="" alt="" />
          </div>
          <div className="flex-1">
            <h6 className="text-sm text-grayLight900 font-medium custom-loading">
              Demi Wilkinson
              <span className="ml-1.5 text-[10px] font-normal text-grayLight600">
                Today, 10:40 AM
              </span>
            </h6>
            <p className="text-xs text-gray500 font-normal mt-1 leading-[18px] custom-loading">
              Lorem Ipsum has been the industry&apos;s standard dummy text ever.
            </p>
          </div>
        </div>
      </div>
    </div>

    <form>
      <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
        <InputText
          inputName="email"
          placeholder="Message"
          id="chatInput"
          className=""
          value={''}
          onChangeFunc={() => {}}
          parentClassName="flex-1 custom-loading rounded-lg"
        />

        <ButtonCmp className="btn_primary lg:!px-[9px] !px-2 custom-loading">
          <Send01 className="w-4 h-4" />
        </ButtonCmp>
      </div>
    </form>
  </>
);

export default ContactDetailLoader;
