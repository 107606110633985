/* eslint-disable max-lines-per-function */
import {
  Send03,
  Edit02,
  ArrowRight,
  RefreshCw01,
  LinkExternal02,
  ThumbsUp,
  ThumbsDown,
  RefreshCw05,
  AlertCircle,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import ButtonCmp from 'src/components/ButtonCmp';
import { PERMISSION } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { getShortName, useRolePermission } from 'src/utils/CommonFunctions';

import AiStarIcon from '../../assets/img/Ai-one-star.svg';

const MessageListDataShowCmp = ({
  messageListdata,
  messageEndRef,
  currentUser,
  isEditMessage,
  textAreaEditMessageRef,
  editMessageValue,
  handleInputChangeEditMessage,
  handleKeyDownEditMessage,
  onSendMessage,
  setIsEditMessage,
  setEditMessageValue,
  loading,
  sendMessageThreadId,
  isThreadChatListDetail,
  aiError,
}: {
  messageListdata: Array<any>;
  messageEndRef: any;
  currentUser: any;
  isEditMessage: any;
  textAreaEditMessageRef: any;
  editMessageValue: any;
  handleInputChangeEditMessage: any;
  handleKeyDownEditMessage: any;
  onSendMessage: any;
  setIsEditMessage: (value: any) => void;
  setEditMessageValue: (value: string) => void;
  loading: boolean;
  sendMessageThreadId: any;
  isThreadChatListDetail: any;
  aiError: any;
}) => {
  const { hasPermissionV2 } = useRolePermission();
  const navigate = useNavigate();
  const trmporaryHideDivs = true;

  const openCustomerDetailPage = (id: any) => {
    if (hasPermissionV2(PERMISSION.CUSTOMER_DETAIL) && id) {
      navigate(`${PATH.CUSTOMER_DETAILS}/${id}`);
    }
  };

  // const makeHtmlStrcutre = (aiResponseMessage: string) => {
  //   if (!aiResponseMessage) {
  //     return { title: '', customers: [] };
  //   }

  //   const lines = aiResponseMessage
  //     .split(/\\n|\n/)
  //     .filter((line: any) => line.trim() !== '');

  //   const title = lines[0] || 'No Title'; // Default title if none exists
  //   const lineLists = lines.slice(1); // The rest are the customer details

  //   return {
  //     title,
  //     lineLists,
  //   };
  // };

  const parseData = (children: any) => {
    if (typeof children === 'string' || !Array.isArray(children)) {
      return;
    }
    const data: any = {};
    children.forEach((item: any) => {
      if (typeof item === 'string' && item.includes(':')) {
        const [key, ...valueParts] = item.split(':');
        const value = valueParts.join(':').trim();
        data[key.trim()] = value || 'N/A';
      }
    });

    return [data]; // Wrap in an array for a single array of objects
  };

  const makeCardCmp = (cardsFormateData: any) => (
    <>
      {cardsFormateData?.map((isCardsData: any, indexOfCards: any) => (
        <div
          className="p-3 rounded-lg border border-utilityGray200 bg-white group/item cursor-pointer flex items-center mb-4"
          key={indexOfCards}
          onClick={() => {
            openCustomerDetailPage(isCardsData?.ID);
          }}
        >
          <div className="flex flex-wrap -mx-2 flex-1">
            <div className="px-2 w-1/4 space-y-0.5">
              <h6 className="text-grayLight600 font-normal text-xs leading-[1.5]">
                Customer Name
              </h6>
              <div className="flex items-center gap-1">
                {!isCardsData?.['Customer Image URL'] ||
                isCardsData?.['Customer Image URL'] === 'None' ? (
                  <span className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                    {getShortName(`${isCardsData?.['Customer Name']}`)}
                  </span>
                ) : (
                  <figure className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none">
                    <img
                      src={isCardsData?.['Customer Image URL']}
                      alt="Customer"
                      className="w-full h-full"
                    />
                  </figure>
                )}
                <p className="text-grayLight900 text-xs leading-[1.5] font-medium truncate mr-1">
                  {isCardsData?.['Customer Name']}
                </p>
              </div>
            </div>
            <div className="px-2 w-1/4 space-y-0.5">
              <h6 className="text-grayLight600 font-normal text-xs leading-[1.5]">
                Contact
              </h6>
              <p className="text-grayLight900 text-xs leading-[1.5] font-medium">
                {isCardsData?.Contact}
              </p>
            </div>
            <div className="px-2 w-1/4 space-y-0.5">
              <h6 className="text-grayLight600 font-normal text-xs leading-[1.5]">
                Total Orders
              </h6>
              <p className="text-grayLight900 text-xs leading-[1.5] font-medium">
                {isCardsData?.['Total Orders']}
              </p>
            </div>
            <div className="px-2 w-1/4 space-y-0.5">
              <h6 className="text-grayLight600 font-normal text-xs leading-[1.5]">
                Total Margin
              </h6>
              <p className="text-grayLight900 text-xs leading-[1.5] font-medium">
                ${isCardsData?.['Total Margin']}
              </p>
            </div>
          </div>
          <ArrowRight
            width={24}
            className="text-primary invisible transition-all group-hover/item:visible"
          />
        </div>
      ))}
    </>
  );

  const makeCustomTable = (props: any) => {
    const filteredChildren = React.Children.toArray(props.children).filter(
      (child: any, indexFilter: number) => {
        // Exclude the element if it contains "ID" or is the first column
        if (
          child.props &&
          child.props.children &&
          child.props.children.toString().toLowerCase() === 'id'
        ) {
          return false; // Exclude elements with "ID"
        }

        if (indexFilter === 0 || indexFilter === 5) {
          return false; // Optionally exclude the  columns
        }

        return true; // Include other elements
      }
    );

    return (
      <tr
        className={`border-b border-utilityGray200 group/item ${
          props.children?.[0]?.props?.children.toString().toLowerCase() !== 'id'
            ? 'cursor-pointer'
            : ''
        }`}
        {...props}
        onClick={() => {
          if (
            props.children?.[0]?.props?.children.toString().toLowerCase() !==
            'id'
          ) {
            openCustomerDetailPage(props.children?.[0]?.props?.children);
          }
        }}
      >
        {filteredChildren}

        <td
          className={`${
            props.children?.[0]?.props?.children.toString().toLowerCase() !==
            'id'
              ? ''
              : 'text-xs text-grayLight600 font-medium bg-gray50'
          }`}
        >
          {props.children?.[0]?.props?.children.toString().toLowerCase() !==
          'id' ? (
            <ArrowRight
              width={24}
              className="text-primary invisible transition-all  group-hover/item:visible"
            />
          ) : (
            ''
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      {messageListdata.map((msgs, index) => {
        const content = msgs?.message?.replace(/\\n+/g, '\n').trim();

        // Check if ## TABLE is present
        const isDiffPresentTable = content.includes('## TABLE');
        const isDiffPresentCards = content.includes('## CARDS');
        const isDiffPresentDraft = content.includes('## DRAFT');

        // let { title, lineLists } = makeHtmlStrcutre(msgs?.message);

        return (
          <div
            className={`${
              msgs?.sender === 'user' ? '' : 'mb-5'
            } text-base font-normal`}
            key={index}
            ref={index === messageListdata.length - 2 ? messageEndRef : null}
          >
            {msgs?.sender === 'user' ? (
              <div className="flex mt-10">
                {currentUser.image ? (
                  <figure className="w-10 h-10 basis-10 grow-0 shrink-0  mr-3">
                    <img
                      className="object-cover w-8 h-8 overflow-hidden rounded-full"
                      src={`${currentUser.imageUrl}${currentUser.image}`}
                      alt=""
                    />
                  </figure>
                ) : (
                  <div className="w-10 h-10 text-sm rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none mr-3">
                    {getShortName(
                      `${currentUser.firstName} ${currentUser.lastName}`
                    )}
                  </div>
                )}
                <>
                  {isEditMessage === index ? (
                    <div className="flex-1">
                      <div className="flex gap-2 group/item items-center px-2 py-3 border border-borderPrimary rounded-md">
                        <textarea
                          rows={1}
                          className="text-grayLight900 resize-none scrollbar-hide overflow-y-auto max-h-56 font-medium focus:outline-none w-full ignore-blur"
                          ref={textAreaEditMessageRef}
                          name="askCopilotEdit"
                          value={
                            isEditMessage === index
                              ? editMessageValue
                              : msgs?.message
                          }
                          onChange={handleInputChangeEditMessage}
                          onKeyDown={(e) => {
                            handleKeyDownEditMessage(
                              e,
                              msgs?.user_row ? msgs?.user_row?.id : msgs?.id,
                              messageListdata[index + 1]?.assistant_row
                                ? messageListdata[index + 1]?.assistant_row?.id
                                : messageListdata[index + 1]?.id
                            );
                          }}
                        />
                      </div>
                      <div className="mt-2">
                        <ButtonCmp
                          className="btn-outline-primary !border-none !p-0 hover:!bg-transparent"
                          onClick={() => {
                            setIsEditMessage(null);
                            setEditMessageValue('');
                          }}
                        >
                          Cancel
                        </ButtonCmp>
                        <ButtonCmp
                          className="btn_secondary_black !border-none !p-0 ml-2 hover:!bg-transparent"
                          onClick={() =>
                            onSendMessage(
                              editMessageValue.trim(),
                              true,
                              msgs?.user_row ? msgs?.user_row?.id : msgs?.id,
                              messageListdata[index + 1]?.assistant_row
                                ? messageListdata[index + 1]?.assistant_row?.id
                                : messageListdata[index + 1]?.id
                            )
                          }
                          disabled={
                            msgs?.message === editMessageValue ||
                            !editMessageValue.trim()
                          }
                        >
                          Update
                        </ButtonCmp>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2 group/item">
                      <div className="text-grayLight900 pt-1.5 flex-auto font-medium">
                        {isEditMessage === index
                          ? editMessageValue || msgs?.message
                          : msgs?.message}
                      </div>
                      {!loading && messageListdata?.length - 2 === index && (
                        <span
                          className="p-2 bg-gray100 h-fit flex justify-center items-center rounded-full transition-all invisible cursor-pointer text-grayLight900 group-hover/item:visible"
                          onClick={() => {
                            setIsEditMessage(index);
                            setEditMessageValue(msgs?.message);
                          }}
                        >
                          <Edit02 className="w-[14px] h-[14px]" />
                        </span>
                      )}
                    </div>
                  )}
                </>
              </div>
            ) : (
              <div className=" flex mt-10">
                <div className="flex w-10 h-10 basis-10 p-2 bg-white rounded-full grow-0 shrink-0 overflow-hidden mr-3 shadow-[inset_0_0_4px_0_rgba(0,0,0,0.1)]">
                  <img src={AiStarIcon} alt="" className="" />
                </div>
                {(loading &&
                  messageListdata?.length - 1 === index &&
                  sendMessageThreadId.current ===
                    isThreadChatListDetail?.thread?.threadId) ||
                (loading &&
                  messageListdata?.length - 1 === index &&
                  !isThreadChatListDetail) ? (
                  <ul className="flex flex-col gap-y-2.5 mb-1 w-full">
                    <li className="text-xs custom-loading-v2">
                      dkjkdshhdshfdjjhd
                    </li>
                    <li className="text-xs custom-loading-v2 custom-loading-v2-delay">
                      dkjkdshhdshfdjjhd
                    </li>
                    <li className="text-xs custom-loading-v2 custom-loading-v2-moredelay w-[75%]">
                      dkjkdshhdshfdjjhd
                    </li>
                  </ul>
                ) : (
                  <div className="flex flex-col w-full">
                    {aiError && messageListdata?.length - 1 === index ? (
                      <></>
                    ) : (
                      <>
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            h1: ({ ...props }) => (
                              <h2
                                className="text-3xl font-bold mb-4 text-gray-800"
                                {...props}
                              />
                            ),
                            h2: ({ ...props }) => (
                              <h2
                                className="text-2xl font-semibold mb-3 text-gray-700"
                                {...props}
                              />
                            ),
                            h3: ({ ...props }) => (
                              <h3
                                className="text-xl font-medium mb-2 text-gray-600"
                                {...props}
                              />
                            ),
                            p: ({ ...props }: any) => {
                              if (
                                isDiffPresentCards &&
                                Array.isArray(props?.children)
                              ) {
                                let cardsFormateData = parseData(
                                  props?.children
                                );

                                return makeCardCmp(cardsFormateData);
                              }

                              return (
                                <p
                                  className="mb-4 text-gray-700 leading-relaxed"
                                  {...props}
                                />
                              );
                            },
                            ul: ({ ...props }) => (
                              <ul
                                className="list-disc pl-6 mb-4 text-gray-700"
                                {...props}
                              />
                            ),
                            ol: ({ ...props }) => (
                              <ol
                                className="list-decimal pl-6 mb-4 text-gray-700"
                                {...props}
                              />
                            ),
                            li: ({ ...props }) => (
                              <li className="mb-2" {...props} />
                            ),
                            table: ({ ...props }) => (
                              <div className="overflow-auto border border-utilityGray200 rounded-lg custom-scrollbar w-[760px]">
                                <table
                                  className="table text-sm text-left text-nowrap whitespace-nowrap"
                                  {...props}
                                />
                              </div>
                            ),
                            th: ({ ...props }) => (
                              <th
                                className="text-xs text-grayLight600 font-medium bg-gray50"
                                {...props}
                              />
                            ),
                            tr: ({ ...props }: any) => {
                              if (isDiffPresentTable) {
                                return makeCustomTable(props);
                              }

                              return (
                                <tr
                                  className="border-b border-utilityGray200"
                                  {...props}
                                />
                              );
                            },
                            td: ({ ...props }) => (
                              <td
                                className="px-5 py-4 border-none"
                                {...props}
                              />
                            ),
                            blockquote: ({ ...props }) => (
                              <blockquote
                                className="border-l-4 border-blue-500 pl-4 italic text-gray-600"
                                {...props}
                              />
                            ),
                            code: ({ ...props }) => (
                              <code
                                className="bg-gray-100 text-red-500 px-2 py-1 rounded text-sm font-mono"
                                {...props}
                              />
                            ),
                            img: ({ ...props }) => (
                              <img
                                className="max-w-full h-auto rounded"
                                {...props}
                              />
                            ),
                            a: ({ ...props }) => (
                              <a
                                className="text-blue-500 underline hover:text-blue-700"
                                {...props}
                              />
                            ),
                          }}
                        >
                          {msgs?.message
                            ?.replace(/\\n+/g, '\n')
                            .replace(/## TABLE\n/, '')
                            .replace(/## CARDS\n?/g, '')
                            .replace(/## DRAFT\n?/g, '')
                            .trim()}
                        </ReactMarkdown>
                        {isDiffPresentDraft && (
                          <div className="flex justify-end">
                            <ButtonCmp
                              className="btn-outline-primary overflow-hidden flex-nowrap lg:px-[9px] px-2 max-sm:hidden whitespace-nowrap transition-all duration-[0.3s] "
                              iconSide="right"
                              icon={<Send03 className="w-5 h-5" />}
                              onClick={() => {}}
                            >
                              <span className="text-sm">Bulk Send</span>
                            </ButtonCmp>
                          </div>
                        )}
                      </>
                    )}
                    {!aiError && messageListdata?.length - 1 === index && (
                      <div className="flex items-center justify-between mt-5">
                        <div className="flex gap-x-1">
                          <div className="w-8 h-8 bg-transparent hover:bg-primary50 text-utilityBlack hover:text-primary flex items-center justify-center rounded-full cursor-pointer">
                            <ThumbsUp className="w-4 h-4 icon-filled" />
                          </div>
                          <div className="w-8 h-8 bg-transparent hover:bg-primary50 text-utilityBlack hover:text-primary flex items-center justify-center rounded-full cursor-pointer">
                            <ThumbsDown className="w-4 h-4" />
                          </div>
                          <div className="w-8 h-8 bg-transparent hover:bg-primary50 text-utilityBlack hover:text-primary flex items-center justify-center rounded-full cursor-pointer">
                            <RefreshCw01
                              className="w-4 h-4"
                              onClick={() => {
                                onSendMessage(
                                  messageListdata[index - 1]?.user_row
                                    ? messageListdata[index - 1]?.user_row
                                        ?.message
                                    : messageListdata[index - 1]?.message,
                                  true,
                                  messageListdata[index - 1]?.user_row
                                    ? messageListdata[index - 1]?.user_row?.id
                                    : messageListdata[index - 1]?.id,
                                  messageListdata[index]?.assistant_row
                                    ? messageListdata[index]?.assistant_row?.id
                                    : messageListdata[index]?.id,
                                  true
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <p className="text-xs font-medium text-grayLight600">
                            Load More
                          </p>
                          <RefreshCw05 className="w-4 h-4 text-utilityBlack cursor-pointer" />
                        </div>
                      </div>
                    )}
                    {aiError && messageListdata?.length - 1 === index && (
                      <div>
                        <div className="flex gap-4 bg-utilityError50 p-4 rounded-lg border border-utilityError300">
                          <div className="w-5 h-5 relative rounded-full">
                            <div className="w-7 h-7 left-[-4px] top-[-4px] absolute opacity-30 rounded-3xl border-2 border-fgErrorPrimary" />
                            <div className="w-[38px] h-[38px] left-[-9px] top-[-9px] absolute opacity-10 rounded-3xl border-2 border-fgErrorPrimary" />
                            <AlertCircle className="w-5 h-5 text-fgErrorPrimary" />
                          </div>
                          <p className="text-grayLight600 text-sm">
                            {
                              'Something went wrong. Please try again. If the issue continues, visit our Help Center for assistance.'
                            }
                          </p>
                        </div>
                        <div className="flex items-center gap-2 flex-col mt-4">
                          <p className="text-xs text-black font-medium">
                            There was an error generating a response
                          </p>
                          <ButtonCmp
                            className="btn-outline-primary overflow-hidden flex-nowrap lg:px-[9px] px-2 max-sm:hidden whitespace-nowrap transition-all duration-[0.3s] border-senary300 hover:border-senary300"
                            iconSide="left"
                            icon={
                              <RefreshCw01 className="w-5 h-5 text-grayLight600" />
                            }
                            onClick={() => {
                              if (
                                messageListdata[index - 1]?.user_row?.id ||
                                messageListdata[index - 1]?.id
                              ) {
                                onSendMessage(
                                  messageListdata[index - 1]?.user_row
                                    ? messageListdata[index - 1]?.user_row
                                        ?.message
                                    : messageListdata[index - 1]?.message,
                                  true,
                                  messageListdata[index - 1]?.user_row
                                    ? messageListdata[index - 1]?.user_row?.id
                                    : messageListdata[index - 1]?.id,
                                  messageListdata[index]?.assistant_row
                                    ? messageListdata[index]?.assistant_row?.id
                                    : messageListdata[index]?.id,
                                  true
                                );
                              } else {
                                onSendMessage(
                                  messageListdata[index - 1]?.user_row
                                    ? messageListdata[index - 1]?.user_row
                                        ?.message
                                    : messageListdata[index - 1]?.message
                                );
                              }
                            }}
                          >
                            <span className="text-sm text-textSecondary font-semibold">
                              Regenerate
                            </span>
                          </ButtonCmp>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
      {!trmporaryHideDivs && (
        <div className="mb-5 text-base font-normal">
          {/* Q1 */}
          <div className="flex flex-col">
            {/* Question */}
            <div className="flex mt-10">
              <figure className="w-10 h-10 basis-10 grow-0 shrink-0  mr-3">
                <img
                  className="object-cover w-8 h-8 overflow-hidden rounded-md"
                  src="https://storage.googleapis.com/western-alliance-gcp/bf14fb4f-7be0-4e3e-86f6-cd65b476e29f.png"
                  alt=""
                />
              </figure>
              <div className="flex gap-2 group/item">
                <div className="text-grayLight900 pt-1.5 flex-auto font-medium">
                  {/* {msgs.question} */}
                  how can i track my order
                </div>
                <span className="p-2 bg-gray100 flex justify-center items-center rounded-full transition-all invisible cursor-pointer text-grayLight900 group-hover/item:visible">
                  <Edit02 className="w-[14px] h-[14px]" />
                </span>
              </div>
            </div>

            {/* Answer on the right */}
            <div className=" flex mt-10">
              {/* Answer loader */}
              <div className="flex w-10 h-10 basis-10 grow-0 shrink-0 overflow-hidden rounded-md mr-3">
                {/* {msgs.answer ||
              (loading && index === messageListdata.length - 1 ? (
                <div className="relative h-3 w-3 mr-3">
                  <Loader spinnerClass={'h-4 w-4'} />
                </div>
              ) : (
                ''
              ))} */}

                <img src={AiStarIcon} alt="" className="w-[30px]" />
              </div>
              {/* Answer body */}
              <div className="flex flex-col">
                <p className="text-grayLight900 pt-1.5 mb-5">
                  To track your order, you will typically need a tracking number
                  or order ID. This information is usually sent to you via email
                  or SMS after your order is placed.
                </p>
                <div className="">
                  <p className="font-medium mb-5">
                    Here are the general steps to track your order:
                  </p>
                  <ol className="list-decimal pl-5 mb-1">
                    <li className="mb-4">
                      <strong className="font-medium">
                        Find your tracking number or order ID:
                      </strong>{' '}
                      Check your order confirmation email or SMS for this
                      information.
                    </li>
                    <li className="mb-4">
                      <strong className="font-medium">
                        Visit the retailer&apos;s website:
                      </strong>{' '}
                      Go to the website of the retailer where you made your
                      purchase.
                    </li>
                    <li className="mb-4">
                      <strong className="font-medium">
                        Enter your tracking number or order ID:
                      </strong>{' '}
                      Input the information you found in step 1.
                    </li>
                    <li className="mb-4">
                      <strong className="font-medium">
                        Click &quot;Track&quot; or &quot;Submit&quot;:
                      </strong>{' '}
                      This will initiate the tracking process.
                    </li>
                  </ol>
                  <p className="font-medium mb-5">
                    If you&apos;re unable to track your order on the
                    retailer&apos;s website, you can try these additional
                    methods:
                  </p>
                  <ul className="list-disc pl-5">
                    <li className="mb-4">
                      <strong className="font-medium">
                        Contact the retailer&apos;s customer service:
                      </strong>{' '}
                      Reach out to their customer support team via phone, email,
                      or live chat. They can provide you with more specific
                      information about your order&apos;s status.
                    </li>
                    <li className="mb-4">
                      <strong className="font-medium">
                        Check the shipping carrier&apos;s website:
                      </strong>{' '}
                      If you know the shipping carrier (e.g., FedEx, UPS, USPS,
                      etc.), you can directly track your order on their website
                      using your tracking number.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Q2 */}
          <div className="flex flex-col">
            {/* Question */}
            <div className="flex mt-10">
              <figure className="w-10 h-10 basis-10 grow-0 shrink-0  mr-3">
                <img
                  className="object-cover w-8 h-8 overflow-hidden rounded-md"
                  src="https://storage.googleapis.com/western-alliance-gcp/bf14fb4f-7be0-4e3e-86f6-cd65b476e29f.png"
                  alt=""
                />
              </figure>
              <div className="flex gap-2 group/item">
                <div className="text-grayLight900 pt-1.5 flex-auto font-medium">
                  {/* {msgs.question} */}
                  Display my customers with their details
                </div>
                <span className="p-2 bg-gray100 flex justify-center items-center rounded-full transition-all invisible cursor-pointer text-grayLight900 group-hover/item:visible">
                  <Edit02 className="w-[14px] h-[14px]" />
                </span>
              </div>
            </div>

            {/* Answer on the right */}
            <div className=" flex mt-10">
              {/* Answer loader */}
              <div className="flex w-10 h-10 basis-10 grow-0 shrink-0 overflow-hidden rounded-md mr-3">
                {/* {msgs.answer ||
              (loading && index === messageListdata.length - 1 ? (
                <div className="relative h-3 w-3 mr-3">
                  <Loader spinnerClass={'h-4 w-4'} />
                </div>
              ) : (
                ''
              ))} */}
                {/* <Star06 width={32} height={32} /> */}
                <img src={AiStarIcon} alt="" className="w-[30px]" />
              </div>
              {/* Answer body */}
              <div className="flex flex-col w-full">
                <p className="text-grayLight900 pt-1.5 mb-5">
                  Here is a list of customers with their orders, and assigned
                  sales representatives
                </p>
                {/* Ans List */}
                <div className="flex flex-col gap-4">
                  <div className="border border-gray100 p-3 rounded-lg flex items-center text-xs group/item cursor-pointer">
                    <div className="grid grid-cols-4 flex-auto">
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Customer Name
                        </div>
                        <div className="flex">
                          <figure className="w-4 h-4 basis-4 grow-0 shrink-0 rounded-full overflow-hidden mr-1">
                            <img
                              src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/66eeb0303d20e5d8136f89f6_Acme%2520Corp.svg"
                              alt=""
                              className="w-full h-full"
                            />
                          </figure>
                          <span className="text-grayLight900 font-medium">
                            Envirotex
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Sales Representative
                        </div>
                        <div className="flex">
                          <figure className="w-4 h-4 basis-4 grow-0 shrink-0 rounded-full overflow-hidden mr-1">
                            <img
                              src="https://storage.googleapis.com/western-alliance-gcp/e59af79d-c050-48ed-bed4-18b334435250.png"
                              alt=""
                              className="w-full h-full"
                            />
                          </figure>
                          <span className="text-grayLight900 font-medium">
                            Kate Morrison
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Total Orders
                        </div>
                        <div className="text-grayLight900 font-medium">
                          7568
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Total Margin
                        </div>
                        <div className="text-grayLight900 font-medium">
                          $22,250.18
                        </div>
                      </div>
                    </div>
                    <ArrowRight
                      width={24}
                      className="text-primary invisible transition-all  group-hover/item:visible"
                    />
                  </div>
                  <div className="border border-gray100 p-3 rounded-lg flex items-center text-xs group/item cursor-pointer">
                    <div className="grid grid-cols-4 flex-auto">
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Customer Name
                        </div>
                        <div className="flex">
                          <figure className="w-4 h-4 basis-4 grow-0 shrink-0 rounded-full overflow-hidden mr-1">
                            <img
                              src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/66eeb0303d20e5d8136f89f6_Acme%2520Corp.svg"
                              alt=""
                              className="w-full h-full"
                            />
                          </figure>
                          <span className="text-grayLight900 font-medium">
                            Envirotex
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Sales Representative
                        </div>
                        <div className="flex">
                          <figure className="w-4 h-4 basis-4 grow-0 shrink-0 rounded-full overflow-hidden mr-1">
                            <img
                              src="https://storage.googleapis.com/western-alliance-gcp/e59af79d-c050-48ed-bed4-18b334435250.png"
                              alt=""
                              className="w-full h-full"
                            />
                          </figure>
                          <span className="text-grayLight900 font-medium">
                            Kate Morrison
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Total Orders
                        </div>
                        <div className="text-grayLight900 font-medium">
                          7568
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Total Margin
                        </div>
                        <div className="text-grayLight900 font-medium">
                          $22,250.18
                        </div>
                      </div>
                    </div>
                    <ArrowRight
                      width={24}
                      className="text-primary invisible transition-all  group-hover/item:visible"
                    />
                  </div>
                  <div className="border border-gray100 p-3 rounded-lg flex items-center text-xs group/item cursor-pointer">
                    <div className="grid grid-cols-4 flex-auto">
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Customer Name
                        </div>
                        <div className="flex">
                          <figure className="w-4 h-4 basis-4 grow-0 shrink-0 rounded-full overflow-hidden mr-1">
                            <img
                              src="https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/66eeb0303d20e5d8136f89f6_Acme%2520Corp.svg"
                              alt=""
                              className="w-full h-full"
                            />
                          </figure>
                          <span className="text-grayLight900 font-medium">
                            Envirotex
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Sales Representative
                        </div>
                        <div className="flex">
                          <figure className="w-4 h-4 basis-4 grow-0 shrink-0 rounded-full overflow-hidden mr-1">
                            <img
                              src="https://storage.googleapis.com/western-alliance-gcp/e59af79d-c050-48ed-bed4-18b334435250.png"
                              alt=""
                              className="w-full h-full"
                            />
                          </figure>
                          <span className="text-grayLight900 font-medium">
                            Kate Morrison
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Total Orders
                        </div>
                        <div className="text-grayLight900 font-medium">
                          7568
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-grayLight600 leading-[18px]">
                          Total Margin
                        </div>
                        <div className="text-grayLight900 font-medium">
                          $22,250.18
                        </div>
                      </div>
                    </div>
                    <ArrowRight
                      width={24}
                      className="text-primary invisible transition-all  group-hover/item:visible"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-5">
              <RefreshCw01 className="w-4 h-4 text-grayLight600 cursor-pointer" />
              <p className="text-xs font-normal text-grayLight600 ml-2">
                Load More
              </p>
            </div>
          </div>
          {/* Q3 */}
          <div className="flex flex-col">
            {/* Question */}
            <div className="flex mt-10">
              <figure className="w-10 h-10 basis-10 grow-0 shrink-0  mr-3">
                <img
                  className="object-cover w-8 h-8 overflow-hidden rounded-md"
                  src="https://storage.googleapis.com/western-alliance-gcp/bf14fb4f-7be0-4e3e-86f6-cd65b476e29f.png"
                  alt=""
                />
              </figure>
              <div className="flex gap-2 group/item">
                <div className="text-grayLight900 pt-1.5 flex-auto font-medium">
                  {/* {msgs.question} */}
                  Please check in with my customers with i haven&apos;t moved a
                  load for more than 30 days
                </div>
                <span className="p-2 bg-gray100 flex justify-center items-center rounded-full transition-all invisible cursor-pointer text-grayLight900 group-hover/item:visible">
                  <Edit02 className="w-[14px] h-[14px]" />
                </span>
              </div>
            </div>

            {/* Answer on the right */}
            <div className=" flex mt-10">
              {/* Answer loader */}
              <div className="flex w-10 h-10 basis-10 grow-0 shrink-0 overflow-hidden rounded-md mr-3">
                {/* {msgs.answer ||
              (loading && index === messageListdata.length - 1 ? (
                <div className="relative h-3 w-3 mr-3">
                  <Loader spinnerClass={'h-4 w-4'} />
                </div>
              ) : (
                ''
              ))} */}
                {/* <Star06 width={32} height={32} /> */}
                <img src={AiStarIcon} alt="" className="w-[30px]" />
              </div>
              {/* Answer body */}
              <div className="flex flex-col w-full">
                <p className="text-grayLight900 pt-1.5 mb-5">
                  Here&apos;s a list of customer names along with some details
                  about why no loads have been moved for more than 30 days:
                </p>
                {/* Ans List */}
                <div className="flex flex-col gap-4 text-grayLight900 ">
                  <div className="border border-gray100 p-3 rounded-xl  text-sm">
                    <h2 className="flex items-center font-semibold text-base gap-1">
                      Greenfield Distributors{' '}
                      <LinkExternal02
                        width={18}
                        height={18}
                        className="text-primary cursor-pointer"
                      />
                    </h2>
                    <p className="mt-2">
                      <strong className="font-medium">Reason:</strong> Delays
                      due to a slowdown in production. They&apos;ve had fewer
                      orders and are currently facing challenges with supply
                      chain disruptions.
                    </p>
                  </div>
                  <div className="border border-gray100 p-3 rounded-xl  text-sm">
                    <h2 className="flex items-center font-semibold text-base gap-1">
                      QuickMove Logistics
                      <LinkExternal02
                        width={18}
                        height={18}
                        className="text-primary cursor-pointer"
                      />
                    </h2>
                    <p className="mt-2">
                      <strong className="font-medium">Reason:</strong>{' '}
                      Unforeseen truck maintenance issues and difficulty
                      securing drivers due to industry-wide labor shortages.
                    </p>
                  </div>
                  <div className="border border-gray100 p-3 rounded-xl text-sm">
                    <h2 className="flex items-center font-semibold text-base gap-1">
                      Riverstone Packaging Inc.
                      <LinkExternal02
                        width={18}
                        height={18}
                        className="text-primary cursor-pointer"
                      />
                    </h2>
                    <p className="mt-2">
                      <strong className="font-medium">Reason:</strong> Awaiting
                      a restock of raw materials. Their production schedule has
                      been delayed, meaning they have not been shipping as much.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-5">
              <RefreshCw01 className="w-4 h-4 text-grayLight600 cursor-pointer" />
              <p className="text-xs font-normal text-grayLight600 ml-2">
                Load More
              </p>
            </div>
          </div>
          {/* Q3 */}
          <div className="flex flex-col">
            {/* Question */}
            <div className="flex mt-10">
              <figure className="w-10 h-10 basis-10 grow-0 shrink-0  mr-3">
                <img
                  className="object-cover w-8 h-8 overflow-hidden rounded-md"
                  src="https://storage.googleapis.com/western-alliance-gcp/bf14fb4f-7be0-4e3e-86f6-cd65b476e29f.png"
                  alt=""
                />
              </figure>
              <div className="flex gap-2 group/item">
                <div className="text-grayLight900 pt-1.5 flex-auto font-medium">
                  {/* {msgs.question} */}
                  Make draft email for green field distributers to request them
                  to move their loads.
                </div>
                <span className="p-2 bg-gray100 flex justify-center items-center rounded-full transition-all invisible cursor-pointer text-grayLight900 group-hover/item:visible">
                  <Edit02 className="w-[14px] h-[14px]" />
                </span>
              </div>
            </div>

            {/* Answer on the right */}
            <div className=" flex mt-10">
              {/* Answer loader */}
              <div className="flex w-10 h-10 basis-10 grow-0 shrink-0 overflow-hidden rounded-md mr-3">
                {/* {msgs.answer ||
              (loading && index === messageListdata.length - 1 ? (
                <div className="relative h-3 w-3 mr-3">
                  <Loader spinnerClass={'h-4 w-4'} />
                </div>
              ) : (
                ''
              ))} */}
                {/* <Star06 width={32} height={32} /> */}
                <img src={AiStarIcon} alt="" className="w-[30px]" />
              </div>
              {/* Answer body */}
              <div className="flex flex-col w-full">
                <p className="text-grayLight900 pt-1.5 mb-5 font-medium">
                  Here&apos;s a polished draft email for your request:
                </p>
                {/* Ans List */}
                <div className="flex flex-col gap-4 text-grayLight900 text-sm">
                  <div className="mb-4">
                    <p className="mb-2">
                      <strong className="font-medium">Subject:</strong> Request
                      to Move Loads with Green Field Distributors.
                    </p>
                    <p className="">Hello [first name],</p>
                  </div>
                  <div className="flex flex-col gap-3 mb-4">
                    <p className="">I hope this message finds you well.</p>
                    <p className="">
                      My name is [Your Name], and I am reaching out from [Your
                      Company Name]. We greatly admire the efficiency and
                      reliability of Green Field Distributors and believe in the
                      value you bring to the supply chain industry.
                    </p>
                    <p className="">
                      We would like to explore the opportunity to collaborate
                      with your team to facilitate the movement of our loads.
                      [Briefly mention the type of loads, such as &quot;bulk
                      goods,&quot; &quot;perishables,&quot; etc.] We are
                      confident that your expertise in logistics and
                      distribution would ensure a seamless and timely transfer
                      of these shipments.
                    </p>
                    <p className="">
                      Could you please let us know the next steps to proceed? We
                      would also appreciate if you could share any specific
                      requirements or terms for partnering on this endeavor.
                    </p>
                    <p className="">
                      Thank you for considering this request. We look forward to
                      your response and the possibility of working together.
                    </p>
                  </div>
                  <div className="">
                    <p className="mb-3">Sincerely,</p>
                    <p className="">The [Your Company Name] Team</p>
                  </div>
                  <div className="flex justify-end">
                    <ButtonCmp
                      className="btn-outline-primary overflow-hidden flex-nowrap lg:px-[9px] px-2 max-sm:hidden whitespace-nowrap transition-all duration-[0.3s] "
                      iconSide="right"
                      icon={<Send03 className="w-5 h-5" />}
                      onClick={() => {}}
                    >
                      <span className="text-sm">Bulk Send</span>
                    </ButtonCmp>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-5">
              <div className="flex gap-x-1">
                <div className="w-8 h-8 bg-transparent hover:bg-primary50 text-utilityBlack hover:text-primary flex items-center justify-center rounded-full cursor-pointer">
                  <ThumbsUp className="w-4 h-4 icon-filled" />
                </div>
                <div className="w-8 h-8 bg-transparent hover:bg-primary50 text-utilityBlack hover:text-primary flex items-center justify-center rounded-full cursor-pointer">
                  <ThumbsDown className="w-4 h-4" />
                </div>
                <div className="w-8 h-8 bg-transparent hover:bg-primary50 text-utilityBlack hover:text-primary flex items-center justify-center rounded-full cursor-pointer">
                  <RefreshCw01 className="w-4 h-4" />
                </div>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-xs font-medium text-grayLight600">
                  Load More
                </p>
                <RefreshCw05 className="w-4 h-4 text-utilityBlack cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageListDataShowCmp;
