import { yupResolver } from '@hookform/resolvers/yup';
import { CurrencyDollar, FilePlus02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { updateClaimStatus } from 'src/services/ClaimService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import { handleInputChange } from '../ClaimsDisputes.const';

const claimExplanationSchema = yup.object().shape({
  claimExplanation: yup
    .string()
    .trim()
    .required('Description is required.')
    .min(50, 'Claim description must be at least 50 characters.')
    .max(250, 'Claim description should be no longer than 250 characters.'),
  claimAmount: yup
    .number()
    .nullable() // Allows the field to be null when not needed
    .typeError('Claim amount must be a valid number')
    .when([], {
      is: (isClaimAmountUpdatable: boolean) => isClaimAmountUpdatable,
      then: (schema) => schema.required('Claim Amount is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
});

interface IProps {
  handleClose: any;
  claim: any;
  title: any;
  isEdit: any;
  getClaim?: any;
  setShowClaimDisputeNoSalesRepWarning?: any;
  isClaimAmountUpdatable?: boolean;
}

const EditClaimExplanation = ({
  handleClose,
  claim,
  title,
  isEdit,
  getClaim,
  setShowClaimDisputeNoSalesRepWarning,
  isClaimAmountUpdatable,
}: IProps) => {
  const navigate = useNavigate();
  const { reloadCounts } = useContext(EntityCountsContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(claimExplanationSchema),
    defaultValues: {
      claimExplanation: '',
      claimAmount: claim?.claimAmount,
    },
    context: { isClaimAmountUpdatable },
  });

  const claimDescription = watch('claimExplanation');

  useEffect(() => {
    if (claim && claim.claimExplanation) {
      setValue('claimExplanation', claim.claimExplanation);
    }
  }, []);

  const onSubmit = (formData: any) => {
    setIsLoading(true);

    const appendFormData: any = new FormData();

    appendFormData.append('status', 'Opened');
    appendFormData.append('claimExplanation', formData?.claimExplanation);

    if (claim && claim?.claimID) {
      appendFormData.append('claimID', claim?.claimID);
    }

    if (isClaimAmountUpdatable) {
      appendFormData.append('claimAmount', formData?.claimAmount);
    }

    updateClaimStatus(appendFormData)
      .then(() => {
        handleClose();

        if (!isEdit) {
          navigate(`${PATH.CLAIM_DETAILS}/${claim?.id}`);
          reloadCounts();
        } else {
          getClaim();
        }
      })
      .catch((e: any) => {
        if (e?.response?.data?.isShowNoSalesRepWarning) {
          if (setShowClaimDisputeNoSalesRepWarning) {
            setShowClaimDisputeNoSalesRepWarning(claim);
          }
          handleClose();
        } else {
          WalToast.error(
            e?.response?.data?.message ?? 'Claim description not added'
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const headerIcon = <FilePlus02 className="w-7 h-7" />;

  const memorizedBadge = useMemo(
    () =>
      claimDescription &&
      claimDescription?.trim()?.length > 0 && (
        <div className="text-grayLight900 text-xs">
          {claimDescription.trim().length}
          &nbsp;/&nbsp;250
        </div>
      ),
    [claimDescription]
  );

  return (
    <CommonModal
      title={title}
      titleDesc={`${
        isEdit
          ? 'View and manage your claim details'
          : 'Open your claim by adding an amount in an description below.'
      }`}
      handleClose={handleClose}
      size={'max-w-[540px]'}
      headerIcon={headerIcon}
      primaryBtnText={`${isEdit ? 'Save' : 'Open Claim'}`}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        {isClaimAmountUpdatable && (
          <Controller
            name="claimAmount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputType="number"
                inputName="claimAmount"
                label="Claim Amount"
                parentClassName="mb-4"
                className="pl-8"
                placeholder="Enter Claim Amount"
                value={`${value}`}
                onChangeFunc={(e) => handleInputChange(e, 'change', onChange)}
                onBlur={(e) => handleInputChange(e, 'blur', onChange)}
                labelClassName="block mb-1.5"
                errorText={errors?.claimAmount?.message ?? ''}
                shouldFormatNumber={true}
                icon={
                  <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                    <CurrencyDollar className="w-4 h-4" />
                  </div>
                }
              />
            )}
          />
        )}
        <Controller
          name="claimExplanation"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="claimExplanation"
              label={
                <>
                  Claim Description <span>(min. 50 characters)</span>
                </>
              }
              placeholder="Enter Claim Description"
              value={value}
              onChangeFunc={onChange}
              labelClassName="block mb-1.5"
              className="h-32"
              errorText={errors?.claimExplanation?.message ?? ''}
              asTextarea="textarea"
              labelIconRight={memorizedBadge}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default EditClaimExplanation;
