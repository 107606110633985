import { Mail02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DynamicCKEditor from 'src/components/DynamicCKEditor';
import DynamicCreatableSelect from 'src/components/DynamicCreatableSelect';
// import EmailReplyCmp from 'src/components/EmailReplyCmp';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import { AuthContext } from 'src/context/AuthContext';
import { replyEmail } from 'src/services/QuoteEmailService';
import WalToast from 'src/utils/WalToast';

const ReplyModal = ({
  emailDetails,
  handleClose,
  draftEmail,
  draftEmailLoading,
}: any) => {
  const { currentUser } = useContext(AuthContext);
  const defaultTo = {
    label: emailDetails?.sender?.emailAddress?.address,
    value: emailDetails?.sender?.emailAddress?.address,
  };

  const getInitialContent = () => {
    const emptyLines = '<p>&nbsp;</p>'.repeat(4);
    const dividerLine = '<p>--------------</p>';
    const draftContent = draftEmail ? draftEmail.replace(/\n/g, '<br>') : '';
    const originalContent = emailDetails?.body?.content || '';

    return `${draftContent}${emptyLines}${dividerLine}${originalContent}`;
  };

  const [selectedOptions, setSelectedOptions] = useState([defaultTo]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedMailType, setSelectedMailType] = useState();
  const [isEmailBodyInitialized, setIsEmailBodyInitialized] = useState(false);
  const initFormData = {
    messageId: emailDetails?.id,
    from: currentUser?.email,
    to: [],
    emailType: '',
    emailBody: getInitialContent(),
  };
  const initValidForm = {
    to: true,
    emailBody: true,
  };
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);

  const tabOptionsArr = [
    {
      value: 'CC',
      name: 'CC',
    },
    {
      value: 'BCC',
      name: 'BCC',
    },
  ];

  useEffect(() => {
    if (!draftEmailLoading && !isEmailBodyInitialized && draftEmail) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        emailBody: getInitialContent(),
      }));
      setIsEmailBodyInitialized(true);
    }
  }, [draftEmailLoading, draftEmail]);

  const handleSelectChange = (newValue: any) => {
    setSelectedOptions(newValue);
  };

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const handleEditorChange = (data: any) => {
    setFormData({ ...formData, emailBody: data });
  };

  const handleKeyDown = (event: any) => {
    if (inputValue && (event.key === 'Enter' || event.key === 'Tab')) {
      if (isValidEmail(inputValue)) {
        const newOption = { label: inputValue, value: inputValue };
        setSelectedOptions((prev: any) => [...prev, newOption] as any);
        setInputValue('');
      } else {
        setInputValue('');
      }
      event.preventDefault();
    }
  };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      to: true,
      emailBody: true,
    };

    if (formData.emailBody?.trim() === '') {
      validFormData.emailBody = false;
      valid = false;
    }

    if (selectedOptions?.length <= 0) {
      validFormData.to = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = (event: any) => {
    event?.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    setIsLoading(true);
    const payload = formData;
    payload.to = selectedOptions?.map((v: any) => v.value)?.join(', ');
    replyEmail(payload)
      .then(() => {
        setIsLoading(false);
        WalToast.success('Reply has been sent successfully');
      })
      .finally(() => {
        setIsLoading(false);
        handleClose(false);
      })
      .catch(() => {
        setIsLoading(false);
        WalToast.error('Reply has been not sent, Something went wrong');
      });
  };

  return (
    <div>
      <CommonModal
        title="Reply"
        titleDesc={
          <>
            <span className="font-bold mr-1">Subject:</span>
            {emailDetails?.subject}
          </>
        }
        headerIcon={<Mail02 />}
        size="max-w-[823px] h-[574px]"
        handleClose={handleClose}
        primaryBtnOnClick={handleSubmit}
        primaryBtnText="Reply"
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={isLoading}
      >
        {/* <EmailReplyCmp /> */}
        <form
          className="h-full px-5 pb-5 overflow-y-auto flex flex-col custom-scrollbar-v2"
          onSubmit={handleSubmit}
        >
          <div className="w-full border-b border-utilityGray200 pt-5 sticky top-0 z-[2] bg-white">
            <div className="mb-3 md:flex">
              <label className="form_label md:mb-0 form_label_center w-[80px]">
                From:
              </label>
              <div className="flex-1">
                <InputText
                  inputName="from"
                  placeholder="Enter From address"
                  parentClassName="flex-1"
                  value={currentUser?.email}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-3 md:flex">
              <label className="form_label md:mb-0 form_label_center w-[80px]">
                To:
              </label>
              <div className="flex-1 relative">
                <DynamicCreatableSelect
                  placeholder="Enter To address"
                  isClearable={true}
                  isMulti={true}
                  menuIsOpen={false}
                  onChange={handleSelectChange}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  value={selectedOptions}
                  inputValue={inputValue}
                  errorText={
                    !validForm.to && selectedOptions?.length <= 0
                      ? 'sender (TO) is required'
                      : null
                  }
                />
                <TabButton
                  tabParentClassName="absolute right-1.5 top-1.5 "
                  parentClassName={' '}
                  className="!py-1 !min-h-[unset]"
                  activeClassName="bg-gray50"
                  tabArray={tabOptionsArr}
                  handleOnClick={(e: any) =>
                    setSelectedMailType(e.target.dataset.value)
                  }
                  isActive={selectedMailType}
                />
              </div>
            </div>
          </div>
          <div
            className={`flex-1 reply-modal-ck-editor ${
              draftEmailLoading ? 'custom-loading' : ''
            }`}
          >
            <div className="pt-4 h-full">
              {!draftEmailLoading && (
                <DynamicCKEditor
                  data={formData?.emailBody}
                  onChange={handleEditorChange}
                  errorText={
                    !validForm.emailBody && formData?.emailBody?.trim() === ''
                      ? 'email body is required'
                      : null
                  }
                />
              )}
              {/* )} */}
            </div>
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default ReplyModal;
