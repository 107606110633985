import {
  ArrowLeft,
  ArrowRight,
  CurrencyDollar,
  InfoCircle,
  Percent01,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPredictivePrice } from 'src/services/QuoteService';
import {
  getFormattedNumber,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import { predictivePriceOptions } from './PredictivePrice.interface';

const chartFilterTab: any = [
  {
    value: 'weekly',
    name: 'Weekly',
    tooltipValue: '',
  },
  {
    value: 'monthly',
    name: 'Monthly',
    tooltipValue: '',
  },
];

const initialSeries = [
  {
    name: 'Max',
    data: [
      1725, 1600, 1750, 1700, 1650, 1400, 1300, 1300, 1200, 1250, 1200, 1100,
      1150, 1250, 1350,
    ],
  },
];

const PredictivePriceChart = ({ id, openAddQuickModal }: any) => {
  const { hasRoleV2 } = useRolePermission();
  const [isActiveTab, setIsActiveTab] = useState<string>('weekly');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [isNextYearDisable, setIsNextYearDisable] = useState<any>(false);
  const [totalCost, setTotalCost] = useState<any>('');
  const [marginPercentage, setMarginPercentage] = useState<any>('');
  const [totalSalePrice, setTotalSalePrice] = useState<any>('');
  const abortControllerRef = useRef<AbortController | null>(null);

  const generateQuarterSelections = () => {
    const startYear = 2000;
    const currentYear = new Date().getFullYear(); // This will dynamically get the current year
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const selections = [];

    for (let year = startYear; year <= currentYear; year++) {
      const maxQuarter = year === currentYear ? currentQuarter : 4;

      for (let quarter = 1; quarter <= maxQuarter; quarter++) {
        selections.push(`Q${quarter}-${year}`);
      }
    }

    return selections;
  };

  const quarterArr = generateQuarterSelections();
  const [dateRange, setDateRange] = useState<any>(
    moment().format(quarterArr[0])
  );
  const [chartOptions, setChartOptions] = useState<any>(predictivePriceOptions);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getChartData = () => {
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const filter = {
      activeTab: isActiveTab,
      range: dateRange,
    };

    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.5,
          stops: [0, 50, 90, 100],
        },
      },
    }));

    setIsLoading(true);
    getPredictivePrice(id, filter, signal)
      .then((response: any) => {
        if (response && response.data) {
          const { predictivePricingData } = response.data;
          setPredictivePriceData(predictivePricingData ?? []);
          setSeries(predictivePricingData?.graphData?.series ?? []);
          setMarginPercentage(predictivePricingData.margin);
          setTotalCost(predictivePricingData.total);

          setTimeout(() => {
            setChartOptions((prevOptions: any) => ({
              ...prevOptions,
              fill: {
                type: 'solid', // Use solid fill
                colors: ['transparent'], // Transparent background
              },
              xaxis: {
                ...prevOptions.xaxis,
                categories:
                  isActiveTab === 'weekly'
                    ? response.data.xValues.map(
                        (x: string) => x.split(' - ')[0]
                      )
                    : response.data.xValues,
              },
            }));

            setIsLoading(false);
          }, 1000);
        } else {
          setIsLoading(false);
        }
      })
      .catch((e: any) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const margin = (totalCost * marginPercentage) / 100;
    const salePrice = parseFloat(totalCost) + margin;

    setTotalSalePrice(salePrice);
  }, [totalCost, marginPercentage]);

  useEffect(() => {
    setIsPrevYearDisable(false);
    setIsPrevYearDisable(false);

    if (isActiveTab === 'weekly') {
      setDateRange(quarterArr[quarterArr.length - 1]);
      const valKey: any = _.findKey(
        quarterArr,
        (val) => val === quarterArr[quarterArr.length - 1]
      );
      if (!quarterArr[valKey - 1]) setIsPrevYearDisable(true);
      if (!quarterArr[valKey + 1]) setIsNextYearDisable(true);
    }

    if (isActiveTab === 'monthly') {
      setDateRange(moment().format('YYYY'));
      const nextMonth = moment(dateRange).add(1, 'year').format('YYYY');
      if (nextMonth === moment().format('YYYY')) setIsNextYearDisable(true);
    }
  }, [isActiveTab]);

  const getFilterRange = (type: string) => {
    if (isActiveTab === 'weekly') {
      if (type === 'prev' && !isPrevYearDisable) {
        const valKey: any = _.findKey(quarterArr, (val) => val === dateRange);
        const newKey = parseInt(valKey, 10) - 1;
        const newDateRange = quarterArr[newKey];

        if (newDateRange) {
          setDateRange(newDateRange);

          if (hasRoleV2('user')) {
            const [q, year] = newDateRange.split('-');
            const quarterNumber = parseInt(q.replace('Q', ''), 10);
            const yearNumber = parseInt(year, 10);
            const currentYear = new Date().getFullYear();
            const boundaryYear = currentYear - 1;
            const boundaryQuarter = 1;

            if (
              yearNumber < boundaryYear ||
              (yearNumber === boundaryYear && quarterNumber <= boundaryQuarter)
            ) {
              setIsPrevYearDisable(true);
            }
          } else {
            setIsPrevYearDisable(!quarterArr[newKey - 1]);
          }
        } else {
          console.error('New date range not found.');
        }
        setIsNextYearDisable(false);
      }

      if (type === 'next' && !isNextYearDisable) {
        setIsPrevYearDisable(false);
        const valKey: any = _.findKey(quarterArr, (val) => val === dateRange);
        const valKeyNum = +valKey;
        setDateRange(quarterArr[valKeyNum + 1]);
        if (!quarterArr[valKeyNum + 2]) setIsNextYearDisable(true);
        setIsPrevYearDisable(false);
      }
    }

    if (isActiveTab === 'monthly') {
      const currentYear = moment().year();

      if (type === 'prev' && !isPrevYearDisable) {
        const prevMonth = moment(moment(dateRange, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');
        setDateRange(prevMonth);

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsNextYearDisable(false);
        }
      }

      if (type === 'next' && !isNextYearDisable) {
        setIsPrevYearDisable(false);
        const nextMonth = moment(moment(dateRange, 'YYYY'))
          .add(1, 'year')
          .format('YYYY');
        setDateRange(nextMonth);

        if (nextMonth === moment().format('YYYY')) {
          setIsNextYearDisable(true);
        }
      }
    }
  };

  useEffect(() => {
    getChartData();
  }, [dateRange]);

  return (
    <>
      <div id="chart" className="flex p-5">
        <div className="rounded-xl border border-utilityGray200 p-5 w-full flex-1 mr-5">
          <div className="flex sm:flex-row flex-col justify-between gap-2">
            <div className="min-w-[18%] px-3 py-2.5 bg-white rounded-lg shadow border border-gray-300 items-center gap-1 justify-between flex">
              <ArrowLeft
                className={`w-4 h-4  ${
                  isPrevYearDisable
                    ? 'text-grayText cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => getFilterRange('prev')}
              />
              <div className="px-0.5 justify-center items-center flex">
                <div className="text-center text-textSecondary text-xs font-medium leading-tight">
                  {dateRange}
                </div>
              </div>
              <ArrowRight
                className={`w-4 h-4 ${
                  isNextYearDisable
                    ? 'text-grayText cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => getFilterRange('next')}
              />
            </div>
            <div className="flex gap-x-5">
              <div className="flex gap-x-5">
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#A4BCFD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Minimum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#3538CD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Maximum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#6172F3]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Target
                  </div>
                </div>
              </div>

              <TabButton
                tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                parentClassName="w-full currency-tab quoting-currency"
                childrenClassName="test"
                activeClassName="text-grayLight900 bg-utilityGray100"
                className=""
                isTooltip={true}
                tabArray={chartFilterTab}
                handleOnClick={(e: any) => {
                  setIsActiveTab(e.target.dataset.value);
                }}
                isActive={isActiveTab}
              />
            </div>
          </div>
          <Chart
            options={chartOptions}
            series={isLoading ? initialSeries : series}
            type="area"
            height={250}
            className={`analytics_totalquotes_tooltip ${
              isLoading ? 'apex-line-chart custom-loading graph-loading' : ''
            }`}
          />
        </div>
        <div className="w-[496px] rounded-xl border border-utilityGray200 px-4 py-4 space-y-4">
          <div className="flex items-center gap-9 pb-4 border-b border-[#EAECF0]">
            <div className="w-2/5">
              <TooltipCmp
                message={`${predictivePriceData?.from}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-grayLight600 text-sm font-normal truncate cursor-default ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {/* <span className="text-grayLight600">From: </span> */}
                  {predictivePriceData?.from}
                </div>
              </TooltipCmp>
            </div>
            <div className="p-1 border rounded-md border-[#D0D5DD] shadow-lg">
              <ArrowRight className="w-[14px] h-[14px] text-[#101828]" />
            </div>
            <div className="w-2/5">
              <TooltipCmp
                message={`${predictivePriceData?.to}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-grayLight600 text-sm font-normal truncate cursor-default ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {/* <span className="text-grayLight600">To: </span> */}
                  {predictivePriceData?.to}
                </div>
              </TooltipCmp>
            </div>
          </div>
          <h6 className="text-grayLight600 text-sm font-normal">
            Predictive Cost
          </h6>
          <div className="flex gap-x-5 items-center pb-2">
            <h6
              className={`text-grayLight900 text-3xl font-semibold ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {predictivePriceData?.total
                ? getFormattedNumber(predictivePriceData?.total, true, true)
                : '$0'}
            </h6>
            <div className="flex-1">
              <div className="flex justify-between">
                <p className="text-grayLight600 text-sm font-normal mb-1">
                  Confidence
                </p>
                <span className="text-grayLight600 text-sm font-semibold mb-1">
                  {predictivePriceData?.confidenceLevel}%
                </span>
              </div>
              <div className="progress-range-container">
                <div
                  className="progress-range-bar !bg-[#17B26A]"
                  style={{
                    width: `${predictivePriceData?.confidenceLevel}%`,
                  }}
                >
                  <div className="progress-range-thumb progress-range-thumb-invisible left-[100%]">
                    <div className="progress-tooltip absolute bottom-full left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white tooltip-custom-arrow text-xs font-semibold mb-3.5">
                      {predictivePriceData?.confidenceLevel}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="flex-1">
              <InputText
                label={'Total Cost'}
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="totalCost"
                value={totalCost}
                className="pl-8"
                parentClassName="w-full"
                icon={<CurrencyDollar className="input-currency-sign" />}
                onChangeFunc={(e: any) => {
                  setTotalCost(e.target.value);
                }}
              />
            </div>
            <div className="flex-1">
              <InputText
                label={'Margin'}
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="marginPercentage"
                value={marginPercentage}
                className="pl-8"
                parentClassName="w-full"
                icon={<Percent01 className="input-currency-sign" />}
                onChangeFunc={(e: any) => {
                  setMarginPercentage(e.target.value);
                }}
              />
            </div>
            <div className="flex-1">
              <InputText
                label={'Total Sale Price'}
                labelClassName="block mb-1.5"
                inputType="text"
                inputName="totalSalePrice"
                value={getFormattedNumber(totalSalePrice)}
                className="pl-8"
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            </div>
          </div>
          <div className="rounded-[10px] border border-utilityBrand200 bg-primary50 p-3 text-xs text-grayLight600 font-normal flex items-center">
            <InfoCircle className="w-4 h-4 mr-1" />
            Your average margin for this customer is
            <span className="font-semibold ml-1">
              {' '}
              {predictivePriceData?.margin}%
            </span>
          </div>
          <ButtonCmp
            className="btn-outline-primary w-full"
            icon={<Plus className="w-4 h-4" />}
            onClick={openAddQuickModal}
          >
            Add Active Quote
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default PredictivePriceChart;
