import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const dimensionsOrderDetailRow = ({ data, handlingUnitOptions }: any) => {
  const handlingUnit = handlingUnitOptions?.find(
    (item: any) => item?.value === data?.handlingUnit
  );

  return (
    <>
      <td className="px-5 py-4">{data?.handlingUnitNo}</td>
      <td className="px-5 py-4">{handlingUnit?.label ?? '-'}</td>
      <td className="px-5 py-4">
        {data?.length} X {data?.width} X {data?.height}
      </td>
      <td className="px-5 py-4">Freight Class</td>
      <td className="px-5 py-4">
        {data?.totalWeight ? data?.totalWeight.toFixed(2) : 0} (
        {data?.weightMeasurement})
      </td>
      <td className="px-5 py-4">{data?.refNumber ?? '-'}</td>
      <td className="px-5 py-4">{data?.description ?? '-'}</td>
    </>
  );
};

export default dimensionsOrderDetailRow;
