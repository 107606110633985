import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Address from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address';
import DeleteModal from 'src/components/DeleteModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { BasicContext } from 'src/context/BasicContext';
import {
  createShipper,
  deleteShippers,
  updateShipper,
} from 'src/services/ShipperService';
import WalToast from 'src/utils/WalToast';

import { OrderContext } from '../../';
import {
  classOptions,
  IMapAddress,
  initAction,
  initShipperAddress,
  // initShipperAddress,
  scriptId,
} from '../../../order.interface';

import ShipperAndConsigneeAddressModal from './ShipperAndConsigneeAddressModal';

interface IProps {
  action: any;
  setAction: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
}

const AddressDetails = ({
  action,
  setAction,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
  } = useFormContext();

  const watchEquipmentType = watch('equipmentType');

  const {
    isValidAddress,
    setIsValidAddress,
    shipperOptions,
    consigneeOptions,
    shippers,
    consignees,
    currentLegData,
    setCurrentLegData,
    setIsTailgate,
    serviceList,
    setShipperFullAddress,
    setConsigneeFullAddress,
    isConsigneeBtnDisable,
    setIsConsigneeBtnDisable,
    isShipperBtnDisable,
    setIsShipperBtnDisable,
    getShipper,
    equipmentTypeOptions,
    isEquipmentTypeLoading,
  } = useContext<any>(OrderContext);

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [addressData, setAddressData] = useState<IMapAddress>();

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    if (currentLegData) {
      setShipperAddress(currentLegData?.shipper?.shipperAddress?.fullAddress);
      setConsigneeAddress(
        currentLegData?.consignee?.consigneeAddress?.fullAddress
      );

      setShipperFullAddress(currentLegData?.shipper?.shipperAddress);
      setConsigneeFullAddress(currentLegData?.consignee?.consigneeAddress);
    }
  }, [currentLegData]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value?.fullAddress);
      setValue('shipper.shipperAddress', value);
      setIsValidAddress((old: any) => ({ ...old, shipperAddress: true }));

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData.shipper,
          shipperAddress: value,
        },
      }));

      setShipperFullAddress(value);
    } else if (action.consignee) {
      setConsigneeAddress(value?.fullAddress);
      setValue('consignee.consigneeAddress', value);
      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData.consignee,
          consigneeAddress: value,
        },
      }));

      setConsigneeFullAddress(value);

      setIsValidAddress((old: any) => ({ ...old, consigneeAddress: true }));
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value?.type === 'blur') {
      // if (value.target.value) {
      //   setIsValidAddress((old) => ({ ...old, [name]: false }));
      // }

      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));
        setError('shipper.shipperAddress.fullAddress', {
          type: 'manual',
          message: 'Shipper address is invalid.',
        });
        setValue('shipper.shipperAddress', value);

        return;
      }
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));
        setError('consignee.consigneeAddress.fullAddress', {
          type: 'manual',
          message: 'Consignee address is invalid.',
        });
        setValue('consignee.consigneeAddress', value);

        return;
      }
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
    }

    if (name === 'consigneeAddress') {
      if (value?.city && value?.address1 && value?.city != value?.address1) {
        clearErrors('consignee.consigneeAddress.fullAddress');
        setIsValidAddress((old: any) => ({ ...old, [name]: true }));
        setConsigneeAddress(value?.fullAddress);
        setConsigneeFullAddress(value);
      } else {
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));

        setError('consignee.consigneeAddress.fullAddress', {
          type: 'manual',
          message: 'Consignee address is invalid.',
        });
      }

      setValue('consignee.consigneeAddress', value);

      if (value?.companyName) {
        setValue('consignee.companyName', value.companyName);
      }

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData.consignee,
          consigneeAddress: value,
        },
      }));
    }

    if (name === 'shipperAddress') {
      if (value?.city && value?.address1 && value?.city != value?.address1) {
        clearErrors('shipper.shipperAddress.fullAddress');
        setIsValidAddress((old: any) => ({ ...old, [name]: true }));
        setShipperAddress(value?.fullAddress);
        setShipperFullAddress(value);
      } else {
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));

        setError('shipper.shipperAddress.fullAddress', {
          type: 'manual',
          message: 'Shipper address is invalid.',
        });
      }

      setValue('shipper.shipperAddress', value);

      if (value?.companyName) {
        setValue('shipper.companyName', value.companyName);
      }

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData.shipper,
          shipperAddress: value,
        },
      }));
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(getValues('shipper.shipperAddress'));
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(getValues('consignee.consigneeAddress'));
      }
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;

    if (name === 'shipperId') {
      let shipperAddr = initShipperAddress;

      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );

        setValue('shipper.companyName', shipper.companyName);
        setValue('shipper.id', shipper.id);
        shipperAddr = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setShipperAddress(shipper.fullAddress);
      } else {
        setValue('shipper.companyName', undefined);
        setValue('shipper.id', undefined);
        setShipperAddress('');
      }

      setValue('shipper.shipperAddress', shipperAddr);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData.shipper,
          shipperAddress: shipperAddr,
        },
      }));

      setShipperFullAddress(shipperAddr);
    } else if (name === 'consigneeId') {
      let consigneeAddr = initShipperAddress;

      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        setValue('consignee.companyName', consignee.companyName);
        setValue('consignee.id', consignee.id);

        consigneeAddr = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setConsigneeAddress(consignee.fullAddress);
      } else {
        setValue('consignee.companyName', undefined);
        setConsigneeAddress('');
      }

      setValue('consignee.consigneeAddress', consigneeAddr);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData.consignee,
          consigneeAddress: consigneeAddr,
        },
      }));

      setConsigneeFullAddress(consigneeAddr);
    }

    if (event?.value) {
      setIsValidAddress({ ...isValidAddress, [name]: true });

      if (name === 'consigneeId') {
        setIsValidAddress({
          ...isValidAddress,
          consigneeAddress: true,
          consigneeCompanyName: true,
        });
      }

      if (name === 'shipperId') {
        setIsValidAddress({
          ...isValidAddress,
          shipperAddress: true,
          shipperCompanyName: true,
        });
      }
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;
    let insertMsg: any;
    let updateMsg: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = getValues('shipper.id') ?? null;
      companyName = getValues('shipper.companyName');
      address = getValues('shipper.shipperAddress');
      insertMsg = 'Shipper details added successfully';
      updateMsg = 'Shipper details updated successfully';
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = getValues('consignee.id') ?? null;
      companyName = getValues('consignee.companyName');
      address = getValues('consignee.consigneeAddress');
      insertMsg = 'Consignee details added successfully';
      updateMsg = 'Consignee details updated successfully';
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (
      companyName === '' ||
      address.fullAddress === '' ||
      typeof address !== 'object'
    ) {
      if (companyName === '' || !companyName) {
        if (type === 'shipper') {
          setIsValidAddress((old: any) => ({
            ...old,
            shipperCompanyName: false,
          }));
        } else if (type === 'consignee') {
          setIsValidAddress((old: any) => ({
            ...old,
            consigneeCompanyName: false,
          }));
        }
      }

      if (address.fullAddress === '' || typeof address !== 'object') {
        if (type === 'shipper') {
          setIsValidAddress((old: any) => ({ ...old, shipperAddress: false }));
        } else if (type === 'consignee') {
          setIsValidAddress((old: any) => ({
            ...old,
            consigneeAddress: false,
          }));
        }
      }

      return false;
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }

            WalToast.success(updateMsg, '');
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }

            WalToast.success(insertMsg, '');
          }
        })
        .catch(console.error);
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? getValues('shipper.id')
          : getValues('consignee.id');

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                if (action.shipper) {
                  setShipperAddress('');
                } else if (action.consignee) {
                  setConsigneeAddress('');
                }

                getShipper();

                const title = action.shipper ? 'Shipper' : 'Consignee';
                WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  useEffect(() => {
    clearErrors('shipper.shipperAddress.fullAddress');

    if (!shipperAddress) {
      setValue('shipper.shipperAddress', initShipperAddress);
      setValue('shipper.companyName', undefined);
      setValue('shipper.id', null);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData?.shipper,
          shipperAddress: initShipperAddress,
          id: null,
        },
      }));

      setShipperFullAddress(initShipperAddress);
    }
  }, [shipperAddress]);

  useEffect(() => {
    clearErrors('consignee.consigneeAddress.fullAddress');

    if (!consigneeAddress) {
      setValue('consignee.consigneeAddress', initShipperAddress);
      setValue('consignee.companyName', undefined);
      setValue('consignee.id', null);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData?.consignee,
          consigneeAddress: initShipperAddress,
          id: null,
        },
      }));

      setConsigneeFullAddress(initShipperAddress);
    }
  }, [consigneeAddress]);

  useEffect(() => {
    if (!isEquipmentTypeLoading && equipmentTypeOptions?.length > 0) {
      const currentEquipmentType = watchEquipmentType;

      if (currentEquipmentType) {
        const defaultEquipmentType =
          equipmentTypeOptions.find(
            (option: any) => option.value === currentEquipmentType
          ) || null;

        if (defaultEquipmentType) {
          setValue('equipmentType', defaultEquipmentType.value);
          setValue('equipmentTypeFullName', defaultEquipmentType.label);
        }
      } else {
        const defaultEquipmentType =
          equipmentTypeOptions.find((option: any) => option.value === 'V') ||
          null;

        if (defaultEquipmentType) {
          setValue('equipmentType', defaultEquipmentType.value);
          setValue('equipmentTypeFullName', defaultEquipmentType.label);
        }
      }
    }
  }, [isEquipmentTypeLoading, equipmentTypeOptions, watchEquipmentType]);

  const memorizedEquipmentTypeSelectBox = useMemo(
    () => (
      <Controller
        name={`equipmentType`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectBox
            name="equipmentType"
            id="equipmentType"
            className="form_control"
            parentClassName=""
            size="sm"
            options={equipmentTypeOptions}
            onChangeFunc={(e: any) => {
              onChange(e?.value);
              setValue('equipmentTypeFullName', e?.label);

              if (error?.message) {
                clearErrors('equipmentType');
              }
            }}
            isSearchable={false}
            value={
              value
                ? equipmentTypeOptions.filter((val: any) => value === val.value)
                : equipmentTypeOptions.filter((val: any) => 'V' === val.value)
            }
            errorText={error ? error.message : null}
            isLoading={isEquipmentTypeLoading}
          />
        )}
      />
    ),
    [isEquipmentTypeLoading, equipmentTypeOptions, watchEquipmentType]
  );

  return (
    <>
      <div>
        <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
          Order Details
        </h6>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0 ">
          <div className="flex flex-wrap sm:-mx-1.5 -my-2">
            <Controller
              name={`orderType`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SelectBox
                  name="orderType"
                  id="orderType"
                  className="form_control"
                  label="Order Type"
                  labelClassName={'form_label block mb-1.5'}
                  parentClassName="sm:px-1.5 sm:py-2 sm:w-1/2"
                  size="sm"
                  options={classOptions}
                  onChangeFunc={(e: any) => {
                    onChange(e?.value);
                  }}
                  isSearchable={false}
                  value={
                    value
                      ? classOptions.filter((val: any) => value === val.value)
                      : classOptions.filter((val: any) => 'ltl' === val.value)
                  }
                  required
                  errorText={error ? error.message : null}
                />
              )}
            />

            <div className="sm:px-1.5 sm:py-2 sm:w-1/2">
              <label className="form_label mb-1.5 block">
                Equipment Type
                <span className="text-red-600 leading-4">*</span>
              </label>
              {memorizedEquipmentTypeSelectBox}
            </div>
          </div>
        </div>
      </div>
      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />
      <ShipperAndConsigneeAddressModal
        control={control}
        errors={errors}
        data="shipper"
        title="Shipper"
        onAutocompleteChange={handleAutocompleteChange('shipperAddress')}
        onAddressInputChange={setShipperAddress}
        setAction={setAction}
        status={status}
        handleActionType={handleActionType}
        isAddressExits={shipperAddress}
        isValidAddress={isValidAddress}
        selectBoxOptionList={shipperOptions}
        handleSelectChange={handleSelectChange}
        setIsTailgate={setIsTailgate}
        currentLegData={currentLegData}
        setCurrentLegData={setCurrentLegData}
        setValue={setValue}
        serviceList={serviceList}
        saveShipperDetails={saveShipperDetails}
        shipperBtnDisable={isShipperBtnDisable}
      />

      <ShipperAndConsigneeAddressModal
        control={control}
        errors={errors}
        data="consignee"
        title="Consignee"
        onAutocompleteChange={handleAutocompleteChange('consigneeAddress')}
        onAddressInputChange={setConsigneeAddress}
        setAction={setAction}
        status={status}
        handleActionType={handleActionType}
        isAddressExits={consigneeAddress}
        isValidAddress={isValidAddress}
        selectBoxOptionList={consigneeOptions}
        handleSelectChange={handleSelectChange}
        setIsTailgate={setIsTailgate}
        currentLegData={currentLegData}
        setCurrentLegData={setCurrentLegData}
        setValue={setValue}
        serviceList={serviceList}
        saveShipperDetails={saveShipperDetails}
        shipperBtnDisable={isConsigneeBtnDisable}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? getValues('consignee.companyName')
              : getValues('shipper.companyName')
          } From Wal HQ `}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}
    </>
  );
};

export default AddressDetails;
