import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CreditTabOrderRaw = ({ data }: { data: any }) => (
  <>
    <td className="px-5 py-4 min-w-36 max-w-36">
      <h6 className="text-grayLight900 font-semibold leading-tight truncate h-8 flex items-center ">
        {data?.fullId}
      </h6>
    </td>
    <td className="px-5 py-4 font-normal min-w-[150px]">
      <div className="flex gap-1">
        {data?.orderStatus === 'invoice-sent' && (
          <BadgeCmp
            style="modern"
            badgeDotColor="bg-utilityPink600"
            mainClassName=" mr-1"
          >
            Invoice Sent
          </BadgeCmp>
        )}
        {data?.orderStatus === 'delivered' && (
          <BadgeCmp style="modern" type="lightblue" mainClassName=" mr-1">
            Delivered
          </BadgeCmp>
        )}
        {data?.orderStatus === 'in-transit' && (
          <BadgeCmp style="modern" type="primary" mainClassName=" mr-1">
            In-transit
          </BadgeCmp>
        )}
        {data?.orderStatus === 'invoice-created' && (
          <BadgeCmp style="modern" type="error" mainClassName=" mr-1">
            Invoice Created
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-4 font-normal ">
      {data.orderCreatedAt &&
        moment(data.orderCreatedAt).format('MMM DD, YYYY')}
    </td>
    <td className="px-5 py-4 font-normal ">
      ${getFormattedNumber(data.combinedCost ?? 0)}
    </td>
    <td className="px-5 py-4 font-semibold">
      {getFormattedNumber(data.combinedMargin, true, true)}
    </td>
    <td className="px-5 py-4 font-semibold">
      ${getFormattedNumber(data.combinedRevenue ?? 0)}
    </td>
    <td className="px-5 py-4 font-normal ">
      {data.orderCommissioneeFirstName && data.orderCommissioneeLastName ? (
        <div className="flex items-center min-w-92">
          {data.orderCommissioneeImage ? (
            <img
              className="table-profile-icn-circle-sm"
              src={data.orderCommissioneeImageUrl + data.orderCommissioneeImage}
              alt={data.orderCommissioneeFirstName}
              title={data.orderCommissioneeFirstName}
              onError={onError}
            />
          ) : (
            <div className="table-profile-user-circle-sm">
              {getShortName(
                `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
              )}
            </div>
          )}
          <div className="pl-2">
            <h6 className="text-grayLight900 font-medium leading-tight max-w-28 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
          </div>
        </div>
      ) : (
        <div className="flex items-center min-w-92">
          <div className="table-profile-empty-circle-sm"></div>
          <div className="pl-2">
            <h6 className="text-grayLight900 font-medium leading-tight max-w-28 truncate">
              Unassigned
            </h6>
          </div>
        </div>
      )}
    </td>
  </>
);

export default CreditTabOrderRaw;
