import React, { useContext, useMemo } from 'react';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '../..';
import LegDetailsRow from '../../LegDetails/LegDetailsRow';

const LegOverviewTab = () => {
  const { legData } = useContext<any>(OrderContext);

  const legHeadCells = useMemo(
    () => [
      {
        id: 'lefId',
        name: 'Leg ID',
      },
      {
        id: 'carrier',
        name: 'Carrier',
      },
      {
        id: 'addresses',
        name: 'Addresses',
      },
      {
        id: 'mileage',
        name: 'Mileage',
      },
      {
        id: 'estTransitTime',
        name: 'Est. Transit Time',
      },
    ],
    []
  );

  return (
    <div className="flex">
      <div className="w-1/2 flex-1 p-4 bg-gray25">
        <TableCmp
          headCells={legHeadCells}
          tableDataArr={legData?.length > 0 ? legData : []}
          TableRowCmp={LegDetailsRow}
          tableFooterRowCmpProps={{}}
          numberOfSkeletonRows={1}
        />
      </div>
    </div>
  );
};

export default LegOverviewTab;
