import { DownloadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import { Eye } from 'lucide-react';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';

import pdfIcon2 from '../../../../assets/img/pdf-icon.webp';

const PdfTableLoadingRow = () => (
  <>
    <td className="w-52 min-w-52 max-w-52 ">
      <div className="flex items-center gap-3 custom-loading">
        <img src={pdfIcon2} className="w-9 h-9 flex-none" />
        <p className="font-medium text-grayLight900 truncate">
          testPSd1234.pdf
        </p>
      </div>
    </td>
    <td>
      <span className="custom-loading">Aliqua in ut </span>
    </td>
    <td>
      <span className="custom-loading"> CAD </span>
    </td>
    <td>
      <span className="custom-loading"> 13 jun 2024 </span>
    </td>
    <td className="w-[115px] min-w-[115px]">
      <div className="flex gap-1">
        <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2 custom-loading">
          <Eye className="w-4 h-4" />
        </ButtonCmp>
        <ButtonCmp className="btn-outline-primary lg:px-[9px] px-2 custom-loading">
          <DownloadCloud02 className="w-4 h-4" />
        </ButtonCmp>
      </div>
    </td>
  </>
);

export default PdfTableLoadingRow;
