import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import EmailReplyCmp from 'src/components/EmailReplyCmp';

interface IProps {
  handleClose: any;
  customerName: any;
  customerEmail?: any;
  onSubmit: any;
  generatedPdfName?: any;
  defaultAttachments?: {
    url: string;
    fileName: string;
  }[];
}

const SendQuoteToCustomerModal = ({
  handleClose,
  customerName,
  customerEmail,
  onSubmit,
  defaultAttachments,
}: IProps) => {
  const emailDetails = `Please find the detailed quote with this attached document provides a comprehensive breakdown of our offerings, pricing, and any relevant terms.`;

  // const emailStaticBody = useMemo(
  //   () => (
  //     <div className="flex flex-wrap -mx-[6px] mt-6 gap-y-3">
  //       <div className="w-1/2 px-[6px]">
  //         <div className="border border-utilityGray200 p-3 rounded-lg flex items-center gap-[6px]">
  //           <img src={pdficon} alt="" className="" />
  //           <div className="flex-1">
  //             <p className="truncate max-w-48 text-xs font-medium text-grayLight900">
  //               {generatedPdfName}.pdf
  //             </p>
  //             {/* <p className="text-[10px] text-grayLight600">
  //           200 KB – 100% uploaded
  //         </p> */}
  //           </div>
  //           <CheckBox
  //             classes="z-10"
  //             checked={isFileAttached}
  //             onChangeFunc={(e: any) => setIsFileAttached(e.target.checked)}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   ),
  //   [generatedPdfName, isFileAttached]
  // );

  return (
    <CommonModal
      title={`Send Customer Quote to ${customerName}`}
      titleDesc=""
      handleClose={handleClose}
      headerIcon={''}
      size={'max-w-[600px]'}
      isOverflow={true}
      modalHeaderClass="border-b-0"
    >
      <EmailReplyCmp
        subjectLine="Generated quote PDF"
        toEmailAddress={customerEmail}
        emailDetails={emailDetails}
        handleClose={handleClose}
        onSubmit={(data: any) => {
          onSubmit(data);
        }}
        defaultAttachments={defaultAttachments}
      />
    </CommonModal>
  );
};

export default SendQuoteToCustomerModal;
