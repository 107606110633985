import moment from 'moment';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAppearance } from 'src/services/SettingService';

import { CURRENCY, MAP_API, TRIMBLE_KEY } from '../constants/common';
import { TIMEZONE } from '../constants/common';

import { AuthContext } from './AuthContext';

export const BasicContext = createContext<any>(null);

export const BasicProvider = (props: any) => {
  const { children } = props;
  const { isAuthenticated } = useContext(AuthContext);
  const [currency, setCurrency] = useState<string>(CURRENCY.CAD);
  const [appearance, setAppearance] = useState<any>({});
  const [mapService, setMapService] = useState<string>(MAP_API);
  const [googleKey, setGoogleKey] = useState<string>(window.GOOGLE_API_KEY);
  const [trimbleKey, setTrimbleKey] = useState<string>(TRIMBLE_KEY);
  const [tooltipGlobalOpen, setTooltipGlobalOpen] = useState(false);
  const [lastSyncDateTimeActual, setLastSyncDateTimeActual] =
    useState(undefined);
  const [allTimeStartDateActual, setAllTimeStartDateActual] =
    useState(undefined);
  const [allTimeStartDateQuote, setAllTimeStartDateQuote] = useState(undefined);

  const [actionTooltipGlobalOpen, setActionTooltipGlobalOpen] = useState(false);
  const [actionDelayTimeout, setActionDelayTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [
    ordersUpdateSocketTriggeredCount,
    setOrdersUpdateSocketTriggeredCount,
  ] = useState<boolean | undefined>(false);
  const [reportMonth, setReportMonth] = useState<string>('');
  const [reportYear, setReportYear] = useState<string>('');
  const [isMakeCall, setIsMakeCall] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAppearance()
      .then((response) => {
        if (response.data) {
          setAppearance(response.data);
          moment.tz.setDefault(response.data?.timezone ?? TIMEZONE);
          setCurrency(response?.data?.currency ?? CURRENCY.CAD);
        }
      })
      .finally(() => {})
      .catch(console.log);
  }, [isAuthenticated]);

  const value = {
    currency,
    setCurrency,
    tooltipGlobalOpen,
    setTooltipGlobalOpen,
    actionTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
    actionDelayTimeout,
    setActionDelayTimeout,
    mapService,
    setMapService,
    googleKey,
    setGoogleKey,
    trimbleKey,
    setTrimbleKey,
    lastSyncDateTimeActual,
    setLastSyncDateTimeActual,
    allTimeStartDateActual,
    setAllTimeStartDateActual,
    allTimeStartDateQuote,
    setAllTimeStartDateQuote,
    appearance,
    setAppearance,
    ordersUpdateSocketTriggeredCount,
    setOrdersUpdateSocketTriggeredCount,
    reportMonth,
    setReportMonth,
    reportYear,
    setReportYear,
    isMakeCall,
    setIsMakeCall,
  };

  return (
    <BasicContext.Provider value={value}>{children}</BasicContext.Provider>
  );
};

BasicProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
