import {
  ArrowNarrowDownLeft,
  DownloadCloud02,
  // DownloadCloud02,
  PauseCircle,
  PlayCircle,
  Trash01,
  VolumeMax,
  VolumeX,
} from '@untitled-ui/icons-react/build/cjs';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  getFormattedDate,
  // getMinutesAndSeconds,
} from 'src/utils/CommonFunctions';

// import WaveForm from '../../../assets/img/Wave-form-component1.svg';

const CallHistoryRow = ({ data }: any) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioTime, setAudioTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isRecordingLoading, setIsRecordingLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const fetchRecordingDetails = useCallback(async (recordingId: string) => {
    setIsRecordingLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'http://localhost:1337/api/v1/twilio/download-recording',
        { recordingSid: recordingId },
        { responseType: 'blob' }
      );

      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(audioBlob);

      if (audioRef.current) {
        audioRef.current.src = url;

        return () => URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.error('Error fetching recording details:', err);
      setError('Unable to fetch recording. Please try again.');
    } finally {
      setIsRecordingLoading(false);
    }
  }, []);

  const handlePlayPause = useCallback(
    async (recordingId: string) => {
      if (!audioRef.current) return;

      try {
        if (isPlaying) {
          await audioRef.current.pause();
        } else {
          if (!audioRef.current.src) {
            await fetchRecordingDetails(recordingId);
          }
          await audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (err) {
        console.error('Playback error:', err);
        setError('Unable to play audio. Please try again.');
      }
    },
    [isPlaying, fetchRecordingDetails]
  );

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current && !isDragging) {
      setAudioTime(audioRef.current.currentTime);
    }
  }, [isDragging]);

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  }, []);

  const updateTimeFromClick = useCallback(
    (e: any) => {
      if (!progressRef.current || !audioRef.current) return;

      const rect = progressRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      const newTime = pos * duration;

      audioRef.current.currentTime = newTime;
      setAudioTime(newTime);
    },
    [duration]
  );

  const toggleVolume = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setIsMuted(audioRef.current.muted);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: any) => {
      if (isDragging) {
        updateTimeFromClick(e);
      }
    },
    [isDragging, updateTimeFromClick]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (e: any) => {
      setIsDragging(true);
      updateTimeFromClick(e);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [updateTimeFromClick]
  );

  // Clean up when component unmounts
  useEffect(
    () => () => {
      if (audioRef.current?.src) {
        URL.revokeObjectURL(audioRef.current.src);
      }
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp]
  );

  const downloadRecording = async () => {
    try {
      const response = await axios.post(
        'http://localhost:1337/api/v1/twilio/download-recording',
        { recordingSid: 'REa9fee210adb53eeb8ecc23cea741cdb4' },
        { responseType: 'blob' }
      );

      // Create a blob from the response data
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

      // Create a URL for the blob
      const url = URL.createObjectURL(audioBlob);

      // Create a temporary anchor element
      const anchor = document.createElement('a');
      anchor.href = url;
      // anchor.download = `recording_${recordingSid}.mp3`;
      anchor.download = `recording.mp3`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor); // Clean up

      // Revoke the blob URL to release memory
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error downloading recording:', err);
    }
  };

  return (
    <>
      <td className="px-5 py-4 w-[24.06%] min-w-[24.06%] max-w-[1px]">
        <div className="h-8 flex items-center gap-3 truncate">
          <ArrowNarrowDownLeft
            className={`w-4 h-4 flex-none ${
              data.callType === 'missed'
                ? 'text-fgErrorPrimary'
                : 'text-gray400'
            }`}
          />
          <p
            className={`capitalize truncate ${
              data.callType === 'missed'
                ? 'text-fgErrorPrimary'
                : 'text-textSecondary'
            }`}
          >
            {data.callType}
          </p>
        </div>
      </td>

      <td className="px-5 py-4 w-[24.06%] min-w-[200px] max-w-[24.06%]">
        <p className="truncate text-grayLight600">
          {getFormattedDate(data?.createdAt, 'MMM Do, YYYY', true, true)}
        </p>
      </td>

      <td className="px-5 py-4 w-[24.06%] min-w-[150px] max-w-[24.06%]">
        <p className="truncate text-grayLight600">
          {moment.utc(data?.duration * 1000).format('mm:ss')}
        </p>
      </td>

      <td className="px-5 py-2 w-[24.06%] min-w-[400px] max-w-[24.06%]">
        {data?.recordingId ? (
          <div
            className={`p-2 pr-3 rounded-3xl  flex items-center gap-x-3 flex-wrap border max-w-[310px] ${
              error ? 'border-fgErrorPrimary' : ' border-utilityGray100'
            } ${isPlaying ? 'bg-primary100' : 'bg-utilityGray100'}`}
          >
            {isRecordingLoading ? (
              <div className="animate-pulse w-6 h-6 rounded-full bg-gray-300" />
            ) : (
              <button
                onClick={() => handlePlayPause(data?.recordingId)}
                disabled={isRecordingLoading}
                className="focus:outline-none"
              >
                {isPlaying ? (
                  <PauseCircle className="text-primary cursor-pointer w-6 h-6" />
                ) : (
                  <PlayCircle className="text-gray500 cursor-pointer w-6 h-6" />
                )}
              </button>
            )}

            <div className="items-center flex flex-1">
              <div
                ref={progressRef}
                className={` flex-1 relative h-[2.5px] cursor-pointer rounded-full ${
                  isPlaying ? 'bg-borderSecondary' : 'bg-borderPrimary'
                }`}
                onMouseDown={handleMouseDown}
              >
                <div
                  className={`absolute h-full transition-all duration-300 ${
                    isPlaying ? 'bg-primary500' : 'bg-gray500'
                  }`}
                  style={{
                    width: `${duration ? (audioTime / duration) * 100 : 0}%`,
                  }}
                />
                <div
                  className={`absolute top-1/2 -translate-y-1/2 w-2.5 h-2.5 rounded-full  -ml-1.5 ${
                    isPlaying ? 'bg-primary500' : 'bg-gray500'
                  }`}
                  style={{
                    left: `${duration ? (audioTime / duration) * 100 : 0}%`,
                  }}
                />
              </div>
            </div>

            <p className="text-sm font-medium text-grayLight900">
              {Math.floor(audioTime / 60)}:
              {Math.floor(audioTime % 60)
                .toString()
                .padStart(2, '0')}
            </p>
            {isMuted ? (
              <VolumeX
                className="text-gray500 w-5 h-5 cursor-pointer"
                onClick={toggleVolume}
              />
            ) : (
              <VolumeMax
                className="text-gray500 w-5 h-5 cursor-pointer"
                onClick={toggleVolume}
              />
            )}
            <DownloadCloud02
              className="text-gray500 w-5 h-5 cursor-pointer"
              onClick={downloadRecording}
            />

            <audio
              ref={audioRef}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
              onEnded={() => setIsPlaying(false)}
            />
          </div>
        ) : (
          <p>No Recording Available</p>
        )}
      </td>
      {/* <td className="px-5 py-4 w-44 min-w-44 max-w-44">
      <div className="flex items-center px-2 py-2 bg-utilityGray100 border rounded-full border-utilityGray100 gap-2 w-[232px]">
        <PlayCircle className="w-4 h-4 text-gray500 cursor-pointer" />
        <img src={WaveForm} alt="waveform" className="h-5" />
        <span className="text-gray500">
          {getMinutesAndSeconds(data.duration)}
        </span>
        <VolumeMax className="w-4 h-4 text-gray500 cursor-pointer" />
        <DownloadCloud02 className="w-4 h-4 text-gray500 cursor-pointer" />
      </div>
    </td> */}

      <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
        <Trash01 className="w-4 h-4 text-gray400 cursor-pointer" />
      </td>
    </>
  );
};
export default CallHistoryRow;
