import {} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/context/AuthContext';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import InternalChat from './InternalChat';

interface IProps {
  data?: any;
  chatType: any;
  chatChannelName: any;
  internalNoteChannelName?: any;
  notesCount: any;
  setNotesCount: any;
  isShowChatAndNotesSideBar: any;
  setIsShowChatAndNotesSideBar: any;
  chatWrapClassName?: string;
  isShowInternalNote: any;
  chatEntityId: any;
  sidebarChatCloseIconClass?: string;
  chatTopContent?: any;
}

const InternalChatAndNotes = ({
  data,
  chatType,
  chatChannelName,
  internalNoteChannelName,
  //   notesCount,
  //   setNotesCount,
  isShowChatAndNotesSideBar,
  setIsShowChatAndNotesSideBar,
  chatWrapClassName,
  isShowInternalNote,
  sidebarChatCloseIconClass,
  chatTopContent,
  chatEntityId,
}: IProps) => {
  const { currentUser } = useContext(AuthContext);

  const [currentTab, setCurrentTab] = useState('messages');

  //For Internal Note
  //   const [internalNotes, setInternalNotes] = useState<any[]>([]);

  //For Chat
  const [typingUsers, setTypingUsers] = useState<string[]>([]);
  // when finalize chat and note flow, refactor this state and move up to chat component including socket listen event also
  const [messages, setMessages] = useState<any>([]);
  const [members, setMembers] = useState([]);
  const [lastMessageId, setLastMessageId] = useState(null);
  //   const [lastNoteId, setLastNoteId] = useState(null);

  const typingTimeouts = new Map();

  useEffect(() => {
    if (data.id) {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            io.socket.get(`/subscribe/${currentUser?.id}`, function (jwr: any) {
              if (jwr.error) {
                console.error('Subscription error:', jwr.error);

                return;
              }
            });

            io.socket.get(`/subscribe/globalRoom`, function (jwr: any) {
              if (jwr.error) {
                console.error('Subscription error:', jwr.error);

                return;
              }
            });
          });

          if (internalNoteChannelName) {
            io.socket.on('connect', function socketConnected() {
              let noteChannelName = `/subscribe/${internalNoteChannelName}_${data.id}`;

              io.socket.get(noteChannelName, (details: any) => {
                console.log(`Joined room: ${JSON.stringify(details)}`);
              });
            });
          }

          io.socket.on('connect', function socketConnected() {
            const messageChannelName = `/subscribe/${chatChannelName}_${data.id}`;
            console.log('messageChannelName', messageChannelName);

            io.socket.get(messageChannelName, (details: any) => {
              console.log(`Joined room: ${JSON.stringify(details)}`);
            });
          });

          //   io.socket.on('internalNotes', (notes: any) => {
          //     setInternalNotes((prevNotes: any) => [...prevNotes, notes]);
          //     setNotesCount((prevCount: number) => prevCount + 1);
          //     setLastNoteId(notes.id);
          //   });

          io.socket.on('userStatusUpdate', (userStatus: any) => {
            const userStatusElements = document.querySelectorAll(
              `.user-status-${userStatus.userId}`
            );

            if (userStatusElements.length > 0) {
              userStatusElements.forEach((element) => {
                element.classList.remove('bg-successSecondary', 'bg-danger500');

                if (userStatus.onlineStatus === 'online') {
                  element.classList.add('bg-successSecondary');
                } else {
                  element.classList.add('bg-danger500');
                }
              });
            }
          });

          io.socket.on('message', (data2: any) => {
            setTypingUsers((prevTypingUsers) => {
              const newTypingUsers = new Map(
                prevTypingUsers.map((user: any) => [user.id, user])
              );

              if (newTypingUsers.has(data2.sender.id)) {
                newTypingUsers.delete(data2.sender.id);

                if (typingTimeouts.has(data2.sender.id)) {
                  clearTimeout(typingTimeouts.get(data2.sender.id));
                  typingTimeouts.delete(data2.sender.id);
                }
              }

              return Array.from(newTypingUsers.values());
            });

            setMessages((prevMessages: any) => [...prevMessages, data2]);
            setLastMessageId(data2.id);
          });

          io.socket.on('typing', (typingStatus: any) => {
            setTypingUsers((prevTypingUsers) => {
              const newTypingUsers = new Map(
                prevTypingUsers.map((user: any) => [user.id, user])
              );

              if (
                typingStatus.typing &&
                typingStatus.user.id !== currentUser.id
              ) {
                newTypingUsers.set(typingStatus.user.id, {
                  ...typingStatus.user,
                  lastTyped: Date.now(),
                });

                if (typingTimeouts.has(typingStatus.user.id)) {
                  clearTimeout(typingTimeouts.get(typingStatus.user.id));
                }

                const timeoutId = setTimeout(() => {
                  setTypingUsers((prevTypingUsers2) =>
                    prevTypingUsers2.filter(
                      (user: any) => user.id !== typingStatus.user.id
                    )
                  );
                  typingTimeouts.delete(typingStatus.user.id);
                }, 10000);

                typingTimeouts.set(typingStatus.user.id, timeoutId);
              }

              return Array.from(newTypingUsers.values());
            });
          });

          // Send typing event to server
          const handleTyping = (isTypingValue: any) => {
            const typingChannelName = `/typing/${chatChannelName}_${data.id}`;

            io.socket.get(typingChannelName, {
              user: currentUser,
              typing: isTypingValue,
            });
          };

          const inputElement: any = document.getElementById('chatInput');
          inputElement.addEventListener('keypress', () => handleTyping(true));
          inputElement.addEventListener('blur', () => handleTyping(false));

          // Clean up on component unmount
          return () => {
            io.socket.off('internalNotes');
            io.socket.off('message');
            io.socket.off('typing');
            io.socket.off('userStatusUpdate');
          };
        })
        .catch((error) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, [data.id]);

  return (
    <div
      className={`xlm:w-[380px] xl:w-[360px] w-[320px]  bg-gray25 border-l border-utilityGray200 h-full flex flex-col transition-all duration-[0.5s] max-xl:absolute ${chatWrapClassName}  ${
        isShowChatAndNotesSideBar
          ? 'xl:-mr-0 right-0'
          : 'xxl:-mr-[407px] xl:-mr-[412px] -right-[383px] '
      } `}
    >
      {chatTopContent}
      {currentTab === 'messages' && (
        <InternalChat
          data={data}
          chatType={chatType}
          typingUsers={typingUsers}
          messages={messages}
          members={members}
          setMessages={setMessages}
          setMembers={setMembers}
          currentTab={currentTab}
          isShowInternalNote={isShowInternalNote}
          setCurrentTab={setCurrentTab}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
          lastMessageId={lastMessageId}
          setLastMessageId={setLastMessageId}
          chatEntityId={chatEntityId}
          sidebarChatCloseIcon={sidebarChatCloseIconClass}
        />
      )}

      {/* {currentTab === 'internal_notes' && (
        <InternalNotes
          data={data}
          notesCount={notesCount}
          internalNotes={internalNotes}
          setInternalNotes={setInternalNotes}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
          lastNoteId={lastNoteId}
          setLastNoteId={setLastNoteId}
        />
      )} */}
    </div>
  );
};

export default InternalChatAndNotes;
