import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const addTag = async (data: any) => {
  const response = await axiosInterceptor.post(API.TAG.CREATE, data);

  return response;
};

export const getTagList = async (params: any) => {
  const response = await axiosInterceptor.get(API.TAG.LIST, {
    params,
  });

  return response;
};

export const deleteTag = async (id: string) => {
  const response = await axiosInterceptor.delete(`${API.TAG.DELETE}/${id}`);

  return response;
};
