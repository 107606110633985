import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import defaultImage from '../../../assets/img/default-image.jpg';

const CarriersListLoading = () => (
  <>
    <td className="px-5 py-4  min-w-96 w-96 max-w-96">
      <div className="flex items-center truncate custom-loading">
        <img
          className="table-profile-customer-circle-xl rounded-md"
          src={defaultImage}
        />
        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            ABF: Time Keeper (Guaranteed by 1200 on 2024-05-09)
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-56 w-56 max-w-56">
      {/* <div className="pl-3 font-medium custom-loading">Direct</div> */}
      <span className="flex gap-1 custom-loading">
        <div className=" px-[6px] py-[2px] border inline-flex shadow items-center justify-start bg-primary50 border-primary200 rounded-full ">
          <div className="text-center text-xs font-medium  flex items-center capitalize text-primary ">
            Api
          </div>
        </div>
        <div className=" px-[6px] py-[2px] border inline-flex shadow items-center justify-start bg-primary50 border-primary200 rounded-full ">
          <div className="text-center text-xs font-medium  flex items-center capitalize text-primary ">
            Spot
          </div>
        </div>
      </span>
    </td>

    <td className="px-5 py-4">
      <div className="flex items-center gap-1 custom-loading">
        <div className="font-medium">Freightcom</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="pl-3 custom-loading">0</span>
    </td>
    <td className="px-5 py-4">
      <span className="pl-3 custom-loading">0</span>
    </td>

    <td className="px-5 py-4 w-[61px] max-w-[61px] min-w-[61px]">
      <div className="custom-loading">
        <Edit01 className="w-4 h-4 cursor-pointer flex items-center font-semibold flex-none " />
      </div>
    </td>
  </>
);

export default CarriersListLoading;
