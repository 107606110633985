import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { addTagToOrder } from 'src/services/OrderService';
import * as Yup from 'yup';

interface IProps {
  tagList: any;
  handleClose: () => void;
  masterOrderId: number;
  setOrderTags: (tags: any) => void;
}

const schema = Yup.object().shape({
  tag: Yup.array()
    .min(1, 'Tag field is required')
    .required('Tag field is required'),
});

const TagsModal = ({
  tagList,
  handleClose,
  masterOrderId,
  setOrderTags,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tag: [],
    },
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);

    addTagToOrder({
      masterOrderId,
      tagIds: data.tag,
    })
      .then((response) => {
        if (response?.data) {
          setOrderTags((prev: any) => [...prev, ...response.data]);
          handleClose();
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  };

  return (
    <CommonModal
      title="Select Tag"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnText="Add Tag"
      secondaryBtnOnClick={handleClose}
      secondaryBtnText="Close"
      handleClose={handleClose}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        <Controller
          name="tag"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectBox
              options={tagList}
              placeholder="Select Tag"
              onChangeFunc={(event: any) => {
                const newValue = event.map((e: any) => e.value);

                onChange(newValue ?? []);
              }}
              value={
                value
                  ? tagList?.filter((tag: any) => value?.includes(tag?.value))
                  : []
              }
              errorText={error?.message ?? ''}
              isMultipleSelection={true}
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default TagsModal;
