import React, { useContext } from 'react';
import CarrierAndSalesPrice from 'src/app/Orders/OrderDetails/Step4/CarrierAndSalesPrice';

import { OrderContext } from '../..';

const Step4 = () => {
  const {
    id,
    isCustomerLoading,
    customerList,
    order,
    salesPrice,
    setSalesPrice,
    carrierPrice,
    setCarrierPrice,
  } = useContext<any>(OrderContext);

  return (
    <CarrierAndSalesPrice
      id={id}
      isCustomerLoading={isCustomerLoading}
      customerList={customerList}
      order={order}
      salesPrice={salesPrice}
      setSalesPrice={setSalesPrice}
      carrierPrice={carrierPrice}
      setCarrierPrice={setCarrierPrice}
    />
  );
};

export default Step4;
