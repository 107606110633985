import { DownloadCloud02, File06 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import ImageIcon from 'src/assets/img/image-icon.svg';
import CommonModal from 'src/components/CommonModal';
import { approveDocuments } from 'src/services/OrderService';
import { convertToGBMBKB, downloadPdf } from 'src/utils/CommonFunctions';

import pdf from '../../../assets/img/pdf-icon.webp';

interface IProps {
  handleClose: any;
  documents: any;
  title: string;
  titleDesc: string;
  contentTitle: string;
  isShowButtons: boolean;
  orderLegId: number;
  setDocuments?: any;
}

const ViewOrderPodModal = ({
  handleClose,
  orderLegId,
  title,
  titleDesc,
  isShowButtons,
  contentTitle,
  documents,
  setDocuments,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsLoading(true);

    approveDocuments({ orderLegId: orderLegId })
      .then((response) => {
        if (response) {
          setDocuments((docs: any[]) =>
            docs.map((doc) => ({ ...doc, isApproved: true }))
          );
          handleClose();
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <CommonModal
        title={title}
        titleDesc={titleDesc}
        handleClose={handleClose}
        headerIcon={<File06 />}
        size={'max-w-[600px]'}
        primaryBtnText={isShowButtons ? 'Approve' : undefined}
        secondaryBtnText={isShowButtons ? 'Cancel' : undefined}
        primaryBtnOnClick={onSubmit}
        secondaryBtnOnClick={handleClose}
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={isLoading}
      >
        <div className="p-5 flex flex-col gap-y-3">
          <h6 className="text-textSecondary text-sm font-medium">
            {contentTitle}
          </h6>
          {documents &&
            documents?.length > 0 &&
            documents?.map((doc: any, index: number) => (
              <div
                className="rounded-xl border border-utilityGray200 bg-white flex gap-3 p-4"
                key={index}
              >
                {doc?.fileName.endsWith('.pdf') ? (
                  <img src={pdf} className="h-10 " alt="PDF Icon" />
                ) : (
                  <img src={ImageIcon} className="h-10 " alt="Image Icon" />
                )}
                <div className="flex-1 w-[calc(100%_-_85px)] ">
                  <h6 className="text-textSecondary text-sm font-medium truncate">
                    {doc?.fileName}
                  </h6>
                  <p className="text-grayLight600 text-sm font-normal">
                    {convertToGBMBKB(doc?.fileSize)}
                  </p>
                </div>
                <DownloadCloud02
                  className="w-4 h-4 text-primary700 cursor-pointer flex-none"
                  onClick={() => {
                    downloadPdf(`${doc.fileUrl}${doc.file}`, doc.file);
                  }}
                />
              </div>
            ))}
        </div>
      </CommonModal>
    </>
  );
};

export default ViewOrderPodModal;
