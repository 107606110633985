import { Map02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';

import NasaMap from '../../../assets/img/NasaMap.svg';

interface IProps {
  handleClose: any;
}

const CustomiseMapModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Customise your Map'}
    titleDesc={'Add a customised theme to your map.'}
    handleClose={() => handleClose(false)}
    headerIcon={<Map02 />}
    size={'max-w-[620px]'}
    isOverflow={true}
    modalClassName=""
  >
    <ul className="w-full p-5 flex flex-wrap -m-2.5">
      <li className="p-2.5 w-1/4">
        <div className="cursor-pointer">
          <div className="w-[124px] h-[124px] rounded-[27px] overflow-hidden">
            <img
              src={NasaMap}
              alt="NasaMap"
              className="w-full h-full object-cover"
            />
          </div>
          <h6 className="text-grayLight600 text-sm font-medium line-clamp-1 mt-2.5">
            NASA&apos;s Black Marble
          </h6>
        </div>
      </li>
      <li className="p-2.5 w-1/4">
        <div className="cursor-pointer">
          <div className="w-[124px] h-[124px] rounded-[27px] overflow-hidden">
            <img
              src={NasaMap}
              alt="NasaMap"
              className="w-full h-full object-cover"
            />
          </div>
          <h6 className="text-grayLight600 text-sm font-medium line-clamp-1 mt-2.5">
            NASA&apos;s Black Marble
          </h6>
        </div>
      </li>
    </ul>
  </CommonModal>
);

export default CustomiseMapModal;
