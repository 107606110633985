import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { markNotificationAsRead } from 'src/services/NotificationService';

import { getEntityCount } from '../services/AuthService';

import { AuthContext } from './AuthContext';

export const EntityCountsContext = createContext<any>(null);

const initState = {
  claimsAndDisputesCount: 0,
  claimsCount: 0,
  disputesCount: 0,
  notificationsCount: 0,
  custOnBoardCount: 0,
  customerCount: 0,
  orderCount: 0,
  loadInsuranceCount: 0,
  carrierCount: 0,
  teamMemberCount: 0,
  teamCount: 0,
  feedbackCount: 0,
  creditIncreaseRequestCount: 0,
  creditDashboardCount: 0,
  missedCallCount: 0,
  quotingRequestCount: 0,
};

export const EntityCountsProvider = (props: any) => {
  const { children } = props;
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [entityCounts, setEntityCounts] = useState(initState);

  const { isAuthenticated } = useContext(AuthContext);

  const fetchCounts = async () => {
    if (!isAuthenticated) {
      return;
    }

    setLoader(true);
    getEntityCount()
      .then((response: any) => {
        if (response.data) {
          // setEntityCounts({
          //   claimsAndDisputesCount: response.data.totalCount || 0,
          //   claimsCount: response.data.claimsCount || 0,
          //   disputesCount: response.data.disputesCount || 0,
          //   notificationsCount: response.data.notificationsCount || 0,
          //   custOnBoardCount:
          //     response.data.customerOnBoardNotificationCount || 0,
          //   customerCount: response.data?.customerCount || 0,
          //   orderCount: response.data?.orderCount || 0,
          //   loadInsuranceCount: response.data?.loadInsuranceCount || 0,
          //   carrierCount: response.data?.carrierCount || 0,
          //   teamMemberCount: response.data?.teamMemberCount || 0,
          // });

          const newEntityCounts = Object.keys(initState).map((key) => ({
            key,
            value: response.data?.[key] || 0,
          }));

          const countsObject = newEntityCounts.reduce(
            (acc: any, { key, value }) => {
              acc[key] = value;

              return acc;
            },
            {}
          );

          setEntityCounts(countsObject);

          setNotificationsCount(response.data.notificationsCount || 0);
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoader(false);
      });
  };

  const handleNotificationRead = (param: any) => {
    setIsNotificationLoading(true);

    markNotificationAsRead(param)
      .then((response: any) => {
        if (response.message) {
          fetchCounts();
        }
      })
      .catch(console.error)
      .finally(() => setIsNotificationLoading(false));
  };

  useEffect(() => {
    fetchCounts();
  }, [isAuthenticated]);

  const reloadCounts = () => {
    fetchCounts();
  };

  return (
    <EntityCountsContext.Provider
      value={{
        entityCounts,
        notificationsCount,
        setNotificationsCount,
        loader,
        isNotificationLoading,
        reloadCounts,
        handleNotificationRead,
      }}
    >
      {children}
    </EntityCountsContext.Provider>
  );
};

EntityCountsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
