import React, { useCallback, useContext, useState } from 'react';
import DeleteModal from 'src/components/DeleteModal';
import {
  deleteCarrierPrice,
  deleteSalesPrice,
} from 'src/services/OrderService';

import { OrderContext } from '../..';

import RenderCarrierPriceSection from './RenderCarrierPriceSection';
import RenderSalesPriceSection from './RenderSalesPriceSection';

const initDeleteAction = {
  mode: null,
  type: null,
};

const PricingTab = () => {
  const {
    currentLegData,
    setCurrentLegData,
    order,
    setSalesPrice,
    setCarrierPrice,
    setTabValues,
    activeLeg,
  } = useContext<any>(OrderContext);

  const [deleteAction, setDeleteAction] = useState(initDeleteAction);

  const handleDeletePrice = useCallback(
    (status: boolean) => () => {
      if (status) {
        if (deleteAction.mode === 'delete-carrier-price') {
          deleteCarrierPrice({
            masterOrderId: order?.id,
            orderLegId: currentLegData?.id,
          })
            .then((response) => {
              if (response) {
                setCarrierPrice([]);
                setCurrentLegData((old: any) => ({
                  ...old,
                  carrierPrice: [],
                }));

                setTabValues((prev: any) => ({
                  ...prev,
                  [activeLeg]: {
                    ...prev[activeLeg],
                    carrierPrice: [],
                  },
                }));
              }
            })
            .catch(console.error);
        }

        if (deleteAction.mode === 'delete-sales-price') {
          deleteSalesPrice({
            masterOrderId: order?.id,
            orderLegId: currentLegData?.id,
          })
            .then((response) => {
              if (response) {
                setSalesPrice([]);
                setCurrentLegData((old: any) => ({
                  ...old,
                  salesPrice: [],
                }));

                setTabValues((prev: any) => ({
                  ...prev,
                  [activeLeg]: {
                    ...prev[activeLeg],
                    salesPrice: [],
                  },
                }));
              }
            })
            .catch(console.error);
        }
      }

      setDeleteAction((old: any) => ({
        ...old,
        ...{ mode: null },
      }));
    },
    [deleteAction]
  );

  return (
    <>
      <div className="flex">
        <div className="w-1/2 flex-1 p-4 bg-gray25">
          <RenderCarrierPriceSection setDeleteAction={setDeleteAction} />
        </div>
        <div className="w-1/2 flex-1 p-4 bg-gray25">
          <RenderSalesPriceSection setDeleteAction={setDeleteAction} />
        </div>
      </div>

      {deleteAction.mode === 'delete-carrier-price' && (
        <DeleteModal
          moduleName="Carrier Price"
          handleClose={handleDeletePrice}
        />
      )}

      {deleteAction.mode === 'delete-sales-price' && (
        <DeleteModal moduleName="Sales Price" handleClose={handleDeletePrice} />
      )}
    </>
  );
};

export default PricingTab;
