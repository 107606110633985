import React, { useContext } from 'react';

import { OrderContext } from '../..';

import AddOrderDetails from './AddOrderDetails';

interface IProps {
  tempLegData: any;
  setTempLegData: any;
  dimensions: any;
  setDimensions: any;
}

const Step3 = ({
  tempLegData,
  setTempLegData,
  dimensions,
  setDimensions,
}: IProps) => {
  const {
    setIsTailgate,
    serviceList,
    setWeightMeasurement,
    setLengthMeasurement,
    lengthMeasurement,
    weightMeasurement,
    order,
    setOrder,
  } = useContext<any>(OrderContext);

  return (
    <AddOrderDetails
      setIsTailgate={setIsTailgate}
      serviceList={serviceList}
      setWeightMeasurement={setWeightMeasurement}
      setLengthMeasurement={setLengthMeasurement}
      lengthMeasurement={lengthMeasurement}
      weightMeasurement={weightMeasurement}
      tempLegData={tempLegData}
      setTempLegData={setTempLegData}
      dimensions={dimensions}
      setDimensions={setDimensions}
      order={order}
      setOrder={setOrder}
    />
  );
};

export default Step3;
