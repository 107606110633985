import { MEASUREMENT } from 'src/constants/common';
import * as Yup from 'yup';

export interface IMapAddress {
  fullAddress: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  latitude: string;
  longitude: string;
  stateCode: string;
  countryCode: string;
}

export interface IFormData {
  [key: string]: any;
  customerId: any;
  shipperId: number | null;
  shipperCompanyName: string;
  shipperAddress: IMapAddress;
  pickupDate: any;
  consigneeId: number | null;
  consigneeCompanyName: string;
  consigneeAddress: IMapAddress;
}

export const initShipperAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

export interface IDimensionProps {
  handlingUnit: number | string | undefined;
  handlingUnitNo: number | string | undefined;
  // weight: number | string| undefined;
  length: number | string | undefined;
  width: number | string | undefined;
  height: number | string | undefined;
  totalWeight: number | string | undefined;
  freightClass: number | string | undefined;
  refNumber: number | string | undefined;
  isStack: boolean | string | undefined;
  itemClassForDayross: number | undefined;
  description: string | string | undefined;
}

export const classOptions = [
  {
    label: 'LTL',
    value: 'ltl',
  },
  {
    label: 'FTL',
    value: 'ftl',
  },
];

// export const equipmentTypeOptions = [
//   { label: 'Dryvan', value: 'dryvan' },
//   { label: 'Flatbed', value: 'flatbed' },
//   { label: 'Step-Deck', value: 'step-deck' },
//   { label: 'Reefer', value: 'reefer' },
//   { label: 'Straight Truck', value: 'straight-truck' },
//   { label: 'Roll-tite', value: 'roll-tite' },
//   { label: 'RGN/Lowboy', value: 'rgn-lowboy' },
// ];

export const initAction = {
  mode: null,
  shipper: false,
  consignee: false,
  type: null,
  status: null,
};

export const scriptId = 'google-maps-api';

// export const orderSchema = Yup.object({
//   shipper: Yup.object({
//     companyName: Yup.string()
//       .required('Company name is required')
//       .min(2, 'Company name should be at least 2 characters'),
//     contactName: Yup.string()
//       .required('Contact name is required')
//       .min(2, 'Contact name should be at least 2 characters'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     email: Yup.string()
//       .required('Email is required')
//       .email('Invalid email format'),
//   }),
//   consignee: Yup.object({
//     companyName: Yup.string()
//       .required('Company name is required')
//       .min(2, 'Company name should be at least 2 characters'),
//     contactName: Yup.string()
//       .required('Contact name is required')
//       .min(2, 'Contact name should be at least 2 characters'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     email: Yup.string()
//       .required('Email is required')
//       .email('Invalid email format'),
//   }),
//   orderType: Yup.string()
//     .required('Order type is required')
//     .oneOf(['ltl', 'ftl'], 'Invalid order type'),
//   equipmentType: Yup.string()
//     .required('Equipment type is required')
//     .oneOf(['dryvan', 'flatbed', 'reefer'], 'Invalid equipment type'),
//   poNumber: Yup.string().required('PO number is required'),
//   // .matches(
//   //   /^[a-zA-Z0-9]+$/,
//   //   'PO number should contain only alphanumeric characters'
//   // ),
//   refNumber: Yup.string().required('Reference number is required'),
//   linearFootage: Yup.number()
//     .required('Linear footage is required')
//     .min(1, 'Linear footage must be at least 1')
//     .max(100, 'Linear footage cannot exceed 100'),
//   declaredValue: Yup.number()
//     .required('Declared value is required')
//     .min(0, 'Declared value cannot be negative'),
//   specialNotes: Yup.string()
//     .max(500, 'Special notes cannot exceed 500 characters')
//     .nullable(),

//   order_dimensions: Yup.array().of(
//     Yup.object().shape({
//       handlingUnit: Yup.number()
//         .nullable()
//         .required('Handling unit is required'),
//       handlingUnitNo: Yup.number()
//         .nullable()
//         .required('Handling unit number is required'),
//       weight: Yup.number().nullable().required('Weight is required'),
//       length: Yup.number().nullable().required('Length is required'),
//       width: Yup.number().nullable().required('Width is required'),
//       height: Yup.number().nullable().required('Height is required'),
//       totalWeight: Yup.number().nullable().required('Total weight is required'),
//       freightClass: Yup.number()
//         .nullable()
//         .required('Freight class is required'),
//       refNumber: Yup.number()
//         .nullable()
//         .required('Reference number is required'),
//       isStack: Yup.boolean().nullable().required('IsStack is required'),
//     })
//   ),
// });

export const orderSchema = Yup.object({
  shipper: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    shipperAddress: Yup.object({
      fullAddress: Yup.string().required('Shipper address is required'),
    }),
  }),
  consignee: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    consigneeAddress: Yup.object({
      fullAddress: Yup.string().required('Consignee address is required'),
    }),
  }),
});

export const step1Schema = Yup.object({
  orderType: Yup.string()
    .required('Order type is required')
    .oneOf(['ltl', 'ftl'], 'Invalid order type'),
  equipmentType: Yup.string().required('Equipment type is required'),

  shipper: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    shipperAddress: Yup.object({
      fullAddress: Yup.string().required('Shipper address is required'),
    }),
  }),
  consignee: Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters'),
    contactName: Yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name should be at least 2 characters'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    consigneeAddress: Yup.object({
      fullAddress: Yup.string().required('Consignee address is required'),
    }),
  }),
});

export const step3Schema = Yup.object({
  poNumber: Yup.string().nullable(),
  // .required('PO number is required')
  refNumber: Yup.string().nullable(),
  // .required('Reference number is required')
  linearFootage: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  // .required('Linear footage is required')
  // .min(1, 'Linear footage must be at least 1')
  // .max(100, 'Linear footage cannot exceed 100'),
  totalWeight: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  declaredValue: Yup.string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable(),
  // .required('Declared value is required')
  // .min(0, 'Declared value cannot be negative')
  specialNotes: Yup.string()
    .max(500, 'Special notes cannot exceed 500 characters')
    .nullable(),
  // order_dimensions: Yup.array().of(
  //   Yup.object().shape({
  //     handlingUnit: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable(),
  //     handlingUnitNo: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable()
  //       .when(['length', 'width', 'height', 'totalWeight', 'refNumber'], {
  //         is: (
  //           length: any,
  //           width: any,
  //           height: any,
  //           totalWeight: any,
  //           refNumber: any
  //         ) => length || width || height || totalWeight || refNumber,
  //         then: (schema) =>
  //           schema
  //             .required('Handling unit number is required')
  //             .min(1, 'Handling unit must be at least 1'),
  //         otherwise: (schema) => schema,
  //       }),
  //     length: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable()
  //       .min(1, 'Length must be at least 1'),
  //     width: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable()
  //       .min(1, 'Width must be at least 1'),
  //     height: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable()
  //       .min(1, 'Height must be at least 1'),
  //     totalWeight: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable()
  //       .min(1, 'Total weight must be at least 1'),
  //     freightClass: Yup.number()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable(),
  //     refNumber: Yup.string()
  //       .transform((value, originalValue) =>
  //         originalValue === '' ? null : value
  //       )
  //       .nullable(),
  //     isStack: Yup.boolean().nullable(),
  //   })
  // ),
  order_dimensions: Yup.array().of(
    Yup.object().shape({
      handlingUnit: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(), // Ignored in validation

      handlingUnitNo: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { length, width, height, totalWeight, refNumber } =
              this.parent;

            if (length || width || height || totalWeight || refNumber) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      length: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Length must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, width, height, totalWeight, refNumber } =
              this.parent;

            if (handlingUnitNo || width || height || totalWeight || refNumber) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      width: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Width must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, length, height, totalWeight, refNumber } =
              this.parent;

            if (
              handlingUnitNo ||
              length ||
              height ||
              totalWeight ||
              refNumber
            ) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      height: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Height must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, length, width, totalWeight, refNumber } =
              this.parent;

            if (handlingUnitNo || length || width || totalWeight || refNumber) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      totalWeight: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable()
        .min(1, 'Total weight must be at least 1')
        .test(
          'conditional-required',
          'If any field is entered, all fields are required',
          function (value) {
            const { handlingUnitNo, length, width, height, refNumber } =
              this.parent;

            if (handlingUnitNo || length || width || height || refNumber) {
              return value !== null && value !== undefined;
            }

            return true;
          }
        ),

      freightClass: Yup.number()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),
      refNumber: Yup.string()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value
        )
        .nullable(),
      isStack: Yup.boolean().nullable(), // Ignored in validation
    })
  ),
});

export const step4Schema = Yup.object().shape({
  salesPrice: Yup.array()
    .required('Sale Price is required')
    .min(1, 'Sale Price is required')
    .test(
      'is-not-empty',
      'Sale Price cannot be empty',
      (value) => value && value.length > 0
    ),
});

export const dimensionObj: IDimensionProps = {
  handlingUnit: undefined,
  handlingUnitNo: undefined,
  // weight: undefined,
  length: undefined,
  width: undefined,
  height: undefined,
  totalWeight: undefined,
  freightClass: undefined,
  refNumber: undefined,
  isStack: true,
  itemClassForDayross: 0,
  description: undefined,
};

export const defualtOrderValue = {
  shipper: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
    email: '',
    shipperAddress: initShipperAddress,
  },
  consignee: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
    email: '',
    consigneeAddress: initShipperAddress,
  },
  orderType: 'ltl',
  equipmentTypeFullName: 'Dry Van',
  equipmentType: 'V',
  poNumber: '',
  refNumber: '',
  linearFootage: '',
  totalWeight: '',
  declaredValue: '',
  specialNotes: '',
  declaredValueUnit: 'CAD',
  order_dimensions: [dimensionObj],
  additionalServices: null,
  carrierPrice: [],
  salesPrice: [],
  weightMeasurement: MEASUREMENT.WEIGHT1.value,
  lengthMeasurement: MEASUREMENT.LENGTH1.value,
};

export const processOrderData = (order: any) => {
  let salesPrice: any = order.salesPrice;
  let carrierPrice: any = [];
  let documents: any = [];
  let claim: any = [];
  let dispute: any = [];

  if (order?.carrierPrice && order?.carrierPrice?.length > 0) {
    carrierPrice = order.carrierPrice.map((carrier: any) => {
      const additionalCharge = carrier.additional_charge
        ? JSON.parse(carrier.additional_charge)
        : [];

      const carrierData = carrier.carrier ? JSON.parse(carrier.carrier) : [];

      return {
        ...carrier,
        additional_charge: additionalCharge,
        carrier: carrierData,
      };
    });
  }

  if (order?.documents) {
    documents = Array.isArray(order?.documents)
      ? order?.documents
      : JSON.parse(order?.documents);
  }

  if (order?.claim) {
    claim = Array.isArray(order?.claim)
      ? order?.claim
      : JSON.parse(order?.claim);
  }

  if (order?.dispute) {
    dispute = Array.isArray(order?.dispute)
      ? order?.dispute
      : JSON.parse(order?.dispute);
  }

  return {
    shipper: {
      companyName: order?.shipperCompanyName || '',
      id: order?.shipperId || null,
      contactName: order?.shipperContactName || '',
      phoneNumber: order?.shipperContactNumber || '',
      email: order?.shipperEmail || '',
      shipperAddress: {
        fullAddress: order?.shipperFullAddress || '',
        address1: order?.shipperAddress1 || '',
        city: order?.shipperCity || '',
        state: order?.shipperState || '',
        country: order?.shipperCountry || '',
        postal: order?.shipperPostal || '',
        latitude: order?.shipperLatitude || '',
        longitude: order?.shipperLongitude || '',
        stateCode: order?.shipperStateCode || '',
        countryCode: order?.shipperCountryCode || '',
      },
    },
    consignee: {
      id: order?.consigneeId || null,
      companyName: order?.consigneeCompanyName || '',
      contactName: order?.consigneeContactName || '',
      phoneNumber: order?.consigneeContactNumber || '',
      email: order?.consigneeEmail || '',
      consigneeAddress: {
        fullAddress: order?.consigneeFullAddress || '',
        address1: order?.consigneeAddress1 || '',
        city: order?.consigneeCity || '',
        state: order?.consigneeState || '',
        country: order?.consigneeCountry || '',
        postal: order?.consigneePostal || '',
        latitude: order?.consigneeLatitude || '',
        longitude: order?.consigneeLongitude || '',
        stateCode: order?.consigneeStateCode || '',
        countryCode: order?.consigneeCountryCode || '',
      },
    },
    orderType: order?.orderType || 'ltl',
    equipmentType: order?.equipmentType || 'V',
    equipmentTypeFullName: order?.equipmentTypeFullName || 'Dry Van',
    poNumber: order?.poNumber || '',
    refNumber: order?.refNumber || '',
    linearFootage: order?.linearFootage || undefined,
    totalWeight: order?.totalWeight || undefined,
    declaredValue: order?.declaredValue || undefined,
    specialNotes: order?.specialNotes || '',
    declaredValueUnit: order?.declaredValueUnit || 'CAD',
    order_dimensions:
      order?.order_dimensions?.length > 0
        ? order?.order_dimensions
        : [dimensionObj],
    additionalServices: order?.additionalServices || null,
    salesPrice,
    carrierPrice,
    carrierPickupAppointmentDatetime: order?.carrierPickupAppointmentDatetime,
    carrierPickupAppointmentStatus: order?.carrierPickupAppointmentStatus,
    carrierDeliveryAppointmentDatetime:
      order?.carrierDeliveryAppointmentDatetime,
    carrierDeliveryAppointmentStatus: order?.carrierDeliveryAppointmentStatus,
    shipperAppointmentDatetime: order?.shipperAppointmentDatetime,
    shipperAppointmentStatus: order?.shipperAppointmentStatus,
    deliveryAppointmentDatetime: order?.deliveryAppointmentDatetime,
    deliveryAppointmentStatus: order?.deliveryAppointmentStatus,
    id: order?.id ?? 0,
    orderId: order?.orderId ?? null,
    legId: order?.legId ?? null,
    orderStatus: order?.orderStatus,
    customerId: order?.customerId,
    cadRate: order?.cadRate,
    usdRate: order?.usdRate,
    customerName: order?.customerName,
    customerEmail: order?.customerEmail,
    customerImage: order?.customerImage,
    customerImageUrl: order?.customerImageUrl,
    claimId: order?.claimId,
    disputeId: order?.disputeId,
    createdAt: order?.createdAt,
    documents: documents,
    claim: claim[0] ? Object.assign({}, claim[0]) : {},
    dispute: dispute[0] ? Object.assign({}, dispute[0]) : {},
    salesRepEmail: order?.salesRepEmail,
    salesRepFirstName: order?.salesRepFirstName,
    salesRepId: order?.salesRepId,
    salesRepLastName: order?.salesRepLastName,
    legPosition: order?.legPosition,
    isLastLeg: order?.isLastLeg,
    expectedPickupTime: order?.expectedPickupTime,
    expectedEstimatedDeliveryTime: order?.expectedEstimatedDeliveryTime,
    weightMeasurement:
      order?.order_dimensions?.length > 0
        ? order?.order_dimensions?.[0]?.weightMeasurement
        : MEASUREMENT.WEIGHT1.value,
    lengthMeasurement:
      order?.order_dimensions?.length > 0
        ? order?.order_dimensions?.[0]?.lengthMeasurement
        : MEASUREMENT.LENGTH1.value,
  };
};
export const ordersTimeFilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
  {
    value: 'yearly',
    name: 'This Year',
  },
];
export interface IOrderProps {
  handleClose: () => void;
  onCreate?: () => void;
}

export const handleInputChange = (e: any, type: string, onChange: any) => {
  const { target } = e;
  let { value: currentValue } = target;

  let newValue = currentValue;

  if (type === 'blur' && !currentValue) {
    newValue = 0;
  } else {
    newValue = currentValue;

    if (!newValue.includes('.')) {
      newValue = Number(newValue);
    }
  }

  if (type === 'blur') {
    newValue = parseFloat(newValue).toFixed(2);
  }

  onChange(newValue);
};

export interface IOrderForm {
  customerId: number;
}
