import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, onError } from 'src/utils/CommonFunctions';

const CarrierRow = ({ data, handleActionType }: any) => (
  <>
    <td
      className={`px-5 py-4  min-w-96 w-96 max-w-96`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <div className="flex items-center truncate">
        <div className="table-profile-wrap notification-mark-wrap">
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="table-profile-icn-circle-xl rounded-md"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl rounded-md">
              {getShortName(`${data.name}`)}
            </div>
          )}
          {data.hasUnreadNotification ? (
            <span className={`notification-dot`}></span>
          ) : (
            ''
          )}
        </div>

        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data.name}
          </h6>
        </div>
      </div>
    </td>
    <td
      className={`px-5 py-4 min-w-56 w-56 max-w-56`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <span className="flex gap-1">
        {data.isApiConnection ? (
          <BadgeCmp style="modern" type="success">
            API
          </BadgeCmp>
        ) : (
          ''
        )}
        {data.isSpotQuote ? (
          <BadgeCmp style="modern" type="primary">
            Spot
          </BadgeCmp>
        ) : (
          ''
        )}
      </span>
    </td>

    <td
      className={`px-5 py-4`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <span className="">{data.mcId}</span>
    </td>
    <td
      className={`px-5 py-4`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <span className="">{data.rateCount}</span>
    </td>
    <td
      className={`px-5 py-4`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <span className="">{data.quoteCount}</span>
    </td>
    {/* <td
      className={`px-5 py-4`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <span className="">{data?.lastOrder}</span>
    </td>
    <td
      className={`px-5 py-4`}
      onClick={handleActionType('carrier', 'viewCarrier', data)}
    >
      <span className="">{data?.orderCount}</span>
    </td> */}

    {/* <td className="px-5 py-4">
                                                    <span className="">{data.email}</span>
                                                </td> */}
    <td className={`px-5 py-4 w-[61px] max-w-[61px] min-w-[61px]`}>
      <TooltipCmp message="Edit">
        <Edit01
          className="w-4 h-4 cursor-pointer flex items-center font-semibold flex-none"
          onClick={handleActionType('carrier', 'edit', data)}
        />
      </TooltipCmp>
    </td>
  </>
);

export default CarrierRow;
