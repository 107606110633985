import {
  Check,
  ClockFastForward,
  Edit01,
  Lock02,
  Mail01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import { DATE_FORMAT, USER_ROLE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';

import BadgeCmp from '../../../../components/BadgeCmp';
import TooltipCmp from '../../../../components/TooltipCmp';
import {
  getShortName,
  onError,
  useRolePermission,
} from '../../../../utils/CommonFunctions';

interface IProps {
  data: any;
  handleActionType: (action: any, mode?: any, team?: any) => () => void;
  resentEmailInvitation: any;
}

const MemberRow = ({
  data,
  handleActionType,
  resentEmailInvitation,
}: IProps) => {
  const { currentUser } = useContext(AuthContext);
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const getUserAccountTypeColor = (accountType: string) => {
    if (accountType === USER_ROLE.ADMIN) {
      return 'primary';
    } else if (accountType === USER_ROLE.MANAGER) {
      return 'success';
    } else if (accountType === USER_ROLE.SALES) {
      return 'pink';
    } else if (accountType === USER_ROLE.OPERATIONS) {
      return 'lightblue';
    } else if (accountType === USER_ROLE.CLAIMS) {
      return 'error';
    } else if (accountType === USER_ROLE.FINANCE) {
      return 'warning';
    }
  };

  const roles =
    typeof data?.roles === 'string' ? JSON.parse(data?.roles) : data?.roles;

  let updateRolePermissionCondition = true;

  if (hasRoleV2(USER_ROLE.MANAGER)) {
    const hasAdminOrManager = roles?.some(
      (role: any) =>
        role.roleName === USER_ROLE.ADMIN || role.roleName === USER_ROLE.MANAGER
    );

    if (hasAdminOrManager) {
      updateRolePermissionCondition = false;
    }
  }

  useEffect(() => {
    const calculateAndSetTimeKLeft = (interval?: any) => {
      if (!data?.invitationResentAt) {
        setIsButtonEnabled(true);
      } else {
        const now = moment.utc();
        const resentTime = moment.utc(data?.invitationResentAt);
        const duration = moment.duration(now.diff(resentTime));
        const secondsElapsed = duration.asSeconds();

        if (secondsElapsed >= 61) {
          setIsButtonEnabled(true);

          if (interval) {
            clearInterval(interval);
          }
        } else {
          setIsButtonEnabled(false);
          setTimeLeft(60 - Math.floor(secondsElapsed));
        }
      }
    };

    calculateAndSetTimeKLeft();
    const interval = setInterval(() => {
      calculateAndSetTimeKLeft(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <>
      <td
        className=" px-5 py-4 w-80 min-w-80 max-w-80"
        onClick={handleActionType('team_management', 'view_profile', data)}
      >
        <div className="flex items-center">
          <div className="table-profile-wrap notification-mark-wrap">
            {data.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={data.imageUrl + data.image}
                alt={data.firstName}
                title={data.firstName}
                onError={onError}
              />
            ) : (
              <div className="table-profile-user-circle-xl">
                {getShortName(`${data.firstName} ${data.lastName}`)}
              </div>
            )}

            {data?.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>
          <div className="ml-3 max-w-40">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">{`${data.firstName} ${data.lastName}`}</h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">{`${data.email}`}</p>
          </div>
        </div>
      </td>

      <td className="px-5 py-4 w-56 max-w-56 min-w-56 ">
        <div className="flex">
          {data.isVerified && data.userId ? (
            <BadgeCmp style="pill" type="success">
              <Check className="mr-1 h-3 w-3" /> Active
            </BadgeCmp>
          ) : (
            <BadgeCmp style="pill" type="warning">
              <ClockFastForward className="mr-1 h-3 w-3 text-lg" /> Pending
            </BadgeCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 w-56 max-w-56 min-w-56 ">
        <span>
          {data.lastLoginAt
            ? moment(data.lastLoginAt).format(DATE_FORMAT)
            : '-'}
        </span>
      </td>
      <td className="px-5 py-4 w-[26rem] max-w-[26rem] min-w-[26rem]">
        <div className="flex">
          {roles && roles.length > 0 ? (
            roles?.map((role: any, index: number) => (
              <BadgeCmp
                style="modern"
                type={getUserAccountTypeColor(role.roleName)}
                key={index}
                mainClassName="me-2"
              >
                {role ? role.displayName : '-'}
              </BadgeCmp>
            ))
          ) : data?.role ? (
            <BadgeCmp
              style="modern"
              type={getUserAccountTypeColor(data.role.roleName)}
            >
              {data.role ? data.role.displayName : '-'}
            </BadgeCmp>
          ) : (
            '-'
          )}
        </div>
      </td>
      <td className="px-5 py-4 ">
        <div className="items-center flex gap-4 justify-end">
          {hasPermissionV2('team_member_update') &&
            updateRolePermissionCondition &&
            data?.id !== currentUser?.id &&
            !data?.isVerified && (
              <ActionTooltip
                message={
                  isButtonEnabled
                    ? 'Resend Email Invitation'
                    : `Resend email invitation after ${timeLeft} seconds`
                }
                isOpenChildTopPosOnTop={true}
                tooltipPosition="center"
                parentClassName="min-w-[180px]"
                openOnHover={true}
              >
                <Mail01
                  className={`w-4 h-4 relative ${
                    isButtonEnabled ? 'cursor-pointer' : 'opacity-50'
                  }`}
                  onClick={() => {
                    if (isButtonEnabled) {
                      resentEmailInvitation(data);
                      setIsButtonEnabled(false);
                    }

                    return;
                  }}
                />
              </ActionTooltip>
            )}
          {hasPermissionV2('team_member_update') &&
            updateRolePermissionCondition &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Change Permission'}>
                <Lock02
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'access-control', data)}
                />
              </TooltipCmp>
            )}
          {hasPermissionV2('team_member_delete') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Delete Member'}>
                <Trash01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'user-delete', data)}
                />
              </TooltipCmp>
            )}
          {hasPermissionV2('team_member_update') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Edit Member'}>
                <Edit01
                  className="w-4 h-4 relative cursor-pointer "
                  onClick={handleActionType('team', 'user-update', data)}
                />
              </TooltipCmp>
            )}
        </div>
      </td>
    </>
  );
};

export default MemberRow;
