import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

// import defaultImage from '../../assets/img/default-image.jpg';

const QuotingSplitsLoadingRow = () => (
  <>
    <td className={`px-5 py-4  min-w-96 w-96 max-w-96`}>
      <div className="flex items-center truncate">
        <div className="table-profile-wrap notification-mark-wrap">
          <div className="flex items-center gap-3">
            <div className="table-profile-customer-circle-xl custom-loading">
              J D
            </div>
          </div>
        </div>

        <div className="pl-3 w-[calc(100%_-_58px)]">
          <span className="truncate block max-w-28 text-grayLight900 font-semibold leading-5 custom-loading">
            Culpa consectetur do laborum
          </span>
        </div>
      </div>
    </td>

    <td className={`px-5 py-4 `}>
      <span className="custom-loading">22</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="custom-loading">22</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="custom-loading">22</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="custom-loading">25</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="custom-loading">23</span>
    </td>

    <td className={`px-5 py-4`}>
      <span className="custom-loading">22</span>
    </td>
  </>
);

export default QuotingSplitsLoadingRow;
