import { Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

const carrierRow = ({ data, handleCarrierEdit, handleCarrierDelete }: any) => {
  let carrier = data?.carrier;

  const additionalChargesTotal = Array.isArray(data?.additional_charge)
    ? data?.additional_charge.reduce(
        (total: number, item: any) => total + (item.price || 0),
        0
      )
    : 0;

  return (
    <>
      <td className="px-5 py-4 w-48 max-w-48 min-w-48">
        <div className="flex items-center gap-3">
          <div className="table-profile-wrap">
            {carrier?.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={`${carrier?.image}`}
              />
            ) : (
              <div className="table-profile-user-circle-xl">
                {getShortName(carrier?.name ?? 'Unassigned')}
              </div>
            )}
          </div>
          <div className="truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {carrier?.name ?? 'Unassigned'}
            </h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              #WQ085020145
            </p>
          </div>
        </div>
      </td>
      {/* <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>{carrier?.isPrivateCarrier ? 'Private' : 'Common'}</span>
      </td> */}
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>{data?.budgetCost ? `$${data?.budgetCost}` : '-'}</span>
      </td>
      <td className="px-5 py-4 w-[160px] max-w-[160px] min-w-[160px]">
        <span
          dangerouslySetInnerHTML={{
            __html: data?.carrierBookingDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data?.carrierBookingDate,
                    `Do MMMM YYYY`,
                    false,
                    false
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>{data?.totalPrice ? `$${data?.totalPrice}` : '-'}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>{data?.baseCarrierRate ? `$${data?.baseCarrierRate}` : '-'}</span>
      </td>
      <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
        <span>
          {additionalChargesTotal ? `$${additionalChargesTotal}` : '-'}
        </span>
      </td>

      <td className="px-5 py-4 w-[80px] min-w-[80px] max-w-[80px]">
        <div className="justify-end items-center gap-2 flex ">
          <Edit01
            className="w-4 h-4 relative cursor-pointer"
            onClick={() => {
              handleCarrierEdit(data);
            }}
          />
          <Trash01
            className="w-4 h-4 relative cursor-pointer"
            onClick={() => {
              handleCarrierDelete();
            }}
          />
        </div>
      </td>
    </>
  );
};

export default carrierRow;
