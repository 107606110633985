import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import TooltipCmp from 'src/components/TooltipCmp';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import {
  capitalizeFirstLetter,
  formatBigNumberToAbbreviation,
  getShortName,
  onError,
  useRolePermission,
} from 'src/utils/CommonFunctions';
// import Streaks from '../../assets/img/streaks.svg';

const CustomerRow = ({
  data,
  openCustomerPage,
  selectedCustomersForSalesRepReassign,
  setSelectedCustomersForSalesRepReassign,
}: any) => {
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const newSegmentArray = data?.new_segment
    ?.split(',')
    ?.map((item: string) => item.trim());
  const customerGroupDetailsArr = JSON.parse(data?.customerGroupDetails);
  const [isShowCustomerGroupListDropdown, setIsShowCustomerGroupListDropdown] =
    useState(false);

  const renderSegment = (newSegment: any, index: number) => {
    if (index > 1) {
      const otherSegments = newSegmentArray
        ?.filter((segment: any, i: number) => i > 1)
        .map((segment: string) => capitalizeFirstLetter(segment));

      return (
        <TooltipCmp message={otherSegments.join(', ')}>
          <BadgeCmp
            key={index}
            style="modern"
            type="gray"
            mainClassName=""
            isHidePillDot
          >
            +{newSegmentArray?.length - 2}
          </BadgeCmp>
        </TooltipCmp>
      );
    } else if (newSegment === CUSTOMER_SEGMENT_TYPES.TEST) {
      return (
        <BadgeCmp
          key={index}
          style="modern"
          badgeDotColor="bg-yellow300"
          mainClassName=""
        >
          {CUSTOMER_SEGMENT_TYPES.TEST}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.TOP) {
      return (
        <BadgeCmp
          key={index}
          style="modern"
          badgeDotColor="bg-[#B2AC17]"
          mainClassName=""
        >
          {CUSTOMER_SEGMENT_TYPES.TOP}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.DORMANT) {
      return (
        <BadgeCmp
          key={index}
          style="modern"
          badgeDotColor="bg-utilityPink600"
          mainClassName=""
        >
          {CUSTOMER_SEGMENT_TYPES.DORMANT}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.PROSPECT) {
      return (
        <BadgeCmp key={index} style="modern" type="lightblue" mainClassName="">
          {CUSTOMER_SEGMENT_TYPES.PROSPECT}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.NEW) {
      return (
        <BadgeCmp
          key={index}
          style="modern"
          badgeDotColor="bg-fgSuccessPrimary"
          mainClassName=""
        >
          {CUSTOMER_SEGMENT_TYPES.NEW}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.ACTIVE) {
      return (
        <BadgeCmp key={index} style="modern" type="primary" mainClassName="">
          {CUSTOMER_SEGMENT_TYPES.ACTIVE}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.INACTIVE) {
      return (
        <BadgeCmp key={index} style="modern" type="error" mainClassName="">
          {CUSTOMER_SEGMENT_TYPES.INACTIVE}
        </BadgeCmp>
      );
    } else if (newSegment == CUSTOMER_SEGMENT_TYPES.AT_RISK) {
      return (
        <BadgeCmp key={index} style="modern" type="gray" mainClassName="">
          {CUSTOMER_SEGMENT_TYPES.AT_RISK}
        </BadgeCmp>
      );
    }
  };

  return (
    <>
      <td
        className={`px-5 py-4 min-w-56 max-w-56 w-56`}
        onClick={() => openCustomerPage(data)}
      >
        <div className="flex items-center gap-3">
          {data.image ? (
            <div className="table-profile-wrap notification-mark-wrap">
              <img
                className="table-profile-icn-circle-xl"
                src={data.imageUrl + data.image}
                alt=""
                title=""
                onError={onError}
              />
              {data.hasUnreadNotification ? (
                <span className={`notification-dot`}></span>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="table-profile-wrap notification-mark-wrap">
              <div className="table-profile-customer-circle-xl">
                {getShortName(`${data.name}`)}
              </div>
              {data.hasUnreadNotification ? (
                <span className={`notification-dot`}></span>
              ) : (
                ''
              )}
            </div>
          )}

          {data.name.length > 40 ? (
            <div className="flex items-center gap-1">
              <TooltipCmp message={data.name} parentClassName="">
                <span className="truncate block xxl:max-w-52 xlm:max-w-44 max-w-40 text-grayLight900 font-semibold leading-5">
                  {data.name}
                </span>
              </TooltipCmp>
              {/* {data?.isStreak ? <img src={Streaks} /> : false} */}
              {/* <img src={getRankDiamond(data?.order_count)} /> */}
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <span className="truncate block xxl:max-w-52 xlm:max-w-44 max-w-40 text-grayLight900 font-semibold leading-5">
                {data.name}
              </span>
              {/* {data?.isStreak ? <img src={Streaks} /> : false} */}
              {/* <img src={getRankDiamond(data?.order_count)} /> */}
            </div>
          )}
        </div>
      </td>

      <td
        className={`px-5 py-3 min-w-44 max-w-44 w-44`}
        onClick={() => openCustomerPage(data)}
      >
        <div className="flex gap-1">
          {newSegmentArray
            ?.filter((newSegment: string, index: number) => index <= 2)
            .map((newSegment: string, index: number) =>
              renderSegment(newSegment, index)
            )}
        </div>
      </td>

      <td
        className={`px-5 py-3 min-w-44 max-w-44 w-44 `}
        onClick={() => openCustomerPage(data)}
      >
        {data.orderCommissionees?.split(',').length === 1 &&
        data.orderCommissioneeFirstName &&
        data.orderCommissioneeLastName ? (
          <div className="flex items-center min-w-92">
            {data.orderCommissioneeImage ? (
              <img
                className="table-profile-icn-circle-sm"
                src={
                  data.orderCommissioneeImageUrl + data.orderCommissioneeImage
                }
                alt={data.orderCommissioneeFirstName}
                title={data.orderCommissioneeFirstName}
                onError={onError}
              />
            ) : (
              <div className="table-profile-user-circle-sm">
                <span>
                  {getShortName(
                    `${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`
                  )}
                </span>
              </div>
            )}
            <div className="pl-2">
              {(
                data.orderCommissioneeFirstName + data.orderCommissioneeLastName
              ).length > 30 ? (
                <div className="flex items-center gap-1">
                  <TooltipCmp
                    message={`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}
                    parentClassName=""
                  >
                    <h6 className="text-grayLight900 font-medium leading-tight max-w-32 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
                  </TooltipCmp>
                </div>
              ) : (
                <h6 className="text-grayLight900 font-medium leading-tight max-w-32 truncate">{`${data.orderCommissioneeFirstName} ${data.orderCommissioneeLastName}`}</h6>
              )}
            </div>
          </div>
        ) : data.orderCommissionees ? (
          <div className="font-semibold leading-tight cursor-pointer flex gap-1">
            <p className="max-w-52 truncate">{data.orderCommissionees}</p>
            <TooltipCmp
              message={`${data.orderCommissionees} is not an active user on WALHQ`}
            >
              <InfoCircle className="h-4 w-4" />
            </TooltipCmp>
          </div>
        ) : (
          <div className="flex items-center min-w-92">
            <div className="table-profile-empty-circle-sm"></div>

            <div className="pl-2">
              <h6 className="text-grayLight900 font-medium leading-tight max-w-32 truncate">
                Unassigned
              </h6>
            </div>
          </div>
        )}
      </td>

      <td className={`px-5 py-3 min-w-44 max-w-44 w-44`}>
        <OutsideClickHandler
          onOutsideClick={() => setIsShowCustomerGroupListDropdown(false)}
          containerClassName="relative cursor-pointer"
        >
          <div
            className="flex gap-x-1.5 items-center"
            onClick={(e: any) => {
              e?.stopPropagation();
              setIsShowCustomerGroupListDropdown((prev) => !prev);
            }}
          >
            <span
              className="truncate"
              onClick={() => {
                navigate(
                  `${PATH.CUSTOMER_GROUP_DETAILS}/${customerGroupDetailsArr?.[0]?.id}`
                );
              }}
            >
              {customerGroupDetailsArr?.[0]?.groupName}
            </span>
            {customerGroupDetailsArr?.length > 1 && (
              <div className="rounded-full border border-utilityGray200 bg-gray50 px-1.5 py-0.5 text-textSecondary text-xs font-medium">
                +{customerGroupDetailsArr?.length - 1}
              </div>
            )}
          </div>
          <ul
            className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium mt-1.5 z-[1] w-[150px] left-0 absolute ${
              isShowCustomerGroupListDropdown ? '' : 'hidden'
            }`}
          >
            {customerGroupDetailsArr?.map((cusGroupDetail: any) => (
              <li
                className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer truncate`}
                key={cusGroupDetail?.id}
                onClick={() => {
                  navigate(
                    `${PATH.CUSTOMER_GROUP_DETAILS}/${cusGroupDetail?.id}`
                  );
                }}
              >
                {cusGroupDetail?.groupName}
              </li>
            ))}
          </ul>
        </OutsideClickHandler>
      </td>

      <td
        className={`px-5 py-3 font-normal min-w-44 max-w-44 w-44`}
        onClick={() => openCustomerPage(data)}
      >
        <span>
          ${data.totalGP ? formatBigNumberToAbbreviation(data.totalGP) : 0}
        </span>
      </td>

      <td
        className={`px-5 py-3 font-normal min-w-44 max-w-44 w-44`}
        onClick={() => openCustomerPage(data)}
      >
        <span>
          {data.last_ordered &&
            moment(data.last_ordered).format('MMM DD, YYYY')}
        </span>
      </td>

      {hasRoleV2('admin') && (
        <td className={`px-5 py-4 min-w-16 max-w-16`}>
          <CheckBox
            checked={selectedCustomersForSalesRepReassign.some(
              (cusData: any) => cusData.id === data.id
            )}
            onChangeFunc={(e: any) => {
              e.stopPropagation();

              if (e.target.checked) {
                setSelectedCustomersForSalesRepReassign([
                  ...selectedCustomersForSalesRepReassign,
                  data,
                ]);
              } else {
                setSelectedCustomersForSalesRepReassign(
                  selectedCustomersForSalesRepReassign.filter(
                    (cData: any) => cData.id !== data.id
                  )
                );
              }
            }}
            parentClassName=""
          />
        </td>
      )}
    </>
  );
};
export default CustomerRow;
