import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useMemo } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import TableCmp from 'src/components/TableCmp';

import { OrderContext } from '..';
import { filterServicesByType } from '../../order.constant';

import dimensionsOrderDetailRow from './dimensionsOrderDetailRow';
import OrderDetailsRow from './OrderDetailsRow';

const OrderDetails = () => {
  const {
    currentLegData,
    isMasterOrder,
    isOrderHasLeg,
    isLoading,
    order,
    handlingUnitOptions,
    serviceList,
  } = useContext<any>(OrderContext);

  const headCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
        rowClassName: '',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
        rowClassName: '',
      },
      {
        id: 'dimensions',
        name: 'Dimensions',
        rowClassName: '',
      },
      {
        id: 'class',
        name: 'Class',
        rowClassName: '',
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        rowClassName: '',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
        rowClassName: '',
      },
      {
        id: 'description',
        name: 'Description',
        rowClassName: '',
      },
      {
        id: 'action',
        name: 'Action',
        visible: isOrderHasLeg,
      },
    ],
    [isOrderHasLeg]
  );

  const orderHeadCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
      },
      {
        id: 'legId',
        name: 'Leg ID',
      },
      {
        id: 'pickup',
        name: 'Pickup',
      },
      {
        id: 'dropoff',
        name: 'Dropoff',
      },
      {
        id: 'totalWeight',
        name: 'Total weight (lbs)',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
      },
    ],
    []
  );

  const otherAddtionalService = filterServicesByType(
    serviceList,
    currentLegData?.additionalServices?.serviceId,
    'OTHER'
  );

  const hasOrderDimensions =
    currentLegData?.order_dimensions?.length > 0 &&
    currentLegData?.order_dimensions[0]?.id;

  return (
    <>
      {(!isMasterOrder && isOrderHasLeg) ||
      (isMasterOrder && !isOrderHasLeg) ? (
        <li>
          <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
            Order Details
          </h6>
          <AccordionCmp
            title={
              <div className="flex flex-wrap items-start flex-1 gap-x-3">
                <div className="flex-1">
                  <h6 className="text-grayLight600 text-xs font-normal mb-1">
                    Order Type
                  </h6>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName={`!flex w-fit ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {currentLegData?.orderType === 'ltl' ? 'LTL' : 'FTL'}
                  </BadgeCmp>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight600 text-xs font-normal mb-1">
                    Equipment Type
                  </h6>
                  <p
                    className={`text-textSecondary text-sm font-medium ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {!isLoading
                      ? currentLegData?.equipmentTypeFullName &&
                        currentLegData?.equipmentTypeFullName != ''
                        ? currentLegData?.equipmentTypeFullName
                        : '-'
                      : '13123556'}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight600 text-xs font-normal mb-1">
                    PO number
                  </h6>
                  <p
                    className={`text-textSecondary text-sm font-medium ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {!isLoading
                      ? currentLegData?.poNumber &&
                        currentLegData?.poNumber != ''
                        ? currentLegData?.poNumber
                        : '-'
                      : '13123556'}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight600 text-xs font-normal mb-1">
                    Reference Number
                  </h6>
                  <p
                    className={`text-textSecondary text-sm font-medium ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {!isLoading
                      ? currentLegData?.refNumber &&
                        currentLegData?.refNumber != ''
                        ? currentLegData?.refNumber
                        : '-'
                      : '123456'}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight600 text-xs font-normal mb-1">
                    Linear Footage
                  </h6>
                  <p
                    className={`text-textSecondary text-sm font-medium ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {!isLoading ? currentLegData?.linearFootage : '123456'}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-grayLight600 text-xs font-normal mb-1">
                    Declared Value
                  </h6>
                  <p
                    className={`text-textSecondary text-sm font-medium ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {!isLoading ? order?.declaredValue ?? '-' : '123456'}
                  </p>
                </div>
              </div>
            }
            className="!bg-gray25 !p-5 !rounded-xl !shadow-xs"
            contentClassName="!bg-gray25 flex flex-col gap-y-4"
            contentParentClass="!border-utilityGray200 !mt-4"
            AccordionIconClass={`${
              hasOrderDimensions ||
              (otherAddtionalService && otherAddtionalService?.length > 0)
                ? ''
                : 'hidden'
            }`}
            isClickable={
              hasOrderDimensions ||
              (otherAddtionalService && otherAddtionalService?.length > 0)
            }
          >
            {hasOrderDimensions && (
              <div>
                <h6 className="form_label block mb-1.5">Dimensions</h6>
                <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                    <TableCmp
                      headCells={headCells}
                      tableDataArr={currentLegData?.order_dimensions ?? []}
                      // TableLoaderRowCmp={CarriersListLoading}
                      TableRowCmp={dimensionsOrderDetailRow}
                      tableRowCmpProps={{
                        currentLegData,
                        isOrderHasLeg,
                        handlingUnitOptions,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {otherAddtionalService && otherAddtionalService?.length > 0 && (
              <div>
                <h6 className="form_label block mb-1.5">
                  Other Additional Services
                </h6>
                <div className="rounded-xl border border-utilityGray200 bg-white shadow-sm px-4 py-1.5">
                  <div className="flex flex-wrap -mx-5">
                    {otherAddtionalService?.map(
                      (service: any, index: number) => (
                        <div
                          className="flex gap-2 text-grayLight900 truncate px-5 py-2.5 w-1/4"
                          key={index}
                        >
                          <Check className="w-4 h-4 text-primary flex-none" />
                          <h6 className="truncate text-xs"> {service?.name}</h6>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </AccordionCmp>
        </li>
      ) : (
        <>
          {order?.order_dimensions?.length > 0 &&
            order?.order_dimensions?.[0]?.id && (
              <li>
                <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
                  Order Details
                </h6>
                <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                    <TableCmp
                      headCells={orderHeadCells}
                      tableDataArr={order?.order_dimensions ?? []}
                      TableRowCmp={OrderDetailsRow}
                      tableRowCmpProps={{ handlingUnitOptions }}
                      numberOfSkeletonRows={1}
                    />
                  </div>
                </div>
              </li>
            )}
        </>
      )}
    </>
  );
};

export default OrderDetails;
