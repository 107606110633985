import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import {
  allMonthCommissionReport,
  allMonthCommissionReportCount,
} from 'src/services/CommissionReportService';

import CommissionReportLoadingRow from './CommissionReportLoadingRow';
import CommissionReportRow from './CommissionReportRow';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'periodStartDate',
  page: 1,
  limit: 25,
  year: 'all',
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const yearArr = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
];

const AllMonthCommissionReport = () => {
  const navigate = useNavigate();
  const { setReportMonth, setReportYear } = useContext(BasicContext);
  const [params, setParams] = useState(initParams);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [periodData, setPeriodData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const headCells = useMemo(
    () => [
      {
        id: 'period',
        name: 'Period',
        sortable: true,
        visible: true,
      },
      {
        id: 'createdAt',
        name: 'Created Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'periodStartDate',
        name: 'Period Start Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'periodEndDate',
        name: 'Period End Date',
        sortable: true,
        visible: true,
      },
      {
        id: 'commissionedUser',
        name: 'Commissioned Users',
        sortable: true,
        visible: true,
      },
      {
        id: 'totalMargin',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'avgMargin',
        name: 'Average Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'loadsDelivered',
        name: 'Loads delivered',
        sortable: true,
        visible: true,
      },
      {
        id: 'marginPercentage',
        name: 'Margin Percentage',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setPeriodData([]);

    Promise.all([
      allMonthCommissionReport(params),
      allMonthCommissionReportCount(params),
    ])
      .then(([responseData, responseCount]: [any, any]) => {
        if (responseData?.data) {
          setPeriodData(responseData.data);
        }
        setTotal(responseCount.total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [params]);

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  const searchDebounce = useCallback(
    debounce(
      (search: string) =>
        setParams((old: any) => ({ ...old, ...{ search, page: 1 } })),
      700
    ),
    []
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Commission Reports"
            desc="View and manage your history of commission."
            isShowNotificationBox
          />
        }
      >
        <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">All Commission Reports</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {total} {total > 1 ? 'Reports' : 'Report'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Monthly summary of commission reports.
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchValue}
                inputType="text"
                isClearable={true}
                parentClassName="table-searchInput"
                onChangeFunc={(e) => {
                  setSearchValue(e.target.value);
                  searchDebounce(e.target.value);
                }}
              />
            </div>
            <div className="table-selectbox">
              <SelectBox
                name="teamListGroup"
                id="teamListGroup"
                className="form_control"
                size="sm"
                placeholder="All Reports"
                noOptionMessage="No Year Found"
                isClearable={true}
                isSearchable={true}
                options={yearArr}
                onChangeFunc={(event: any) =>
                  setParams((old: any) => ({
                    ...old,
                    year: event?.value ?? 'all',
                  }))
                }
                value={yearArr.filter((val: any) => params.year === val.value)}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                placeholder="10"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div
            data-test-id="userTest"
            className="h-full lg:min-h-[122px] w-full border-t border-gray100 "
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={periodData}
                TableLoaderRowCmp={CommissionReportLoadingRow}
                TableRowCmp={CommissionReportRow}
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={15}
                isTableRowClickable={true}
                onRowClick={(rowData: any) => {
                  const reportMonth = rowData?.month;
                  const reportYear = rowData?.year;

                  setReportMonth(reportMonth);
                  setReportYear(reportYear);
                  localStorage.setItem('reportMonth', reportMonth);
                  localStorage.setItem('reportYear', reportYear);

                  navigate(`${PATH.COMMISSION_REPORT}/${rowData?.id}`, {
                    state: { reportMonth, reportYear },
                  });
                }}
              />
            </div>
            {!periodData.length && !isLoading && (
              <NotFoundUI
                title="No Report Found"
                desc="There are no data for Report."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={total}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default AllMonthCommissionReport;
