import { ArrowUpRight, Copy06 } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import Map from 'src/components/Map/Map';
import TrimbleMap from 'src/components/TrimbleMap/Map';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { getShortName, onError, usePhone } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

// import Streaks from '../../../assets/img/streaks.svg';

import RankInfoModal from './RankInfoModal';
import StreakInfoModal from './StreakInfoModal';
import TypeInfoModal from './TypeInfoModal';

interface IProps {
  customerDetail: any;
  isCustomerDetailsLoading: boolean;
}

const CustomerDetailRightSideDetailSection = ({
  customerDetail,
  isCustomerDetailsLoading,
}: IProps) => {
  const { mapService } = useContext(BasicContext);
  const { getFormatPhoneWithPlus } = usePhone();

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );
  const [isShowRankInfoModal, setIsShowRankInfoModal] = useState(false);
  const [isShowStreakInfoModal, setIsShowStreakInfoModal] = useState(false);
  const [isShowTypeInfoModal, setIsShowTypeInfoModal] = useState(false);
  const customerGroupDetailsArr = customerDetail?.customerGroupDetails
    ? JSON.parse(customerDetail?.customerGroupDetails)
    : [];

  return (
    <>
      <div className="xl:w-[306px] xls:w-[280px]  w-full xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
        <ul className="flex xls:flex-col flex-row gap-y-4 flex-wrap pb-4 border-b border-utilityGray200">
          <li className="text-xs w-full">
            <p className="text-grayLight600 font-normal mb-1">
              Business Phone Number
            </p>
            <div
              className={`flex items-center ${
                isCustomerDetailsLoading ? 'custom-loading' : ''
              }`}
            >
              <a
                href={`tel:${getFormatPhoneWithPlus(customerDetail?.phone)}`}
                className="text-grayLight900 font-medium"
              >
                {getFormatPhoneWithPlus(customerDetail?.phone) ?? '-'}
              </a>
              {customerDetail?.phone && (
                <Copy06
                  className="w-3.5 h-3.5 ml-1 cursor-pointer text-primary500"
                  onClick={(event) => {
                    event.stopPropagation();
                    copyDebounce(getFormatPhoneWithPlus(customerDetail?.phone));
                  }}
                />
              )}
            </div>
          </li>
          <li className="text-xs">
            <p className="text-grayLight600 font-normal mb-1">Business Email</p>
            {customerDetail?.email ? (
              <div className="text-grayLight900 font-medium flex items-center">
                <a href={`mailto:${customerDetail?.email}`}>
                  {customerDetail?.email}
                </a>
                <Copy06
                  className="w-3.5 h-3.5 ml-1 cursor-pointer text-primary500"
                  onClick={(event) => {
                    event.stopPropagation();
                    copyDebounce(customerDetail?.email);
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  className={`text-grayLight900 font-medium flex items-center ${
                    isCustomerDetailsLoading ? 'custom-loading' : ''
                  }`}
                >
                  -
                </div>
              </>
            )}
          </li>
          <li className="text-xs">
            <p className="text-grayLight600 font-normal mb-1">
              Onboarding Date
            </p>
            <div
              className={`text-grayLight900 font-medium ${
                isCustomerDetailsLoading ? 'custom-loading' : ''
              }`}
            >
              {customerDetail?.createdAt
                ? moment(customerDetail?.createdAt).format('MMMM Do, YYYY')
                : '-'}
            </div>
          </li>
          <li className="text-xs">
            <p className="text-grayLight600 font-normal mb-1">
              Customer Website
            </p>
            <div
              className={`flex items-center ${
                isCustomerDetailsLoading ? 'custom-loading' : ''
              }`}
            >
              <a
                href={customerDetail?.website ?? false}
                className="text-primary700 font-semibold"
                target="_blank"
              >
                {customerDetail?.website ?? '-'}
              </a>
              {customerDetail?.website && (
                <ArrowUpRight className="w-3.5 h-4 ml-1 mt-0.5 cursor-pointer text-primary700" />
              )}
            </div>
          </li>
          <li className="text-xs">
            <p className="text-grayLight600 font-normal mb-1">Sales Rep.</p>
            <div
              className={`flex items-center gap-x-1 ${
                isCustomerDetailsLoading ? 'custom-loading' : ''
              }`}
            >
              {customerDetail?.orderCommissioneeFirstName &&
              customerDetail?.orderCommissioneeLastName ? (
                <>
                  <div className="rounded-full border-gray-200 border-[1px] flex-none overflow-hidden w-4 h-4">
                    {customerDetail.orderCommissioneeImage ? (
                      <img
                        src={
                          customerDetail.orderCommissioneeImageUrl +
                          customerDetail.orderCommissioneeImage
                        }
                        className="w-full h-full"
                        onError={onError}
                      />
                    ) : (
                      <div className="w-4 h-4 bg-primary text-[6px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none leading-[1]">
                        <>
                          {getShortName(
                            `${customerDetail.orderCommissioneeFirstName} ${customerDetail.orderCommissioneeLastName}`
                          )}
                        </>
                      </div>
                    )}
                  </div>
                  <h6 className="text-grayLight900 font-medium">
                    {`${customerDetail?.orderCommissioneeFirstName} ${customerDetail?.orderCommissioneeLastName}`}
                  </h6>
                </>
              ) : (
                'Unassigned'
              )}
            </div>
          </li>
          <li className="text-xs">
            {customerDetail?.about && (
              <>
                <p className="text-grayLight600 font-normal mb-1"> About</p>

                <div className="flex flex-col gap-4">
                  <p className="text-grayLight900 font-medium ">
                    {customerDetail?.about}
                  </p>
                </div>
              </>
            )}
          </li>
          {customerGroupDetailsArr.length > 0 && (
            <li className="text-xs">
              <p className="text-grayLight600 font-normal mb-1">Group</p>
              {customerGroupDetailsArr.map((cusGroupDetail: any) => (
                <div
                  className={`flex items-center cursor-pointer`}
                  key={`cus_group_${cusGroupDetail?.id}`}
                  onClick={() => {
                    window.open(
                      `${PATH.CUSTOMER_GROUP_DETAILS}/${cusGroupDetail?.id}`,
                      '_blank'
                    );
                  }}
                >
                  <div className="text-primary700 font-semibold">
                    {cusGroupDetail?.groupName}
                  </div>
                  <ArrowUpRight className="w-3.5 h-4 ml-1 cursor-pointer text-primary700" />
                </div>
              ))}
            </li>
          )}
        </ul>
        <ul className="py-4 border-b border-utilityGray200 flex flex-col gap-y-4">
          <li className="flex gap-1 flex-wrap items-center">
            <p className="text-grayLight600 text-xs font-normal">Type:</p>
            <div className="flex-1 flex justify-between items-center gap-3">
              <div
                className={`flex justify-between items-center ${
                  isCustomerDetailsLoading ? 'custom-loading' : ''
                }`}
              >
                {customerDetail?.newSegmentArray.length > 0 && (
                  <>
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.TEST
                    ) && (
                      <BadgeCmp
                        style="modern"
                        badgeDotColor="bg-yellow300"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.TEST}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.TOP
                    ) && (
                      <BadgeCmp
                        style="modern"
                        badgeDotColor="bg-[#b2ac17]"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.TOP}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.DORMANT
                    ) && (
                      <BadgeCmp
                        style="modern"
                        badgeDotColor="bg-utilityPink600"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.DORMANT}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.PROSPECT
                    ) && (
                      <BadgeCmp
                        style="modern"
                        type="lightblue"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.PROSPECT}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.NEW
                    ) && (
                      <BadgeCmp
                        style="modern"
                        badgeDotColor="bg-fgSuccessPrimary"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.NEW}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.ACTIVE
                    ) && (
                      <BadgeCmp
                        style="modern"
                        type="primary"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.ACTIVE}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.INACTIVE
                    ) && (
                      <BadgeCmp
                        style="modern"
                        type="error"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.INACTIVE}
                      </BadgeCmp>
                    )}
                    {customerDetail?.newSegmentArray?.includes(
                      CUSTOMER_SEGMENT_TYPES.AT_RISK
                    ) && (
                      <BadgeCmp
                        style="modern"
                        type="gray"
                        mainClassName=" mr-1"
                      >
                        {CUSTOMER_SEGMENT_TYPES.AT_RISK}
                      </BadgeCmp>
                    )}
                  </>
                )}
              </div>
              <ArrowUpRight
                className="w-3 h-3 text-primary cursor-pointer flex-none"
                // onClick={() => setIsShowTypeInfoModal(true)}
              />
            </div>
          </li>
          {/* <li className={`flex gap-1 flex-wrap items-center `}>
            <p className="text-grayLight600 text-xs font-normal">Rank:</p>
            <div
              className={`flex-1 flex justify-between items-center gap-3 ${
                isCustomerDetailsLoading ? 'custom-loading' : ''
              }`}
            >
              <div>
                <img src={getRankDiamond(customerDetail?.total_orders)} />
                -
              </div>
              <ArrowUpRight
                className="w-3 h-3 text-primary cursor-pointer"
                // onClick={() => setIsShowRankInfoModal(true)}
              />
            </div>
          </li> */}
          {/* <li className={`flex gap-1 flex-wrap items-center `}>
            <p className="text-grayLight600 text-xs font-normal">Streaks:</p>
            <div
              className={`flex-1 flex justify-between items-center gap-3 ${
                isCustomerDetailsLoading ? 'custom-loading' : ''
              }`}
            >
              <div>
                {customerDetail?.isStreak ? <img src={Streaks} /> : '-'}
                -
              </div>
              <ArrowUpRight
                className="w-3 h-3 text-primary cursor-pointer"
                // onClick={() => setIsShowStreakInfoModal(true)}
              />
            </div>
          </li> */}
        </ul>
        <ul className="pt-6">
          {(customerDetail?.latitude && customerDetail?.longitude) ||
          isCustomerDetailsLoading ? (
            <div className="mb-3">
              {isCustomerDetailsLoading ? (
                <div className="w-full h-[150px] rounded-lg custom-loading"></div>
              ) : (
                <>
                  {mapService === 'trimble_map' ? (
                    <TrimbleMap
                      className="rounded-lg bg-white"
                      address={
                        customerDetail?.latitude && customerDetail?.longitude
                          ? {
                              latitude: customerDetail?.latitude,
                              longitude: customerDetail?.longitude,
                            }
                          : null
                      }
                      id={'consigneeMap1'}
                      height={150}
                    />
                  ) : (
                    <Map
                      className="rounded-lg bg-white"
                      address={
                        customerDetail?.latitude && customerDetail?.longitude
                          ? {
                              latitude: customerDetail?.latitude,
                              longitude: customerDetail?.longitude,
                            }
                          : null
                      }
                      id={'consigneeMap2'}
                      onReverseAddressFunc={() => true}
                      height={150}
                      draggable={false}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            ''
          )}

          <h6 className="text-grayLight900 text-sm font-semibold mb-0.5">
            Billing Address
          </h6>
          <p
            className={`text-grayLight600 text-sm font-normal ${
              isCustomerDetailsLoading ? 'custom-loading' : ''
            }`}
          >
            {`${customerDetail?.address1}, ${customerDetail?.city}, ${customerDetail?.country}`}
          </p>
        </ul>
      </div>

      {isShowRankInfoModal && (
        <RankInfoModal handleClose={() => setIsShowRankInfoModal(false)} />
      )}
      {isShowStreakInfoModal && (
        <StreakInfoModal handleClose={() => setIsShowStreakInfoModal(false)} />
      )}
      {isShowTypeInfoModal && (
        <TypeInfoModal handleClose={() => setIsShowTypeInfoModal(false)} />
      )}
    </>
  );
};
export default CustomerDetailRightSideDetailSection;
