import { PlayCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const CallLogLoaderRow = () => (
  <>
    <td className="px-5 py-4 w-48 min-w-48 max-w-48">
      <div className="flex items-center gap-3">
        <div className="flex-none custom-loading table-profile-customer-circle-xl"></div>
        <div className="truncate max-w-44 custom-loading">
          <h6 className="text-grayLight900 font-medium truncate">
            Orlando Diggs
          </h6>
          <p className="truncate mt-[1px] text-gray500 font-normal">
            +1 (315) 623-3438
          </p>
        </div>
      </div>
    </td>

    {/* <td className="px-5 py-4 w-48 min-w-48 max-w-48">
      <div className="flex items-center gap-3">
        <div className="custom-loading table-profile-customer-circle-xl"></div>
        <div className="truncate max-w-44 custom-loading">
          <h6 className="text-grayLight900 font-medium truncate">
            Orlando Diggs
          </h6>
          <p className="truncate mt-[1px] text-gray500 font-normal">
            +1 (315) 623-3438
          </p>
        </div>
      </div>
    </td> */}

    <td className="px-5 py-4 w-56 min-w-56 max-w-56">
      <p className="truncate text-textSecondary font-medium custom-loading">
        Polaris Transportation
      </p>
    </td>

    <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
      <div className="flex items-center gap-1 text-primary custom-loading">
        <p>outbound</p>
      </div>
    </td>

    <td className="px-5 py-4 w-20 min-w-20 max-w-24">
      <div className="flex items-center gap-1 custom-loading">
        <PlayCircle className="cursor-pointer w-4 h-4 text-gray400" />
        <p className="">00:29</p>
      </div>
    </td>

    <td className="px-5 py-4 w-44 min-w-44 max-w-44">
      <p className="truncate custom-loading">Dec 24th, 2024, 16:18</p>
    </td>

    <td className="px-5 py-4 w-[13.38%] max-w-[13.38%] min-w-[13.38%]">
      <div>
        <div className="custom-loading">Dec 24th, 2024</div>
      </div>
    </td>

    <td className="px-5 py-4 w-[84px] min-w-[84px] max-w-[84px]">
      <div>
        <div className="text-gray400 custom-loading">00:29</div>
      </div>
    </td>
  </>
);

export default CallLogLoaderRow;
