import { Contrast01 } from '@untitled-ui/icons-react/build/cjs';
import React, { memo } from 'react';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';

const TrackAndTraceSetting = ({ handleClose }: any) => {
  const closeSettings = () => {
    handleClose();
  };

  const handleSubmit = () => {};

  return (
    <>
      <CommonModal
        title="Follow up Count"
        titleDesc="Drag the map so the pin matches the exact location of your business location."
        handleClose={closeSettings}
        headerIcon={<Contrast01 />}
        size={'max-w-[580px]'}
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={closeSettings}
        primaryBtnOnClick={handleSubmit}
      >
        <div className="p-5 space-y-4">
          <SelectBox
            name=""
            className="form_control"
            placeholder="Select count"
            value=""
            label="Follow up count"
            labelClassName="form_label mb-1"
          />
          <SelectBox
            name=""
            className="form_control"
            placeholder="Select interval"
            value=""
            label="Follow up Interval"
            labelClassName="form_label mb-1"
          />
        </div>
      </CommonModal>
    </>
  );
};

export default memo(TrackAndTraceSetting);
