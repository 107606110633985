import React from 'react';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
}

const CostBreakDownFooterRow = ({ data }: IProps) => (
  <>
    <td className="px-5 py-4 w-[54px] max-w-[54px] min-w-[54px] bg-gray50"></td>
    <td className="px-5 py-4 bg-gray50">
      <div className="flex items-center gap-3">
        <div className="table-profile-user-circle-sm invisible">WA</div>
        <h6 className={`truncate text-grayLight900 font-semibold`}>
          Total Margin
        </h6>
      </div>
    </td>

    <td className="px-5 py-4 bg-gray50"></td>

    <td className="px-5 py-4 bg-gray50">
      <div className="text-grayLight900 font-semibold">
        {data?.totalMarginInCAD < 0
          ? `-$${getFormattedNumber(
              Math.abs(data?.totalMarginInCAD).toFixed(2)
            )}`
          : `$${getFormattedNumber((data?.totalMarginInCAD ?? 0).toFixed(2))}`}
      </div>
    </td>

    <td className={`px-5 py-4 bg-gray50`}>
      <div className="text-grayLight900 font-semibold">
        {data?.totalMarginInUSD && data?.totalMarginInUSD < 0
          ? `-$${getFormattedNumber(
              Math.abs(data?.totalMarginInUSD).toFixed(2)
            )}`
          : `$${getFormattedNumber((data?.totalMarginInUSD ?? 0).toFixed(2))}`}
      </div>
    </td>
  </>
);

export default CostBreakDownFooterRow;
