import { yupResolver } from '@hookform/resolvers/yup';
import { ImagePlus, User01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import CropperModal from 'src/components/CropperModal/CropperModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  addOnBoardingCustomer,
  updateOnboarding,
} from 'src/services/CustomerOnBoardingService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface IOnBoardCustomer {
  action: string;
  setAction: any;
  customerData?: any;
  handleClose: any;
}

const AddOnBoardCustomer = ({
  handleClose,
  customerData,
}: IOnBoardCustomer) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const [preview, setPreview] = useState<string | null>(
    customerData?.imageUrl + customerData?.image
  );
  const [isLoading, setIsLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const validationSchema = yup.object().shape({
    businessName: yup.string().required('Business Name is required.'),
    contactName: yup.string().required('Contact Name is required.'),
    contactEmail: yup
      .string()
      .test({
        message: 'Invalid email address',
        test: function (value) {
          if (value != '') {
            const emailRegex =
              /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

            return emailRegex.test(value ?? '');
          }

          return true;
        },
      })
      .required('Contact Email is required.'),
    customerLogo: yup.mixed().test({
      name: 'fileSize',
      message: 'Image size should be less than 2MB.',
      test: async (value: any) => {
        if (!value || !(value instanceof File)) return true;

        return value.size <= MAX_FILE_SIZE;
      },
    }),
    expiry_days: yup.number(),
    credit_limit: yup.number(),
  });

  const expiryDaysArr = [
    { label: 'None', value: 0 },
    { label: '1 Day', value: 1 },
    { label: '5 Days', value: 5 },
    { label: '10 Days', value: 10 },
    { label: '15 Days', value: 15 },
    { label: '30 Days', value: 30 },
  ];

  console.log('customerData', customerData);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      contactName: customerData?.contactName || '',
      contactEmail: customerData?.contactEmail || '',
      businessName: customerData?.businessName || '',
      expiry_days: customerData?.expiry_days || 0,
      credit_limit: customerData?.initial_credit || 0,
    },
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setError('customerLogo' as 'customerLogo', {});
    setPreview(null);
    const file = acceptedFiles[0];

    setValue('customerLogo', file, { shouldValidate: true });
    const { result, message } = checkFileTypeValidation(
      acceptedFiles,
      MAX_FILE_SIZE
    );

    if (result) {
      setIsLoading(false);
      const reader = new FileReader();

      reader.onloadstart = () => {
        setPreview('');
        setUpload(false);
      };

      reader.onloadend = () => {
        setPreview(reader.result as any);
        // setValue("image", reader.result, { shouldValidate: true });
        setUpload(true);
      };
      reader.readAsDataURL(file);
    } else {
      // setImageError(message);
      setError('customerLogo' as 'customerLogo', {
        type: 'manual',
        // message: 'Image size must be less than 2MB.',
        message: message,
      });
    }
  }, []);

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    // console.log('fileInfo :>> ', fileInfo);
    if (fileInfo) setValue('customerLogo', fileInfo?.convertedFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const onSubmit = async (formData: any) => {
    // if (formData.customerLogo === '') {
    //   setError('customerLogo' as 'customerLogo', {
    //     type: 'manual',
    //     message: 'Customer logo is required.',
    //   });
    // } else {
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      if (key !== 'customerLogo') appendFormData.append(key, value);
    });

    if (customerData && customerData?.businessName) {
      appendFormData.append('id', customerData?.id);
      if (formData.customerLogo)
        appendFormData.append('customerLogo', formData.customerLogo);

      updateOnboarding(appendFormData)
        .then((response) => {
          handleClose(response.data);
        })
        .catch(() => WalToast.error('something went wrong please try again'))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (formData.customerLogo)
        appendFormData.append('customerLogo', formData.customerLogo);
      addOnBoardingCustomer(appendFormData)
        .then((response) => {
          handleClose(response.data);
        })
        .catch((error: any) =>
          WalToast.error(error?.response?.data?.message || 'Customer not added')
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    // }
  };

  return (
    <>
      <CommonModal
        title="Onboard A New Customer"
        titleDesc="Fill out the basic customer information."
        handleClose={() => handleClose(false)}
        headerIcon={<User01 />}
        size={'max-w-[688px]'}
        secondaryBtnOnClick={() => handleClose(false)}
        modalHeaderClass=""
        primaryBtnOnClick={handleSubmit(onSubmit)}
        primaryBtnText="Next"
        secondaryBtnText="Cancel"
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={isLoading}
        isEscapeClose={false}
      >
        <div className="py-5 px-4 flex flex-col gap-y-4">
          <div className="w-full md:flex pb-4 border-b border-utilityGray200">
            <p className="text-xs font-medium text-textSecondary mb-2 md:mb-0 w-40">
              Customer Logo
              {/* <span className="text-red-600 leading-4 text-xs">*</span> */}
            </p>

            <div className="position-relative flex-1">
              <div className="">
                <div {...getRootProps()}>
                  <label
                    className="cursor-pointer flex items-center gap-2"
                    htmlFor="fileSelect"
                  >
                    {preview ? (
                      <img
                        className="rounded-lg border-utilityBlack border-[0.75px] border-opacity-[0.08] w-14 h-14 object-cover flex-none"
                        src={preview}
                        alt=""
                        title=""
                        onError={onError}
                      />
                    ) : watch('businessName') ? (
                      <label className="rounded-lg bg-utilityGray100 w-14 h-14 border-utilityBlack border-[0.75px] border-opacity-[0.08] text-primary text-xl font-semibold uppercase flex items-center justify-center flex-none">
                        {getShortName(`${watch('businessName')}`)}
                      </label>
                    ) : (
                      <label className="w-14 h-14 border border-black/[0.08] rounded-lg object-cover flex justify-center items-center bg-utilityGray100 text-gray500 flex-none">
                        <ImagePlus />
                      </label>
                    )}
                    <div
                      className={`relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white ${
                        errors.customerLogo
                          ? 'border-red-400'
                          : 'border-utilityGray200'
                      }`}
                    >
                      <div className="justify-center text-xs items-center gap-1.5 flex">
                        <div className="text-primary font-semibold leading-tight">
                          Click to upload
                        </div>
                        <div className="text-grayLight600 text-xs font-normal leading-tight">
                          or drag and drop
                        </div>
                      </div>
                      <div className="text-center text-grayLight600 text-xs font-normal leading-[18px]">
                        SVG, PNG or JPG
                      </div>
                    </div>
                  </label>
                  <input
                    accept=".svg, .png, .jpg, .jpeg"
                    {...getInputProps()}
                  />
                  <div className="mt-1.5">
                    {errors.customerLogo && (
                      <ErrorMsg errorText={errors.customerLogo.message} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-1.5">
            <Controller
              name="businessName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Business Name"
                  inputName="businessName"
                  placeholder="Business Name"
                  parentClassName="w-1/2 px-1.5"
                  className=""
                  value={value}
                  required={true}
                  onChangeFunc={onChange}
                  errorText={
                    errors.businessName ? errors.businessName.message : null
                  }
                  labelClassName="block mb-1.5"
                />
              )}
            />
            <Controller
              name="expiry_days"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="expiry_days"
                  label="Expiration"
                  labelClassName="form_label block mb-1.5"
                  className="form_control"
                  parentClassName="w-1/2 px-1.5"
                  size="sm"
                  errorText={
                    errors.expiry_days ? errors.expiry_days.message : null
                  }
                  placeholder="Expiration"
                  options={expiryDaysArr}
                  onChangeFunc={(e: any) => onChange(e?.value ?? '')}
                  value={expiryDaysArr.filter(
                    (val: any) => value == val?.value
                  )}
                />
              )}
            />
          </div>
          <div className="flex flex-wrap -mx-1.5">
            <Controller
              name="contactName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Contact Name"
                  inputName="contactName"
                  placeholder="Contact Name"
                  parentClassName="w-1/2 px-1.5"
                  className=""
                  value={value}
                  required={true}
                  onChangeFunc={onChange}
                  errorText={
                    errors.contactName ? errors.contactName.message : null
                  }
                  labelClassName="block mb-1.5"
                />
              )}
            />
            <Controller
              name="contactEmail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Contact Email"
                  inputName="contactEmail"
                  placeholder="Contact Email"
                  parentClassName="w-1/2 px-1.5"
                  className=""
                  required={true}
                  disabled={customerData?.contactEmail ? true : false}
                  value={value}
                  onChangeFunc={onChange}
                  errorText={
                    errors.contactEmail ? errors.contactEmail.message : null
                  }
                  labelClassName="block mb-1.5"
                />
              )}
            />
          </div>

          {/* <Controller
            name="expiry_days"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                name="expiry_days"
                label="Expiration"
                labelClassName="form_label block mb-1.5"
                className="form_control"
                size="sm"
                errorText={
                  errors.expiry_days ? errors.expiry_days.message : null
                }
                placeholder="Expiration"
                options={expiryDaysArr}
                onChangeFunc={(e: any) => onChange(e?.value ?? '')}
                value={expiryDaysArr.filter((val: any) => value == val?.value)}
              />
            )}
          /> */}

          {/* <Controller
          name="website"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Website"
              inputName="website"
              placeholder="Website"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              errorText={errors.website ? errors.website.message : null}
              icon={
                <Copy06
                  className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-2 w-5 h-5 text-primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    copyDebounce(value);
                  }}
                />
              }
              labelClassName="block mb-1.5"
            />
          )}
        /> */}
        </div>
      </CommonModal>
      {upload && (
        <CropperModal
          modalTitle="Edit Customer Logo"
          modalDesc="Edit customer logo as per your requirement"
          imageUpload={imageUpload}
          imageUrl={preview}
          setUpload={setUpload}
          setCropData={setPreview}
          defaultImage=""
          isCropType={false}
          defaultCropType="Square"
          setIsCloseCancel={() => {
            setValue('customerLogo', '');
            setPreview(customerData?.imageUrl + customerData?.image);
          }}
        />
      )}
    </>
  );
};

export default AddOnBoardCustomer;
