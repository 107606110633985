import { yupResolver } from '@hookform/resolvers/yup';
import { Mail02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import DynamicCKEditor from 'src/components/DynamicCKEditor';
import InputText from 'src/components/InputText/InputText';
import { ThreadSendEmail } from 'src/services/CallCenterService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const initialEmailData = {
  contactId: null,
  subject: '',
  emailBody: '',
  fromEmail: '',
  toEmail: '',
  ccEmail: '',
};

const ThreadEmailModal = ({ contactId, emailDetails, handleClose }: any) => {
  const [emailData, setEmailData] = useState<any>(initialEmailData);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (emailDetails) {
      setEmailData((old: any) => ({
        ...old,
        emailBody: emailDetails,
      }));
    }

    if (contactId) {
      setEmailData((old: any) => ({
        ...old,
        contactId: contactId,
      }));
    }
  }, [emailDetails, contactId]);

  const validationSchema = yup.object().shape({
    fromEmail: yup
      .string()
      .required('Email is required')
      .email('Invalid Email'),
    toEmail: yup.string().required('Email is required').email('Invalid Email'),
    subject: yup.string().required('Subject is required'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: emailData,
  });

  useEffect(() => {
    setValue('fromEmail', emailData.fromEmail);
    setValue('toEmail', emailData.toEmail);
    setValue('ccEmail', emailData.ccEmail);
    setValue('subject', emailData.subject);
  }, [emailData]);

  const onSubmit = (data: any) => {
    setIsLoading(true);

    const emailFormData = {
      ...data,
      contactId: emailData.contactId,
      emailBody: emailData.emailBody,
    };

    ThreadSendEmail(emailFormData)
      .then((response: any) => {
        handleClose(response.data);
      })
      .catch(() => WalToast.error('Email not sent'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CommonModal
      title="Email"
      titleDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      headerIcon={<Mail02 />}
      size="max-w-[823px] h-[574px]"
      handleClose={handleClose}
      secondaryBtnText="Cancel"
      primaryBtnText="Send Email"
      primaryBtnLoading={false}
      primaryBtnDisabled={isLoading}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      secondaryBtnOnClick={handleClose}
    >
      <div className="h-full flex flex-col px-5 pb-5 overflow-y-auto">
        <div className="w-full border-b border-utilityGray200 pt-5 sticky top-0 z-[2] bg-white">
          <div className="mb-3 flex">
            <label className="form_label md:mb-0 form_label_center w-[80px]">
              From:
            </label>
            <div className="flex-1">
              <Controller
                name="fromEmail"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="fromEmail"
                    placeholder="Enter Email"
                    parentClassName="flex-1"
                    value={value}
                    onChangeFunc={onChange}
                    errorText={
                      errors.fromEmail ? errors.fromEmail.message : null
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="mb-3 flex">
            <label className="form_label md:mb-0 form_label_center w-[80px]">
              To:
            </label>
            <div className="flex-1">
              <Controller
                name="toEmail"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="toEmail"
                    placeholder="Enter Email"
                    parentClassName="flex-1"
                    value={value}
                    onChangeFunc={onChange}
                    errorText={errors.toEmail ? errors.toEmail.message : null}
                  />
                )}
              />
            </div>
          </div>
          <div className="mb-3 flex">
            <label className="form_label md:mb-0 form_label_center w-[80px]">
              CC:
            </label>
            <div className="flex-1">
              <Controller
                name="ccEmail"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="ccEmail"
                    placeholder="Enter CC address"
                    parentClassName="flex-1"
                    value={value}
                    onChangeFunc={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="my-5 flex-1 overflow-y-auto scrollbar-hide">
          <div className="text-grayLight600 text-sm text-left mb-3">
            <span className="font-bold mr-1">Subject: </span>
            <Controller
              name="subject"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="subject"
                  placeholder="Enter Subject"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={errors.subject ? errors.subject.message : null}
                />
              )}
            />
          </div>

          <div className="h-full pt-4">
            <DynamicCKEditor data={emailDetails} readOnly={true} />
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default ThreadEmailModal;
