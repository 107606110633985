import React, { useEffect, useMemo, useState } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import LeadsRow from './LeadsRow';

const dummyData = [
  {
    carrier: {
      code: 'CL',
      name: 'Canadian Logistics',
      rating: 4.5,
      contact: '+1-800-409-2269',
    },
    origin: 'Edmonton',
    dfo: '281 mi',
    destination: 'Montreal',
    dfd: '150 mi',
    equipmentType: 'Dry Van, Flatbed',
    comments: 'Ready now',
  },
  {
    carrier: {
      code: 'MT',
      name: 'Mannan Transport Inc.',
      rating: 4.5,
      contact: '+1-800-409-2269',
    },
    origin: 'Vancouver',
    dfo: '329 mi',
    destination: 'Calgary',
    dfd: '250 mi',
    equipmentType: 'Reefer, Roll Tite Trailer',
    comments: 'LTL, FTL - Dryvan -...',
  },
  {
    carrier: {
      code: 'CL',
      name: 'Canadian Logistics',
      rating: 4.5,
      contact: '+1-800-409-2269',
    },
    origin: 'Calgary',
    dfo: '198 mi',
    destination: 'Vancouver',
    dfd: '150 mi',
    equipmentType: 'Straight Truck',
    comments: 'Ready now',
  },
  {
    carrier: {
      code: 'MT',
      name: 'Mannan Transport Inc.',
      rating: 4.5,
      contact: '+1-800-409-2269',
    },
    origin: 'Montreal',
    dfo: '329 mi',
    destination: 'Edmonton',
    dfd: '220 mi',
    equipmentType: 'Flatbed, Dry Van',
    comments: 'LTL, FTL - Dryvan -...',
  },
];

const LeadsTab = () => {
  const [params, setParams] = useState([]);
  const [leads, setLeads] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setLeads(dummyData);

    setIsLoading(false);
  }, []);

  const headCells = useMemo(
    () => [
      {
        id: 'carrier',
        name: 'Carrier',
        sortable: false,
        visible: true,
      },
      {
        id: 'origin',
        name: 'Origin',
        sortable: false,
        visible: true,
      },
      {
        id: 'DFO',
        name: 'DFO',
        sortable: false,
        visible: true,
      },
      {
        id: 'destination',
        name: 'Destination',
        sortable: true,
        visible: true,
      },
      {
        id: 'DFD',
        name: 'DFD',
        sortable: false,
        visible: true,
      },
      {
        id: 'equipmentType',
        name: 'Equipment Type',
        sortable: true,
        visible: true,
      },
      {
        id: 'comments',
        name: 'Comments',
        sortable: true,
        visible: true,
      },
    ],
    []
  );

  return (
    <>
      <TableCmp
        headCells={headCells}
        params={params}
        setParams={setParams}
        tableDataArr={leads}
        // TableLoaderRowCmp={CustomerDetailClaimsLoadingRaw}
        TableRowCmp={LeadsRow}
        tableRowCmpProps={{}}
        isTableDataLoading={isLoading}
        numberOfSkeletonRows={7}
      />
      {!isLoading && !leads?.length && (
        <NotFoundUI
          title={`No Leads Found`}
          desc={`There are currently no leads found`}
          containerClassName="min-h-[160px] !h-auto"
        />
      )}
    </>
  );
};

export default LeadsTab;
