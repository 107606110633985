import React, { useContext, useRef } from 'react';
import { usePhone } from 'src/utils/CommonFunctions';

import { OrderContext } from '../..';

import RenderAddressSection from './RenderAddressSection';

const AddressDetailsTab = () => {
  const {
    currentLegData,
    isLoading,
    serviceList,
    order,
    isMasterOrder,
    isOrderHasLeg,
  } = useContext<any>(OrderContext);

  const shipperAccordionRef = useRef<any>(null);
  const consigneeAccordionRef = useRef<any>(null);

  const { getFormatPhoneWithPlus } = usePhone();

  const handleAccordionToggle = () => {
    if (shipperAccordionRef.current) {
      shipperAccordionRef.current.toggle();
    }

    if (consigneeAccordionRef.current) {
      consigneeAccordionRef.current.toggle();
    }
  };

  return (
    <div className="flex">
      <div className="w-1/2 flex-1 p-4 bg-gray25">
        <RenderAddressSection
          ref={shipperAccordionRef}
          isLoading={isLoading}
          currentLegData={currentLegData}
          order={order}
          isShowOrderAddress={isMasterOrder && isOrderHasLeg}
          dataKey={'shipper'}
          title={'Shipper'}
          serviceList={serviceList}
          getFormatPhoneWithPlus={getFormatPhoneWithPlus}
          onAccordionClick={handleAccordionToggle}
        />
      </div>
      <div className="w-1/2 flex-1 p-4 bg-gray25 border-l border-utilityGray200">
        <RenderAddressSection
          ref={consigneeAccordionRef}
          isLoading={isLoading}
          currentLegData={currentLegData}
          order={order}
          isShowOrderAddress={isMasterOrder && isOrderHasLeg}
          dataKey={'consignee'}
          title={'Consignee'}
          serviceList={serviceList}
          getFormatPhoneWithPlus={getFormatPhoneWithPlus}
          onAccordionClick={handleAccordionToggle}
        />
      </div>
    </div>
  );
};

export default AddressDetailsTab;
