import { yupResolver } from '@hookform/resolvers/yup';
import {
  AlertCircle,
  UploadCloud02,
  UserPlus01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Team1 from '../../assets/img/default-team-images/team1.png';
import Team10 from '../../assets/img/default-team-images/team10.png';
import Team2 from '../../assets/img/default-team-images/team2.png';
import Team3 from '../../assets/img/default-team-images/team3.png';
import Team4 from '../../assets/img/default-team-images/team4.png';
import Team5 from '../../assets/img/default-team-images/team5.png';
import Team6 from '../../assets/img/default-team-images/team6.png';
import Team7 from '../../assets/img/default-team-images/team7.png';
import Team8 from '../../assets/img/default-team-images/team8.png';
import Team9 from '../../assets/img/default-team-images/team9.png';
import CommonModal from '../../components/CommonModal';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import Radio from '../../components/Radio/Radio';
import { createTeam, updateTeam } from '../../services/TeamService';
import WalToast from '../../utils/WalToast';

const teamDefaultImages = [
  { id: 'team1', image: Team1 },
  { id: 'team2', image: Team2 },
  { id: 'team3', image: Team3 },
  { id: 'team4', image: Team4 },
  { id: 'team5', image: Team5 },
  { id: 'team6', image: Team6 },
  { id: 'team7', image: Team7 },
  { id: 'team8', image: Team8 },
  { id: 'team9', image: Team9 },
  { id: 'team10', image: Team10 },
];

const supportedFormats = [
  'image/svg+xml',
  'image/png',
  'image/jpeg',
  'image/gif',
];

const createSchema = yup.object().shape({
  teamName: yup.string().required('Team name is required.'),
  image: yup.string().nullable(),
  imageFile: yup
    .mixed()
    .required('Team image is required.')
    .test({
      name: 'fileFormat',
      message: 'Unsupported image format.',
      test: async (value: any) => {
        if (!value || !(value instanceof File)) return true;

        return supportedFormats.includes(value.type);
      },
    })
    .test({
      name: 'fileSize',
      message: 'Image size is too large.',
      test: (value: any) => {
        if (!value || !(value instanceof File)) return true;

        return value.size <= 1048576; // 1MB in bytes
      },
    })
    .test(
      'fileResolution',
      'The image must be 800x800 pixels or smaller',
      (value) =>
        new Promise((resolve, reject) => {
          if (!value) {
            return resolve(true);
          }

          if (!(value instanceof Blob)) {
            return resolve(true);
          }

          const file: any = value;
          const reader = new FileReader();

          reader.onload = (event: any) => {
            const img = new Image();

            img.onload = () => {
              const width = img.width;
              const height = img.height;
              resolve(width <= 800 && height <= 800);
            };
            img.onerror = reject;
            img.src = event.target.result;
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        })
    ),
});

const updateSchema = yup.object().shape({
  teamName: yup.string().required('Team name is required.'),
  image: yup.string().nullable(),
  imageFile: yup
    .mixed()
    .test({
      name: 'fileFormat',
      message: 'Unsupported image format.',
      test: async (value: any) => {
        if (!value || !(value instanceof File)) return true;

        return supportedFormats.includes(value.type);
      },
    })
    .test({
      name: 'fileSize',
      message: 'Image size is too large.',
      test: (value: any) => {
        if (!value || !(value instanceof File)) return true;

        return value.size <= 1048576; // 1MB in bytes
      },
    })
    .test(
      'fileResolution',
      'The image must be 800x800 pixels or smaller',
      (value) =>
        new Promise((resolve, reject) => {
          if (!value) {
            return resolve(true);
          }

          if (!(value instanceof Blob)) {
            return resolve(true);
          }

          const file: any = value;
          const reader = new FileReader();

          reader.onload = (event: any) => {
            const img = new Image();

            img.onload = () => {
              const width = img.width;
              const height = img.height;
              resolve(width <= 800 && height <= 800);
            };
            img.onerror = reject;
            img.src = event.target.result;
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        })
    ),
});

interface IProps {
  mode: string;
  team?: any;
  handleClose: (teamForm?: any, isSaveChanges?: boolean) => void;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateUpdateTeam = ({
  mode,
  team,
  handleClose,
  setIsRefresh,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const {
    handleSubmit,
    control,
    setValue,
    // setError,
    // getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      mode === 'team-create' || mode === 'team-create-instant'
        ? createSchema
        : updateSchema
    ),
  });

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      // console.log("The object has no keys.");
    } else {
      setIsSaveChanges(false);
    }
  }, [errors]);

  useEffect(() => {
    if (mode === 'team-update') {
      setValue('teamName', team.name);

      if (team?.iconUrl && team?.icon) {
        setPreview(`${team?.iconUrl}${team?.icon}`);
      }

      // set default image
      if (team?.defaultImageId) {
        setValue('image', team?.defaultImageId, { shouldValidate: true });
      }
    }
  }, [team]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setPreview(null);
    const file = acceptedFiles[0];
    setValue('imageFile', file, { shouldValidate: true });
    setValue('image', undefined, { shouldValidate: true });

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          setPreview(img.src);
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const onSaveChanges = async () => {
    setIsSaveChanges(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  const onSubmit = async (data: any) => {
    const createPayloadForm = new FormData();
    createPayloadForm.append('name', data.teamName);

    if (data.image) {
      const selectedImage = teamDefaultImages.find(
        (tdi) => tdi.id === data.image
      )!;
      createPayloadForm.append('icon', selectedImage.image);
      createPayloadForm.append('defaultImageId', selectedImage.id);
    } else if (data.imageFile) {
      createPayloadForm.append('icon', data.imageFile);
    }

    if (mode === 'team-create-instant') {
      try {
        setIsLoading(true);
        await createTeam(createPayloadForm);
        setIsRefresh(true);
        handleClose(true);
        WalToast.success(`${data.teamName} was added to WALHQ`);
      } catch (e) {
        console.log(e);
        WalToast.error(
          `Error while creating ${data.teamName}. Please try again.`
        );
      } finally {
        setIsLoading(false);
        setIsSaveChanges(false);
      }
    } else if (mode === 'team-create') {
      try {
        if (isSaveChanges) {
          setIsLoading(true);
          await createTeam(createPayloadForm);
          setIsRefresh(true);
          handleClose(true, true);
          setIsSaveChanges(false);
          WalToast.success(`${data.teamName} was added to WALHQ`);
        } else {
          handleClose(createPayloadForm);
        }
      } catch (e) {
        console.log(e);
        setIsSaveChanges(false);
        WalToast.error(
          `Error while creating ${data.teamName}. Please try again.`
        );
      } finally {
        setIsLoading(false);
      }
    } else if (mode === 'team-update') {
      try {
        if (isSaveChanges) {
          setIsLoading(true);
          const managerIds = team.members.map((e: any) => e.id);
          createPayloadForm.append('managerIds', managerIds.toString());
          await updateTeam(createPayloadForm, team.id);
          setIsRefresh(true);
          setIsSaveChanges(false);
          handleClose(true, true);
          WalToast.success('Team updated Successfully');
        } else {
          handleClose(createPayloadForm);
        }
      } catch (e) {
        console.log(e);
        setIsSaveChanges(false);
        WalToast.error('New team not updated Successfully');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isSaveChanges) {
      handleSubmit(onSubmit)();
    }
  }, [isSaveChanges]);

  return (
    <CommonModal
      title={
        mode === 'team-create' || mode === 'team-create-instant'
          ? 'Add A New Team To WALHQ'
          : `Edit ${team.name}`
      }
      titleDesc="Fill out the details below to create a new team."
      handleClose={() => handleClose()}
      headerIcon={<UserPlus01 />}
      secondaryBtnOnClick={() => onSaveChanges()}
      size={'max-w-[500px]'}
      primaryBtnText="Continue"
      primaryBtnLoading={!isSaveChanges ? isLoading : false}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      secondaryBtnText="Save changes"
      secondaryBtnLoading={isSaveChanges ? isLoading : false}
    >
      <div className="relative px-5 pt-8 flex flex-col gap-5 pb-5">
        <Controller
          name="teamName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Team Name"
              inputName="teamName"
              placeholder="Enter team name"
              icon={
                errors.teamName ? (
                  <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                ) : null
              }
              value={value}
              onChangeFunc={onChange}
              errorText={errors.teamName ? errors.teamName.message : null}
            />
          )}
        />

        <div className="flex flex-wrap sm:max-w-[320px] max-w-[248px] mx-auto sm:gap-5 gap-3 justify-center ">
          <Controller
            name="image"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                {teamDefaultImages.map((defaultImage) => (
                  <div
                    className="relative cursor-pointer"
                    key={defaultImage.id}
                    onClick={() => {
                      onChange(defaultImage.id);
                      setPreview(null);
                      setValue('imageFile', false, { shouldValidate: true });
                    }}
                  >
                    <div className="sm:w-12 w-10 sm:h-12 h-10 rounded-full border-[0.75px] border-black/[0.08]">
                      <img
                        src={defaultImage.image}
                        alt=""
                        className="h-full w-full"
                      />
                    </div>
                    <div className="absolute bottom-0 right-0">
                      <Radio
                        id={`team_default_image_${defaultImage.id}`}
                        onChangeFunc={() => {}}
                        inputName={'teamDefaultImage'}
                        value={defaultImage.id}
                        checked={value === defaultImage.id}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          />
          <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
            {errors.image && <ErrorMsg errorText={errors.image.message} />}
          </div>
        </div>
      </div>
      <div className="w-full h-10 items-center gap-2 flex">
        <div className="grow h-px bg-gray100" />
        <div className="px-3.5 py-2.5 bg-white rounded-lg shadow-sm border border-borderPrimary">
          <div className="text-textSecondary text-xs font-semibold leading-tight">
            OR upload your own icon
          </div>
        </div>
        <div className="grow h-px bg-gray100" />
      </div>
      <div className="relative flex flex-col gap-5 p-5 pb-8">
        <div className="">
          <div {...getRootProps()}>
            <label
              className="cursor-pointer flex items-center gap-2"
              htmlFor="fileSelect"
            >
              {preview && (
                <div className="w-16 h-16 rounded-full overflow-hidden flex justify-center items-center">
                  <img
                    src={preview}
                    alt=""
                    className="w-16 h-16 object-cover"
                  />
                </div>
              )}
              <div
                className={`p-3 bg-white rounded-xl border flex flex-grow flex-col items-center justify-center ${
                  errors.imageFile ? 'border-red-400' : 'border-utilityGray200'
                }`}
              >
                <div className="w-9 h-9 mb-2 rounded-lg shadow-sm border border-utilityGray200 justify-center items-center flex">
                  <UploadCloud02 className="w-4 h-4 relative flex justify-start items-start" />
                </div>

                <div className="justify-center text-xs items-center gap-1.5 flex">
                  <div className="text-primary font-semibold leading-tight">
                    Click to upload
                  </div>
                  <div className="text-grayLight600 text-xs font-normal leading-tight">
                    or drag and drop
                  </div>
                </div>
                <div className="text-center text-grayLight600 text-xs font-normal leading-[18px]">
                  SVG, PNG or JPG (max. 800x800px)
                </div>
              </div>
            </label>
            <input accept=".svg, .png, .jpg, .jpeg" {...getInputProps()} />
            <div className="mt-1.5">
              {errors.imageFile && (
                <ErrorMsg errorText={errors.imageFile.message} />
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default memo(CreateUpdateTeam);
