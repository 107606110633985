import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const NewOrderLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-[150px] w-[15.29%] max-w-[1px]  ">
      <div className="flex items-center">
        <div className="table-profile-user-circle-xl custom-loading">J D</div>
        <div className="ml-3 font-medium truncate custom-loading">
          <h6 className="text-grayLight900 font-medium leading-tight truncate ">
            XXXXX XXXXXXXX
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            #WAL-TEST-123
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[130px] max-w-[130px] w-[130px]  ">
      <BadgeCmp style="modern" type="error" mainClassName="custom-loading">
        New
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 max-w-[1px] min-w-[100px] w-[13.29%]  ">
      <TooltipCmp childrenClassName="!block truncate custom-loading">
        Unicon Concrete Specialties Ltd
      </TooltipCmp>
    </td>

    <td className="px-5 py-4 max-w-[1px] min-w-[100px] w-[14.29%]  ">
      <TooltipCmp type="dark" parentClassName="">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate max-w-[130px]">
            Etobicoke, ON, Canada
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp type="dark" parentClassName="max-w-90">
        <p className="flex custom-loading">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate max-w-[130px]">
            {' '}
            Still water, OK 74078, USA
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 min-w-[220px] w-[220px] max-w-[220px]  ">
      <span className="custom-loading">
        Dec 23<sup>rd</sup> - 2024, 06:43 PM
      </span>
    </td>
    <td className="px-5 py-4 min-w-[130px] w-[8%] max-w-[8%] ">
      <span className="custom-loading">$ 1234.22</span>
    </td>
    <td className="px-5 py-4 min-w-[130px] w-[8%] max-w-[8%]  ">
      <span className="custom-loading">$ 134542.22</span>
    </td>
    <td className="px-5 py-4 min-w-[120px] w-[7%] max-w-[7%]  ">
      <div className="flex gap-1 custom-loading">10.10%</div>
    </td>
    <td className="px-5 py-4 min-w-[120px] w-[7%] max-w-[7%]  ">
      <span className="custom-loading">$ 95.26 CAD</span>
    </td>
    {/* <td className={`px-5 py-4 min-w-[84px] w-[84px] max-w-[84px]  `}>
      <div className="justify-center items-center gap-2 flex">
        <div className={`w-4 h-4 flex-none custom-loading`}></div>
        <div className={`w-4 h-4 flex-none custom-loading`}></div>
      </div>
    </td> */}
  </>
);

export default NewOrderLoaderRow;
