import { createSlice } from '@reduxjs/toolkit';
import { Call } from '@twilio/voice-sdk';

interface Contact {
  name: string;
  companyName: string;
  role: string;
  image: string;
  from: string;
  number?: string;
}

interface BargeWhisperCallDetails {
  agentName: string;
  customerName: string;
  conferenceSid: string;
  agentCallSid: string;
  agentImage: string;
  customerImage: string;
}

export interface CallCenterState {
  incomingCall: Call | null;
  outgoingCall: Call | null;
  token: string;
  device: any;
  conferenceName: string;
  conferenceId: string;
  participants: any[];
  isCallInProgress: boolean;
  isMuted: boolean;
  onHold: boolean;
  holdStatus: boolean;
  countryCode: string;
  fromNumberContact: Contact | null;
  outgoingCallDetails: Contact | null;
  ErrorMessage: string | null;
  isRecording: boolean;
  callDuration: number;
  bargeWhisperCallDetails: BargeWhisperCallDetails | null;
  isBargeWhisper: boolean;
  isBarge: boolean;
  isWhisper: boolean;
  supervisorSid: string;
  agentCallSid: string;
  callLogId: number | null;
  adminJoiningACall: boolean;
  bargeWhisperConferenceId: string | null;
  bargeWhisperCall: Call | null;
  isBargeWhisperMuted: boolean;
  bargeWhisperNumber: string | null;
}

const initialState: CallCenterState = {
  incomingCall: null,
  outgoingCall: null,
  token: '',
  device: '',
  conferenceName: '',
  conferenceId: '',
  participants: [],
  isCallInProgress: false,
  isMuted: false,
  onHold: false,
  holdStatus: false,
  countryCode: '+91',
  fromNumberContact: null,
  outgoingCallDetails: null,
  ErrorMessage: null,
  isRecording: true,
  callDuration: 0,
  bargeWhisperCallDetails: null,
  isBargeWhisper: false,
  isBarge: false,
  isWhisper: false,
  supervisorSid: '',
  agentCallSid: '',
  callLogId: null,
  adminJoiningACall: false,
  bargeWhisperConferenceId: null,
  bargeWhisperCall: null,
  isBargeWhisperMuted: false,
  bargeWhisperNumber: null,
};

export const CallCenter = createSlice({
  name: 'callCenter',
  initialState,
  reducers: {
    setIncomingCall: (state, action) => {
      state.incomingCall = action.payload;
    },
    setOutgoingCall: (state, action) => {
      state.outgoingCall = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setConferenceName: (state, action) => {
      state.conferenceName = action.payload;
    },
    setConferenceId: (state, action) => {
      state.conferenceId = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setIsCallInProgress: (state, action) => {
      state.isCallInProgress = action.payload;
    },
    setIsMuted: (state, action) => {
      state.isMuted = action.payload;
    },
    setOnHold: (state, action) => {
      state.onHold = action.payload;
    },
    setHoldStatus: (state, action) => {
      state.holdStatus = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setFromNumberContact: (state, action) => {
      state.fromNumberContact = action.payload;
    },
    setOutgoingCallDetails: (state, action) => {
      state.outgoingCallDetails = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.ErrorMessage = action.payload;
    },
    setIsRecording: (state, action) => {
      state.isRecording = action.payload;
    },
    incrementCallDuration(state) {
      state.callDuration += 1;
    },
    setCallDuration(state, action) {
      state.callDuration = action.payload;
    },
    setBargeWhisperCallDetails: (state, action) => {
      state.bargeWhisperCallDetails = action.payload;
    },
    setIsBargeWhisper: (state, action) => {
      state.isBargeWhisper = action.payload;
    },
    setIsBarge: (state, action) => {
      state.isBarge = action.payload;
    },
    setIsWhisper: (state, action) => {
      state.isWhisper = action.payload;
    },
    setSupervisorSid: (state, action) => {
      state.supervisorSid = action.payload;
    },
    setAgentCallSid: (state, action) => {
      state.agentCallSid = action.payload;
    },
    setCallLogId: (state, action) => {
      state.callLogId = action.payload;
    },
    setAdminJoiningACall: (state, action) => {
      state.adminJoiningACall = action.payload;
    },
    setBargeWhisperConferenceId: (state, action) => {
      state.bargeWhisperConferenceId = action.payload;
    },
    setBargeWhisperCall: (state, action) => {
      state.bargeWhisperCall = action.payload;
    },
    setIsBargeWhisperMuted: (state, action) => {
      state.isBargeWhisperMuted = action.payload;
    },
    setBargeWhisperNumber: (state, action) => {
      state.bargeWhisperNumber = action.payload;
    },
  },
});
export const {
  setIncomingCall,
  setOutgoingCall,
  setDevice,
  setToken,
  setConferenceName,
  setConferenceId,
  setParticipants,
  setIsCallInProgress,
  setIsMuted,
  setOnHold,
  setHoldStatus,
  setCountryCode,
  setFromNumberContact,
  setOutgoingCallDetails,
  setErrorMessage,
  setIsRecording,
  incrementCallDuration,
  setBargeWhisperCallDetails,
  setIsBargeWhisper,
  setIsBarge,
  setIsWhisper,
  setSupervisorSid,
  setCallDuration,
  setAgentCallSid,
  setCallLogId,
  setAdminJoiningACall,
  setBargeWhisperConferenceId,
  setBargeWhisperCall,
  setIsBargeWhisperMuted,
  setBargeWhisperNumber,
} = CallCenter.actions;
export default CallCenter.reducer;
