import {
  Copy06,
  DownloadCloud02,
  Edit05,
  Share03,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY, STATUS } from 'src/constants/common';
import { deleteActiveQuote } from 'src/services/QuoteService';
import {
  downloadPdf,
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan.svg';
import defaultImage from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

// import ServiceDetail from './ServiceDetail';

const QuotedRow = ({
  data,
  handleActionType,
  currency,
  addressDetails,
  setQuotedList,
  setIsRefresh,
}: any) => {
  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  const getRemainingDays = (expirationDate: string): string => {
    const expDate = new Date(expirationDate);
    const today = new Date();
    expDate.setDate(expDate.getDate() + 1);

    const diffTime = expDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Tomorrow';
    } else if (diffDays > 1) {
      return `${diffDays} Days`;
    } else {
      return 'Expired';
    }
  };

  const handleActiveQuoteDelete = (quoteId: number, quoteCarrierId: any) => {
    deleteActiveQuote(quoteId, {
      quoteCarrierId: [quoteCarrierId],
    })
      .then(() => {
        WalToast.success(`Quote Removed from Active Quote successfully`);
        setIsRefresh(true);
        setQuotedList((prevQuotedList: any) =>
          prevQuotedList.filter((q: any) => quoteCarrierId !== q.id)
        );
      })
      .finally(() => {
        // setIsLoading(false);
      })
      .catch(console.error);
  };

  return (
    <>
      <td className="px-5 py-4 min-w-[240px] w-[240px] max-w-[240px]">
        <div className=" flex items-center">
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="table-profile-icn-circle-xl rounded-md"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl rounded-md">
              {getShortName(`${data.name}`)}
            </div>
          )}
          <div className="pl-3">
            {(data.name + (data.banyanPostFix || '')).length > 30 ? (
              <TooltipCmp message={`${data.name} ${data.banyanPostFix || ''}`}>
                <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none">
                  {`${data.name} ${data.banyanPostFix || ''}`}
                </h6>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none">
                {`${data.name} ${data.banyanPostFix || ''}`}
              </h6>
            )}

            <p className="text-grayLight600 text-[10px] font-normal leading-tight flex items-center">
              {data.carrierQuoteId.length > 30 ? (
                <TooltipCmp
                  message={`#${data.carrierQuoteId}`}
                  childrenClassName=""
                >
                  <span className="max-w-28 truncate inline-block align-middle  ">
                    #{data.carrierQuoteId}
                  </span>
                </TooltipCmp>
              ) : (
                <span className="inline align-middle truncate max-w-28">
                  #{data.carrierQuoteId}
                </span>
              )}
              &nbsp;
              <div className="flex gap-1">
                <TooltipCmp message="Copy">
                  <Copy06
                    onClick={(event) => {
                      event.stopPropagation();
                      copyDebounce(data.carrierQuoteId);
                    }}
                    className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                  />
                </TooltipCmp>

                {data.quoteUrl && (
                  <TooltipCmp message="Open">
                    <a
                      href={data.quoteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                    </a>
                  </TooltipCmp>
                )}
              </div>
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[160px] w-[160px] max-w-[160px]">
        {(data.isFreightcom || data.isFreightcom === 1) && (
          <div className="flex items-center">
            <img
              className="table-profile-icn-circle-sm"
              src={FreightcomIcon}
              alt="FC"
            />
            <div className="pl-3 font-medium">Freightcom</div>
          </div>
        )}
        {(data.isBanyan || data.isBanyan === 1) && (
          <div className="flex items-center">
            <img
              className="table-profile-icn-circle-sm"
              src={BanyanIcon}
              alt="BANYAN"
            />
            <div className="pl-3 font-medium">Banyan</div>
          </div>
        )}
        {!data.isBanyan && !data.isFreightcom && (
          <div className="flex items-center">
            <img
              className="table-profile-icn-circle-sm"
              src={data.image ? `${data.imageUrl}${data.image}` : defaultImage}
              alt="CR"
              onError={onError}
            />
            <div className="pl-3 font-medium">Direct</div>
          </div>
        )}
      </td>
      <td className="px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]">
        {/* {data.transitTime > 0
          ? `Est. ${data.transitTime} days`
          : data.transitTime === 0
          ? ' Est. Today'
          : '-'} */}
        {addressDetails?.status !== STATUS.WON ? (
          data.expirationDate ? (
            <BadgeCmp
              style="modern"
              type={
                moment(data.expirationDate)
                  .add(1, 'days')
                  .isBefore(moment().startOf('day'))
                  ? 'red'
                  : 'warning'
              }
            >
              {getRemainingDays(data.expirationDate)}
            </BadgeCmp>
          ) : (
            <BadgeCmp
              style="modern"
              type={
                new Date(addressDetails.pickupDate) < new Date()
                  ? 'red'
                  : 'warning'
              }
            >
              {getRemainingDays(data.expirationDate)}
            </BadgeCmp>
          )
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]">
        {data.transitTime > 0 ? <span>Est. {data.transitTime} days</span> : '-'}
      </td>
      <td className="px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]">
        {/* {data.projectedDeliveryDate ? moment(data.projectedDeliveryDate).format("MMM. Do - YYYY") : "-"} */}
        {data.transitTime > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    moment
                      .utc(data.projectedDeliveryDate)
                      .format(`MMM Do - YYYY`)
                  )
                : '-',
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]">
        {addressDetails?.status !== STATUS.WON ||
        addressDetails.finalBookingPrice === data.totalCostCAD
          ? data.carrierQuoteId
            ? currency === CURRENCY.CAD
              ? `$${getFormattedNumber(data.totalCostCAD)}`
              : `$${getFormattedNumber(data.totalCostUSD)}`
            : '-'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(addressDetails?.finalBookingPrice)}`
          : `$${getFormattedNumber(addressDetails?.finalBookingPriceUSD)}`}
      </td>
      <td className="px-5 py-4 w-[172px] min-w-[172px] max-w-[172px]">
        {data.margin === 0
          ? '$0.00'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.margin)}`
          : `$${getFormattedNumber(data.marginUSD)}`}
      </td>
      {/* <td className="px-5 py-4">
        <span>
          {data.totalCharge === 0
            ? '-'
            : currency === CURRENCY.CAD
            ? `$${getFormattedNumber(data.totalCharge)}`
            : `$${getFormattedNumber(data.totalChargeUSD)}`}
        </span>
      </td>
      <td className="px-5 py-4">
        <div className="flex items-center gap-1">
          {data.additionalCharges > 0 || data?.services?.length > 0 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={<ServiceDetail carrierServices={data} />}
            >
              {data.additionalCharges === 0
                ? '$0.00'
                : currency === CURRENCY.CAD
                ? `$${getFormattedNumber(data.additionalCharges)}`
                : `$${getFormattedNumber(data.additionalChargesUSD)}`}
            </ActionTooltip>
          ) : data.additionalCharges === 0 ? (
            '$0.00'
          ) : currency === CURRENCY.CAD ? (
            `$${getFormattedNumber(data.additionalCharges)}`
          ) : (
            `$${getFormattedNumber(data.additionalChargesUSD)}`
          )}
          {data?.services.some((form: any) => !form.isQuoted) ? (
            <TooltipCmp message="Carrier did not provide a rate for this additional service, for this additional service. Please validate feasibility and custom pricing.">
              <InfoCircle className="text-warning500 w-3.5 h-3.5" />
            </TooltipCmp>
          ) : (
            ''
          )}
        </div>
      </td> */}
      <td className="px-5 py-4 text-sm w-[160px] min-w-[160px] max-w-[160px]">
        <span className="text-textSecondary font-bold">
          {addressDetails?.status !== STATUS.WON ||
          addressDetails.finalBookingPrice === data.totalCostCAD
            ? data.carrierQuoteId
              ? currency === CURRENCY.CAD
                ? `$${getFormattedNumber(data.finalCharge)} CAD`
                : `$${getFormattedNumber(data.finalChargeUSD)} USD`
              : '-'
            : currency === CURRENCY.CAD
            ? `$${getFormattedNumber(
                (addressDetails?.finalBookingPrice || 0) + data.margin
              )}`
            : `$${getFormattedNumber(
                (addressDetails?.finalBookingPriceUSD || 0) + data.marginUSD
              )}`}
        </span>
      </td>

      {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
        <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
          {addressDetails.status === STATUS.ACTIVE ? (
            data.carrierQuoteId && (
              <div className="flex justify-end gap-3">
                {data.poq && data.poqUrl ? (
                  <TooltipCmp message="Download POQ">
                    <DownloadCloud02
                      className="w-4 h-4 cursor-pointer"
                      onClick={() =>
                        downloadPdf(
                          `${data.poqUrl}${data.poq}`,
                          `POQ-${data.poq}`
                        )
                      }
                    ></DownloadCloud02>
                  </TooltipCmp>
                ) : (
                  <span className="w-4 h-4">&nbsp;</span>
                )}
                <TooltipCmp message="Delete">
                  <Trash01
                    className="w-4 h-4 cursor-pointer"
                    onClick={() =>
                      handleActiveQuoteDelete(addressDetails.id, data.id)
                    }
                  ></Trash01>
                </TooltipCmp>
                <TooltipCmp message="Modify Pricing">
                  <Edit05
                    className="w-4 h-4 cursor-pointer"
                    onClick={handleActionType('model', 'update', data)}
                  ></Edit05>
                </TooltipCmp>
              </div>
            )
          ) : (
            <></>
          )}
        </td>
      )}
      {/* <td className="px-5 pl-0 py-4">
       
        <CheckBox
          checked={selectedOuoteToGeneratePdf.some(
            (quoteData: any) => quoteData.id === data.id
          )}
          onChangeFunc={(e: any) => {
            e.stopPropagation();

            if (e.target.checked) {
              setSelectedOuoteToGeneratePdf([
                ...selectedOuoteToGeneratePdf,
                data,
              ]);
            } else {
              setSelectedOuoteToGeneratePdf(
                selectedOuoteToGeneratePdf.filter(
                  (qData: any) => qData.id !== data.id
                )
              );
            }
          }}
          parentClassName=""
        />
      </td> */}
    </>
  );
};
export default QuotedRow;
