import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  // useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import { listClaim, listClaimCount } from 'src/services/ClaimService';
import { listDispute, listDisputeCount } from 'src/services/DisputeService';
import { isValidJSON, useRolePermission } from 'src/utils/CommonFunctions';

import ClaimLoaderRow from './Claims/ClaimLoaderRow';
import ClaimResolve from './Claims/ClaimResolve';
import ClaimsRow from './Claims/ClaimsRow';
import EditClaimExplanation from './Claims/EditClaimExplanation';
import ModifyClaimAmount from './Claims/ModifyClaimAmount';
import { FilterArray } from './Claims&Disputes.interface';
import { getNotFoundContent } from './ClaimsDisputes.const';
import DisputeResolve from './Disputes/DisputeResolve';
import DisputesLoaderRow from './Disputes/DisputesLoaderRow';
import DisputesRow from './Disputes/DisputesRow';
import EditDisputeExplanation from './Disputes/EditDisputeExplanation';
// import Notification from './Notification';
import ModifyDisputeAmount from './Disputes/ModifyDisputeAmount';
import OrderNoSalesRepWarning from './OrderNoSalesRepWarning';

export const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  filter: {
    type: 'customer-claims',
    status: 'all',
  },
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const initTabTypeArray: any = [
  {
    value: 'customer-claims',
    name: 'Customer Claims',
    count: 0,
  },
  {
    value: 'carries-disputes',
    name: 'Carrier Disputes',
    count: 0,
  },
];

export const initAction = {
  mode: null,
};

const ClaimsAndDisputes = () => {
  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();

  // const { handleNotificationRead, claimsAndDisputesCount } =
  //   useContext(EntityCountsContext);

  const { entityType } = useContext(NotificationsContext);
  const [tabTypeArray, setTabTypeArray] = useState(initTabTypeArray);
  const { entityCounts } = useContext(EntityCountsContext);
  const [action, setAction] = useState(initAction);
  const [isClaims, setIsClaims] = useState(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.CLAIMS_AND_DISPUTES_LIST
    );

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.filter?.type === 'customer-claims'
      : true;
  });

  const [claims, setClaims] = useState([]);
  const [claim, setClaim] = useState(undefined);
  const [claimTotals, setClaimTotals] = useState(0);
  const [isModifyClaimAmount, setIsModifyClaimAmount] = useState(false);

  const [disputes, setDisputes] = useState([]);
  const [dispute, setDispute] = useState(undefined);
  const [disputeTotals, setDisputeTotals] = useState(0);
  const [isModifyDisputeAmount, setIsModifyDisputeAmount] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [notFoundContent, setNotFoundContent] = useState<any>(null);
  const [
    showClaimDisputeNoSalesRepWarning,
    setShowClaimDisputeNoSalesRepWarning,
  ] = useState<any>(undefined);
  // const [isShowNotification, setIsShowNotification] = useState<any>(null);
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.CLAIMS_AND_DISPUTES_LIST
    );

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });

  const [search, setSearch] = useState(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.CLAIMS_AND_DISPUTES_LIST
    );

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.search || ''
      : '';
  });

  const [currentFilterType, setCurrentFilterType] = useState(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.CLAIMS_AND_DISPUTES_LIST
    );

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.filter?.type || ''
      : 'customer-claims';
  });

  const [currentStatusFilterType, setCurrentStatusFilterType] = useState(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.CLAIMS_AND_DISPUTES_LIST
    );

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.filter?.status || ''
      : 'all';
  });

  // const notificationDivRef = useRef<any>(null);

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const fetchClaimsDisputes = async (claimDisputeApiSignal?: any) => {
    try {
      setIsLoading(true);
      setClaims([]);
      setDisputes([]);

      setNotFoundContent(null);

      let responseData: any = { data: null, total: 0 };

      if (isClaims) {
        const data = { ...params };

        const [claimsResponse, claimsCountResponse]: [any, any] =
          await Promise.all([
            listClaim(data, claimDisputeApiSignal),
            listClaimCount(data, claimDisputeApiSignal),
          ]);

        if (claimsResponse?.data) {
          setClaims(claimsResponse.data);
        }

        setClaimTotals(claimsCountResponse.total);

        responseData = claimsResponse;
      } else {
        const [disputesResponse, disputesCountResponse]: [any, any] =
          await Promise.all([
            listDispute(params, claimDisputeApiSignal),
            listDisputeCount(params, claimDisputeApiSignal),
          ]);

        if (disputesResponse?.data) {
          setDisputes(disputesResponse.data);
        }

        setDisputeTotals(disputesCountResponse.total);

        responseData = disputesResponse;
      }

      if (!responseData?.data?.length) {
        const content = getNotFoundContent(
          false,
          responseData?.data,
          search,
          isClaims
        );
        setNotFoundContent(content);
      }

      setIsLoading(false);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);

    localStorage.setItem(
      TABLE_IDS.CLAIMS_AND_DISPUTES_LIST,
      JSON.stringify(params)
    );

    const claimDisputeApiController = new AbortController();
    const claimDisputeApiSignal = claimDisputeApiController.signal;

    fetchClaimsDisputes(claimDisputeApiSignal);

    return () => {
      claimDisputeApiController.abort();
    };
  }, [params, currentFilterType]);

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchClaim') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const headCellsClaims = useMemo(
    () => [
      {
        id: 'claimID',
        name: 'Claim ID',
        sortable: false,
        visible: true,
        rowClassName: 'w-32',
      },
      {
        id: 'status',
        name: 'Status',
        sortable: false,
        visible: true,
        rowClassName: 'text-xs w-32',
      },
      {
        id: 'orderID',
        name: 'Order ID',
        sortable: false,
        visible: true,
        rowClassName: 'w-32',
      },

      {
        id: 'createdAt',
        name: 'Claim Created',
        sortable: true,
        visible: true,
        rowClassName: 'w-40',
      },
      {
        id: 'customer',
        name: 'Customer',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
        sortable: true,
        visible: true,
        rowClassName: 'w-30',
      },
      {
        id: 'claimAmount',
        name: 'Claim Amount',
        sortable: true,
        visible: true,
        rowClassName: 'w-30',
      },
      {
        id: 'timer',
        name: 'Outstanding',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      // {
      //   id: 'action',
      //   visible: hasRoleV2('finance') || hasRoleV2('admin') ? true : false,
      //   rowClassName: '',
      // },
    ],
    []
  );

  const headCellsDisputes = useMemo(
    () => [
      {
        id: 'disputeId',
        name: 'Dispute ID',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'status',
        name: 'Status',
        sortable: false,
        visible: true,
        rowClassName: 'text-xs ',
      },
      {
        id: 'orderId',
        name: 'Order ID',
        sortable: false,
        visible: true,
        rowClassName: '',
      },

      {
        id: 'createdAt',
        name: 'Dispute Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'carrier',
        name: 'Carrier',
        sortable: false,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'totalPrice',
        name: 'Total Price',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'disputeAmount',
        name: 'Dispute Amount',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      {
        id: 'timer',
        name: 'Timer',
        sortable: true,
        visible: true,
        rowClassName: '',
      },
      // {
      //   id: 'action',
      //   visible: hasRoleV2('finance') || hasRoleV2('admin') ? true : false,
      //   rowClassName: '',
      // },
    ],
    []
  );

  const onRowClick = (data: any) => () => {
    if (data.status === 'New') {
      setAction((old: any) => ({ ...old, mode: 'addClaimDescription' }));
      setClaim(data);
    } else {
      navigate(`${PATH.CLAIM_DETAILS}/${data?.id}`);
    }
  };

  const onDisputeRowClick = (data: any) => () => {
    if (data.status === 'New') {
      setAction((old: any) => ({ ...old, mode: 'addDisputeDescription' }));
      setDispute(data);
    } else {
      navigate(`${PATH.DISPUTE_DETAILS}/${data?.id}`);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  const handleClose = useCallback(() => {
    setAction(initAction);
    setClaim(undefined);
    setDispute(undefined);
    setIsModifyClaimAmount(false);
    setIsModifyDisputeAmount(false);
  }, []);

  // const handleClickOutside = (event: any) => {
  //   if (
  //     notificationDivRef.current &&
  //     !notificationDivRef.current.contains(event.target)
  //   ) {
  //     setIsShowNotification(false);
  //   }
  // };

  // useEffect(() => {
  //   if (isShowNotification) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [isShowNotification]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (claimsAndDisputesCount && claimsAndDisputesCount > 0) {
  //       let type;

  //       if (currentFilterType === 'carries-disputes') {
  //         type = 'dispute';
  //       } else {
  //         type = 'claim';
  //       }
  //       const param = { type: type };
  //       handleNotificationRead(param);
  //     }
  //   }, 200);
  // }, [claimsAndDisputesCount]);

  useEffect(() => {
    if (entityType === 'claim_status') {
      fetchClaimsDisputes();
    }
  }, [entityType]);

  useEffect(() => {
    if (
      (entityCounts.claimsCount && entityCounts?.claimsCount > 0) ||
      (entityCounts.disputesCount && entityCounts?.disputesCount > 0)
    ) {
      const updateTypeArray = tabTypeArray.map((tab: any) => {
        if (tab.name === 'Customer Claims') {
          return {
            ...tab,
            count: entityCounts.claimsCount > 0 ? entityCounts.claimsCount : 0,
          };
        }

        if (tab.name === 'Carrier Disputes') {
          return {
            ...tab,
            count:
              entityCounts.disputesCount > 0 ? entityCounts.disputesCount : 0,
          };
        }

        return tab;
      });

      setTabTypeArray(updateTypeArray);
    }
  }, [entityCounts]);

  const memoizedTabTypeArray = useMemo(
    () => (
      <TabButton
        parentClassName="!bg-gray50 !w-full "
        className="rounded-md text-gray500 !border-0 min-w-[182px] "
        tabParentClassName={`!w-full `}
        activeClassName="!bg-primary100 !text-primary700 "
        tabArray={tabTypeArray}
        isActive={currentFilterType}
        isTab={true}
        handleOnClick={(e: any) => {
          setParams((old: any) => ({
            ...old,
            ...{
              page: 1,
              filter: { ...old.filter, type: e.target.dataset.value },
              sortType: 'desc',
              sortField: 'createdAt',
            },
          }));
          setCurrentFilterType(e?.target?.dataset?.value);
          setIsClaims(e?.target?.dataset?.value === 'customer-claims');
        }}
      />
    ),
    [tabTypeArray, currentFilterType]
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Claims & Disputes`}
            desc={`Manage, segment and view your customers here.`}
            isShowNotificationBox
          />
        }
      >
        <div>
          <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
            {memoizedTabTypeArray}
          </div>
        </div>

        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col flex-1">
          <div className="table-top-header ">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">
                  {isClaims
                    ? hasRoleV2('admin') || hasRoleV2('manager')
                      ? 'All Customer Claims'
                      : 'My Customer Claims'
                    : hasRoleV2('admin') || hasRoleV2('manager')
                    ? 'All Carrier Disputes'
                    : 'My Carrier Disputes'}
                </h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {isClaims ? (
                    <>
                      {claimTotals ?? 0} {claimTotals > 1 ? 'Claims' : 'Claim'}
                    </>
                  ) : (
                    <>
                      {disputeTotals ?? 0}{' '}
                      {disputeTotals > 1 ? 'Disputes' : 'Dispute'}
                    </>
                  )}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                {`View and manage all ${
                  isClaims ? 'customer claims' : 'carrier disputes'
                } of your orders`}
              </p>
            </div>
          </div>

          <div className="table-bottom-header">
            <div className="table-header-bottom-left flex">
              <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
                <TabButton
                  className=""
                  tabArray={FilterArray}
                  activeClassName="!bg-utilityGray100"
                  isActive={currentStatusFilterType}
                  handleOnClick={(e: any) => {
                    setParams((old: any) => ({
                      ...old,
                      filter: { ...old.filter, status: e.target.dataset.value },
                      sortType: 'desc',
                      sortField: 'createdAt',
                      page: 1,
                    }));
                    setCurrentStatusFilterType(e?.target?.dataset?.value);
                  }}
                />
              </div>
            </div>
            <InputText
              inputName="searchClaim"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              parentClassName="table-searchInput"
              onChangeFunc={handleFilter}
              isClearable={true}
            />

            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                options={recordsPerPageArray}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>

          <div className="lg:min-h-[160px] w-full border-t border-gray100 flex-1">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide ">
              {currentFilterType === 'customer-claims' && (
                <TableCmp
                  headCells={headCellsClaims}
                  tableDataArr={claims}
                  TableLoaderRowCmp={ClaimLoaderRow}
                  TableRowCmp={ClaimsRow}
                  tableRowCmpProps={{
                    setAction: setAction,
                    onRowClick: onRowClick,
                    setClaim: setClaim,
                  }}
                  params={params}
                  setParams={setParams}
                  isTableDataLoading={isLoading}
                  numberOfSkeletonRows={10}
                  isTableRowClickable={true}
                />
              )}

              {currentFilterType === 'carries-disputes' && (
                <TableCmp
                  headCells={headCellsDisputes}
                  tableDataArr={disputes}
                  TableLoaderRowCmp={DisputesLoaderRow}
                  TableRowCmp={DisputesRow}
                  tableRowCmpProps={{
                    setAction: setAction,
                    onRowClick: onDisputeRowClick,
                    setDispute: setDispute,
                  }}
                  params={params}
                  setParams={setParams}
                  isTableDataLoading={isLoading}
                  numberOfSkeletonRows={10}
                  isTableRowClickable={true}
                />
              )}
            </div>
            {notFoundContent && (
              <NotFoundUI
                title={notFoundContent?.title}
                desc={notFoundContent?.desc}
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                testId="wal-Claims-dataNotFoundText"
              />
            )}
          </div>

          {(!!claims.length || !!disputes.length) && (
            <div className="w-full bg-white rounded-b-xl">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={isClaims ? claimTotals : disputeTotals}
                currentPage={params.page}
                handlePagination={handlePagination}
              />
            </div>
          )}
        </div>
      </PageSectionLayout>

      {showClaimDisputeNoSalesRepWarning && (
        <OrderNoSalesRepWarning
          handleClose={() => setShowClaimDisputeNoSalesRepWarning(undefined)}
          claimDisputeData={showClaimDisputeNoSalesRepWarning}
        />
      )}

      {/* CLAIM */}
      {action.mode === 'resolveClaim' && (
        <ClaimResolve
          handleClose={handleClose}
          claim={claim}
          getClaimList={fetchClaimsDisputes}
          isClaimListPage={true}
          handleModifyClaimAmount={() => setIsModifyClaimAmount(true)}
        />
      )}

      {isModifyClaimAmount && (
        <ModifyClaimAmount
          claim={claim}
          handleClose={() => setIsModifyClaimAmount(false)}
          getClaim={fetchClaimsDisputes}
          setClaim={setClaim}
        />
      )}

      {action.mode === 'addClaimDescription' && (
        <EditClaimExplanation
          handleClose={handleClose}
          claim={claim}
          title="Open Claim"
          isEdit={false}
          getClaim={fetchClaimsDisputes}
          setShowClaimDisputeNoSalesRepWarning={
            setShowClaimDisputeNoSalesRepWarning
          }
          isClaimAmountUpdatable={true}
        />
      )}

      {/* DISPUTES */}
      {action.mode === 'resolveDispute' && (
        <DisputeResolve
          handleClose={handleClose}
          dispute={dispute}
          isDisputeListPage={true}
          reloadDisputes={fetchClaimsDisputes}
          handleModifyDisputeAmount={() => setIsModifyDisputeAmount(true)}
        />
      )}

      {isModifyDisputeAmount && (
        <ModifyDisputeAmount
          dispute={dispute}
          handleClose={() => setIsModifyDisputeAmount(false)}
          getDisputeDetails={fetchClaimsDisputes}
          setDispute={setDispute}
        />
      )}

      {action.mode === 'addDisputeDescription' && (
        <EditDisputeExplanation
          handleClose={handleClose}
          dispute={dispute}
          title="Open Dispute"
          isEdit={false}
          getDispute={fetchClaimsDisputes}
          setShowClaimDisputeNoSalesRepWarning={
            setShowClaimDisputeNoSalesRepWarning
          }
          isDisputeAmountUpdatable={true}
        />
      )}
    </>
  );
};

export default ClaimsAndDisputes;
