import { PhoneCall01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import TooltipCmp from 'src/components/TooltipCmp';

const PredictivePricingLoaderRow = () => (
  <>
    <td className="px-5 py-4 min-w-[200px] max-w-[1px] w-[15%]">
      <div className="flex items-center gap-3">
        <div className="table-profile-wrap custom-loading">
          <div className="table-profile-customer-circle-xl rounded-md">WA</div>
        </div>
        <div className="truncate custom-loading">
          <TooltipCmp>
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              Maritime Ontario
            </h6>
          </TooltipCmp>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            +1 (315) 623-3438
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
      <span className="custom-loading">LTL LTL</span>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[1px] min-w-[17.03%]">
      <TooltipCmp>
        <p className="truncate custom-loading">Etobicoke, ON, Canada</p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[1px] min-w-[17.03%]">
      <TooltipCmp>
        <p className="truncate custom-loading">Stillwater, OK 74078, USA</p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[200px] min-w-[200px]">
      <span className="custom-loading">Dec 5th, 2024, 10:20 </span>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[1px] min-w-[17.03%]">
      <div className="truncate w-fit max-w-full custom-loading">
        <ActionTooltip
          openOnHover={true}
          tooltipPosition="center"
          parentClassName="max-w-[320px]"
          isOpenChildTopPosOnTop={true}
          type="dark"
          message="Dry Van"
        >
          <p className="truncate ">Dry Van Dry Van</p>
        </ActionTooltip>
      </div>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
      <span className="font-semibold custom-loading"> 10 Ft. 10 Ft.</span>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
      <span className="font-semibold custom-loading">15000 lbs</span>
    </td>
    <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
      <span className="font-semibold custom-loading">$280</span>
    </td>
    <td className="px-5 py-4 w-[70px] max-w-[70px] min-w-[70px]">
      <div className="custom-loading w-4 h-4">
        <PhoneCall01 className="w-4 h-4 text-gray400" />
      </div>
    </td>
  </>
);

export default PredictivePricingLoaderRow;
