import { ArrowDown, ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import TabButton from 'src/components/TabButton';
import { CustomerKeyMetrics } from 'src/services/CustomerService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import CustomerGroupOrdersTable from './CustomerGroupOrdersTable';

interface IProps {
  customerGroupDetail: any;
}

const CustomerGroupDetailOverviewTab = ({ customerGroupDetail }: IProps) => {
  const [dayFilter, setDayFilter] = useState<any>('30');
  const [customerOrdersData, setCustomerOrdersData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupCustomersIds, setGroupCustomersIds] = useState([]);

  useEffect(() => {
    const keyMetricsApiController = new AbortController();
    const keyMetricsApiSignal = keyMetricsApiController.signal;

    if (groupCustomersIds?.length > 0) {
      setCustomerOrdersData([]);
      setIsLoading(true);
      CustomerKeyMetrics(
        { customerId: groupCustomersIds, timeFrame: dayFilter },
        keyMetricsApiSignal
      )
        .then((response: any) => {
          setCustomerOrdersData(response.data ?? []);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);

          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsLoading(false);
        });
    }

    return () => {
      keyMetricsApiController.abort();
    };
  }, [groupCustomersIds, dayFilter]);

  useEffect(() => {
    const cusIds = customerGroupDetail?.customers?.map((c: any) => c?.id);
    setGroupCustomersIds(cusIds);
  }, [customerGroupDetail]);

  const tabArray: any = [
    {
      value: '30',
      name: '30 Days',
      isAllow: true,
    },
    {
      value: '90',
      name: '90 Days',
      isAllow: true,
    },
    {
      value: '180',
      name: '180 Days',
      isAllow: true,
    },
    {
      value: '360',
      name: '360 Days',
      isAllow: true,
    },
    {
      value: 'all',
      name: 'All Time',
      isAllow: true,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mt-8 flex-wrap">
        <div>
          <h6 className="text-grayLight900 text-lg font-semibold sm:mb-0 mb-1">
            Key Metrics
          </h6>
        </div>

        <TabButton
          parentClassName="!bg-gray50 "
          className={`!rounded-md !border-0 [&>span]:text-gray400`}
          activeClassName=" !bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md "
          tabArray={tabArray.filter((e: any) => e.isAllow)}
          isActive={dayFilter}
          isTab={true}
          handleOnClick={(e: any) => {
            setDayFilter(e.target.dataset.value);
          }}
        />
      </div>
      <ul className="flex -m-2 flex-wrap mt-1.5">
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Total Orders
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.totalOrders > 0
                      ? customerOrdersData?.totalOrders
                      : 0}
                  </span>
                </div>

                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.totalOrdersDiff >= 0 ? (
                      <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.totalOrdersDiff
                          ? Math.abs(customerOrdersData?.totalOrdersDiff)
                          : 0}{' '}
                        %
                      </span>{' '}
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Total Margin
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.totalMargin > 0
                      ? getFormattedNumber(customerOrdersData?.totalMargin)
                      : 0}
                  </span>
                  <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                    $
                  </sup>
                </div>
                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.totalMarginDiff >= 0 ? (
                      <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.totalMarginDiff
                          ? Math.abs(customerOrdersData?.totalMarginDiff)
                          : 0}{' '}
                        %
                      </span>{' '}
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Margin Percentage
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.avgMarginPercentage > 0
                      ? customerOrdersData?.avgMarginPercentage
                      : 0}
                  </span>
                  <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                    %
                  </sup>
                </div>
                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.avgMarginPercentageDiff >= 0 ? (
                      <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.avgMarginPercentageDiff
                          ? Math.abs(
                              customerOrdersData?.avgMarginPercentageDiff
                            )
                          : 0}{' '}
                        %
                      </span>{' '}
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="w-full justify-between flex items-start">
              <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                Average Order Value
              </h6>
            </div>
            <div className="w-full flex justify-between pt-2 ">
              <div className="w-full flex flex-col justify-end">
                <div className={` ${isLoading ? 'w-fit custom-loading' : ''}`}>
                  <span className="font-semibold text-2xl">
                    {customerOrdersData?.avgOrderValue > 0
                      ? getFormattedNumber(customerOrdersData?.avgOrderValue)
                      : 0}
                  </span>
                  <sup className="text-sm font-semibold leading-tight xxl:-top-[0.7em]">
                    $
                  </sup>
                </div>
                <div className="w-full flex  justify-between ">
                  <div
                    className={`w-fit flex justify-center items-center mt-2 ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {customerOrdersData?.avgOrderValueDiff >= 0 ? (
                      <>
                        <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                      </>
                    ) : (
                      <ArrowDown className="w-4 h-4 flex-none  text-danger" />
                    )}

                    <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                      <span className="xxl:text-sm text-xs">
                        {customerOrdersData?.avgOrderValueDiff
                          ? Math.abs(customerOrdersData?.avgOrderValueDiff)
                          : 0}
                        %
                      </span>
                      &nbsp;vs. Last {dayFilter} Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <CustomerGroupOrdersTable groupCustomersIds={groupCustomersIds} />
    </>
  );
};

export default CustomerGroupDetailOverviewTab;
