import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { OrderSlice } from 'src/app/NewOrder/Order.slice';

import { feedBackSlice } from '../app/Feedback/Feedback.slice';
import { teamSlice } from '../app/Teams/Teams.slice';

import { CallCenter } from './CallCenter.slice';
import { mobileViewSlice } from './MobileView.slice';
import { SocketConnection } from './SocketConnection.slice';

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['CallCenter'],
  whitelist: [],
};
const rootReducer = combineReducers({
  Team: teamSlice.reducer,
  Feedback: feedBackSlice.reducer,
  MobileView: mobileViewSlice.reducer,
  SocketConnection: SocketConnection.reducer,
  Order: OrderSlice.reducer,
  CallCenter: CallCenter.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export default setupStore;
