import React, { useContext, useState } from 'react';

import { OrderContext } from '..';
import { initShipperAddress } from '../../order.interface';

import LegOrderDetailsModal from './LegOrderDetailsModal';
import SetMiddlePointModal from './SetMiddlePointModal';
import SplitLegModal from './SplitLegModal';

interface SplitLegDetailsProps {
  isShowSplitLegModal: boolean;
  setIsShowSplitLegModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const initLegFormData = {
  parentLegId: null,
  parentLegPosition: null,
  legAddress: initShipperAddress,
  pickupOrDropOff: null,
  additionalServices: [],
  lineItems: [],
};

const SplitLegDetails = ({
  isShowSplitLegModal,
  setIsShowSplitLegModal,
}: SplitLegDetailsProps) => {
  const {
    // currentLegData,
    handlingUnitOptions,
    serviceTypes,
    tabValues,
    id,
    refreshPage,
    setIsMasterOrder,
  } = useContext<any>(OrderContext);

  const [legFormData, setLegFormData] = useState(initLegFormData);
  const [isShowLegOrderDetailModal, setIsShowLegOrderDetailModal] =
    useState(false);
  const [isShowSetMiddlePointModal, setIsShowSetMiddlePointModal] =
    useState(false);
  const [splitLegData, setSplitLegData] = useState<any>(null);

  // const combinedOrderDimensions = [
  //   ...legData
  //     .flatMap((leg: any) =>
  //       (leg?.order_dimensions || [])
  //         .filter((dimension: any) => dimension)
  //         .map((dimension: any) => {
  //           if ((dimension && !dimension?.id) || dimension?.dropLegId != null) {
  //             return null;
  //           }

  //           return {
  //             ...dimension,
  //             shipper: leg?.shipper?.shipperAddress,
  //             consignee: leg?.consignee?.consigneeAddress,
  //             legId: leg?.legId,
  //           };
  //         })
  //     )
  //     .filter((item: any) => item !== null),
  // ];

  const handleSubmit = () => {
    setIsShowSplitLegModal(false);
    setIsShowSetMiddlePointModal(true);
  };

  const closeLegOrderDetailsModal = () => {
    setIsShowLegOrderDetailModal(false);
    setLegFormData(initLegFormData);
    setSplitLegData(null);
  };

  return (
    <>
      {isShowSplitLegModal && (
        <SplitLegModal
          setSplitLegData={setSplitLegData}
          splitLegData={splitLegData}
          handleClose={() => {
            setIsShowSplitLegModal(false);
            setLegFormData(initLegFormData);
            setSplitLegData(null);
          }}
          handleSubmit={handleSubmit}
          tabValues={tabValues}
          setLegFormData={setLegFormData}
        />
      )}

      {isShowSetMiddlePointModal && (
        <SetMiddlePointModal
          handleClose={() => {
            setIsShowSetMiddlePointModal(false);
            setLegFormData(initLegFormData);
            setSplitLegData(null);
          }}
          splitLegData={splitLegData}
          legFormData={legFormData}
          setLegFormData={setLegFormData}
          handleSubmit={() => {
            setIsShowSetMiddlePointModal(false);
            setIsShowLegOrderDetailModal(true);
          }}
        />
      )}

      {isShowLegOrderDetailModal && (
        <LegOrderDetailsModal
          handleClose={closeLegOrderDetailsModal}
          combinedOrderDimensions={splitLegData?.order_dimensions}
          handlingUnitOptions={handlingUnitOptions}
          serviceTypes={serviceTypes}
          setLegFormData={setLegFormData}
          legFormData={legFormData}
          masterOrderId={id}
          refreshPage={refreshPage}
          setIsMasterOrder={setIsMasterOrder}
        />
      )}
    </>
  );
};

export default SplitLegDetails;
