import { FilePlus03 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect } from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  onError,
} from 'src/utils/CommonFunctions';

interface IProps {
  handleClose: any;
  quotedList: any;
  currency: any;
  selectedDisplayOptions: any;
  setSelectedDisplayOptions: any;
  selectedLanguage: any;
  handleLanguageSelect: any;
  selectedQuoteToGeneratePdfId: any;
  setSelectedQuoteToGeneratePdfId: any;
  handleGeneratePdf: any;
  isPdfGenerating: any;
}

const GeneratePdfModal = ({
  handleClose,
  quotedList = [],
  currency,
  selectedDisplayOptions,
  setSelectedDisplayOptions,
  selectedLanguage,
  handleLanguageSelect,
  selectedQuoteToGeneratePdfId,
  setSelectedQuoteToGeneratePdfId,
  handleGeneratePdf,
  isPdfGenerating,
}: IProps) => {
  useEffect(() => {
    if (quotedList?.length === 1)
      setSelectedQuoteToGeneratePdfId(quotedList.map((data: any) => data.id));
  }, [quotedList]);

  return (
    <CommonModal
      title={'Generate PDF'}
      titleDesc={'Select the active quote you would like to move forward with'}
      handleClose={() => handleClose(false)}
      headerIcon={<FilePlus03 />}
      size={'max-w-[688px]'}
      isOverflow={true}
      modalClassName=""
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnText="Draft Email"
      primaryBtnDisabled={!selectedQuoteToGeneratePdfId.length}
      primaryBtnOnClick={() => handleGeneratePdf()}
      primaryBtnLoading={isPdfGenerating}
    >
      <div className="w-full p-5 flex flex-col gap-y-4">
        <div>
          <h6 className="form_label block mb-1.5">Select a Language</h6>
          <div className="rounded-lg border border-utilityGray200 p-3 flex gap-x-5">
            <div
              className="flex gap-x-2.5 items-center cursor-pointer min-w-[152px]"
              onClick={() => handleLanguageSelect('english')}
            >
              <CheckBox
                checked={selectedLanguage === 'english'}
                onChangeFunc={() => {}}
              ></CheckBox>
              <h6 className="text-grayLight900 text-xs font-normal">English</h6>
            </div>
            <div
              className="flex gap-x-2.5 items-center cursor-pointer"
              onClick={() => handleLanguageSelect('french')}
            >
              <CheckBox
                checked={selectedLanguage === 'french'}
                onChangeFunc={() => {}}
              ></CheckBox>
              <h6 className="text-grayLight900 text-xs font-normal">French</h6>
            </div>
          </div>
        </div>
        <div>
          <h6 className="form_label block mb-1.5">Display Settings</h6>
          <div className="rounded-lg border border-utilityGray200 p-3 flex gap-x-5">
            <div
              className="flex gap-x-2.5 items-center cursor-pointer min-w-[152px]"
              onClick={() =>
                setSelectedDisplayOptions((prevState: any) => ({
                  ...prevState,
                  displayCarrierNames: !prevState?.displayCarrierNames,
                }))
              }
            >
              <CheckBox
                checked={selectedDisplayOptions.displayCarrierNames}
                onChangeFunc={() => {}}
                // onChangeFunc={(e: any) =>
                //   setSelectedDisplayOptions((prevState: any) => ({
                //     ...prevState,
                //     displayCarrierNames: e.target.checked,
                //   }))
                // }
              ></CheckBox>
              <h6 className="text-grayLight900 text-xs font-normal">
                Display Carrier Names
              </h6>
            </div>
            <div
              className="flex gap-x-2.5 items-center cursor-pointer"
              onClick={() =>
                setSelectedDisplayOptions((prevState: any) => ({
                  ...prevState,
                  displayDeliveryTime: !prevState?.displayDeliveryTime,
                }))
              }
            >
              <CheckBox
                checked={selectedDisplayOptions.displayDeliveryTime}
                onChangeFunc={() => {}}
                // onChangeFunc={(e: any) =>
                //   setSelectedDisplayOptions((prevState: any) => ({
                //     ...prevState,
                //     displayDeliveryTime: e.target.checked,
                //   }))
                // }
              ></CheckBox>
              <h6 className="text-grayLight900 text-xs font-normal ">
                Display Est. Delivery & Transit Time
              </h6>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div className="flex gap-x-3 justify-between mb-1.5">
            <h6 className="form_label block mb-0">Select Quote Options</h6>
            <p
              className="cursor-pointer text-primary text-xs font-semibold"
              onClick={() => {
                let isChecked =
                  quotedList.length > 0 &&
                  quotedList.every((data: any) =>
                    selectedQuoteToGeneratePdfId.includes(data.id)
                  );

                if (!isChecked) {
                  setSelectedQuoteToGeneratePdfId(
                    quotedList.map((data: any) => data.id)
                  );
                } else {
                  setSelectedQuoteToGeneratePdfId([]);
                }
              }}
            >
              Select All
            </p>
            {/* <CheckBox
              checked={
                quotedList.length > 0 &&
                quotedList.every((data: any) =>
                  selectedQuoteToGeneratePdfId.includes(data.id)
                )
              }
              onChangeFunc={(e: any) => {
                const checked = e.target.checked;

                if (checked) {
                  setSelectedQuoteToGeneratePdfId(
                    quotedList.map((data: any) => data.id)
                  );
                } else {
                  setSelectedQuoteToGeneratePdfId([]);
                }
              }}
            ></CheckBox> */}
          </div>
          <ul className="flex flex-col gap-y-4">
            {quotedList.map((data: any, index: any) => (
              <li
                key={index}
                className="flex cursor-pointer rounded-lg border border-utilityGray200 p-4"
                onClick={() =>
                  setSelectedQuoteToGeneratePdfId((prevState: any) => {
                    if (prevState.includes(data.id)) {
                      return prevState.filter((id: any) => id !== data.id);
                    } else {
                      return [...prevState, data.id];
                    }
                  })
                }
              >
                <div className="flex flex-1 gap-y-2 flex-wrap">
                  <div className="flex items-center gap-x-1.5 w-[38%]">
                    {data.image ? (
                      <img
                        src={`${data.imageUrl}${data.image}`}
                        className="table-profile-icn-circle-xl rounded-md"
                        alt={data.name}
                        title={data.name}
                        onError={onError}
                      />
                    ) : (
                      <div className="table-profile-customer-circle-xl rounded-md">
                        WA
                      </div>
                    )}
                    <div className="w-[calc(100%_-_52px)]">
                      {(data.name + (data.banyanPostFix || '')).length > 30 ? (
                        <TooltipCmp
                          message={`${data.name} ${data.banyanPostFix || ''}`}
                        >
                          <h6 className="text-grayLight900 text-sm font-medium truncate">
                            {`${data.name} ${data.banyanPostFix || ''}`}
                          </h6>
                        </TooltipCmp>
                      ) : (
                        <h6 className="text-grayLight900 text-sm font-medium truncate">
                          {`${data.name} ${data.banyanPostFix || ''}`}
                        </h6>
                      )}
                      <p className="text-grayLight600 text-xs font-medium truncate">
                        #{data.carrierQuoteId}
                      </p>
                    </div>
                  </div>
                  <div className="min-w-[31%] flex flex-col justify-between">
                    <h6 className="text-grayLight600 text-xs font-medium">
                      Est. Delivery Date
                    </h6>
                    <p className="text-grayLight900 text-xs font-medium">
                      {data.transitTime > 0 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data.projectedDeliveryDate
                              ? getDateWithSuffixFormat(
                                  moment
                                    .utc(data.projectedDeliveryDate)
                                    .format(`MMM Do - YYYY`)
                                )
                              : '-',
                          }}
                        />
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                  <div className="min-w-[31%] flex flex-col justify-between">
                    <h6 className="text-grayLight600 text-xs font-medium">
                      Total Price
                    </h6>
                    <p className="text-grayLight900 text-xs font-medium">
                      {currency === CURRENCY.CAD
                        ? `$${getFormattedNumber(data.finalCharge)} CAD`
                        : `$${getFormattedNumber(data.finalChargeUSD)} USD`}
                    </p>
                  </div>
                </div>
                <CheckBox
                  checked={selectedQuoteToGeneratePdfId.includes(data.id)}
                  onChangeFunc={() => {}}
                  classes="self-center"
                ></CheckBox>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </CommonModal>
  );
};
export default GeneratePdfModal;
