import { Edit05 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import CreditIndexChart from 'src/app/Customers/CustomerDetailCreditTab/CreditIndexChart';

import logomark from '../../../assets/img/Logomark.svg';

const CustomerCreditInfo = ({
  creditLimitDetail,
  isCreditLimitDetailLoading,
  setIsDisplayCreditInfo,
}: any) => {
  console.log('creditLimitDetail', creditLimitDetail);
  const [customerCreditDetail, setCustomerCreditDetail] = useState<any>();
  useEffect(() => {
    let creditLimit = {
      creditValue: creditLimitDetail?.common_value,
      creditDescription: creditLimitDetail?.common_description,
    };

    setCustomerCreditDetail(creditLimit);
  }, [creditLimitDetail]);

  return (
    <div className="flex flex-col gap-y-2">
      <ul className="flex flex-col gap-y-4">
        <li>
          <div className="flex justify-between items-center gap-x-2 mb-2">
            <h6 className="text-grayLight900 text-sm font-medium">
              {creditLimitDetail?.company_name}
            </h6>
            <Edit05
              className="w-4 h-4 text-gray400 cursor-pointer"
              onClick={() => setIsDisplayCreditInfo(false)}
            />
          </div>
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="flex justify-between items-center mb-5 z-[1] relative">
              <h6 className="grayLight900 text-sm font-semibold">
                Credit Rating
              </h6>
              <div className="hover-text-wrap">
                <div className="hover-text overflow-hidden border border-utilityGray200 rounded cursor-pointer flex items-center ">
                  <img src={logomark} />
                  <span className="font-semibold text-[#0d442e] text-xs leading-[20px] transition-all duration-[0.5s] w-0 opacity-0 creditsafe-text text-nowrap ">
                    By Creditsafe
                  </span>
                </div>
              </div>
            </div>
            <CreditIndexChart customerCreditDetail={customerCreditDetail} />
          </div>
        </li>
        {/* <li className="space-y-2">
          <h6 className="text-textSecondary text-xs font-medium">
            Common Value
          </h6>
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="flex justify-between gap-x-1 py-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Common Description
              </h6>
              <p
                className={`text-grayLight900 text-xs font-medium ${
                  isCreditLimitDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {creditLimitDetail?.common_description ?? '-'}
              </p>
            </div>
            <div className="flex justify-between gap-x-1 py-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Common Value
              </h6>
              <p
                className={`text-grayLight900 text-xs font-medium ${
                  isCreditLimitDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {creditLimitDetail?.common_value ?? '-'}
              </p>
            </div>
          </div>
        </li> */}
        <li className="space-y-2">
          <h6 className="text-textSecondary text-xs font-medium">
            Credit Limit
          </h6>
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="flex justify-between gap-x-1 py-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Currency
              </h6>
              <p
                className={`text-grayLight900 text-xs font-medium ${
                  isCreditLimitDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {creditLimitDetail?.currency ?? '-'}
              </p>
            </div>
            <div className="flex justify-between gap-x-1 py-1">
              <h6 className="text-grayLight600 text-xs font-normal">Value</h6>
              <p
                className={`text-grayLight900 text-xs font-medium ${
                  isCreditLimitDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {creditLimitDetail?.credit_value ?? '-'}
              </p>
            </div>
          </div>
        </li>
        <li className="space-y-2">
          <h6 className="text-textSecondary text-xs font-medium">
            Provider Value
          </h6>
          <div className="rounded-lg border border-utilityGray200 p-3">
            <div className="flex justify-between gap-x-1 py-1">
              <h6 className="text-grayLight600 text-xs font-normal">Value</h6>
              <p
                className={`text-grayLight900 text-xs font-medium ${
                  isCreditLimitDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {creditLimitDetail?.provider_value ?? '-'}
              </p>
            </div>
            <div className="flex justify-between gap-x-1 py-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Provider Description
              </h6>
              <p
                className={`text-grayLight900 text-xs font-medium ${
                  isCreditLimitDetailLoading ? 'custom-loading' : ''
                }`}
              >
                {creditLimitDetail?.provider_description ?? '-'}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default CustomerCreditInfo;
