import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import _, { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import DateRangePicker from 'src/components/DateRangePicker';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { TIME_FILTER } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import useCountUp from 'src/hooks/useCountUp';
import {
  getAnalytics,
  getCustomerSplit,
  getTeamPerformance,
} from 'src/services/QuoteService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  convertSecondsToTime,
  getDateRange,
  getFormattedNumber,
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../../assets/img/Avatar7.png';

import KpiCard from './KpiCard';
import QuoteRequestsGraph from './QuoteRequestsGraph';
import QuotingSplitsLoadingRow from './QuotingSplitsLoadingRow';
import QuotingSplitsRow from './QuotingSplitsRow';
import TotalQuotesGraph from './TotalQuotesGraph';

// const recordsPerPageArray = [
//   { label: '25', value: 25 },
//   { label: '50', value: 50 },
//   { label: '100', value: 100 },
// ];

const teamTabArray: any[] = [
  {
    value: 'teamPerformance',
    name: 'Team Performance',
    count: 0,
  },
  {
    value: 'customerSplit',
    name: 'Customer Split',
    count: 0,
  },
];

interface IAnalytics {
  totalHits: number;
  totalClassificationAccuracy: number;
  totalOrders: number;
  totalQuotes: number;
  totalRequests: number;
  totalWonLoss: number;
  totalHitsGrowth: number;
  totalQuotesGrowth: number;
  totalClassificationAccuracyGrowth: number;
  totalOrdersGrowth: number;
  totalWonLossGrowth: number;
  totalRequestsGrowth: number;
  avgTimeToClassify: number;
  avgTimeToQuote: number;
  avgTimeToClassifyGrowth: number;
  avgTimeToQuoteGrowth: number;
  quotingPercentage: number;
  quotingPercentageGrowth: number;
  graph: {
    labels: string[];
    value: {
      hit: number[];
      requests: number[];
      orders: number[];
      quotes: number[];
    };
  };
  requestQuotePieChartData: {
    labels: string[];
    values: number[];
    totalValue: number;
  };
  totalQuotePieChartData: {
    labels: string[];
    values: number[];
    totalValue: number;
  };
}

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  type: 'common',
  provider: '',
  member: 'all',
  team: 'all',
};

const QuotingAnalytics = () => {
  const { hasRoleV2 } = useRolePermission();
  const { appearance, allTimeStartDateQuote } = useContext(BasicContext);
  const [teamOptions, setTeamOptions] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>('all');
  const [selectedTeam, setSelectedTeam] = useState<string | null>('all');
  const [activeTableTab, setActiveTableTab] = useState(teamTabArray[0].value);
  const [search, setSearch] = useState('');
  const [quotesData, setQuotesData] = useState<any[]>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState<IAnalytics>({
    totalHits: 0,
    totalClassificationAccuracy: 0,
    totalOrders: 0,
    totalQuotes: 0,
    totalRequests: 0,
    totalWonLoss: 0,
    totalHitsGrowth: 0,
    totalQuotesGrowth: 0,
    totalClassificationAccuracyGrowth: 0,
    totalOrdersGrowth: 0,
    totalWonLossGrowth: 0,
    totalRequestsGrowth: 0,
    avgTimeToClassify: 0,
    avgTimeToQuote: 0,
    avgTimeToClassifyGrowth: 0,
    avgTimeToQuoteGrowth: 0,
    quotingPercentage: 0,
    quotingPercentageGrowth: 0,
    graph: {
      labels: [],
      value: {
        hit: [],
        requests: [],
        orders: [],
        quotes: [],
      },
    },
    requestQuotePieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
    totalQuotePieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
  });
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');
  const [boxLabel, setBoxLabel] = useState('Yesterday');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [activeFilter, setActiveFilter] = useState(() => 'daily');
  const [params, setParams] = useState(initParams);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => 'custom'
  );
  const [startDate, setStartDate] = useState<any>(() => '');
  const [endDate, setEndDate] = useState<any>(() => '');
  const [userList, setUserList] = useState<any[]>([]);
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');

  const headCells = useMemo(
    () => [
      {
        id: activeTableTab === 'teamPerformance' ? 'user' : 'name',
        name: activeTableTab === 'teamPerformance' ? 'User' : 'Customer',
      },
      {
        id: 'totalQuoteRequests',
        name: 'Total Quote Requests',
      },
      {
        id: 'avgTimeClassify',
        name: 'Avg. Time to Classify',
      },
      {
        id: 'totalQuotes',
        name: 'Total Quotes',
      },
      {
        id: 'avgTimeQuote',
        name: 'Avg. Time to Quote',
      },

      {
        id: 'totalOrders',
        name: 'Total Orders',
      },
      {
        id: 'winLoss',
        name: 'Win/Loss',
      },
    ],
    [activeTableTab]
  );

  useEffect(() => {
    setFilterAfterPrevNext(activeFilter);

    if (activeFilter !== '') {
      const { start, end } = getDateRange(activeFilter);
      setStartDate(start);
      setEndDate(end);
      setIsPrevYearDisable(false);
    }

    if (activeFilter === 'weekly') {
      setBoxLabel('Last Week');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'monthly') {
      setBoxLabel('Last Month');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'yearly') {
      setBoxLabel('Last Year');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'daily') {
      setBoxLabel('Yesterday');
      setSelectedTimeRange('custom');
    }
  }, [activeFilter]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamOptions(teamData);
        // setSelectedTeam('all');
        // setSelectedUser('all');
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (teamOptions && teamOptions?.length > 0) {
      let uniqueArr = [];

      if (selectedTeam === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamOptions.map((tlo) => tlo.members);
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
        setSelectedUser('all');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamOptions.find((tlo) => tlo.value === selectedTeam);
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      console.log('updatedListAllMembers', updatedListAllMembers);
      console.log('teamOptions', teamOptions);
      setUserList(updatedListAllMembers);
    }
  }, [selectedTeam, teamOptions]);

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateQuote);

    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateQuote);
    }
  }, [allTimeStartDateQuote]);

  const getFilterRange = (type?: string) => {
    setFilterAfterPrevNext(activeFilter);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      // setIsNextYearDisable(false);
      if (activeFilter === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (activeFilter === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      // if (moment(endDT, "DD-MM-YYYY").isSameOrAfter(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"))) {
      //     setIsNextYearDisable(true);
      // }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(activeFilter);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const getAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const param = {
        startDate: moment(startDate).format('DD/MM/YYYY'),
        endDate: moment(endDate).format('DD/MM/YYYY'),
        type: activeFilter,
        userId: selectedUser === 'all' ? null : selectedUser,
        teamId: selectedTeam === 'all' ? null : selectedTeam,
      };

      const abortController = new AbortController();
      const signal = abortController.signal;
      const response: any = await getAnalytics(param, signal);

      if (response?.data) {
        console.log('response', response);
        setAnalyticsData({
          totalHits: response?.data?.totalHits,
          totalClassificationAccuracy:
            response?.data?.totalClassificationAccuracy,
          totalOrders: response?.data?.totalOrders,
          totalQuotes: response?.data?.totalQuotes,
          totalRequests: response?.data?.totalRequests,
          totalWonLoss: +response?.data?.totalWonLoss,
          totalHitsGrowth: +response?.data?.totalHitsGrowth,
          totalQuotesGrowth: +response?.data?.totalQuotesGrowth,
          totalClassificationAccuracyGrowth:
            +response?.data?.totalClassificationAccuracyGrowth,
          totalOrdersGrowth: +response?.data?.totalOrdersGrowth,
          totalWonLossGrowth: +response?.data?.totalWonLossGrowth,
          totalRequestsGrowth: +response?.data?.totalRequestsGrowth,
          avgTimeToClassify: +response?.data?.avgTimeToClassify,
          avgTimeToQuote: +response?.data?.avgTimeToQuote,
          avgTimeToClassifyGrowth: +response?.data?.avgTimeToClassifyGrowth,
          avgTimeToQuoteGrowth: +response?.data?.avgTimeToQuoteGrowth,
          quotingPercentage: +response?.data?.quotingPercentage,
          quotingPercentageGrowth: +response?.data?.quotingPercentageGrowth,
          graph: {
            labels: response?.data?.categories,
            value: response?.data?.data,
          },
          totalQuotePieChartData: response?.data?.totalQuotePieChartData,
          requestQuotePieChartData: response?.data?.requestQuotePieChartData,
        });
      }
    } catch (e: any) {
      //   setError(error.message);
      if (e.code === 'ERR_CANCELED') {
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) getAnalyticsData();
  }, [startDate, endDate, selectedUser, selectedTeam]);

  const getTeamPerformanceData = async () => {
    try {
      setQuotesData([]);
      setTotals(0);
      setIsTableDataLoading(true);
      const payload = {
        ...params,
        startDate: moment(startDate).format('DD/MM/YYYY'),
        endDate: moment(endDate).format('DD/MM/YYYY'),
        member: selectedUser ?? 'all',
        team: selectedTeam ?? 'all',
      };
      let response: any = {};
      console.log('payload', payload);

      const abortController = new AbortController();
      const signal = abortController.signal;

      if (activeTableTab === 'teamPerformance')
        response = await getTeamPerformance(payload, signal);
      else {
        response = await getCustomerSplit(payload, signal);
      }
      console.log('response', response);

      if (response?.data) {
        setQuotesData(response?.data);
        setTotals(response?.total);
      }
    } catch (e: any) {
      //   setError(error.message);
      if (e.code === 'ERR_CANCELED') {
        return;
      }
    } finally {
      setIsTableDataLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) getTeamPerformanceData();
  }, [params, startDate, endDate, selectedUser, selectedTeam, activeTableTab]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch, page: 1 } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  useEffect(() => {
    if (selectedUser && selectedUser !== 'all')
      setActiveTableTab('customerSplit');
  }, [selectedUser]);

  useEffect(() => {
    console.log(
      'analyticsData.requestQuotePieChartData ',
      analyticsData.requestQuotePieChartData
    );
    console.log(
      'analyticsData.totalQuotePieChartData ',
      analyticsData.totalQuotePieChartData
    );
  }, [analyticsData]);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quoting Analytics"
          desc="Current quoting analytics and activity."
          // rightSideContent={rightSideContent}
          mainContainerClassName="!flex-row "
          leftClassName="!w-auto"
          rightClassName="xxl:!w-[30%] xlm:!w-[32%] xl:!w-[27%] sm:!w-auto !w-full"
        />
      }
    >
      <div className="h-full w-full xxl:gap-4 xl:gap-3 gap-3 flex flex-col">
        <div
          className={`w-full justify-between sm:items-center flex  xls:flex-row flex-col flex-wrap xxl:gap-4 xl:gap-3 gap-3 `}
        >
          <div
            className={`flex-1 flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full`}
          >
            <TabButton
              className="leading-4 sm:w-auto w-1/4 flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
              activeClassName="!bg-utilityGray100"
              tabArray={TIME_FILTER}
              parentClassName="w-full"
              tabParentClassName="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full"
              isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
              handleOnClick={(e: any) => {
                if (activeFilter === e.target.dataset.value) getFilterRange();
                setActiveFilter(e.target.dataset.value);
              }}
            />

            <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full items-center cursor-pointer justify-between flex">
              <DateRangePicker
                parentClassName="sales-datepicker"
                handleDatePickerChange={(dates: any, type?: string) =>
                  handleDatePickerChange(dates, type)
                }
                selectedTimeRange={selectedTimeRange}
                startDate={startDate}
                endDate={endDate}
                allTimeStartDate={allTimeStartDate}
                showYearDropdown={false}
                isToday={activeFilter === 'daily'}
                isShowDropDownIcon={false}
                containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
                isPrevDisabled={isPrevYearDisable}
                onPrevNextClick={(type: string) => getFilterRange(type)}
              />
            </div>
          </div>

          <div className="flex sm:flex-row  flex-wrap xxl:gap-4 gap-3 sm:justify-end xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full empty:hidden ">
            <>
              <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  options={teamOptions}
                  onChangeFunc={(event: any) => {
                    setSelectedTeam(event?.value ?? 'all');
                    setSelectedUser('all');
                  }}
                  value={teamOptions.filter(
                    (val) => val?.value == selectedTeam
                  )}
                />
              </div>
              <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  noOptionMessage="No Users Found"
                  isSearchable={true}
                  className="form_control"
                  isClearable={true}
                  options={userList}
                  onChangeFunc={(event: any) => {
                    setSelectedUser(event?.value ?? 'all');
                  }}
                  value={userList.filter((val) => val?.value == selectedUser)}
                />
              </div>
            </>
          </div>
        </div>

        <div className="flex flex-wrap xxl:gap-4 gap-3">
          <div className="xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] w-full flex flex-col xxl:gap-3 gap-3">
            <div className="w-full flex flex-wrap xxl:gap-3 gap-3 sm:flex-row flex-col">
              {/* <KpiCard
                headerTitle="Total Hits"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalHits.toFixed(2), isLoading)
                )}
                growth={analyticsData.totalHitsGrowth}
                fromDate={boxLabel}
                tooltipText="Total Hits"
                isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Classification Accuracy"
                contentLable={`${analyticsData.totalClassificationAccuracy}`}
                growth={analyticsData.totalClassificationAccuracyGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
              /> */}
              <KpiCard
                headerTitle="Total Quote Requests"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalRequests.toFixed(2), isLoading)
                )}
                growth={analyticsData.totalRequestsGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Total Quotes"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalQuotes.toFixed(2), isLoading)
                )}
                growth={analyticsData.totalQuotesGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Total Orders"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalOrders.toFixed(2), isLoading)
                )}
                growth={analyticsData.totalOrdersGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Avg. Time to Classify"
                contentLable={convertSecondsToTime(
                  analyticsData.avgTimeToClassify
                )}
                growth={analyticsData.avgTimeToClassifyGrowth}
                fromDate={boxLabel}
                tooltipText="Avg. Time to Classify"
                isLoading={isLoading}
              />
              {/* <KpiCard
                headerTitle="Quoting Percentage"
                tooltipText="Quoting Percentage"
                contentLable={`${getFormattedNumber(
                  useCountUp(
                    +analyticsData.quotingPercentage.toFixed(2),
                    isLoading
                  )
                )}`}
                growth={analyticsData?.quotingPercentageGrowth}
                signIcon={'%'}
                fromDate={boxLabel}
                isLoading={isLoading}
              /> */}
              <KpiCard
                headerTitle="Avg. Time to Quote"
                tooltipText="Avg. Time to Quote"
                contentLable={convertSecondsToTime(
                  analyticsData.avgTimeToQuote
                )}
                growth={analyticsData.avgTimeToQuoteGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Win/Loss"
                tooltipText="Win/Loss"
                contentLable={getFormattedNumber(
                  useCountUp(
                    +(analyticsData.totalWonLoss > 0 &&
                    analyticsData.totalQuotes > 0
                      ? (
                          (analyticsData.totalWonLoss * 100) /
                          analyticsData.totalQuotes
                        ).toFixed(2)
                      : 0),
                    isLoading
                  )
                )}
                growth={analyticsData.totalWonLossGrowth ?? 0}
                fromDate={boxLabel}
                signIcon={'%'}
                isLoading={isLoading}
              />
            </div>
          </div>
          <QuoteRequestsGraph data={analyticsData} isLoading={isLoading} />
        </div>

        <TotalQuotesGraph data={analyticsData} isLoading={isLoading} />

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header ">
            <div className="table-left-wrap ">
              <div className={`table-title-wrap`}>
                <h5 className="table-title">Quoting Splits</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {totals === 1 ? `${totals} Quote` : `${totals} Quotes`}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Manage analytics of your customer quotes.
              </p>
            </div>
            <div className="flex items-center">
              <div className="table-header-bottom-left mr-2">
                <InputText
                  inputName="searchQuote"
                  placeholder="Search"
                  className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                  icon={
                    <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                  }
                  value={search}
                  inputType="text"
                  onChangeFunc={handleFilter}
                  parentClassName="table-searchInput"
                  isClearable={true}
                />
              </div>
              <TabButton
                className=""
                tabArray={teamTabArray}
                isActive={activeTableTab}
                activeClassName="!bg-utilityGray100"
                handleOnClick={(e: any) => {
                  setActiveTableTab(e?.target?.dataset?.value);
                }}
              />
            </div>
          </div>
          {/* <div className="table-bottom-header">
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div> */}
          <div className="w-full border-t border-gray100">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={quotesData}
                TableLoaderRowCmp={QuotingSplitsLoadingRow}
                tableRowCmpProps={{
                  activeTableTab: activeTableTab,
                }}
                TableRowCmp={QuotingSplitsRow}
                numberOfSkeletonRows={15}
                tableHeaderClass=""
                isTableDataLoading={isTableDataLoading}
              />
            </div>
            {!quotesData.length && !isTableDataLoading && (
              <NotFoundUI
                title="No Quoting Splits found"
                desc="There are no data for Quoting Splits."
                containerClassName="!h-auto"
              />
            )}
          </div>

          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={handlePagination}
          />
        </div>
      </div>
    </PageSectionLayout>
  );
};
export default QuotingAnalytics;
