import {
  Edit05,
  Trash01,
  Settings01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';

import aiStar from '../../../../../assets/img/ai-gradient-star.svg';

import LeadsTab from './LeadsTab';
import RatesTab from './RatesTab';

const RateGather = () => {
  const [activeTab, setActiveTab] = useState('leads');

  const truckFindingAiDetail: any = {};
  const callStats: any = {};
  const timerActiveIndex = 'inprogressOutboundCalls';
  const filterByPostOptions: any = [];

  const leadClassificationArray = [
    {
      value: 'leads',
      name: (
        <div data-value="leads">
          Leads
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="leads"
            isHidePillDot
            mainClassName={`bg-primary100 border-borderPrimary ml-2 `}
          >
            {2}
          </BadgeCmp>
        </div>
      ),
    },
    {
      value: 'rates',
      name: (
        <div data-value="rates">
          Rates
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="rates"
            isHidePillDot
            mainClassName={`bg-primary100 border-borderPrimary ml-2`}
          >
            {2}
          </BadgeCmp>
        </div>
      ),
    },
  ];

  return (
    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
      <div className="w-full flex-col flex">
        <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
          <div className="table-header-bottom-left">
            <TabButton
              className="min-w-32"
              // activeClassName="!bg-utilityGray100"
              tabArray={leadClassificationArray}
              // parentClassName="table-tabs"
              isTab={true}
              isActive={activeTab}
              handleOnClick={(e: any) => {
                setActiveTab(e?.target?.dataset?.value);
              }}
            />
            <div
              className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden `}
            ></div>
          </div>

          <div className="hidden">
            {JSON.stringify(truckFindingAiDetail)}
            <br />
            {JSON.stringify(callStats)}
            <br />
            {JSON.stringify(timerActiveIndex)}
            <br />
          </div>
          <div
            className={`ai-gradient-border rounded-lg border border-borderPrimary flex gap-2 items-center p-[7px] w-[296px] `}
          >
            <img src={aiStar} className="w-4 h-4 text-primary" />
            <div className="flex items-center flex-1">
              <p className="text-grayLight600 text-sm font-semibold line-clamp-1">
                4 Rates Gathered
              </p>
            </div>
          </div>

          <div className="min-w-[150px]">
            <SelectBox
              className="form_control"
              size="md"
              placeholder="Filter by post"
              isClearable={false}
              isSearchable={false}
              options={filterByPostOptions}
              // onChangeFunc={(event: any) => {
              //   const index = filterByPostOptions.findIndex(
              //     (option: any) => event?.value === option.value
              //   );
              //   setTruckFindingAiDetail(
              //     loadLinkPosts[index]?.loadLinkTruckFindingAis?.[0]
              //   );
              //   setLoadLinkPostingDetail(loadLinkPosts[index]);
              //   setActivePostIndex(index);
              // }}
              // value={filterByPostOptions.filter(
              //   (option: any) =>
              //     loadLinkPosts.length > 0 &&
              //     loadLinkPosts[activePostIndex].id === option.value
              // )}
              onChangeFunc={() => {}}
              value={''}
            />
          </div>

          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            onClick={() => {}}
            disabled={true}
          >
            <Edit05 className="w-4 h-4" />
          </ButtonCmp>
          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            onClick={() => {}}
            disabled={true}
            loading={false}
          >
            <Trash01 className="w-4 h-4" />
          </ButtonCmp>

          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2"
            loading={false}
            disabled={true}
            onClick={() => {}}
          >
            {<Settings01 className="w-4 h-4" />}
          </ButtonCmp>
        </div>

        <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
          {activeTab === 'leads' && <LeadsTab />}
        </div>

        <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
          {activeTab === 'rates' && <RatesTab />}
        </div>
      </div>
    </div>
  );
};

export default RateGather;
