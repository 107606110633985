import axios from 'axios';
import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

//this is fot AI chatbot in ai to send message

const BaseAiApiUrl = window.AI_API_URL;
const headers = {
  'Content-Type': 'application/json',
};

export const axiosAiInterceptor = axios.create({
  baseURL: BaseAiApiUrl,
  headers: headers,
});

export const aiSendMessage = async (data: any) => {
  const response: any = await axiosAiInterceptor.post(
    API.AI_CHATBOT.QUESTION,
    data
  );

  return response;
};

// This is for normal api call

export const threadList = async (url: string) => {
  const response = await axiosInterceptor.get(
    `${API.AI_CHATBOT.THREAD_LIST}${url}`
  );

  return response;
};

export const threadChatListDetail = async (url: string) => {
  const response = await axiosInterceptor.get(
    `${API.AI_CHATBOT.THREAD_CHAT_LIST_DETAIL}${url}`
  );

  return response;
};

export const updateThreadName = async (id: string, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.AI_CHATBOT.THREAD_RENAME}/${id}`,
    data
  );

  return response;
};

export const deleteThread = async (id: string) => {
  const response = await axiosInterceptor.delete(
    `${API.AI_CHATBOT.THREAD_DELETE}/${id}`
  );

  return response;
};
