import { ArrowUp, Settings01 } from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import React, { useContext, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import ButtonCmp from 'src/components/ButtonCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Toggle from 'src/components/Toggle/Toggle';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { CustomerList, getCustomerById } from 'src/services/CustomerService';
import { getMapService } from 'src/services/IntegrationService';
import { getNewOrderList, orderList } from 'src/services/OrderService';
import { listQuote, listQuoteCount } from 'src/services/QuoteService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  capitalizeFirstLetter,
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../assets/img/Avatar7.png';

import CustomerDetail from './CustomerDetail';
import CustomerFilterModal from './CustomerFilterModal';
import ActiveShipments from './InTransit/ActiveShipments';
import FilterModal from './InTransit/FilterModal';
import LaneActivity from './LaneActivity';
import MapBox from './MapBox';

const initResult = {
  from: '',
  to: '',
  status: '',
  classification: 'ftl',
  equipmentTypeId: 'V',
};

const initFilter = {
  isDisplayCustomers: false,
  isDisplayTransit: false,
  isDisplayLaneActivity: false,
  filterType: '',
};

const initParams: any = {
  segment: ['top'],
  team: 'all',
  member: 'all',
  search: '',
  limit: 10,
};

const initTransitParams: any = {
  search: '',
  sortType: 'asc',
  sortField: 'createdAt',
  statusfilter: 'in-transit',
  isPagination: false,
};

const MapIt = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  let custId: any = localStorage.getItem('customerId');

  const { setMapService, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const { hasRoleV2 } = useRolePermission();
  const [inTransitparams, setInTransitParams] = useState(initTransitParams);
  const [customers, setCustomers] = useState<any>();
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);
  const [allRoutes, setAllRoutes] = useState<[number, number][]>([]);
  const [customerId, setCustomerId] = useState<any>(custId);
  const [searchResult, setSearchResult] = useState<any>(initResult);
  const [userList, setUserList] = useState<any[]>([]);
  const [isDisplayDetail, setIsDisplayDetail] = useState(
    custId > 0 ? true : false
  );
  const [hasSearched, setHasSearched] = useState(false);

  const [mapFilters, setMapFilters] = useState<any>(() => {
    const storedParams = localStorage.getItem('mapFilters');

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initFilter;
  });
  const [routeFilters, setRouteFilters] = useState({
    radius: 0,
    population: 0,
  });
  const [customer, setCustomer] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [isLatestOrdersLoading, setIsPendingQuoteLoading] = useState(true);
  const [isPendingQuoteLoading, setIsLatestOrdersLoading] = useState(true);
  const [customerQuote, setCustomerQuote] = useState([]);
  const [quoteTotal, setQuoteTotal] = useState(0);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [inTransitOrders, setInTransitOrders] = useState<any>();
  const [inTransitOrderId, setInTransitOrderId] = useState('');
  const [params, setParams] = useState(initParams);

  const [segmentListOption, setSegmentListOption] = useState<any[]>([]);

  const [isOrdersLoading, setIsOrdersLoading] = useState(false);

  const handleToggle = (event: any) => {
    setHasSearched(false);
    setMapFilters(initFilter);
    const { checked, name } = event.target;

    setIsDisplayDetail(true);
    setMapFilters((old: any) => ({ ...old, [name]: checked }));
  };

  const getCustomerList = async (signal?: any) => {
    setIsCustomerLoading(true);
    setIsCustomerLoading(true);
    // handleClear();
    setCustomers([]);

    try {
      const result = await CustomerList(params, signal);

      if (result.data && result.data.length) {
        const filteredData = result.data.filter((data: any) => !data.isDeleted);

        if (filteredData?.length === 0) {
          WalToast.error('Customer not found.');
        }

        if (filteredData?.length === 1) {
          setIsDisplayDetail(true);
          setCustomerId(filteredData?.[0]?.id);
        }
        setCustomers(filteredData);
        setIsCustomerLoading(false);
      } else {
        WalToast.error('Customer not found.');
      }
    } catch (error) {
      console.error(error);
      setIsCustomerLoading(false);
    } finally {
      setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    if (mapFilters.isDisplayCustomers) getCustomerList();
  }, [params]);

  const getOrderList = () => {
    setIsOrdersLoading(true);
    setOrders([]);

    const orderListApiController = new AbortController();
    const orderListApiSignal = orderListApiController.signal;

    // orderList({ ...params, ...{ isPagination: true } })
    //   .then((response: any) => {
    //     setOrders(response.data ?? []);
    //     setIsOrdersLoading(false);
    //   })
    //   .catch((e) => {
    //     console.log(e);

    //     if (e.code === 'ERR_CANCELED') {
    //       return;
    //     }
    //     setIsOrdersLoading(false);
    //   });

    getNewOrderList({ ...inTransitparams }, orderListApiSignal)
      .then((response: any) => {
        console.log('response abc', response);
        setInTransitOrders(response.data ?? []);
        // setTotal(response?.total ?? 0);
        setIsOrdersLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrdersLoading(false);
      });
  };

  useEffect(() => {
    if (hasSearched) {
      setMapFilters(initFilter);
      setInTransitOrderId('');
      setInTransitOrders([]);
    }
  }, [hasSearched]);

  useEffect(() => {
    if (mapFilters.isDisplayCustomers) {
      setParams((old: any) => ({
        ...old,
        search: '',
      }));
      setHasSearched(false);
      setInTransitOrderId('');
      setInTransitOrders([]);
      getCustomerList();
    }

    if (mapFilters.isDisplayTransit) {
      setHasSearched(false);
      setCustomerId('');
      setCustomer([]);
      getOrderList();
    }

    if (!mapFilters.isDisplayCustomers) {
      setCustomerId(null);
      setCustomers([]);
    }

    if (!mapFilters.isDisplayTransit) {
      setInTransitOrderId('');
      setInTransitOrders([]);
    }
  }, [mapFilters.isDisplayCustomers, mapFilters?.isDisplayTransit]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
      })
      .catch(console.error);

    const statusArray = Object.entries(CUSTOMER_SEGMENT_TYPES)
      .map((keyValue: any) => {
        if (keyValue?.[1] !== 'test') {
          return {
            id: keyValue?.[1],
            value: capitalizeFirstLetter(keyValue?.[1]),
          };
        }

        return null; // Return null for 'test' values
      })
      .filter((item) => item !== null);
    setSegmentListOption(getSelectBoxOptions(statusArray, 'id', 'value'));
  }, []);

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (params.team === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === params.team
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );

      const unassignedSelectBoxOption = {
        value: 'unassigned',
        label: 'Unassigned',
        image: null,
        isImage: false,
      };

      if (hasRoleV2('admin')) {
        setUserList([unassignedSelectBoxOption, ...updatedListAllMembers]);
      } else {
        setUserList([...updatedListAllMembers]);
      }
    }
  }, [params.team, teamListOptions]);

  useEffect(() => {
    getMapService().then((response: any) => {
      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  }, []);

  const getCustomer = () => {
    setIsLoading(true);
    getCustomerById(customerId)
      .then((response: any) => {
        console.log('response', response, customerQuote);
        setIsLoading(false);
        setCustomer(response?.data);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
        console.error(e?.response?.message);
      });
  };

  const getCustomerOrders = () => {
    orderList({ isPagination: 0, customerId: customerId })
      .then((response: any) => {
        setOrders(response.data ?? []);
        setIsLatestOrdersLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLatestOrdersLoading(false);
      });
  };

  const getCustomerPendingQuote = async () => {
    setIsPendingQuoteLoading(true);

    try {
      setCustomerQuote([]);

      const [quotesResponse, quotesCountResponse]: [any, any] =
        await Promise.all([
          listQuote({
            filter: { status: 'Opened' },
            customerId: customerId,
          }),
          listQuoteCount({
            filter: { status: 'Opened' },
            customerId: customerId,
          }),
        ]);

      if (quotesResponse?.data) {
        setCustomerQuote(quotesResponse.data?.quotes);
      }

      setQuoteTotal(quotesCountResponse.total ?? 0);

      setIsPendingQuoteLoading(false);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }
      setCustomerQuote([]);
      setIsPendingQuoteLoading(false);
    }
  };

  useEffect(() => {
    if (custId) setCustomerId(custId);
  }, [custId]);

  useEffect(() => {
    if (customerId > 0) {
      getCustomer();
      getCustomerOrders();
      getCustomerPendingQuote();

      localStorage.setItem('customerId', customerId);
      localStorage.setItem('mapFilters', JSON.stringify(mapFilters));
    }
  }, [customerId]);

  return (
    <PageSectionLayout
      header={<></>}
      headerClassName="!p-0"
      contentClassName="!p-0"
    >
      <div className="relative h-full w-full geography-map">
        <MapBox
          setHasSearched={setHasSearched}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          isDisplayCustomers={mapFilters?.isDisplayCustomers}
          isDisplayDetail={isDisplayDetail}
          setIsDisplayDetail={setIsDisplayDetail}
          // setCustomers={setCustomers}
          setParams={setParams}
          setCustomerId={setCustomerId}
          customers={customers}
          cusromerId={customerId}
          setInTransitOrderId={setInTransitOrderId}
          inTransitOrders={inTransitOrders}
          setInTransitOrders={setInTransitOrders}
          inTransitOrderId={inTransitOrderId}
          mapFilters={mapFilters}
          setAllRoutes={setAllRoutes}
          setIsCustomerLoading={setIsCustomerLoading}
          allRoutes={allRoutes}
          routeFilters={routeFilters}
          setRouteFilters={setRouteFilters}
          setMapFilters={setMapFilters}
          hasSearched={hasSearched}
        />
        {/* )} */}
        {isDisplayDetail ? (
          <>
            {isDisplayDetail && mapFilters.isDisplayLaneActivity && (
              <LaneActivity
                handleClose={() => {
                  setIsDisplayDetail(false);
                }}
              />
            )}
            {isDisplayDetail && customerId && (
              <CustomerDetail
                customerId={customerId}
                isLoading={isLoading}
                quoteTotal={quoteTotal}
                isLatestOrdersLoading={isLatestOrdersLoading}
                isPendingQuoteLoading={isPendingQuoteLoading}
                customer={customer}
                customerQuote={customerQuote}
                orders={orders}
                setCustomerId={setCustomerId}
                handleClose={() => setIsDisplayDetail(false)}
              />
            )}
            {/* {isDisplayDetail && mapFilters.isDisplayTransit && (
              <InTransit
                inTransitOrders={inTransitOrders}
                handleClose={() => setIsDisplayDetail(false)}
                setOrderId={setOrderId}
              />
            )} */}
            {isDisplayDetail && inTransitOrderId ? (
              <ActiveShipments
                handleClose={() => {
                  setIsDisplayDetail(false);
                  // setInTransitOrderId('');
                }}
                orderId={inTransitOrderId}
                setOrderId={setInTransitOrderId}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          ((mapFilters.isDisplayCustomers && customerId) ||
            (mapFilters.isDisplayTransit && inTransitOrderId)) && (
            <>
              <ButtonCmp
                onClick={() => setIsDisplayDetail(true)}
                icon={
                  <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
                }
                iconSide="right"
                className="btn_secondary_black text-primary fixed bottom-10 right-10 z-[2]"
              >
                {customerId ? 'Customer Detail' : 'InTransit Order'}
              </ButtonCmp>
              {/* <div
                className="fixed w-10 p-2 z-[2] cursor-pointer rounded-md border bottom-10 right-10 bg-white"
                onClick={() => setIsDisplayDetail(true)}
              >
                <Expand03 className="w-5 h-5 text-primary cursor-pointer" />
              </div> */}
            </>
          )
        )}

        <div className="rounded-lg border border-utilityGray200  p-3 shadow-lg bg-white absolute left-4 bottom-4 z-[2] min-w-[300px] space-y-4">
          <div className="flex items-center gap-2">
            <Toggle
              id="display-customers-toggle"
              name="isDisplayCustomers"
              labelClassName="justify-between flex-1"
              isDisabled={isCustomerLoading}
              isChecked={mapFilters.isDisplayCustomers}
              onChange={handleToggle}
              label="Display Customers"
              labelPosition="left"
              labelTextClassName="text-xs font-normal text-grayLight900"
            />
            {isCustomerLoading ? (
              <span
                className={`loading loading-spinner text-gray-600 h-4 w-4 `}
              ></span>
            ) : (
              <Settings01
                className={`w-4 h-4  ${
                  mapFilters.isDisplayCustomers
                    ? 'cursor-pointer text-gray600'
                    : 'text-gray500'
                }`}
                onClick={() => {
                  if (mapFilters.isDisplayCustomers)
                    setMapFilters((old: any) => ({
                      ...old,
                      filterType: 'customer_detail',
                    }));
                }}
              />
            )}
          </div>

          <div className="flex items-center gap-2">
            <Toggle
              id="display-transit-toggle"
              name="isDisplayTransit"
              labelClassName="justify-between flex-1"
              isChecked={mapFilters.isDisplayTransit}
              onChange={handleToggle}
              label="Display In-Transit Shipments"
              labelPosition="left"
              labelTextClassName="text-xs font-normal text-grayLight900"
            />
            {isOrdersLoading ? (
              <span
                className={`loading loading-spinner text-gray-600 h-4 w-4 `}
              ></span>
            ) : (
              <span className={`w-4 h-4`}></span>
            )}

            {/* <Settings01
                className={`w-4 h-4 cursor-pointer ${
                  mapFilters.isDisplayTransit ? 'text-gray600' : 'text-gray500'
                }`}
                onClick={() =>
                  setMapFilters((old: any) => ({
                    ...old,
                    filterType: 'in_transit',
                  }))
                }
              /> */}
          </div>
          {/* <div className="flex I-center justify-between gap-2">
            <span className="text-xs font-normal text-grayLight900">
              Display Lane Activity
            </span>
            <div className="flex">
              <Toggle
                id="display-lane-actiovity"
                name="isDisplayLaneActivity"
                labelClassName="mr-2"
                isChecked={mapFilters.isDisplayLaneActivity}
                onChange={handleToggle}
              />
              <span className={`w-4 h-4`}></span>
              <Settings01
                className={`w-4 h-4 ${
                  mapFilters.isDisplayLaneActivity
                    ? 'text-gray900'
                    : 'text-gray500'
                }`}
                onClick={() =>
                  setMapFilters((old: any) => ({
                    ...old,
                    filterType: 'lane_activity',
                  }))
                }
              /> 
            </div>
          </div> */}

          {mapFilters.filterType === 'customer_detail' && (
            <CustomerFilterModal
              handleClose={() =>
                setMapFilters((old: any) => ({
                  ...old,
                  filterType: '',
                }))
              }
              params={params}
              setParams={setParams}
              teamListOptions={teamListOptions}
              userList={userList}
              segmentListOption={segmentListOption}
            />
          )}

          {mapFilters.filterType === 'in_transit' && (
            <FilterModal
              handleClose={() =>
                setMapFilters((old: any) => ({
                  ...old,
                  filterType: '',
                }))
              }
              params={inTransitparams}
              setParams={setInTransitParams}
              teamListOptions={teamListOptions}
              userList={userList}
            />
          )}
        </div>
      </div>
    </PageSectionLayout>
  );
};

export default MapIt;
