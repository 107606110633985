import { Check, ChevronRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import { IStepBar, ISteps } from './StepBar.interface';

const StepBar = ({
  steps,
  activeStep,
  stepsParentClass,
  stepClass,
  variant = 'full',
}: IStepBar) => (
  <ol
    className={`flex  items-center md:justify-end w-full gap-2 ${stepsParentClass}`}
  >
    {steps.map(({ name, step }: ISteps, index) =>
      variant === 'full' ? (
        <>
          <li
            key={index}
            className={`flex xs:gap-3 gap-1 justify-end items-center w-auto ${stepClass} `}
          >
            {activeStep === step ? (
              <span className="flex items-center justify-center xs:w-4 w-3 xs:h-4 h-3 bg-primary shadow-md rounded-full shrink-0">
                <span className="flex items-center justify-center xs:w-1.5 w-1.5 xs:h-1.5 h-1.5 bg-white rounded-full shrink-0"></span>
              </span>
            ) : activeStep > step ? (
              <span className="flex items-center justify-center xs:w-4 w-3 xs:h-4 h-3 bg-primary rounded-full shrink-0">
                <Check className="text-white xs:w-4 w-3 xs:h-4 h-3" />
              </span>
            ) : (
              <span className="flex items-center justify-center xs:w-4 w-3 xs:h-4 h-3 border border-utilityGray200 bg-white shadow-md rounded-full shrink-0">
                <span className="flex items-center justify-center xs:w-1.5 w-1.5 xs:h-1.5 h-1.5 bg-borderPrimary rounded-full shrink-0"></span>
              </span>
            )}
            <span className="md:text-xs xs:text-xs text-[10px] text-textSecondary font-semibold flex items-center">
              {name}
            </span>
          </li>
          <ChevronRight className="ml-1 h-4 w-4 last:hidden" />
        </>
      ) : (
        <li
          key={index}
          className={`h-[5px] w-full rounded-full  ${
            index < activeStep ? 'bg-primary' : 'bg-utilityGray200'
          }`}
        ></li>
      )
    )}
  </ol>
);

export default StepBar;
