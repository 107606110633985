import { yupResolver } from '@hookform/resolvers/yup';
import { UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import CropperModal from 'src/components/CropperModal/CropperModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';
import { CURRENCY, TIMEZONE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getAppearance, updateAppearance } from 'src/services/SettingService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import clock from '../../../assets/img/clock.svg';
import defaultImage from '../../../assets/img/default-image.jpg';

interface FormValues {
  web_title: string;
  logo?: File | null; // Optional and nullable
  favicon?: File | null; // Optional and nullable
  timezone?: string;
  currency?: string;
  load_insurance_purchase_per: number;
  load_insurance_sell_per: number;
  transferNumber: string;
  transfer_number_code: string;
  // AITransferNumber: string;
  ai_transfer_number_code: string;
}

const Appearance = () => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const { appearance, setAppearance, setCurrency } = useContext(BasicContext);

  const [uploadType, setUploadType] = useState<'logo' | 'favicon' | ''>('');
  const [displayLogo, setDisplayLogo] = useState<any>(null);
  const [displayFavicon, setDisplayFavicon] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSameFavicon, setIsSameFavicon] = useState(false);

  const getAppearanceDetail = () => {
    getAppearance()
      .then((response) => {
        if (response.data) {
          setAppearance(response.data);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  useEffect(() => {
    getAppearanceDetail();
  }, []);

  const validationSchema = yup.object().shape({
    web_title: yup.string().required('Name is required.'),
    logo: yup
      .mixed()
      .nullable()
      .test({
        name: 'fileSize',
        message: 'Image size should be less than 2MB.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return value.size <= MAX_FILE_SIZE;
        },
      }),
    favicon: yup
      .mixed()
      .nullable()
      .test({
        name: 'fileSize',
        message: 'Image size should be less than 2MB.',
        test: async (value: any) => {
          if (!value || !(value instanceof File)) return true;

          return value.size <= MAX_FILE_SIZE;
        },
      }),
    load_insurance_purchase_per: yup
      .number()
      .min(0, 'Value cannot be less than 0%')
      .max(100, 'Value cannot be more than 100%')
      .required('Load Insurance purchase(%) is required.'),
    load_insurance_sell_per: yup
      .number()
      .min(0, 'Value cannot be less than 0%')
      .max(100, 'Value cannot be more than 100%')
      .required('Load Insurance sell(%) is required.'),
    timezone: yup.string().nullable(),
    currency: yup.string().nullable(),
    transferNumber: yup.string().required('Transfer Number is required.'),
    transfer_number_code: yup
      .string()
      .required('Transfer Country code is required.'),
    // AITransferNumber: yup.string(),
    ai_transfer_number_code: yup
      .string()
      .required('AI Transfer Country code is required.'),
  });

  const defaultValues: FormValues = {
    web_title: appearance.web_title || '',
    logo: appearance.logoUrl + appearance.logo || '',
    favicon: appearance.faviconUrl + appearance.favicon || '',
    timezone: appearance?.timezone || null,
    currency: appearance?.currency || null,
    load_insurance_purchase_per: 0,
    load_insurance_sell_per: 0,
    transferNumber: '',
    transfer_number_code: '',
    // AITransferNumber: '',
    ai_transfer_number_code: '',
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (appearance) {
      reset({
        web_title: appearance.web_title || '',
        logo: appearance.logoUrl ? appearance.logoUrl + appearance.logo : '',
        favicon: appearance.faviconUrl
          ? appearance.faviconUrl + appearance.favicon
          : '',
        timezone: appearance?.timezone ?? null,
        currency: appearance?.currency ?? null,
        load_insurance_purchase_per: appearance?.load_insurance_purchase_per,
        load_insurance_sell_per: appearance?.load_insurance_sell_per,
      });
      setIsSameFavicon(appearance?.isSameLogo ?? false);
    }
  }, [appearance, reset]);

  // const onDrop = useCallback(
  //   (acceptedFiles: File[], type: 'logo' | 'favicon') => {
  //     if (type === 'logo') {
  //       setDisplayLogo(null);
  //     } else {
  //       setDisplayFavicon(null);
  //     }

  //     const file = acceptedFiles[0];

  //     if (file.type.match('image')) {
  //       setValue(type, file);
  //     }

  //     if (file) {
  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         const img = new Image();

  //         img.onload = () => {
  //           if (type === 'logo') {
  //             setDisplayLogo(img.src);
  //           } else {
  //             setDisplayFavicon(img.src);
  //           }
  //         };
  //         img.src = e.target!.result as string;
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   },
  //   []
  // );

  const onDrop = useCallback(
    (acceptedFiles: File[], type: 'logo' | 'favicon') => {
      const file = acceptedFiles[0];

      if (type === 'logo') {
        setError('logo' as 'logo', {});
        setDisplayLogo(null);
        setValue('logo', file, { shouldValidate: true });
      } else {
        setError('favicon' as 'favicon', {});
        setDisplayFavicon(null);
        setValue('favicon', file, { shouldValidate: true });
      }

      const { result, message } = checkFileTypeValidation(
        acceptedFiles,
        MAX_FILE_SIZE
      );

      if (result) {
        setIsLoading(false);
        const reader = new FileReader();

        reader.onloadstart = () => {
          if (type === 'logo') {
            setDisplayLogo(null);
          } else {
            setDisplayFavicon(null);
          }
          setUploadType('');
        };

        reader.onloadend = () => {
          if (type === 'logo') {
            setDisplayLogo(reader.result as any);
          } else {
            setDisplayFavicon(reader.result as any);
          }

          // setValue("image", reader.result, { shouldValidate: true });
          setUploadType(type);
        };
        reader.readAsDataURL(file);
      } else {
        // setImageError(message);
        if (type === 'logo') {
          setError('logo' as 'logo', {
            type: 'manual',
            // message: 'Image size must be less than 2MB.',
            message: message,
          });
        } else {
          setError('favicon' as 'favicon', {
            type: 'manual',
            // message: 'Image size must be less than 2MB.',
            message: message,
          });
        }
      }
    },
    []
  );

  const { getRootProps: getLogoProps, getInputProps: getLogoInputProps } =
    useDropzone({
      onDrop: (files) => onDrop(files, 'logo'),
      maxFiles: 1,
      multiple: false,
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/svg+xml': ['.svg'],
      },
    });

  const { getRootProps: getFaviconProps, getInputProps: getFaviconInputProps } =
    useDropzone({
      onDrop: (files) => onDrop(files, 'favicon'),
      maxFiles: 1,
      multiple: false,
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/svg+xml': ['.svg'],
      },
    });

  // Function to generate timezone objects
  const generateTimeZonesList = () => {
    const timezones = moment.tz.names();

    const timeZonesList = timezones.map((timezone) => {
      const tzOffset = moment.tz(timezone).format('Z'); // Get the UTC offset
      const country = timezone.split('/')[0]; // Get the country/region part of the timezone
      const label = `${timezone} (${moment.tz(timezone).zoneAbbr()})`;
      const subLabel = `UTC${tzOffset}`;

      return {
        value: timezone,
        label: label,
        subLabel: subLabel,
        image: clock, // Add your image or icon here
        country: country,
      };
    });

    return timeZonesList;
  };

  const TimeZonesList = generateTimeZonesList();

  console.log('errors', errors);
  const currencyList = [
    { label: 'CAD', value: CURRENCY.CAD },
    { label: 'USD', value: CURRENCY.USD },
  ];

  const imageUpload = (data: any, type: 'logo' | 'favicon') => {
    const appLogoImage = data;
    const fileInfo = convertBase64ToFile(appLogoImage);
    setValue(type, fileInfo?.convertedFile);
  };

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      if (value) appendFormData.append(key, value);
    });

    // pushing image to last
    const LogoToAppend = appendFormData.get('logo');
    appendFormData.delete('logo');

    if (LogoToAppend) {
      appendFormData.append('logo', LogoToAppend);
    }

    if (isSameFavicon) {
      if (LogoToAppend) {
        appendFormData.delete('favicon');
        appendFormData.append('favicon', LogoToAppend);
      }
    } else {
      const FaviconToAppend = appendFormData.get('favicon');
      appendFormData.delete('favicon');

      if (FaviconToAppend) {
        appendFormData.append('favicon', FaviconToAppend);
      }
    }
    appendFormData.append('isSameLogo', isSameFavicon);
    updateAppearance(appendFormData)
      .then((response) => {
        getAppearanceDetail();
        setCurrency(response?.data?.currency ?? CURRENCY.CAD);
        moment.tz.setDefault(response.data?.timezone ?? TIMEZONE);
        WalToast.success('Appearance setting have been updated successfully');
      })
      .finally(() => setIsLoading(false))
      .catch(() => WalToast.error('Appearance setting have been not updated'));
  };

  return (
    <form className="h-full w-full px-2 py-1" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
        <div>
          <h6 className="text-grayLight900 font-semibold text-sm">
            Appearance
          </h6>
          <p className="text-grayLight600 font-normal text-sm">
            Update logo and website details here.
          </p>
        </div>
      </div>

      <div className="mt-4">
        <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            Title
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="web_title"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="web_title"
                  placeholder="Enter web title"
                  parentClassName="flex-1"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={errors.web_title ? 'Web title is required' : null}
                />
              )}
            />
          </div>
        </div>

        <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            <div className="flex items-center gap-0.5">Brand Logo</div>
            <p className="text-grayLight600 font-normal">
              Update your company logo and then choose where you want it to
              display.
            </p>
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full ">
            <div className="flex gap-4">
              {displayLogo ? (
                <img
                  className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-lg object-cover opa"
                  src={displayLogo}
                  alt={watch('web_title')}
                  title={watch('web_title')}
                />
              ) : watch('logo') ? (
                <label>
                  <img
                    className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-lg object-cover"
                    src={appearance.logoUrl + appearance.logo}
                    alt={watch('web_title')}
                    title={watch('web_title')}
                    onError={onError}
                  />
                </label>
              ) : watch('web_title') ? (
                <label className="flex-none w-14 h-14 rounded-lg cursor-pointer text-2xl font-medium bg-primary  text-white border-[0.75px] border-black/[0.08] flex items-center justify-center uppercase">
                  {getShortName(`${watch('web_title')}`)}
                </label>
              ) : (
                <label className="cursor-pointer">
                  <img
                    className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-lg object-cover"
                    src={defaultImage}
                    alt={watch('web_title')}
                    title={watch('web_title')}
                  />
                </label>
              )}
              <label
                className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
                htmlFor="fileSelect"
                {...getLogoProps()}
              >
                <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                  <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                </div>
                <div className="text-grayLight600 text-xs font-normal">
                  <p>
                    <span className="text-[#2422DD] font-semibold pr-1">
                      Click to upload
                    </span>{' '}
                    or drag and drop
                  </p>
                  <p className="text-[11px] leading-[18px]">
                    SVG, PNG or JPG (max. 800x400px)
                  </p>
                </div>
              </label>
              <input
                type="file"
                name="logo"
                className="hidden"
                {...getLogoInputProps()}
              />
            </div>
            <span className="text-xs font-normal text-red-600 dark:text-red-500">
              {errors.logo ? errors.logo.message : null}
            </span>
          </div>
        </div>

        <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            <div className="flex items-center gap-0.5">Upload favicon</div>
            <p className="text-grayLight600 font-normal">
              Update your company logo for favicon icon it appear in square box
              in 16px * 16px size.
            </p>
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full ">
            <div className="flex flex-col ">
              <Toggle
                label="Use same as brand logo."
                labelClassName="form_label"
                labelPosition="right"
                isChecked={isSameFavicon}
                onChange={(e: any) => setIsSameFavicon(e?.target?.checked)}
              />
            </div>
            {!isSameFavicon && (
              <div className="flex gap-4">
                {displayFavicon ? (
                  <img
                    className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-lg object-cover opa"
                    src={displayFavicon}
                    alt={watch('web_title')}
                    title={watch('web_title')}
                  />
                ) : watch('favicon') ? (
                  <label>
                    <img
                      className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-lg object-cover"
                      src={appearance.faviconUrl + appearance.favicon}
                      alt={watch('web_title')}
                      title={watch('web_title')}
                      onError={onError}
                    />
                  </label>
                ) : watch('web_title') ? (
                  <label className="flex-none w-14 h-14 rounded-lg cursor-pointer text-2xl font-medium bg-primary  text-white border-[0.75px] border-black/[0.08] flex items-center justify-center uppercase">
                    {getShortName(watch('web_title'))}
                  </label>
                ) : (
                  <label className="cursor-pointer">
                    <img
                      className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-lg object-cover"
                      src={defaultImage}
                      alt={watch('web_title')}
                      title={watch('web_title')}
                    />
                  </label>
                )}

                <label
                  className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
                  htmlFor="fileSelect"
                  {...getFaviconProps()}
                >
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[11px] leading-[18px]">
                      SVG, PNG or JPG (max. 800x400px)
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="favicon"
                  className="hidden"
                  {...getFaviconInputProps()}
                />
              </div>
            )}

            <span className="text-xs font-normal text-red-600 dark:text-red-500">
              {errors.favicon ? errors.favicon.message : null}
            </span>
          </div>
        </div>
        <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            <div className="flex items-center gap-0.5">Default Timezone</div>
            <p className="text-grayLight600 font-normal">
              Update timezone for your organization.
            </p>
          </label>

          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full ">
            <Controller
              name="timezone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="timezone"
                  placeholder="Select timezone"
                  parentClassName="flex-1"
                  menuPlaceOption="top"
                  labelClassName="form_label"
                  selectoptiontext="whitespace-normal"
                  value={TimeZonesList.filter(
                    (timezone) => timezone?.value === value
                  )}
                  options={TimeZonesList}
                  onChangeFunc={(e: any) => onChange(e?.value ?? null)}
                  // errorText={!errors?.timezone?.message ?? null}
                  isSearchable
                  isClearable
                />
              )}
            />
          </div>
        </div>
        <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            <div className="flex items-center gap-0.5">Default Currency</div>
            <p className="text-grayLight600 font-normal">
              Set default currency for your organization.
            </p>
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full ">
            <Controller
              name="currency"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  name="currency"
                  placeholder="Select currency"
                  parentClassName="flex-1"
                  menuPlaceOption="top"
                  labelClassName="form_label"
                  selectoptiontext="whitespace-normal"
                  value={currencyList.filter(
                    (currency) => currency?.value === value
                  )}
                  options={currencyList}
                  onChangeFunc={(e: any) => onChange(e?.value ?? null)}
                  // errorText={!errors?.currency?.message ?? null}
                  isClearable
                />
              )}
            />
          </div>
        </div>
        <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            Transfer Number
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="transferNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="transferNumber"
                  phoneInputParentClassName="flex-1"
                  value={value}
                  onNumberChange={(country: any, phone: any, code: any) => {
                    const fullPhoneNumber = `+${code}${phone}`;
                    setValue('transferNumber', fullPhoneNumber);
                    setValue('transfer_number_code', `+${code}`);

                    onChange(fullPhoneNumber);
                  }}
                  errors={
                    errors.transferNumber ? errors.transferNumber.message : null
                  }
                />
              )}
            />
          </div>
        </div>
        {/* <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            AI Transfer Number
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="AITransferNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  name="AITransferNumber"
                  phoneInputParentClassName="flex-1"
                  value={value}
                  onNumberChange={(country: any, phone: any, code: any) => {
                    const fullPhoneNumber = `+${code}${phone}`;
                    setValue('AITransferNumber', fullPhoneNumber);
                    setValue('ai_transfer_number_code', `+${code}`);

                    onChange(fullPhoneNumber);
                  }}
                  errors={
                    errors.AITransferNumber
                      ? errors.AITransferNumber.message
                      : null
                  }
                />
              )}
            />
          </div>
        </div> */}
        <div className="flex justify-end gap-4 py-4">
          <ButtonCmp
            type="button"
            className="btn_secondary_black shadow py-2 px-3"
            onClick={() => {
              reset(defaultValues);
            }}
          >
            Cancel
          </ButtonCmp>
          <ButtonCmp
            type="submit"
            className="btn_primary  shadow py-2 px-3"
            loading={isLoading}
          >
            Save
          </ButtonCmp>
        </div>
      </div>
      {(uploadType === 'logo' || uploadType === 'favicon') && (
        <CropperModal
          modalTitle="Edit website logo"
          modalDesc="Edit website logo as per your requirement"
          imageUpload={(e: any) => imageUpload(e, uploadType)}
          imageUrl={uploadType === 'logo' ? displayLogo : displayFavicon}
          setUpload={setUploadType}
          setCropData={
            uploadType === 'logo' ? setDisplayLogo : setDisplayFavicon
          }
          defaultImage=""
          defaultCropType="Square"
        />
      )}
    </form>
  );
};

export default Appearance;
