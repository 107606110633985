import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const RatesRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4  min-w-80 w-80 max-w-80">
      <div className="flex items-center truncate ">
        <div className="table-profile-customer-circle-xl rounded-md">
          {getShortName(`${data?.carrier?.name}`)}
        </div>
        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data?.carrier?.name}
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-32 w-32 max-w-32">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.contactPerson}</div>
      </div>
    </td>

    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">${data?.rate}</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.equipmentType}</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.estDeliveryDate}</div>
      </div>
    </td>
    <td className="px-5 py-4">
      <div className="flex items-center gap-1 ">
        <div className="font-medium">{data?.availability}</div>
      </div>
    </td>
  </>
);

export default RatesRow;
