/* eslint-disable @typescript-eslint/no-unused-vars */
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { groupBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import CheckBox from 'src/components/CheckBox';
import NotFoundUI from 'src/components/NotFoundUI';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getPricingRate } from 'src/services/CommonService';
import WalToast from 'src/utils/WalToast';

import AccessTypeModal from './AccessTypeModal';
import {
  dangerTypeList,
  inbondContactMethodList,
  inbondTypeList,
  initServiceValidForm,
  limitedAccessList,
  packagingGroupOptions,
} from './additionalServices.const';
import DangerousMoreModal from './DangerousMoreModal';
import ExcessValuationModal from './ExcessValuationModal';
import InBondMoreModal from './InBondMoreModal';

interface IProps {
  setIsTailgate: any;
  currentLegData: any;
  setCurrentLegData: any;
  setValue: any;
  title: any;
  // titleDescription: any;
  serviceList: any;
  showService: any;
  additionalServiceHeaderClass?: string;
  additionalServiceClass?: string;
}

const AdditionalServiceCmp = ({
  setIsTailgate,
  currentLegData,
  setCurrentLegData,
  setValue,
  title,
  // titleDescription,
  serviceList,
  showService,
  additionalServiceHeaderClass,
  additionalServiceClass,
}: IProps) => {
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [isOpenAccordian, setIsOpenAccordian] = useState(false);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [serviceTypes, setServiceTypes] = useState<any>();

  const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
    useState<boolean>(false);
  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(false);
  const [limitedAccessTypeList, setLimitedAccessTypeList] =
    useState<any>(limitedAccessList);
  const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    limitedAccessTypeList?.[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>('');

  const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
    useState<boolean>(false);
  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');

  const [isDeclaredValueMore, setIsDeclaredValueMore] =
    useState<boolean>(false);
  const [declaredValue, setDeclaredValue] = useState<any>(0);
  const [rates, setRates] = useState<any>({});
  const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
    useState<boolean>(false);
  const [isExcessValuationModalOpen, setIsExcessValuationModalOpen] =
    useState<boolean>(false);
  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>('');
  const [isDangerousFormSubmitted, setIsDangerousFormSubmitted] =
    useState<boolean>(false);
  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);
  const { currency } = useContext(BasicContext);
  const [declaredValueUnit, setDeclaredValueUnit] = useState(currency);

  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const declaredValueWithUnit = {
    declaredValueCAD: 0,
    declaredValueUSD: 0,
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  useEffect(() => {
    if (serviceList?.length) {
      let serviceByGroup: any = groupBy(serviceList, (val) => val.type);
      setServiceTypes(serviceByGroup);
    }
  }, [serviceList]);

  useEffect(() => {
    if (
      !allowHandleChecked
      // && !isLoading
    ) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [
    allowHandleChecked,
    //  isLoading
  ]);

  useEffect(() => {
    if (isLimitedMore && allowHandleChecked) {
      setIsAccessTypeModalOpen(true);
    }
  }, [isLimitedMore]);

  useEffect(() => {
    if (isInbondMore && allowHandleChecked) {
      setIsInbondMoreModalOpen(true);
    }
  }, [isInbondMore]);

  useEffect(() => {
    if (isDangerousMore && allowHandleChecked) {
      setIsDangerousMoreModalOpen(true);
    }
  }, [isDangerousMore]);

  useEffect(() => {
    if (isDeclaredValueMore && allowHandleChecked) {
      setIsExcessValuationModalOpen(true);
    }
  }, [isDeclaredValueMore]);

  useEffect(() => {
    if (declaredValueUnit === CURRENCY.CAD) {
      if (declaredValueWithUnit.declaredValueCAD)
        setDeclaredValue(declaredValueWithUnit.declaredValueCAD);
    } else if (declaredValueUnit === CURRENCY.USD) {
      if (declaredValueWithUnit.declaredValueUSD)
        setDeclaredValue(declaredValueWithUnit.declaredValueUSD);
    }
  }, [declaredValueUnit]);

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        WalToast.error('Something went wrong while fetching rate.');
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((old: any) => [...old, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessTypeList[0].value);
      }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        // setInbondContactMethod(inbondContactMethodList[0].value);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(false);
      }
    }

    setIsSubmitForm(true);
  };

  const getList = (data: any) => (
    <>
      {data.map((val: any) => (
        <div
          className=" p-4 relative w-1/4 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:top-0 before:left-0 before:absolute"
          key={`service_${val.id}`}
        >
          <div className="flex cursor-pointer">
            <CheckBox
              id={`service_${val.id}`}
              onChangeFunc={handleChange}
              name={val.slug}
              value={val.id}
              checked={selectedServices.includes(val.id)}
              parentClassName="mt-0.5"
            />
            <div className="flex leading-4">
              {val.description ? (
                <>
                  <TooltipCmp
                    message={
                      val.name === 'After Hours / Weekend / Holiday Pickup' &&
                      'After Hours / Weekend / Holiday Pickup'
                    }
                  >
                    <label
                      htmlFor={`service_${val.id}`}
                      className="md:ml-2 ml-1.5 text-xs leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5 capitalize"
                    >
                      {val.name === 'After Hours / Weekend / Holiday Pickup'
                        ? 'After Hours Pickup'
                        : val.name}
                    </label>
                  </TooltipCmp>

                  <TooltipCmp message={<div>{val.description}</div>}>
                    <InfoCircle className="h-3.5 w-3.5 text-primary mt-1" />
                  </TooltipCmp>
                </>
              ) : (
                <TooltipCmp
                  message={
                    val.name === 'After Hours / Weekend / Holiday Pickup' &&
                    'After Hours / Weekend / Holiday Pickup'
                  }
                >
                  <label
                    htmlFor={`service_${val.id}`}
                    className="md:ml-2 ml-1.5 text-xs leading-4 text-textSecondary cursor-pointer font-medium xxl:me-2.5 me-1.5 capitalize"
                  >
                    {val.name === 'After Hours / Weekend / Holiday Pickup'
                      ? 'After Hours Pickup'
                      : val.name}
                  </label>
                </TooltipCmp>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const getName = (contactMethod: string) => {
    if (contactMethod === 'fax-number') {
      return 'Fax';
    } else if (contactMethod === 'phone-number') {
      return 'Phone';
    } else {
      return 'Email';
    }
  };

  const parsedPhoneNumber = inbondContactValue
    ? parsePhoneNumberFromString(inbondContactValue)
    : null;
  const defaultValues = {
    phone: parsedPhoneNumber?.nationalNumber || '',
    phone_country_code: parsedPhoneNumber?.country || '',
  };

  const emergencyContactNo = emergencyContactNumber
    ? parsePhoneNumberFromString(emergencyContactNumber)
    : null;
  const defaultEmergencyPhoneValues = {
    phone: emergencyContactNo?.nationalNumber || '',
    phone_country_code: emergencyContactNo?.country || '',
  };

  const isInBondFormValid = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      isEmailValid: false,
      isPhoneValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        validFormData.inbondContactValue = true;

        if (getName(inbondContactMethod) === 'Email') {
          if (emailRegex.test(inbondContactValue)) {
            validFormData.isEmailValid = true;
          } else {
            validFormData.isEmailValid = false;
            valid = false;
          }
        } else {
          validFormData.isEmailValid = true;
        }

        if (getName(inbondContactMethod) === 'Phone') {
          if (!validServicesForm.isPhoneValid) {
            validFormData.isPhoneValid = false;
            valid = false;
          }

          if (!validServicesForm.inbondContactValue) {
            validFormData.inbondContactValue = false;
            valid = false;
          }
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isAccessTypeFormValid = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;
    setInbondContactValue('');

    if (name === 'contactMethod') {
      isInBondFormValid();

      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const prepareAdditionalServices = () => {
    const formDataCurrent: any = {
      serviceId: selectedServices,
      limitedAccess: isLimitedMore,
      inbond: isInbondMore,
      dangerousGoods: isDangerousMore,
    };

    if (isLimitedMore) {
      formDataCurrent.limitedAccessType = limitedAccessType;
      formDataCurrent.limitedAccessOther = limitedAccessOther;
    }

    if (isInbondMore) {
      formDataCurrent.inbondType = inbondType;
      formDataCurrent.inbondName = inbondName;
      formDataCurrent.inbondAddress = inbondAddress;
      formDataCurrent.inbondContactMethod = inbondContactMethod;
      formDataCurrent.inbondContactValue = inbondContactValue;
    }

    if (isDangerousMore) {
      formDataCurrent.dangerGoodsType = dangerType;
      formDataCurrent.goodsClass = goodsClass;
      formDataCurrent.description = description;
      formDataCurrent.unitedNationsNumber = unitedNationsNumber;
      formDataCurrent.packagingGroup = packagingGroup;
      formDataCurrent.emergencyContactName = emergencyContactName;
      formDataCurrent.emergencyContactNumber = emergencyContactNumber;
      formDataCurrent.emergencyContactExt = emergencyContactExt;
    }

    if (isDeclaredValueMore) {
      formDataCurrent.declaredValue = declaredValue;
      formDataCurrent.isDeclaredValue = isDeclaredValueMore;
      formDataCurrent.currency = declaredValueUnit;
    }

    return formDataCurrent;
  };

  const submitService = () => {
    const additionalServices = prepareAdditionalServices();

    setIsSubmitForm(false);
    setValue('additionalServices', additionalServices);
    setCurrentLegData((old: any) => ({ ...old, additionalServices }));
  };

  const isDangerousGoodsFormValid = () => {
    let valid = true;
    const validFormData = {
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyPhoneNumber === '' || emergencyPhoneNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      } else {
        if (!validServicesForm.isEmergencyPhoneValid) {
          validFormData.isEmergencyPhoneValid = false;
          valid = false;
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isExcessValuation = () => {
    let valid = true;
    const validFormData = {
      declaredValue: true,
    };

    if (!declaredValue) {
      validFormData.declaredValue = false;
      valid = false;
    }
    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
      inbondContactValue: true,
    };
    defaultValues.phone_country_code = country;
    setInbondContactValue(`+${code}${phone}`);

    if (phone === '') {
      validFormData.phone = false;
      validFormData.isPhoneValid = false;
      validFormData.inbondContactValue = false;
    } else {
      validFormData.isPhoneValid = isValid;
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));
  };

  const handleDangerPhoneChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };
    defaultEmergencyPhoneValues.phone_country_code = country;
    setEmergencyContactNumber(`+${code}${phone}`);
    setEmergencyPhoneNumber(`${phone}`);

    if (phone === '') {
      validFormData.emergencyContactNumber = false;
      validFormData.isEmergencyPhoneValid = false;
    } else {
      validFormData.isEmergencyPhoneValid = isValid;
    }

    setServicesValidForm({ ...validServicesForm, ...validFormData });
  };

  useEffect(() => {
    let filterTypes = limitedAccessList.filter((type: any) => {
      if (type.value === limitedAccessType) {
        return true;
      }

      return type.name
        .toLowerCase()
        .includes(limitedAccessSearch.toLowerCase());
    });
    setLimitedAccessTypeList(filterTypes);
  }, [limitedAccessSearch]);

  useEffect(() => {
    if (isSubmitForm) {
      submitService();
    }
  }, [isSubmitForm]);

  const resetState = () => {
    setIsTailgate([]);
    setIsLimitedMore(false);
    setLimitedAccessType('');
    setLimitedAccessOther('');
    setIsInbondMore(false);
    setInbondType('');
    setInbondName('');
    setInbondAddress('');
    setInbondContactMethod('');
    setInbondContactValue('');
    setIsDeclaredValueMore(false);
    setDeclaredValueUnit('');
    setDeclaredValue('');
    setIsDangerousMore(false);
    setDangerType('');
    setPackagingGroup('');
    setUnitedNationsNumber('');
    setGoodsClass('');
    setEmergencyContactName('');
    setEmergencyContactNumber('');
    setEmergencyContactExt('');
    setDescription('');
    setSelectedServices([]);
  };

  useEffect(() => {
    if (currentLegData && currentLegData?.additionalServices) {
      const additionalServices = currentLegData?.additionalServices;

      setSelectedServices(additionalServices.serviceId);

      setLimitedAccessType(additionalServices.limitedAccessType || '');
      setLimitedAccessOther(additionalServices.limitedAccessOther || '');

      setInbondType(additionalServices.inbondType || '');
      setInbondName(additionalServices.inbondName || '');
      setInbondAddress(additionalServices.inbondAddress || '');
      setInbondContactMethod(additionalServices.inbondContactMethod || '');
      setInbondContactValue(additionalServices.inbondContactValue || '');

      setDangerType(additionalServices.dangerGoodsType || '');
      setGoodsClass(additionalServices.goodsClass || '');
      setDescription(additionalServices.description || '');
      setUnitedNationsNumber(additionalServices.unitedNationsNumber || '');
      setPackagingGroup(additionalServices.packagingGroup || '');
      setEmergencyContactName(additionalServices.emergencyContactName || '');
      setEmergencyContactNumber(
        additionalServices.emergencyContactNumber || ''
      );
      setEmergencyContactExt(additionalServices.emergencyContactExt || '');

      setDeclaredValue(additionalServices.declaredValue || '');
      setDeclaredValueUnit(additionalServices.currency || '');

      if (additionalServices?.serviceId) {
        additionalServices?.serviceId?.forEach((service: any) => {
          const matchingService: any = serviceList.find(
            (s: any) =>
              s.id === service &&
              (s.slug === 'tailgate_pickup' || s.slug === 'tailgate_delivery')
          );

          if (matchingService) {
            setIsTailgate((old: any) => [...old, matchingService.slug]);
          }
        });
      }
    } else {
      resetState();
    }
  }, [currentLegData]);

  return (
    <>
      <div className="w-full sm:px-1.5 py-2">
        <h6 className="form_label block mb-1.5">Additional Services</h6>
        <AccordionCmp
          title={
            <div className="w-full">
              <h3 className="text-grayLight900 font-semibold text-xs">
                {title}
              </h3>
              {/* <p className="text-xs text-grayLight600 font-normal">
                {titleDescription}
              </p> */}
            </div>
          }
          isOpen={isOpenAccordian}
          handleClick={(e: any) => {
            if (e?.target && !e?.target?.closest('.TabButton')) {
              setIsOpenAccordian(!isOpenAccordian);
            }
          }}
          className={`shadow-sm !p-0 overflow-hidden !cursor-default !border-borderPrimary ${additionalServiceClass}`}
          contentClassName="!p-0"
          contentParentClass="!mt-0 !pt-0 !border-t-0"
          accordionHeaderWrap={`px-4 py-3 cursor-pointer ${additionalServiceHeaderClass}`}
        >
          {serviceList?.length ? (
            <div className="flex flex-wrap">
              {showService === 'pickup' &&
              serviceTypes &&
              serviceTypes.PICKUP?.length > 0 ? (
                getList(serviceTypes.PICKUP)
              ) : showService === 'delivery' &&
                serviceTypes &&
                serviceTypes.DELIVERY?.length > 0 ? (
                getList(serviceTypes.DELIVERY)
              ) : showService === 'other' &&
                serviceTypes &&
                serviceTypes.OTHER?.length > 0 ? (
                getList(serviceTypes.OTHER)
              ) : (
                <NotFoundUI
                  title="No services found"
                  desc="No services found."
                  containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                />
              )}
            </div>
          ) : (
            <div className="flex justify-center mt-4">
              {/* Optionally handle loading or empty state */}
              {/* {isLoading ? (
                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
              ) : (
                false
              )} */}
            </div>
          )}
        </AccordionCmp>

        {isInbondMoreModalOpen && (
          <InBondMoreModal
            setServicesValidForm={setServicesValidForm}
            setIsInbondMore={setIsInbondMore}
            setIsInbondMoreModalOpen={setIsInbondMoreModalOpen}
            isInBondFormValid={isInBondFormValid}
            setIsSubmitForm={setIsSubmitForm}
            handleChangeRadio={handleChangeRadio}
            inbondType={inbondType}
            validServicesForm={validServicesForm}
            inbondName={inbondName}
            setInbondName={setInbondName}
            inbondAddress={inbondAddress}
            setInbondAddress={setInbondAddress}
            getName={getName}
            inbondContactMethod={inbondContactMethod}
            inbondContactValue={inbondContactValue}
            setInbondContactValue={setInbondContactValue}
            handleNumberChange={handleNumberChange}
          />
        )}
        {isDangerousMoreModalOpen && (
          <DangerousMoreModal
            setServicesValidForm={setServicesValidForm}
            setIsDangerousFormSubmitted={setIsDangerousFormSubmitted}
            setUnitedNationsNumber={setUnitedNationsNumber}
            setGoodsClass={setGoodsClass}
            setDescription={setDescription}
            setEmergencyContactName={setEmergencyContactName}
            setEmergencyContactNumber={setEmergencyContactNumber}
            setEmergencyContactExt={setEmergencyContactExt}
            setIsDangerousMore={setIsDangerousMore}
            setIsDangerousMoreModalOpen={setIsDangerousMoreModalOpen}
            handleChangeRadio={handleChangeRadio}
            dangerType={dangerType}
            validServicesForm={validServicesForm}
            isDangerousGoodsFormValid={isDangerousGoodsFormValid}
            setIsSubmitForm={setIsSubmitForm}
            unitedNationsNumber={unitedNationsNumber}
            setPackagingGroup={setPackagingGroup}
            packagingGroup={packagingGroup}
            goodsClass={goodsClass}
            description={description}
            emergencyContactName={emergencyContactName}
            handleDangerPhoneChange={handleDangerPhoneChange}
            isDangerousFormSubmitted={isDangerousFormSubmitted}
            emergencyContactNumber={emergencyContactNumber}
            emergencyContactExt={emergencyContactExt}
          />
        )}
        {isExcessValuationModalOpen && (
          <ExcessValuationModal
            setServicesValidForm={setServicesValidForm}
            setIsDeclaredValueMore={setIsDeclaredValueMore}
            isExcessValuation={isExcessValuation}
            setIsSubmitForm={setIsSubmitForm}
            setIsExcessValuationModalOpen={setIsExcessValuationModalOpen}
            validServicesForm={validServicesForm}
            setDeclaredValue={setDeclaredValue}
            declaredValue={declaredValue}
            filterArr={filterArr}
            setDeclaredValueUnit={setDeclaredValueUnit}
            declaredValueUnit={declaredValueUnit}
          />
        )}
        {isAccessTypeModalOpen && (
          <AccessTypeModal
            setLimitedAccessSearch={setLimitedAccessSearch}
            setServicesValidForm={setServicesValidForm}
            setIsAccessTypeModalOpen={setIsAccessTypeModalOpen}
            isAccessTypeFormValid={isAccessTypeFormValid}
            setIsSubmitForm={setIsSubmitForm}
            limitedAccessSearch={limitedAccessSearch}
            limitedAccessTypeList={limitedAccessTypeList}
            handleChangeRadio={handleChangeRadio}
            limitedAccessType={limitedAccessType}
            validServicesForm={validServicesForm}
            limitedAccessOther={limitedAccessOther}
            setLimitedAccessOther={setLimitedAccessOther}
          />
        )}
      </div>
    </>
  );
};

export default AdditionalServiceCmp;
