import { Phone } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';

import defaultImage from '../../../../assets/img/default-image.jpg';

const ThreadCommunicationLoader = () => (
  <>
    <div className="flex flex-wrap items-center gap-2 w-full justify-between border-b border-utilityGray200 px-5 py-3.5">
      <div className="w-9 h-9 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none custom-loading">
        <span className="text-primary lg:text-xl text-sm font-semibold"></span>
      </div>
      <h6 className="flex-1 text-base font-semibold text-grayLight900 custom-loading">
        Test Carrier
      </h6>
      <div className="flex gap-3">
        <ButtonCmp
          icon={<Phone className="w-4 h-4" />}
          className="btn_secondary_black lg:px-[9px] px-2 custom-loading"
        >
          <></>
        </ButtonCmp>
        <ButtonCmp
          icon={<Phone className="w-4 h-4" />}
          className="btn_secondary_black lg:px-[9px] px-2 custom-loading"
        >
          <></>
        </ButtonCmp>
      </div>
    </div>
    <div className="bg-gray25 flex-1 w-full p-5 overflow-y-auto custom-scrollbar-v2 flex flex-col">
      <ul className="w-full mt-auto flex flex-col gap-y-6">
        <li className="flex gap-3 cursor-pointer">
          <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none custom-loading">
            <p className="mb-0 text-sm">dc</p>
          </div>
          <div className="w-[42%] space-y-1 max-w-[440px] flex-1">
            <div className="flex justify-between gap-x-2">
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                Drew Cano
              </p>
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                #WAL-PDFA-859
              </p>
            </div>
            <div className="bg-white flex flex-col text-grayLight900 text-xs font-normal p-4 rounded-xl rounded-tl-none border border-utilityGray200 shadow-xs gap-1.5 relative [&:hover>.hover-emoji]:!flex">
              <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                <div className="text-grayText w-8 custom-loading">From</div>
                <div className="flex items-center custom-loading">
                  <figure className="w-4 h-4 rounded-full border border-white flex-none">
                    <img
                      src={defaultImage}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </figure>
                  <p className="text-grayLight900 font-medium ml-1 break-all">
                    kylaclay@walhq.com
                  </p>
                </div>
              </div>
              <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                <div className="text-grayText w-8 custom-loading">To</div>
                <div className="flex items-center custom-loading">
                  <figure className="w-4 h-4 rounded-full border border-white flex-none">
                    <img
                      src={defaultImage}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </figure>
                  <p className="text-grayLight900 font-medium ml-1 break-all">
                    demiwilkinson@walhq.com
                  </p>
                </div>
              </div>
              <div className="flex py-1.5 gap-1.5 ">
                <div className=" font-medium custom-loading">Subject:</div>
                <div className="flex items-center text-grayLight600 custom-loading">
                  Order Tracking Information for Your Shipment
                </div>
              </div>
              <p className="custom-loading">
                We are excited to inform you that your order #WAL-PDFA-859 has
                been processed and is ...
              </p>
            </div>
            <div className="flex justify-between items-center gap-x-2">
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                12:40am
              </p>
              <div className="flex gap-1 items-center custom-loading">
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  👍
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  ❤️
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  👌
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  🙂
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  😅
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="flex gap-3 cursor-pointer ml-auto">
          <div className="w-[42%] space-y-1 max-w-[440px] flex-1">
            <div className="flex justify-between gap-x-2">
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                Drew Cano
              </p>
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                #WAL-PDFA-859
              </p>
            </div>
            <div className="bg-white flex flex-col text-grayLight900 text-xs font-normal p-4 rounded-xl rounded-tl-none border border-utilityGray200 shadow-xs gap-1.5 relative [&:hover>.hover-emoji]:!flex">
              <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                <div className="text-grayText w-8 custom-loading">From</div>
                <div className="flex items-center custom-loading">
                  <figure className="w-4 h-4 rounded-full border border-white flex-none">
                    <img
                      src={defaultImage}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </figure>
                  <p className="text-grayLight900 font-medium ml-1 break-all">
                    kylaclay@walhq.com
                  </p>
                </div>
              </div>
              <div className="flex items-center py-1.5 border-b border-utilityGray200 gap-1.5">
                <div className="text-grayText w-8 custom-loading">To</div>
                <div className="flex items-center custom-loading">
                  <figure className="w-4 h-4 rounded-full border border-white flex-none">
                    <img
                      src={defaultImage}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </figure>
                  <p className="text-grayLight900 font-medium ml-1 break-all">
                    demiwilkinson@walhq.com
                  </p>
                </div>
              </div>
              <div className="flex py-1.5 gap-1.5 ">
                <div className=" font-medium custom-loading">Subject:</div>
                <div className="flex items-center text-grayLight600 custom-loading">
                  Order Tracking Information for Your Shipment
                </div>
              </div>
              <p className="custom-loading">
                We are excited to inform you that your order #WAL-PDFA-859 has
                been processed and is ...
              </p>
            </div>
            <div className="flex justify-between items-center gap-x-2">
              <div className="flex gap-1 items-center custom-loading">
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  👍
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  ❤️
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  👌
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  🙂
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  😅
                </div>
              </div>
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                12:40am
              </p>
            </div>
          </div>
          <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none custom-loading">
            <p className="mb-0 text-sm">dc</p>
          </div>
        </li>
        <li className="flex gap-3 cursor-pointer">
          <div className="relative flex items-center justify-center w-8 h-8 rounded-full uppercase border-gray-200 border bg-utilityGray100 text-primary flex-none custom-loading">
            <p className="mb-0 text-sm">dc</p>
          </div>
          <div className="w-[42%] space-y-1 max-w-[440px] flex-1">
            <div className="flex justify-between gap-x-2">
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                Drew Cano
              </p>
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                #WAL-PDFA-859
              </p>
            </div>
            <div className="rounded-xl rounded-tl-none border border-utilityGray200 bg-white shadow-xs px-4 py-3 flex flex-wrap items-center gap-x-3 w-fit">
              <div className="w-10 h-10 bg-gray500 rounded-full flex justify-center items-center custom-loading"></div>
              <div className="flex-1 mr-1.5">
                <h6 className="text-grayLight900 text-sm font-medium custom-loading">
                  Incoming call...
                </h6>
                <p className="text-grayLight600 text-sm font-normal custom-loading">
                  Not answered yet Not answered yet
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center gap-x-2">
              <p className="text-grayLight600 font-normal text-xs custom-loading">
                12:40am
              </p>
              <div className="flex gap-1 items-center custom-loading">
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  👍
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  ❤️
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  👌
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  🙂
                </div>
                <div className="text-xs cursor-pointer flex items-center bg-utilityGray100 px-1 min-w-6 min-h-6 py-0.5 rounded-full">
                  😅
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div className="xl:p-4 p-3 flex gap-2 border-t border-utilityGray200">
      <InputText
        inputName="email"
        placeholder="Email"
        id="chatInput"
        className=""
        value={''}
        onChangeFunc={() => {}}
        parentClassName="flex-1 custom-loading"
      />

      <ButtonCmp className="btn_primary lg:!px-[9px] !px-2 custom-loading">
        <div className="w-4 h-4"> </div>
      </ButtonCmp>
    </div>
  </>
);

export default ThreadCommunicationLoader;
