import { ChevronDown, ChevronRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CustomerListTableRow = ({
  data,
  isShowOrdersModal,
  selectedCustomer,
}: any) => (
  <>
    <td className="px-5 py-4 first:w-80 first:min-w-80 first:max-w-80 text-pretty">
      <div className="flex items-center gap-3">
        <div className="flex w-fit rounded border border-borderPrimary shadow-xs p-px mr-2">
          {isShowOrdersModal && data?.id === selectedCustomer?.id ? (
            <ChevronDown className="w-3.5 h-3.5 flex-none" />
          ) : (
            <ChevronRight className="w-3.5 h-3.5 flex-none" />
          )}
        </div>
        {data.image ? (
          <img
            src={`${data.imageUrl}${data.image}`}
            className="table-profile-icn-circle-xl rounded-md"
            alt={data.name}
            title={data.name}
            onError={onError}
          />
        ) : (
          <div className="table-profile-customer-circle-xl rounded-md">
            {getShortName(`${data.name}`)}
          </div>
        )}
        {data.name.length > 25 ? (
          <TooltipCmp
            message={data.name}
            parentClassName=""
            isOpenChildTopPosOnTop={false}
          >
            <span className="truncate block max-w-48 text-sm whitespace-nowrap font-semibold ">
              {data.name}
            </span>
          </TooltipCmp>
        ) : (
          <span className="truncate block max-w-48  text-sm whitespace-nowrap font-semibold ">
            {data.name}
          </span>
        )}
      </div>
    </td>

    <td className="px-5 py-4 w-64 min-w-64 max-w-64">
      <div className="flex items-center min-w-92">
        {data.salesRepImage && data.salesRepImageUrl ? (
          <img
            className="table-profile-icn-circle-sm"
            src={`${data.salesRepImageUrl}${data.salesRepImage}`}
            alt={data.salesRepFullName}
            title={data.salesRepFullName}
            onError={onError}
          />
        ) : data.salesRepFullName ? (
          <div className="table-profile-user-circle-sm">
            {getShortName(`${data.salesRepFullName}`)}
          </div>
        ) : (
          ''
        )}
        <div className="pl-2">
          <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate">
            {data.salesRepFullName}
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">{getFormattedNumber(data.sales, true, true)}</td>

    <td className="px-5 py-4">{getFormattedNumber(data.profit, true, true)}</td>
    <td className="px-5 py-4">
      {data.profitPercentage > 0
        ? `${parseFloat(data?.profitPercentage).toFixed(2)}%`
        : `${0}%`}
    </td>
    <td className="px-5 py-4">{data.orderCount} </td>
    <td
      className={`px-5 py-4 font-medium ${
        data.change < 0 ? 'text-fgErrorPrimary' : 'text-fgSuccessPrimary'
      }`}
    >
      {data.change < 0 ? '' : '+'}
      {data?.change > 0 ? parseFloat(data?.change).toFixed(2) : 0}%
    </td>
  </>
);

export default CustomerListTableRow;
