import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

export const addCallLog = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CALL_CENTER.ADD_CALL_LOG,
    data
  );

  return response;
};

export const addCallDuration = async (data: any) => {
  console.log('⭐️ ~ addCallDuration ~ data:', data);
  const response = await axiosInterceptor.post(
    API.CALL_CENTER.CALL_DURATION,
    data
  );

  return response;
};

export const transferCall = async (data: any) => {
  const response = await axiosInterceptor.post(
    `${window.API_URL}/twilio/transfer-call`,
    data
  );

  return response;
};
