import { Edit01, Trash01, Plus } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import CarrierModal from 'src/app/Orders/Common/Carrier/CarrierModal';
import TooltipCmp from 'src/components/TooltipCmp';
import { updateOrCreateCarrierPrice } from 'src/services/OrderService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

import { OrderContext } from '../..';

interface IProps {
  setDeleteAction: any;
}

const RenderCarrierPriceSection = ({ setDeleteAction }: IProps) => {
  const [carrierEditData, setCarrierEditData] = useState(null);
  const [isCarrierFormOpen, setIsCarrierFormOpen] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<any>(null);
  const [isCarrierAssigned, setIsCarrierAssigned] = useState(false);
  const [additionalCharge, setAdditionalCharge] = useState<number>(0);

  const {
    currentLegData,
    setCurrentLegData,
    id,
    order,
    carrierPrice,
    setCarrierPrice,
    setTabValues,
    activeLeg,
  } = useContext<any>(OrderContext);

  useEffect(() => {
    if (
      carrierPrice &&
      Array.isArray(carrierPrice) &&
      carrierPrice.length > 0
    ) {
      const selectedCarrier = carrierPrice[0];
      setCarrier(selectedCarrier);

      const additionalChargesTotal = selectedCarrier?.additional_charge
        ? selectedCarrier.additional_charge.reduce(
            (total: number, item: any) => total + (item.price || 0),
            0
          )
        : 0;
      setAdditionalCharge(additionalChargesTotal);
    } else {
      setCarrier(null);
      setAdditionalCharge(0);
    }
  }, [carrierPrice]);

  useEffect(() => {
    if (carrier) {
      setIsCarrierAssigned(true);
    } else {
      setIsCarrierAssigned(false);
    }
  }, [carrier]);

  const handleCarrierEdit = (data: any) => {
    if (data.carrierDate) {
      data.carrierDate = moment(data.carrierDate).format('YYYY-MM-DD');
    }

    if (!data.carrierId) {
      data.carrierId = 'unassigned';
    }

    setCarrierEditData(data);
    setIsCarrierFormOpen(true);
  };

  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    updateOrCreateCarrierPrice({
      ...newCarrierPrice,
      orderLegId: currentLegData?.id,
      masterOrderId: id,
    })
      .then((response) => {
        if (response?.data) {
          let carrierPriceData = {};

          if (Array.isArray(response?.data) && response?.data.length > 0) {
            carrierPriceData = response.data.map((data: any) => ({
              ...data,
              additional_charge: data.additional_charge
                ? JSON.parse(data.additional_charge)
                : [],
              carrier: data.carrier ? JSON.parse(data.carrier) : {},
            }));
          }

          setIsCarrierFormOpen(false);
          setCarrierPrice(carrierPriceData);

          setCurrentLegData((old: any) => ({
            ...old,
            carrierPrice: carrierPriceData,
          }));

          setTabValues((prev: any) => ({
            ...prev,
            [activeLeg]: {
              ...prev[activeLeg],
              carrierPrice: carrierPriceData,
            },
          }));
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <ul className="rounded-lg border border-utilityGray200 bg-white shadow-xxs">
        <li className="last:border-b-0 border-b border-utilityGray200 p-3 space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Carrier
          </h6>
          {isCarrierAssigned ? (
            <>
              <div className="flex items-center gap-3">
                <div className="table-profile-wrap">
                  {carrier?.carrier?.image ? (
                    <img
                      className="table-profile-icn-circle-xl"
                      src={`${carrier?.carrier?.image}`}
                    />
                  ) : (
                    <div className="table-profile-user-circle-xl">
                      {getShortName(carrier?.carrier?.name ?? 'Unassigned')}
                    </div>
                  )}
                </div>
                <div className="truncate">
                  <h6 className="text-grayLight900 font-medium leading-tight truncate">
                    <TooltipCmp
                      message={
                        carrier?.carrier?.name &&
                        carrier?.carrier?.name?.length > 18 &&
                        carrier?.carrier?.name
                      }
                    >
                      {carrier?.carrier?.name ?? 'Unassigned'}
                    </TooltipCmp>
                  </h6>
                  <p className="text-grayLight600 font-normal leading-tight truncate">
                    #WQ085020145
                  </p>
                </div>
              </div>
              <div className="justify-end items-center gap-2 flex ">
                <Edit01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={() => {
                    handleCarrierEdit(carrier);
                  }}
                />
                <Trash01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={() => {
                    setDeleteAction((old: any) => ({
                      ...old,
                      mode: 'delete-carrier-price',
                    }));
                  }}
                />
              </div>
            </>
          ) : (
            <div
              className="p-4 cursor-pointer hover:bg-gray25"
              onClick={() => setIsCarrierFormOpen(true)}
            >
              <div className="inline-flex items-center">
                <Plus className="w-4 h-4 text-primary700" />
                <p className="text-grayLight900 text-sm font-medium">
                  Add Carrier Costs
                </p>
              </div>
            </div>
          )}
        </li>

        <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
          <div className="w-1/2 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Base Cost
            </h6>
            <p className={`text-textSecondary text-sm font-medium`}>
              {carrier?.baseCarrierRate ? `$${carrier?.baseCarrierRate}` : '-'}
            </p>
          </div>
          <div className="w-1/2 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Total Cost
            </h6>
            <p className={`text-textSecondary text-sm font-medium `}>
              {carrier?.totalPrice ? `$${carrier?.totalPrice}` : '-'}
            </p>
          </div>
          <div className="w-1/2 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Additionals
            </h6>
            <p className={`text-textSecondary text-sm font-medium `}>
              {additionalCharge ? `$${additionalCharge}` : '-'}
            </p>
          </div>
        </li>
        <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
          <div className="w-1/2 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Budget Cost
            </h6>
            <p className={`text-textSecondary text-sm font-medium `}>
              {carrier?.budgetCost ? `$${carrier?.budgetCost}` : '-'}
            </p>
          </div>
          <div className="w-1/2 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Carrier Booking Date
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium `}
              dangerouslySetInnerHTML={{
                __html: carrier?.carrierBookingDate
                  ? getDateWithSuffixFormat(
                      getFormattedDate(
                        carrier?.carrierBookingDate,
                        `Do MMMM YYYY`,
                        false,
                        false
                      )
                    )
                  : '-',
              }}
            ></p>
          </div>
        </li>
      </ul>

      {isCarrierFormOpen && (
        <CarrierModal
          handleClose={() => {
            setIsCarrierFormOpen(false);
          }}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
          customerId={order?.cusotmerId}
        />
      )}
    </>
  );
};

export default RenderCarrierPriceSection;
