import React from 'react';
import CheckBox from 'src/components/CheckBox';

const CreditSafeCompanyLoadingRow = () => (
  <>
    <td className="px-5 py-4 w-[130px] max-w-[130px] min-w-[130px]">
      <div className="flex items-center gap-2">
        <CheckBox classes="custom-loading" onChangeFunc={() => {}} />
        <span className="custom-loading">456</span>
      </div>
    </td>

    <td className="px-5 py-4 w-[130px] max-w-[130px] min-w-[130px]">
      <span className="custom-loading truncate">456fg</span>
    </td>

    <td className="px-5 py-4 w-[120px] max-w-[120px] min-w-[120px]">
      <span className="custom-loading truncate">country</span>
    </td>

    <td className="px-5 py-4 w-[130px] max-w-[130px] min-w-[130px]">
      <p className="custom-loading truncate">Test Company Organisation</p>
    </td>

    <td className="px-5 py-4 w-[130px] max-w-[1px] min-w-[130px]">
      <p className="custom-loading truncate">Test Company Organisation</p>
    </td>

    <td className="px-5 py-4 w-[180px] max-w-[1px] min-w-[180px]">
      <p className="custom-loading truncate">Test Company Organisation</p>
    </td>

    <td className="px-5 py-4 w-[150px] max-w-[150px] min-w-[150px]">
      <span className="custom-loading truncate">Status</span>
    </td>

    <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
      <span className="custom-loading truncate">Status</span>
    </td>

    <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
      <span className="custom-loading truncate">Status</span>
    </td>

    <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
      <span className="custom-loading truncate">Status</span>
    </td>

    <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
      <span className="custom-loading truncate">dsfdsfs</span>
    </td>

    <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
      <p className="custom-loading truncate">Headers Quarter</p>
    </td>

    <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
      <p className="custom-loading truncate">+91 79878979877</p>
    </td>

    <td className="px-5 py-4 w-[150px] max-w-[150px] min-w-[150px]">
      <span className="custom-loading truncate">#21414</span>
    </td>

    <td className="px-5 py-4 w-[280px] max-w-[280px] min-w-[280px]">
      <p className="custom-loading truncate">Significant Items</p>
    </td>

    <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
      <p className="custom-loading truncate">Significant Items</p>
    </td>

    <td className="px-5 py-4 w-[200px] max-w-[200px] min-w-[200px]">
      <span className="custom-loading truncate">#ghfhfhg</span>
    </td>
  </>
);

export default CreditSafeCompanyLoadingRow;
