/* eslint-disable padding-line-between-statements */
import { DownloadCloud02, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import csv from 'src/assets/img/csv.png';
import doc from 'src/assets/img/doc.png';
import pdf from 'src/assets/img/pdf-icon.png';
import txt from 'src/assets/img/txt.png';
import xls from 'src/assets/img/xls.png';
import xlsx from 'src/assets/img/xlsx.png';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  convertToGBMBKB,
  downloadPdf,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const LoadInsuranceFiles = ({ data, deleteFile, onRowClick }: any) => {
  const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

  const getExtention = () => {
    const filename = data?.fileOriginalName;
    const parts = filename?.split('.');
    if (parts) return parts[parts?.length - 1] ?? '';
    return '';
  };

  const getImgSrc = () => {
    const fileType = getExtention();
    if (validImageExtensions.includes(fileType)) {
      return data.fileUrl + data.fileName;
    } else if (fileType === 'pdf') {
      return pdf;
    } else if (fileType === 'xls') {
      return xls;
    } else if (fileType === 'xlsx') {
      return xlsx;
    } else if (fileType === 'csv') {
      return csv;
    } else if (fileType === 'txt') {
      return txt;
    } else {
      return doc;
    }
  };

  const onDownloadFile = () => {
    downloadPdf(`${data.fileUrl}${data.fileName}`, data.fileName);
  };

  return (
    <>
      <td
        className="px-5 py-4 w-[33.99%] min-w-[33.99%] max-w-[1px] cursor-pointer"
        onClick={() => {
          onRowClick(data?.fileUrl + data?.fileName)();
        }}
      >
        <div className="flex items-center gap-3 ">
          <div className="w-8 h-10 flex-none  ">
            <img
              src={getImgSrc()}
              onError={onError}
              className="w-full h-full object-contain"
            />
          </div>

          <div className="truncate  ">
            <h6 className="text-grayLight900 font-medium truncate">
              {data.fileOriginalName}
            </h6>
            <span className="font-normal text-grayLight600">
              {convertToGBMBKB(data.size)}
            </span>
          </div>
        </div>
      </td>
      <td
        className="px-5 py-4 w-[27.99%] min-w-[27.99%] max-w-[27.99%]"
        onClick={() => {
          onRowClick(data?.fileUrl + data?.fileName)();
        }}
      >
        <span className=" ">
          {moment(data.uploadedDate).format('MMM DD, YYYY')}
        </span>
      </td>

      <td
        className="px-5 py-4 w-[30.99%] min-w-[30.99%] max-w-[1px]"
        onClick={() => {
          onRowClick(data?.fileUrl + data?.fileName)();
        }}
      >
        <div className="flex items-center gap-3">
          {data.uploadedBy?.image ? (
            <img
              src={data.uploadedBy?.imageUrl + data.uploadedBy?.image}
              className="table-profile-icn-circle-xl"
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl">
              {getShortName(
                `${data?.uploadedBy?.firstName} ${data?.uploadedBy?.lastName}`
              )}
            </div>
          )}
          {/* </div> */}
          <div className="truncate">
            <TooltipCmp
              message={`${data?.uploadedBy?.firstName} ${data?.uploadedBy?.lastName}`}
            >
              <p className="truncate text-grayLight900 font-medium">
                {`${data?.uploadedBy?.firstName} ${data?.uploadedBy?.lastName}`}
              </p>
            </TooltipCmp>
            <p className="font-normal truncate">{data?.uploadedBy?.jobRole}</p>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-[84px] min-w-[84px] max-w-[84px]">
        <div className="flex gap-2">
          <Trash01
            className="w-4 h-4 cursor-pointer"
            // onClick={() => removeRaw(data?.id, 'financeStatement')}
            onClick={() => deleteFile(data)}
          />
          <DownloadCloud02
            className="w-4 h-4 cursor-pointer"
            onClick={onDownloadFile}
          />
        </div>
      </td>
    </>
  );
};

export default LoadInsuranceFiles;
