import { ArrowDownLeft } from '@untitled-ui/icons-react/build/cjs';
import { ArrowUpRight } from 'lucide-react';
import moment from 'moment';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getShortName,
  setNumberWithCommas,
  usePhone,
} from 'src/utils/CommonFunctions';

// import star from '../../../../../assets/img/Star.svg';

import { ILeadRowProps } from './LoadBoard.interface';

const LoadBoardRateRow = ({ data }: ILeadRowProps) => {
  const { getFormatPhoneWithPlus } = usePhone();
  // const [isShowTypeInfoModal, setIsShowTypeInfoModal] = useState(false);

  return (
    <>
      <td className="px-5 py-4 max-w-[1px] w-[18%] min-w-[200px]">
        <div className="flex items-center">
          <div className="table-profile-customer-circle-xl rounded-md">
            {getShortName(data.company ? data.company : 'Unknown')}
          </div>
          <div className="ml-3 truncate flex-1">
            <TooltipCmp message={data.company ? data.company : 'Unknown'}>
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {data.company ? data.company : 'Unknown'}
              </h6>
            </TooltipCmp>

            <div className="flex items-center truncate">
              <p className="max-w-[calc(100%_-_108px)]">
                <TooltipCmp message={data.carrierContactPerson}>
                  <p className="text-grayLight600 font-normal leading-tight truncate mr-0.5">
                    {data.carrierContactPerson != 'Not provided' &&
                    data.carrierContactPerson != 'None'
                      ? data.carrierContactPerson
                      : ''}
                  </p>
                </TooltipCmp>
              </p>
              <span>{getFormatPhoneWithPlus(data.companyPhone)}</span>
            </div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
        <BadgeCmp
          style="modern"
          type={
            data.callStatus === 'Completed'
              ? 'success'
              : data.callStatus === 'in progress'
              ? 'primary'
              : 'error'
          }
        >
          {data.callStatus}
        </BadgeCmp>
      </td>
      {/* <td className="px-5 py-4 max-w-[110px] w-[110px] min-w-[110px]">
        <div className="rounded-md border border-utilityGray200 px-1 py-0.5 inline-flex items-center gap-x-0.5 text-gray500 font-normal">
          4.5
          <img src={star} className="w-3.5 h-3.5 object-contain" />
        </div>
      </td> */}
      <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
        <div className="flex">
          {data.callType == 'inbound' && (
            <ArrowDownLeft color="#475467" className="w-4 h-4 mr-1" />
          )}
          {data.callType == 'outbound' && (
            <ArrowUpRight color="#475467" className="w-4 h-4 mr-1" />
          )}
          <span>
            {data.callType
              ? data.callType.charAt(0).toUpperCase() + data.callType.slice(1)
              : ''}
          </span>
        </div>
      </td>

      {/* <td className="px-5 py-4 max-w-[1px] w-32 min-w-32">
        <p className="truncate">{data.carrierContactPerson}</p>
      </td> */}

      <td className="px-5 py-4 min-w-[12.10%] w-[12.10%] max-w-[1px]">
        <div className="truncate max-w-full w-fit">
          {data.vehicleType.length > 30 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="max-w-[320px] w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={data.vehicleType}
            >
              <p>{`${data.vehicleType.slice(0, 30)}...`}</p>
            </ActionTooltip>
          ) : (
            <p className="truncate ">{data.vehicleType}</p>
          )}
        </div>
      </td>
      <td className="px-5 py-4 min-w-[12.10%] w-[12.10%] max-w-[1px]">
        <span>
          {data.estimatedDeliveryDate
            ? moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').isValid()
              ? moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').format(
                  'MMM Do - YYYY'
                )
              : data.estimatedDeliveryDate
            : ''}
        </span>
      </td>
      <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
        <span className="text-textSecondary font-bold">
          {data.offerAmount ? `$${setNumberWithCommas(data.offerAmount)}` : ''}
        </span>
      </td>
      <td className="px-5 py-4 min-w-[20.04%] w-[20.04%] max-w-[1px]">
        <TooltipCmp message={data.notes}>
          <div className="truncate">{data.notes || '-'}</div>
        </TooltipCmp>
      </td>
      {/* <td
        className="px-5 py-4 max-w-[60px] w-[60px] min-w-[60px]"
        onClick={() => setIsShowTypeInfoModal(true)}
      >
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
      </td>
      {isShowTypeInfoModal &&
        // <MoveToActive
        //   handleClose={() => setIsShowTypeInfoModal(false)}
        //   selectedCarrierRates={selectedCarrierRates}
        //   currency={currency}
        // />
        null} */}
    </>
  );
};

export default LoadBoardRateRow;
