import {
  ChevronLeft,
  PhoneOutgoing01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const CallTranscriptLoader = () => (
  <>
    <div className="px-4 py-[26px] flex items-center gap-x-2 border-b border-utilityGray200">
      <div className="custom-loading w-full">
        <ChevronLeft className="w-4 h-4" />
        <h6 className="text-grayLight900 text-base font-medium flex-1">
          Call Transcript
        </h6>
      </div>
    </div>
    <div className="p-4">
      <div className="p-3 rounded-lg border border-utilityGray200 flex gap-x-2">
        <div className="rounded-full bg-gray500 w-9 h-9 flex items-center justify-center custom-loading">
          <PhoneOutgoing01 className="text-white w-5 h-5" />
        </div>
        <div className="flex-1 custom-loading">
          <h6 className="text-grayLight900 text-xs font-medium leading-[1.5] line-clamp-1">
            Call with Olivia Rhye
          </h6>
          <div className="text-textSecondary text-xs font-normal leading-[1.5] flex gap-x-1">
            <p className="line-clamp-1">Kate Morrison</p> 2:40
          </div>
        </div>
      </div>
    </div>
    <ul className="flex-1 custom-scrollbar-v2 overflow-auto mb-4">
      {Array.from({ length: 7 }).map((_, index) => (
        <li className="px-6 py-2 flex gap-x-3" key={index}>
          <p className="text-gray500 text-xs font-medium mt-0.5 custom-loading h-fit">
            0:01
          </p>
          <p className="text-gray500 text-xs font-normal custom-loading">
            Hugh G. Rection: Lorem ipsum dolor sit amet, consectetur
          </p>
        </li>
      ))}
    </ul>
  </>
);

export default CallTranscriptLoader;
