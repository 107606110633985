import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  IMapAddress,
  initAction,
  initShipperAddress,
  scriptId,
} from 'src/app/Orders/order.interface';
import Address from 'src/app/QuotingHub/QuotingDashboard/AddressDetails/Address';
import DeleteModal from 'src/components/DeleteModal';
import { BasicContext } from 'src/context/BasicContext';
import {
  createShipper,
  deleteShippers,
  updateShipper,
} from 'src/services/ShipperService';

import { OrderContext } from '../../..';

import ShipperAndConsigneeAddressModal from './ShipperAndConsigneeAddressModal';

interface IProps {
  action: any;
  setAction: any;

  shippers: any;
  consignees: any;
  shipperOptions: any;
  consigneeOptions: any;
  isValidAddress: any;
  setIsValidAddress: any;
  isConsigneeBtnDisable: any;
  setIsConsigneeBtnDisable: any;
  isShipperBtnDisable: any;
  setIsShipperBtnDisable: any;
  getShipper: any;
  currentLegData: any;
  setCurrentLegData: any;
  tempLegData: any;
  setTempLegData: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
}

const AddressDetails = ({
  action,
  setAction,
  currentLegData,
  setCurrentLegData,
  shippers,
  consignees,
  shipperOptions,
  consigneeOptions,
  isValidAddress,
  setIsValidAddress,
  isConsigneeBtnDisable,
  setIsConsigneeBtnDisable,
  isShipperBtnDisable,
  setIsShipperBtnDisable,
  getShipper,
  tempLegData,
  setTempLegData,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext();

  const { setIsTailgate, serviceList } = useContext<any>(OrderContext);

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );

  const [addressData, setAddressData] = useState<IMapAddress>();

  const { googleKey } = useContext(BasicContext);

  useEffect(() => {
    if (currentLegData) {
      setShipperAddress(currentLegData?.shipper?.shipperAddress?.fullAddress);
      setConsigneeAddress(
        currentLegData?.consignee?.consigneeAddress?.fullAddress
      );
    }
  }, [currentLegData]);

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value?.fullAddress);
      setValue('shipper.shipperAddress', value);
      setIsValidAddress((old: any) => ({ ...old, shipperAddress: true }));

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData.shipper,
          shipperAddress: value,
        },
      }));
    } else if (action.consignee) {
      setConsigneeAddress(value?.fullAddress);
      setValue('consignee.consigneeAddress', value);
      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData.consignee,
          consigneeAddress: value,
        },
      }));

      setIsValidAddress((old: any) => ({ ...old, consigneeAddress: true }));
    }
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value?.type === 'blur') {
      // if (value.target.value) {
      //   setIsValidAddress((old) => ({ ...old, [name]: false }));
      // }

      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));
        setError('shipper.shipperAddress.fullAddress', {
          type: 'manual',
          message: 'Shipper address is invalid.',
        });
        setValue('shipper.shipperAddress', value);

        return;
      }
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value?.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));
        setError('consignee.consigneeAddress.fullAddress', {
          type: 'manual',
          message: 'Consignee address is invalid.',
        });
        setValue('consignee.consigneeAddress', value);

        return;
      }
      setIsValidAddress((old: any) => ({ ...old, [name]: true }));
    }

    if (name === 'consigneeAddress') {
      if (value?.city && value?.address1 && value?.city != value?.address1) {
        clearErrors('consignee.consigneeAddress.fullAddress');
        setIsValidAddress((old: any) => ({ ...old, [name]: true }));
        setConsigneeAddress(value?.fullAddress);
      } else {
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));

        setError('consignee.consigneeAddress.fullAddress', {
          type: 'manual',
          message: 'Consignee address is invalid.',
        });
      }

      setValue('consignee.consigneeAddress', value);

      if (value?.companyName) {
        setValue('consignee.companyName', value.companyName);
      }

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData.consignee,
          consigneeAddress: value,
        },
      }));
    }

    if (name === 'shipperAddress') {
      if (value?.city && value?.address1 && value?.city != value?.address1) {
        clearErrors('shipper.shipperAddress.fullAddress');
        setIsValidAddress((old: any) => ({ ...old, [name]: true }));
        setShipperAddress(value?.fullAddress);
      } else {
        setIsValidAddress((old: any) => ({ ...old, [name]: false }));

        setError('shipper.shipperAddress.fullAddress', {
          type: 'manual',
          message: 'Shipper address is invalid.',
        });
      }

      setValue('shipper.shipperAddress', value);

      if (value?.companyName) {
        setValue('shipper.companyName', value.companyName);
      }

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData.shipper,
          shipperAddress: value,
        },
      }));
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(getValues('shipper.shipperAddress'));
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(getValues('consignee.consigneeAddress'));
      }
      setAction((old: any) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;

    if (name === 'shipperId') {
      let shipperAddr = initShipperAddress;

      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );

        setValue('shipper.companyName', shipper.companyName);
        setValue('shipper.id', shipper.id);
        shipperAddr = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setShipperAddress(shipper.fullAddress);
      } else {
        setValue('shipper.companyName', undefined);
        setValue('shipper.id', undefined);
        setShipperAddress('');
      }

      setValue('shipper.shipperAddress', shipperAddr);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        shipper: {
          ...prevData.shipper,
          shipperAddress: shipperAddr,
        },
      }));
    } else if (name === 'consigneeId') {
      let consigneeAddr = initShipperAddress;

      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        setValue('consignee.companyName', consignee.companyName);
        setValue('consignee.id', consignee.id);

        consigneeAddr = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setConsigneeAddress(consignee.fullAddress);
      } else {
        setValue('consignee.companyName', undefined);
        setConsigneeAddress('');
      }

      setValue('consignee.consigneeAddress', consigneeAddr);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        consignee: {
          ...prevData.consignee,
          consigneeAddress: consigneeAddr,
        },
      }));
    }

    if (event?.value) {
      setIsValidAddress({ ...isValidAddress, [name]: true });

      if (name === 'consigneeId') {
        setIsValidAddress({
          ...isValidAddress,
          consigneeAddress: true,
          consigneeCompanyName: true,
        });
      }

      if (name === 'shipperId') {
        setIsValidAddress({
          ...isValidAddress,
          shipperAddress: true,
          shipperCompanyName: true,
        });
      }
    }
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave;
    let address: any;
    let companyName;
    let typeId;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = getValues('shipper.id') ?? null;
      companyName = getValues('shipper.companyName');
      address = getValues('shipper.shipperAddress');
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = getValues('consignee.id') ?? null;
      companyName = getValues('consignee.companyName');
      address = getValues('consignee.consigneeAddress');
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
    };

    if (
      companyName === '' ||
      address.fullAddress === '' ||
      typeof address !== 'object'
    ) {
      if (companyName === '' || !companyName) {
        if (type === 'shipper') {
          setIsValidAddress((old: any) => ({
            ...old,
            shipperCompanyName: false,
          }));
        } else if (type === 'consignee') {
          setIsValidAddress((old: any) => ({
            ...old,
            consigneeCompanyName: false,
          }));
        }
      }

      if (address.fullAddress === '' || typeof address !== 'object') {
        if (type === 'shipper') {
          setIsValidAddress((old: any) => ({ ...old, shipperAddress: false }));
        } else if (type === 'consignee') {
          setIsValidAddress((old: any) => ({
            ...old,
            consigneeAddress: false,
          }));
        }
      }

      return false;
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
          }
        })
        .catch(console.error);
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper();

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
          }
        })
        .catch(console.error);
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? getValues('shipper.id')
          : getValues('consignee.id');

        if (idToDelete) {
          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                if (action.shipper) {
                  setShipperAddress('');
                } else if (action.consignee) {
                  setConsigneeAddress('');
                }

                getShipper();

                // const title = action.shipper ? 'Shipper' : 'Consignee';
                // WalToast.success(`${title} deleted successfully`, '');
              }
            })
            .catch(console.error);
        }
      }
    },
    [action]
  );

  useEffect(() => {
    clearErrors('shipper.shipperAddress.fullAddress');

    if (!shipperAddress) {
      setValue('shipper.shipperAddress', initShipperAddress);
      setValue('shipper.companyName', undefined);
      setValue('shipper.id', null);
    }
  }, [shipperAddress]);

  useEffect(() => {
    clearErrors('consignee.consigneeAddress.fullAddress');

    if (!consigneeAddress) {
      setValue('consignee.consigneeAddress', initShipperAddress);
      setValue('consignee.companyName', undefined);
      setValue('consignee.id', null);
    }
  }, [consigneeAddress]);

  return (
    <>
      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />
      <ShipperAndConsigneeAddressModal
        control={control}
        errors={errors}
        data="shipper"
        title="Shipper"
        onAutocompleteChange={handleAutocompleteChange('shipperAddress')}
        onAddressInputChange={setShipperAddress}
        setAction={setAction}
        status={status}
        handleActionType={handleActionType}
        isAddressExits={shipperAddress}
        isValidAddress={isValidAddress}
        selectBoxOptionList={shipperOptions}
        handleSelectChange={handleSelectChange}
        setIsTailgate={setIsTailgate}
        currentLegData={tempLegData}
        setCurrentLegData={setTempLegData}
        setValue={setValue}
        serviceList={serviceList}
        saveShipperDetails={saveShipperDetails}
        shipperBtnDisable={isShipperBtnDisable}
      />

      <ShipperAndConsigneeAddressModal
        control={control}
        errors={errors}
        data="consignee"
        title="Consignee"
        onAutocompleteChange={handleAutocompleteChange('consigneeAddress')}
        onAddressInputChange={setConsigneeAddress}
        setAction={setAction}
        status={status}
        handleActionType={handleActionType}
        isAddressExits={consigneeAddress}
        isValidAddress={isValidAddress}
        selectBoxOptionList={consigneeOptions}
        handleSelectChange={handleSelectChange}
        setIsTailgate={setIsTailgate}
        currentLegData={tempLegData}
        setCurrentLegData={setTempLegData}
        setValue={setValue}
        serviceList={serviceList}
        saveShipperDetails={saveShipperDetails}
        shipperBtnDisable={isConsigneeBtnDisable}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? getValues('consignee.companyName')
              : getValues('shipper.companyName')
          } From Wal HQ `}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}
    </>
  );
};

export default AddressDetails;
