import React from 'react';

const OfficersOnboarding = ({ data }: any) => (
  <>
    <td className="px-5 py-4">
      <span>{data.officer_name}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.title}</span>
    </td>
    {/* <td className="px-5 py-4">
      <span>{data.home_address}</span>
    </td>
    <td className="px-5 py-4">
      <span>{data.city}</span>
    </td> */}
  </>
);

export default OfficersOnboarding;
