import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const addOnBoardingCustomer = async (data: any) => {
  console.log('data :>> ', data);
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.CREATE,
    data
  );

  return response;
};

export const deleteStatementFile = async (id: number) => {
  const response = await axiosInterceptor.delete(
    `${API.CUSTOMER_ONBOARDING.DELETE_STATEMENT}/${id}`
  );

  return response;
};

export const uploadFinanceStatement = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.UPLOAD_FINANCE_STATEMENT,
    data
  );

  return response;
};

export const businessCreditApplication = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.BUSINESS_CREDIT_APPLICATION,
    { ...data, id: id }
  );

  return response;
};

export const businessCreditApplicationDetail = async (
  id: any,
  customerId?: any
) => {
  const response = await axiosInterceptor.patch(
    `${API.CUSTOMER_ONBOARDING.BUSINESS_CREDIT_APPLICATION_DETAIL}/${
      id ?? customerId
    }`,
    { customerId }
  );

  return response;
};

export const sendCustomerMail = async (id: any, data?: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.SEND_MAIL,
    { ...data, id }
  );

  return response;
};

export const resendCustomerMail = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.RESEND_MAIL,
    data
  );

  return response;
};

export const updateOnboarding = async (data: any) => {
  const response = await axiosInterceptor.patch(
    API.CUSTOMER_ONBOARDING.UPDATE,
    data
  );

  return response;
};

export const updateAsOpenOnboarding = async (data: any) => {
  const response = await axiosInterceptor.patch(
    API.CUSTOMER_ONBOARDING.UPDATE_AS_OPEN,
    data
  );

  return response;
};

export const verifyOtp = async (id: number, otp: any) => {
  // console.log('id,otp :>> ', id, otp);
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.VERIFY_OTP,
    { id: id, otp: otp }
  );

  return response;
};

export const creditReferenceAttachment = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.CREDIT_REFERENCE_ATTACHMENT,
    data
  );

  return response;
};

export const deleteAttachment = async (id: any) => {
  const response = await axiosInterceptor.delete(
    `${API.CUSTOMER_ONBOARDING.DELETE_ATTACHMENT}/${id}`
  );

  return response;
};

export const getCreditReferenceAttachment = async (id: any) => {
  const response = await axiosInterceptor.get(
    `${API.CUSTOMER_ONBOARDING.GET_CREDIT_REFERENCE_ATTACHMENT}/${id}`
  );

  return response;
};

export const creditReferenceNote = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.CREDIT_REFERENCE_NOTE,
    data
  );

  return response;
};

export const getCreditReferenceNote = async (id: any) => {
  const response = await axiosInterceptor.get(
    `${API.CUSTOMER_ONBOARDING.GET_CREDIT_REFERENCE_NOTE}/${id}`
  );

  return response;
};

export const aiResponseGenerate = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.CUSTOMER_ONBOARDING.AI_API,
    data
  );

  return response;
};

export const creditSafeOnboardingCompanyList = async (params: any) => {
  const response = await axiosInterceptor.get(
    API.CUSTOMER_ONBOARDING.CREDIT.LIST_CREDIT_SAFE_COMPANIES,
    {
      params,
    }
  );

  return response;
};

export const linkCompanyToCreditSafe = async (id: any, params: any) => {
  const response = await axiosInterceptor.post(
    `${API.CUSTOMER_ONBOARDING.CREDIT.CUSTOMER_CREDIT_DETAIL}/${id}`,
    {
      ...params,
    }
  );

  return response;
};
