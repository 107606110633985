import React, { useEffect, useRef } from 'react';

import { MapStyle } from './MapStyle';

interface MapProps {
  address: any;
  id: string;
  onReverseAddressFunc?: any;
  draggable?: boolean;
  height?: number;
  className?: string;
}

const initialMapAddressVal = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
};

const Map: React.FC<MapProps> = ({
  address,
  id,
  className,
  onReverseAddressFunc,
  draggable,
  height,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<any>(null);
  const mapInstanceRef = useRef<any>(null);

  const getAddress = (lat: number, lng: number) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { location: { lat, lng } },
      (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            const addressObject = results[0];
            let latitudeOk = addressObject.geometry.location.lat();
            let longitudeOk = addressObject.geometry.location.lng();
            let city = '';
            let state = '';
            let postcode = '';
            let addressOk = '';
            let country = '';
            let stateCode = '';
            let countryCode = '';

            addressObject.address_components.forEach((component: any) => {
              const componentType = component.types;

              if (componentType.includes('premise')) {
                addressOk += `${component.long_name} `;
              } else if (componentType.includes('street_number')) {
                addressOk += `${component.long_name} `;
              } else if (componentType.includes('route')) {
                addressOk += `${component.short_name} `;
              } else if (componentType.includes('sublocality')) {
                addressOk += `${component.long_name} `;
              } else if (componentType.includes('postal_code')) {
                postcode = `${component.long_name}${postcode}`;
              } else if (componentType.includes('postal_code_suffix')) {
                postcode = `${postcode}-${component.long_name}`;
              } else if (componentType.includes('locality')) {
                city = component.long_name;
              } else if (
                componentType.includes('administrative_area_level_1')
              ) {
                state = component.long_name;
                stateCode = component.short_name;
              } else if (componentType.includes('country')) {
                country = component.long_name;
                countryCode = component.short_name;
              }
            });

            const query = addressObject.formatted_address;

            let addressObj = {
              fullAddress: query,
              address1: addressOk,
              city: city,
              state: state,
              country: country,
              postal: postcode,
              latitude: latitudeOk,
              longitude: longitudeOk,
              stateCode: stateCode,
              countryCode: countryCode,
            };
            onReverseAddressFunc(addressObj);
          } else {
            onReverseAddressFunc(initialMapAddressVal);
          }
        } else {
          onReverseAddressFunc(initialMapAddressVal);
        }
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const latitude = parseFloat(address?.latitude);
      const longitude = parseFloat(address?.longitude);

      if (
        window.google &&
        window.google.maps &&
        mapRef.current &&
        !mapInstanceRef.current
      ) {
        const mapOptions = {
          center: {
            lat: !isNaN(latitude) ? latitude : 56.130366,
            lng: !isNaN(longitude) ? longitude : -106.346771,
          },
          zoom: !isNaN(latitude) && !isNaN(longitude) ? 15 : 3,
          gestureHandling: 'cooperative',
          mapTypeId: 'styled_map',
          mapTypeControlOptions: {
            mapTypeIds: ['styled_map', 'satellite'],
          },
        };

        const styledMapType = new window.google.maps.StyledMapType(MapStyle, {
          name: 'Roadmap',
        });

        const map = new window.google.maps.Map(mapRef.current, mapOptions);
        map.mapTypes.set('styled_map', styledMapType);
        mapInstanceRef.current = map;

        const marker = new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: map,
          draggable: draggable,
        });

        markerRef.current = marker;

        if (draggable) {
          window.google.maps.event.addListener(marker, 'dragend', () => {
            const position = marker.getPosition();
            getAddress(position.lat(), position.lng());
          });
        }
      }
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const latitude = parseFloat(address?.latitude);
      const longitude = parseFloat(address?.longitude);

      if (
        mapInstanceRef.current &&
        markerRef.current &&
        !isNaN(latitude) &&
        !isNaN(longitude)
      ) {
        const newLatLng = new window.google.maps.LatLng(latitude, longitude);

        if (!markerRef.current) {
          markerRef.current = new window.google.maps.Marker({
            position: newLatLng,
            map: mapInstanceRef.current,
          });
        } else {
          markerRef.current.setPosition(newLatLng);
          markerRef.current.setMap(mapInstanceRef.current);
        }

        mapInstanceRef.current.panTo(newLatLng);
        mapInstanceRef.current.setZoom(15);
      } else if (mapInstanceRef.current && markerRef.current) {
        const newLatLng = new window.google.maps.LatLng(56.130366, -106.346771);
        markerRef.current.setPosition(newLatLng);
        mapInstanceRef.current.panTo(newLatLng);
        mapInstanceRef.current.setZoom(3);
        markerRef.current.setMap(null);
      }
    }, 500);
  }, [address]);

  return (
    <div
      className={className}
      id={id}
      ref={mapRef}
      style={{ width: '100%', height: `${height}px` }}
    />
  );
};

Map.defaultProps = {
  address: {},
  id: '',
  draggable: true,
  height: 220,
};

export default Map;
