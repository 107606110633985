import {
  ArrowNarrowLeft,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Copy01,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
// import SelectBox from 'src/components/SelectBox/SelectBox';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import TrackTraceAiCmp from 'src/components/TrackTraceAiCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getMapService } from 'src/services/IntegrationService';
import {
  deleteAppointment,
  getRadarOrderDetail,
  updateOrderLocation,
} from 'src/services/OrderService';
import {
  calculateDistance,
  formatLastUpdate,
  getDateWithSuffixFormat,
  getFormattedDate,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../assets/img/default-image.jpg';
import blueDot from '../../assets/img/radar/blue-gradient-dot.svg';
import checkBoxGreen from '../../assets/img/radar/checkbox-green.svg';
import clock from '../../assets/img/radar/clock.svg';
import cross from '../../assets/img/radar/cross-red.svg';
import magnifier from '../../assets/img/radar/magnifying-glass.svg';
import truck from '../../assets/img/radar/truck.svg';
// import CommonAppointmentDetails from '../Orders/Common/Appointment/CommonAppointmentDetails';
import AppointmentModal from '../Orders/Common/Appointment/AppointmentModal';
import AppointmentSuccessModal from '../Orders/Common/Appointment/AppointmentSuccessModal';
import CommonAppointmentDetails from '../Orders/Common/Appointment/CommonAppointmentDetails';
import DraftMailModal from '../Orders/Common/DraftMailModal';
import {
  appointmentDetails,
  getEmailMessageForAppointMent,
} from '../Orders/order.constant';
import { initAction } from '../Orders/order.interface';
import { scriptId } from '../QuotingHub/QuotingDashboard/AddressDetails/Address.interface';

import RadarAdditionalServices from './RadarAdditionalServices';
import RadarPricing from './RadarPricing';

// import UpdateLocation from './UpdateLocation';
const tabArr = [
  {
    value: 'dimensions',
    name: 'Dimensions',
  },
  {
    value: 'additional_services',
    name: 'Additional Services',
  },
  {
    value: 'appointments',
    name: 'Appointments',
  },
  {
    value: 'pricing',
    name: 'Pricing',
  },
];

const initFormData = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

const OrderDetail = ({ setOrderId, orderId, setOrder, order }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('dimensions');
  const [activeOrderLeg, setActiveOrderLeg] = useState<any>({});
  const [isDisplayLocation, setIsDisplayLocation] = useState(false);
  const [orderCurrentLocation, setOrderCurrentLocation] = useState<string>('');
  const [formData, setFormData] = useState<any>(initFormData);
  const { setMapService, googleKey, setGoogleKey, setTrimbleKey } =
    useContext(BasicContext);
  const [action, setAction] = useState<any>(initAction);
  const [editMode, setEditMode] = useState<{
    type: string | null;
    details: any;
  }>({ type: null, details: null });
  const { mapService } = useContext(BasicContext);

  useEffect(() => {
    if (orderId) {
      setIsLoading(true);
      setOrder({});
      getRadarOrderDetail(orderId)
        .then(async (response: any) => {
          setOrder(response.data);
          setIsLoading(false);

          setActiveOrderLeg(response.data?.orderLegs[0]);
          setOrderCurrentLocation(
            response.data?.orderLocationUpdate
              ? response.data?.orderLocationUpdate.fullAddress
              : response.data?.orderLegs?.[0].shipperFullAddress
          );

          if (response.data?.orderLocationUpdate) {
            setFormData({
              fullAddress: response.data?.orderLocationUpdate.fullAddress,
              address1: response.data?.orderLocationUpdate.address1,
              city: response.data?.orderLocationUpdate.city,
              state: response.data?.orderLocationUpdate.state,
              country: response.data?.orderLocationUpdate.country,
              postal: response.data?.orderLocationUpdate.postal,
              latitude: response.data?.orderLocationUpdate.latitude,
              longitude: response.data?.orderLocationUpdate.longitude,
              stateCode: response.data?.orderLocationUpdate.stateCode,
              countryCode: response.data?.orderLocationUpdate.countryCode,
            });
          }
        })
        .catch((e) => {
          console.log(e);

          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsLoading(false);
        });
    }
  }, orderId);

  useEffect(() => {
    getMapService().then((response: any) => {
      setIsLoading(false);

      if (response?.data) {
        if (response.data?.slug) {
          setMapService(response.data?.slug);

          if (response.data.configurations?.length) {
            let configuration = response.data.configurations[0];

            if (configuration.value) {
              if (response.data.slug === 'trimble_map') {
                setTrimbleKey(configuration.value);
              } else {
                setGoogleKey(configuration.value);
              }
            }
          }
        }
      }
    });
  });

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  const changeActiveOrderLeg = (orderLegIndex: number) => {
    if (orderLegIndex >= 0 && orderLegIndex < order?.orderLegs.length) {
      setActiveOrderLeg(order?.orderLegs[orderLegIndex]);
    }
  };

  const submitUpdateOrderLocation = () => {
    const activeLeg = order?.orderLegs.find(
      (orderLeg: any) => orderLeg.status != 'completed'
    );

    if (activeLeg) {
      updateOrderLocation({
        fullAddress: formData.fullAddress,
        address1: formData.address1,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        postal: formData.postal,
        latitude: formData.latitude,
        longitude: formData.longitude,
        stateCode: formData.stateCode,
        countryCode: formData.countryCode,
        orderLegId: activeLeg.id,
      })
        .then((response: any) => {
          if (response) {
            order.orderLocationUpdate = response.data;
            setOrder(order);
            setIsDisplayLocation(false);
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDeleteAppointment = (type: any) => {
    deleteAppointment({
      orderLegId: activeOrderLeg?.id,
      type: type,
    })
      .then((response) => {
        if (response?.data) {
          const updatedLegData = { ...activeOrderLeg };

          if (type === 'shipper-appointment') {
            updatedLegData.shipperAppointmentDatetime = null;
          } else if (type === 'carrier-pickup-appointment') {
            updatedLegData.carrierPickupAppointmentDatetime = null;
          } else if (type === 'carrier-delivery-appointment') {
            updatedLegData.carrierDeliveryAppointmentDatetime = null;
          } else if (type === 'delivery-appointment') {
            updatedLegData.deliveryAppointmentDatetime = null;
          }

          setActiveOrderLeg((old: any) => ({ ...old, ...updatedLegData }));
        }
      })
      .catch(console.error);
  };

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      if (!formData.fullAddress) {
        setFormData(initFormData);
        setOrderCurrentLocation('');
      }

      return;
    }

    setFormData(value);
    setOrderCurrentLocation(value.fullAddress);
  };

  return (
    <>
      <div>
        <p
          className="flex items-center gap-x-0.5 text-grayLight900 text-xs font-medium mb-2.5 cursor-pointer"
          onClick={() => {
            setOrderId(null);
            setOrder(null);
          }}
        >
          <ArrowNarrowLeft className="w-4 h-4" />
          Back
        </p>

        <div className="flex gap-x-2 justify-between items-start">
          <a
            href={`/orders/${orderId}`}
            className={`text-primary text-xl font-medium flex-none `}
            target="_blank"
          >
            {order?.orderId}
          </a>
          <BadgeCmp style="modern" type="success">
            {order?.orderStatus}
          </BadgeCmp>
        </div>
        <div className="flex gap-x-2 justify-between items-start mt-1">
          <StatusDotProfileCmp
            label={`Last Update: ${formatLastUpdate(order?.createdAt)}`}
            dotColor="green"
          />
          <p className="text-xs text-gray400 font-normal">
            Tracking ID :&nbsp;
            <span className="text-grayLight600 font-medium mr-2"></span>
            <Copy01
              className="w-4 h-4 text-senary300 cursor-pointer inline hidden"
              onClick={() => copyDebounce('')}
            />
          </p>
        </div>
      </div>
      <div className=" flex flex-col gap-y-5 flex-1 overflow-y-auto scrollbar-hide">
        <ul className="rounded-xl border border-utilityGray200 p-4">
          <li className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden ">
            <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
              <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
            </div>
            <div className="flex-1 text-xs text-grayLight600 font-normal">
              <h6 className="text-gray400 text-xs font-normal">
                {order?.shipperAddress?.shipperCompanyName}
              </h6>
              <p className="text-textSecondary text-xs font-medium">
                {`${order?.shipperAddress?.city}, ${order?.shipperAddress?.state}`}
              </p>
            </div>
          </li>
          <li className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden">
            <div>
              <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
            </div>
            <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
              <div className="flex-1 text-xs text-grayLight600 font-normal">
                <h6 className="text-gray400 text-xs font-normal">
                  {order?.shipperAddress?.consigneeCompanyName}
                </h6>
                <p className="text-textSecondary text-xs font-medium">
                  {`${order?.consigneeAddress?.city}, ${order?.consigneeAddress?.state}`}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className="rounded-xl border border-utilityGray200 p-3 ">
          <ul className="-mx-2 -my-3 flex flex-wrap">
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Sales Rep.
              </h6>
              {order?.salesRepFirstName && order?.salesRepLastName && (
                <div className="flex items-center gap-x-1 mt-1">
                  <img
                    src={
                      order?.salesImage && order?.salesImageUrl
                        ? `${order.salesImageUrl}${order.salesImage}`
                        : defaultImage
                    }
                    className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  />
                  {/* IF NO IMAGE FOUND THAN BELOW COMMENTED CODE WILL BE USED */}
                  {/* <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                  WA
                </div> */}
                  <p className="text-grayLight900 text-xs font-medium">
                    {order?.salesRepFirstName} {order?.salesRepLastName}
                  </p>
                </div>
              )}
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Customer
              </h6>
              <div className="flex items-center gap-x-1 mt-1">
                <img
                  src={
                    order?.customerImage && order?.customerImageUrl
                      ? `${order.customerImageUrl}${order.customerImage}`
                      : defaultImage
                  }
                  className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                />
                {/* IF NO IMAGE FOUND THAN BELOW COMMENTED CODE WILL BE USED */}
                {/* <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]">
                  WA
                </div> */}
                <p className="text-grayLight900 text-xs font-medium">
                  {order?.customerName}
                </p>
              </div>
            </li>
            <li className="w-1/3 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Order Created
              </h6>

              <p
                className="text-grayLight900 text-xs font-medium mt-1"
                dangerouslySetInnerHTML={{
                  __html:
                    order && order.createdAt
                      ? getDateWithSuffixFormat(
                          getFormattedDate(
                            order.createdAt,
                            `Do MMM YYYY`,
                            true,
                            true
                          )
                        )
                      : '-',
                }}
              ></p>
            </li>
            <li className="w-1/4 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Total Price
              </h6>

              <p className="text-grayLight900 text-xs font-medium mt-1">
                ${order?.combinedRevenue}
              </p>
            </li>
            <li className="w-1/4 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">Margin</h6>

              <p className="text-grayLight900 text-xs font-medium mt-1">
                ${order?.combinedMargin}{' '}
                <span className="text-gray500 font-normal text-[11px]">
                  ({order?.combinedMarginPercentage}%)
                </span>
              </p>
            </li>
            <li className="w-1/4 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Service Type
              </h6>

              {order?.orderCarrier?.quoteType && (
                <BadgeCmp
                  type="success"
                  style="modern"
                  badgeTextColor="!uppercase"
                >
                  {order?.orderCarrier?.quoteType}
                </BadgeCmp>
              )}
            </li>
            <li className="w-1/4 px-2 py-3">
              <h6 className="text-grayLight600 text-xs font-normal">
                Equipment Type
              </h6>

              {order?.equipmentTypeFullName}
            </li>
            <li className="w-full px-2 py-3 hidden">
              <h6 className="text-grayLight600 text-xs font-normal">Tags</h6>
              <div className="mt-1.5 flex flex-wrap gap-2">
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={truck} className="mr-1" />
                  Missing Carrier
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={blueDot} className="mr-1" />
                  LTL
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={magnifier} className="mr-1" />
                  After Hours Traced
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={cross} className="mr-1" />
                  Do Not Track
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={checkBoxGreen} className="mr-1" />
                  Delivered
                </BadgeCmp>
                <BadgeCmp type="success" style="modern" isHidePillDot>
                  <img src={clock} className="mr-1" />
                  Lift gate Required
                </BadgeCmp>
              </div>
            </li>
          </ul>
        </div>
        <AccordionCmp
          title={
            <div className="w-full">
              <h6 className="text-grayLight900 text-xs font-medium">
                Order Details
              </h6>
              <ul className="rounded-[10px] border border-utilityGray200 bg-gray25 p-4 flex gap-x-2 mt-3">
                <li className="flex-1 flex flex-col gap-y-1">
                  <h6 className="text-grayLight600 text-xs font-normal">
                    Order Type
                  </h6>
                  {order?.orderCarrier?.quoteType && (
                    <BadgeCmp
                      type="success"
                      style="modern"
                      mainClassName="w-fit"
                      badgeTextColor="!uppercase"
                    >
                      {order?.orderCarrier?.quoteType}
                    </BadgeCmp>
                  )}
                </li>
                <li className="flex-1 flex flex-col gap-y-1">
                  <h6 className="text-grayLight600 text-xs font-normal">
                    PO number
                  </h6>
                  <p className="text-textSecondary text-sm font-medium">
                    {order?.poNumber}
                  </p>
                </li>
                <li className="flex-1 flex flex-col gap-y-1">
                  <h6 className="text-grayLight600 text-xs font-normal">
                    Reference Number
                  </h6>
                  <p className="text-textSecondary text-sm font-medium">
                    {order?.refNumber}
                  </p>
                </li>
              </ul>
            </div>
          }
          className="!p-0 overflow-hidden flex-none"
          accordionHeaderWrap="p-4 relative"
          AccordionIconClass="absolute top-4 right-4"
          contentParentClass="bg-gray25 !mt-0"
          contentClassName="!bg-gray25 px-4 pb-4"
        >
          <AccordionCmp
            title={order?.orderLegs?.map((orderLeg: any, index: number) => (
              <div
                className={`w-full ${
                  activeOrderLeg?.id == orderLeg.id ? '' : 'hidden'
                }`}
                key={index}
              >
                <div className="flex justify-between pr-6">
                  <div className="flex items-center gap-2">
                    <h6 className="text-grayLight900 text-base font-medium">
                      #{orderLeg.legId}
                    </h6>
                    <BadgeCmp
                      style="modern"
                      type="success"
                      mainClassName={` ${isLoading ? 'custom-loading' : ''}`}
                    >
                      {orderLeg.legId}
                    </BadgeCmp>
                  </div>
                  {order?.orderLegs?.length > 1 && (
                    <ul className="flex gap-x-3">
                      <li
                        className={`w-8 h-8 flex justify-center items-center rounded-full border border-borderPrimary bg-white ${
                          activeOrderLeg?.id == order?.orderLegs?.[0].id
                            ? 'cursor-not-allowed'
                            : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          changeActiveOrderLeg(index - 1);
                        }}
                      >
                        <ChevronLeft className="w-4 h-4" />
                      </li>
                      <li
                        className={`w-8 h-8 flex justify-center items-center rounded-full border border-borderPrimary bg-white ${
                          activeOrderLeg?.id ==
                          order?.orderLegs?.[order?.orderLegs?.length - 1].id
                            ? 'cursor-not-allowed'
                            : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          changeActiveOrderLeg(index + 1);
                        }}
                      >
                        <ChevronRight className="w-4 h-4" />
                      </li>
                    </ul>
                  )}
                </div>
                <ul className="rounded-[10px] border border-utilityGray200 p-4 flex gap-x-2 mt-3 bg-white">
                  <li className="flex-1 flex flex-col gap-y-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Carrier
                    </h6>
                    {orderLeg?.carrierName && (
                      <div className="flex items-center gap-x-1">
                        <img
                          src={
                            orderLeg.carrierImageUrl + orderLeg.carrierImage ||
                            defaultImage
                          }
                          className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                        />
                        <p className="text-grayLight900 text-xs font-medium truncate">
                          {orderLeg?.carrierName}
                        </p>
                      </div>
                    )}
                  </li>
                  <li className="flex-1 flex flex-col gap-y-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Shipper
                    </h6>
                    <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                      {orderLeg?.shipperCity}, {orderLeg?.shipperState}
                    </p>
                  </li>
                  <li className="flex-1 flex flex-col gap-y-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Consignee
                    </h6>
                    <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                      {orderLeg?.consigneeCity}, {orderLeg?.consigneeState}
                    </p>
                  </li>
                </ul>
              </div>
            ))}
            className="!p-0 overflow-hidden flex-none border-0 !bg-transparent rounded-none"
            accordionHeaderWrap="relative"
            AccordionIconClass="absolute top-2 right-0"
            contentParentClass="bg-gray25 !mt-0 !border-t-0"
            contentClassName="!bg-gray25"
          >
            <div className="rounded-lg border border-utilityGray200 bg-white p-4">
              <TabButton
                parentClassName="tab-border-bottom-parent w-full gap-x-4 mb-5"
                activeClassName="tab-border-bottom-active"
                className="tab-border-bottom !px-0"
                tabBadgeClassName="tab-border-bottom-badge"
                tabArray={tabArr}
                handleOnClick={(e: any) => {
                  setActiveTab(e?.target?.dataset?.value);
                }}
                isActive={activeTab}
                isTabBorderBottom={true}
              />
              {activeTab === 'dimensions' &&
                order?.orderLegs?.map((orderLeg: any, legIndex: number) => (
                  <div
                    key={legIndex}
                    className={`${
                      orderLeg.id == activeOrderLeg?.id ? '' : 'hidden'
                    }`}
                  >
                    {orderLeg?.order_dimensions?.map(
                      (orderDimension: any, index: number) => (
                        <AccordionCmp
                          key={index}
                          title={`${index + 1} ${orderDimension.unit}`}
                          accordionHeaderWrap="text-xs"
                          className="border-t-0 border-x-0 rounded-none last:border-b-0 !px-0"
                          contentClassName="rounded-lg !bg-gray25 p-3 "
                          contentParentClass="!pt-0 !border-t-0"
                        >
                          <ul className="flex flex-wrap gap-y-5 -mx-1">
                            <li className="flex flex-col gap-y-1 px-1 w-1/3">
                              <h6 className="text-grayLight600 text-xs font-normal">
                                Handling unit
                              </h6>
                              <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                                {orderDimension.unit}
                              </p>
                            </li>
                            <li className="flex flex-col gap-y-1 px-1 w-1/3">
                              <h6 className="text-grayLight600 text-xs font-normal">
                                Dimensions ({orderDimension.lengthMeasurement})
                              </h6>
                              <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                                {orderDimension.length}” X{' '}
                                {orderDimension.width}” X{' '}
                                {orderDimension.height}”
                              </p>
                            </li>
                            <li className="flex flex-col gap-y-1 px-1 w-1/3">
                              <h6 className="text-grayLight600 text-xs font-normal">
                                Class
                              </h6>
                              <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                                {orderDimension.freightClass}
                              </p>
                            </li>
                            <li className="flex flex-col gap-y-1 px-1 w-1/3">
                              <h6 className="text-grayLight600 text-xs font-normal">
                                Total weight
                              </h6>
                              <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                                {orderDimension.totalWeight} (
                                {orderDimension.weightMeasurement})
                              </p>
                            </li>
                            <li className="flex flex-col gap-y-1 px-1 w-1/3">
                              <h6 className="text-grayLight600 text-xs font-normal">
                                REF Number
                              </h6>
                              <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                                {orderDimension.refNumber}
                              </p>
                            </li>
                            <li className="flex flex-col gap-y-1 px-1 w-1/3">
                              <h6 className="text-grayLight600 text-xs font-normal">
                                Unit Weight
                              </h6>
                              <p className="text-grayLight900 text-xs font-medium line-clamp-1">
                                {orderDimension.weight} (
                                {orderDimension.weightMeasurement})
                              </p>
                            </li>
                          </ul>
                        </AccordionCmp>
                      )
                    )}
                  </div>
                ))}

              {activeTab === 'additional_services' &&
                order?.orderLegs?.map((orderLeg: any, legIndex: number) => (
                  <div
                    key={legIndex}
                    className={`${
                      orderLeg.id == activeOrderLeg?.id ? '' : 'hidden'
                    }`}
                  >
                    <RadarAdditionalServices orderLeg={orderLeg} />
                  </div>
                ))}

              {activeTab === 'appointments' &&
                order?.orderLegs?.map((orderLeg: any, legIndex: number) => (
                  <div
                    key={legIndex}
                    className={`${
                      orderLeg.id == activeOrderLeg?.id ? '' : 'hidden'
                    }`}
                  >
                    <CommonAppointmentDetails
                      fromRadar={true}
                      localLegData={activeOrderLeg}
                      setAction={setAction}
                      handleDeleteAppointment={handleDeleteAppointment}
                      setEditMode={setEditMode}
                      isShowDraftEmail={true}
                      isLoading={isLoading}
                    />
                  </div>
                ))}

              {activeTab === 'pricing' &&
                order?.orderLegs?.map((orderLeg: any, legIndex: number) => (
                  <div
                    key={legIndex}
                    className={`${
                      orderLeg.id == activeOrderLeg?.id ? '' : 'hidden'
                    }`}
                  >
                    <RadarPricing orderLeg={orderLeg} />
                  </div>
                ))}
            </div>
          </AccordionCmp>
        </AccordionCmp>

        <div className="flex items-start justify-between gap-x-3 w-full">
          <div>
            <h6 className="text-grayLight900 text-base font-medium mb-0.5">
              Location:{' '}
              {order?.orderLocationUpdate
                ? order?.orderLocationUpdate?.fullAddress
                : `${order?.orderLegs?.[0].shipperAddress1}, ${order?.orderLegs?.[0].shipperCity}`}
            </h6>
            <StatusDotProfileCmp
              dotColor="green"
              label={`Last Update: ${formatLastUpdate(
                order?.orderLocationUpdate
                  ? order?.orderLocationUpdate?.updatedAt
                  : order?.createdAt
              )}`}
            />
          </div>
          {!isDisplayLocation && (
            <div className="flex flex-col items-end justify-end">
              <ButtonCmp
                className="btn-outline-primary mt-1 text-nowrap"
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsDisplayLocation(true);
                }}
              >
                Update Location
              </ButtonCmp>
            </div>
          )}
        </div>
        {isDisplayLocation ? (
          <div className="flex items-end gap-x-2.5">
            {mapService === 'trimble_map' ? (
              <AutoCompleteTrimble
                inputName="fullAddress"
                placeholder="Enter address"
                className="form_control truncate"
                label="Address"
                labelClassName="mb-1.5 block"
                mapParentClass="flex-1"
                dropdownClass="!max-h-[200px]"
                onAddressInputChange={setOrderCurrentLocation}
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                errorText={!orderCurrentLocation ? 'Address is required' : null}
                value={orderCurrentLocation}
              />
            ) : (
              <AutoCompleteGoogle
                inputName="fullAddress"
                placeholder="Enter address"
                className="form_control truncate"
                label="Address"
                mapParentClass="flex-1"
                labelClassName="mb-1.5 block"
                onAddressInputChange={handleAutocompleteChange}
                onChangeFunc={handleAutocompleteChange}
                onBlur={handleAutocompleteChange}
                errorText={!orderCurrentLocation ? 'Address is required' : null}
                value={orderCurrentLocation}
              />
            )}

            <ButtonCmp
              className={`btn-outline-primary lg:!px-[9px] !px-2 h-fit 'bg-primary text-white'`}
              icon={<Plus className="w-4 h-4" />}
              onClick={() => {
                submitUpdateOrderLocation();
              }}
            >
              Add
            </ButtonCmp>
          </div>
        ) : (
          <>
            <div>
              <div className="flex justify-between items-center gap-x-2 mb-1.5">
                <h6 className="text-textSecondary text-sm font-medium">
                  60% Complete
                </h6>
                <p className="text-gray500 text-xs font-normal">2 days left</p>
              </div>
              <progress
                className="progress bg-utilityGray200 progress-success flex"
                value="60"
                max="100"
              ></progress>
            </div>
            <ul className="flex justify-between gap-x-2 pb-3 border-b border-utilityGray200">
              <li className="flex-1 flex flex-col gap-y-1">
                <h6 className="text-grayLight600 text-xs font-normal">ETA</h6>
                <p className="text-grayLight900 text-xs font-medium">
                  {activeOrderLeg.expectedEstimatedDeliveryTime
                    ? getDateWithSuffixFormat(
                        activeOrderLeg.expectedEstimatedDeliveryTime,
                        'MMM Do'
                      )
                    : ''}
                </p>
              </li>
              <li className="flex-1 flex flex-col gap-y-1">
                <h6 className="text-grayLight600 text-xs font-normal">
                  Est. Distance Left
                </h6>
                <p className="text-grayLight900 text-xs font-medium">
                  {calculateDistance(
                    order?.shipperAddress?.latitude,
                    order?.shipperAddress?.longitude,
                    order?.consigneeAddress?.latitude,
                    order?.consigneeAddress?.longitude
                  )}{' '}
                  MI
                </p>
              </li>
              <li className="flex-1 flex flex-col gap-y-1">
                <h6 className="text-grayLight600 text-xs font-normal">
                  Predictive Location Index
                </h6>
                <BadgeCmp style="modern" type="success" mainClassName="w-fit">
                  On Track
                </BadgeCmp>
                {/* <BadgeCmp style="modern" type="warning" mainClassName="w-fit">
                  At Risk
                </BadgeCmp>
                <BadgeCmp style="modern" type="red" mainClassName="w-fit">
                  Delayed
                </BadgeCmp>
                <BadgeCmp style="modern" type="red" mainClassName="w-fit">
                  Critical Delay
                </BadgeCmp> */}
              </li>
            </ul>
            <ul>
              {order?.orderLegs?.map((orderLeg: any, index: number) => (
                <>
                  <li
                    key={index}
                    className="relative flex items-start gap-x-2 pb-5 last:pb-0 [&:last-child>div:after]:hidden z-[1]"
                  >
                    <div
                      className={
                        (order?.orderLocationUpdate &&
                          order?.orderLocationUpdate.fullAddress ==
                            orderLeg.shipperFullAddress) ||
                        (!order?.orderLocationUpdate && index == 0)
                          ? `after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary after:bg-fgSuccessPrimary`
                          : `after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-transparent after:bg-fgSuccessPrimary`
                      }
                    >
                      {(order?.orderLocationUpdate &&
                        order?.orderLocationUpdate.fullAddress ==
                          orderLeg.shipperFullAddress) ||
                      (!order?.orderLocationUpdate && index == 0) ? (
                        <Check className="w-3 h-3 text-white" />
                      ) : (
                        <div className="w-2 h-2 bg-fgSuccessPrimary rounded-full"></div>
                      )}
                    </div>
                    <div className="flex-1 py-[6px] px-2 rounded-lg bg-white border border-utilityGray200 shadow-sm">
                      <div className="flex items-center gap-x-1 mb-2">
                        <h6 className="text-grayLight900 text-sm font-medium">
                          {orderLeg.shipperCity}
                        </h6>
                        <p className="text-gray500 leading-4 text-[10px] uppercase font-normal">
                          #{orderLeg.legId}
                        </p>
                      </div>
                      <p className="text-utilityGray800 leading-4 text-xs font-normal mb-[2px]">
                        {orderLeg.shipperFullAddress}
                      </p>
                      <p className="text-[10px] text-gray500 hidden">
                        4 Nov 2023 10:30 AM
                      </p>
                    </div>
                  </li>
                  {index == order?.orderLegs?.length - 1 && (
                    <li className="relative flex items-start gap-x-2 pb-5 last:pb-0 [&:last-child>div:after]:hidden z-[1]">
                      <div
                        className={
                          order?.orderLocationUpdate &&
                          order?.orderLocationUpdate.fullAddress ==
                            orderLeg.consigneeFullAddress
                            ? `after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary after:bg-fgSuccessPrimary`
                            : `after:content-[''] after:h-[93%] after:w-[2px] after:rounded-sm after:top-[13px] after:absolute after:z-[-1] flex items-center justify-center w-[18px] h-[18px] rounded-full bg-transparent after:bg-fgSuccessPrimary`
                        }
                      >
                        {order?.orderLocationUpdate &&
                        order?.orderLocationUpdate.fullAddress ==
                          orderLeg.consigneeFullAddress ? (
                          <Check className="w-3 h-3 text-white" />
                        ) : (
                          <div className="w-2 h-2 bg-fgSuccessPrimary rounded-full"></div>
                        )}
                      </div>
                      <div className="flex-1 py-[6px] px-2 rounded-lg bg-white border border-utilityGray200 shadow-sm">
                        <div className="flex items-center gap-x-1 mb-2">
                          <h6 className="text-grayLight900 text-sm font-medium">
                            {orderLeg.consigneeCity}
                          </h6>
                          <p className="text-gray500 leading-4 text-[10px] uppercase font-normal">
                            #{orderLeg.legId}
                          </p>
                        </div>
                        <p className="text-utilityGray800 leading-4 text-xs font-normal mb-[2px]">
                          {orderLeg.consigneeFullAddress}
                        </p>
                        <p className="text-[10px] text-gray500 hidden">
                          4 Nov 2023 8:40 AM
                        </p>
                      </div>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </>
        )}

        {/* BELOW COMPONENT ALSO USED IN ORDER DETAIL */}
        <TrackTraceAiCmp />
      </div>
      <div className="p-4 shadow-tmd flex gap-x-4">
        <ButtonCmp className="btn-outline-primary flex-1" onClick={() => {}}>
          Report Interruption
        </ButtonCmp>
        <ButtonCmp className="btn-outline-primary flex-1" onClick={() => {}}>
          Mark as Delivered
        </ButtonCmp>
      </div>

      {appointmentDetails[action.mode] && (
        <AppointmentModal
          modalTitle={
            editMode.type
              ? `Edit ${appointmentDetails[editMode.type].title}`
              : `Set ${appointmentDetails[action.mode].title}`
          }
          modalDesc={appointmentDetails[action.mode].description}
          primaryBtnText="Set Appointment"
          type={action.mode}
          orderLegId={activeOrderLeg?.id}
          editData={editMode.details}
          handleClose={() => {
            setAction(initAction);
            setEditMode({ type: null, details: null });
          }}
          handleOnSubmit={(formattedData: any) => {
            let type = action.mode;
            const updatedLegData = { ...activeOrderLeg };

            if (type === 'shipper-appointment') {
              updatedLegData.shipperAppointmentDatetime =
                formattedData.combinedDatetime;
            } else if (type === 'carrier-pickup-appointment') {
              updatedLegData.carrierPickupAppointmentDatetime =
                formattedData.combinedDatetime;
            } else if (type === 'carrier-delivery-appointment') {
              updatedLegData.carrierDeliveryAppointmentDatetime =
                formattedData.combinedDatetime;
            } else if (type === 'delivery-appointment') {
              updatedLegData.deliveryAppointmentDatetime =
                formattedData.combinedDatetime;
            }
            setActiveOrderLeg((old: any) => ({ ...old, ...updatedLegData }));
            setAction((old: any) => ({
              ...old,
              mode: 'appointment-success-modal',
              type: action.mode,
            }));

            setEditMode({ type: null, details: null });
          }}
        />
      )}

      {action.mode === 'appointment-success-modal' && (
        <AppointmentSuccessModal
          handleClose={() => {
            setAction(initAction);
          }}
          setAction={setAction}
        />
      )}

      {action.mode === 'appointment-darft-modal' && (
        <DraftMailModal
          handleClose={() => setAction(initAction)}
          title={`Set Appointment`}
          DraftDetails={{
            subjectLine: appointmentDetails[action.type].draftTitle,
            toEmailAddress:
              appointmentDetails[action.type].sendMailTo === 'shipper'
                ? activeOrderLeg?.shipper?.email
                : appointmentDetails[action.type].sendMailTo === 'consignee'
                ? activeOrderLeg?.consignee?.email
                : appointmentDetails[action.type].sendMailTo === 'carrier'
                ? activeOrderLeg?.carrier?.length > 0
                  ? activeOrderLeg?.carrier[0]?.email
                  : ''
                : '',
            emailDetails: getEmailMessageForAppointMent(
              action.type,
              activeOrderLeg
            ),
          }}
        />
      )}
      {/* {isDisplayLocation ? (
        <UpdateLocation
          status={status}
          handleClose={() => setIsDisplayLocation(false)}
        />
      ) : (
        ''
      )} */}
    </>
  );
};
export default OrderDetail;
