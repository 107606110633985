import {
  // Microphone01,
  PhoneCall02,
  PhoneOutgoing02,
  // Voicemail,
  VolumeMax,
  // VolumeX,
} from '@untitled-ui/icons-react/build/cjs';
import axios from 'axios';
import React, { useEffect } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import {
  setCallDuration,
  setConferenceId,
  setConferenceName,
  setErrorMessage,
  setHoldStatus,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setOutgoingCall,
  setOutgoingCallDetails,
  setParticipants,
} from 'src/redux/CallCenter.slice';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { RootState } from 'src/redux/store';
import { disconnectCall } from 'src/services/CallCenterService';
import { fetchJsFromCDN } from 'src/utils/CommonFunctions';

import Profile from '../../assets/img/profile.png';

const OutboundModal = () => {
  const dispatch = useDispatch();
  console.log('⭐️ ~ OutboundModal ~ dispatch:', dispatch);
  const { outgoingCallDetails, outgoingCall, conferenceName, conferenceId } =
    useSelector((state: RootState) => state.CallCenter);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const handleHangup = async () => {
    if (outgoingCall) {
      if (typeof outgoingCall.disconnect === 'function') {
        outgoingCall?.disconnect();
      }
      await disconnectCall({
        callerSid: outgoingCall?.parameters?.CallSid,
      });
      dispatch(setIsCallInProgress(false));
      dispatch(setCallDuration(0));
      dispatch(setOutgoingCall(null));
      dispatch(setOutgoingCallDetails(null));
      dispatch(setOnHold(false));
      dispatch(setIsMuted(false));
      dispatch(setHoldStatus(false));
      dispatch(setIsRecording(true));
    }
  };

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.removeAllListeners();

      // Conference Created For Incoming Call
      socketIO.on('conferenceCreated', (conference: any) => {
        console.log('⭐️⭐️ ~ conference in outboundModal:', conference);
        dispatch(setConferenceName(conference?.conferenceName));
        dispatch(setConferenceId(conference?.conferenceSid));
        dispatch(setParticipants(conference?.participants));
        dispatch(setIsCallInProgress(true));
      });

      socketIO.on('disconnectCall', async () => {
        console.log('disconnect call not received');
        handleHangup();
      });
    } else {
      fetchJsFromCDN(
        'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
        ['io']
      )
        .then(([io]: any) => {
          io.sails.url = window.SERVER_URL;

          io.socket.on('connect', function socketConnected() {
            dispatch(setIsSocketConnected(true));
            dispatch(setSocketIo(io.socket));

            io.socket.get(
              `/subscribe/globalRoom`,
              function (data: any, jwr: any) {
                if (jwr.error) {
                  return;
                }
              }
            );
          });

          // Conference Created For Incoming Call
          io.socket.on('conferenceCreated', (conference: any) => {
            console.log(
              '⭐️⭐️ ~ io.socket.on ~ in outboundModal:',
              conference
            );
            dispatch(setConferenceName(conference?.conferenceName));
            dispatch(setConferenceId(conference?.conferenceSid));
            dispatch(setParticipants(conference?.participants));
            dispatch(setIsCallInProgress(true));
          });

          io.socket.on('disconnectCall', async () => {
            console.log('disconnect call not received');
            handleHangup();
          });

          // Clean up on component unmount
          return () => {
            io.socket.off('classifyCaller');
          };
        })
        .catch((error: any) => {
          console.error('Failed to load Sails socket library:', error);
        });
    }
  }, []);

  const handleTransferCall = async () => {
    console.log('callInstance.current', outgoingCall);

    // if (!incomingCall?.parameters?.CallSid) {
    //   setErrorMessage('Please ensure a call is in progress.');
    //   return;
    // }

    try {
      const callSid = outgoingCall?.parameters?.CallSid;
      console.log('1. ⭐️ ~ handleTransferCall ~ callSid:', callSid);

      // const callSid = incomingCall?.parameters?.CallSid;
      console.log('2. ⭐️ handleTransferCall ~ callSid:', callSid);
      console.log('3. ⭐️ conferenceName', conferenceName);

      // transfer the call
      await axios.post(`${window.API_URL}/twilio/transfer-call`, {
        conferenceName: conferenceName,
        currentParticipantCallSid: callSid,
        newParticipantNumber: '+919427420518',
        conferenceSid: conferenceId,
        removeCurrentParticipant: true,
      });

      // rejectIncomingCall();

      console.log(`4. ⭐️ Call successfully transferred`);
    } catch (error) {
      dispatch(
        setErrorMessage(
          error instanceof Error
            ? error.message
            : 'An error occurred during the call transfer.'
        )
      );
      console.error('Error transferring call:', error);
    }
  };

  return (
    <>
      <Draggable>
        <div className="floating-modal">
          <div className="floating-modal-header">
            <h6 className="floating-modal-header-title">Outbound</h6>
            <p className="flex-none">Calling...</p>
          </div>

          <div className="floating-modal-body flex items-center gap-x-2.5">
            <img
              className="w-10 h-10 rounded-full border border-gray500 flex-none"
              src={outgoingCallDetails?.image || Profile}
            />
            {/* IF NO IMAGE FOUND SHOW THIS BELOW CODE */}
            {/* <div className="w-10 h-10 rounded-full border border-gray500 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
              MD
              </div> */}
            <div className="flex-1 truncate">
              <h6 className="text-base font-medium text-white truncate">
                {outgoingCallDetails?.name || 'Unknown'}
              </h6>

              <p className="text-xs leading-[1.5] font-normal text-borderPrimary truncate">
                {outgoingCallDetails?.companyName}
              </p>
              {/* <p className="text-xs font-normal text-borderPrimary truncate">
                {outgoingCallDetails?.number}
              </p> */}
            </div>

            <div className="flex gap-2 flex-none">
              <ButtonCmp
                className="btn-xs btn-gray"
                icon={<PhoneOutgoing02 className="w-4 h-4" />}
                disabled={true}
                onClick={handleTransferCall}
              >
                <></>
              </ButtonCmp>

              <ButtonCmp
                className="btn-xs btn-gray"
                icon={<VolumeMax className="w-4 h-4" />}

                // icon={
                //   isMutedAudio ? (
                //     <VolumeX className="w-4 h-4" />
                //   ) : (
                //     <VolumeMax className="w-4 h-4" />
                //   )
                // }
                // onClick={toggleVolume}
              >
                <></>
              </ButtonCmp>
              <ButtonCmp
                className="btn-xs btn-danger"
                icon={<PhoneCall02 className="w-4 h-4" />}
                onClick={handleHangup}
              >
                <></>
              </ButtonCmp>

              {/* <ButtonCmp
                className="btn-gray"
                icon={<Microphone01 className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp> */}
              {/* <ButtonCmp
            className="btn-gray"
            icon={<Voicemail className="w-4 h-4" />}
          >
            <></>
          </ButtonCmp> */}
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default OutboundModal;
