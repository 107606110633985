import {
  CurrencyDollar,
  InfoCircle,
  Percent01,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TooltipCmp from 'src/components/TooltipCmp';
import { getPredictivePrice } from 'src/services/QuoteService';

import { predictivePriceOptions } from './PredictivePrice.interface';

const PredictivePrice = ({ id }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    getPredictivePrice(id)
      .then((response: any) => {
        if (response && response.data) {
          setPredictivePriceData(response?.data ?? []);
          setSeries(response?.data?.graphData?.series ?? []);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <div id="chart" className="">
        <Chart
          options={predictivePriceOptions}
          series={series}
          type="line"
          height={'280px'}
          className={` rounded-xl border border-utilityGray200 bg-white p-5 w-full ${
            isLoading ? 'custom-loading graph-loading' : ''
          }`}
        />
        <div className="w-full rounded-xl border border-utilityGray200 px-4 py-4 mt-5 bg-white">
          <div className="flex items-center justify-between pb-4">
            <div className="flex items-center gap-3">
              <TooltipCmp
                message={`${predictivePriceData?.from}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-sm truncate p-2 border border-utilityGray200 bg-gray50 rounded-lg ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600 font-normal">From: </span>
                  <span className="text-grayLight900 font-medium">
                    {predictivePriceData?.from}
                  </span>
                </div>
              </TooltipCmp>

              <TooltipCmp
                message={`${predictivePriceData?.to}`}
                parentClassName="max-w-90"
              >
                <div
                  className={`text-sm truncate p-2 border border-utilityGray200 bg-gray50 rounded-lg${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <span className="text-grayLight600 font-normal">To: </span>
                  <span className="text-grayLight900 font-medium">
                    {predictivePriceData?.to}
                  </span>
                </div>
              </TooltipCmp>
            </div>

            <div>
              <ButtonCmp
                className="btn-outline-primary"
                icon={<Plus className="w-4 h-4" />}
              >
                Add Quick Quote
              </ButtonCmp>
            </div>
          </div>

          <div className="flex bg-gray25 p-3 rounded-xl gap-3">
            <div className="flex-1 bg-white border border-utilityGray200 p-3 rounded-xl">
              <h6 className="text-grayLight600 text-sm font-normal mb-4">
                Target Buy Rate
              </h6>
              <div className="flex gap-x-5 items-center">
                <h6
                  className={`text-grayLight900 text-3xl font-semibold ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  ${predictivePriceData?.total}
                </h6>
                <div className="flex-1">
                  <p className="text-grayLight600 text-sm font-normal mb-1">
                    Confidence
                  </p>
                  <div className="progress-range-container">
                    <div
                      className="progress-range-bar !bg-[#17B26A]"
                      style={{
                        width: `${predictivePriceData?.confidenceLevel}%`,
                      }}
                    >
                      <div className="progress-range-thumb progress-range-thumb-invisible left-[100%]">
                        <div className="progress-tooltip absolute bottom-full left-1/2 -translate-x-1/2 px-3 py-2 rounded-lg border border-utilityGray200 bg-white tooltip-custom-arrow text-xs font-semibold mb-3.5">
                          {predictivePriceData?.confidenceLevel}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex items-center gap-2 bg-white border border-utilityGray200 p-3 rounded-xl">
              <div className="w-1/3">
                <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                  Total Cost
                </h6>

                <InputText
                  inputType="text"
                  inputName="markup"
                  value={'203'}
                  className="pl-8"
                  parentClassName="w-full"
                  icon={<CurrencyDollar className="input-currency-sign" />}
                />
              </div>
              <div className="w-1/3">
                <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                  Margin
                </h6>

                <InputText
                  inputType="text"
                  inputName="markup"
                  value={'17'}
                  className="pl-8"
                  parentClassName="w-full"
                  icon={<Percent01 className="input-currency-sign" />}
                />
              </div>
              <div className="w-1/3">
                <h6 className="text-textSecondary text-xs font-medium mb-1.5">
                  Total Sale Price
                </h6>

                <InputText
                  inputType="text"
                  inputName="markup"
                  value={'1399'}
                  className="pl-8"
                  icon={<CurrencyDollar className="input-currency-sign" />}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 rounded-[10px] border border-utilityBrand200 bg-primary50 p-3 text-xs text-grayLight600 font-normal flex items-center">
            <InfoCircle className="w-4 h-4 mr-1" />
            Your average margin for this customer is
            <span className="font-semibold ml-1"> 17.45%</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PredictivePrice;
