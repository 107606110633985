import { Plane, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import { ListPredictivePricing } from 'src/services/PredictivePricingService';

import PredictivePricingLoaderRow from '../PredictivePrice/PredictivePricingLoaderRow';
import PredictivePricingRow from '../PredictivePrice/PredictivePricingRow';

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  classification: null,
};

const classOptions = [
  {
    label: 'LTL',
    value: QUOTE_CLASSIFICATION.LTL,
  },
  {
    label: 'FTL',
    value: QUOTE_CLASSIFICATION.FTL,
  },
];

const PredictivePrice = () => {
  const [predictivePrices, setPredictivePrices] = useState<any[]>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(initParams);

  const [search, setSearch] = useState('');
  const [sourceSearch, setSourceSearch] = useState('');
  const [destinationSearch, setDestinationSearch] = useState('');

  const getPredictivePricing = (signal: any) => {
    setPredictivePrices([]);
    setIsLoading(true);

    ListPredictivePricing(params, signal)
      .then((response: any) => {
        if (response.data) {
          setPredictivePrices(response.data);
          setTotals(response.total);
        }

        setIsLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
      });
  };

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch, page: 1 } })),
      700
    ),
    []
  );

  const sourceSearchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({
          ...old,
          ...{ sourceSearch: debSearch, page: 1 },
        })),
      700
    ),
    []
  );

  const destinationSearchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({
          ...old,
          ...{ destinationSearch: debSearch, page: 1 },
        })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchPredictivePricing') {
      setSearch(value);
      searchDebounce(value);
    }

    if (name === 'searchSource') {
      setSourceSearch(value);
      sourceSearchDebounce(value);
    }

    if (name === 'searchDestination') {
      setDestinationSearch(value);
      destinationSearchDebounce(value);
    }
  };

  useEffect(() => {
    const predictivePricingApiController = new AbortController();
    const predictivePricingApiSignal = predictivePricingApiController.signal;
    getPredictivePricing(predictivePricingApiSignal);

    return () => {
      predictivePricingApiController.abort();
    };
  }, [params]);

  const baseHeadCells = [
    {
      id: 'carrierName',
      name: 'Carrier',
      rowClassName: '',
      sortable: true,
    },
    {
      id: 'classification',
      name: 'Service Type',
      sortable: true,
    },
    {
      id: 'sourceCity',
      name: 'Origin',
      sortable: true,
    },
    {
      id: 'destinationCity',
      name: 'Destination',
      sortable: true,
    },
    {
      id: 'createdOn',
      name: 'Date',
      sortable: true,
    },
    {
      id: 'carrierEquipmentType',
      name: 'Equipment Type',
      sortable: true,
    },
    {
      id: 'linearFootage',
      name: 'Linear Feet',
      sortable: true,
    },
    {
      id: 'totalWeight',
      name: 'Total Weight',
      sortable: true,
    },
    {
      id: 'offerAmount',
      name: 'Total Rate',
      sortable: true,
    },
    {
      id: 'action',
      name: '',
    },
  ];

  return (
    <PageSectionLayout
      header={
        <Header
          title={'Predictive Pricing'}
          desc={`Adjust prices in real-time based on demand and trends.`}
        />
      }
    >
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
        <div className="table-top-header ">
          <div className="table-left-wrap sm:w-auto w-full">
            <div className="table-title-wrap">
              <h5 className="table-title">All Rates</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={isLoading ? 'custom-loading' : ''}
              >
                {totals} {totals > 1 ? 'Prices' : 'Price'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">View and manage predictive prices</p>
          </div>
        </div>
        <div className="table-bottom-header">
          <div className="table-header-bottom-left flex gap-3">
            <InputText
              inputName="searchPredictivePricing"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              onChangeFunc={handleFilter}
              parentClassName="table-searchInput"
              isClearable={true}
            />
            <InputText
              inputName="searchSource"
              placeholder="Search origin"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <Plane className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={sourceSearch}
              inputType="text"
              isClearable={true}
              onChangeFunc={handleFilter}
              parentClassName="min-w-[250px]"
            />
            <InputText
              inputName="searchDestination"
              placeholder="Search destination"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input "
              icon={
                <Plane className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4 rotate-90" />
              }
              value={destinationSearch}
              inputType="text"
              isClearable={true}
              onChangeFunc={handleFilter}
              parentClassName="min-w-[250px]"
            />
          </div>
          <SelectBox
            parentClassName="w-[90px]"
            name="classification"
            required
            className={`form_control`}
            labelClassName="form_label mb-1.5 block"
            options={classOptions}
            placeholder="All Type"
            isClearable={true}
            value={
              params.classification
                ? classOptions?.filter(
                    (val: any) => params?.classification === val?.value
                  )
                : 'All'
            }
            onChangeFunc={(event: any) => {
              console.log('event?.value ', event?.value);
              setParams((old: any) => ({
                ...old,
                classification: event?.value || undefined,
                page: 1,
              }));
            }}
          />
          <div className="table-recordsPerPage">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: any) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div>
        </div>
        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={baseHeadCells}
              tableDataArr={predictivePrices}
              params={params}
              setParams={setParams}
              TableLoaderRowCmp={PredictivePricingLoaderRow}
              TableRowCmp={PredictivePricingRow}
              numberOfSkeletonRows={15}
              isTableDataLoading={isLoading}
              tableHeaderClass=""
            />
          </div>
          {predictivePrices.length <= 0 && !isLoading && (
            <NotFoundUI
              title="No Rates Found"
              desc="Currently, no rates are available for the selected criteria."
              containerClassName="!h-[unset] flex-1"
            />
          )}
        </div>
        <CustomPagination
          recordsPerPage={params.limit}
          totalRecords={totals}
          currentPage={params.page}
          handlePagination={(page: number) => {
            setParams((old) => ({ ...old, page }));
          }}
        />
      </div>
    </PageSectionLayout>
  );
};

export default PredictivePrice;
