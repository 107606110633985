import { Check } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

interface IProps {
  isProgressLoading: any;
  progressData: any;
}

const ProgressList = ({ isProgressLoading, progressData }: IProps) => {
  const ProgressItem = ({
    progressValue,
    status,
    loader,
    iconClass = '',
    progressClass = 'bg-utilityGray200 progress-success',
    labelClass = 'text-grayLight600 text-xs font-normal mb-2.5',
    step,
  }: {
    progressValue: number;
    status: string;
    loader?: boolean;
    iconClass?: string;
    progressClass?: string;
    labelClass?: string;
    step?: string;
  }) => (
    <li className="flex-1">
      <h6
        className={`text-xs font-semibold leading-[1.5] uppercase mb-1.5 ${
          progressValue === 0 ? 'text-gray500' : 'text-grayLight900'
        }`}
      >
        {step}
      </h6>
      <div className={`flex items-center gap-x-1 ${labelClass}`}>
        {progressValue === 100 || progressValue === 0 ? (
          <div
            className={`rounded-xl ${iconClass} w-3.5 h-3.5 flex items-center justify-center ${
              loader ? 'custom-loading' : ''
            }`}
          >
            {loader ? (
              <Check className="w-2.5 h-2.5 text-white" />
            ) : (
              progressValue === 100 && (
                <Check className="w-2.5 h-2.5 text-white" />
              )
            )}
          </div>
        ) : (
          <>
            {/* <p
              className={`loading loading-spinner text-primary w-3.5 h-3.5 }`}
            ></p> */}

            <div
              className={`radial-progress text-primary ${iconClass}`}
              style={
                {
                  '--value': progressValue,
                  '--size': '14px',
                  '--thickness': '3px',
                } as React.CSSProperties & { '--value': number }
              }
              role="progressbar"
            ></div>
          </>
        )}

        <span className={`${loader ? 'custom-loading' : ''}`}>{status}</span>
      </div>
      <progress
        className={`progress max-w-full h-[5px] flex ${progressClass} ${
          loader ? 'custom-loading' : ''
        }`}
        value={progressValue}
        max="100"
      ></progress>
    </li>
  );

  return (
    <>
      <ul className="flex flex-wrap flex-1 xxl:gap-x-8 gap-x-5">
        <ProgressItem
          progressValue={progressData.Booked ?? 0}
          status="Booked"
          loader={isProgressLoading}
          iconClass={`${
            progressData.Booked === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Booked === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
          step={'Step 1'}
        />
        <ProgressItem
          progressValue={progressData.Dispatched ?? 0}
          status="Dispatched"
          loader={isProgressLoading}
          iconClass={`${
            progressData.Dispatched === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Dispatched === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
          progressClass="bg-utilityGray200 progress-success"
          step={'Step 2'}
        />
        <ProgressItem
          progressValue={progressData.InTransit ?? 0}
          loader={isProgressLoading}
          status="In-Transit"
          iconClass={`${
            progressData.InTransit === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.InTransit === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
          step={'Step 3'}
        />
        <ProgressItem
          progressValue={progressData.Delivered ?? 0}
          loader={isProgressLoading}
          status="Delivered"
          iconClass={`${
            progressData.Delivered === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Delivered === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
          step={'Step 4'}
        />
        <ProgressItem
          progressValue={progressData.Completed ?? 0}
          loader={isProgressLoading}
          status="Completed"
          iconClass={`${
            progressData.Completed === 100
              ? 'bg-fgSuccessPrimary'
              : progressData.Completed === 0
              ? 'border-2 border-utilityGray200'
              : 'radial-progress text-primary'
          }`}
          step={'Step 5'}
        />
      </ul>
    </>
  );
};

export default ProgressList;
