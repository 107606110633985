import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import Toggle from 'src/components/Toggle/Toggle';
import { CURRENCY, TIMEZONE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getAppearance, updateAppearance } from 'src/services/SettingService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface FormValues {
  isEnableLoadInsurance: boolean;
  load_insurance_purchase_per: number;
  load_insurance_sell_per: number;
}

const LoadInsuranceSettings = () => {
  const { appearance, setAppearance, setCurrency } = useContext(BasicContext);
  const [isLoading, setIsLoading] = useState(false);

  // const [isEnableLoadInsurance, setIsEnableLoadInsurance] = useState(false);

  const getAppearanceDetail = () => {
    getAppearance()
      .then((response) => {
        if (response.data) {
          setAppearance(response.data);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  useEffect(() => {
    getAppearanceDetail();
  }, []);

  const validationSchema = yup.object().shape({
    isEnableLoadInsurance: yup.boolean(),
    load_insurance_purchase_per: yup
      .number()
      .min(0, 'Value cannot be less than 0%')
      .max(100, 'Value cannot be more than 100%')
      .required('Load Insurance purchase(%) is required.'),
    load_insurance_sell_per: yup
      .number()
      .min(0, 'Value cannot be less than 0%')
      .max(100, 'Value cannot be more than 100%')
      .required('Load Insurance sell(%) is required.'),
  });

  const defaultValues: FormValues = {
    isEnableLoadInsurance: false,
    load_insurance_purchase_per: 0,
    load_insurance_sell_per: 0,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (appearance) {
      console.log('appearance', appearance);

      reset({
        isEnableLoadInsurance: appearance?.isEnableLoadInsurance,
        load_insurance_purchase_per: appearance?.load_insurance_purchase_per,
        load_insurance_sell_per: appearance?.load_insurance_sell_per,
      });
    }
  }, [appearance, reset]);

  const onSubmit = async (formData: any) => {
    setIsLoading(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      if (value) appendFormData.append(key, value);
    });

    updateAppearance(appendFormData)
      .then((response) => {
        getAppearanceDetail();
        setCurrency(response?.data?.currency ?? CURRENCY.CAD);
        moment.tz.setDefault(response.data?.timezone ?? TIMEZONE);
        WalToast.success('Appearance setting have been updated successfully');
      })
      .finally(() => setIsLoading(false))
      .catch(() => WalToast.error('Appearance setting have been not updated'));
  };

  return (
    <form className="h-full w-full px-2 py-1" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
        <div>
          <h6 className="text-grayLight900 font-semibold text-sm">
            Load Insurance
          </h6>
          <p className="text-grayLight600 font-normal text-sm">
            Update load insurance details here.
          </p>
        </div>
      </div>

      <div className="mt-4">
        <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm"></label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="isEnableLoadInsurance"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Toggle
                  label="Enable Load Insurance."
                  labelClassName="form_label"
                  labelPosition="right"
                  isChecked={value}
                  onChange={(e: any) => {
                    onChange(e?.target?.checked);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            Cost(%)
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="load_insurance_purchase_per"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="load_insurance_purchase_per"
                  inputType="number"
                  placeholder="Enter Load Insurance Cost Percentage"
                  parentClassName="flex-1"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={
                    errors.load_insurance_purchase_per
                      ? errors.load_insurance_purchase_per?.message
                      : null
                  }
                  shouldFormatNumber={true}
                  inputValueUnitType="%"
                />
              )}
            />
          </div>
        </div>
        <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            Sale Price(%)
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="load_insurance_sell_per"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="load_insurance_sell_per"
                  inputType="number"
                  placeholder="Enter Load Insurance Sales Price Percentage"
                  parentClassName="flex-1"
                  value={value}
                  onChangeFunc={onChange}
                  errorText={
                    errors.load_insurance_sell_per
                      ? errors.load_insurance_sell_per?.message
                      : null
                  }
                  shouldFormatNumber={true}
                  inputValueUnitType="%"
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 py-4">
          <ButtonCmp
            type="button"
            className="btn_secondary_black shadow py-2 px-3"
            onClick={() => {
              reset(defaultValues);
            }}
          >
            Cancel
          </ButtonCmp>
          <ButtonCmp
            type="submit"
            className="btn_primary  shadow py-2 px-3"
            loading={isLoading}
          >
            Save
          </ButtonCmp>
        </div>
      </div>
    </form>
  );
};

export default LoadInsuranceSettings;
