// import { Clock } from '@untitled-ui/icons-react/build/cjs';
// import moment from 'moment';
import React from 'react';
// import { getBadgeType } from 'src/app/Claims&Disputes/ClaimsDisputes.const';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  // displayTimeDifference,
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

const CustomerDetailDisputesRaw = ({ onRowClick, data }: any) => (
  // const { type, clockType } = getBadgeType(
  //   moment.utc(data?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
  // );

  <>
    <td
      className="px-5 py-4 min-w-44 w-44 max-w-44"
      onClick={() => onRowClick(data)}
    >
      <div className="flex items-center">
        {data.name &&
          (data.image ? (
            <div className="w-8 h-8 rounded-full uppercase bg-utilityGray100 text-primary flex-none relative">
              <img
                className="w-full h-full rounded-full border border-black/[0.08] flex-none"
                src={data.imageUrl + data.image}
                alt={data.name}
                title={data.name}
              />
              {data.hasUnreadNotification ? (
                <span
                  className={`border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 right-0`}
                ></span>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="w-8 h-8 rounded-full uppercase bg-utilityGray100 text-primary flex-none relative">
              <div className="w-full h-full rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
                {getShortName(`${data.name}`)}
              </div>
              {data.hasUnreadNotification ? (
                <span
                  className={`border-[1.5px] border-white bg-primary500 rounded-full w-2.5 h-2.5 absolute top-0 right-0`}
                ></span>
              ) : (
                ''
              )}
            </div>
          ))}

        {!data.name && !data.image && data.hasUnreadNotification ? (
          <p className="bg-primary500 w-2 h-2 rounded flex-none mt-3"></p>
        ) : (
          ''
        )}

        <div className="pl-2 text-xs font-medium max-w-28">
          <TooltipCmp
            message={data.name && data.name.length > 18 ? data.name : null}
          >
            <div className="text-grayLight900 font-medium leading-tight truncate">{`${
              data.name ?? '-'
            }`}</div>
          </TooltipCmp>
          <div className="text-grayLight600 font-normal leading-tight truncate">
            #{data.disputeID}
          </div>
        </div>
      </div>
    </td>

    <td
      className="px-5 py-4 text-xs max-w-32 w-32 min-w-32 "
      onClick={() => onRowClick(data)}
    >
      <BadgeCmp
        style="modern"
        type={
          data.status === 'New'
            ? 'success'
            : data.status === 'Resolved'
            ? 'primary'
            : data.status === 'Opened'
            ? 'lightblue'
            : data.status === 'Closed'
            ? 'error'
            : 'success'
        }
      >
        <span data-test-id="wal-QuotingDashboard-status">{data.status}</span>
      </BadgeCmp>
    </td>
    <td
      className="px-5 py-4 max-w-32 min-w-32 w-32"
      onClick={() => onRowClick(data)}
    >
      <div className="flex items-center">
        <div className="text-xs font-medium max-w-32">
          <div className="text-grayLight600 font-normal leading-tight truncate">
            #{data.orderID}
          </div>
        </div>
      </div>
    </td>
    <td
      className="px-5 py-4 min-w-40 w-40 max-w-40"
      onClick={() => onRowClick(data)}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: data.createdAt
            ? getDateWithSuffixFormat(
                getFormattedDate(data.createdAt, `MMM Do - YYYY`, true, true)
              )
            : '-',
        }}
      />
    </td>

    <td
      data-test-id="wal-QuotingDashboard-totalWeight"
      className="px-5 py-4  min-w-[150px] max-w-[150px]"
      onClick={() => onRowClick(data)}
    >
      {data.carrier ? (
        <TooltipCmp
          message={data.carrier.length > 24 ? data.carrier : null}
          parentClassName="max-w-90"
        >
          <p className="truncate">{data.carrier}</p>
        </TooltipCmp>
      ) : (
        '-'
      )}
    </td>
    {/* <td
      data-test-id="wal-QuotingDashboard-totalUnits"
      className="px-5 py-4"
      onClick={() => onRowClick(data)}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: data.orderCreatedAt
            ? getDateWithSuffixFormat(
                getFormattedDate(
                  data.orderCreatedAt,
                  `MMM Do - YYYY`,
                  true,
                  true
                )
              )
            : '-',
        }}
      />
    </td> */}
    <td
      className={`px-5 py-4 min-w-30 max-w-30`}
      onClick={() => onRowClick(data)}
    >
      ${data.totalPrice}
    </td>
    <td
      className={`px-5 py-4 min-w-30 max-w-30`}
      onClick={() => onRowClick(data)}
    >
      ${data.disputeAmount}
    </td>

    {/* <td
        className="px-5 py-4 min-w-30 max-w-30"
        onClick={() => onRowClick(data)}
      >
        {data.createdAt ? (
          <BadgeCmp style="pill" type={type} mainClassName="rounded-md">
            <div className="flex items-center gap-1">
              <Clock className={`w-3 h-3 mt-[1px] ${clockType}`} />
              {displayTimeDifference(
                moment
                  .utc(data?.createdAt)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              )}
            </div>
          </BadgeCmp>
        ) : (
          '-'
        )}
      </td> */}
  </>
);
export default CustomerDetailDisputesRaw;
